export enum ProductivityStatus {
  UndefinedPassive = -3,
  UnproductivePassive = -2,
  Unproductive = -1,
  Undefined = 0,
  Productive = 1,
  ProductivePassive = 2,
  OfflineMeetings = 9,
  None = -999
}
