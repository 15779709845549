import React from 'react';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { TimeAgo } from '../../common/components/TimeAgo';

import { TextOverflow } from '../../common/styles/app.component.styles';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from '../../common/utils/datetime/datetimeFormatters';
import { getTimeLapsedSinceNow } from '../../common/utils/datetime/datetimeCalculations';
import { IAgentSearch } from '../models/IAgentSearch';

export const useAddAgentColumns = (): IGridColumn[] => {
  return [
    {
      field: 'userName',
      headerName: 'Device Login',
      isSortable: true,
      type: 'string',
      width: '24%',
      template: (dataItem: IAgentSearch) => {
        let deviceLogin = dataItem?.userName;
        if (deviceLogin && dataItem?.logonDomain) {
          deviceLogin += '@';
        }
        deviceLogin += dataItem?.logonDomain;
        return (
          <Tooltip title={deviceLogin ?? ' '} placement="bottom-start">
            <TextOverflow>{deviceLogin ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'firstLog',
      headerName: 'First Log',
      type: 'date',
      isSortable: true,
      width: '10%',

      template: (dataItem: IAgentSearch) => {
        return <>{formatDate(dataItem?.firstLog) ?? ''}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log',
      type: 'date',
      isSortable: true,
      width: '12%',
      template: (dataItem: IAgentSearch) => {
        if (dataItem) {
          const timeAgo = getTimeLapsedSinceNow(dataItem.lastLog);
          return (
            <TimeAgo
              lastLog={dataItem.lastLog}
              learnMoreLink="https://support.activtrak.com/"
              timeAgo={timeAgo}
            />
          );
        }
        return <></>;
      }
    },
    {
      field: 'displayName',
      headerName: 'User',
      type: 'string',
      isSortable: true,
      width: '18%',
      template: (dataItem: IAgentSearch) => {
        return (
          <Tooltip title={dataItem?.displayName ?? ''} placement="bottom-start">
            <TextOverflow>{dataItem?.displayName ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'tracked',
      headerName: 'Tracking',
      type: 'string',
      isSortable: true,
      width: '12%',
      template: (dataItem: IAgentSearch) => {
        return (
          <span>
            {dataItem?.tracked === null || dataItem.tracked
              ? 'Tracked'
              : 'Untracked'}
          </span>
        );
      }
    },
    {
      field: 'displayId',
      headerName: 'Source & ID',
      type: 'string',
      isSortable: true,
      width: '24%',
      template: (dataItem: IAgentSearch) => {
        return (
          <Tooltip
            title={dataItem?.displayId?.value ?? ''}
            placement="bottom-start"
          >
            <TextOverflow>{dataItem?.displayId?.value ?? ''}</TextOverflow>
          </Tooltip>
        );
      }
    }
  ];
};
