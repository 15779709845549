import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { ColumnState } from 'ag-grid-community';
import { IWorkingHoursColumnsStore } from '../models/WorkingHours.models';
import {
  WORKING_HOURS_DETAILED_COLUMNS_DEFAULT,
  WORKING_HOURS_SUMMARY_COLUMNS_DEFAULT
} from '../constants/workingHours.constants';
import { REPORT_COLUMNS_STORAGE_PREFIX } from '../../common/constants/reports.constants';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';

export const workingHoursColumnsStore =
  createStore<IWorkingHoursColumnsStore>()(
    persist(
      () => {
        return {
          [ReportViewTypes.SUMMARY]: WORKING_HOURS_SUMMARY_COLUMNS_DEFAULT,
          [ReportViewTypes.DETAILED]: WORKING_HOURS_DETAILED_COLUMNS_DEFAULT
        };
      },
      {
        name: REPORT_COLUMNS_STORAGE_PREFIX + 'workingHours'
      }
    )
  );

export const setWorkingHoursColumns = (
  newColumns: Partial<Record<ReportViewTypes, ColumnState[]>>
) => {
  workingHoursColumnsStore.setState(newColumns);
};

export const getWorkingHoursColumns = () => {
  const cols = workingHoursColumnsStore.getState();
  //remove any null values caused by column access removal first
  //this should not be needed, but let's throw it in for safety
  Object.keys(cols).forEach((key) => {
    cols[key] = cols[key].filter((col) => col);
  });

  return cols;
};

export const resetWorkingHoursColumns = () => {
  workingHoursColumnsStore.setState(
    {
      [ReportViewTypes.SUMMARY]: WORKING_HOURS_SUMMARY_COLUMNS_DEFAULT,
      [ReportViewTypes.DETAILED]: WORKING_HOURS_DETAILED_COLUMNS_DEFAULT
    },
    true
  );
};
