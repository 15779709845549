import {
  IAgentSearch,
  IAgentSearchDto,
  IAgentSearchResults,
  IAgentSearchResultsDto
} from '../models/IAgentSearch';
import { IUserAgent, IUserIdentity } from '../models/IUserIdentity';
import { mapToValueValidation } from './IdentityMappers';
import { nullIfEmpty } from './SearchUtils';

export function mapToAgentResults(
  agentResultsDto: IAgentSearchResultsDto
): IAgentSearchResults {
  return {
    cursor: agentResultsDto.cursor,
    results: agentResultsDto.results.map(mapToAgent)
  };
}

export function mapToAgent(agentDto: IAgentSearchDto): IAgentSearch {
  if (agentDto) {
    const userAndLogonDomain =
      nullIfEmpty(agentDto.userName) == null
        ? null
        : agentDto.userName + '@' + agentDto.logonDomain;
    return {
      displayId: mapToValueValidation(agentDto.displayId),
      userId: agentDto.userId,
      entityId: agentDto.entityId,
      revision: agentDto.revision,
      displayName: agentDto.displayName,
      firstLog: new Date(agentDto.firstLog),
      lastLog: new Date(agentDto.lastLog),
      userName: agentDto.userName,
      logonDomain: agentDto.logonDomain,
      selected: false,
      singleDescription:
        userAndLogonDomain ?? agentDto.userName ?? agentDto.userId.toString(),
      tracked: agentDto.tracked,
      userLogonDomain: userAndLogonDomain
    };
  }
  return null;
}

export const mapToAgentSearch = (
  dataItem: IUserAgent,
  selectedIdentity: IUserIdentity
): IAgentSearch => ({
  userId: dataItem.userId,
  userName: dataItem.userName,
  logonDomain: dataItem.logonDomain,
  lastLog: dataItem.lastLog,
  firstLog: dataItem.firstLog,
  selected: true,
  entityId: selectedIdentity.id,
  revision: selectedIdentity.revision,
  displayId: selectedIdentity.displayId,
  displayName: dataItem.alias,
  singleDescription: null,
  tracked: dataItem.tracked,
  userLogonDomain: dataItem.userLogonDomain
});
