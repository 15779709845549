import React, { MutableRefObject, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ICoreCategory } from '../../models';
import PercentInput from './PercentInput';
import {
  DialogContentStyled,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import BackButtonBox from './BackButtonBox';
import CalculateCoreActivityHours from './CalculateCoreActivityHours';
import { useGoalState } from '../../hooks/useGoalState';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  backToStep3: () => void;
  onSaveConfiguration: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
};

export const Step4Dialog = (props: ComponentProps) => {
  const { open, onClose, backToStep3, onSaveConfiguration, coreCategoryRef } =
    props;
  const { goal, fetchGoal } = useGoalState();
  const [percentValue, setPercentValue] = useState<number | null>(null);

  const handlePercentBlur = (value: number | null) => {
    setPercentValue(value);
  };

  const onSave = () => {
    coreCategoryRef.current.targetPercentage = percentValue;
    setPercentValue(null);
    onSaveConfiguration();
  };

  useEffect(() => {
    if (open) {
      setPercentValue(coreCategoryRef.current.targetPercentage);
      fetchGoal(coreCategoryRef.current.groupId);
    }
  }, [coreCategoryRef, fetchGoal, open]);

  const onBackToStep3 = () => {
    if (percentValue) {
      coreCategoryRef.current.targetPercentage = percentValue;
    }
    backToStep3();
  };

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <BackButtonBox onClick={onBackToStep3} />
        <Box width={692} maxHeight={353}>
          <DialogTitle>
            Step 4 of 4: Set your Team’s Core Activity Time Goal
          </DialogTitle>
          <DialogContentStyled>
            <Typography sx={TotCss.instructions}>
              Determine the percentage of productive time your team should
              dedicate to their core categories.
            </Typography>
            <PercentInput
              defaultValue={coreCategoryRef.current.targetPercentage}
              onBlur={handlePercentBlur}
            />
            <CalculateCoreActivityHours
              percentValue={percentValue}
              productiveHrsDayGoal={goal?.targetValue}
            />
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={onClose}
              onClick={onSave}
              disabled={!percentValue}
              confirmText={'Save configuration'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
