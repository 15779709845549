import { IUpdateValue } from '../models/IUpdateValue';
import {
  IValueValidation,
  IValueValidationPrimary
} from '../models/IUserIdentity';
import { IValueId } from '../models/IValueId';

export const trimValueOrEmptyString = (value: string): string => {
  if (value) {
    return value.trim();
  }
  return '';
};

export const trimValueId = (valueId: IValueId): IValueId => {
  const trimmedValue = valueId?.value.trim();
  return { value: trimmedValue };
};

export const trimValueIdOrNull = (valueId: IValueId): IValueId => {
  const trimmedValue = valueId?.value?.trim();
  if (!trimmedValue) {
    return null;
  }
  return { value: trimmedValue };
};

export const trimValueValidationPrimary = (
  valueId: IValueValidationPrimary
): IUpdateValue => {
  const trimmedValue = valueId?.value.trim();
  return { value: trimmedValue, id: valueId?.originalValue };
};

export const trimValueValidation = (
  valueId: IValueValidation
): IUpdateValue => {
  const trimmedValue = valueId?.value.trim();
  return {
    value: trimmedValue,
    id:
      valueId?.originalValue && valueId.originalValue.length > 0
        ? valueId?.originalValue
        : null
  };
};
