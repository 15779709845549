import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Box from '@mui/material/Box';
import { TeamsComparisonDonutChartProps } from '.';
import { DONUT_BASE_CONFIG } from '../../../utils';
import { TeamsComparisonCanvasBox } from '../../../styles/todaysActivity.styles';
import { getPieChartTooltip } from '../../../../common/utils/chart';

export const TeamsComparisonDonutChart = (
  props: TeamsComparisonDonutChartProps
) => {
  const { chartData, centerText, noUsersCount } = props;

  const options = {
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 2,
        right: 10,
        bottom: 4
      }
    },
    cutout: '78%',
    plugins: {
      legend: { ...DONUT_BASE_CONFIG.legend, display: false },
      tooltip: {
        ...DONUT_BASE_CONFIG.tooltip,
        borderWidth: 0,
        cornerRadius: 4,
        displayColors: false,
        xAlign: 'center',
        yAlign: 'center',
        backgroundColor: (tooltipItem) => {
          return tooltipItem.tooltip.labelColors[0].backgroundColor;
        },
        callbacks: {
          label: function (context) {
            let label = context.label;
            const value = context.formattedValue;

            if (noUsersCount && chartData.datasets[0].data[1] === 1) {
              return `No data to report`;
            }

            if (!label) label = 'Unknown';

            return `${value} ${label} users today`;
          }
        },
        enabled: false,
        external: function (context) {
          const tooltipId = 'todays-activity-tooltip';
          let tooltipEl = document.getElementById(tooltipId);
          const tooltipModel = context.tooltip;

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl = getPieChartTooltip(context, tooltipId);
        }
      }
    }
  };

  return (
    <Box>
      {chartData && centerText && (
        <TeamsComparisonCanvasBox>
          <Doughnut
            // Using key this way is a hacky way to get the centerText plugin to update on props change
            key={Math.random()}
            data={chartData as any}
            options={options as any}
            plugins={[centerText]}
          />
        </TeamsComparisonCanvasBox>
      )}
    </Box>
  );
};
