import React from 'react';
import {
  FeatureBadgeTypography,
  FeatureBadgeWrapper
} from '../Header/styles/FeatureBadge.styles';

export const FeatureBadge: React.FC<{
  label: string;
  isPrimary?: boolean;
}> = ({ label, isPrimary = true }) => {
  return (
    <FeatureBadgeWrapper isPrimary={isPrimary}>
      <FeatureBadgeTypography isPrimary={isPrimary}>
        {label}
      </FeatureBadgeTypography>
    </FeatureBadgeWrapper>
  );
};

export default FeatureBadge;
