import angular from 'angular';
import { createStore } from 'zustand';

type InjectorStore = {
  injector: angular.auto.IInjectorService;
  getInjector: () => angular.auto.IInjectorService;
  setInjector: (injector: angular.auto.IInjectorService) => void;
};

/** don't expose - private instance */
const _globalInjectorStore = createStore<InjectorStore>((set, get) => ({
  injector: null,
  getInjector: () => get().injector,
  setInjector: (injector) => set({ injector })
}));

/**
 * @deprecated use getInjector instead
 * @returns 
 */
export const useInjector = () => {
  const injector = _globalInjectorStore.getState().injector;
  return injector;
};

export const getInjector = () => {
  const injector = _globalInjectorStore.getState().injector;
  return injector;
};

export const setInjector = (injector: angular.auto.IInjectorService) => {
  _globalInjectorStore.getState().setInjector(injector);
};
