import React, { useEffect, useState } from 'react';
import { ListFilter } from '../../common/components/Filter/ListFilter';
import { IListFilter } from '../../common/models';

type ComponentProps = {
  gridFilter: string;
  onSetGridFilter: (newFilter: string) => void;
};

const bulkFilterMenuItems: IListFilter[] = [
  {
    label: 'All users',
    template: () => 'All users',
    filter: 'limit',
    filterValue: ''
  },
  {
    label: 'Users active in last 30 days',
    template: () => 'Users active in last 30 days',
    filter: 'limit',
    filterValue: 'active30days'
  },
  {
    label: 'Tracked users',
    template: () => 'Tracked users',
    filter: 'limit',
    filterValue: 'tracked'
  },
  {
    label: 'Untracked users',
    template: () => 'Untracked users',
    filter: 'limit',
    filterValue: 'untracked'
  }
];

export const GlobalUserFilters = (props: ComponentProps) => {
  const { gridFilter, onSetGridFilter } = props;

  const onSetGlobalFilter = (filter: IListFilter) => {
    onSetGridFilter(filter.filterValue);
  };

  const getLabelByValue = (value: string): string => {
    let matchLabel = null;
    bulkFilterMenuItems.forEach((item) => {
      if (item.filterValue === value) {
        matchLabel = item.label;
      }
    });
    return matchLabel;
  };
  const [selectedFilterValue, setSelectedFilterValue] = useState<string>(
    getLabelByValue(gridFilter)
  );

  useEffect(() => {
    const gridLabel = getLabelByValue(gridFilter);
    setSelectedFilterValue(gridLabel);
  }, [gridFilter]);

  return (
    <ListFilter
      listItems={bulkFilterMenuItems}
      onClick={onSetGlobalFilter}
      defaultSelection={selectedFilterValue}
    />
  );
};
