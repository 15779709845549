import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { IInsightsCategory } from '../../models';
import {
  StyledFormControlLabel,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import CategoryCheckboxLabel from './CategoryCheckboxLabel';

type CategoryCheckboxListProps = {
  filtered: IInsightsCategory[];
  checkedItems: IInsightsCategory[];
  setCheckedItems: React.Dispatch<React.SetStateAction<IInsightsCategory[]>>;
  searchString: string;
};

const CategoryCheckboxList: React.FC<CategoryCheckboxListProps> = ({
  filtered,
  checkedItems,
  setCheckedItems,
  searchString
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const category = filtered.find((c) => c.categoryId === value);
    if (checked && category) {
      setCheckedItems((prev) => [...prev, category]);
    } else {
      setCheckedItems((prev) =>
        prev.filter((item) => item.categoryId !== value)
      );
    }
  };

  return (
    <Box sx={{ ...TotCss.scrollableBox, height: '210px' }}>
      <FormGroup>
        {filtered?.map((c) => (
          <StyledFormControlLabel
            sx={{
              padding:
                c.matchedApps?.length > 0 || c.matchedSites?.length > 0
                  ? '8px 0 6px 0'
                  : '0'
            }}
            key={c.categoryId}
            control={
              <Checkbox
                color="primary"
                checked={checkedItems.some(
                  (item) => item.categoryId === c.categoryId
                )}
                value={c.categoryId}
                name={c.category}
                onChange={handleChange}
                sx={{
                  marginTop:
                    c.matchedApps?.length > 0 || c.matchedSites?.length > 0
                      ? '-11px'
                      : '0',
                  alignSelf: 'flex-start'
                }}
              />
            }
            label={CategoryCheckboxLabel({ category: c, searchString })}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default CategoryCheckboxList;
