import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  getChildAccounts,
  getEnterprise,
  postLoginTemp
} from './utils/api/commandCenterApi.js';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarMessage from './components/SnackbarMessage.jsx';
import AddCustomerDialog from './components/AddCustomer/AddCustomerDialog.jsx';
import Layout from './components/Layout/Layout.jsx';
import Dashboard from './routes/Dashboard/Dashboard.jsx';
import Enterprise from './routes/Enterprise/Enterprise.jsx';

import { profileStore } from '../../_reactivtrak/src/common/services/Profile/useProfileState';
import { PlanPeriod } from '../../_reactivtrak/src/common/enums/PlanPeriod';
import { ChannelType, SubChannelType } from '../../_reactivtrak/src/common/enums/ChannelType'

const classes = {
  loader: {
    position: 'absolute',
    left: '11%',
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 171
  }
};

const SNACKBAR_INITIAL_STATE = {
  open: false,
  message: '',
  severity: ''
};

import {
  CC_TYPE,
  CHANNEL_TYPE,
  EXPIRED_DASHBOARD_TYPE,
  PAID_DASHBOARD_TYPE,
  TRIAL_DASHBOARD_TYPE,
  ALL_DASHBOARD_TYPE
} from './utils/constants';
import { getIsCCEnterprise } from './utils/api/utils';

import { userServiceFunctions } from '../../_app/serviceFunctions/userServiceFunctions.js';
const { getIsImposter } = userServiceFunctions;

const intercom_app_id = import.meta.env.PROD
  ? 'd645d06179373abcfd63e2adf58402e05e558127'
  : 'jt7axmqc';

function bootIntercom(traits) {
  if (window.Intercom) {
    window.Intercom('boot', {
      // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
      // eslint-disable-next-line no-undef
      app_id: intercom_app_id,
      name: `${traits.firstName} ${traits.lastName}`,
      email: traits.username, // Email address,
      user_id: traits.username,
      user_hash: traits.intercommHash
      // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
    });
  }
}

async function loadIntercom(profile) {
  bootIntercom(profile);
}

export default function DashboardLayout() {
  const [snackbar, setSnackbar] = useState(SNACKBAR_INITIAL_STATE);
  const [activeUsers, setActiveUsers] = useState(null);
  const [licenseQuantity, setLicenseQuantity] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [commandCenterType, setCommandCenterType] = useState('');
  const [cceDefaultTrialFields, setCceDefaultTrialFields] = useState({});

  const [profile, setProfile] = useState({});
  const [accountTypeCounts, setAccountTypeCounts] = useState({});
  const [paidChildAccounts, setPaidChildAccounts] = useState();
  const [activeTrialChildAccounts, setActiveTrialChildAccounts] = useState();
  const [activeExpiredTrialChildAccounts, setActiveExpiredTrialChildAccounts] =
    useState();
  const [enterpriseAccounts, setEnterpriseAccounts] = useState();

  useEffect(() => {
    const checkProfileData = async () => {
      // Ensure base profile has finished populating then check if profileStore did not populate to fetch profile data
      if (profile?.roleAccess && !profile?.firstName) {
        await profileStore.getState().fetch();
        const { firstName, lastName, username } =
          profileStore.getState().profile;
        setProfile({ ...profile, firstName, lastName, username });
      }
    };

    checkProfileData();
  }, [profile]);

  useEffect(() => {
    const url = window.location.href;
    const signupUrl = url.match('signup');

    if (!openDialog && signupUrl) {
      setOpenDialog(true);
    }
    if (openDialog && !signupUrl) {
      window.location.href += '?signup';
    }
  }, [openDialog]);

  const loadMspAccount = useCallback(async () => {
    try {
      const resultChildAccounts = await getChildAccounts(ALL_DASHBOARD_TYPE);
      const paids = resultChildAccounts.filter((child) => child.isPaid);
      const activeTrials = resultChildAccounts.filter(
        (child) => child.isActiveTrial
      );
      const expiredTrials = resultChildAccounts.filter(
        (child) => child.isExpiredTrial
      );
      setPaidChildAccounts(paids);
      setActiveTrialChildAccounts(activeTrials);
      setActiveExpiredTrialChildAccounts(expiredTrials);
      setAccountTypeCounts({
        paid: paids.length,
        activeTrial: activeTrials.length,
        expiredTrial: expiredTrials.length
      });
    } catch (e) {
      onError(e);
    }
  }, []);

  const loadEnterpriseAccount = useCallback(async () => {
    try {
      const resultEnterpriseAccounts = await getEnterprise();
      setEnterpriseAccounts(resultEnterpriseAccounts.parents);
      setActiveUsers(resultEnterpriseAccounts.usedLicenses);
      setLicenseQuantity(resultEnterpriseAccounts.licenseQuantity);
      setAccountTypeCounts({
        enterprise: resultEnterpriseAccounts.parents.length
      });

      const trialPool = resultEnterpriseAccounts?.parents.find(
        (p) => p.planPeriod === PlanPeriod.Trial
      );

      setCceDefaultTrialFields({
        parentPoolAcctId: trialPool?.accountNumber,
        planCode: trialPool?.planCode,
        channel: ChannelType.LicensePool,
        subChannel: SubChannelType.MSPChild
      });
    } catch (e) {
      onError(e);
    }
  }, []);

  useEffect(() => {
    const getUserProfile = async () => {
      try {

        const profile = await postLoginTemp();
        //TEMP: Compensate for API change
        profile.isAdmin =
          typeof profile.roles.find((r) => r.includes('Admin')) != 'undefined';
        profile.isSupportUser =
          typeof profile.roles.find((r) => r.includes('Support')) !=
          'undefined';
        profile.isImposter = getIsImposter();
        profile.mspParentAdmin =
          profile.subscriptionType === CHANNEL_TYPE.MSPOfficialParent &&
          (profile.isAdmin || profile.isSupportUser);
        profile.enterpriseParentAdmin =
          profile.subscriptionType === CHANNEL_TYPE.EnterpriseParent &&
          (profile.isAdmin || profile.isSupportUser);
        profile.partnerParentAdmin =
          profile.subscriptionType === CHANNEL_TYPE.PartnerParent &&
          (profile.isAdmin || profile.isSupportUser);


        // TODO: Review after accountSettings refactoring
        const { firstName, lastName } = profileStore.getState().profile;
        profile.firstName = firstName;
        profile.lastName = lastName;


        setProfile(profile);
        loadIntercom(profile);
      } catch (e) {
        onError(e);
      }
    };

    getUserProfile();
  }, []);

  useEffect(() => {
    if (getIsCCEnterprise()) {
      loadEnterpriseAccount();
      setCommandCenterType(CC_TYPE.Enterprise);
    } else {
      loadMspAccount();
      setCommandCenterType(CC_TYPE.Standard);
    }
  }, [loadEnterpriseAccount, loadMspAccount]);

  const closeSnackbar = () => {
    setSnackbar(SNACKBAR_INITIAL_STATE);
  };

  const onError = (e) => {
    setSnackbar({
      open: true,
      message: e.message,
      severity: 'error'
    });
  };

  const onSuccess = (successMessage, children) => {
    setSnackbar({
      open: true,
      message: successMessage,
      severity: 'success',
      children
    });
  };

  const openAddCustomerDialog = (e) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  const updateUserCount = (userCount) => {
    setActiveUsers(userCount);
  };

  const renderMsp = (type, childAccounts, canUpdateCount = false) => {
    return (
      <Dashboard
        profile={profile}
        updateUserCount={updateUserCount}
        canUpdateCount={canUpdateCount}
        openDialog={openAddCustomerDialog}
        onError={onError}
        onSuccess={onSuccess}
        dashboardType={type}
        accounts={childAccounts}
      />
    );
  };

  const renderLayout = () => {
    switch (commandCenterType) {
      case CC_TYPE.Standard:
        return (
          <Routes>
            <Route
              path="/cc/paid-subscriptions"
              element={renderMsp(PAID_DASHBOARD_TYPE, paidChildAccounts, true)}
            />
            <Route
              path="/cc/trial-subscriptions"
              element={renderMsp(
                TRIAL_DASHBOARD_TYPE,
                activeTrialChildAccounts
              )}
            />
            <Route
              path="/cc/expired-trial-subscriptions"
              element={renderMsp(
                EXPIRED_DASHBOARD_TYPE,
                activeExpiredTrialChildAccounts
              )}
            />
            <Route
              path="/cc/dashboard"
              element={<Navigate to="/cc/paid-subscriptions" />}
            />
          </Routes>
        );
      case CC_TYPE.Enterprise:
        return (
          <Routes>
            <Route
              path="/cc/enterprise"
              element={
                <Enterprise accounts={enterpriseAccounts} onError={onError} />
              }
            />
            <Route
              path="/cc/dashboard"
              element={<Navigate to="/cc/enterprise" />}
            />
          </Routes>
        );
      default:
        return <CircularProgress color="primary" sx={classes.loader} />;
    }
  };

  return (
    <Box className="App">
      <Layout
        profile={profile}
        commandCenterType={commandCenterType}
        accountTypeCount={accountTypeCounts}
        activeUsers={activeUsers}
        licenseQuantity={licenseQuantity}
        openDialog={openAddCustomerDialog}
        onError={onError}
        onSuccess={onSuccess}
        hasSupportPortalRole={profile.isSupportUser}
        isImpersonating={profile.isImposter}
      >
        {renderLayout()}
      </Layout>
      <SnackbarMessage
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        close={closeSnackbar}
      >
        {snackbar.children}
      </SnackbarMessage>
      <AddCustomerDialog
        open={openDialog}
        onCloseDialog={() => {
          setOpenDialog(false);
        }}
        onError={onError}
        onSuccess={onSuccess}
        profile={profile}
        ccType={commandCenterType}
        cceDefaultFields={cceDefaultTrialFields}
      />
    </Box>
  );
}
