import { SvgIconProps } from './SvgIconProps';

export const UploadIcon2: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? '16px';
  const width = props.width ?? '16px';
  const fillColor = props.fillColor ?? 'currentColor';

  return (
    <svg
      width={height}
      height={width}
      viewBox="0 -960 960 960"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z"
        fill={fillColor}
      />
    </svg>
  );
};
