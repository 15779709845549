import React from 'react';
import { styled } from '@mui/material/styles';
import {
  fontColor900,
  gray0,
  gray13,
  gray15,
  mediumFontWeight,
  primaryColor,
  primaryFontSize
} from '../../../constants';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

const baseTooltipStyles = {
  color: primaryColor,
  backgroundColor: gray0,
  textAlign: 'left',
  margin: `0px !important`,
  padding: '8px 0',
  borderRadius: '5px',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.20), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
  '& .MuiTabs-flexContainer': {
    margin: '8px 16px !important'
  },
  '& .MuiTabs-indicator': {
    bottom: '16px'
  },
  '& .MuiList-root': {
    padding: 0
  }
};

export const BaseTooltipSC = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: baseTooltipStyles
});

export const TeamsTooltipSC = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    ...baseTooltipStyles,
    minWidth: '380px',
    maxHeight: '263px'
  }
});

export const UsersComputersTooltipSC = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    ...baseTooltipStyles,
    minWidth: '400px',
    maxHeight: '400px',
    '& .MuiTabs-flexContainer': {
      margin: '8px 16px !important'
    }
  }
});

export const ProductivityFilterSC = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    ...baseTooltipStyles,
    padding: '8px 0',
    '.MuiListItemIcon-root': {
      minWidth: '32px'
    }
  }
});

export const ExportContainerSC = styled('div')({
  ' & .btn-default': {
    fontWeight: mediumFontWeight,
    color: fontColor900,
    backgroundColor: gray0,
    borderColor: gray13,
    borderRadius: '4px',
    letterSpacing: '1.25px',
    lineHeight: '16px',
    padding: '0 16px',
    height: '32px',
    '&:hover': {
      backgroundColor: gray15,
      borderColor: gray13,
      color: fontColor900
    },
    '&:focus': {
      backgroundColor: gray15,
      borderColor: gray13,
      color: fontColor900
    },
    '&:active': {
      backgroundColor: gray15,
      borderColor: gray13,
      color: fontColor900
    }
  },
  '& .open>.dropdown-toggle.btn-default': {
    backgroundColor: gray15,
    borderColor: gray13,
    color: fontColor900
  },
  '& .dropdown-menu': {
    background: gray0,
    ' > li': {
      fontSize: primaryFontSize,
      color: primaryColor,
      ' > a': {
        color: primaryColor,
        fontSize: primaryFontSize,
        '&:hover': {
          color: primaryColor,
          backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
        }
      }
    }
  }
});

export const ListSkeletonLoaderContainer = styled('div')({
  flex: 1,
  margin: '5px 0'
});

export const ListSkeletonSC = styled(Skeleton)`
  height: 32px !important;
`;

export const GridColumnsFilterActionsSC = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 8px;
`;

export const GridColumnsFilterSC = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    ...baseTooltipStyles,
    minWidth: '350px',
    maxWidth: '350px'
  }
});
