import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HelpIcon from '@mui/icons-material/Help';
import CloudCircle from '@mui/icons-material/CloudCircle';
import {
  ProductivityIconProps,
  ProductivityName
} from '../models/Productivity.models';
import {
  productivityColors,
  stopLightProductivityColors
} from '../../../common/constants/styleGuide';

export const ProductivityIcons: ProductivityIconProps = {
  [ProductivityName.Productive]: {
    icon: CheckCircleIcon,
    color: productivityColors.productive
  },
  [ProductivityName.Unproductive]: {
    icon: RemoveCircleIcon,
    color: productivityColors.unproductive
  },
  [ProductivityName.Undefined]: {
    icon: HelpIcon,
    color: productivityColors.undefined
  },
  [ProductivityName.OfflineMeetings]: {
    icon: CloudCircle,
    color: productivityColors.offlineMeetings
  },
  [ProductivityName.ProductiveActive]: {
    icon: CheckCircleIcon,
    color: stopLightProductivityColors.productive
  },
  [ProductivityName.ProductivePassive]: {
    icon: CheckCircleIcon,
    color: stopLightProductivityColors.productivePassive
  },
  [ProductivityName.UnproductiveActive]: {
    icon: RemoveCircleIcon,
    color: stopLightProductivityColors.unproductive
  },
  [ProductivityName.UnproductivePassive]: {
    icon: RemoveCircleIcon,
    color: stopLightProductivityColors.unproductivePassive
  },
  [ProductivityName.UndefinedActive]: {
    icon: HelpIcon,
    color: stopLightProductivityColors.undefined
  },
  [ProductivityName.UndefinedPassive]: {
    icon: HelpIcon,
    color: stopLightProductivityColors.undefinedPassive
  }
};
