import { createStore } from 'zustand';
import {
  WorkingHoursExportExcludeColumn,
  IWorkingHoursExportExcludeColumnsStore
} from '../models/WorkingHours.models';

export const workingHoursExportExcludeColumnsStore =
  createStore<IWorkingHoursExportExcludeColumnsStore>(() => ({
    excludeColumns: []
  }));

export const setWorkingHoursExportExcludeColumns = (
  newColumns: WorkingHoursExportExcludeColumn[]
) => {
  workingHoursExportExcludeColumnsStore.setState({
    excludeColumns: newColumns
  });
};

export const getWorkingHoursExportExcludeColumns = () =>
  workingHoursExportExcludeColumnsStore.getState();

export const resetWorkingHoursExportExcludeColumns = () => {
  workingHoursExportExcludeColumnsStore.setState(
    {
      excludeColumns: []
    },
    true
  );
};
