import { useState } from 'react';
import {
  DetailItem,
  DetailLabel,
  EditIconName,
  DetailItemEmployeeId,
  DetailEditBox
} from '../styles/UserDetail.view.styles';
import { TextOverflow } from '../../common/styles/app.component.styles';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import { useAgentSearchState } from '../hooks/useAgentSearchState';
import { SkeletonLoader } from '../../common/components/SkeletonLoader';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { EditUserModal } from './EditUserModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { TrackModal } from './TrackModal';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { DoNotTrackModal } from './DoNotTrackModal';
import { DeleteUser } from './DeleteUser';
import { IDntDeleteState } from '../models/IDntDeleteState';
import { GroupTypeLabel } from '../../common/components/GroupType';

type ComponentProps = {
  identityDetailState: IIdentityDetailState;
  dntDeleteState: IDntDeleteState;
  commonState: IIdentityCommonState;
  newUserMode: boolean;
  isReadOnly: boolean;
};

export const DetailUserInfo = (props: ComponentProps) => {
  const { identityDetailState, isReadOnly, commonState, dntDeleteState } =
    props;
  const { isLoadingDetail, selectedIdentity, handleSelectedIdentityChange } =
    identityDetailState;
  const [openEditNameModal, setOpenEditNameModal] = useState<boolean>(false);
  const agentSearchState = useAgentSearchState();

  const onOpenEditNameModal = () => {
    agentSearchState.resetAgentsSelected();
    setOpenEditNameModal(true);
  };

  const onTrackingSelectionChange = () => {
    const newTracked = !selectedIdentity?.tracked;
    dntDeleteState.setTracking(newTracked).then(([error, newIdentity]) => {
      if (!error) {
        commonState.setIdentityNotification({
          msg:
            'Tracking ' +
            (newTracked ? 'started' : 'stopped') +
            ' successfully',
          type: 'success'
        });
        dntDeleteState.userToModify.tracked = false;
        identityDetailState.handleSelectedIdentityChange(
          dntDeleteState.userToModify,
          newIdentity
        );
      } else {
        commonState.setIdentityNotification({
          msg: 'Error occurred while stopping tracking',
          type: 'error'
        });
      }
    });
  };

  const onCloseEditModal = (error, newIdentity) => {
    if (newIdentity) {
      identityDetailState.handleSelectedIdentityChange(
        selectedIdentity,
        newIdentity
      );
      if (!error) {
        commonState.setIdentityNotification({
          msg: 'User updated successfully',
          type: 'success'
        });
        setOpenEditNameModal(false);
      }
    } else {
      if (error && !newIdentity) {
        //catastrophic error
        commonState.setIdentityNotification({
          msg: 'Error occurred while updating user: ' + error?.data?.value,
          type: 'error'
        });
      } else {
        setOpenEditNameModal(false);
      }
    }
  };

  return (
    <>
      <Card elevation={3}>
        <CardContent sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          {!isLoadingDetail && (
            <>
              <DetailEditBox>
                <Button
                  onClick={onOpenEditNameModal}
                  startIcon={<EditIconName />}
                  color="secondary"
                  disabled={isReadOnly}
                >
                  Edit user
                </Button>
              </DetailEditBox>
              <DetailLabel>User:</DetailLabel>
              <DetailItem>
                <Tooltip
                  title={selectedIdentity?.displayName?.value ?? ''}
                  placement="bottom-start"
                >
                  <TextOverflow>
                    {selectedIdentity?.displayName?.value}
                  </TextOverflow>
                </Tooltip>
              </DetailItem>
              <DetailLabel>Source & ID:</DetailLabel>
              <DetailItemEmployeeId sx={{ paddingBottom: '32px' }}>
                <GroupTypeLabel
                  iconSource={selectedIdentity?.displayId?.source}
                  tooltipContent={`${selectedIdentity?.displayId?.value} - ${selectedIdentity?.displayId?.source}`}
                  tooltipChild={selectedIdentity?.displayId?.value}
                />
              </DetailItemEmployeeId>
            </>
          )}
          {isLoadingDetail && <SkeletonLoader height={140}></SkeletonLoader>}

          <Box display="flex" sx={{ flexFlow: 'row wrap', columnGap: '15px' }}>
            <FormControlLabel
              value={selectedIdentity?.tracked}
              control={
                <Radio
                  size="small"
                  color="primary"
                  onChange={() => onTrackingSelectionChange()}
                  checked={selectedIdentity?.tracked ?? false}
                  name="detail-user-track"
                  disabled={isReadOnly}
                />
              }
              label="Tracked"
            />

            <FormControlLabel
              value={!selectedIdentity?.tracked}
              control={
                <Radio
                  size="small"
                  color="primary"
                  checked={!(selectedIdentity?.tracked ?? false)}
                  onChange={() => onTrackingSelectionChange()}
                  name="detail-user-track"
                  disabled={isReadOnly}
                />
              }
              label="Untracked"
            />
          </Box>
        </CardContent>
      </Card>
      <EditUserModal
        isOpen={openEditNameModal}
        selectedIdentity={selectedIdentity}
        onClose={onCloseEditModal}
        identityDetailState={identityDetailState}
      />
      <TrackModal
        dntState={dntDeleteState}
        openTrackModal={dntDeleteState.openTrackModal}
        commonState={commonState}
        handleIdentityChanged={handleSelectedIdentityChange}
      />
      <DoNotTrackModal
        dntState={dntDeleteState}
        openDoNotTrackDeleteModal={dntDeleteState.openDoNotTrackDeleteModal}
        commonState={commonState}
        handleIdentityGridChange={handleSelectedIdentityChange}
      />
      <DeleteUser
        dntState={dntDeleteState}
        commonState={commonState}
        handleIdentityGridChange={handleSelectedIdentityChange}
      />
    </>
  );
};
