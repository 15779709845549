'use strict';
import { BundleFlag } from '../../../_reactivtrak/src/common/enums/BundleFlag';
var analyticEventServiceFunctions = require('_app/serviceFunctions/analyticEventServiceFunctions').analyticEventServiceFunctions;

var integrationsLogos = {
    salesforce: require('/app/images/integrations/salesforce.png'),
    azureAD: require('/app/images/integrations/Azure-AD-logo.png'),
    googleCalendar: require('/app/images/integrations/Google-Calendar-logo.png'),
    outlookCalendar: require('/app/images/integrations/outlook-calendar-logo.png'),
    hrdc: require('/app/images/integrations/hrdc-logo.png')
};

var integrationsExamples = {
    salesforce: require('/app/images/integrations/salesforce_sample.png'),
    azureAD: require('/app/images/integrations/azure_sample.png'),
    googleCalendar: require('/app/images/integrations/GCal-card-content.png'),
    outlookCalendar: require('/app/images/integrations/outlook-calendar-sample.png'),
    hrdc: require('/app/images/integrations/hrdc_sample.png')
};

var highestPlan = require('../../../_reactivtrak/src/common/constants/plans').INSIGHTS_UPGRADE_PLAN_LABEL;

angular.module('app').controller('IntegrationsCtrl', IntegrationsCtrl);

IntegrationsCtrl.$inject = [
    '$scope',
    'integrationsService',
    'authorizationService',
    'messagesService',
    '$interval'
];

function IntegrationsCtrl(
    $scope,
    integrationsService,
    authorizationService,
    msg,
    $interval
) {
    $scope.integrationsTags = {
        salesforce: 'uid_sfdc',
        salesforceLeads: 'uid_sfdclds',
        salesforceOpportunities: 'uid_sfdcopp',
        azureAD: 'uid_azad',
        googleCalendar: 'uid_gcal',
        outlookCalendar: 'uid_ocal',
        hrdc: 'uid_hrdc'
    };

    $scope.userIdInfo = undefined;
    $scope.integrationV2Enabled = authorizationService.hasFeature(BundleFlag.IntegrationV2Enabled);

    // This time integrations basic information is already loaded in the page, so we don't need to wait for the API call to finish
    $scope.integrationsV1 = [
        {
            integrationCode: $scope.integrationsTags.azureAD,
            badge: undefined,
            logo: integrationsLogos.azureAD,
            altText: 'Azure AD logo',
            title: 'User Configuration with Azure AD',
            description: 'Daily sync changes to Groups and Do Not Track list from Azure AD',

            modalTitle: 'User Configuration with Azure AD',
            modalDescription: 'Daily sync changes to Groups and Do Not Track list from Azure AD',
            modalExampleImage: integrationsExamples.azureAD,
            modalExampleAltText: 'Azure example',

            requirements:
                "Available for ActivTrak paid plans. Add users to an 'ActivTrak Do Not Track' Azure AD user group to instruct ActivTrak to add the user to the Do Not Track list. After setting up this group, click 'Integrate' to set up your Azure AD integration.",
            templateHTML: 'None',
            resourceHTML:
                '<ul><li>Learn more about <a href="https://support.activtrak.com/hc/en-us/articles/360027278492-Do-Not-Track" target="_blank" rel="noopener">Do Not Track</a>, which suspends user activity tracking and frees up ActivTrak licenses.</li><li>View the Automated User Management via Azure AD <a href="https://support.activtrak.com/hc/en-us/articles/8327818605211" target="_blank" rel="noopener">Setup Guide</a>.</li><li><a href="https://www.activtrak.com/product/integrations/activconnect/?integration" target="_blank" rel="noopener">Contact sales</a> to see the integration in action.</li></ul>',

            isBeta: undefined,
            betaSignupUrl: undefined,
            isPlanAccessible: undefined
        },
        {
            integrationCode: $scope.integrationsTags.salesforceLeads,
            badge: undefined,
            logo: integrationsLogos.salesforce,
            altText: 'Salesforce logo',
            title: 'New Deal Pipeline Leads',
            description: 'See how productivity drives individual and team-wide lead conversion',

            modalTitle: 'New Deal Pipeline Leads',
            modalDescription: 'Understand how productivity drives individual and team-wide lead conversion',
            modalExampleImage: integrationsExamples.salesforce,
            modalExampleAltText: 'Salesforce example',

            requirements: `Available for ActivTrak ${highestPlan} customers only. Uses Salesforce Leads, Users, and Tasks. ActivConnect add-on required.`,
            templateHTML: msg.get('powerBITemplate'),
            resourceHTML: `<ul>${msg.get(
                'aTSalesforceConfiguration'
            )}<li><a href="#/app/account/upgrade" target="_blank" rel="noopener">Upgrade to ${highestPlan}</a> today and get access to the Google Workspace Add-on.</li></ul>`,

            isBeta: undefined,
            betaSignupUrl: undefined,
            isPlanAccessible: undefined
        },
        {
            integrationCode: $scope.integrationsTags.salesforceOpportunities,
            badge: undefined,
            logo: integrationsLogos.salesforce,
            altText: 'Salesforce logo',
            title: 'New Deal Pipeline Opportunities',
            description: 'See how productivity drives individual and team-wide opportunity creation',

            modalTitle: 'New Deal Pipeline Opportunities',
            modalDescription: 'Understand how productivity drives individual and team-wide opportunity creation',
            modalExampleImage: integrationsExamples.salesforce,
            modalExampleAltText: 'Salesforce example',

            requirements: `Available for ActivTrak ${highestPlan} customers only. Uses Salesforce Opportunities, Users, and Tasks. ActivConnect add-on required.`,
            templateHTML: msg.get('powerBITemplate'),
            resourceHTML: `<ul>${msg.get(
                'aTSalesforceConfiguration'
            )}<li><a href="#/app/account/upgrade" target="_blank" rel="noopener">Upgrade to ${highestPlan}</a> today and get access to the Google Workspace Add-on.</li></ul>`,

            isBeta: undefined,
            betaSignupUrl: undefined,
            isPlanAccessible: undefined
        },
        {
            integrationCode: $scope.integrationsTags.outlookCalendar,
            badge: 'Early Access',
            logo: integrationsLogos.outlookCalendar,
            altText: 'Outlook Calendar logo',
            title: 'Outlook Calendar Integration',
            description:
                "Get a complete picture of employees' workdays by supplementing digital activity data with calendar data",

            modalTitle: 'Outlook Calendar Integration',
            modalDescription: msg.get('calendarHeader'),
            modalExampleImage: integrationsExamples.outlookCalendar,
            modalExampleAltText: 'Outlook Calendar example',

            requirements: msg.get('ocalIntegrationRequirementsHTML'),
            templateHTML: undefined,
            resourceHTML:
                '<ul><li>Review the <a href="https://support.activtrak.com/hc/en-us/articles/14157396964123-Offline-Meeting-Time-Beta-" target="_blank" rel="noopener">Offline Meeting Time article</a> in our Help Center to understand how calendar data will appear in your account</li><li>Follow the instructions in our <a href="https://support.activtrak.com/hc/en-us/articles/17461433216411" target="_blank" rel="noopener">Setup Guide</a> to get started.</li></ul>',

            isBeta: undefined,
            betaSignupUrl: 'https://www.activtrak.com/product/integrations/outlook-calendar/',
            isPlanAccessible: undefined
        },
        {
            integrationCode: $scope.integrationsTags.googleCalendar,
            badge: 'Early Access',
            logo: integrationsLogos.googleCalendar,
            altText: 'Google Calendar logo',
            title: 'Google Calendar Integration',
            description:
                "Get a complete picture of employees' workdays by supplementing digital activity data with calendar data",

            modalTitle: 'Google Calendar Integration',
            modalDescription: msg.get('calendarHeader'),
            modalExampleImage: integrationsExamples.googleCalendar,
            modalExampleAltText: 'Google Calendar example',

            requirements: msg.get('gcalIntegrationRequirementsHTML'),
            templateHTML: undefined,
            resourceHTML:
                '<ul><li>Review the <a href="https://support.activtrak.com/hc/en-us/articles/14157396964123-Offline-Meeting-Time-Beta-" target="_blank" rel="noopener">Offline Meeting Time article</a> in our Help Center to understand how calendar data will appear in your account</li><li>Follow the instructions in our <a href="https://support.activtrak.com/hc/en-us/articles/17461491382171" target="_blank" rel="noopener">Setup Guide</a> to get started.</li></ul>',

            isBeta: undefined,
            betaSignupUrl: 'https://www.activtrak.com/product/integrations/google-calendar/',
            isPlanAccessible: undefined
        },
        {
            integrationCode: $scope.integrationsTags.hrdc,
            badge: 'Beta',
            logo: integrationsLogos.hrdc,
            altText: 'HRDC logo',
            title: 'Automated User Management via HR Data Connector',
            description:
                'Onboard users via your employee identity system of record and keep groups and profiles current',

            modalTitle: 'Automated User Management <br />via HR Data Connector',
            modalDescription:
                'Join the beta and quickly onboard users via your employee identity system of record and keep groups and profiles current.',
            modalExampleImage: integrationsExamples.hrdc,
            modalExampleAltText: 'HRDC example',

            requirements: msg.get('hrdcIntegrationRequirements'),
            templateHTML: 'None',
            resourceHTML: msg.get('hrdcContactProductHTML'),

            isBeta: undefined,
            betaSignupUrl:
                'https://www.activtrak.com/product/integrations/automated-user-management-hr-data-connector/',
            isPlanAccessible: undefined
        }
    ];

    const excludedV1Integrations = [
        $scope.integrationsTags.outlookCalendar,
        $scope.integrationsTags.googleCalendar,
        $scope.integrationsTags.azureAD
    ];

    $scope.integrationsV2 =
        $scope.integrationsV1 &&
        $scope.integrationsV1.filter((integration) => {
            return !excludedV1Integrations.includes(integration.integrationCode);
        });

    $scope.integrations = $scope.integrationV2Enabled ? $scope.integrationsV2 : $scope.integrationsV1;

    this.$onInit = function () {
        analyticEventServiceFunctions.newEvent('View page Integrations', {});
        $scope.loadIntegrations();
        $scope.loadUserId();
        $scope.loadStates();

        $scope.loadStatesInterval = $interval(function () {
            $scope.loadStates();
        }, 5 * 60 * 1000);
    };

    $scope.$on('$destroy', function () {
        $interval.cancel($scope.loadStatesInterval);
    });

    $scope.loadIntegrations = function () {
        integrationsService.getIntegrations().then(function (response) {
            var integrationsData = response.data.integrations;
            for (var integration of $scope.integrations) {
                var integrationData = integrationsData.find(function (item) {
                    return item.integrationcode === integration.integrationCode;
                });
                if (integrationData) {
                    integration.plans = integrationData.plans;
                    integration.isBeta = integrationData.state === 'beta';
                    integration.isPlanAccessible = integrationData.isPlanAccessible;
                    integration.badge = $scope.manageBadges(integrationData.state);
                }
            }
        });
    };

    $scope.manageBadges = function (isStateData) {
        if (isStateData == 'beta') {
            return isStateData.charAt(0).toUpperCase() + isStateData.slice(1);
        }
        if (isStateData == 'ea') {
            return 'Early Access';
        }
        if (isStateData == 'ga') {
            return '';
        }
    };

    $scope.loadUserId = function () {
        integrationsService.getUserId().then(function (data) {
            $scope.userIdInfo = {
                userId: data.userId,
                companyName: data.companyName
            };

            $scope.loadSolutions();
        });
    };

    $scope.loadSolutions = function () {
        integrationsService.getSolutions($scope.userIdInfo.userId).then(function (result) {
            function findSolutionInstance(integrationCode) {
                return solutionInstances.find(function (item) {
                    return item.tags.indexOf(integrationCode) !== -1;
                });
            }
            var solutionInstances = result.data;
            for (var integration of $scope.integrations) {
                //Set solutionInstance
                var instance = findSolutionInstance(integration.integrationCode);
                if (instance) {
                    integration.solutionInstance = instance;
                }
            }

             // Reconfigure banner would be displayed if they are on v2 and have not reconfigured integrations yet
            if ($scope.integrationV2Enabled) {
                for (var tag of excludedV1Integrations) {
                    if (findSolutionInstance(tag)?.instance) {
                        $scope.showReconfigureBanner = true;
                        break; 
                    }
                }
            }
        });
    };

    $scope.loadStates = function () {
        integrationsService.getSummary().then(function (result) {
            var integrationStates = result.data;
            for (var integration of $scope.integrations) {
                //Set integrationState
                var instance = integrationStates.find(function (item) {
                    return item.integrationcode === integration.integrationCode;
                });
                if (instance) {
                    integration.integrationState = instance;
                }
            }
        });
    };

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.integrations');
    };
}
