enum RegionCodes {
  AU = 'au',
  CA = 'ca',
  EU = 'eu',
  UK = 'uk',
  US = 'us'
}

export const regionUrlSuffixes = [
  '-' + RegionCodes.AU + '.',
  '-' + RegionCodes.CA + '.',
  '-' + RegionCodes.EU + '.',
  '-' + RegionCodes.UK + '.',
  '-' + RegionCodes.US + '.'
];
