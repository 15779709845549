import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useWindowSize, WindowSize } from '../../../common/hooks';
import { breakpoints, gray0 } from '../../../common/constants';
import { useUserHealthCenterText, DONUT_BASE_CONFIG } from '../../utils';
import { ChartLabelStyle, UserHealthChartProps } from '../../models';
import { getPieChartTooltip } from '../../../common/utils/chart';

export default function UserHealthChart(props: UserHealthChartProps) {
  const { chartData, activeSinceDays, dataPercentages } = props;
  const [donutCenterTextStyle, setDonutCenterTextStyle] =
    useState<ChartLabelStyle>({ textSize: 12, lineHeight: 26 });

  const windowSize: WindowSize = useWindowSize();
  useEffect(() => {
    if (windowSize?.width) {
      //we only care about what the correct 'px' are at the required breakpoints, not customize this for how it looks anywhere in between
      if (windowSize.width >= breakpoints.xl) {
        setDonutCenterTextStyle({ textSize: 14, lineHeight: 30 });
      } else {
        setDonutCenterTextStyle({ textSize: 12, lineHeight: 26 });
      }
    }
  }, [windowSize?.width]);

  const centerText = useUserHealthCenterText(
    dataPercentages[1],
    activeSinceDays,
    donutCenterTextStyle
  );

  const options = {
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 2,
        right: 10,
        bottom: 4
      }
    },
    cutout: '78%',
    plugins: {
      legend: { ...DONUT_BASE_CONFIG.legend, display: false },
      tooltip: {
        ...DONUT_BASE_CONFIG.tooltip,
        bodyColor: gray0,
        borderWidth: 0,
        cornerRadius: 4,
        displayColors: false,
        xAlign: 'center',
        yAlign: 'center',
        backgroundColor: (tooltipItem) => {
          return tooltipItem.tooltip.labelColors[0].backgroundColor;
        },
        callbacks: {
          label: function (context) {
            let label = context.label;
            const value = context.formattedValue;

            if (!label) label = 'Unknown';

            let sum = 0;
            const dataArr = context.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += Number(data);
            });

            const percentage = ((value * 100) / sum).toFixed(0) + '%';
            return `${label} Users: ${value} (${percentage})`;
          }
        },
        enabled: false,
        external: function (context) {
          const tooltipId = 'user-health-tooltip';
          let tooltipEl = document.getElementById(tooltipId);
          const tooltipModel = context.tooltip;

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl = getPieChartTooltip(context, tooltipId);
        }
      }
    }
  };

  return (
    <>
      {chartData && centerText && (
        <Doughnut
          // Using key this way is a hacky way to get the centerText plugin to update on props change
          key={Math.random()}
          data={chartData as any}
          options={options as any}
          plugins={[centerText]}
        />
      )}
    </>
  );
}
