import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartOptions } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useLastUpdate, useWorkloadUsersState } from '../../hooks';
import { gray17, gray7 } from '../../../common/constants';
import {
  errorMsg,
  lastUpdateTooltipText,
  lastUpdateText,
  modalButtonLabel,
  modalTitle,
  noUsersMeetingCriteria,
  teamWidgetSubheader,
  widgetTitle,
  WorkloadBarChartLegendPercentages
} from '../../constants';
import {
  ErrorMsgDisplay,
  BarChartCopyDisplay,
  BaseLoadingView,
  OverallStatus,
  TemplateShadow,
  WidgetModal,
  WidgetModalList,
  WidgetDialog
} from '../templates';
import { BarChartLegend } from '../templates/BarChartLegend';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { InfoButton } from '../../../common/components/Button/InfoButton';
import { LinkWithIcon } from '../../../common/components/Link/LinkWithIcon';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import { WhiteBackgroundTooltip } from '../../../common/components/Tooltip/TextWithTooltip.styles';
import {
  ChartTitle,
  InfoOutlinedIcon,
  LastUpdatedSC,
  ModalHeader,
  ModalSubtitle
} from '../../styles/templates.styles';
import { IRedirectParams, IWeeklyUsers, WidgetProps } from '../../models';
import { getWorkloadBalanceCopy, getWorkloadSubtitleCopy } from '../../utils';
import { convertObjectToQs } from '../../../common/utils/parse/parse';

export default function WorkloadBarChart(props: Readonly<WidgetProps>) {
  const { reportFilters } = props;

  const [currentWeekUsers, setCurrentWeekUsers] = useState<IWeeklyUsers>();
  const [isCurrentWeekUsersOpen, setIsCurrentWeekUsersOpen] =
    useState<boolean>(false);

  const {
    workloadData,
    isLoading,
    isTileError,
    init,
    updateChart,
    workloadUsers,
    allUsersByWeek,
    hasNoResults
  } = useWorkloadUsersState();

  const lastUpdate = useLastUpdate(workloadData?.lastUpdated);
  const primaryFiltersQuery = convertObjectToQs(reportFilters);

  useEffect(
    () => {
      init(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [init, primaryFiltersQuery]
  );

  const getCurrentWeeklyUsers = useCallback(
    (index) => {
      const currentWeeklyUsers = allUsersByWeek[index];
      setCurrentWeekUsers(currentWeeklyUsers);
    },
    [allUsersByWeek]
  );

  const handleClose = useCallback(() => {
    setIsCurrentWeekUsersOpen(!isCurrentWeekUsersOpen);
  }, [isCurrentWeekUsersOpen]);

  const workloadLevelCopy = useMemo(() => {
    return getWorkloadBalanceCopy(
      workloadData?.status?.last30Days,
      workloadData?.status?.previous30Days
    );
  }, [workloadData?.status?.last30Days, workloadData?.status?.previous30Days]);

  const workloadSubtitleCopy = useMemo(() => {
    return getWorkloadSubtitleCopy(workloadData?.status?.last30Days);
  }, [workloadData?.status?.last30Days]);

  const chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    datasets: {
      bar: {
        barPercentage: 0.9,
        categoryPercentage: 0.9
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        align: 'center',
        anchor: 'center',
        color: function (context) {
          return context.dataset.label === 'Overutilized' ? 'white' : gray17;
        },
        font: {
          size: 10,
          weight: 'bold'
        },
        formatter: (value) => {
          // toFixed() needed for proper final rendering
          return value && value > 5 ? value.toFixed() + '%' : null;
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          minRotation: 45,
          padding: -2
        }
      },
      y: {
        stacked: true,
        grid: {
          drawTicks: false
        },
        suggestedMax: 100,
        ticks: {
          callback: function (value) {
            return value + '%';
          },
          stepSize: 25,
          padding: 5
        }
      }
    },
    responsive: true,
    onClick: (event, elements) => {
      if (elements[0]) {
        const i = elements[0].index;
        getCurrentWeeklyUsers(i);
        setIsCurrentWeekUsersOpen(!isCurrentWeekUsersOpen);
      }
    },
    onHover: (event, chartElement) => {
      if (event.native.target instanceof HTMLElement) {
        event.native.target.style.cursor = chartElement[0]
          ? 'pointer'
          : 'default';
      }
    }
  };

  const tooltipBackgroundColors = [gray7, gray7, gray7, gray7];

  const params: IRedirectParams = {
    modelId: 'activinsights',
    pageId: 'workload_balance',
    subPageId: 'users',
    insightsRange: '30 day'
  };

  return (
    <TemplateShadow
      sx={{
        height: { xs: '100%', sm: '430px' }
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <WidgetLinkWrapper>
          <WidgetLinkTypography variant="h4">
            {widgetTitle.workload}
          </WidgetLinkTypography>
        </WidgetLinkWrapper>
        <Box>
          <LinkWithIcon
            text="Workload Balance"
            redirectTo={'app.reports.insightsWorkloadBalance'}
            redirectParams={params}
            disabled={hasNoResults}
            id="0b92f0c4-80dc-4acc-a3e7-95a0211cda02"
          />
        </Box>
      </Stack>
      {!isLoading && !isTileError && (
        <>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'center', sm: 'left' }}
          >
            <Box
              sx={{
                textAlign: { xs: 'center', sm: 'left' },
                width: { xs: 'auto', sm: '45%' }
              }}
            >
              <OverallStatus
                header={workloadData?.status?.last30Days}
                subheader={teamWidgetSubheader.workload}
                tooltipLegend={{
                  items: WorkloadBarChartLegendPercentages,
                  colors: tooltipBackgroundColors,
                  subtitle:
                    "Team Workload is an assessment based on the distribution of team members' utilization levels calculated as follows:"
                }}
                headerFontSize="40px"
                noResults={hasNoResults}
              />
              {!hasNoResults && workloadLevelCopy && (
                <BarChartCopyDisplay widgetCopy={workloadLevelCopy} />
              )}
              {/* do not refactor WidgetModal usage into another common component */}
              <WidgetModal
                buttonLabel={modalButtonLabel.workload}
                id="457be0d6-910f-41c4-af8d-36eb7fa816c1"
                isDisabled={hasNoResults}
              >
                <ModalHeader variant="h6">{modalTitle.workload}</ModalHeader>
                {workloadSubtitleCopy && (
                  <ModalSubtitle>{workloadSubtitleCopy}</ModalSubtitle>
                )}
                {!workloadUsers || workloadUsers.length === 0 ? (
                  <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
                ) : (
                  <WidgetModalList data={workloadUsers} />
                )}
              </WidgetModal>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                width: { xs: '100%', sm: '55%' }
              }}
            >
              <Box sx={{ marginTop: '3px', marginBottom: '-6px' }}>
                <ChartTitle variant="h5" sx={{ display: 'inline' }}>
                  Utilization trends
                </ChartTitle>
                <WhiteBackgroundTooltip
                  placement="top"
                  title="
                        Each bar gives the percentage of users in each
                        utilization category for the full week starting with the
                        date shown"
                  enterTouchDelay={0}
                >
                  <IconButton sx={{ marginBottom: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </WhiteBackgroundTooltip>
              </Box>
              <Box sx={{ width: '100%', height: 280 }}>
                <Chart
                  type="bar"
                  data={workloadData.chartData}
                  options={chartOptions}
                  plugins={[ChartDataLabels as any]}
                  id="829b887d-3035-4705-a0fa-3d64a423cace"
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <BarChartLegend
                  widgetData={workloadData}
                  hasNoResults={hasNoResults}
                  id="860b9973-a249-4007-b034-4d7f518c7faf"
                  updateChart={updateChart}
                />
              </Box>
            </Box>
          </Stack>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
              <InfoButton tooltipText={lastUpdateTooltipText} />
            </LastUpdatedSC>
          )}
          {/* do not refactor WidgetDialog usage into another common component */}
          <WidgetDialog isOpen={isCurrentWeekUsersOpen} onClose={handleClose}>
            <Box sx={{ minWidth: '400px' }}>
              <ModalHeader variant="h6">{modalTitle.workload}</ModalHeader>
              {currentWeekUsers?.weekOf && (
                <ModalSubtitle>{currentWeekUsers.weekOf}</ModalSubtitle>
              )}
              {!currentWeekUsers || currentWeekUsers.users?.length === 0 ? (
                <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
              ) : (
                <WidgetModalList data={currentWeekUsers?.users} />
              )}
            </Box>
          </WidgetDialog>
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
