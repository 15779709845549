import { Role, RoleAccessKeys } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsClassificationId: IRoute = {
  name: 'app.settings.classification.id',
  stateDefinition: {
    url: '/{type}/{id}',
    data: { pageTitle: 'Classifications' },
    params: {
      id: null,
      type: null
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.SupportIntermediate]
  }
};

export default appSettingsClassificationId;
