import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';
import { DetailsTreeView, UnlockUserButton, VerifyAccountButton } from '.';
import { TabContent } from './templates/TabContent';
import { DataCollectionToggle } from './DataCollectionToggle';
import { TAB_LABELS } from '../constants';
import { TabContentDiv, TabPanel } from '../styles/supportPortal.styles';
import { ITabChangeEvent, ISupportControlValues } from '../models/IRandomTypes';
import { IFullyMappedAccountDetails } from '../models/IMappedDetails';
import { IAccountCredentials } from '../models/IAccountData';

interface IDetailsPanelProps {
  accountDetails: IFullyMappedAccountDetails;
  accountCredentials: IAccountCredentials;
  setDetailsModalClose: () => void;
  supportControlValues: ISupportControlValues;
}

export const DetailsPanel = (props: IDetailsPanelProps) => {
  const {
    accountCredentials,
    accountDetails,
    setDetailsModalClose,
    supportControlValues
  } = props;

  const { email, id, isImpersonating, tokenData } = accountCredentials;
  const { dataCollection, verified } = supportControlValues;

  const [tabValue, setTabValue] = useState<number>(0);

  const authorizationService = useAuthorization();
  const isSuperAdminOrAdvanced: boolean =
    authorizationService.hasAnyRole([Role.SuperAdmin, Role.SupportAdvanced]) &&
    !isImpersonating;

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  const handleChange = (event: ITabChangeEvent, newValue: number) => {
    event.preventDefault();
    setTabValue(newValue);
  };

  return (
    <TabPanel>
      <Tabs
        selectionFollowsFocus
        orientation="vertical"
        variant="fullWidth"
        value={tabValue}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        {TAB_LABELS.map(({ index, label }) => (
          <Tab
            key={index}
            label={label}
            {...a11yProps(index)}
            sx={{ padding: '16px' }}
          />
        ))}
      </Tabs>
      <Divider orientation="vertical" flexItem />
      <TabContentDiv role="tabpanel">
        {TAB_LABELS.filter(({ index }) => index !== 6).map(
          ({ index, label }) => (
            <TabContent key={index} value={tabValue} index={index}>
              <DetailsTreeView accountInfo={accountDetails[`${label}`]} />
            </TabContent>
          )
        )}
        <TabContent value={tabValue} index={6}>
          <Stack direction="column" pl={2.5}>
            {isSuperAdminOrAdvanced && (
              <DataCollectionToggle
                value={dataCollection}
                accountId={id}
                token={tokenData.token}
                setDetailsModalClose={setDetailsModalClose}
              />
            )}
            <VerifyAccountButton
              value={verified}
              accountId={id}
              setDetailsModalClose={setDetailsModalClose}
            />
            <UnlockUserButton
              username={email}
              setDetailsModalClose={setDetailsModalClose}
            />
          </Stack>
        </TabContent>
      </TabContentDiv>
    </TabPanel>
  );
};
