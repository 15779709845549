import React, { useEffect, useState } from 'react';
import { useUIRouterState } from '../../../hooks/useUIRouterHistory';
import {
  REPORT_PAGE_MODE_MAPPED,
  ReportPageModeLabels,
  ReportPageModeTypes
} from '../constants/reportPageModeToggle.constants';
import { setReportPageMode } from '../stores/reportPageModeStore';
import { useReportPageModeStore } from '../hooks/useReportPageModeToggleStore';
import { useReportFilterStore } from '../hooks/reportFiltersStore';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

type ReportPageModeToggleProps = {
  onChange?: (pageMode: ReportPageModeTypes) => void;
  isDisabled?: boolean;
};

export const ReportPageModeToggle = (props: ReportPageModeToggleProps) => {
  const { onChange, isDisabled = false } = props;

  const [userModeSelection, setUserModeSelection] = useState({
    label: ReportPageModeLabels.Users,
    value: ReportPageModeTypes.Users
  });

  //if there is a 'groupBy' setting from the browser url, update the pageMode store with it
  const { groupBy } = useUIRouterState<{ groupBy: string }>().params;
  if (groupBy) setReportPageMode(REPORT_PAGE_MODE_MAPPED[groupBy]);

  const pageMode = useReportPageModeStore((s) => s.pageMode);
  const usersReportFilter = useReportFilterStore((s) => s.users);
  const userMode = usersReportFilter[0]?.filterMode;

  useEffect(() => {
    //All of this is to set the values for the shared Users/Computers button
    setUserModeSelection(
      userMode === 'computers'
        ? {
            label: ReportPageModeLabels.Computers,
            value: ReportPageModeTypes.Computers
          }
        : {
            label: ReportPageModeLabels.Users,
            value: ReportPageModeTypes.Users
          }
    );

    if (pageMode !== ReportPageModeTypes.Groups) {
      setReportPageMode(
        userMode === 'computers'
          ? ReportPageModeTypes.Computers
          : ReportPageModeTypes.Users
      );
    }
  }, [pageMode, userMode]);

  const handleToggleChange = (
    e: React.MouseEvent<HTMLElement>,
    value: ReportPageModeTypes
  ) => {
    if (value !== null) {
      setReportPageMode(value);
      if (onChange) onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={pageMode}
      exclusive
      onChange={handleToggleChange}
      disabled={isDisabled}
      aria-label="Report Group By"
      sx={{ mr: 2 }}
    >
      <ToggleButton
        value={userModeSelection.value}
        id={`cb1908e6-1624-4bad-81a1-81f4f51784a1-${userModeSelection.value}`}
      >
        {userModeSelection.label}
      </ToggleButton>
      <ToggleButton
        value={ReportPageModeTypes.Groups}
        id={`cb1908e6-1624-4bad-81a1-81f4f51784a1-${ReportPageModeTypes.Groups}`}
      >
        {ReportPageModeLabels.Groups}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
