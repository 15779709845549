export enum ReportType {
  WorkingHours = 'WorkingHours',
  ScheduleAdherence = 'ScheduleAdherence',
  TopUsersAndGroups = 'TopUsers',
  TopApplications = 'TopApplications',
  TopWebsites = 'TopWebsites',
  ActivityLog = 'ActivityLog',
  SecurityAuditLog = 'securityAuditLog', // need to confirm
  AlarmLog = 'AlarmLog',
  ScreenshotsHistory = 'Screenshots',
  VideosHistory = 'videosHistory' // need to confirm
}
