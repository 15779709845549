import { createStore } from 'zustand/vanilla';

import { ACCOUNT_SETTINGS_STORE_DEFAULT } from './accountSettingsStore.constants';
import {
  IAccountSettings,
  IAccountSettingsStore
} from './accountSettingsStore.models';

//TBD: child account info may require a separate state
export const accountSettingsStore = createStore<IAccountSettingsStore>(
  (set) => ({
    ...ACCOUNT_SETTINGS_STORE_DEFAULT,
    reset: () => {
      set(ACCOUNT_SETTINGS_STORE_DEFAULT);
    }
  })
);

export const getAccountSettings = () => {
  return accountSettingsStore.getState().accountSettings;
};

export const getAccountSetting = (setting: keyof IAccountSettings): any => {
  return accountSettingsStore.getState().accountSettings[setting];
};

export const setAccountSettings = (newSettings: Partial<IAccountSettings>) => {
  const currentSettings = accountSettingsStore.getState().accountSettings;
  const combinedSettings = { ...currentSettings, ...newSettings };
  accountSettingsStore.setState({ accountSettings: combinedSettings });
};

export const getAccountId = () => {
  const { account } = getAccountSettings();
  return account;
}