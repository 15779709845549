import { useCallback, useState } from 'react';
import { fetchData, postData, putData } from '../../common/helpers';
import { useNotifications } from '../../common/services/Notifications';
import {
  ICoreCategory,
  ICoreCategoriesState,
  IBulkPreprocess
} from '../models';
import FileSaver from 'file-saver';

export const useCoreCategoriesState = (): ICoreCategoriesState => {
  const notificationService = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coreCategories, setCoreCategories] = useState<ICoreCategory[]>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const fetchCoreCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchData<ICoreCategory[]>({
        path: '/api/insights/categories/core'
      });
      data.forEach((datum) => {
        let categoryNames = datum.categories.map((c) => c.category).join(', ');
        if (categoryNames.length > 80) {
          categoryNames = categoryNames.substring(0, 80) + '...';
        }
        datum.categoryNames = categoryNames;
      });
      setCoreCategories(data);
    } catch (error) {
      notificationService.error(
        'Error fetching Activity Alignment configurations'
      );
      console.error('Error fetching Activity Alignment configurations', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    fetchCoreCategories();
  }, [fetchCoreCategories]);

  const createCoreCategory = useCallback(async (payload: ICoreCategory) => {
    setIsLoading(true);
    try {
      await postData<void>({
        path: '/api/insights/categories/core',
        args: payload
      });
    } catch (error) {
      console.error(
        'ActivTrak Error: Unable to create Activity Alignment configuration',
        error
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const editCoreCategory = useCallback(async (payload: ICoreCategory) => {
    setIsLoading(true);
    try {
      await putData<void>({
        path: '/api/insights/categories/core',
        args: payload
      });
    } catch (error) {
      console.error(
        'ActivTrak Error: Unable to edit Activity Alignment configuration',
        error
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteCoreCategories = useCallback(async (payload: ICoreCategory[]) => {
    setIsLoading(true);
    const groupIds = payload.map((cc) => cc.groupId);
    try {
      await postData<void>({
        path: '/api/insights/categories/core/removelist',
        args: groupIds
      });
    } catch (error) {
      console.error(
        'ActivTrak Error: Unable delete Activity Alignment configurations',
        error
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const downLoadExcelTemplate = useCallback(async () => {
    setIsDownloading(true);
    try {
      const data = await fetchData<ArrayBuffer>({
        path: '/api/insights/categories/core/download',
        ...{ responseType: 'arraybuffer' }
      });
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      FileSaver.saveAs(blob, 'core-categories-template.xlsx');
    } catch (error) {
      console.error('Error downloading core categories Excel template:', error);
      throw error;
    } finally {
      setIsDownloading(false);
    }
  }, []);

  const uploadExcelFile = useCallback(
    async (
      file: File,
      onUploadProgress: (progress: number) => void,
      signal: AbortSignal
    ) => {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      try {
        const config = {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onUploadProgress(progress);
          },
          signal
        };
        return await postData<IBulkPreprocess>({
          path: '/api/insights/categories/core/upload',
          args: formData,
          ...config
        });
      } catch (error) {
        if (error.name === 'CanceledError') {
          console.warn('Upload cancelled');
        } else {
          console.error('Error uploading core categories Excel file:', error);
          throw error;
        }
      } finally {
        setIsUploading(false);
      }
    },
    []
  );

  const createBulkCoreCategories = useCallback(async (payload: string) => {
    setIsLoading(true);
    try {
      await postData<void>({
        path: '/api/insights/categories/core/processfile',
        args: { tableName: payload }
      });
    } catch (error) {
      console.error('Error: Unable to bulk create core categories', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return <ICoreCategoriesState>{
    init,
    isLoading,
    coreCategories,
    createCoreCategory,
    editCoreCategory,
    deleteCoreCategories,
    downLoadExcelTemplate,
    isDownloading,
    isUploading,
    uploadExcelFile,
    createBulkCoreCategories
  };
};
