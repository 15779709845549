import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { blue9, gray1 } from '../../../common/constants';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ExcelIcon } from '../../../common/assets/Icons/ExcelIcon';

type OwnProps = {
  isDownloading: boolean;
  isUploading: boolean;
  handleDownload: () => void;
  hasDownloadError: boolean;
};

const BulkDownload: React.FC<OwnProps> = ({
  isDownloading,
  isUploading,
  handleDownload,
  hasDownloadError
}) => {
  return (
    <Box height="104px" marginTop="24px" padding="18px" bgcolor={gray1}>
      <Box display="flex" alignItems="center">
        <Box sx={{ marginRight: '8px' }}>
          <ExcelIcon />
        </Box>
        {isDownloading ? (
          <CircularProgress size={12} color="primary" />
        ) : (
          <Typography color={blue9} marginTop="-4px" marginRight="2px">
            {isUploading ? (
              <strong>Download template here</strong>
            ) : (
              <Link onClick={handleDownload}>
                <strong>Download template here</strong>
              </Link>
            )}
          </Typography>
        )}
        {hasDownloadError && (
          <Box
            display="flex"
            alignItems="center"
            sx={{ margin: '-4px 0 0 2px' }}
          >
            <WarningAmberIcon fontSize="small" color="error" />
            <Typography color="error" marginLeft="2px">
              Error downloading template. Please try again.
            </Typography>
          </Box>
        )}
      </Box>
      <Typography>
        Download the template to define Core Categories for multiple teams by
        selecting the role that best matches each team.
      </Typography>{' '}
    </Box>
  );
};

export default BulkDownload;
