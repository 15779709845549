import moment from 'moment';
import { getDateSettingsStore } from '../../stores/dateSettingsStore';

/**
 * Returns true if the provided date is today.
 */
export const isToday = (dateToCompare: string): boolean => {
  const compareDate = moment(dateToCompare);
  const today = moment();

  return compareDate.isSame(today, 'day');
};

/**
 * Returns true if the provided date is today in the account's timezone.
 */
export const isTodayByTimezone = (dateToCompare: string): boolean => {
  const { timezoneKey } = getDateSettingsStore();
  const compareDate = moment.tz(dateToCompare, timezoneKey);
  const today = moment.tz(timezoneKey);

  return compareDate.isSame(today, 'day');
};
