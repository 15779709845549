import { useState, useCallback } from 'react';
import moment from 'moment';
import ClassificationService from './ClassificationService';
import { sorter } from '../../common/utils/sorter/sorter';
import {
  withCategoryNames,
  mapToClassificationActivity,
  getActivityUniqueId
} from '../../common/helpers/classifications';
import { ICategory } from '../../common/models/ICategory';
import { IClassificationActivity } from '../../common/models/IClassificationActivity';
import { ActivityType } from '../../common/enums/ActivityType';
import { IActivityStoreState, IClassificationStore } from '../models';
import { newEvent } from '../../common/analytics/eventHeap';
import { find } from 'lodash';
import { RecentActivitiesProps } from '../../common/models/RecentActivitiesProps';

export const initialActivitiesState: IActivityStoreState = {
  activities: [],
  totalActivities: 0,
  currentActivityIndex: 0,
  currentFilter: null,
  currentSearchValue: '',
  showAllActivities: false,
  isSearching: false,
  isClassified: false,
  isLoading: true,
  isTileError: false,
  error: '',
  categories: []
};

type eventPropertiesType = {
  Activity: string;
  CategoryName: string;
  ProductivityName: string;
  IsBulkUpdate: boolean;
  TimeStamp: string;
};

//making the store a hook because the api 'baseUrl' cannot be detected unless it is instantiated in a hook or class - SOLVE?
//would prefer to export just a basic state object to avoid looping issues caused by hook dependencies in useEffect
export const useClassificationsStore = (): IClassificationStore => {
  const classificationService = new ClassificationService();

  const [originalActivities, setOriginalActivities] =
    useState<IClassificationActivity[]>(null);

  const [activitiesState, setActivitiesState] = useState(
    initialActivitiesState
  );

  const { activities, isClassified } = activitiesState;

  /* BEGIN REDUCERS - global state needs to be updated thru reducers in order to trigger a re-render of all children that use it */
  const setActivities = (newActivities) => {
    setActivitiesState((prevState) => ({
      ...prevState,
      ...{ activities: newActivities }
    }));
  };

  const setTotalActivities = (count) => {
    setActivitiesState((prevState) => ({
      ...prevState,
      ...{ totalActivities: count }
    }));
  };

  const setCategories = (categories: string[]) => {
    setActivitiesState((prevState) => ({
      ...prevState,
      ...{ categories: categories }
    }));
  };

  const resetActivities = () => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ activities: [] }
    }));

    setOriginalActivities([]);
  };

  const setCurrentSearchValue = useCallback((term) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ currentSearchValue: term }
    }));
  }, []);

  const setIsSearching = (searching: boolean) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ isSearching: searching }
    }));
  };

  const setIsLoading = (loading: boolean) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ isLoading: loading }
    }));
  };

  const setIsTileError = (isError: boolean) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ isTileError: isError }
    }));
  };

  const setError = (error) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ error: error }
    }));
  };

  const setCurrentFilter = useCallback((filter) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ currentFilter: filter }
    }));
  }, []);

  const setIsClassified = useCallback((classified: boolean) => {
    setActivitiesState((prevState: IActivityStoreState) => ({
      ...prevState,
      ...{ isClassified: classified }
    }));
  }, []);
  /* END REDUCERS */

  //Wrapping functions with useCallback keeps them from causing infinite loops when used as hook dependencies
  const getRecentActivities = useCallback(
    async (
      categories: ICategory[],
      params?: Partial<RecentActivitiesProps>
    ) => {
      setIsSearching(
        (params?.term && params?.term !== '') ||
          (params?.categories && params.categories.length > 0)
      );
      setIsLoading(true);
      resetActivities();

      try {
        const response = await classificationService.getRecentActivities(
          params
        );

        const resultWithCategoryNames = withCategoryNames(
          response.activities,
          categories
        );

        setActivities(resultWithCategoryNames);
        setOriginalActivities(resultWithCategoryNames);

        const totalActivities = response?.count || 0;
        setTotalActivities(totalActivities);

        //OPTIONAL USAGE: use this version of the data ONLY when a separate local copy is needed
        return resultWithCategoryNames;
      } catch (error) {
        setIsTileError(true);
        setError('Unable to Retrieve Activities');
      } finally {
        setIsLoading(false);
      }
    },
    // disabling eslint because using this as dependency with dependencies causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getActivityById = async (
    id: number,
    type: ActivityType
  ): Promise<IClassificationActivity> => {
    const activityById = find(
      activitiesState.activities,
      (a) => a.uniqueId === getActivityUniqueId(id, type)
    );

    return (
      activityById ?? (await classificationService.getActivityById(id, type))
    );
  };

  const sendUpdateEvent = (eventProps: eventPropertiesType) => {
    try {
      newEvent('Classification Updated', eventProps);
    } catch (error) {
      console.error(`
      Cannot send heap event: Classification Updated: ${eventProps}
      Error: ${error}
      `);
    }
  };

  const updateActivities = async (
    selectedItems: IClassificationActivity[],
    isBulkUpdate = false
  ) => {
    if (selectedItems?.length) {
      try {
        const response = await classificationService.updateActivity(
          selectedItems
        );
        const updatedItems = await response?.data.map(
          mapToClassificationActivity
        );
        if (updatedItems?.length) {
          //heap event date
          const date = moment(new Date()).format('MMMM DD, YYYY kk:mm:ss');

          const currentActivities = activities;
          const backupActivities = originalActivities;
          if (!currentActivities) {
            return response;
          }

          updatedItems.forEach((a) => {
            const i = activities.findIndex(
              (activity) => a.uniqueId === activity.uniqueId
            );
            if (i === -1) {
              return;
            }

            //update totalActivities based on status change
            if (currentActivities[i].status !== a.status) {
              if (a.status.includes('Manual')) {
                /*
                if the status has changed to manual classification
                - pending: decrease the total
                - classified: don't update totalActivities since previously classified.
                */
                if (!isClassified) {
                  setActivitiesState((prevState) => ({
                    ...prevState,
                    ...{ totalActivities: --prevState.totalActivities }
                  }));
                } else if (
                  !currentActivities[i].status.includes('Pending') &&
                  a.status.includes('Pending')
                ) {
                  /*
                else if the status has changed back to pending (reset)
                - pending: increase the total
                - classified: decrease the total
                */
                  if (isClassified) {
                    setActivitiesState((prevState) => ({
                      ...prevState,
                      ...{ totalActivities: --prevState.totalActivities }
                    }));
                  } else {
                    setActivitiesState((prevState) => ({
                      ...prevState,
                      ...{ totalActivities: ++prevState.totalActivities }
                    }));
                  }
                }
              }
            }

            //the entire object should be replaced, but the API only returns new categoryId, productivity and status values
            currentActivities[i].categoryId = a.categoryId;
            currentActivities[i].localCategoryId = a.localCategoryId;
            currentActivities[i].productivity = a.productivity;
            currentActivities[i].localProductivity = a.localProductivity;
            currentActivities[i].status = a.status;
            currentActivities[i].statusDisplay = a.statusDisplay;
            currentActivities[i].disabled = false;
            currentActivities[i].categoryName = a.categoryName;

            if (backupActivities) {
              backupActivities[i].categoryId = a.categoryId;
              backupActivities[i].localCategoryId = a.localCategoryId;
              backupActivities[i].productivity = a.productivity;
              backupActivities[i].localProductivity = a.localProductivity;
              backupActivities[i].status = a.status;
              backupActivities[i].statusDisplay = a.statusDisplay;
              backupActivities[i].disabled = false;
              backupActivities[i].categoryName = a.categoryName;
            }

            //heap event
            const eventProps: eventPropertiesType = {
              Activity: a.name,
              CategoryName: a.categoryName,
              ProductivityName: a.productivityName,
              IsBulkUpdate: isBulkUpdate,
              TimeStamp: date
            };
            sendUpdateEvent(eventProps);
          });

          setActivities(currentActivities);
          setOriginalActivities(backupActivities);
        } else {
          setDisabledActivities(selectedItems);
          return response;
        }
      } catch (error) {
        setError(`Unable to Update Activities`);
        setDisabledActivities(selectedItems);
        throw error;
      }
    }
  };

  const sortActivities = useCallback(
    (newOrderDirection, orderBy) => {
      const sortedData = sorter(activities, newOrderDirection, orderBy);
      setActivities(sortedData);
    },
    [activities]
  );

  const setSelectedActivities = useCallback(
    (selectedItems) => {
      const resetData = activities.map((item) => {
        return { ...item, selected: false };
      });
      selectedItems.forEach((a) => {
        const i = resetData.findIndex(
          (activity) => a.uniqueId === activity.uniqueId
        );
        if (i === -1) {
          return;
        }
        resetData[i] = a;
      });

      setActivities(resetData);
    },
    [activities]
  );

  const setDisabledActivities = useCallback(
    (selectedItems, isDisabled = false) => {
      const resetData = activities.map((item) => {
        return { ...item, disabled: false };
      });

      if (isDisabled) {
        selectedItems.forEach((a) => {
          const i = resetData.findIndex(
            (activity) => a.uniqueId === activity.uniqueId
          );
          if (i === -1) {
            return;
          }
          a.disabled = isDisabled;
          resetData[i] = a;
        });
      }

      setActivities(resetData);
    },
    [activities]
  );

  const setActivitiesError = useCallback((error) => {
    setError(error);
  }, []);

  const resetActivitiesError = useCallback(() => {
    setError('');
  }, []);

  return {
    activitiesState,
    setIsClassified,
    getRecentActivities,
    getActivityById,
    updateActivities,
    setCategories,
    setCurrentSearchValue,
    setCurrentFilter,
    sortActivities,
    setSelectedActivities,
    setDisabledActivities,
    setActivitiesError,
    resetActivitiesError,
    //this is the base setState. TODO: replace Store individual setState functions with this where possible
    setActivitiesState
  };
};
