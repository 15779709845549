import { Dispatch, SetStateAction } from 'react';
import { IBaseIntegrationConfigurationState } from './IBaseIntegrationConfigurationState';
import {
  IntegrationCodes,
  IntegrationTypeCodes
} from '../constants/IntegrationCodes';

export interface IIntegration extends IIntegrationDetail {
  isPlanAccessible: boolean;
  planNames: string[];
  integrationcode: IntegrationCodes;
  error: IIntegrationError;
  callToAction: CallToActionType;
  isIntegrationEnabled: boolean;
  integrationConfigurations: IIntegrationConfigurationDto[];
  hasConfigurations: boolean;
  badge: string;
  integrationState: IIntegrationStateDto;
  stateType: IntegrationStateType;
  disposition: DispositionType;
  traySolutionInstance: string;
  trayEnabled: boolean;
}

export enum DispositionType {
  ga = 'GA',
  beta = 'Beta'
}

export enum CallToActionType {
  none = 'None',
  integrate = 'Integrate',
  learnMore = 'Learn More',
  upgrade = 'Upgrade'
}
//upper case to match db values
export enum IntegrationStateType {
  None = 0,
  Deleted = 1,
  Error = 2,
  Idle = 4,
  Paused = 8,
  Running = 16,
  Waiting = 32,
  NoFetchWarning = 64
}

export interface IIntegrationSummaryDto {
  id: number;
  integrationcode: IntegrationCodes;
  integrationname: string;
  integrationlabel: string;
  disposition: string;
  createddate: string;
  changeoverdate: string;
  state: string;
  statemessage: string;
  errorcode: number;
  updatedtime: string;
  isPlanAccessible: boolean;
  integrationConfigurations: IIntegrationConfigurationDto[];
}

export interface IIntegrationConfigurationDto {
  integrationcode: string;
  instanceid: string;
  name: string;
  value: string;
  typecode: IntegrationTypeCodes;
  creationdate?: string;
}
export interface IIntegrationDetailDto {
  id: number;
  integrationcode: string;
  integrationname: string;
  integrationlabel: boolean;
  state: string;
  changeoverdate: string;
}

export interface IIntegrationPlanDto {
  integrationcode: string;
  plannames: string[];
  isPlanAccessible: boolean;
}

export interface IIntegrationStateDto {
  integrationcode: string;
  acctid: string;
  state: string;
  statemessage: string;
  stateType: IntegrationStateType;
  errorcode: number;
  updatedtime: string;
}

export interface IIntegrationDetails {
  [key: string]: IIntegrationDetail;
}
export interface IIntegrationDetail {
  integrationName: string;
  runsOnSchedule: boolean;
  logo: any;
  altText: string;
  title: string;
  description: string;
  modalTitle: string;
  modalDescription: string;
  modalExampleImage: string;
  modalExampleAltText: string;
}

export type ConfigModalProps = {
  setOpenConfigDialog: Dispatch<SetStateAction<boolean>>;
  configState: IBaseIntegrationConfigurationState;
};

export type ConfigMergeModalProps = {
  setMergeInitiateConfig: Dispatch<SetStateAction<() => void>>;
  setMergeIsReady: Dispatch<SetStateAction<boolean>>;
  showIntegrateButton: boolean;
  baseModalProps: ConfigModalProps;
};

export interface IIntegrationErrors {
  [key: number]: IIntegrationError;
}
export interface IIntegrationError {
  errorMessage: string;
  showContactSupport: boolean;
}

export type IntegrationConfigPayload = {
  instanceId?: string;
  tenantId?: string;
  clientId?: string;
  clientSecret?: string;
  domain?: string;
};

export type AboutModalProps = {
  description: string;
  exampleImage: string;
  exampleAltText: string;
};

export enum IntegrationStateErrorCodes {
  None = 0,
  APIAuthenticationError = 1000,
  GetUserFailed = 2000,
  CreateUserFailed = 2100,
  DeleteIntegrationFailed = 3000,
  EnableIntegrationFailed = 3100,
  DisableIntegrationFailed = 3200,
  CreateIntegrationFailed = 3300,
  GetIntegrationFailed = 3400,
  GetIntegrationListFailed = 3500,
  GetAccountFailed = 4000,
  AzureFetch = 5000,
  GCalFetch = 5002,
  OCalFetch = 5003,
  CalendarTokenExpired = 5005,
  CalendarPermissionDenied = 5006,
  NoFetchWarning48HoursSync = 6444,
  ConfigurationAuthorizationFailed = 8000,
  MissingField = 8100,
  GroupBucketFailed = 8200,
  Uncategorized = 9000,
  TwoDaySyncTimeout = 9100
}

export type ISolutionDto = {
  instance: string;
  enabled: boolean;
  tags: string[];
};
export type ITrayUserDto = {
  userId: string;
  companyName: string;
};

export type IMergeLinkDto = {
  link_token: string;
  integration_name: string;
  magic_link_url: string;
};

export type IMergeLinkedAccountsDto = {
  id: string;
  integration: { name: string };
};
