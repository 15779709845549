import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import contentBlockModalControllerTemplate from 'views/modals/contentBlockModal.html?raw';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

// Register report component
import './auditLog.component.js';

angular.module('app').controller('AuditLogCtrl', AuditLogCtrl);

AuditLogCtrl.$inject = [
    '$scope',
    '$timeout',
    'messagesService',
    'authorizationService',
    'auditLogService',
    '$rootScope',
    'auditLogSampleService',
    'pageSizeService',
    'customUibModal',
    'exportInfoService',
    'gridPropertiesService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions'
];

function AuditLogCtrl(
    $scope,
    $timeout,
    msg,
    authorizationService,
    auditLogService,
    $rootScope,
    auditLogSampleService,
    pageSizeService,
    customUibModal,
    exportInfoService,
    gridPropertiesService,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions
) {
    $scope.isSmallWindow = window.innerWidth < 992;
    $scope.showAuditLog =
        authorizationService.hasFeature('isAuditAlarmEnabled') ||
        authorizationService.hasRole([
            authorizationService.roles.supportAdvanced,
            authorizationService.roles.superAdmin
        ]);
    let cacheKey;
    $scope.reportFilters = {};

    $scope.formatDate = function (date) {
        return atHelperFunctions.formatDate(date);
    };

    $scope.canUpgrade = authorizationService.hasRouteByName('app.account.upgrade');

    const noRecordsTemplate = kendo.template(
        '<div ng-show="!isLoading && noResults" class="col-md-12 center-block m-t-20">' +
            '<strong>No Results</strong><br />' +
            '<span>Data was not available to generate this report.</span><br />' +
            '<div class="hidden-xs hidden-sm" ng-hide="usedLicenses">' +
            '<a ng-click="download()">Download the Agent</a> and install on a target computer to begin collecting data.' +
            '</div>' +
            '</div>'
    );

    $scope.noResults = false;
    $scope.isLoading = false;

    const accountSettings = getAccountSettings();
    $scope.usedLicenses = accountSettings.usedLicenses;

    $scope.auditDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const service = $scope.showAuditLog ? auditLogService : auditLogSampleService;
                $scope.isLoading = true;

                const args = generateParameters($scope.reportFilters, {});
                service
                    .getLog(args, {
                        params: options.data
                    })
                    .success(function (result) {
                        $scope.isLoading = false;
                        $scope.noResults = !(result.data && result.data.length > 0);
                        $rootScope.$broadcast('setGoogleExportParameters', {
                            columns: result.columns,
                            total: result.total,
                            calculateHere: true
                        });
                        cacheKey = result.cacheKey;
                        options.success(result);
                    })
                    .error(function (error) {
                        $scope.isLoading = false;
                        $scope.noResults = true;
                        options.error(error);
                        $scope.$emit('showNotification', {
                            message: 'Error loading the Audit Log data.',
                            color: 'danger'
                        });
                        console.error(`ActivTrak Error: Error loading the Audit Log data.`, error);
                    });
            }
        },
        pageSize: pageSizeService.loadPageSize('audit', 20),
        serverPaging: $scope.showAuditLog,
        serverSorting: $scope.showAuditLog,
        serverFiltering: $scope.showAuditLog,
        sort: {
            field: 'time',
            dir: 'asc'
        },
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    $scope.pagerOptions = {
        dataSource: $scope.auditDataSource,
        refresh: true,
        pageSizes: [10, 20, 30, 40, 50, 100, 500, 1000],
        buttonCount: 5,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'entries'),
            display: msg.get('itemsDisplay', 'entries'),
            empty: msg.get('noItemsToDisplay', 'entries')
        }
    };

    $scope.showActionObject = function (entry) {
        customUibModal.open({
            animation: false,
            windowClass: 'centered-modal',
            template: contentBlockModalControllerTemplate,
            controller: 'contentBlockModalController',
            resolve: {
                data: {
                    content: JSON.stringify(JSON.parse(entry.actionobject), null, 2),
                    title: msg.get('actionData')
                }
            }
        });
    };

    const statusTemplate =
        '#if (status > 0) {# <i class="fa fa-check text-master" title="' + msg.get('success') + '"></i></a> #}#';

    $scope.auditGridOptions = {
        autoBind: false,
        noRecords: {
            template: noRecordsTemplate
        },
        dataSource: $scope.auditDataSource,
        filterable: {
            mode: 'row'
        },
        sortable: true,
        resizable: true,
        columnMenu: false,
        columns: [
            {
                field: 'time',
                title: msg.get('dateTime'),
                filterable: false,
                template: atHelperFunctions.compactDateTemplate,
                attributes: {
                    class: 'text-nowrap text-center'
                },
                width: '143px'
            },
            {
                field: 'user',
                title: msg.get('atUser'),
                filterable: templateServiceFunctions.createFilter('user', $scope.auditDataSource),
                width: '170px',
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'ip',
                title: msg.get('publicIp'),
                filterable: templateServiceFunctions.createFilter('ip', $scope.auditDataSource),
                width: '130px',
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'eventname',
                title: msg.get('event'),
                filterable: templateServiceFunctions.createFilter('eventname', $scope.auditDataSource),
                width: '143px',
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'description',
                template: kendo.template(function (data) {
                    return browserServiceFunctions.htmlEscape(data.description);
                }),
                filterable: templateServiceFunctions.createFilter('description', $scope.auditDataSource),
                title: msg.get('description')
            },
            {
                field: 'actiontype',
                filterable: templateServiceFunctions.createFilter('actiontype', $scope.auditDataSource),
                title: msg.get('actionType'),
                width: '100px',
                attributes: {
                    class: 'text-nowrap'
                }
            },
            {
                field: 'actionobject',
                filterable: false,
                title: msg.get('actionData'),
                attributes: {
                    class: 'text-nowrap no-tooltip'
                },
                template: function (data) {
                    return data.actionobject
                        ? '<a ng-click="showActionObject(dataItem)" kendo-tooltip="" k-options="{ content: \'View action data\', position: \'top\', animation: { open: { effects: \'fade:in\' }, close: { effects: \'fade:out\' } } }" data-role="tooltip" class="m-r-10"><i class="fa fa-eye"></i></a>' +
                              browserServiceFunctions.htmlEscape(data.actionobject)
                        : '';
                }
            },
            {
                field: 'status',
                title: msg.get('status'),
                template: statusTemplate,
                filterable: false,
                attributes: {
                    class: 'text-center'
                },
                width: '60px'
            }
        ],
        dataBound: function (e) {
            gridPropertiesService.bindFilterAutoComplete(e, true);
        }
    };

    // Export...
    exportInfoService.setExportInfo({
        mainDataSource: $scope.auditDataSource,
        getCacheKey: function () {
            return cacheKey;
        },
        getSecondaryParameters: function () {
            return {
                filter: $scope.auditDataSource.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: $scope.auditDataSource.sort()
            };
        }
    });

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.text-nowrap:not(.no-tooltip)'
    });

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        pageSizeService.dataSourceReload($scope.auditDataSource);
    };

    $(window).on('resize.doResize', function () {
        $scope.$apply(function () {
            const previousIsSmallWindow = $scope.isSmallWindow;
            $scope.isSmallWindow = window.innerWidth < 992;

            if (previousIsSmallWindow !== $scope.isSmallWindow) {
                $scope.bindReport();
            }
        });
    });

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('audit');
        }
    });
}

angular.module('app').service('auditLogService', AuditLogService);

AuditLogService.$inject = ['$http', 'envConfig'];

function AuditLogService($http, envConfig) {
    this.getLog = function (parameters, data) {
        return $http.get(envConfig.apiUrl() + '/api/auditLog?' + parameters, data);
    };
}
