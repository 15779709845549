import {
  heavyFontWeight,
  secondaryFontSize,
  smallFontSize
} from '../../common/constants';

export const roleAccessSettingsStyles = {
  footer: {
    fontSize: `${smallFontSize} !important`,
    lineHeight: `${secondaryFontSize} !important`
  },
  learnMoreLink: {
    fontWeight: 'bold'
  },
  footerInfo: {
    float: 'right'
  },
  disabledWizardLauncher: {
    fontWeight: heavyFontWeight,
    alignItems: 'center',
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.26)'
  },
  checkboxes: {
    pointerEvents: 'none'
  }
};
