import { productivityColors } from '_reactivtrak/src/common/constants/styleGuide';

angular.module('app').service('chartService', ChartService);

ChartService.$inject = ['$window', 'constantsService', 'browserServiceFunctions'];

function ChartService($window, constant, browserServiceFunctions) {
    function getHues() {
        return {
            productive: [
                '#8dfdf2',
                '#67fdee',
                '#03DAC5',
                '#03cbb8',
                '#03bdab',
                '#02ae9e',
                '#02a090',
                '#029183',
                '#028376',
                '#027469',
                '#01665c',
                '#01574f',
                '#014942',
                '#013a35',
                '#012c27'
            ],
            unproductive: [
                '#703e8e',
                '#aa2bc0',
                '#5f0099',
                '#a646a6',
                '#4c11a2',
                '#a676c4',
                '#ce6edf',
                '#a819ff',
                '#6b2d6b',
                '#843feb',
                '#d8c2e5',
                '#e9bff1',
                '#d899ff',
                '#2f142f',
                '#c9aaf6'
            ],
            undefined: [
                '#b2b2b2',
                '#a3a3a3',
                '#8C8C8C',
                '#838383',
                '#797979',
                '#707070',
                '#676767',
                '#5d5d5d',
                '#545454',
                '#4b4b4b',
                '#414141',
                '#383838',
                '#2f2f2f',
                '#252525',
                '#1c1c1c'
            ],
            multi: [
                '#2f4b7c',
                '#665191',
                '#a05195',
                '#d45087',
                '#f95d6a',
                '#ff7c43',
                '#ffa600',
                '#0091a6',
                '00adac',
                '#00caa8'
            ],
            multiExtended: [
                '#2f4b7c',
                '#665191',
                '#a05195',
                '#d45087',
                '#f95d6a',
                '#ff7c43',
                '#ffa600',
                '#0091a6',
                '00adac',
                '#00caa8',
                '#00e599',
                '#FFBD47',
                '#FFEB10',
                '#B9D12D',
                '#66acff'
            ],
            neutral: [productivityColors.productive, productivityColors.unproductive, productivityColors.undefined],
            neutralDetails: [
                productivityColors.productive,
                productivityColors.productivePassive,
                productivityColors.unproductive,
                productivityColors.unproductivePassive,
                productivityColors.undefined,
                productivityColors.passive
            ],
            dlp: ['#62549a']
        };
    }

    var defaultFont = constant.get('fontString', 'body', 13);
    var isMobile = browserServiceFunctions.isMobileAgent();

    var getProcessedSeries = function (settings, defaultHue) {
        var hues = getHues();
        var idx = 0;
        var hue = settings.hue && hues[settings.hue] !== undefined ? settings.hue : defaultHue || 'multi';

        return settings.series.map(function (item) {
            if (idx >= hues[hue].length) {
                idx = 0;
            }

            return angular.extend(item, {
                color: item.color || hues[hue][idx++],
                overlay: {
                    gradient: 'none'
                }
            });
        });
    };

    this.buildPieChart = function (settings) {
        var hues = getHues();
        return {
            defaults: {
                type: 'pie',
                labels: {
                    visible: true,
                    position: 'center',
                    color: function (e) {
                        return e.percentage < 0.1 ? 'transparent' : '#fff';
                    },
                    background: 'transparent',
                    template: "#= kendo.format('{0:P}', percentage)#"
                }
            },
            tooltip: {
                visible: settings.hasOwnProperty('showTooltip') ? settings.showTooltip : true,
                template: '${dataItem.tooltip()}'
            },
            legend: {
                labels: {
                    margin: 2,
                    font: settings.font || defaultFont,
                    template: settings.legendTemplate
                },
                position: $window.innerWidth < 1200 ? 'bottom' : 'right'
            },
            legendItemClick: settings.legendItemClick || null,
            series: [
                {
                    field: settings.field,
                    categoryField: settings.categoryField,
                    overlay: {
                        gradient: 'none'
                    },
                    padding: isMobile ? 0 : 20
                }
            ],
            seriesClick: settings.seriesClick || null,
            chartArea: {
                height: isMobile ? 420 : settings.height || 400
            },
            dataSource: new kendo.data.DataSource({
                data: settings.data,
                schema: {
                    model: settings.model
                }
            }),
            colors:
                settings.huesTable && settings.hueOption
                    ? hues[settings.huesTable[settings.hueOption] || 'multi'] || hues.multi
                    : hues.multi,
            transitions: !isMobile
        };
    };

    this.buildBarChart = function (settings) {
        return {
            defaults: {
                type: 'bar',
                stack: settings.stack || false
            },
            series: getProcessedSeries(settings, 'neutral'),
            categoryAxis: {
                categories: settings.categories,
                majorGridLines: {
                    visible: false
                },
                line: {
                    color: '#e6e6e6'
                },
                labels: settings.labels ? settings.labels : {}
            },
            valueAxis: {
                line: {
                    visible: false
                },
                labels: {
                    visible: false
                },
                majorGridLines: {
                    visible: false
                },
                min: 0
            },
            legend: {
                labels: {
                    margin: 2,
                    font: settings.font || defaultFont
                },
                position: $window.innerWidth < 1200 ? 'bottom' : 'right'
            },
            tooltip: {
                visible: true,
                template: settings.tooltipTemplate
            },
            chartArea: {
                height: isMobile ? 320 : 423
            },
            dataSource: new kendo.data.DataSource({
                data: settings.data
            }),
            seriesClick: settings.seriesClick || null,
            transitions: !isMobile
        };
    };

    this.buildColumnChart = function (settings) {
        return {
            defaults: {
                type: 'column'
            },
            series: getProcessedSeries(settings, settings.colorScheme || 'multi'),
            valueAxis: {
                name: settings.valueAxisName,
                labels: {
                    template: settings.labelTooltipTemplate
                },
                line: {
                    visible: false
                },
                axisCrossingValue: 0,
                min: 0,
                max: settings.valueAxisMax,
                majorUnit: settings.majorUnit,
                plotBands: settings.plotBands
            },
            categoryAxis: {
                categories: settings.categories || [],
                line: {
                    visible: false
                },
                majorGridLines: {
                    visible: false
                },
                labels: {
                    rotation: settings.rotation ? settings.rotation : 0
                },
                name: settings.categoryAxisName,
                plotBands: settings.categoryAxisPlotBands,
                notes: settings.categoryAxisNotes
            },
            tooltip: {
                visible: true,
                template: settings.tooltipTemplate
            },
            chartArea: {
                height: settings.height === 0 ? undefined : settings.height ? settings.height : isMobile ? 420 : 400
            },
            legend: {
                labels: {
                    margin: 2,
                    font: settings.font || defaultFont
                },
                position: $window.innerWidth < 1200 ? 'bottom' : 'right',
                visible: settings.legendVisible
            },
            dataSource: new kendo.data.DataSource({
                data: settings.data,
                schema: {
                    model: settings.model
                }
            }),
            seriesClick: settings.seriesClick || undefined,
            seriesHover: settings.seriesHover,
            transitions: !isMobile && settings.transitions,
            delayUpdate: settings.delayUpdate,
            render: settings.render
        };
    };

    this.buildAreaChart = function (settings) {
        return {
            defaults: {
                type: 'area',
                stack: true,
                opacity: 1
            },
            series: getProcessedSeries(settings, settings.colorScheme || 'multi'),
            valueAxis: {
                labels: {
                    font: settings.font,
                    template: settings.labelTooltipTemplate
                },
                line: {
                    visible: false
                },
                axisCrossingValue: 0,
                min: 0,
                majorUnit: settings.majorUnit
            },
            categoryAxis: {
                categories: settings.categories || [],
                line: {
                    visible: false
                },
                majorGridLines: {
                    visible: true,
                    step: settings.step
                },
                labels: {
                    font: settings.font,
                    step: settings.step,
                    rotation: settings.rotation ? settings.rotation : 0
                }
            },
            tooltip: {
                visible: true,
                template: settings.tooltipTemplate
            },
            chartArea: {
                height: settings.height ? settings.height : isMobile ? 420 : 400
            },
            legend: {
                labels: {
                    margin: 2,
                    font: settings.font || defaultFont
                },
                position: $window.innerWidth < 1200 ? 'bottom' : 'right',
                visible: settings.legendVisible
            },
            dataSource: new kendo.data.DataSource({
                data: settings.data,
                schema: {
                    model: settings.model
                }
            }),
            seriesClick: settings.seriesClick || null,
            transitions: !isMobile
        };
    };

    this.buildLineChart = function (settings) {
        return {
            defaults: settings.seriesDefaults,
            series: settings.series,
            valueAxis: {
                labels: {
                    font: settings.font,
                    template: settings.labelTooltipTemplate
                },
                line: {
                    visible: false
                },
                axisCrossingValue: 0,
                min: 0,
                majorUnit: settings.majorUnit,
                name: settings.valueAxisName,
                plotBands: settings.valueAxisPlotBands
            },
            categoryAxis: {
                categories: settings.categories || [],
                line: {
                    visible: false
                },
                majorGridLines: {
                    visible: true,
                    step: settings.step
                },
                labels: {
                    step: settings.step,
                    font: settings.font,
                    rotation: settings.rotation ? settings.rotation : 'auto'
                },
                name: settings.categoryAxisName,
                plotBands: settings.categoryAxisPlotBands
            },
            tooltip: {
                visible: true,
                template: settings.tooltipTemplate
            },
            chartArea: {
                height: settings.height ? settings.height : isMobile ? 420 : 400
            },
            legend: {
                labels: {
                    font: settings.font || defaultFont
                },
                position: $window.innerWidth < 1200 ? 'bottom' : 'right',
                visible: settings.legendVisible
            },
            dataSource: new kendo.data.DataSource({
                data: settings.data,
                schema: {
                    model: settings.model
                }
            }),
            seriesClick: settings.seriesClick || null,
            transitions: !isMobile,
            render: settings.render
        };
    };

    this.buildStackedBarChart = function (settings) {
        return {
            seriesDefaults: settings.seriesDefaults,
            series: getProcessedSeries(settings, settings.colorScheme || 'multi'),
            valueAxis: {
                labels: {
                    template: settings.labelTooltipTemplate,
                    font: settings.font
                },
                line: {
                    visible: false
                },
                axisCrossingValue: 0,
                min: 0,
                name: settings.valueAxisName,
                plotBands: settings.valueAxisPlotBands
            },
            categoryAxis: {
                categories: settings.categories,
                line: {
                    visible: false
                },
                majorGridLines: {
                    visible: false
                },
                labels: {
                    rotation: 'auto',
                    font: settings.font || defaultFont,
                    template: settings.categoryAxisTemplate
                },
                name: settings.categoryAxisName,
                plotBands: settings.categoryAxisPlotBands
            },
            tooltip: {
                visible: true,
                template: settings.tooltipTemplate
            },
            chartArea: {
                height: settings.height === 0 ? undefined : settings.height ? settings.height : isMobile ? 420 : 400
            },
            legend: {
                labels: {
                    margin: 2,
                    font: settings.font || defaultFont,
                    template: settings.legendTemplate
                },
                position: $window.innerWidth < 1200 ? 'bottom' : 'right',
                visible: settings.legendVisible
            },
            seriesClick: settings.seriesClick || null,
            transitions: !isMobile,
            delayUpdate: settings.delayUpdate,
            render: settings.render
        };
    };
}
