import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useEffect, useState } from 'react';
import { UserDetailsHeadings } from '../../account-configuration/constants/users.consts';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { IUserIdentity } from '../models/IUserIdentity';
import { useEditUserState } from '../hooks/useEditUserState';
import { IUserIdentityEditDto } from '../models/IUserIdentityDto';
import {
  CreateDetailErrorMessage,
  SetUserIdentityErrorMessage
} from '../utils/ErrorMessages';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import { mediumFontWeight } from '../../common/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { ActivTrakIcon } from '../../common/assets/Icons';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  ENTER_VALID_EMAIL,
  ENTER_VALID_UPN
} from '../constants/errorMessages.constants';
import {
  patchEditIdentityPathArgs,
  userHasMinimumRequiredFields,
  userIdCount,
  validateDeviceLogins,
  validateEmailUpnArray
} from '../utils/DetailEmailEmployeeIdUtils';
import { GroupTypeLabel } from '../../common/components/GroupType';

type EditUserModalProps = {
  isOpen: boolean;
  onClose: (error: any, newUser: IUserIdentity) => void;
  selectedIdentity: IUserIdentity;
  identityDetailState: IIdentityDetailState;
};

export const EditUserModal = (props: EditUserModalProps) => {
  const { isOpen, onClose, selectedIdentity, identityDetailState } = props;
  const editUserState = useEditUserState(selectedIdentity);

  const {
    editUser,
    handleDisplayNameChange,
    handleEmployeeIdChange,
    handleEmailChange,
    updateEmailUpnValidations,
    handleUserPrincipalNameChange,
    setEditUser,
    handleDeviceLoginDomainChange,
    handleDeviceLoginUserChange,
    onAddUserPrincipalName,
    onAddEmail,
    onAddEmployeeId,
    onAddDeviceLogon,
    onDeleteEmail,
    onDeleteUserPrincipalName,
    onDeleteDeviceLogon,
    onDeleteEmployeeId,
    toggleAgentDeviceLogon,
    setInitialDeviceLogonAgents,
    updateDeviceLogonAgents,
    agentsAndDeviceLogons,
    resetEditUser
  } = editUserState;

  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [addUpnButtonEnabled, setAddUpnButtonEnabled] =
    useState<boolean>(false);
  const [addEmailButtonEnabled, setAddEmailButtonEnabled] =
    useState<boolean>(false);
  const [addEmployeeIdButtonEnabled, setAddEmployeeIdButtonEnabled] =
    useState<boolean>(false);
  const [addDeviceLogonButtonEnabled, setAddDeviceLogonButtonEnabled] =
    useState<boolean>(false);
  const [idCount, setIdCount] = useState<number>(0);

  const handleDialogClick = useCallback((event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    event.stopPropagation();
  }, []);

  const setButtonsEnabled = () => {
    const dataChanged =
      JSON.stringify(editUser) !== JSON.stringify(selectedIdentity);
    const hasRequiredFields = userHasMinimumRequiredFields(editUser);

    setSaveButtonEnabled(hasRequiredFields && dataChanged);
    const hasEmptyUpn = editUser?.upns?.some(
      (upn) => upn.value === '' || upn.value === null
    );
    setAddUpnButtonEnabled(!hasEmptyUpn);

    const hasEmptyEmail = editUser?.emails?.some((email) => email.value === '');
    setAddEmailButtonEnabled(!hasEmptyEmail);

    const hasEmptyEmployeeId = editUser?.employeeIds?.some(
      (employeeId) => employeeId.value === ''
    );
    setAddEmployeeIdButtonEnabled(!hasEmptyEmployeeId);
    const hasEmptyDeviceLogon = editUser?.deviceLogons?.some(
      (deviceLogon) => deviceLogon.user === ''
    );
    setAddDeviceLogonButtonEnabled(!hasEmptyDeviceLogon);
  };

  useEffect(() => {
    setButtonsEnabled();
    setIdCount(userIdCount(editUser));
    if (agentsAndDeviceLogons.length <= 1) {
      setInitialDeviceLogonAgents(editUser);
    } else {
      updateDeviceLogonAgents(editUser);
    }
  }, [editUser]);

  const onCancelChanges = () => {
    onClose(null, null);
    resetEditUser();
  };

  const validateChanges = () => {
    const emailsValid = validateEmailUpnArray(
      editUser?.emails,
      ENTER_VALID_EMAIL
    );
    const upnsValid = validateEmailUpnArray(editUser?.upns, ENTER_VALID_UPN);
    const deviceLoginsValid = validateDeviceLogins(editUser?.deviceLogons);
    return emailsValid && upnsValid && deviceLoginsValid;
  };

  const onSaveUserDetail = async (
    details: IUserIdentityEditDto,
    userIdentity: IUserIdentity
  ): Promise<[IUserIdentity, any]> => {
    const args = { ...details };

    const [error, newIdentity] = await patchEditIdentityPathArgs(
      `/identity/v1/entities/${userIdentity.id}/revision/${userIdentity.revision}`,
      args
    );
    if (!error) {
      //onClose(null, newIdentity);
      identityDetailState.setDetailNotification({
        msg: 'Saved',
        type: 'success'
      });
      return [newIdentity, null]; //selected Identity was set
    } else {
      const message = CreateDetailErrorMessage(error);
      if (SetUserIdentityErrorMessage(message, userIdentity, details)) {
        return [userIdentity, error];
      } else {
        onClose(error, null);
      }
    }
  };

  const saveEditUser = async () => {
    const updateDto = editUserState.getSaveDto();

    const [tempIdentity, tempError] = await onSaveUserDetail(
      updateDto,
      editUser
    );
    if (tempIdentity && tempError) {
      setEditUser({ ...tempIdentity });
    } else {
      onClose(tempError, tempIdentity);
    }
  };

  const onSaveChanges = async () => {
    if (validateChanges()) {
      await saveEditUser();
    } else {
      setEditUser({ ...editUser });
    }
  };

  const editClasses = {
    editRow: {
      pt: 3,
      display: 'flex',
      alignItems: 'top'
    },
    firstColumn: { flex: '6%', pr: 0, flexGrow: '0', pt: 1 },
    secondColumn: { flex: '81%' },
    thirdColumn: { width: '8%', pl: 1 },
    addButtonRow: {
      display: 'flex',
      pt: 2,
      pb: 1,
      justifyContent: 'flex-end',
      width: '92%'
    },
    smallBtn: {
      padding: '0 16px',
      lineHeight: '12px',
      fontSize: '12px',
      height: '24px'
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClick}
      maxWidth="xl"
      fullWidth={false}
    >
      <DialogTitle>{UserDetailsHeadings.Edit}</DialogTitle>
      <DialogContent
        id="edit-agent-dialog-content"
        sx={{ pb: 1, overflowX: 'hidden' }}
      >
        <Box sx={{ minWidth: 700, minHeight: 400 }}>
          <Box sx={{ ...editClasses.editRow, pt: 2 }}>
            <Box sx={{ ...editClasses.firstColumn }}>
              <GroupTypeLabel
                iconSource={editUser?.displayName?.source}
                label=""
              />
            </Box>
            <Box sx={{ ...editClasses.secondColumn, pb: 2 }}>
              <TextField
                name="displayName"
                label="Display Name"
                value={editUser?.displayName?.value ?? ''}
                onChange={(e) => handleDisplayNameChange(e)}
                inputProps={{ maxLength: 255 }}
                helperText={
                  editUser?.displayName?.failedExplanation &&
                  editUser?.displayName?.failedExplanation !== ''
                    ? editUser?.displayName?.failedExplanation
                    : ''
                }
                error={editUser?.displayName?.validationFailed}
                fullWidth
              />
            </Box>
            <Box sx={{ ...editClasses.thirdColumn }}> </Box>
          </Box>
          {editUser?.upns?.map((upn, index) => {
            return (
              <Box
                key={'upn:' + index + ':' + upn.originalValue}
                sx={{ ...editClasses.editRow }}
              >
                <Box sx={{ ...editClasses.firstColumn }}>
                  <GroupTypeLabel iconSource={upn?.source} label="" />
                </Box>
                <Box sx={{ ...editClasses.secondColumn }}>
                  <TextField
                    name={
                      'userPrincipalName:' + upn.originalValue + ':' + index
                    }
                    label="User Principal Name"
                    value={upn?.value ?? ''}
                    onChange={(e) => handleUserPrincipalNameChange(e, upn)}
                    inputProps={{ maxLength: 255 }}
                    error={upn.validationFailed}
                    helperText={
                      upn.failedExplanation ? upn.failedExplanation : ''
                    }
                    fullWidth
                    onBlur={updateEmailUpnValidations}
                  />
                </Box>
                <Box sx={{ ...editClasses.thirdColumn }}>
                  <Button
                    onClick={() => {
                      onDeleteUserPrincipalName(upn);
                    }}
                    color="secondary"
                    disabled={upn.value === '' || idCount <= 1}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              ...editClasses.addButtonRow
            }}
          >
            <Button
              onClick={onAddUserPrincipalName}
              variant="outlined"
              disabled={!addUpnButtonEnabled}
              size="small"
              sx={{ ...editClasses.smallBtn }}
            >
              Add user principal
            </Button>
          </Box>

          {editUser?.emails?.map((email, index) => {
            return (
              <Box
                key={'email:' + index + ':' + email.originalValue}
                sx={{ ...editClasses.editRow }}
              >
                <Box sx={{ ...editClasses.firstColumn }}>
                  <GroupTypeLabel iconSource={email?.source} label="" />
                </Box>
                <Box sx={{ ...editClasses.secondColumn }}>
                  <TextField
                    name={'email:' + email.originalValue + ':' + index}
                    label="Email"
                    value={email?.value ?? ''}
                    onChange={(e) => handleEmailChange(e, email)}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    error={email.validationFailed}
                    helperText={
                      email.failedExplanation ? email.failedExplanation : ''
                    }
                    onBlur={updateEmailUpnValidations}
                  />
                </Box>
                <Box sx={{ ...editClasses.thirdColumn }}>
                  <Button
                    onClick={() => {
                      onDeleteEmail(email);
                    }}
                    color="secondary"
                    disabled={email.value === '' || idCount <= 1}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              ...editClasses.addButtonRow
            }}
          >
            <Button
              onClick={onAddEmail}
              variant="outlined"
              disabled={!addEmailButtonEnabled}
              size="small"
              sx={{ ...editClasses.smallBtn }}
            >
              Add Email
            </Button>
          </Box>
          {editUser?.employeeIds?.map((employeeId, index) => {
            if (employeeId) {
              return (
                <Box
                  key={'employeeId:' + index + ':' + employeeId.originalValue}
                  sx={{ ...editClasses.editRow }}
                >
                  <Box sx={{ ...editClasses.firstColumn }}>
                    <GroupTypeLabel iconSource={employeeId?.source} label="" />
                  </Box>
                  <Box sx={{ ...editClasses.secondColumn }}>
                    <TextField
                      name={
                        'employeeId:' + employeeId.originalValue + ':' + index
                      }
                      label="Employee Id"
                      value={employeeId?.value ?? ''}
                      onChange={(e) => handleEmployeeIdChange(e, employeeId)}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                      error={employeeId.validationFailed}
                      helperText={
                        employeeId.failedExplanation
                          ? employeeId.failedExplanation
                          : ''
                      }
                    />
                  </Box>
                  <Box sx={{ ...editClasses.thirdColumn }}>
                    <Button
                      onClick={() => {
                        onDeleteEmployeeId(employeeId);
                      }}
                      color="secondary"
                      disabled={employeeId.value === '' || idCount <= 1}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              );
            }
          })}

          <Box
            sx={{
              ...editClasses.addButtonRow
            }}
          >
            <Button
              onClick={onAddEmployeeId}
              variant="outlined"
              disabled={!addEmployeeIdButtonEnabled}
              size="small"
              sx={{ ...editClasses.smallBtn }}
            >
              Add Employee Id
            </Button>
          </Box>
          <>
            <Box sx={{ pb: 2, fontWeight: mediumFontWeight }}>
              Device logins
            </Box>

            {agentsAndDeviceLogons?.map((login, index) => {
              return (
                <Box
                  key={'deviceLogin:' + index + ':' + login.uniqueId}
                  sx={{ ...editClasses.editRow }}
                >
                  <Box sx={{ ...editClasses.firstColumn }}>
                    {login.type === 'Agent' && <ActivTrakIcon />}
                  </Box>
                  <Box sx={{ ...editClasses.secondColumn }}>
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        name={'deviceLogons:' + index + ':' + login.uniqueId}
                        label="Username"
                        value={login?.user ?? ''}
                        disabled={login.type == 'Agent'}
                        onChange={(e) =>
                          handleDeviceLoginUserChange(e.target.value, login)
                        }
                        sx={{ width: '40%' }}
                        inputProps={{ maxLength: 255 }}
                        error={
                          login.validationFailed &&
                          login.failedExplanation.indexOf('Domain') == -1
                        }
                        helperText={
                          login.failedExplanation &&
                          login.failedExplanation.indexOf('Domain') == -1
                            ? login.failedExplanation
                            : ''
                        }
                      />
                      <Box sx={{ pr: 1, pl: 1, pt: 1 }}>@</Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <TextField
                          name={
                            'deviceLogonsDomain:' + index + ':' + login.uniqueId
                          }
                          disabled={login.type == 'Agent'}
                          label={'Logon Domain'}
                          value={
                            !login.global && login.domain ? login.domain : ''
                          }
                          onChange={(e) =>
                            handleDeviceLoginDomainChange(e.target.value, login)
                          }
                          sx={{ width: '100%' }}
                          inputProps={{ maxLength: 255 }}
                          error={
                            login.validationFailed &&
                            login.failedExplanation.indexOf('Domain') !== -1
                          }
                          helperText={
                            login.failedExplanation &&
                            login.failedExplanation.indexOf('Domain') !== -1
                              ? login.failedExplanation
                              : ''
                          }
                        />
                      </Box>
                      <Box sx={{ pl: 1 }}>
                        {login.global}
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={'dlGlobal:' + login.uniqueId + ':' + index}
                              checked={login?.global ?? false}
                              onChange={(event) => {
                                toggleAgentDeviceLogon(
                                  login,
                                  event.target.checked
                                );
                              }}
                            />
                          }
                          label="Any"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ ...editClasses.thirdColumn }}>
                    <Button
                      disabled={
                        login.user === '' ||
                        login.type == 'Agent' ||
                        idCount <= 1
                      }
                      onClick={() => {
                        onDeleteDeviceLogon(login);
                      }}
                      color="secondary"
                      style={{ paddingBottom: '16px' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </Box>
                </Box>
              );
            })}
            <Box
              sx={{
                ...editClasses.addButtonRow,
                pb: 1
              }}
            >
              <Button
                onClick={onAddDeviceLogon}
                variant="outlined"
                disabled={!addDeviceLogonButtonEnabled}
                size="small"
                sx={{ ...editClasses.smallBtn }}
              >
                Add Device Login
              </Button>
            </Box>
          </>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 2 }}>
        <Button onClick={onCancelChanges}>Cancel</Button>
        <Button
          onClick={onSaveChanges}
          variant="outlined"
          disabled={!saveButtonEnabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
