import React, { useEffect, useState } from 'react';
import { useUIRouterHistory } from '../../../common/hooks/useUIRouterHistory';
import { useUIRouterQueryString } from '../../../common/hooks/useUIRouterQueryString';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { IGroupMember } from '../../models';
import { getUserTypeCounts } from '../../utils';
import { AddGroupMembersDialogContent } from '../AddGroupMembersDialogContent';
import CreateGroupDialogContent from './CreateGroupDialogContent';
import ReviewGroupMembers from './ReviewGroupMembers';
import { useNotifications } from '../../../common/services/Notifications';
import { BulkGroupsModal } from './BulkGroupsModal';
import { useGroup } from '../../services';
import { bulkGroupClasses } from '../../styles/BulkGroupModal.styles';
import { newEvent } from '../../../common/analytics/eventHeap';
import { PageContent } from '../../enums';
import { AddGroupIcon } from '../../../common/assets/Icons';

type CreateGroupProps = {
  onSubmit: (name: string) => Promise<any>;
  getGroups: (params?: object) => Promise<void>;
  setMemberCount: (
    groupId: number,
    userIncrement?: number,
    computerIncrement?: number
  ) => void;
  onProgressBarStart: () => void;
  onEndProgbarDwnld: () => void;
};

export default function CreateGroupButton({
  onSubmit,
  getGroups,
  setMemberCount,
  onProgressBarStart,
  onEndProgbarDwnld
}: CreateGroupProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>();
  const [pageContent, setPageContent] = useState<PageContent>(
    PageContent.CreateGroup
  );
  const [creating, setCreating] = useState<boolean>(false);
  const history = useUIRouterHistory();
  const urlQuery = useUIRouterQueryString();

  const notificationService = useNotifications();
  const { groupState } = useGroup();
  const { allGroupMembers, addGroupMembers, getGroupMembers, originalAllGroupMembers } = groupState;

  useEffect(() => {
    getGroupMembers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const createGroupQuery = urlQuery.get('openCreateGroupModal');

    if (createGroupQuery) {
      setOpenCreateModal(true);
    }
  }, [urlQuery]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setGroupName('');
    setPageContent(PageContent.CreateGroup);

    allGroupMembers.forEach((member) => {
      if (member.selected && member.groupId === null) {
        member.selected = false;
      }
    });
    resetOptions();
    setOpen(false);
  };

  const submit = async () => {
    setCreating(true);
    newEvent('Created Group', {});
    if (!isNameValid) {
      notificationService.error('Invalid group name.');
      return;
    }

    const addMembers = async (groupId: number): Promise<void> => {
      const newMembers = getNewMembers();
      try {
        await addGroupMembers(groupId, newMembers);
        const { userCount, computerCount } = getUserTypeCounts(newMembers);
        setMemberCount(groupId, userCount, computerCount);
        //no need to await since this is a background task
        getGroups();
        // redirect to group page
        history.push('app.settings.groups_id', { groupId });
      } catch (error) {
        notificationService.error('Unable to add group members');
      } finally {
        setCreating(false);
        handleClose();
      }
    };

    try {
      //create group
      const grp = await onSubmit(groupName);
      //add members
      addMembers(grp.id);
    } catch (error) {
      setCreating(false);
      handleClose();
    }
  };

  const handleAddMembersClick = () => {
    setGroupName(groupName.trim().trimEnd());
    if (!isNameValid) {
      notificationService.error('Invalid group name.');
      return;
    }
    setPageContent(PageContent.AddMembers);
  };

  const getNewMembers = (): IGroupMember[] => {
    return originalAllGroupMembers.filter(
      (sgm) => sgm.selected && sgm.groupId === null
    );
  };

  const resetOptions = () => {
    setOpen(false);
    setOpenCreateModal(false);
    setGroupName('');

    setPageContent(PageContent.CreateGroup);
  };

  const isNameValid = (): boolean =>
    groupName !== undefined &&
    groupName !== null &&
    groupName.trim().trimEnd() !== '';

  const controlGroupCreationFlow = () => {
    setOpenCreateModal(true);
  };

  const getPageContent = () => {
    if (pageContent === PageContent.CreateGroup) {
      return (
        <>
          <CreateGroupDialogContent
            groupName={groupName}
            setGroupName={setGroupName}
          />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleAddMembersClick}
              disabled={!isNameValid()}
            >
              Add Members
            </Button>
          </DialogActions>
        </>
      );
    } else if (pageContent === PageContent.AddMembers) {
      return (
        <>
          <AddGroupMembersDialogContent allGroupMembers={allGroupMembers} />
          <DialogActions>
            <Button onClick={() => setPageContent(PageContent.CreateGroup)}>
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => setPageContent(PageContent.ReviewMembers)}
            >
              Next
            </Button>
          </DialogActions>
        </>
      );
    } else if (pageContent === PageContent.ReviewMembers) {
      return (
        <>
          <DialogContent>
            <ReviewGroupMembers members={getNewMembers()} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPageContent(PageContent.AddMembers)}>
              Back
            </Button>
            <Button variant="contained" onClick={submit} disabled={creating}>
              Create
            </Button>
          </DialogActions>
        </>
      );
    }
  };

  return (
    <>
      <Button
        onClick={controlGroupCreationFlow}
        color="secondary"
        startIcon={<AddGroupIcon />}
      >
        Create Group
      </Button>
      <BulkGroupsModal
        open={openCreateModal}
        onClose={() => {
          resetOptions();
        }}
        addGroupFn={() => {
          setOpenCreateModal(false);
          handleOpen();
        }}
        onProgressBarStart={onProgressBarStart}
        onEndProgbarDwnld={onEndProgbarDwnld}
      />
      <Dialog
        data-testid="create-group-dialog"
        open={open}
        onClose={handleClose}
        sx={
          PageContent.AddMembers === pageContent
            ? bulkGroupClasses.dialogWide
            : bulkGroupClasses.dialog
        }
      >
        <DialogTitle>Create Groups</DialogTitle>
        {getPageContent()}
      </Dialog>
    </>
  );
}
