import {
  IPreMapTeam,
  IReportGroupSummaryDto,
  IReportModalListItem,
  IReportSummaryDto,
  IWorkloadTeamDto,
  WorkloadLabel,
  WorkloadStatusProps,
  ProductivityGoalLabel,
  IReportProductivityGoalModalListItem,
  ProductivityGoalLabelsModelType
} from '../models';
import { sorter } from '../../common/utils/sorter/sorter';
import { SortDirection } from '../../common/enums/SortDirection';

const getWorkloadTeamStatus = (team: IWorkloadTeamDto): WorkloadStatusProps => {
  if (
    team.overutilizedMembers >=
    team.underutilizedMembers + team.healthyMembers
  ) {
    return { status: WorkloadLabel.High };
  }

  if (
    team.underutilizedMembers >
    team.overutilizedMembers + team.healthyMembers
  ) {
    return { status: WorkloadLabel.Low };
  }

  if (team.healthyMembers >= 0.7 * team.totalMembers) {
    return { status: WorkloadLabel.Optimal };
  }

  return { status: WorkloadLabel.Varied };
};

export const mapWorkloadTeams = (
  teams: IWorkloadTeamDto[],
  statusFilter?: WorkloadLabel[]
): IReportModalListItem[] => {
  const filtered = statusFilter
    ? teams.filter((team) =>
        statusFilter.includes(getWorkloadTeamStatus(team).status)
      )
    : teams;

  const mapped = filtered.map((team) => {
    const teamStatus = getWorkloadTeamStatus(team);
    return {
      name: team.groupName,
      value: teamStatus.status,
      id: team.groupId,
      url: 'app.dashboardCurrent.management',
      urlParams: { groupId: team.groupId }
    };
  });

  return sorter(mapped, SortDirection.Ascending, 'value');
};

//TODO: This is the generic teams modal mapping
//In future, use raw data from api to determine client-side model
export const mapTeam = (team: IReportGroupSummaryDto): IReportModalListItem => {
  return {
    name: team.groupName,
    value: `${team.percentage}%`,
    id: team.groupId
  };
};

export const mapUser = (team: IReportSummaryDto): IReportModalListItem => {
  return {
    name: team.name,
    value: `${team.percentage}%`
  };
};

export const mapGroupsWithLinks = (
  groups: IPreMapTeam[]
): IReportModalListItem[] => {
  const sortedGroups = sorter(
    groups,
    SortDirection.Ascending,
    'activeUsersTodayPercentage'
  );

  return sortedGroups?.map((group) => {
    if ('activeUsersTodayPercentage' in group) {
      return {
        name: group.groupName,
        value: `${group.activeUsersTodayPercentage}%`,
        url: 'app.dashboardCurrent.management',
        urlParams: { groupId: group.groupId }
      };
    } else {
      return {
        name: group.groupName,
        value: `${group.percentage}%`,
        url: 'app.dashboardCurrent.management',
        urlParams: { groupId: group.groupId }
      };
    }
  });
};

export const mapUserProductivityAchievementModal = (
  usersList: IReportSummaryDto[]
): IReportProductivityGoalModalListItem[] => {
  const orderedList = usersList
    .sort(
      (a: IReportSummaryDto, b: IReportSummaryDto) =>
        a.percentage - b.percentage
    )
    .map((item: IReportSummaryDto) => {
      if (item.percentage < 100) {
        return {
          name: item.name,
          value: ProductivityGoalLabel.Missing
        };
      }
      return {
        name: item.name,
        value: ProductivityGoalLabel.Achieving
      };
    });

  return orderedList;
};

export const mapProductivityTeam = (
  teamsList: IReportSummaryDto[],
  selectedStatus: ProductivityGoalLabel
): IReportModalListItem[] => {
  const goalTeams = mapTeamsProductivityAchievementModal(teamsList);

  if (!selectedStatus) {
    return [...goalTeams.missing, ...goalTeams.achieving].map((team) => ({
      ...team,
      value: `${team.value}%`,
      url: 'app.dashboardCurrent.management',
      urlParams: { groupId: team.groupId }
    }));
  }

  const statusMapping = {
    [ProductivityGoalLabel.Missing]: goalTeams.missing,
    [ProductivityGoalLabel.Achieving]: goalTeams.achieving
  };

  const selectedList = statusMapping[selectedStatus];

  return selectedList.map((team) => ({
    ...team,
    value: `${team.value}%`,
    url: 'app.dashboardCurrent.management',
    urlParams: { groupId: team.groupId }
  }));
};

export const mapTeamsProductivityAchievementModal = (
  teamsList: IReportSummaryDto[]
): ProductivityGoalLabelsModelType => {
  const allList = teamsList.map((item: IReportSummaryDto) => ({
    name: item.groupName,
    value: item.percentage,
    label:
      item.percentage < 100
        ? ProductivityGoalLabel.Missing
        : ProductivityGoalLabel.Achieving,
    groupId: item.groupId
  }));

  const missingList = allList.filter(
    (item) => item.label === ProductivityGoalLabel.Missing
  );
  const achievingList = allList.filter(
    (item) => item.label === ProductivityGoalLabel.Achieving
  );

  return {
    missing: missingList,
    achieving: achievingList
  };
};
