import { IRoute } from '../../../../models/IRoute';
import { appTemplate as angularJsTemplate } from '../templates';

export const app: IRoute = {
  name: 'app',
  stateDefinition: {
    abstract: true,
    url: '/app',
    template: angularJsTemplate,
    controller: 'MainCtrl'
  }
};

export default app;
