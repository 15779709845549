import React, { Fragment, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { IntegrationCard } from '../components/IntegrationCard';
import { useIntegrationContextProvider } from './IntegrationProvider';
import { useAuthorization } from '../../common/services/Authorization';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { IIntegration } from '../models';
import { IntegrationCodes } from '../constants/IntegrationCodes';
import { isHRISIntegration } from '../utils/integration.utils';

export const IntegrationCards = () => {
  const { integrationState } = useIntegrationContextProvider();

  const { integrationData, init, integrationsNotification } = integrationState;
  useEffect(() => {
    init();
  }, [init, integrationsNotification]);
  const authorizationService = useAuthorization();
  const isV2MigrationEnabled = authorizationService.hasFeature(
    BundleFlag.IntegrationV2Enabled
  );

  // Filters v2 integrations per feature flag.
  const filteredV2Integrations = integrationData?.filter(
    (integration: IIntegration) => {
      if (isHRISIntegration(integration?.integrationcode)) {
        // Prevents showing HRIS card. Change to Show HRIS card after UI ready.
        return false;
      } else if (
        Object.values(IntegrationCodes).includes(integration?.integrationcode)
      ) {
        return isV2MigrationEnabled;
      }
    }
  );

  return (
    <Grid container spacing={4}>
      {!integrationData && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {filteredV2Integrations?.map((integration, i) => (
        <Fragment key={i}>
          <IntegrationCard {...integration} />
        </Fragment>
      ))}
    </Grid>
  );
};
