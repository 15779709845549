import { useCallback, useState } from 'react';
import { fetchData } from '../../common/helpers';
import { useNotifications } from '../../common/services/Notifications';
import { IInsightsCategory, IRolesCategories } from '../models';
import { ICategoriesState } from '../models/ICategoriesState';
import {
  CATS_APPS_SITES,
  ROLES_CATEGORIES
} from '../utils/TimeOnTask.view.utils';

export const useCategoriesState = (): ICategoriesState => {
  const notificationService = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<IInsightsCategory[]>([]);
  const [isRoleLoading, setIsRoleLoading] = useState<boolean>(false);
  const [rolesCategories, setRolesCategories] =
    useState<IRolesCategories[]>(null);

  const fetchCategories = useCallback(async (groupId: string) => {
    setIsLoading(true);
    try {
      const cachedData = sessionStorage.getItem(
        `${CATS_APPS_SITES}_${groupId}`
      );
      if (cachedData) {
        setCategories(JSON.parse(cachedData));
      } else {
        const data = await fetchData<IInsightsCategory[]>({
          path: `/api/insights/categories/${groupId}?includeApps=true&includeSites=true`
        });
        setCategories(data);
        sessionStorage.setItem(
          `${CATS_APPS_SITES}_${groupId}`,
          JSON.stringify(data)
        );
      }
    } catch (error) {
      notificationService.error('Error fetching aa categories');
      console.error(`Error fetching aa categories:`, error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchRolesCategories = useCallback(async () => {
    setIsRoleLoading(true);
    try {
      const cachedData = sessionStorage.getItem(ROLES_CATEGORIES);
      if (cachedData) {
        setRolesCategories(JSON.parse(cachedData));
      } else {
        const data = await fetchData<IRolesCategories[]>({
          path: '/api/insights/categories/rolemapping'
        });
        setRolesCategories(data);
        sessionStorage.setItem(ROLES_CATEGORIES, JSON.stringify(data));
      }
    } catch (error) {
      console.error('Error fetching roles categories', error);
    } finally {
      setIsRoleLoading(false);
    }
  }, []);

  const init = useCallback(
    (groupId: string) => {
      fetchCategories(groupId);
    },
    [fetchCategories]
  );

  return <ICategoriesState>{
    init,
    isLoading,
    categories,
    setCategories,
    isRoleLoading,
    rolesCategories,
    setRolesCategories,
    fetchRolesCategories
  };
};
