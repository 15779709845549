import { useEffect } from 'react';
import { redirectToAcademy } from './redirectToAcademy';
import { getUIRouterState } from '../../../hooks/useUIRouterHistory';

export const AcademyRedirect = () => {
  useEffect(() => {
    (async () => {
      const state = getUIRouterState();
      await redirectToAcademy(state.params?.direct, state.params?.original_uri);
    })();
  }, []);

  return <></>;
};
