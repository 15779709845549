import axios, { AxiosResponse } from 'axios';
import { setUserToken } from '../authentication/useUserTokenStore';

/**
 * Sets Axios interceptors for error handling and authorization token refreshing
 *
 * @returns {void} Void
 */
export default (): void => {
  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      if (response.headers.Authorization) {
        setUserToken(response.headers.Authorization);
      }

      return response;
    },
    (error: { response?: { status?: number }}) => {
      if (error?.response?.status === 401) {
        setUserToken('invalid');
      }

      return Promise.reject(error);
    }
  );
};
