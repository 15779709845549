import { createStore } from 'zustand';
import {
  ScheduleAdherenceExportExcludeColumn,
  IScheduleAdherenceExportExcludeColumnsStore
} from '../models/ScheduleAdherence.models';

export const scheduleAdherenceExportExcludeColumnsStore =
  createStore<IScheduleAdherenceExportExcludeColumnsStore>(() => ({
    excludeColumns: []
  }));

export const setScheduleAdherenceExportExcludeColumns = (
  newColumns: ScheduleAdherenceExportExcludeColumn[]
) => {
  scheduleAdherenceExportExcludeColumnsStore.setState({
    excludeColumns: newColumns
  });
};

export const getScheduleAdherenceExportExcludeColumns = () =>
  scheduleAdherenceExportExcludeColumnsStore.getState();

export const resetScheduleAdherenceExportExcludeColumns = () => {
  scheduleAdherenceExportExcludeColumnsStore.setState(
    {
      excludeColumns: []
    },
    true
  );
};
