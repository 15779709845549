import React, { useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AgentInstallCard,
  AutoUpdateSettingsCard,
  LandingPageSettingsCard,
  PassiveTimeSettingsCard,
  PerformanceOptimizationCard,
  RealTimeSettingsCard,
  StorageInformationCard,
  ActiveTimeSettingsCard,
  OfflineMeetingSettingsCard,
  BrowserExtensionsCard,
  GTEUsersCard
} from '../components';
import { PrivacyLevelSettingsCard } from '../components/PrivacyLevelSettingsCard';
import { canSeePrivacyLevelSettingsCard } from '../utils/privacyLevelSettings.utils';
import { Role } from '../../common/enums';
import { useAuthorization } from '../../common/services/Authorization';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { useOfflineMeetingSettingsState } from '../hooks';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import {
  getAccountPlanStore,
  isAccountPlanInTrial
} from '../../common/stores/accountPlanStore';

export const AccountConfigurationView = () => {
  const authorizationService = useAuthorization();
  const { screenshotsAllowed = false } = getPrivacySettings();

  const { offlineMeetingSettings, init } = useOfflineMeetingSettingsState();
  useEffect(() => {
    init();
  }, []);

  const isSupport = useRef<boolean>(
    authorizationService.hasAnyRole([Role.SupportBasic])
  );
  const isAdvancedSupport = useRef<boolean>(
    authorizationService.hasAnyRole([Role.SupportAdvanced])
  );
  const isSuperAdmin = useRef<boolean>(
    authorizationService.hasAnyRole([Role.SuperAdmin])
  );

  const { planType } = getAccountPlanStore();
  const hasScreenDetailsEnabled = authorizationService.hasFeature(
    BundleFlag.ScreenDetails
  );
  const allowedPlan = canSeePrivacyLevelSettingsCard(
    planType,
    isAccountPlanInTrial(),
    hasScreenDetailsEnabled
  );

  const isUserIdentityEnabled = authorizationService.hasFeature(
    BundleFlag.UserIdentityConfiguration
  );

  return (
    <Container>
      <Grid container spacing={3}>
        {allowedPlan && (
          <Grid item xs={12}>
            <PrivacyLevelSettingsCard isSupport={isSupport.current} />
          </Grid>
        )}
        <Grid item xs={12}>
          <AgentInstallCard isSupport={isSupport.current} />
        </Grid>
        <Grid item xs={12}>
          <BrowserExtensionsCard isSuperAdmin={isSuperAdmin.current} />
        </Grid>
        <Grid item xs={12}>
          <AutoUpdateSettingsCard isSuperAdmin={isSuperAdmin.current} />
        </Grid>
        <Grid item xs={12}>
          <PassiveTimeSettingsCard isSupport={isSupport.current} />
        </Grid>
        <Grid item xs={12}>
          <ActiveTimeSettingsCard isSupport={isSupport.current} />
        </Grid>
        {offlineMeetingSettings?.hasIntegratedCalendar && (
          <Grid item xs={12}>
            <OfflineMeetingSettingsCard isSupport={isSupport.current} />
          </Grid>
        )}
        <Grid item xs={12}>
          <PerformanceOptimizationCard isSupport={isSupport.current} />
        </Grid>
        <Grid item xs={12}>
          <LandingPageSettingsCard
            isSupport={isSupport.current && !isAdvancedSupport.current}
          />
        </Grid>
        {screenshotsAllowed && (
          <Grid item xs={12}>
            <RealTimeSettingsCard isSupport={isSupport.current} />
          </Grid>
        )}
        <Grid item xs={12}>
          <StorageInformationCard />
        </Grid>
        {isUserIdentityEnabled && (
          <Grid item xs={12}>
            <GTEUsersCard isSupport={isSupport.current} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export const AccountConfigurationComponent = () => (
  <ReactivTrakComponentWrapper>
    <AccountConfigurationView />
  </ReactivTrakComponentWrapper>
);
