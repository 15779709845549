import { createRoot } from 'react-dom/client';
import { TimelineGroup } from 'vis-timeline/esnext';
import { AtTimelineGroupTemplate } from '../../../common/components/AtTimeline/AtTimelineGroupTemplate';

const groupElementMap: { [id: string]: HTMLElement } = {};
export const createGroupTemplate = (
  group: TimelineGroup,
  element: HTMLElement
) => {
  if (!group) return '';

  // Return the element reference if we've already rendered this
  const mapId = group?.id;
  if (groupElementMap[mapId]) return groupElementMap[mapId];

  // Create a container for the react element (prevents DOM node errors)
  const container = document.createElement('div');
  element.appendChild(container);
  const root = createRoot(container);
  root.render(<AtTimelineGroupTemplate group={group} />);

  // Store the rendered element container to reference later
  groupElementMap[mapId] = container;

  // Return the new container
  return container;
};
