import viewTemplate from 'views/widgets/verificationOptions.html?raw';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('verificationOptions', function () {
    return {
        restrict: 'E',
        scope: {
            isModal: '@',
            owner: '@'
        },
        template: viewTemplate,
        controller: [
            '$scope',
            'messagesService',
            'verificationApiService',
            'notificationService',
            function ($scope, msg, verificationApiService, notificationService) {
                var { username } = profileStore.getState().profile;
                $scope.email = username || $scope.owner;

                $scope.sendVerificationEmail = function (e) {
                    e.preventDefault();
                    var payload = {};
                    payload.email = $scope.email;

                    verificationApiService
                        .sendEmail(payload)
                        .then(function () {
                            if (!$scope.isModal) {
                                $scope.$emit('showNotification', {
                                    message: msg.get('verificationEmailSent'),
                                    color: 'success'
                                });
                            } else {
                                $scope.$emit('dismiss-verification-modal');
                            }
                        })
                        .catch(function (response) {
                            if (response.status === 429) {
                                $scope.$emit('dismiss-verification-modal');
                            } else {
                                notificationService.showNotification(
                                    'ActivTrak Error: An error occurred trying to confirm with the Verification Service',
                                    'danger'
                                );
                                $scope.$emit('dismiss-verification-modal');
                            }
                        });
                };
            }
        ]
    };
});
