import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { IUserPermissionStore, IUserPermissionStoreBase } from './userPermissionStore.models';
import { IBundleFlags } from '../../models/IBundleFlags';
import { IFeatureFlags } from '../../models/IFeatureFlags';
import { PermissionLevel } from '../../../common/enums/PermissionLevel';
import { FeatureFlag } from '../../enums/FeatureFlag';
import { BundleFlag } from '../../enums/BundleFlag';
import { getState } from '../../config/routing';


const defaultState: IUserPermissionStoreBase = {
  routeAccesses: {},
  userRoles: [],
  userRoleAccessKeys: [],
  userBundleFlags: null,
  userFeatureFlags: null
};

// TODO: Refactor to create a list of routes the user has access to with levels instead of doing this check every time
export const userPermissionStore = createStore<IUserPermissionStore>((set) => ({
  ...defaultState,
  reset: () => {
    set(defaultState);
  }
}));

export const getUserBundleFlags = () => {
  return userPermissionStore.getState().userBundleFlags;
};

export const setUserBundleFlags = (flags: IBundleFlags) => {
  const current = userPermissionStore.getState();
  userPermissionStore.setState({ ...current, userBundleFlags: flags });
};

export const getUserFeatureFlags = () => {
  return userPermissionStore.getState().userFeatureFlags;
};

export const setUserFeatureFlags = (flags: IFeatureFlags) => {
  const current = userPermissionStore.getState();
  userPermissionStore.setState({ ...current, userFeatureFlags: flags });
};

export const getRoutePermissionLevel = (routeName: string): PermissionLevel => {
  const state = getState(routeName);

  if (state === 'invalid') {
    return PermissionLevel.None;
  }

  return state.authLevel;
};

export const hasFlag = (flag: FeatureFlag | BundleFlag): boolean => {
  const userFeatureFlags = getUserFeatureFlags();
  const userBundleFlags = getUserBundleFlags();
  return userFeatureFlags?.[flag] || userBundleFlags?.[flag] || false;
};

export const useUserPermissionStore = (selector) =>
  useStore<any, any>(userPermissionStore, selector);
