import React from 'react';
import { RoleResourceTitleModel } from '../models';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import { RoleAccessKeys } from '../../common/enums';
import { formatPremiumHoverText } from '../utils';

export const RoleResourceTitle = (props: RoleResourceTitleModel) => {
  const { resource, subItem, secondaryText, premiumFeature } = props;
  const description = secondaryText ? ` - ${secondaryText}` : '';

  const premiumHoverText =
    premiumFeature && formatPremiumHoverText(premiumFeature);

  const formatPhrases = {
    [RoleAccessKeys.Coaching]: 'Coach',
    [RoleAccessKeys.ExecutiveSummary]: 'Executive Summary',
    [RoleAccessKeys.TeamComparison]: 'Team Comparison',
    [RoleAccessKeys.ActivityBreakdown]: 'Activity Breakdown',
    [RoleAccessKeys.TimeOnTask]: 'Activity Alignment',
    [RoleAccessKeys.WorkEfficiency]: 'Work Efficiency',
    [RoleAccessKeys.WorkloadBalance]: 'Workload Balance',
    [RoleAccessKeys.TechnologyUsage]: 'Technology Usage',
    [RoleAccessKeys.BenchmarkAndGoals]: 'Benchmarks & Goals',
    [RoleAccessKeys.PersonalInsights]: 'Personal Insights',
    [RoleAccessKeys.Location]: 'Location Insights',
    [RoleAccessKeys.Configuration]: 'Configuration',
    [RoleAccessKeys.Subscriptions]: 'Subscriptions',
    [RoleAccessKeys.Impact]: 'Impact',

    //Alarms RBAC
    [RoleAccessKeys.Alarms]: 'Alarms',
    [RoleAccessKeys.AlarmsLog]: 'Alarm Log',
    [RoleAccessKeys.AlarmsConfiguration]: 'Configuration',
    [RoleAccessKeys.AlarmsNotifications]: 'Notifications',
    [RoleAccessKeys.AlarmsRiskLevel]: 'Risk Level',
    [RoleAccessKeys.AlarmsScreenshotsReports]: 'Screenshots',
    [RoleAccessKeys.AlarmsScreenshotsRedaction]: 'Screenshots Redaction',
    [RoleAccessKeys.AlarmsScreenshotsFlagging]: 'Screenshots Flagging',
    [RoleAccessKeys.AlarmsVideos]: 'Videos',

    // Home RBAC
    Dashboards: 'Homepages',
    [RoleAccessKeys.DashboardsAdmin]: 'Admin Panel',
    [RoleAccessKeys.DashboardsOrgOverview]: 'Organization Overview',
    [RoleAccessKeys.DashboardsTeamMgmt]: 'Team Management',
    [RoleAccessKeys.DashboardsActivity]: 'Activity Dashboard'
  };

  const phrase =
    formatPhrases[resource] || resource.replace(/([A-Z])/g, ' $1').trim();

  return (
    <Tooltip
      title={
        premiumHoverText ? phrase + premiumHoverText : phrase + description
      }
      placement="bottom-start"
    >
      <Box>
        <span
          style={subItem ? { fontWeight: 'normal' } : { fontWeight: 'bold' }}
        >
          {phrase}
        </span>
        {description}
      </Box>
    </Tooltip>
  );
};
