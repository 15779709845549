import React from 'react';
import { SvgIconWrapper } from './SvgIconWrapper';
import { SvgIconProps } from './SvgIconProps';

export const WorkdayIcon = (props: SvgIconProps) => {
  const { height, width, viewBox } = props;

  const iconViewBox = viewBox ?? '0 0 50 50';
  const iconHeight = height ?? '50px';
  const iconWidth = width ?? '50px';
  const title = props.title ?? 'Workday Icon';
  const className = props.className;

  let classList = 'at-workday-icon';
  if (className) classList += ` ${className}`;

  return (
    <SvgIconWrapper
      height={iconHeight}
      width={iconWidth}
      title={title}
      className={classList}
      viewBox={iconViewBox}
    >
      <>
        <defs>
          <clipPath id="2ebd9878-1416-4f00-8057-b9af9de5f167">
            <path
              d="M24.37567,6.47662A17.61748,17.61748,0,0,1,41.13551,16.946a1.11045,1.11045,0,0,1,.13054.718.98871.98871,0,0,1-1.891.18169A15.62239,15.62239,0,0,0,28.57577,8.78924a16.93076,16.93076,0,0,0-7.14953-.00177,15.17723,15.17723,0,0,0-8.566,5.35377,14.24227,14.24227,0,0,0-2.24205,3.70795.98564.98564,0,0,1-1.83986-.70737A17.61612,17.61612,0,0,1,24.37567,6.47662"
              style={{ fill: 'none', clipRule: 'evenodd' }}
            />
          </clipPath>
          <clipPath id="4639c425-dbaa-45c4-89bf-595e62744474">
            <path
              d="M40.28663,19.43856a1.94788,1.94788,0,0,1-1.8134-1.25068,14.69331,14.69331,0,0,0-5.32731-6.38395A14.48555,14.48555,0,0,0,28.3584,9.72945a16.01236,16.01236,0,0,0-6.7438.00353,14.29576,14.29576,0,0,0-8.02271,5.038,13.29808,13.29808,0,0,0-2.08682,3.45746,1.9345,1.9345,0,0,1-1.46766,1.17836,1.73946,1.73946,0,0,1-.33516.03A1.95187,1.95187,0,0,1,7.88,16.789a18.64334,18.64334,0,0,1,23.33783-10.226,18.75945,18.75945,0,0,1,8.42667,6.04526,18.539,18.539,0,0,1,2.37259,3.94432,2.06016,2.06016,0,0,1,.19051,1.32477,1.94275,1.94275,0,0,1-1.697,1.547,1.67709,1.67709,0,0,1-.224.01411M15.153,10.63615A16.73064,16.73064,0,0,0,9.67226,17.507l.05821-.03881a15.27877,15.27877,0,0,1,2.39729-3.95491,19.70136,19.70136,0,0,1,3.02527-2.8771m19.98621.224a16.66649,16.66649,0,0,1,5.12445,6.608l.01411.0247.03175-.03175c-.00882-.01764-.02293-.05292-.04586-.10408a16.594,16.594,0,0,0-5.12445-6.49684M24.99444,7.431c-.194,0-.38985.00353-.58565.01058a16.5996,16.5996,0,0,0-2.59133.29106,19.0008,19.0008,0,0,1,4.4259-.25578q-.61917-.045-1.24892-.04586"
              style={{ fill: 'none', clipRule: 'evenodd' }}
            />
          </clipPath>
          <clipPath id="4c0dc9da-c9a5-409b-a8db-5bd8c259087e">
            <rect
              x="8.30203"
              y="22.09424"
              width="33.39594"
              height="22.40576"
              style={{ fill: 'none' }}
            />
          </clipPath>
          <clipPath id="673fcab0-eb35-4e35-8328-24cf748a890b">
            <rect
              x="7.7506"
              y="21.3765"
              width="35.28017"
              height="24.69612"
              style={{ fill: 'none' }}
            />
          </clipPath>
          <clipPath id="08ead1e8-8203-45a5-b008-7772ff631f0b">
            <path
              d="M8.302,22.57937a1.01751,1.01751,0,0,1,.79733-.471c1.0143-.00882,2.03037-.03175,3.04291.01411a1.0545,1.0545,0,0,1,.94551.94551c.9949,4.05369,1.97393,8.11267,2.96706,12.16813.29106,1.25245.64563,2.49254.83438,3.76616.19581,1.14308.23461,2.30556.4216,3.45216a43.54862,43.54862,0,0,1,.9261-4.88278q1.94747-7.258,3.89317-14.516a1.19579,1.19579,0,0,1,1.1713-.94374c1.129-.02822,2.25969-.00882,3.38866-.00882a1.34669,1.34669,0,0,1,1.39886.94551c.9702,3.528,1.9263,7.06309,2.89121,10.59287a63.42268,63.42268,0,0,1,1.68463,6.8814c.09349.64739.18169,1.29478.31576,1.93688a96.45141,96.45141,0,0,1,2.09211-10.88393c.64386-2.87181,1.27361-5.74714,1.92453-8.61895a1.16428,1.16428,0,0,1,1.24363-.85025c.91376-.00529,1.82751-.03175,2.73951.01411a.7942.7942,0,0,1,.6615,1.09721q-2.74921,10.13688-5.4896,20.27551a1.17976,1.17976,0,0,1-1.12191.97373c-1.41473.04586-2.833.01058-4.246.0194a1.02264,1.02264,0,0,1-1.13249-.7744c-1.26656-4.64816-2.50666-9.30162-3.76263-13.95331a31.60291,31.60291,0,0,1-.82732-4.95686,41.22259,41.22259,0,0,1-.74441,4.44177Q22.39381,36.42772,20.456,43.61165c-.14465.77616-1.03194.92963-1.69168.88024-1.31595-.0247-2.63014.03-3.94432-.0247a.95229.95229,0,0,1-.91376-.80615Q11.13591,33.5665,8.37259,23.46843a5.18045,5.18045,0,0,1-.07056-.88906"
              style={{ fill: 'none', clipRule: 'evenodd' }}
            />
          </clipPath>
        </defs>
        <title>workday</title>
        <g style={{ clipPath: 'url(#2ebd9878-1416-4f00-8057-b9af9de5f167)' }}>
          <rect
            x="-0.10458"
            y="-2.354"
            width="50.20916"
            height="29.64635"
            style={{ fill: '#f69f1a' }}
          />
        </g>
        <g style={{ clipPath: 'url(#4639c425-dbaa-45c4-89bf-595e62744474)' }}>
          <rect
            x="-1.06941"
            y="-3.32005"
            width="52.13881"
            height="31.57864"
            style={{ fill: '#f69f1a' }}
          />
        </g>
        <g style={{ clipPath: 'url(#4c0dc9da-c9a5-409b-a8db-5bd8c259087e)' }}>
          <g style={{ isolation: 'isolate' }}>
            <g
              style={{ clipPath: 'url(#673fcab0-eb35-4e35-8328-24cf748a890b)' }}
            >
              <g
                style={{
                  clipPath: 'url(#08ead1e8-8203-45a5-b008-7772ff631f0b)'
                }}
              >
                <g
                  style={{
                    clipPath: 'url(#673fcab0-eb35-4e35-8328-24cf748a890b)'
                  }}
                >
                  <rect
                    x="-0.51801"
                    y="13.27419"
                    width="51.03603"
                    height="40.04611"
                    style={{ fill: '#0067ab' }}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </>
    </SvgIconWrapper>
  );
};
