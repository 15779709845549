import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { setLocalStorageItem } from '../../common/utils/localStorage';
import { useAddAccount } from '../hooks/useAddAction';
import { AccountCard, LoadingModal, RedirectingModal } from '.';
import { supportPortalLocalStorage } from '../constants';
import { RedirectStatus } from '../enums/RedirectStatus';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { INewlyAddedAccount } from '../models/IAccountData';

interface ICardsContainerProps {
  setPayload: React.Dispatch<
    React.SetStateAction<Partial<IAddAccountPayloadDto>>
  >;
  payload: IAddAccountPayloadDto;
  setSavedAccounts: React.Dispatch<React.SetStateAction<INewlyAddedAccount[]>>;
  actionType: string;
  savedAccounts: INewlyAddedAccount[];
}

export const CardsContainer = (props: ICardsContainerProps) => {
  const { actionType, payload, savedAccounts, setSavedAccounts, setPayload } =
    props;

  const { handleAddToPortal, handleRedirect, isLoading, status } =
    useAddAccount(savedAccounts, setSavedAccounts);

  useEffect(() => {
    setPayload(null);
    handleFetchAccountDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const handleFetchAccountDetails = async () => {
    if (!payload) return;

    const processAddRequest =
      actionType === 'redirect' ? handleRedirect : handleAddToPortal;

    await processAddRequest(payload);
  };

  const removeAccount = (accountId: string) => {
    const updatedAccountsDisplayed = savedAccounts.filter(
      (account) => account.accountId !== accountId
    );
    setLocalStorageItem(
      supportPortalLocalStorage.accounts,
      JSON.stringify(updatedAccountsDisplayed)
    );
    setSavedAccounts(updatedAccountsDisplayed);
  };

  return (
    <>
      {savedAccounts.length > 0 ? (
        <Stack direction="row" flexWrap="wrap" sx={{ width: '70%', gap: 2 }}>
          {savedAccounts.map((account) => (
            <AccountCard
              key={account.accountId}
              account={account}
              onRemove={removeAccount}
            />
          ))}
        </Stack>
      ) : (
        <></>
      )}
      <Dialog open={isLoading}>
        <LoadingModal />
      </Dialog>
      <Dialog open={status === RedirectStatus.Loading}>
        <RedirectingModal />
      </Dialog>
    </>
  );
};
