import React, { useState, useEffect, useRef, HTMLAttributes } from 'react';
import { debounce } from 'lodash';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import { BaseAutoCompleteSC } from '../../common/styles/app.component.styles';

const SEARCH_CHARS_MIN = 1;

type IdentityAutoCompleteProps = {
  options: any[];
  onChange: (event: object, value: string, reason: string) => void;
  onInputChange: (event: object, value: string, reason: string) => void;
  isLoading: boolean;
  currentValue: string;
  onKeyPress: (event: any) => void;
};

export const UserIdentityAutocomplete: React.FC<IdentityAutoCompleteProps> = ({
  ...props
}) => {
  const {
    options,
    onChange,
    onInputChange,
    onKeyPress,
    isLoading,
    currentValue = ''
  } = props;
  const [fieldValue, setFieldValue] = useState<string>();

  const debounceSetFilter = useRef(
    debounce((value, callback) => {
      setFieldValue(value);
      callback();
    }, 400)
  ).current;

  const autocompleteRef = React.createRef();

  useEffect(() => {
    if (typeof currentValue === 'string') setFieldValue(currentValue);
  }, [currentValue]);

  const handleInputChange = (event, value, reason) => {
    const callback = () => onInputChange(event, value, reason);
    debounceSetFilter(value, callback);
  };

  const handleOnChange = (event, value, reason) => {
    let term = '';
    setFieldValue(value);
    if (value) {
      if (typeof value === 'string') {
        term = value;
      } else {
        term = value['label'];
      }
    }
    if (term) {
      onChange(event, value, reason);
    } else onChange(event, '', reason);
  };

  const currentOptions =
    !fieldValue || fieldValue.length < SEARCH_CHARS_MIN ? [] : options;

  return (
    <BaseAutoCompleteSC
      freeSolo
      value={currentValue}
      options={currentOptions}
      onInputChange={handleInputChange}
      onKeyPress={onKeyPress}
      onChange={handleOnChange}
      selectOnFocus
      clearOnBlur
      loading={isLoading}
      getOptionLabel={(option: any): string => {
        if (typeof option === 'string') {
          return option;
        } else if (option.searchVal) {
          return option.searchVal;
        } else {
          return option['label'];
        }
      }}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: any) => {
        let item: string;
        if (typeof option === 'string') {
          item = option;
        } else {
          item = option['label'];
        }

        return (
          <ListItem {...props} key={props.id}>
            {item}
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          placeholder="Search by Display name, UPN, Employee id, Email, Username, or Device login"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      )}
      filterOptions={(x) => x}
      ref={autocompleteRef}
      data-testid="identity-search"
    />
  );
};
