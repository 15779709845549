import { useCallback, useState } from 'react';
import {
  filterTopUsersResults,
  setTopUsersSearchStore,
  clearTopUsersSearch
} from '../stores/topUsersSearch.store';
import { useReportPageModeStore } from '../../../common/components/ReportFilters/hooks/useReportPageModeToggleStore';
import { REPORT_PAGE_MODE_LABELS_MAPPED } from '../../../common/components/ReportFilters/constants/reportPageModeToggle.constants';
import { useTopUsersReportsStore } from '../hooks/useTopUsersReportStore';
import { useTopUsersSearchStore } from '../hooks/useTopUsersSearchStore';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import {
  ITopUserReportStore,
  ITopUserSearchStore
} from '../models/TopUsersReport.models';
import { ApiStatus } from '../../../common/enums/ApiStatus';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';

export const TopUsersSearch = () => {
  const [showClear, setShowClear] = useState<boolean>(false);

  const { apiStatus } = useTopUsersReportsStore((s: ITopUserReportStore) => s);
  const { searchOptions, searchValue, searchInputValue } =
    useTopUsersSearchStore((s: ITopUserSearchStore) => s);
  const pageMode = useReportPageModeStore((s) => s.pageMode);
  const usersReportFilter = useReportFilterStore((s) => s.users);
  const filterMode = usersReportFilter[0]?.filterMode;

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e?.target) {
        //what happens when typing in the search bar
        const value = e.target.value;
        if (value) {
          setTopUsersSearchStore({ searchInputValue: value });
          setShowClear(Boolean(value?.length));
        }
      }
    },
    []
  );

  const handleOnChange = (event, value) => {
    //what happens when selecting an option
    const label = value?.label;
    const query = value?.value;
    setShowClear(Boolean(label?.length));
    setTopUsersSearchStore({ searchInputValue: label, searchValue: label });
    filterTopUsersResults(query);
    //TODO: update gantt store with selected item's 'query'
  };

    const handleOnClear = useCallback(() => {
      clearTopUsersSearch();
      setShowClear(false);
    }, []);

  const preText = filterMode === 'computers' ? 'Computer' : 'User';
    const modeLabel =
      pageMode === 'groups'
        ? `${preText} ${REPORT_PAGE_MODE_LABELS_MAPPED[pageMode]}`
        : REPORT_PAGE_MODE_LABELS_MAPPED[pageMode];

  return (
    <Autocomplete
      freeSolo
      value={searchValue}
      inputValue={searchInputValue}
      disablePortal
      id="top-users-search"
      options={searchOptions}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      disabled={apiStatus !== ApiStatus.Loaded || !searchOptions?.length}
      getOptionLabel={(option: { label: string; value: string }): string =>
        option?.label || ''
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`Search by ${modeLabel}`}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: showClear && (
              <InputAdornment position="end">
                <IconButton onClick={handleOnClear} color="secondary">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};
