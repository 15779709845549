import { patchIdentityData } from '../../common/helpers/apis';
import {
  EMPLOYEE_ID_DISPLAY,
  EMPLOYEE_ID_TYPE
} from '../constants/detailTypeNames.constants';
import {
  ENTER_UNIQUE_VALUE,
  ENTER_VALID_EMAIL,
  ENTER_VALID_EMPLOYEE_ID,
  ENTER_VALID_LOGON_DOMAIN
} from '../constants/errorMessages.constants';
import { IAgentMove } from '../models/IAgentMove';
import {
  IValueValidation,
  IUserIdentity,
  IUserAgent,
  IDeviceLogon
} from '../models/IUserIdentity';
import { IUserIdentityDto } from '../models/IUserIdentityDto';
import { IUserLogon } from '../models/IUserLogon';
import { PageDirection } from '../models/PageDirection';
import { mapToIdentity } from './IdentityMappers';
import { trimValueIdOrNull } from './TrimUtils';
import { generateGuid } from '../../common/utils/generateGuid';

export const isEmail = (search: string): boolean => {
  if (!search || search.length == 0) {
    return true;
  }
  const regexp = new RegExp(/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  return regexp.test(search);
};

export const validateUnique = (
  emailValue: string,
  selectedIdentity: IUserIdentity,
  dataItem: IValueValidation
) => {
  if (selectedIdentity && selectedIdentity.emails) {
    for (const emailId of selectedIdentity.emails) {
      if (
        emailId.value == emailValue &&
        emailId.originalValue &&
        dataItem.originalValue !== emailId.originalValue
      ) {
        dataItem.validationFailed = true;
        dataItem.failedExplanation = ENTER_UNIQUE_VALUE;
      }
    }
  }
};

export const typeDisplayName = (typeName: string) => {
  switch (typeName) {
    case EMPLOYEE_ID_TYPE:
      return EMPLOYEE_ID_DISPLAY;
    default:
      return typeName;
  }
};

const validateAllFieldsPopulated = (
  dataItem: IValueValidation,
  proposedValue: string
) => {
  if (!proposedValue || proposedValue.length == 0) {
    dataItem.validationFailed = true;
  }
};

const toggleAgentDeviceLogon = (
  editUser: IUserIdentity,
  logonToToggle: IUserLogon,
  isChecked: boolean
): IUserIdentity => {
  if (editUser.deviceLogons == null) {
    editUser.deviceLogons = [];
  }
  const duplicateDeviceLogon = editUser.deviceLogons.find(
    (deviceLogin) => deviceLogin.user === logonToToggle.user
  );
  if (isChecked) {
    //see if we have a duplicate device logon
    if (duplicateDeviceLogon) {
      duplicateDeviceLogon.logonDomain = '';
      duplicateDeviceLogon.isGlobal = true;
    } else {
      //add a new device logon
      const newDeviceLogon = getDefaultDeviceLogon();
      newDeviceLogon.user = logonToToggle.user;
      newDeviceLogon.canBeMerged = true;
      newDeviceLogon.isGlobal = true;
      editUser.deviceLogons.push(newDeviceLogon);
    }
  } else {
    //remove the first duplicate device logon
    editUser.deviceLogons = editUser.deviceLogons.filter((dl) => {
      return dl !== duplicateDeviceLogon;
    });
  }
  return editUser;
};

export const toggleGlobalDeviceLogon = (
  editUser: IUserIdentity,
  logonToToggle: IUserLogon,
  isChecked: boolean
): IUserIdentity => {
  if (logonToToggle?.type === 'Agent') {
    return toggleAgentDeviceLogon(editUser, logonToToggle, isChecked);
  } else {
    if (isChecked) {
      //we are moving from a domain specific to a global rule
      const firstMatch = editUser.deviceLogons.find(
        (deviceLogin) => deviceLogin.user === logonToToggle.user
      );
      if (firstMatch) {
        firstMatch.isGlobal = true;
      }
    } else {
      //remove the global rule from all matching device logons
      editUser.deviceLogons.forEach((deviceLogin) => {
        if (deviceLogin.user === logonToToggle.user) {
          //resetting global rule reverts to the original domain.  if there isn't one, just set to space for user to edit
          deviceLogin.logonDomain = deviceLogin.originalLogonDomain;
          deviceLogin.isGlobal = false;
        }
      });
    }
  }

  return editUser;
};

export const validateEmailUpnArray = (
  vals: IValueValidation[],
  failedExplanation: string
): boolean => {
  if (vals) {
    runEmailUpnValidations(vals, failedExplanation);
    return vals.every((val) => val.validationFailed == false);
  }
  return true;
};

export const validateDeviceLogins = (vals: IDeviceLogon[]): boolean => {
  let passedValidation = true;
  vals?.forEach((deviceLogon) => {
    const domainLength = deviceLogon?.logonDomain?.trim().length ?? 0;
    const userLength = deviceLogon?.user?.trim().length ?? 0;
    //non global device logons must have a domain
    deviceLogon.validationFailed =
      !deviceLogon.isGlobal && domainLength == 0 && userLength > 0;
    if (deviceLogon.validationFailed) {
      deviceLogon.failedExplanation = ENTER_VALID_LOGON_DOMAIN;
      passedValidation = false;
    }
  });
  return passedValidation;
};

export const runEmailUpnValidations = (
  emails: IValueValidation[],
  failedExplanation: string
) => {
  let validationChanged = false;
  emails?.forEach((email) => {
    const priorValidationFailed = email.validationFailed;
    email.validationFailed = !isEmail(email.value);
    validationChanged =
      validationChanged || email.validationFailed !== priorValidationFailed;
    //don't set error message if new row hasn't been fully populated
    if (email.value && email.value.length > 0 && email.validationFailed) {
      email.failedExplanation = failedExplanation;
    } else {
      email.failedExplanation = '';
    }
  });
  return validationChanged;
};

export const validateEmail = (
  dataItem: IValueValidation,
  proposedValue: string
) => {
  dataItem.validationFailed = !isEmail(proposedValue);
  //don't set error message if new row hasn't been fully populated
  if (
    dataItem.validationFailed &&
    (dataItem.originalValue || (proposedValue && proposedValue.length > 0))
  ) {
    dataItem.failedExplanation = ENTER_VALID_EMAIL;
  }
};

export const validateEmployeeId = (
  dataItem: IValueValidation,
  proposedType: string,
  proposedValue: string
) => {
  if (proposedType == EMPLOYEE_ID_TYPE) {
    dataItem.validationFailed = !proposedValue || proposedValue.length == 0;
    if (dataItem.validationFailed) {
      if (
        dataItem.originalValue ||
        (proposedType &&
          proposedType.length > 0 &&
          proposedValue &&
          proposedValue.length > 0)
      ) {
        dataItem.failedExplanation = ENTER_VALID_EMPLOYEE_ID;
      }
    }
  }
};

export const validateValueChange = (
  dataItem: IValueValidation,
  proposedValue: string,
  selectedIdentity: IUserIdentity
) => {
  if (dataItem) {
    dataItem.validationFailed = false;
    dataItem.failedExplanation = '';
    validateAllFieldsPopulated(dataItem, proposedValue);
    validateEmail(dataItem, proposedValue);
    validateUnique(proposedValue, selectedIdentity, dataItem);
  }
};

export const GetNextUserIdFromArray = (
  nextPage: PageDirection,
  identities: number[],
  selectedIdentityId: number
): number => {
  let selectedIndex = identities?.findIndex((x) => x == selectedIdentityId);
  let nextIdentityId: number = -1;
  let iterationCount = 0;
  //skip over orphaned agents
  while (
    identities &&
    iterationCount < identities.length && //no infinite loops
    nextIdentityId == -1
  ) {
    ++iterationCount;
    if (nextPage == PageDirection.Previous) {
      selectedIndex =
        selectedIndex > 0 ? selectedIndex - 1 : identities.length - 1;
    } else {
      selectedIndex =
        selectedIndex >= identities.length - 1 ? 0 : selectedIndex + 1;
    }
    nextIdentityId = selectedIndex >= 0 ? identities[selectedIndex] : null;
  }

  return nextIdentityId;
};

export const GetNextUserId = (
  nextPage: PageDirection,
  identities: IUserIdentity[],
  selectedIdentityId: number
): number => {
  let selectedIndex = identities?.findIndex((x) => x.id == selectedIdentityId);
  let nextIdentity: IUserIdentity = null;
  let iterationCount = 0;
  //skip over orphaned agents
  while (
    identities &&
    iterationCount < identities.length && //no infinite loops
    (nextIdentity == null || !nextIdentity?.id)
  ) {
    ++iterationCount;
    if (nextPage == PageDirection.Previous) {
      selectedIndex =
        selectedIndex > 0 ? selectedIndex - 1 : identities.length - 1;
    } else {
      selectedIndex =
        selectedIndex >= identities.length - 1 ? 0 : selectedIndex + 1;
    }
    nextIdentity = selectedIndex >= 0 ? identities[selectedIndex] : null;
  }
  if (!nextIdentity?.id) {
    return -1;
  }
  return nextIdentity.id;
};

export const getMoveAgentArgs = (
  selectedAgentUserId: number,
  destination: IUserIdentity,
  newTracked: boolean
): IAgentMove => {
  if (destination) {
    const moveAgentArgs = {
      email: null,
      userid: { value: selectedAgentUserId.toString() },
      displayName: {
        value: destination.displayName?.value ?? destination.emailsDisplay
      },
      entity: { value: destination.id.toString() },
      revision: { value: destination.revision.toString() },
      tracking: newTracked
    };
    return moveAgentArgs;
  }
  return null;
};

const mapAgentToUserLogon = (agent: IUserAgent): IUserLogon => {
  return {
    user: agent.userName,
    domain: agent.logonDomain,
    type: 'Agent',
    userId: agent.userId,
    global: false,
    uniqueId: agent.userId.toString(),
    validationFailed: false,
    failedExplanation: '',
    canBeMerged: true
  };
};

const mapDeviceLogonToUserLogon = (deviceLogon: IDeviceLogon): IUserLogon => {
  return {
    user: deviceLogon.user,
    domain: deviceLogon.logonDomain,
    type: 'Device Logon',
    userId: -1,
    global: deviceLogon.isGlobal,
    uniqueId: deviceLogon.uniqueId,
    validationFailed: deviceLogon.validationFailed,
    failedExplanation: deviceLogon.failedExplanation,
    canBeMerged: deviceLogon.canBeMerged
  };
};

const shouldAddDeviceLogon = (
  deviceLogon: IDeviceLogon,
  combined: IUserLogon[]
): boolean => {
  const globalRule =
    deviceLogon.isGlobal && deviceLogon.user && deviceLogon.user.length > 0;
  let foundAgent = false;
  combined.forEach((agent) => {
    if (agent.user === deviceLogon.user) {
      if (
        deviceLogon.canBeMerged &&
        (globalRule || agent.domain === deviceLogon.logonDomain)
      ) {
        foundAgent = true;
        agent.global = globalRule;
        agent.validationFailed = deviceLogon.validationFailed;
        agent.failedExplanation = deviceLogon.failedExplanation;
      }
    }
  });
  if (!foundAgent && deviceLogon.canBeMerged) {
    const duplicate = combined.find(
      (userLogon) =>
        deviceLogon.user === userLogon.user &&
        userLogon.global &&
        userLogon.canBeMerged
    );
    return !duplicate;
  }
  return !foundAgent;
};

export const getCombinedDeviceLogonAgents = (
  tempUser: IUserIdentity
): IUserLogon[] => {
  const combined = [] as IUserLogon[];
  // add agents
  tempUser?.agents?.forEach((agent) => {
    combined.push(mapAgentToUserLogon(agent));
  });
  // add global device logons so we can eliminate duplicate domain specific device logins
  const globalDeviceLogons = tempUser?.deviceLogons?.filter(
    (dl) => !dl.logonDomain || dl.logonDomain === ''
  );
  globalDeviceLogons?.forEach((deviceLogon) => {
    if (shouldAddDeviceLogon(deviceLogon, combined)) {
      combined.push(mapDeviceLogonToUserLogon(deviceLogon));
    }
  });
  const domainSpecificDeviceLogons = tempUser?.deviceLogons?.filter(
    (dl) => dl.logonDomain && dl.logonDomain.length > 0
  );
  domainSpecificDeviceLogons?.forEach((deviceLogon) => {
    if (shouldAddDeviceLogon(deviceLogon, combined)) {
      combined.push(mapDeviceLogonToUserLogon(deviceLogon));
    }
  });

  return combined;
};

export const userHasMinimumRequiredFields = (
  newUser: IUserIdentity
): boolean => {
  const hasEmail =
    newUser?.emails?.length > 0 &&
    null !== trimValueIdOrNull(newUser?.emails[0]);
  const hasEmployeeId =
    newUser?.employeeIds?.length > 0 &&
    null !== trimValueIdOrNull(newUser?.employeeIds[0]);
  const hasUserPrincipalName =
    newUser?.upns?.length > 0 && null !== trimValueIdOrNull(newUser?.upns[0]);

  return hasEmail || hasEmployeeId || hasUserPrincipalName;
};

export const patchEditIdentityPathArgs = async (
  path: string,
  args?: object
): Promise<[any, IUserIdentity]> => {
  try {
    const newIdentityDto = await patchIdentityData<IUserIdentityDto>({
      path: path,
      args: args
    });

    const tempSelected = mapToIdentity(newIdentityDto);

    //todo, handle inline errors
    return [null, tempSelected];
  } catch (error) {
    return [error, null];
  }
};

export const userIdCount = (user: IUserIdentity): number => {
  let countIds = 0;
  if (user?.upns) {
    countIds += user.upns.filter((upn) => upn.value.length > 0).length;
  }
  if (user?.emails) {
    countIds += user.emails.filter((email) => email.value.length > 0).length;
  }
  if (user?.employeeIds) {
    countIds += user.employeeIds.filter(
      (employeeId) => employeeId.value.length > 0
    ).length;
  }
  if (user?.deviceLogons) {
    countIds += user.deviceLogons.filter(
      (deviceLogon) => deviceLogon.user.length > 0
    ).length;
  }
  return countIds;
};

export const getDefaultDeviceLogon = (): IDeviceLogon => {
  const DEVICE_LOGON_DEFAULT: IDeviceLogon = {
    user: '',
    logonDomain: '',
    uniqueId: '',
    validationFailed: false,
    failedExplanation: '',
    primary: false,
    originalUser: '',
    originalLogonDomain: '',
    canBeMerged: true,
    isGlobal: false
  };
  return { ...DEVICE_LOGON_DEFAULT, uniqueId: generateGuid() };
};
