import React from 'react';
import { Box, Typography } from '@mui/material';

import { AboutIntegrationRequirement } from './AboutIntegrationRequirement';
import { AboutIntegrationTemplates } from './AboutIntegrationTemplates';
import { AboutIntegrationGetStarted } from './AboutIntegrationGetStarted';
import { AboutModalProps } from '../models';

export const AboutGoogleCalendarModal = (props: AboutModalProps) => {
  const { description, exampleImage, exampleAltText } = props;
  return (
    <>
      {description && <Typography sx={{ mb: 2 }}>{description}</Typography>}
      {exampleImage &&
        <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
          <img height="200px" src={exampleImage} alt={exampleAltText} />
        </Box>}
      <AboutIntegrationRequirement>
        <ul>
          <li>
            A current paid ActivTrak subscription or an active trial
          </li>
          <li>
            At least one deployed ActivTrak Agent starting to collect activity
            data
          </li>
          <li>An active Google Cloud instance</li>
          <li>An active project set up within Google Cloud Platform</li>
          <li>
            <a
              href="https://support.google.com/a/answer/172176"
              target="_blank"
              rel="noopener noreferrer"
            >
              Super administrator permissions
            </a>{' '}
            to your active Google Workspace instance
            <ul>
              <li>
                Super admin is required for domain-wide access to all
                users&apos; calendar data
              </li>
            </ul>
          </li>
        </ul>
      </AboutIntegrationRequirement>
      <AboutIntegrationTemplates>None</AboutIntegrationTemplates>
      <AboutIntegrationGetStarted>
        <ul>
          <li>
            Review the{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/14157396964123"
              target="_blank"
              rel="noopener noreferrer"
            >
              Offline Meeting Time article
            </a>{' '}
            in our Help Center to understand how calendar data will appear in
            your account
          </li>
          <li>
            Follow the instructions in our{' '}
            <a
              href="https://support.activtrak.com/hc/en-us/articles/17461491382171"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setup Guide
            </a>{' '}
            to get started.
          </li>
        </ul>
      </AboutIntegrationGetStarted>
    </>
  );
};
