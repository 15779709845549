import React from 'react';
import { IGroupsColumnHeader } from '../models';
import { isNotATGroupType } from '../utils';
import { GroupTypeLabel } from '../../common/components/GroupType/GroupTypeLabel';
import Box from '@mui/material/Box';
import { DeleteGroupsButton } from '../components/DeleteGroupsButton';
import { WhiteBackgroundTooltip } from '../../common/components/Tooltip/TextWithTooltip.styles';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';

export const useGroupsColumns = (props: IGroupsColumnHeader): IGridColumn[] => {
  const { readonly, authorizationService, handleOpenDialog, setDeleteGroup  } = props;

  return [
    {
      field: 'name',
      fieldName: 'Group Name',
      headerName: 'Group Name',
      type: 'string',
      isSortable: true,
      width: '35%',
      template: (group) => {
        return(
           <WhiteBackgroundTooltip title = {group.name}>
                 <Box className="truncate">{group.name}</Box>
          </WhiteBackgroundTooltip>

      );
      }
    },
    {
      field: 'mix',
      fieldName: 'Source & Type',
      headerName: 'Source & Type',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (group) => {
        return <GroupTypeLabel iconSource={group.type} label={group.mix} />;
      }
    },
    {
      field: 'userCount',
      fieldName: 'Users',
      headerName: 'Users',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return (
          <Box className="truncate">
            <strong>{dataItem.userCount}</strong> {dataItem.userPreview}
          </Box>
        );
      }
    },
    {
      field: 'computerCount',
      fieldName: 'Computers',
      headerName: 'Computers',
      type: 'string',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return (
          <Box className="truncate">
            <strong>{dataItem.computerCount}</strong> {dataItem.computerPreview}
          </Box>
        );
      }
    },
    {
      type: 'any',
      isSortable: false,
      width: '5%',
      align: 'center',
      template: (group) => {
        const canDeleteGroup =
          !isNotATGroupType(group?.filter) &&
          authorizationService.canDeleteGroup();

        if (!canDeleteGroup) return <></>;

        return (
          <Box onClick={(event) => event.preventDefault()}>
            <DeleteGroupsButton
              group={group}
              readonly={readonly}
              handleOpenDialog={handleOpenDialog}
              setDeleteGroup={setDeleteGroup}
            />
          </Box>
        );
      }
    }
  ];
};
