import angular from 'angular';

import { verify } from '../../_reactivtrak/src/common/config/routing/routeDefinitions/verify/verify';
import { UI_ROUTER_REACT_HYBRID } from '../../_entry/router-hybrid';
import { getUserToken, setUserToken } from '../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { getUserName } from '../../_reactivtrak/src/common/services/Profile/useProfileState';
import { getAccountSettings } from '../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { analyticEventServiceFunctions } from '_app/serviceFunctions/analyticEventServiceFunctions';

angular.module('verify', [UI_ROUTER_REACT_HYBRID, 'core', 'config', 'utils']);
angular.module('verify').controller('VerifyCtrl', VerifyCtrl);

// TODO: Temporarily relocated this function here - will migrate to new analyitics module:
const createTrackerBasedName = (name) => {
    if (typeof window.ga.getAll !== 'function') {
        return name;
    }

    // Retrieve all trackers
    const trackers = window.ga.getAll();
    // Get name of first tracker
    const trackerName = (trackers && trackers[0] && trackers[0].get('name')) || '';
    // Append to function name and return
    return trackerName + '.' + name;
};

const accountVerified = (accountId, url) => {
    try {
        console.info('[accountVerified]:', accountId, url);
        if (window['ga']) {
            window.ga(createTrackerBasedName('send'), 'event', 'Account', 'Verified', accountId);
        }

        analyticEventServiceFunctions.newEvent('Verified', {
            category: 'Account',
            label: accountId,
            page: url.GetLocationWithUtm()
        });

        if (window['Intercom']) {
            window.Intercom('trackEvent', 'email-verified');
        }
    } catch (ex) {
        console.error('[accountVerified]:', ex);
    }
};

VerifyCtrl.$inject = ['$scope', '$state', 'verificationApiService', 'url'];

function VerifyCtrl($scope, $state, verificationApiService, url) {
    $scope.vtoken = $state.params.vtoken;
    var email = $state.params.email;

    $scope.verifyResult = function (result) {
        var verifyResult = result ? 'verifySuccess' : 'verifyError';
        var stateParams = { email };
        stateParams[verifyResult] = true;

        const token = getUserToken();

        if (token === 'invalid') {
            $state.go('login', stateParams);
        }

        // If logged in and email in url query does not match logged in email, logout user
        const userName = getUserName();

        if (email !== userName) {
            setUserToken('invalid');

            $state.go('login', stateParams);
            return;
        }

        const { usedLicenses } = getAccountSettings();
        const stateName = usedLicenses === 0 ? 'app.agentactivation' : 'app.dashboard';
        $state.go(stateName, stateParams);
    };

    verificationApiService
        .verifyByToken($scope.vtoken)
        .then(function (result) {
            accountVerified(result?.data.accountId, url);
            $scope.verifyResult(true);
        })
        .catch(function () {
            $scope.verifyResult(false);
        });
}

angular.module('verify').config([
    '$uiRouterProvider',
    ($uiRouterProvider) => {
        $uiRouterProvider.stateRegistry.register({
            name: verify.name,
            ...verify.stateDefinition
        });
    }
]);
