import {
  deleteReportingData,
  fetchReportingData,
  postReportingData
} from '../../helpers/apis/apiRequest';
import { ApiRoutes } from '../../constants/routesApi';
import {
  addExport,
  fetchAllExportStatuses,
  fetchExportStatus,
  getCompletedExport,
  getErroredExport,
  getExportStore,
  getInProgressExport,
  resetExportStore,
  setExportError,
  setExportStatusInterval
} from './exportStore';
import { STATUS_POLLING_INTERVAL } from './exportStore.constants';
import {
  ExportDestination,
  ExportValidationResult,
  ExportStatus,
  IColumnFilter,
  IExport,
  IExportReportParams,
  IExportRequest,
  IPaginationState
} from './exportStore.models';
import { showModal } from '../globalModalStore';
import { ExportErrorModalContent } from '../../../_global/modals/components/ExportErrorModalContent';
import { SaveExportModalContent } from '../../../_global/modals/components/SaveExportModalContent';
import { ExportInitiatedModalContent } from '../../../_global/modals/components/ExportInitiatedModalContent';
import { fetchGoogleAccessToken } from '../../utils/googleAuth.utils';
import { ReportType } from '../../enums/ReportType';
import { SortDirection } from '../../enums/SortDirection';

export const fetchAllExports = async (): Promise<IExport[]> => {
  const exportStatuses = await fetchReportingData<IExport[]>({
    path: ApiRoutes.exports.fetchAllExports
  });

  return exportStatuses;
};

export const fetchExport = async (exportId: string): Promise<IExport> => {
  const exportStatus = await fetchReportingData<IExport>({
    path: ApiRoutes.exports.fetchExport.replace(':id', exportId)
  });

  return exportStatus;
};

export const createExport = async (
  reportParams: IExportRequest
): Promise<IExport> => {
  return await postReportingData<IExport>({
    path: ApiRoutes.exports.create,
    args: reportParams
  });
};

export const cancelExport = async (id: string): Promise<void> => {
  if (!id) {
    throw new Error('Export id is required');
  }

  await postReportingData<void>({
    path: ApiRoutes.exports.cancel.replace(':id', id)
  });
};

export const deleteExport = async (id: string): Promise<void> => {
  await deleteReportingData<void>({
    path: ApiRoutes.exports.delete.replace(':id', id)
  });
};

export const initializeExportStore = async () => {
  const exportsArray = await fetchAllExportStatuses();

  if (exportsArray.length > 0) {
    const hasValidExports: boolean =
      validateExportStatusArray(exportsArray) === ExportValidationResult.Valid;

    if (hasValidExports) {
      const exportStatus =
        getInProgressExport() ?? getCompletedExport() ?? getErroredExport();
      if (exportStatus.status === ExportStatus.InProgress) {
        openExportInitiatedModal();
      }

      addExport(exportStatus);
      startExportStatusCheck(exportStatus.id);
    } else {
      console.error(
        'ActivTrak Error: Invalid values in the export response object - ',
        JSON.stringify(exportsArray)
      );
      clearAndResetExportStore();
      return;
    }
  }
};

export const startExportStatusCheck = async (exportId: string) => {
  clearExportStatusInterval(exportId);

  const exportStatusInterval = setInterval(async () => {
    try {
      const exportStatus = await fetchExportStatus(exportId);

      if (validateExport(exportStatus) !== ExportValidationResult.Valid) {
        throw new Error(
          `Invalid export status returned - ${JSON.stringify(exportStatus)}`
        );
      }

      if (exportStatus.status !== ExportStatus.InProgress) {
        clearExportStatusInterval(exportId);
      }

      if (exportStatus.status === ExportStatus.Completed) {
        if (!exportStatus.downloadUrl) {
          throw new Error('Download URL not found.');
        }

        if (!exportStatus.id) {
          throw new Error('Download ID not found.');
        }

        if (!exportStatus.destination) {
          throw new Error('Download destination not found.');
        }

        showModal(<SaveExportModalContent exportStatus={exportStatus} />, true);
        return;
      }

      if (exportStatus.status === ExportStatus.Failed) {
        console.error('ActivTrak: Export failed', exportStatus);
        throw new Error('Export status of failed returned.');
      }
    } catch (error) {
      console.error('ActivTrak Error: Error polling export status', error);
      clearExportStatusInterval(exportId);
      deleteExport(exportId);
      showModal(<ExportErrorModalContent />);
      // TEMPORARY FIX: Set export error to true to change exoprt button text back to 'Export'
      setExportError(true);
      return;
    }
  }, STATUS_POLLING_INTERVAL);

  setExportStatusInterval(exportId, exportStatusInterval);
};

export const clearExportStatusInterval = (exportId: string) => {
  const { exportStatusIntervals } = getExportStore();
  const exportStatusInterval = exportStatusIntervals[exportId];
  clearInterval(exportStatusInterval);
  setExportStatusInterval(exportId, null);
};

export const clearAndResetExportStore = () => {
  const { exportStatusIntervals } = getExportStore();
  Object.values(exportStatusIntervals).forEach((interval) => {
    clearInterval(interval);
  });
  resetExportStore();
};

export const openExportInitiatedModal = () => {
  showModal(<ExportInitiatedModalContent />);
};

export const startExport = async (
  reportParams: IExportReportParams,
  exportDestination: ExportDestination
) => {
  const exportRequest: IExportRequest = {
    destination: exportDestination,
    googleDriveToken:
      exportDestination === ExportDestination.GoogleDrive
        ? await fetchGoogleAccessToken()
        : null,
    report: reportParams
  };

  try {
    const exportStatus = await createExport(exportRequest);

    if (validateExport(exportStatus) === ExportValidationResult.Valid) {
      addExport(exportStatus);
      startExportStatusCheck(exportStatus.id);
      openExportInitiatedModal();
    } else {
      throw new Error('Invalid export status returned.');
    }
  } catch (error) {
    console.error('ActivTrak Error: Failed to initiate export request:', error);
    showModal(<ExportErrorModalContent />);
  }
};

export const validateExport = (oneExport: IExport): string => {
  if (!oneExport || oneExport === null || typeof oneExport !== 'object') {
    return ExportValidationResult.InvalidExport;
  }
  const requiredProperties: {
    [key in keyof Partial<IExport>]: (value: any) => boolean;
  } = {
    id: (value: string) => typeof value === 'string' && value.trim() !== '',
    status: (value: ExportStatus) =>
      Object.values(ExportStatus).includes(value),
    destination: (value: ExportDestination) =>
      Object.values(ExportDestination).includes(value),
    reportType: (value: ReportType) => Object.values(ReportType).includes(value)
  };

  for (const [prop, validate] of Object.entries(requiredProperties)) {
    if (
      !Object.prototype.hasOwnProperty.call(oneExport, prop) ||
      !validate(oneExport[prop])
    ) {
      return `${ExportValidationResult.InvalidProp}_${prop}`;
    }
  }

  return ExportValidationResult.Valid;
};

export const validateExportStatusArray = (exportsArray: IExport[]): string => {
  if (!exportsArray || !Array.isArray(exportsArray)) {
    return ExportValidationResult.InvalidArrayOfExports;
  }

  if (
    exportsArray.some(
      (exportItem) =>
        validateExport(exportItem) !== ExportValidationResult.Valid
    )
  ) {
    return ExportValidationResult.InvalidExportInArray;
  }

  return ExportValidationResult.Valid;
};

export const validateReportPayload = (
  reportPayload: IExportReportParams,
  exportDestination: ExportDestination
): ExportValidationResult | string => {
  if (typeof reportPayload !== 'object' || reportPayload === null) {
    return ExportValidationResult.InvalidReportPayload;
  }

  if (!Object.values(ExportDestination).includes(exportDestination)) {
    return ExportValidationResult.InvalidDestination;
  }

  if (reportPayload.hasDataForTable === false) {
    return ExportValidationResult.InvalidReportNoData;
  }

  // This is a custom validation for ScheduleAdherence report type - it has different required properties and it would be too much work to remove the userMode from the existing requiredProperties object
  if (reportPayload.type === ReportType.ScheduleAdherence) {
    return validateScheduleAdherenceExportPayload(
      reportPayload,
      exportDestination
    );
  }

  const requiredProperties = {
    type: (value: ReportType) => Object.values(ReportType).includes(value),
    startDate: (value: string) => value === null || typeof value === 'string',
    endDate: (value: string) => value === null || typeof value === 'string',
    userType: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    userMode: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    userId: (value: string | number) =>
      value === null || typeof value === 'string' || typeof value === 'number'
  };

  for (const [prop, validate] of Object.entries(requiredProperties)) {
    if (
      !Object.prototype.hasOwnProperty.call(reportPayload, prop) ||
      !validate(reportPayload[prop])
    ) {
      return `${ExportValidationResult.InvalidRequiredProp}_${prop}`;
    }
  }

  const optionalProperties = {
    sortDirection: (value: SortDirection) =>
      Object.values(SortDirection).includes(value),
    groupBy: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    productivity: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    limitToPage: (value: IPaginationState) =>
      typeof value === 'object' &&
      value !== null &&
      typeof value.index === 'number' &&
      typeof value.size === 'number',
    sortField: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    filter: (value: IColumnFilter) => {
      if (value === undefined) {
        return true;
      }
      if (typeof value !== 'object' || value === null) {
        return false;
      }
      const { logic, filters } = value;
      if (typeof logic !== 'string' || !Array.isArray(filters)) {
        return false;
      }
      return true;
    }
  };

  for (const [prop, validate] of Object.entries(optionalProperties)) {
    if (reportPayload[prop] !== undefined && !validate(reportPayload[prop])) {
      return `${ExportValidationResult.InvalidOptionalProp}_${prop}`;
    }
  }

  return ExportValidationResult.Valid;
};

export const validateScheduleAdherenceExportPayload = (
  payload: IExportReportParams,
  exportDestination: ExportDestination
): ExportValidationResult | string => {
  if (typeof payload !== 'object' || payload === null) {
    return ExportValidationResult.InvalidReportPayload;
  }

  if (!Object.values(ExportDestination).includes(exportDestination)) {
    return ExportValidationResult.InvalidDestination;
  }

  if (payload.hasDataForTable === false) {
    return ExportValidationResult.InvalidReportNoData;
  }

  const requiredProperties = {
    type: (value: ReportType) => Object.values(ReportType).includes(value),
    startDate: (value: string) => value === null || typeof value === 'string',
    endDate: (value: string) => value === null || typeof value === 'string',
    userType: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    userId: (value: string | number) =>
      value === null || typeof value === 'string' || typeof value === 'number',
    shiftStart: (value: string) =>
      typeof value === 'string' && value.trim() !== '',
    shiftEnd: (value: string) =>
      typeof value === 'string' && value.trim() !== ''
  };

  for (const [prop, validate] of Object.entries(requiredProperties)) {
    if (
      !Object.prototype.hasOwnProperty.call(payload, prop) ||
      !validate(payload[prop])
    ) {
      return `${ExportValidationResult.InvalidRequiredProp}_${prop}`;
    }
  }

  return ExportValidationResult.Valid;
};
