import { Role, RoleAccessKeys } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../enums/FeatureFlag';
import { IRoute } from '../../../../models/IRoute';
import { impactPromoTemplate as angularJsTemplate } from '../templates';

export const appImpactpromo: IRoute = {
  name: 'app.impactpromo',
  stateDefinition: {
    url: '/impactpromo',
    template: angularJsTemplate,
    controller: 'impactPromoCtrl',
    data: { pageTitle: 'Impact' }
  },
  access: {
    redirectTo: 'app.impact',
    roleAccessKey: RoleAccessKeys.Impact,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    excludedBundleFlags: [BundleFlag.ImpactAnalysisInsightsReport],
    featureFlags: [FeatureFlag.ShowPromos]
  }
};

export default appImpactpromo;
