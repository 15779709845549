import { getUserName } from "../../../_reactivtrak/src/common/services/Profile/useProfileState";
import { remove } from 'lodash';

angular.module('app').service('pageSizeService', PageSizeService);

PageSizeService.$inject = [
    '$rootScope',
    '$q',
    'localStorageService'
];

function PageSizeService($rootScope, $q, localStorageService) {
    this.dataSourceReload = function(dataSource, options) {
        var dataSources = dataSource;
        if (Object.prototype.toString.call(dataSource) !== '[object Array]') {
            dataSources = [dataSource];
        }

        var filter = options || {};
        filter.page = 1;
        filter.skip = 0;

        if (dataSources.length === 1) {
            dataSource._skip = 0;
            return dataSource.read(filter);
        } else {
            var deferred = $q.defer();
            var dataSourcesInWork = { dataSources: [] };
            var isResolved = false;

            angular.forEach(dataSources, function(ds, index) {
                ds._skip = 0;
                dataSourcesInWork.dataSources.push(index);

                ds.read(filter).then(function() {
                    dataSourcesInWork.dataSources = remove(
                        dataSourcesInWork.dataSources,
                        function(n) {
                            return n !== index;
                        }
                    );

                    if (
                        dataSourcesInWork.dataSources.length < 1 &&
                        !isResolved
                    ) {
                        isResolved = true;
                        deferred.resolve();
                    }
                });
            });

            return deferred.promise;
        }
    };

    this.loadPageSize = function(name, defaultSize) {
        var pageSize = localStorageService.get(
            getUserName() + '-pageSize-' + name
        );
        if (pageSize === 'all') {
            return undefined;
        }
        return parseInt(pageSize) || defaultSize;
    };

    this.setDropdownHandler = function(name, rootSelector) {
        $(rootSelector || '.k-pager-wrap')
            .get()
            .forEach(function(pagerWrapperEntry) {
                var $pagerWrapper = $(pagerWrapperEntry);
                var pageSizeDropDownList = $pagerWrapper
                    .find('.k-pager-sizes')
                    .find('select')
                    .data('kendoDropDownList');

                if (pageSizeDropDownList) {
                    pageSizeDropDownList.unbind('change');

                    pageSizeDropDownList.bind('change', function(e) {
                        localStorageService.set(
                            getUserName() + '-pageSize-' + name,
                            e.sender.value()
                        );
                    });
                }
            });
    };
}
