import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Tabs, Tab } from '@mui/material';
import TableView from '../../components/TableView/TableView.jsx';
import ListView from '../../components/ListView/ListView.jsx';
import DashboardEmptyMessage from '../../components/DashboardEmptyMessage/DashboardEmptyMessage.jsx';
import {
  editChildAccounts,
  getChildAccountsMinimal
} from '../../utils/api/commandCenterApi.js';
import { ccDashboardClasses } from './Dashboard.styles.js';
import { skeletonCards } from './SkeletonCards.js';
import Sorting from '../../components/Sorting/Sorting.jsx';
import { handleRequestSort } from '../../utils/api/sortChildAccounts.js';
import {
  getDashboardView,
  setDashboardView
} from '../../utils/globalFilters.js';

const DEFAULT_LOADING_CARDS = skeletonCards;
const Dashboard = ({
  dashboardType,
  profile,
  onError,
  openDialog,
  onSuccess,
  updateUserCount,
  accounts
}) => {
  document.title = 'Command Center | ActivTrak';
  const [tab, setTab] = useState(0);
  const [childAccounts, setChildAccounts] = useState(DEFAULT_LOADING_CARDS);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const [extLoaded, setExtLoaded] = useState(false);

  useEffect(() => {
    if (profile && profile.username) {
      setTab(getDashboardView(profile.username));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (accounts) {
      loadChildAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(
    () => {
      const loadMinimalChildAccounts = async () => {
        setLoading(true);
        setChildAccounts(DEFAULT_LOADING_CARDS);
        try {
          const resultChildAccounts = await getChildAccountsMinimal(
            dashboardType
          );
          setChildAccounts(resultChildAccounts);
          setLoading(false);
        } catch (e) {
          onError(e);
        }
      };

      if (!extLoaded) {
        loadMinimalChildAccounts();
      } else {
        loadChildAccount();
      }
    },
    // TODO: dependencies should actually be [dashboardType, canUpdateCount, onError, childAccounts, updateUserCount], but this breaks 'Should render the correct document title' unit test. Need to rethink this code block and/or test.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dashboardType]
  );

  const loadChildAccount = () => {
    setChildAccounts(accounts);
    const activeUsersCount = getActiveAgentsCount(accounts);
    updateUserCount(activeUsersCount);
    setActiveUsers(activeUsersCount.toString());
    setLoading(false);
    setExtLoaded(true);
  };

  const updateCompanyName = async (accountId, companyName) => {
    try {
      await editChildAccounts(accountId, companyName);
      const editedChildAccounts = childAccounts.map((account) => {
        if (account.accountId === accountId) {
          account.companyName = companyName;
        }
        return account;
      });
      setChildAccounts(editedChildAccounts);
      onSuccess('Company Name updated successfully');
    } catch (e) {
      onError(e);
    }
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    if (event.currentTarget.textContent) {
      const sortedChildAccounts = handleRequestSort(childAccounts, event);
      setChildAccounts(sortedChildAccounts);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getActiveAgentsCount = (customers) => {
    return customers.reduce((ac, cv) => {
      return ac + cv.userCount;
    }, 0);
  };

  const renderView = (tab) => {
    if (!childAccounts || !childAccounts.length) {
      return (
        <Box sx={ccDashboardClasses.empty}>
          <DashboardEmptyMessage
            dashboardType={dashboardType}
            openDialog={openDialog}
            profile={profile}
          />
        </Box>
      );
    }

    if (!tab || loading) {
      return (
        <TableView
          customers={childAccounts}
          loading={loading}
          onError={onError}
          updateCompanyName={updateCompanyName}
          extLoaded={extLoaded}
        />
      );
    }

    return (
      <ListView
        customers={childAccounts}
        activeUsers={activeUsers}
        onError={onError}
      />
    );
  };

  const renderTabs = () => {
    if (loading) {
      return (
        <Box sx={ccDashboardClasses.skeletonTabs}>
          <Box width="5rem" height="2rem" mr="1rem">
            <Skeleton variant="rect" height="100%" animation="wave" />
          </Box>
          <Box width="5rem" height="2rem" mr="1rem">
            <Skeleton variant="rect" height="100%" animation="wave" />
          </Box>
        </Box>
      );
    }

    if (!childAccounts || !childAccounts.length) {
      return null;
    }

    return (
      <Box sx={ccDashboardClasses.content}>
        <Box>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Grid" />
            <Tab label="List" />
          </Tabs>
        </Box>
        <Box sx={ccDashboardClasses.position}>
          <Sorting
            anchorEl={anchorEl}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
          />
        </Box>
      </Box>
    );
  };

  const handleChange = (event, newTab) => {
    setTab(newTab);
    if (profile) {
      setDashboardView(newTab, profile.username);
    }
  };

  return (
    <Box>
      <Box sx={ccDashboardClasses.dashboardWrapper}>
        <Box sx={ccDashboardClasses.dashboardHeader}>
          <Box className="dashboard-toggle-view">{renderTabs()}</Box>
        </Box>
        <Box className="dashboard-content">{renderView(tab)}</Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
