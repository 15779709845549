import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { hideModal } from '../../../common/stores/globalModalStore';
import { newEvent } from '../../../common/analytics/eventHeap';
import Link from '../../../common/components/Link';
import { LocationTable } from '../assets/images';
import { showTryLocationMarketoForm } from '../utils/marketo';
import { GlobalModalContent } from '../templates/GlobalModalContent';
import { locationPromoText } from '../constants/WorkingHoursPromo';
import { PrimaryButton, OutlinedButton } from '../styles/ModalBtn.styles';
import {
  fontColor800,
  fontColor900,
  mediumFontWeight
} from '../../../common/constants';
import { getInjector } from '../../../common/third-party/angular2react/useInjector';

export const LocationPromoModal = () => {
  return (
    <GlobalModalContent>
      <Typography
        mb={3}
        sx={{
          fontSize: '20px',
          color: fontColor900,
          fontWeight: mediumFontWeight
        }}
      >
        {locationPromoText.title}
      </Typography>
      <Typography sx={{ color: fontColor800 }} mb={3}>
        {locationPromoText.blurb}
      </Typography>
      <Box mx="auto" display="flex" alignItems="center" justifyContent="center">
        <img
          width="550"
          src={LocationTable}
          alt="three-column chart, first being the user names, second being the user's work location, and third being productivity duration'"
        />
      </Box>
      <Grid
        container
        direction="column"
        display="flex"
        alignItems="flex-end"
        mt={3}
      >
        <Grid item>
          <OutlinedButton
            variant="outlined"
            onClick={() => {
              hideModal();
              newEvent('WHRLocationCTA', { cta: 'marketo' });
              showTryLocationMarketoForm();
            }}
            id="be5de115-54a0-493e-b546-4bbc66a53aa7"
          >
            Try for Free
          </OutlinedButton>
          <PrimaryButton
            onClick={() => {
              hideModal();
              newEvent('WHRLocationCTA', { cta: 'upgrade' });

              const $injector = getInjector();
              const $state: any = $injector.get('$state');
              $state.go('app.account.upgrade');
            }}
            id="2c6c48f9-6746-46e7-9e26-8f570fc0e622"
          >
            Upgrade to Professional
          </PrimaryButton>
        </Grid>
        <Grid item mt={3}>
          <Typography sx={{ color: fontColor800 }}>
            To learn more about {locationPromoText.type},{' '}
            <Link
              href={locationPromoText.link}
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              click here
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </GlobalModalContent>
  );
};
