import { useState } from 'react';
import { useSideBarNavigation } from '../../../hooks/useSideBarNavigation';
import { NavigationFeatureBadge } from './NavigationFeatureBadge';
import { FavoritesStar } from './FavoritesStar';
import { toggleFavorite } from '../favoritesStore';
import { DeleteFilled, MenuItemLabel } from './SideBar.styles';
import { InvalidFavoriteModal } from '../InvalidFavoriteModal';

export const FavoritesNavigationItem = (props: any) => {
  const { item: favorite, subindex } = props;

  const { navigate, canNavigate } = useSideBarNavigation();
  const [showInvalidFavoriteModal, setShowInvalidFavoriteModal] =
    useState(false);

  const isExternal = (item: any) => item?.action?.type === 'external';

  const handleFavoriteNavigate = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    mappedFavorite: any
  ): void => {
    e.preventDefault();
    const favoriteName = mappedFavorite?.action?.options?.ref;

    if (favoriteName === 'app.downloads') {
      navigate({
        action: {
          options: {
            ref: 'app.downloads'
          }
        }
      });
      return;
    }

    const item = mappedFavorite;

    if (!isExternal(item) && !canNavigate(favoriteName)) {
      setShowInvalidFavoriteModal(() => true);
      return;
    }

    setShowInvalidFavoriteModal(() => false);
    navigate(item);
  };

  return (
    <>
      <li id={`favorites_${favorite.id}`} key={subindex}>
        <div className="at-menu-item-container">
          <div className="at-menu-item-row">
            <a
              className="at-flex-container at-menu-item at-pointer"
              onClick={(e) => handleFavoriteNavigate(e, favorite)}
            >
              {/* this is to use the same space as the rest of the items */}
              <div className="at-flex-child at-menu-item-icon">
                <i></i>
                <NavigationFeatureBadge {...favorite} />
              </div>

              <MenuItemLabel
                level={1}
                className={`at-flex-child ${
                  favorite.invalid ? 'at-flex-child-invalid' : ''
                }`}
              >
                {favorite.label}

                {favorite.invalid ? (
                  <DeleteFilled
                    className="pull-right icon-star"
                    fontSize="small"
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <FavoritesStar
                    id={`nav_star_${favorite.id}`}
                    favorited={true}
                    onClick={ async (e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      await toggleFavorite('navigation')({
                        id: favorite.id,
                        name: favorite.action.options.ref
                      });
                    }}
                  />
                )}
              </MenuItemLabel>
            </a>
          </div>
        </div>
      </li>
      <InvalidFavoriteModal
        open={showInvalidFavoriteModal}
        onClose={() => setShowInvalidFavoriteModal(() => false)}
        removeFavorite={async () => {
          await toggleFavorite('invalid')({
            id: favorite.id,
            name: favorite.action.options.ref
          });

          setShowInvalidFavoriteModal(() => false);
        }}
      />
    </>
  );
};
