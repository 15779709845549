export const getSessionStorageItem = (storageItem: string): string => {
  try {
    return window.sessionStorage && window.sessionStorage.getItem(storageItem);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot get sessionStorage item\nItem: ${storageItem}\nError: `,
      error
    );
    return null;
  }
};

export const setSessionStorageItem = (
  storageItem: string,
  value: any
): void => {
  try {
    window.sessionStorage && window.sessionStorage.setItem(storageItem, value);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot set sessionStorage item\nItem: ${storageItem}\nError: `,
      error
    );
  }
};
