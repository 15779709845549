import appVersion from '../../../../version.json';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import { getScreenshotSettingsStore } from '../../../../_reactivtrak/src/common/stores/screenshotSettingsStore';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').controller('SupportModalCtrl', SupportModalCtrl);

SupportModalCtrl.$inject = [
    '$window',
    '$scope',
    '$uibModalInstance',
    'constantsService',
    'authorizationService',
    'templateServiceFunctions',
    'dataCenterService',
    'envConfig'
];

function SupportModalCtrl(
    $window,
    $scope,
    $uibModalInstance,
    constant,
    authorizationService,
    templateServiceFunctions,
    dataCenterService,
    envConfig
) {
    var {
        account,
        unixCreationTime,
        currentTimeZone,
        currentIanaTimeZone,
        bundleFlags,
        hasFailedPayment,
        isSubscriptionCancelled,
        isVerified,
        autoUpdate,
        macAutoupdate,
        dataCollection,
        msaAcceptanceRequired,
        state,
        delayedDelete,
        userMergeEnabled,
        isDataConsoleEnabled,
        isPreaggregationEnabled,
        databaseName
    } = getAccountSettings();
    var { username } = profileStore.getState().profile;

    $scope.sections;
    $scope.supportRole = authorizationService.hasRole(authorizationService.roles.supportBasic);
    $scope.activTrakId = username;
    $scope.accountNumber = account;
    var version = (appVersion && appVersion.version) || 'x.x.x';

    // eslint-disable-next-line no-undef
    var commitHash = __COMMIT_HASH__;
    $window.commitHash = commitHash;

    // Convert unix time to moment and generate creation length text
    if (unixCreationTime && unixCreationTime > 0) {
        var creationDateObject = moment.unix(unixCreationTime);
        var creationLengthString = ' (' + templateServiceFunctions.getCreationLengthString(creationDateObject) + ')';
        $scope.creationDate = creationDateObject.format('Y-MM-DD hh:mm:ss A Z') + creationLengthString;
    } else {
        $scope.creationDate = 'Unknown';
    }

    $scope.getColor = function (value) {
        return typeof value === 'boolean'
            ? value
                ? constant.get('color', 'COLOR-SUCCESS')
                : constant.get('color', 'COLOR-DANGER')
            : constant.get('color', 'COLOR-COMPLETE');
    };

    $scope.getFieldValue = function (field) {
        var value = typeof field.value === 'function' ? field.value() : field.value;

        if (field.subParam) {
            value = value[field.subParam];
        }

        return value;
    };

    function timeFormat(offset) {
        return Math.floor(offset / 60) + ':' + (new Array(2).join('0') + (Math.abs(offset) % 60)).slice(-2);
    }

    function getTimezone() {
        return (
            currentTimeZone +
            ' (UTC' +
            ('-' + timeFormat(moment.tz.zone(currentIanaTimeZone).utcOffset(new Date()))).replace('--', '+') +
            ')'
        );
    }

    function loadSections() {
        var mappedBundleFlags = Object.entries(bundleFlags)
            .map(function ([key, value]) {
                return { label: key, value: value };
            })
            .sort(function (a, b) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            });

        const { screenshotFeatureFlag } = getScreenshotSettingsStore();

        $scope.sections = [
            {
                title: 'Account Flags',
                fields: [
                    {
                        label: 'Paid Account',
                        value: authorizationService.hasFeature('isPaidFunctionalityEnabled')
                    },
                    {
                        label: 'Failed Payment',
                        value: hasFailedPayment
                    },
                    {
                        label: 'Plan Cancelled',
                        value: isSubscriptionCancelled
                    },
                    {
                        label: 'Verified',
                        value: isVerified
                    },
                    {
                        label: 'Auto Update',
                        value: autoUpdate
                    },
                    {
                        label: 'Mac Auto Update',
                        value: macAutoupdate
                    },
                    {
                        label: 'Data Collection',
                        value: dataCollection
                    },
                    {
                        label: 'MSA Acceptance Required',
                        value: msaAcceptanceRequired
                    },
                    {
                        label: 'Screen View',
                        value: screenshotFeatureFlag
                    },
                    {
                        label: 'State',
                        value: state
                    },
                    {
                        label: 'Command Center',
                        value: $scope.hasCommandCenter
                    }
                ]
            },
            {
                title: 'Feature Flags',
                fields: [
                    {
                        label: 'Screenshot Redaction Enabled',
                        value: authorizationService.hasFeature(BundleFlag.ScreenshotRedaction)
                    },
                    {
                        label: 'Screenshot Flagging Enabled',
                        value: authorizationService.hasFeature(BundleFlag.ScreenshotFlagging)
                    },
                    {
                        label: 'ActivInsights Enabled',
                        value: authorizationService.hasFeature(FeatureFlag.InsightsEnabled)
                    },
                    {
                        label: 'ActivInsights Available',
                        value: authorizationService.hasFeature(FeatureFlag.InsightsAvailable)
                    },
                    {
                        label: 'Video Alarms Enabled',
                        value: authorizationService.hasFeature(FeatureFlag.VideoPlayback)
                    },
                    {
                        label: 'Delay Delete',
                        value: delayedDelete
                    },
                    {
                        label: 'User Merge',
                        value: userMergeEnabled
                    }
                ]
            },
            {
                title: 'Data Flags',
                fields: [
                    {
                        label: 'Data Connect Enabled',
                        value: isDataConsoleEnabled
                    },
                    {
                        label: 'Preaggregation Enabled',
                        value: isPreaggregationEnabled
                    },
                    {
                        label: 'Time Zone',
                        value: getTimezone
                    }
                ]
            },
            {
                title: 'Region Info',
                fields: [
                    {
                        label: 'Region',
                        value: dataCenterService.region,
                        subParam: 'name'
                    },
                    {
                        label: 'Database',
                        value: databaseName
                    },
                    {
                        label: 'API Type',
                        value: dataCenterService.regionType
                    },
                    {
                        label: 'API Url',
                        value: envConfig.apiUrl
                    }
                ]
            },
            {
                title: 'App Info',
                fields: [
                    {
                        label: 'Version',
                        value: version + '_' + commitHash
                    }
                ]
            },
            {
                title: 'Bundle Flags',
                fields: mappedBundleFlags
            }
        ];

        return;
    }
    loadSections();

    $scope.refresh = function () {
        loadSections();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };
}
