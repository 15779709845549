import { IRealtimeSettingsStore } from './realtimeSettingsStore.models';
import { getRealtimeSettingsDtoCache } from '../realtimeSettingsDtoCacheStore/realtimeSettingsDtoCacheStore';
import { REALTIME_SETTINGS_STORE_DEFAULT } from './realtimeSettingsStore.constants';
import { IRealtimeSettingsDtoCache } from '../realtimeSettingsDtoCacheStore/realttimeSettingsDtoCacheStore.models';

const mapRealtimeSettingsDtoStoreToRealtimeSettingsStore = (
  realtimeSettingsDtoStore: IRealtimeSettingsDtoCache
): IRealtimeSettingsStore => {
  const {
    realtimePollDelay,
    realtimePollAfterError,
    thumbnailPollDelay,
    messageDelayThreshold,
    messageTimeoutThreshold,
    lastSeenThreshold
  } = realtimeSettingsDtoStore.realtimeSettings;

  return {
    realtimePollDelay: realtimePollDelay,
    realtimePollAfterError: realtimePollAfterError,
    thumbnailPollDelay: thumbnailPollDelay,
    messageDelayThreshold: messageDelayThreshold,
    messageTimeoutThreshold: messageTimeoutThreshold,
    lastSeenThreshold: lastSeenThreshold
  };
};

export const fetchSettings = async (): Promise<IRealtimeSettingsStore> => {
  try {
    const response = await getRealtimeSettingsDtoCache();
    return mapRealtimeSettingsDtoStoreToRealtimeSettingsStore(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Error fetching screenshot settings.',
      error
    );
    return REALTIME_SETTINGS_STORE_DEFAULT;
  }
};
