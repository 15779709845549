import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { getUserClaims, setUserToken } from '../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { LoadApplication } from '../../_reactivtrak/src/common/services/Application/AppLoad';
import { getAccountId } from '../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { rewire } from '../../_entry/rewire';

angular.module('login').controller('SignupLoginCtrl', SignupLoginCtrl);

SignupLoginCtrl.$inject = ['$state', '$stateParams', 'loginService'];

function SignupLoginCtrl($state, $stateParams, loginService) {
    function processError(errorMessage) {
        console.error(errorMessage);

        setUserToken('invalid');
        $state.go('login', {
            logoutReason:
                'Unable to connect to your account. <br/> For help, <a target="_blank" href="#/app/help/tickets">contact support</a>'
        });
    }

    // Invalidate previous token
    setUserToken('invalid');

    const token = $stateParams.key;

    if (!token) {
        processError('ActivTrak Error: Unable to login to new account due to missing token.\nToken: ' + token);
        return;
    }

    setUserToken(token);

    const claims = getUserClaims();

    if (claims === 'invalid') {
        processError('[ActivTrak Error]: Invalid claims.');
        return;
    }

    const expired = moment().diff(userServiceFunctions.getTokenExpiration(), 'seconds') > 0;
    if (expired) {
        processError('ActivTrak Error: Unable to login to new account due to an expired token');
        return;
    }

    (async () => {
        try {
            await LoadApplication();

            const accountId = getAccountId();
            window.localStorage.setItem(`activTrak.newAccount_${accountId}`, 'true');

            // added to control wizard shown when doing regression tests.
            const showWizardOverride = window.localStorage.getItem('activTrak.show_onboarding_wizard') !== 'false';

            if (showWizardOverride) {
                loginService.enableAccountWizard();
            }

            rewire();
        } catch (e) {
            console.error(e);
            processError(e);
        }
    })();
}
