import { http } from '_app/http';

const _goalMin = 0.5;
const _goalMax = 24;

const _fixLookerEscapedFilter = (str) => {
    if (str && str.startsWith("\"") && str.endsWith("\""))
        return str.replace(/^"|"$/g, '').replace(/\\"/g, '"');
    else 
        return str;
}

class GoalControl {
    constructor(
        envConfig,
        showNotification,
        dashboard,
        groups,
        metrics,
        safeApply
    ) {
        this.dashboard = dashboard;
        this.envConfig = envConfig;
        this.showNotification = showNotification;
        this.goal = null;
        this.goalInput = null;
        this.showProgress = false;
        this.allGroups = groups;
        this.allMetrics = metrics;
        this.safeApply = safeApply;
        this.group = null;
        this.metric = null;
    }

    getFilters() {
        return _.isEmpty(this.dashboard.embeddedFilters) ?
            this.dashboard.decodeFilters(this.dashboard.filters) :
            this.dashboard.embeddedFilters;
    }

    getFilter(filterName) {
        if (filterName.toLowerCase() === 'team') {
            //Gaals don't apply to multiple Teams
            if (!this.isMultipleTeams()) {
                return _fixLookerEscapedFilter(this.getFilters()[filterName]);
            }
            return null;
        }
        else { //'metric'
            return this.getFilters()[filterName].replaceAll('^', '');
        }
    }

    setGoalInput() {
        const teamFilter = this.getFilter('Team');
        
        if (teamFilter) {
            const metricFilter = this.getFilter('Metric');

            this.group = this.allGroups.filter((g) => g.name === teamFilter)[0];
            this.metric = this.allMetrics.filter((m) => m.name === metricFilter)[0];
            if (this.group && this.metric) {
                this.getGoal();
            }
            else {
                this.setGoal(null);
            }
        }
        else {
            this.setGoal(null);
        }
    }

    setGoal(goal) {
        if (goal && typeof goal !== 'number') {
            try {
                goal = parseFloat(parseFloat(goal).toFixed(1));
            } catch (e) {
                console.error('ActivTrak Error: Error converting goal to number', goal, e);
                goal = this.goal;
            }
        }

        goal = goal && Math.min(_goalMax, Math.max(_goalMin, goal));
        const valueChanged = goal !== this.goal;
        const plural = goal === 1 ? '' : 's';
        goal = goal ? goal.toString() : null;
        this.goal = goal;
        this.goalInput = this.goal ? `${this.goal} Hour${plural}` : null;

        return Boolean(valueChanged && this.goal);
    }

    goalInputBlur() {
        const newGoal = parseFloat(this.goalInput).toFixed(1);

        if (this.setGoal(newGoal)) {
            this.updateGoal();
        }
    }

    /**
     * Blur input when enter key pressed
     * @param {object} e
     */
    goalInputKeyPressed(e) {
        var key = e.keyCode ? e.keyCode : e.which;

        if (key === 13) {
            e.target.blur();
        } else if (key === 27) {
            this.goalInput = this.goal;
            e.target.blur();
        } else if (key === 38) {
            this.goalInput = Math.min(
                _goalMax,
                (parseFloat(this.goalInput) + 0.1).toFixed(1)
            );
            e.preventDefault();
        } else if (key === 40) {
            this.goalInput = Math.max(
                _goalMin,
                parseFloat(this.goalInput) - 0.1
            ).toFixed(1);
            e.preventDefault();
        } else if (
            !(
                [8, 9, 46, 110, 190].indexOf(key) !== -1 ||
                (key == 65 && (e.ctrlKey || e.metaKey)) ||
                (key >= 35 && key <= 40) ||
                (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
                (key >= 96 && key <= 105)
            )
        ) {
            e.preventDefault();
        }
    }

    /**
     * Remove hours from goal string for input
     */
    goalInputFocus() {
        this.goalInput = this.goal;
    }

    isMultipleTeams() {
        const teamFilter = this.getFilters().Team;
        if(_.isEmpty(teamFilter)) {
            return true;
        }
        return teamFilter.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/).length > 1;
    }

    updateGoal() {
        if (this.goal) {
            this.showProgress = true;
            const data = {
                metricId: this.metric.id,
                groupId: this.group.id,
                groupName: this.getFilters().Team,
                previousValue: parseFloat(this.previousGoal),
                targetValue: parseFloat(this.goal)
            };

            http
                .post(
                    `${this.envConfig.apiUrl()}${'/api/insights/usergrouptarget'}`,
                    { data: data }
                )
                .then(() => {
                    this.previousGoal = this.goal;
                    this.showProgress = false;
                    this.safeApply();
                    this.showNotification(`Goal was successfully set to ${this.goalInput.toLowerCase()}.`, 'success');
                    var analyticEventServiceFunctions =
                        require('_app/serviceFunctions/analyticEventServiceFunctions').analyticEventServiceFunctions;

                    analyticEventServiceFunctions.newEvent('New Goal Set', {});
                })
                .catch((e) => {
                    console.error('ActiveTrak Error: Error fetching groups', e);
                    this.showProgress = false;
                    this.safeApply();
                    this.showNotification('Error retrieving dashboard goal.', 'danger');
                });
        }
    }

    getGoal() {
        this.showProgress = true;
        const data = {metricId: this.metric.id, groupId: this.group.id};
        http
            .get(
                `${this.envConfig.apiUrl()}${'/api/insights/usergrouptarget'}`,
                { data: data }
            )
            .then(results => {
                this.setGoal(results.data.targetValue);
                this.previousGoal = results.data.targetValue;
                this.showProgress = false;
                this.safeApply();
            })
            .catch((e) => {
                console.error(
                    'ActiveTrak Error: Error fetching groups',
                    e
                );
                this.showProgress = false;
                this.safeApply();
                this.showNotification('Error retrieving dashboard goal.', 'danger');
            });
    }
}

export default GoalControl;
