export enum FeatureFlag {
  Reactivate = 'reactivateFeatureFlag',
  VideoPlayback = 'videoAlarmsFeatureFlag',
  CommandCenterAppAccessSettings = 'commandCenterAppAccessSettingsFeatureFlag',
  InsightsAvailable = 'insightsAvailableFeatureFlag',
  InsightsScheduling = 'insightsSchedulingFeatureFlag',
  InsightsEnabled = 'insightsEnabledFeatureFlag',
  ShowTeamPulseScreenViews = 'showTeamPulseScreenViewsFeatureFlag',
  ShowActivation = 'showActivationFeatureFlag',
  SupportTickets = 'showSupportTicketsFeatureFlag',
  ShowOfflineMeetingData = 'showOfflineMeetingDataFeatureFlag',
  ShowLocationData = 'showLocationDataFeatureFlag',
  ShowPromos = 'showPromosFeatureFlag',
  RestrictUpgrade = 'restrictUpgradeFeatureFlag',
  DevelopmentEnvironment = 'developmentEnvironmentFeatureFlag',
  ShowWizard = 'showWizardFeatureFlag'
}
