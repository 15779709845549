import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { IApiKeyPayload } from '../models';
import { useProfileStore } from '../../common/services/Profile';

type CreateApiKeyButtonProps = {
  onSubmit: (payload: IApiKeyPayload) => Promise<string>;
  isDisabled: boolean;
  onCloseSuccessDialog: () => void;
};

export default function CreateApiKeyButton(props: CreateApiKeyButtonProps) {
  const { onSubmit, isDisabled, onCloseSuccessDialog } = props;

  const [newApiKeyName, setNewApiKeyName] = useState<string>('');
  const [keyCreationDialogOpen, setKeyCreationDialogOpen] =
    useState<boolean>(false);

  const { username } = useProfileStore((s) => s.profile);

  const handleNewApiKeyClick = () => {
    setKeyCreationDialogOpen(true);
  };

  const handleApiKeyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewApiKeyName(event.target.value);
  };

  const handleNewApiKeyCancel = () => {
    setNewApiKeyName('');
    setKeyCreationDialogOpen(false);
  };

  const handleCreateApiKeyClick = async () => {
    const payload: IApiKeyPayload = {
      description: newApiKeyName,
      username
    };
    onSubmit(payload);
    setKeyCreationDialogOpen(false);
    onCloseSuccessDialog();
  };

  return (
    <Box alignItems="center">
      <Button
        color="primary"
        onClick={handleNewApiKeyClick}
        disabled={isDisabled}
        variant="contained"
      >
        Create API Key
      </Button>
      <Dialog
        open={keyCreationDialogOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          handleNewApiKeyCancel();
        }}
      >
        <DialogTitle>Create new API key</DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: '30px', paddingRight: '400px' }}>
            Provide a descriptive name for your API key.
          </Typography>
          <TextField
            label="Add API key name"
            value={newApiKeyName}
            onChange={handleApiKeyNameChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <Button variant="text" autoFocus onClick={handleNewApiKeyCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateApiKeyClick}
            variant="contained"
            color="primary"
          >
            Create API Key
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
