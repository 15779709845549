import { ILandingPageOption } from '../models';

//SHOULD THESE VALUES BE MANAGED IN DB??
export const landingPageOptions: ILandingPageOption[] = [
  {
    label: 'Home (Default)',
    value: 'app.dashboard'
  },
  {
    label: 'Insights (Executive Summary)',
    value: 'app.reports.insights'
  },
  {
    label: 'Productivity',
    value: 'app.reports.productivityLegacy'
  },
  {
    label: 'Working Hours',
    value: 'app.reports.workingHours'
  },
  {
    label: 'Top Users',
    value: 'app.reports.topUsersLegacy'
  },
  {
    label: 'Top Categories',
    value: 'app.reports.topcategories'
  },
  {
    label: 'Top Websites',
    value: 'app.reports.topwebsites'
  },
  {
    label: 'Top Applications',
    value: 'app.reports.topapplications'
  },
  {
    label: 'Team Pulse',
    value: 'app.teampulse'
  }
];
