import React from 'react';
import { PlaygroundHowToUseComponent } from '../views/PlaygroundHowToUse.view';
import ChartsDemo from '../../react-charts-demo/views/DemoPage.view';
import { ReportFiltersPlayground } from '../views/ReportFilterPlayground.view';
import { IDevelopmentPages } from '../../common/models/IDevelopmentPages';
import { DataDogDemo } from '../views/DataDogDemo';

export const PLAYGROUNDS: IDevelopmentPages[] = [
  {
    id: 'example',
    label: 'How to use the Playground',
    jsx: <PlaygroundHowToUseComponent />
  },
  {
    id: 'reportfilters',
    label: 'Report Filter',
    jsx: <ReportFiltersPlayground />
  },
  {
    id: 'charts',
    label: 'Chart Demos',
    jsx: <ChartsDemo />
  },
  {
    id: 'datadog',
    label: 'DataDog Demo',
    jsx: <DataDogDemo />
  }
];
