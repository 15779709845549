import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaunchIcon from '@mui/icons-material/Launch';

import { primaryColor } from '../../common/constants';

export const ButtonsPlayground = () => {
  return (
    <Stack direction="column" spacing={4}>
      <Stack
        direction="row"
        spacing={16}
        sx={{ borderBottom: `1px solid ${primaryColor}`, pb: 2 }}
      >
        <Box>
          <Typography variant="h2">Small</Typography>
          <Typography>
            <code>size="small"</code>
            <br />
            (Button height)
          </Typography>
          <Typography>
            <code>color="error"</code>
            <br />
            (Danger button)
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="small">
              Contained
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="small"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="small">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="small" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="small"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="small" color="error">
              Danger
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="small" color="error">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              disabled={true}
            >
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="small"
              color="error"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" size="small">
              Outlined
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" size="small">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" size="small" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              size="small"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button size="small">Text</Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="small"
              startIcon={<KeyboardBackspaceIcon />}
              endIcon={<LaunchIcon />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="small">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="small" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="small"
              disabled={true}
              startIcon={<KeyboardBackspaceIcon />}
              endIcon={<LaunchIcon />}
            >
              Disabled Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="small" color="secondary">
              Text (Secondary)
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="small"
              color="secondary"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="small" color="secondary">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="small" color="secondary" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="small"
              color="secondary"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
      </Stack>
      <Stack
        direction="row"
        spacing={16}
        sx={{ borderBottom: `1px solid ${primaryColor}`, pb: 2 }}
      >
        <Box>
          <Typography variant="h2">
            Medium
            <br />
            (Default)
          </Typography>
          <Typography>
            <code>size="medium"</code>
            <br />
            (Button height - optional)
          </Typography>
          <Typography>
            <code>color="error"</code>
            <br />
            (Danger button)
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained">Contained</Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" color="error">
              Danger
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="error"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" color="error">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" color="error" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="error"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined">Outlined</Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button>Text</Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              startIcon={<KeyboardBackspaceIcon />}
              endIcon={<LaunchIcon />}
            >
              Icons
            </Button>
          </Box>
          <Box>
            <Box sx={{ mb: 2 }}>
              <Button>
                Responsive
                <br />
                line Break
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button disabled={true}>Disabled</Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button
                disabled={true}
                startIcon={<KeyboardBackspaceIcon />}
                endIcon={<LaunchIcon />}
              >
                Disabled Icons
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button variant="text" color="secondary">
                Text (Secondary)
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="text"
                color="secondary"
                startIcon={<GroupAddIcon />}
                endIcon={<ArrowDropDown />}
              >
                Icons
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button variant="text" color="secondary">
                Responsive
                <br />
                line Break
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button variant="text" color="secondary" disabled={true}>
                Disabled
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="text"
                color="secondary"
                disabled={true}
                startIcon={<GroupAddIcon />}
                endIcon={<ArrowDropDown />}
              >
                Disabled Icons
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
      <Stack direction="row" spacing={16}>
        <Box>
          <Typography variant="h2">Large</Typography>
          <Typography>
            <code>size="large"</code>
            <br />
            (Button height)
          </Typography>
          <Typography>
            <code>color="error"</code>
            <br />
            (Danger button)
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="large">
              Contained
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="large"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="large">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="large" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="large"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="large" color="error">
              Danger
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="large"
              color="error"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" size="large" color="error">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="large"
              color="error"
              disabled={true}
            >
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              size="large"
              color="error"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" size="large">
              Outlined
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" size="large">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" size="large" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              size="large"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ mb: 2 }}>
            <Button size="large">Text</Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="large"
              startIcon={<KeyboardBackspaceIcon />}
              endIcon={<LaunchIcon />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="large">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="large" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="large"
              disabled={true}
              startIcon={<KeyboardBackspaceIcon />}
              endIcon={<LaunchIcon />}
            >
              Disabled Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="large" color="secondary">
              Text (Secondary)
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="large"
              color="secondary"
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Icons
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="large" color="secondary">
              Responsive
              <br />
              line Break
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button size="large" color="secondary" disabled={true}>
              Disabled
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              size="large"
              color="secondary"
              disabled={true}
              startIcon={<GroupAddIcon />}
              endIcon={<ArrowDropDown />}
            >
              Disabled Icons
            </Button>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
