import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import { UploadIcon2 } from '../../../common/assets/Icons/UploadIcon2';
import { gray4 } from '../../../common/constants';
import { PrimaryUploadBox } from '../../styles/TimeOnTask.view.styles';
import { Bulk } from '../../utils/TimeOnTask.view.utils';

type OwnProps = {
  currentUploadView: Bulk;
  onDrop: (acceptedFiles: File[]) => void;
};

const BulkUpload: React.FC<OwnProps> = ({ currentUploadView, onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });

  return (
    Bulk.DEFAULT === currentUploadView && (
      <PrimaryUploadBox {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadIcon2 width="30" height="30" />
        <Typography>Drag your file to start uploading</Typography>
        <Box
          display="flex"
          alignItems="center"
          marginTop="10px"
          justifyContent="center"
        >
          <Box borderBottom={`1px solid ${gray4}`} width="100px" />
          <Typography marginX="10px">OR</Typography>
          <Box borderBottom={`1px solid ${gray4}`} width="100px" />
        </Box>
        <Button variant="outlined" style={{ marginTop: '10px' }}>
          Browse files
        </Button>
      </PrimaryUploadBox>
    )
  );
};

export default BulkUpload;
