import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { cardTopActivityClasses } from './CardTopActivity.style';
import { InfoPanel } from '../';
import InfoTip from '../../InfoTip/InfoTip';
import TopActivity from '../../../models/TopActivity';
import InfoPanelVariant from '../InfoPanel/InfoPanelVariant';
import { isEmpty } from '../../../utilities';
import { GroupPulseService } from '../../../utilities/api/groupPulseService';
import { hasRole } from '../../../../../_reactivtrak/src/common/helpers/authorization';
import { Role } from '../../../../../_reactivtrak/src/common/enums';

const CardTopActivity = (props) => {
    const {
        topActivities,
        rootClass,
        contentClass,
        loading,
        type,
        variant,
        teamPulseDataControl,
        teamPulseDataPoller
    } = props;
    const topActivity = new TopActivity({
        topActivities,
        type,
        variant,
        classes: cardTopActivityClasses
    });

    const handleInfoTipOpen = () => {
        GroupPulseService.setPausePolling(true);
        teamPulseDataPoller.pollingPaused(true);
    };

    const handleInfoTipClose = () => {
        GroupPulseService.setPausePolling(teamPulseDataControl.pollingPaused.value);
        teamPulseDataPoller.pollingPaused(teamPulseDataControl.pollingPaused.value);
    };

    const isSupportAdvanced = hasRole(Role.SupportAdvanced);

    const setStyling = () => {
        return isSupportAdvanced
            ? { ...cardTopActivityClasses.disabled, ...rootClass, ...cardTopActivityClasses.root }
            : { ...rootClass, ...cardTopActivityClasses.root };
    };

    return (
        <div style={setStyling()}>
            <div style={{ ...contentClass, ...cardTopActivityClasses.content }}>
                <div style={cardTopActivityClasses.flexChild}>
                    <InfoPanel
                        heading={topActivity.link}
                        headingClass={topActivity.link === 'No Activity' ? cardTopActivityClasses.none : {}}
                        subheading={'Top Activity'}
                        largeHeading={true}
                        loading={loading}
                        align="left"
                        variant={variant}
                    />
                </div>
                <div style={cardTopActivityClasses.grow} />
                {!loading && variant === InfoPanelVariant.Stacked && !isEmpty(topActivity.details) && (
                    <div style={cardTopActivityClasses.flexFixed}>
                        <InfoTip
                            onEnter={handleInfoTipOpen}
                            onClose={handleInfoTipClose}
                            buttonContent={<InfoOutlined sx={cardTopActivityClasses.buttonIcon} />}
                            infoTipContent={topActivity.details}
                            anchorOrigin={{ horizontal: 'center' }}
                            transformOrigin={{ horizontal: 'center' }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardTopActivity;
