import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RepeatIcon from '@mui/icons-material/Repeat';
import DeleteIcon from '@mui/icons-material/Delete';
import { fontColor800, gray3 } from '../../../common/constants';
import { Bulk } from '../../utils/TimeOnTask.view.utils';
import { ExcelIcon } from '../../../common/assets/Icons/ExcelIcon';

type OwnProps = {
  isUploading: boolean;
  filename: string;
  filesize: number;
  currentUploadView: Bulk;
  isNetWorkError?: boolean;
  handleDelete: () => void;
  handleRetry?: () => void;
};

const BulkUploadStatus: React.FC<OwnProps> = ({
  isUploading,
  filename,
  filesize,
  currentUploadView,
  isNetWorkError,
  handleDelete,
  handleRetry
}) => {
  const fileSizeDisplay = (size: number) => {
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(1)}KB`;
    }
    return `${(size / (1024 * 1024)).toFixed(1)}MB`;
  };

  return (
    Bulk.DEFAULT !== currentUploadView &&
    !isUploading && (
      <Box
        height="70px"
        display="flex"
        marginTop="10px"
        border={`1px solid ${gray3}`}
        padding="8px"
        borderRadius="6px"
      >
        <Box display="flex" alignItems="center" width="50%">
          <Box sx={{ margin: '0 8px' }}>
            <ExcelIcon />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography>
              <strong>{filename}</strong>
            </Typography>
            {currentUploadView === Bulk.ERROR ? (
              <Typography>Upload failed</Typography>
            ) : (
              <Typography>{fileSizeDisplay(filesize)}</Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="50%"
          justifyContent="flex-end"
        >
          {currentUploadView === Bulk.ERROR
            ? isNetWorkError && (
                <RepeatIcon
                  onClick={handleRetry}
                  cursor="pointer"
                  fontSize="small"
                  sx={{ marginRight: '10px' }}
                />
              )
            : currentUploadView === Bulk.HAS_WARNINGS && (
                <>
                  <Typography sx={{ marginRight: '10px' }}>
                    File upload successful
                  </Typography>
                  <TaskAltIcon
                    htmlColor={fontColor800}
                    fontSize="small"
                    sx={{ marginRight: '10px' }}
                  />
                </>
              )}
          <DeleteIcon
            onClick={handleDelete}
            fontSize="small"
            cursor="pointer"
          />
        </Box>
      </Box>
    )
  );
};

export default BulkUploadStatus;
