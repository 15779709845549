import { toggleFavorite } from '../favoritesStore';
import { findItemById } from '../navigationStore';

export const handleStarToggle = async (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  item: { id: string }
) => {
  e.preventDefault();
  e.stopPropagation();

  const found = findItemById(item.id);
  await toggleFavorite('navigation')(found);
};
