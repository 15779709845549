import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { IReportTimelineFilters } from '../../common/models/reportTimelineFilters.models';
import {
  REPORT_TIMELINE_DEFAULT_FILTERS,
  REPORT_TIMELINE_STORAGE_PREFIX
} from '../../common/constants/reports.constants';

export const scheduledTimelineStore = createStore<IReportTimelineFilters>()(
  persist(() => ({ ...REPORT_TIMELINE_DEFAULT_FILTERS }), {
    name: `${REPORT_TIMELINE_STORAGE_PREFIX}scheduleAdherence`
  })
);

export const setScheduledTimeline = (timeline: Partial<IReportTimelineFilters>) => {
  scheduledTimelineStore.setState(timeline);
};

export const getScheduledTimeline = () => {
  return scheduledTimelineStore.getState();
};
