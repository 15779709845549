import { useState, useCallback } from 'react';
import { ApiRoutes } from '../constants';
import { postReportingData } from '../../common/helpers';
import {
  ITodaysProductivityOutlookDto,
  ITodaysProductivityOutlookFeedbackDto,
  ITodaysProductivityOutlookState
} from '../models/ITodaysProductivityOutlookTypes';
import { getReportFilters } from '../../common/components/ReportFilters/hooks/reportFiltersStore';

export const useTodaysProductivityOutlookState =
  (): ITodaysProductivityOutlookState => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [summaryData, setSummaryData] =
      useState<ITodaysProductivityOutlookDto>();
    const [isTileError, setIsTileError] = useState<boolean>(false);

    const init = useCallback(async (groupId: string): Promise<void> => {
      setIsLoading(true);
      setIsTileError(false);

      try {
        const response = await postReportingData<ITodaysProductivityOutlookDto>(
          {
            path: ApiRoutes.reports.fetchTodaysProductivityOutlook,
            args: { groupId }
          }
        );
        const { groupId: currentGroupId } = getReportFilters();
        if (currentGroupId.length > 0 && currentGroupId[0] === groupId) {
          setSummaryData(response);
          setIsLoading(false);
        }
      } catch (error) {
        setIsTileError(true);

        console.error(
          `ActivTrak Error: Unable to load Today's Productivity Outlook data`,
          error
        );
        setIsLoading(false);
      }
    }, []);

    const postTodaysProductivityOutlookFeedback = useCallback(
      async (value: boolean): Promise<void> => {
        const runId = summaryData?.metadata?.runId;

        try {
          await postReportingData<ITodaysProductivityOutlookFeedbackDto>({
            path: ApiRoutes.reports.fetchTodaysProductivityOutlookFeedback.replace(
              ':runId',
              runId
            ),
            args: {
              wasThisUseful: value
            }
          });
        } catch (error) {
          console.error(
            `ActivTrak Error: Unable to load Today's Productivity Outlook feedback data`,
            error
          );
        }
      },
      [summaryData?.metadata?.runId]
    );

    return {
      isLoading,
      isTileError,
      summaryData,
      postTodaysProductivityOutlookFeedback,
      init
    };
  };
