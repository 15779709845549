export enum ReportPageModeLabels {
  Users = 'Users',
  Computers = 'Computers',
  Groups = 'Groups'
}

export enum ReportPageModeTypes {
  Users = 'users',
  Computers = 'computers',
  Groups = 'groups'
}

export const REPORT_PAGE_MODE_MAPPED = {
  users: ReportPageModeTypes.Users,
  computers: ReportPageModeTypes.Computers,
  groups: ReportPageModeTypes.Groups
};

export const REPORT_PAGE_MODE_LABELS_MAPPED = {
  users: ReportPageModeLabels.Users,
  computers: ReportPageModeLabels.Computers,
  groups: ReportPageModeLabels.Groups
};

export const REPORT_PAGE_MODE_STORAGE_PREFIX = 'activTrak.reportPageMode';
