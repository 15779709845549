import { useCallback, useState } from 'react';
import { useDashboard } from '../services/DashboardProvider';
import { ITopUsersState } from '../models';
import {
  ITopUsersGroupsDto,
  TopGroupUsersBar,
  TopUsersGroupFilterSort,
  WidgetInitConfig,
  WidgetMode,
  TopUsersGroupParams,
  ITopUsersGroupsOriginalDto
} from '../models/ITopUsersGroupsTypes';
import { filterBarData, sortAndCreateBarData } from '../utils/topusers.utils';
import { fetchReportingData } from '../../common/helpers';
import { ApiRoutes } from '../constants';
import { getTodayInAccountTimezone } from '../../common/utils/datetime/datetimeFormatStrings';

const TRUNCATE_INDEX = 7;

export const useTopUsersGroupsState = (): ITopUsersState => {
  const [chartData, setChartData] = useState<TopGroupUsersBar>();
  const [rawUsersResponse, setRawUsersResponse] =
    useState<ITopUsersGroupsDto[]>();
  const [rawGroupsResponse, setRawGroupsResponse] =
    useState<ITopUsersGroupsDto[]>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [truncatedData, setTruncatedData] = useState<TopGroupUsersBar>();
  const [widgetMode, setWidgetMode] = useState<WidgetMode>();
  const [sortMode, setSortMode] = useState<TopUsersGroupFilterSort>();
  const [filterMode, setFilterMode] = useState<TopUsersGroupFilterSort[]>();
  const [isWidgetStateLoading, setIsWidgetStateLoading] =
    useState<boolean>(false);

  const today = getTodayInAccountTimezone();

  const [usersTodayParams, setUsersTodayParams] = useState<TopUsersGroupParams>(
    {
      from: `${today}`,
      to: `${today}`,
      userId: '-1',
      userType: 'AllUsers',
      mode: 'users',
      range: 'Custom_Date_Range',
      type: 'total'
    }
  );

  // TODO: this will be finished on CORE-2825
  const [groupsTodayParams] = useState<TopUsersGroupParams>({
    from: `${today}`,
    to: `${today}`,
    userId: '-1',
    userType: 'AllUsers',
    mode: 'users',
    range: 'Custom_Date_Range',
    groupBy: 'groups'
  });

  const dashboard = useDashboard();
  const { setDashboardNotification } = dashboard;

  const processResponse = useCallback((response, type: 'users' | 'groups') => {
    if (type === 'users') {
      setRawUsersResponse(response);
    } else if (type === 'groups') {
      response?.length > 15
        ? setRawGroupsResponse(response.slice(0, 15))
        : setRawGroupsResponse(response);
    }
  }, []);

  const processBarData = useCallback(
    (
      response: ITopUsersGroupsDto[],
      initConfig?: WidgetInitConfig
    ): TopGroupUsersBar => {
      if (initConfig) {
        const filteredData = filterBarData(response, initConfig.filterMode);
        return sortAndCreateBarData(
          filteredData,
          initConfig.sortMode,
          initConfig.filterMode,
          setSortMode
        );
      } else {
        const filteredData = filterBarData(response, filterMode);
        return sortAndCreateBarData(
          filteredData,
          sortMode,
          filterMode,
          setSortMode
        );
      }
    },
    [sortMode, filterMode]
  );

  const isTruncated = useCallback(
    (response: ITopUsersGroupsDto[], initConfig?: WidgetInitConfig) => {
      if (response.length > TRUNCATE_INDEX) {
        if (initConfig) {
          setTruncatedData(
            processBarData(response.slice(0, TRUNCATE_INDEX), initConfig)
          );
        } else {
          setTruncatedData(processBarData(response.slice(0, TRUNCATE_INDEX)));
        }
      } else if (truncatedData) {
        // reset previous state truncatedData.
        setTruncatedData(null);
      }
    },
    [processBarData, truncatedData]
  );

  const processInitialConfig = useCallback((initConfig: WidgetInitConfig) => {
    setWidgetMode(initConfig.widgetMode);
    setSortMode(initConfig.sortMode);
    setFilterMode(initConfig.filterMode);
  }, []);

  // TODO: Refactor for Groups when API returns new Groups endpoint with params (CORE-2825)
  const fetchOrGetCurrentState = useCallback(async (): Promise<
    ITopUsersGroupsDto[]
  > => {
    if (rawUsersResponse) {
      const sortModeChanged =
        sortMode !== TopUsersGroupFilterSort[usersTodayParams.type];

      if (sortModeChanged) {
        const newParams = usersTodayParams;
        newParams.type = TopUsersGroupFilterSort[sortMode];

        setUsersTodayParams((prevState) => {
          return { ...prevState, type: newParams.type };
        });
        const response = await fetchReportingData<ITopUsersGroupsDto[]>({
          path: ApiRoutes.reports.fetchProductivity,
          params: newParams
        });
        setRawUsersResponse(response);

        return response;
      } else {
        return rawUsersResponse;
      }
    } else {
      return fetchReportingData<ITopUsersGroupsDto[]>({
        path: ApiRoutes.reports.fetchProductivity,
        params: usersTodayParams
      });
    }
  }, [rawUsersResponse, sortMode, usersTodayParams]);

  const fetchTopUsersGroupsData = useCallback(
    async (initConfig?: WidgetInitConfig) => {
      if (initConfig) processInitialConfig(initConfig);

      const usersResponse: ITopUsersGroupsDto[] =
        await fetchOrGetCurrentState();

      if (!rawUsersResponse) processResponse(usersResponse, 'users');
      if (!usersResponse?.length) {
        setNoResults(true);
        return setChartData(null);
      } else if (widgetMode === 'users') {
        isTruncated(usersResponse);
        setChartData(processBarData(usersResponse));
      } else if (initConfig?.widgetMode === 'users') {
        isTruncated(usersResponse, initConfig);
        setChartData(processBarData(usersResponse, initConfig));
      }

      let groupsResponse: ITopUsersGroupsDto[];
      if (rawGroupsResponse) {
        groupsResponse = rawGroupsResponse;
      } else {
        const response = await fetchReportingData<ITopUsersGroupsOriginalDto>({
          path: ApiRoutes.reports.fetchProductivityGroups,
          params: groupsTodayParams
        });
        groupsResponse = response?.rows;
        processResponse(groupsResponse, 'groups');
      }

      if (widgetMode === 'groups' || initConfig?.widgetMode === 'groups') {
        if (!groupsResponse.length) {
          setNoResults(true);
          return setChartData(null);
        } else if (initConfig?.widgetMode === 'groups') {
          const groupsResponseArgs =
            groupsResponse.length > 15
              ? groupsResponse.slice(0, 15)
              : groupsResponse;

          isTruncated(groupsResponseArgs);
          setChartData(processBarData(groupsResponseArgs, initConfig));
        } else {
          isTruncated(groupsResponse);
          setChartData(
            processBarData(
              groupsResponse.length > 15
                ? groupsResponse.slice(0, 15)
                : groupsResponse
            )
          );
        }
      }
    },
    [
      processInitialConfig,
      fetchOrGetCurrentState,
      rawUsersResponse,
      processResponse,
      rawGroupsResponse,
      groupsTodayParams,
      widgetMode,
      isTruncated,
      processBarData
    ]
  );

  const getData = useCallback(
    async (initConfig?: WidgetInitConfig) => {
      setIsWidgetStateLoading(true);
      try {
        await fetchTopUsersGroupsData(initConfig);
      } catch (error) {
        setNoResults(true);
        setDashboardNotification({
          msg: 'Unable to load Top Users & Groups Data',
          type: 'error'
        });
        console.error(
          `ActivTrak Error: Top Users & Groups Widget: ${error}`,
          error
        );
      }
      setIsWidgetStateLoading(false);
    },
    [fetchTopUsersGroupsData, setDashboardNotification]
  );

  const init = useCallback(
    (
      initConfig: WidgetInitConfig = {
        filterMode: [
          TopUsersGroupFilterSort.productive,
          TopUsersGroupFilterSort.unproductive,
          TopUsersGroupFilterSort.undefined
        ],
        sortMode: TopUsersGroupFilterSort.total,
        widgetMode: 'users'
      }
    ): void => {
      if (!chartData) getData(initConfig);
    },
    [chartData, getData]
  );

  return {
    init,
    getData,
    widgetMode,
    setWidgetMode,
    noResults,
    setNoResults,
    truncatedData,
    chartData,
    sortMode,
    setSortMode,
    filterMode,
    setFilterMode,
    isWidgetStateLoading
  };
};
