import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { favoritesService } from '../../services/Navigation/FavoritesService';
import { withEvents } from './withEvents';
import { newEvent } from '../../analytics/eventHeap';
import { findItemByName, findItemById } from './navigationStore';
import { getUserBundleFlags } from '../../stores/userPermissionsStore/userPermissionStore';
import { useUIRouterState } from '../../hooks/useUIRouterHistory';

const MAX_FAVORITE_ITEMS = 10;
const SUCCESS = 'success';
const WARNING = 'warning';

//This is used to dedup some items names
//TODO: this needs to be part of the metadata object
const UNIQUE_PAGE_NAME_MAP = {
  'app.alarms.settings': 'Alarm Configuration',
  'app.screenshots.latest': 'Screenshots Latest',
  'app.screenshots.history': 'Screenshots History',
  'app.videos.history': 'Videos History',
  'app.account.webhook': 'Alarm Notifications',
  'app.settings.security': 'Security Configuration',
  'app.reports.insightsSettings': 'Insights Configuration',
  'app.activConnect': 'ActivConnect',
  'app.reports.riskLevel': 'Risk Level Alarms',
  'app.account.audit': 'Security Audit Log'
};

const favoritesStoreDefinition = (set, get) => ({
  favorites: [],

  isFavoriteNavigationEnabled: true,

  latest: 0,

  coaching: {} as any,

  setFavorites: (favorites) => {
    return set(() => ({ favorites }));
  },

  isInFavorites: (item: any) => {
    const current = get().favorites;

    //TODO: remove by name comparison
    const found = current.find((f) => f.id === item.id || f.name === item.name);

    return !!found;
  }
});

export const favoritesStore = createStore(favoritesStoreDefinition);
const { setState, getState } = favoritesStore;

const alert = (title, status) => {
  window.postMessage({ name: 'atk:notify', title, status });
};

export const { subscribe, unsubscribe, notify } = withEvents();

const resolveItem = (id, name) => {
  //This is a special case, need to build dto manually
  if (name === 'app.downloads') {
    return {
      label: 'Downloads',
      onClick: () => ({
        stateName: 'app.downloads'
      })
    };
  }

  return id ? findItemById(id) : findItemByName(name);
};

// Command/Unit of Work Pattern
// this function updates the backend and the store in one transaction
export const addToFavorites = async ({ id, name }, area = '') => {
  const current = getState().favorites;
  notify('start:addToFavorites', current);

  const found = resolveItem(id, name);

  if (found === undefined) {
    console.error('ActivTrak Error: cannot resolve favorite item');
    return;
  }
  
  const mapped = {
    id: found.id,
    name: found.action.options.ref,
    label: found.label,
    params: {
      pageId: found.action?.options?.pageId,
      subPageId: found.action?.options?.initialSubPageId,
      modelId: found.action?.options?.modelId
    }
  };

  //Max 5
  if (current.length === MAX_FAVORITE_ITEMS) {
    alert(
      `There is currently a maximum limit of ${MAX_FAVORITE_ITEMS} favorite items`,
      WARNING
    );
    return;
  }

  mapped.label = UNIQUE_PAGE_NAME_MAP[mapped.name] || mapped.label;

  const updated = [...current, mapped];
  setState({ favorites: updated });

  alert(`${mapped.label} has been added to Favorites`, SUCCESS);
  await favoritesService().saveFavorites(updated);

  //Heap analytics - custom event
  newEvent('Favorited', {
    'Favorite Page': mapped.label,
    'Favorite Action': 'added',
    'Favorite Area': area
  });

  notify('end:addToFavorites', updated);
};

// Command/Unit of Work Pattern
// this function updates the backend and the store in one transaction
export const removeFromFavorites = async (item: any, area = '') => {
  const current = getState().favorites;
  notify('start:removeFromFavorites', current);

  const found = current.find((f) => f.id === item.id || f.name === item.name);
  const updated = current.filter((f) => f.id !== found.id);

  setState({ favorites: updated });

  alert(`${found.label} has been removed from Favorites`, SUCCESS);
  await favoritesService().saveFavorites(updated);

  //Heap analytics - custom event
  newEvent('Favorited', {
    'Favorite Page': found.label,
    'Favorite Action': 'removed',
    'Favorite Area': area
  });

  notify('end:removeFromFavorites', updated);
};

export const toggleFavorite =
  (area: string) => async (item: { id: string; name: string }) => {
    const { isInFavorites } = getState();

    if (isInFavorites(item)) {
      await removeFromFavorites(item, area);
    } else {
      await addToFavorites(item, area);
    }
  };

export const useFavoritesStore = (selector) =>
  useStore<any, any>(favoritesStore, selector);

//Favorites not enabled on these states
const EXCLUDED_STATES = [
  'app.dashboard',
  'app.dashboardCurrent.admin',
  'app.dashboardCurrent.organization',
  'app.dashboardCurrent.management',
  'app.settings.classification.id',
  'app.settings.groups_id',
  'app.settings.useragents_id',
  'app.settings.computeragents_id',
  'app.settings.identity_id'
];


//TODO: better name
export const useHeaderFavorites = (): [
  boolean | 'unavailable',
  (area: string) => Promise<void>
] => {
  const state = useUIRouterState();

  //check if favs is available
  const userBundleFlags = getUserBundleFlags();
  const favorites = useFavoritesStore((s) => s.favorites);

  const isFavoriteNavigationEnabled =
    userBundleFlags?.isFavoriteNavigationEnabled;
  const isExcluded = EXCLUDED_STATES.includes(state?.name);

  const isUnavailable = !isFavoriteNavigationEnabled || isExcluded;

  const isCurrentStateInFavorites = favorites?.find(
    (favorite) => favorite.name === state.name
  );

  const isFavorite = isUnavailable ? 'unavailable' : isCurrentStateInFavorites;

  const _toggleCurrentState = async (area:string) => {
    await toggleFavorite(area)({ id:'', name: state.name});
  }

  return [isFavorite, _toggleCurrentState];
};
