import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { IDialogVerifyDeleteUserProps } from '../models/IDialogVerifyDeleteUserProps';

export const DialogVerifyDeleteUser: React.FC<IDialogVerifyDeleteUserProps> = (
  verifyDeleteProps
) => {
  const { closeDialog, dialogOpen, onDelete } = verifyDeleteProps;

  function deleteItem(): void {
    onDelete();
    closeDialog();
  }

  return (
    <Dialog maxWidth="lg" open={dialogOpen} onClose={closeDialog}>
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: '20px' }}>
          Are you sure you want to delete these users?
        </Typography>
      </DialogContent>
      <DialogActions style={{ paddingRight: '20px' }}>
        <Button variant="text" autoFocus onClick={closeDialog}>
          Cancel
        </Button>
        <Button onClick={deleteItem} variant="contained" color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
