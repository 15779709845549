import { ElementType } from 'react';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { ApiStatus } from '../../../common/enums/ApiStatus';

export type ProductivityHeaderProps = {
  reportFilters: IReportFilters;
  isRefreshing: boolean;
  onRefresh: () => void;
};

export type ProductivityReportProps = {
  reportFilters: IReportFilters;
  refreshTimestamp?: number;
};

export enum AggregationPeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year'
}

export interface IAggregationPeriodState {
  period: AggregationPeriod;
  availablePeriods: AggregationPeriod[];
}

export interface ILegendItem {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  hidden: boolean;
}

export type ProductivityLegendsProps = {
  legendItems: ILegendItem[];
};

export enum ProductivitySummaryLegendLabels {
  Productive = 'productive',
  Unproductive = 'unproductive',
  Undefined = 'undefined',
  OfflineMeetings = 'offlineMeetings'
}

export enum ProductivityDetailLegendLabels {
  ProductiveActive = 'productiveActive',
  ProductivePassive = 'productivePassive',
  UnproductiveActive = 'unproductiveActive',
  UnproductivePassive = 'unproductivePassive',
  UndefinedActive = 'undefinedActive',
  UndefinedPassive = 'undefinedPassive',
  OfflineMeetings = 'offlineMeetings'
}

export enum ProductivityName {
  Productive = 'Productive',
  Unproductive = 'Unproductive',
  Undefined = 'Undefined',
  OfflineMeetings = 'Offline Meetings',
  ProductiveActive = 'Productive Active',
  ProductivePassive = 'Productive Passive',
  UnproductiveActive = 'Unproductive Active',
  UnproductivePassive = 'Unproductive Passive',
  UndefinedActive = 'Undefined Active',
  UndefinedPassive = 'Undefined Passive'
}
export interface IProductivityReports {
  date: string;
  data: {
    productive: number;
    unproductive: number;
    undefined: number;
    offlineMeetings: number;
    productiveActive: number;
    productivePassive: number;
    unproductiveActive: number;
    unproductivePassive: number;
    undefinedActive: number;
    undefinedPassive: number;
  };
  error?: string;
}
export interface IProductivityReport {
  data: Partial<IProductivityReports>[];
  dates: string[];
  error: string | Error;
}

export interface IProductivityReportDataDto {
  productive: number;
  unproductive: number;
  undefined: number;
  offlineMeetings: number;
  productiveActive: number;
  productivePassive: number;
  unproductiveActive: number;
  unproductivePassive: number;
  undefinedActive: number;
  undefinedPassive: number;
  productivity?: string;
  totals?: number[];
}
export interface IProductivityReportDto {
  dates: string[];
  data: IProductivityReportDataDto[];
  error: string;
}

export interface IProductivityReportStore {
  response: IProductivityReport | null;
  apiStatus: ApiStatus;
}

export type ProductivityIconProps = Record<
  ProductivityName,
  { icon: ElementType; color: string }
>;
