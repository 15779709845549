import React, { useRef, useState } from 'react';
import { ProfileSettingsModalProps } from '../models';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import { checkInvalidPassword } from '../../common/utils/validation/validation';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { smallFontSize, notifError } from '../../common/constants/styleGuide';

export const ProfileSettingsModal: React.FC<ProfileSettingsModalProps> = ({
  modalOpen,
  setPasswordModalOpen,
  onPasswordChange,
  credentialsSettings
}) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const oldPasswordRef = useRef<HTMLInputElement>();
  const newPasswordRef = useRef<HTMLInputElement>();

  const securityLevelAdvanced = credentialsSettings?.password?.advancedSecurity
    ? 'advancedSecurity'
    : null;

  const handleClosePasswordModal = () => {
    setPasswordModalOpen(false);
    setNewPasswordError(false);
    setShowOldPassword(false);
    setShowNewPassword(false);
  };

  const validatePassword = () => {
    const hasErrors = checkInvalidPassword(
      newPasswordRef.current?.value,
      securityLevelAdvanced
    );

    setNewPasswordError(hasErrors);
  };

  const handleSavePasswordModal = () => {
    setPasswordModalOpen(false);
    setNewPasswordError(false);

    const current = oldPasswordRef.current?.value;
    const newPassword = newPasswordRef.current?.value;

    onPasswordChange({ current: current, new: newPassword });
  };
  return (
    <Dialog
      open={modalOpen}
      aria-labelledby="dialog-profile-modal"
      aria-describedby="dialog-profile-settings-modal"
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        handleClosePasswordModal();
      }}
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <Box sx={{ minWidth: '450px', maxWidth: '520px' }}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Current Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showOldPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword((prev) => !prev)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Current Password"
                inputRef={oldPasswordRef}
              />
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showNewPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword((prev) => !prev)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                inputRef={newPasswordRef}
                onChange={() => {
                  validatePassword();
                }}
              />
            </FormControl>
            {newPasswordError && (
              <FormHelperText>
                {newPasswordError && (
                  <Typography
                    variant="caption"
                    color={notifError}
                    fontSize={smallFontSize}
                  >
                    Minimum of {securityLevelAdvanced ? 16 : 8} characters{' '}
                    <br />
                    Minimum of 1 uppercase characters <br />
                    Minimum of 1 special characters <br />
                    No whitespace characters allowed <br />
                    {securityLevelAdvanced && 'Minimum of 1 number'}
                  </Typography>
                )}
              </FormHelperText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={handleClosePasswordModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePasswordModal}
            disabled={newPasswordError}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
