//DOCS: https://activtrak.atlassian.net/wiki/spaces/DOC/pages/3473014802/Dashboard+Infrastructure
import React, { useEffect, useState } from 'react';
import { DashboardTypes, IWidget } from '../models';
import { useProfileStore } from '../../common/services/Profile';
import { WidgetLoader } from '../components/templates';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatQuote, getRandomQuote } from '../utils';
import { useAuthorization } from '../../common/services/Authorization';
import { useDashboard } from '../services';
import { useRefreshState } from '../../common/components/ReportFilters/hooks';
import { Role } from '../../common/enums';
import { useNotifications } from '../../common/services/Notifications';
import { LegacyHeader } from '../../common/styles/app.component.styles';
import {
  BasicDashboardContainer,
  DashboardScrollbar
} from '../styles/templates.styles';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import { RefreshButton } from '../../common/components/ReportFilters/components/RefreshButton';
import ReportFiltersView from '../../common/components/ReportFilters/view/ReportFilters.view';
import { getReportFilters } from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { useAccountSettingsStore } from '../../common/stores/accountSettingsStore/accountSettingsStore.hooks';

type DashboardViewProps = {
  dashboardType: DashboardTypes;
  showGreeting?: boolean;
  showQuotes?: boolean;
  showReportFilters?: boolean;
  showRefreshBtn?: boolean;
  widgets: IWidget[];
};

export const BasicDashboardView = (props: DashboardViewProps) => {
  const {
    dashboardType,
    showQuotes = false,
    showReportFilters = false,
    showGreeting = false,
    showRefreshBtn = false,
    widgets
  } = props;

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = getReportFilters();
  const { refreshTimestamp, handleRefresh } = useRefreshState();
  const notificationService = useNotifications();
  const authorizationService = useAuthorization();
  const history = useUIRouterHistory();
  const dashboard = useDashboard();
  const { dashboardNotification, setDashboardNotification } = dashboard;
  const { usedLicenses } = useAccountSettingsStore((s) => s.accountSettings);

  //if role is not 'ccaccess' and usedLicenses === 0, redirect to activation page
  useEffect(() => {
    if (authorizationService && usedLicenses >= 0) {
      const userLicenses = usedLicenses;
      const isCC = authorizationService.hasAnyRole([Role.CommandCenter]);
      if (userLicenses === 0 && !isCC) {
        history.push('app.agentactivation');
      }
    }
  }, [authorizationService, history, usedLicenses]);

  const [quote] = useState(
    formatQuote(getRandomQuote(dashboardType), 'Did you know that ', '?')
  );

  const [greeting, setGreeting] = useState<string>();

  const { firstName, username } = useProfileStore((s) => s.profile);

  useEffect(() => {
    let finalGreeting = 'Welcome back';
    if (firstName) {
      finalGreeting = `${finalGreeting}, ${firstName}.`;
    }
    setGreeting(finalGreeting);
  }, [firstName, username]);

  useEffect(() => {
    if (dashboardNotification?.msg && dashboardNotification?.type) {
      notificationService[dashboardNotification.type](
        dashboardNotification.msg
      );
      setDashboardNotification(undefined);
    }
  }, [
    notificationService,
    dashboardNotification?.msg,
    dashboardNotification?.type,
    setDashboardNotification
  ]);

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <DashboardScrollbar>
      <BasicDashboardContainer container spacing={3}>
        <Grid item xs={12}>
          {showGreeting && greeting && (
            <LegacyHeader sx={{ marginBottom: '10px' }}>
              {greeting}
            </LegacyHeader>
          )}
          {showQuotes && quote && (
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: quote }}
            ></Typography>
          )}
          <Stack direction="row" spacing={2}>
            {' '}
            {showReportFilters && <ReportFiltersView />}
            {showRefreshBtn && (
              <RefreshButton
                onRefresh={handleRefresh}
                isRefreshing={isRefreshing}
              />
            )}
          </Stack>
        </Grid>
        {widgets?.length > 0 ? (
          widgets.map(
            (widget) =>
              !widget.shouldExclude && (
                <Grid
                  item
                  key={`widget-container-${widget?.id}`}
                  {...widget?.widths}
                  {...widget?.style}
                >
                  <WidgetLoader
                    id={widget?.id}
                    refreshTimestamp={refreshTimestamp}
                    reportFilters={reportFilters}
                  />
                </Grid>
              )
          )
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', padding: '50px 0' }}>
            <CircularProgress />
          </Grid>
        )}
      </BasicDashboardContainer>
    </DashboardScrollbar>
  );
};
