import { useTopUsersReportsStore } from '../hooks/useTopUsersReportStore';
import { ITopUserReportStore } from '../models/TopUsersReport.models';
import { ApiStatus } from '../../../common/enums/ApiStatus';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import { ReportViewToggle } from '../../../common/components/ReportFilters/components/ReportViewToggle';

type TopUsersReportViewToggleProps = {
  onChange: (view: ReportViewTypes) => void;
};

export const TopUsersReportViewToggle = (
  props: TopUsersReportViewToggleProps
) => {
  const { onChange } = props;

  const { response, apiStatus } = useTopUsersReportsStore(
    (s: ITopUserReportStore) => s
  );

  return (
    <ReportViewToggle
      onChange={onChange}
      isDisabled={!response?.data?.length || apiStatus !== ApiStatus.Loaded}
    />
  );
};
