import * as available from '../../../../../_app/navigation/navigation-files/availableMenus.json';
import * as core from '../../../../../_app/navigation/navigation-files/defaultMenu.json';
import * as support from '../../../../../_app/navigation/navigation-files/supportPortalMenu.json';

import { getRouteDefinitions } from '../../config/routing';
import { ClaimType } from '../../enums';
import { BundleFlag } from '../../enums/BundleFlag';
import { FeatureFlag } from '../../enums/FeatureFlag';
import { hasFeature, hasRole, hasRoleAccess } from '../../helpers';
import { getUserClaims } from '../../helpers/authentication/useUserTokenStore';
import { isAccountPlanInTrial } from '../../stores/accountPlanStore';


type MenuMapType = {
  menu: string;
  submenus?: MenuMapType[]
};

type RuleType = {
  type:
    | 'hasFeature'
    | 'doesNotHaveFeature'
    | 'hasRole'
    | 'doesNotHaveRole'
    | 'isTrial'
    | 'isNotInTrial'
    | 'hasRoleAccess';
  value: string;
};

// TODO: Dedupe - there is one more interface like this
export interface IMenuItem {
  id: string;
  label: string;
  action?: {
    type: string;
    options?: {
      ref: string;
    };
  };
  submenus?: IMenuItem[];
  submenuOpen: boolean;
  isActive: boolean;

  parentId?: string;

  rules?: RuleType[];

  beta?: boolean;
  earlyAccess?: boolean;

  level?: number;
}

const filterAndMapRecursive = (
  input: MenuMapType[],
  parentId?: string,
  level?: number
): IMenuItem[] => {

  const routes = getRouteDefinitions();

  if (routes === 'invalid') {
    throw new Error ('Unable to build route model - app will not start');
  }

  const filtered = input.filter((item) => {
    const isAvailable = item.menu in available;
    return isAvailable;
  });

  const mapped = filtered.map((item) => {
    
    const mapped: IMenuItem = available[item.menu];

    mapped.isActive = false;
    mapped.submenuOpen = false;
    mapped.level = level;

    return {
      ...mapped,
      submenus: item.submenus
        ? filterAndMapRecursive(item.submenus, mapped.id, level + 1)
        : undefined,
      parentId
    };
  });

  const authorized = mapped.filter((item) => {

      /**
       * Evaluate rules:
       * Code is intentionally verbose
       */
      if (item.rules) {
        
        const pass = item.rules.every((rule) => {
          if (rule.type === 'hasFeature') {
            return hasFeature(rule.value as FeatureFlag | BundleFlag);
          }

          if (rule.type === 'doesNotHaveFeature') {
            return !hasFeature(rule.value as FeatureFlag | BundleFlag);
          }

          if (rule.type === 'hasRole') {
            return hasRole(rule.value);
          }

          if (rule.type === 'doesNotHaveRole') {
            return !hasRole(rule.value);
          }

          if (rule.type === 'isTrial') {
            return isAccountPlanInTrial();
          }

          if (rule.type === 'isNotInTrial') {
            return !isAccountPlanInTrial();
          }

          if (rule.type === 'hasRoleAccess') {
            return (
              hasRole('supportbasic') ||
              hasRole('superadmin') ||
              hasRoleAccess(rule.value)
            );
          }

          // otherwise...
          console.error('ActivTrak Error: Unknown menu rule', rule.type);
          return true;
        });

        // if any rule is not met, discard.
        if (!pass) {
          return false;
        }
      }
    
    // TODO: These type of items could be placed on a separate file
    // These can't be matched against application states
    // For now: if rules passed, we let them through.
    if (item.action?.type === 'event' || item.action?.type === 'external') {
      return true;
    }

    // remove empty toggles
    if (item.action?.type === 'toggleSubmenu') {
      return item.submenus?.length > 0;
    }

    const found = routes.find((route) => {
      return route.name === item.action?.options?.ref;
    });

    return found !== undefined;
  });

  return authorized;
};

export const createSidebar = () => {
  // TODO: Cleanup
  const claims = getUserClaims();

  if (claims === 'invalid') {
    throw new Error('cannot build menu - app will not start');
  }

  //determine which menu to load
  const roles = claims.filter((claim) => claim.type === ClaimType.Role);
  const hasSupportRole = roles.some(
    (role) => role.value?.toLowerCase() === 'supportportal'
  );

  const supportDelegate = claims.find(
    (claim) =>
      claim.type === ClaimType.SupportDelegate ||
      claim.type === ClaimType.SuperAdminDelegate
  );

  // support menu only when not impersonating
  const selections =
    hasSupportRole && !supportDelegate ? support.selections : core.selections;

  const menu = filterAndMapRecursive(selections, undefined, 0);

  return menu;
};

