import { resetReportFilters } from '../../components/ReportFilters/hooks/reportFiltersStore';
import { accountSettingsStore } from '../../../common/stores/accountSettingsStore/accountSettingsStore';
import { userPermissionStore } from '../../stores/userPermissionsStore/userPermissionStore';
import { resetEntitiesStore } from '../../stores/entityStore/entityStore';
import { resetPrivacySettingsStore } from '../../hooks/privacySettingsStore';
import { resetAccountPlanStore } from '../../stores/accountPlanStore';
import { clearAndResetExportStore } from '../../stores/exportsStore/exportStore.utils';
import { resetStoreCache } from '../../stores/cacheStore/cacheStore.utils';
import { resetRealtimeSettingsDtoCache } from '../../stores/realtimeSettingsDtoCacheStore/realtimeSettingsDtoCacheStore';
import { resetRealtimeSettingsStore } from '../../stores/realtimeSettingsStore/realtimeSettingsStore';
import { resetWebsocketSettingsStore } from '../../stores/websocketSettingsStore/websocketSettingsStore';
import { resetScreenshotSettingsStore } from '../../stores/screenshotSettingsStore';
import { postLoginStore } from '../../stores/postLoginStore';
import { resetScreenshotRedactionSettingsStore } from '../../stores/screenshotSettingsStore/screenshotRedactionSettingsStore';
import { resetScreenshotSafeSearchSettingsStore } from '../../stores/screenshotSettingsStore/screenshotSafeSearchSettingsStore';
import { resetLiveReportSettingsStore } from '../../stores/liveReportsSettingsStore/liveReportSettingsStore';
import { resetDateSettingsStore } from '../../stores/dateSettingsStore';
import { resetInsightsSettingsStore } from '../../stores/insightsSettingsStore/insightsSettingsStore';
import { resetApiDomainStore } from '../../stores/apiDomainStore/apiDomainStore';
import { resetGlobalModalStore } from '../../stores/globalModalStore';
import { setUserToken } from './useUserTokenStore';
import { resetIdentitySearchResultsStore } from '../../stores/identitySearchResultsStore';
import { resetAlarmSettingsStore } from '../../stores/alarmSettingsStore/alarmSettingsStore';
import { getInjector } from '../../third-party/angular2react/useInjector';
import { StateService } from '@uirouter/core';
import { rewire } from '../../../../../_entry/rewire';

export const resetStores = (): void => {
  resetReportFilters();
  resetEntitiesStore();
  resetPrivacySettingsStore();
  resetAccountPlanStore();
  resetStoreCache();
  resetRealtimeSettingsDtoCache();
  resetRealtimeSettingsStore();
  resetWebsocketSettingsStore();
  resetScreenshotSettingsStore();
  resetScreenshotRedactionSettingsStore();
  resetScreenshotSafeSearchSettingsStore();
  resetDateSettingsStore();
  resetLiveReportSettingsStore();
  resetInsightsSettingsStore();
  resetApiDomainStore();
  resetGlobalModalStore();
  clearAndResetExportStore();
  postLoginStore.getState().reset();
  accountSettingsStore.getState().reset();
  userPermissionStore.getState().reset();
  resetIdentitySearchResultsStore();
  resetAlarmSettingsStore();
};

export const logout = (params?: Record<string, string> | null): void => {
  setUserToken('invalid');
  const $injector = getInjector();
  const $state: StateService = $injector.get('$state');

  $state.go('login', params ?? {});
  rewire();
};
