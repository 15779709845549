import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CircularWithLabel } from '../../../common/components/Progress';
import { PrimaryUploadBox } from '../../styles/TimeOnTask.view.styles';

type OwnProps = {
  isUploading: boolean;
  uploadProgress: number;
  handleCancelUpload: () => void;
};

const BulkUploading: React.FC<OwnProps> = ({
  isUploading,
  uploadProgress,
  handleCancelUpload
}) => {
  return (
    isUploading && (
      <PrimaryUploadBox>
        <CircularWithLabel value={uploadProgress} />
        <Typography sx={{ marginTop: '8px' }}>Uploading...</Typography>
        <Button
          onClick={handleCancelUpload}
          variant="outlined"
          sx={{ marginTop: '8px' }}
        >
          Cancel
        </Button>
      </PrimaryUploadBox>
    )
  );
};

export default BulkUploading;
