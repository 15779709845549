import { Role } from '../../enums';
import { IUserPermissionDefinition } from './userPermissionStore.models';

import { FeatureFlag } from '../../enums/FeatureFlag';
import { BundleFlag } from '../../enums/BundleFlag';
import { getUserBundleFlags, getUserFeatureFlags } from './userPermissionStore';

export const checkUserRoleAccess = (
  routeAccess: IUserPermissionDefinition,
  userRoleAccess: string[]
): boolean => {
  const bypassRoleAccessRoles = [
    Role.SuperAdmin,
    Role.SupportAdvanced,
    Role.SupportIntermediate,
    Role.SupportBasic
  ];

  const isRoleInBypassList = (role: string) =>
    bypassRoleAccessRoles.some(
      (bypassRole) => role.toLowerCase() === bypassRole.toLowerCase()
    );

  const doesRoleMatchRouteAccess = (
    access: IUserPermissionDefinition,
    roleAccessKey: string
  ): boolean =>
    roleAccessKey.toLowerCase() === access.roleAccessKey.toLowerCase();

  return (
    !routeAccess.roleAccessKey ||
    userRoleAccess.some(isRoleInBypassList) ||
    userRoleAccess.some((roleAccessKey) =>
      doesRoleMatchRouteAccess(routeAccess, roleAccessKey)
    )
  );
};

export const checkUserRoles = (routeRoles: Role[], userRoles: Role[]) => {
  return routeRoles.some((role) =>
    userRoles
      .map((userRole) => userRole.toLowerCase())
      .includes(role.toLowerCase())
  );
};

export const checkUserFlags = <T extends string>(
  userFlags: Partial<Record<T, boolean>>,
  flags: T[] = [],
  excludedFlags: T[] = []
) => {
  if (!userFlags) return flags.length <= 0 && excludedFlags.length <= 0;

  const flagsMatch = flags.every((flag) => userFlags[flag]);
  const excludedFlagsMatch =
    excludedFlags.length > 0 && excludedFlags.every((flag) => userFlags[flag]);

  return flagsMatch && !excludedFlagsMatch;
};

export const hasFlag = (flag: FeatureFlag | BundleFlag): boolean => {
  const userFeatureFlags = getUserFeatureFlags();
  const userBundleFlags = getUserBundleFlags();
  return userFeatureFlags?.[flag] || userBundleFlags?.[flag] || false;
};
