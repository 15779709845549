import { createStore } from 'zustand';
import { TIMELINE_STORE_DEFAULT } from './atTimeline.constants';
import { ITimelineStore } from './atTimeline.models';
import { ApiStatus } from '../../enums/ApiStatus';
import { TimelineOptions } from 'vis-timeline/esnext';

export const timelineStore = createStore<ITimelineStore>(
  () => TIMELINE_STORE_DEFAULT
);

/**
 * Returns the timeline store that are set in the store.
 */
export const getTimelineStore = (): ITimelineStore => timelineStore.getState();

/**
 * Sets the timeline store into the store merging the passed in values with the existing values.
 * @param newState - The partial state to set. Undefined values will not be set and will not affect the store.
 */
export const setTimelineStore = (newState: Partial<ITimelineStore>) =>
  timelineStore.setState(newState);

/**
 * Sets the timeline status in the store.
 * @param status - The status to set.
 */
export const setTimelineStatus = (status: ApiStatus) =>
  setTimelineStore({ status });

/**
 * Sets the timeline options in the store.
 * @param options - The options to set.
 * @see https://visjs.github.io/vis-timeline/docs/timeline/#Configuration_Options
 */
export const setTimelineOptions = (options: Partial<TimelineOptions>) => {
  const currentOptions = getTimelineStore().options;
  setTimelineStore({ options: { ...currentOptions, ...options } });
};

/**
 * Resets the timeline store back to default in the store.
 */
export const resetTimelineStore = (): void =>
  timelineStore.setState(TIMELINE_STORE_DEFAULT, true);
