export const ENTER_VALID_EMAIL = 'Enter a valid email address.';
export const ENTER_VALID_UPN = 'Enter a valid upn.';
export const ENTER_VALID_LOGON_DOMAIN =
  'Enter a valid Logon Domain or select Any.';
export const EMAIL_ALREADY_ASSOCIATED =
  'Email is already associated with a user.';
export const ENTER_VALID_EMPLOYEE_ID = 'Enter a valid employee ID.';
export const ENTER_UNIQUE_VALUE = 'Enter a unique value.';

//these strings come from identity api
export const API_EMAIL_NOT_UNIQUE = 'Email is not unique.';
export const API_EMPLOYEE_ID_NOT_UNIQUE = 'EmployeeID is not unique.';
export const API_DEVICE_LOGON_NOT_UNIQUE = 'Device Logon is not unique.';
export const API_DISPLAY_NAME_NOT_UNIQUE =
  'Display Name is associated to another User.';

export const DISPLAY_IN_USE =
  'This display name is already in use. Choose a display name that is not associated with an existing user.';

export const EMPLOYEE_ID_IN_USE =
  'This Employee ID is already in use. Choose an Employee ID that is not associated with another employee.';

export const REVISION_NO_MATCH =
  "Current Revision doesn't match provided revision.";

export const DATA_STALE = 'User data changed, refresh browser to edit.';

export const DEVICE_LOGON_ALREADY_ASSOCIATED =
  'Device Logon assigned to another person.';

export const ALREADY_ASSOCIATED = 'Already associated with another user.';

export const DEVICE_LOGON_NOT_UNIQUE = API_DEVICE_LOGON_NOT_UNIQUE;
