import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { settingsalarmsTemplate as angularJsTemplate } from '../../templates';

export const appAlarmsSettings: IRoute = {
  name: 'app.alarms.settings',
  stateDefinition: {
    url: '/alarms?newalarm',
    params: { filter: null, wasSaved: null },
    template: angularJsTemplate,
    controller: 'AlarmsCtrl',
    data: { pageTitle: 'Alarm Configuration' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.AlarmsConfiguration,
    edit: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.SupportAdvanced
    ],
    read: [Role.User, Role.SupportBasic, Role.SupportIntermediate],
    bundleFlags: [BundleFlag.AlarmConfiguration]
  }
};

export default appAlarmsSettings;
