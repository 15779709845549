import moment from 'moment';
import { accountSettingsStore } from '../../../stores/accountSettingsStore/accountSettingsStore';
import { groupsStore } from '../../../hooks';
import { IAccountSettingsStore } from '../../../stores/accountSettingsStore/accountSettingsStore.models';
import { DateRanges, presetGroups } from '../constants/dateRanges';
import { ProductivityName } from '../constants/productivityName';
import { ReportFilterName } from '../constants/reportFilterName';
import { TimelineSort } from '../constants/timelineSort';
import { IReportFilters } from '../models/IReportFilters';

//////////////////////////////////////////////
// Report Filter Field Validation Functions //
//////////////////////////////////////////////

export const isProductivityValid = (
  filters: Partial<IReportFilters>
): boolean => Object.values(ProductivityName).includes(filters.productivity);

export const isTimelineSortValid = (
  filters: Partial<IReportFilters>
): boolean => Object.values(TimelineSort).includes(filters.timelineSort);

export const isGroupIdValid = (filters: Partial<IReportFilters>): boolean =>
  filters.groupId?.every(
    (id) =>
      id === '-1' ||
      groupsStore.getState().groupFilters.some((group) => group.id === id)
  );

export const isUsersValid = (filters: Partial<IReportFilters>): boolean =>
  filters.users.length > 0; // TODO: Implement this function once we have a full list of users.
// filters.users.every(
//   (user) =>
//     user.userId === -1 ||
//     groupsStore
//       .getState()
//       .legacyUserFilters.some(
//         (legacyUser) => legacyUser.userId === user.userId
//       ) ||
//     groupsStore
//       .getState()
//       .groupFilters.some((group) => group.id === user.userId)
// );

export const isTimelineFromHourValid = (
  filters: Partial<IReportFilters>
): boolean => filters.timelineFromHour >= 0 && filters.timelineFromHour <= 23;

export const isTimelineToHourValid = (
  filters: Partial<IReportFilters>
): boolean => filters.timelineToHour >= 0 && filters.timelineToHour <= 23;

export const isTimelineStrictValid = (
  filters: Partial<IReportFilters>
): boolean => typeof filters.timelineStrict === 'boolean';

export const isTimelineIntervalValid = (
  filters: Partial<IReportFilters>
): boolean => [5, 15, 30, 60, 120].includes(filters.timelineInterval);

export function isValidTimestamp(value) {
  return moment(value, 'X', true).isValid();
}

export const isDateFilterValid = (
  filters: Partial<IReportFilters>
): boolean => {
  const range = filters.dates.range;
  const { accountSettings }: IAccountSettingsStore =
    accountSettingsStore.getState();
  const { roleDateFilters } = accountSettings;
  const isDateRangeInAllowedList = roleDateFilters.some(
    (availableDateRangeGroup) => {
      const dateRangeGroup = presetGroups[availableDateRangeGroup];
      return (
        range === DateRanges.Custom ||
        dateRangeGroup
          .map((dateRangePreset) => dateRangePreset.value)
          .includes(range)
      );
    }
  );
  return isDateRangeInAllowedList && Object.values(DateRanges).includes(range);
};

///////////////////////////////////
// Combined Validation functions //
///////////////////////////////////
const reportFilterValidationFunctions = {
  productivity: isProductivityValid,
  timelineSort: isTimelineSortValid,
  groupId: isGroupIdValid,
  users: isUsersValid,
  timelineFromHour: isTimelineFromHourValid,
  timelineToHour: isTimelineToHourValid,
  timelineStrict: isTimelineStrictValid,
  timelineInterval: isTimelineIntervalValid,
  dates: isDateFilterValid
};

export const isFilterValueValid = (
  filtersName: ReportFilterName,
  filters: IReportFilters
): boolean => {
  const validationFunction = reportFilterValidationFunctions[filtersName];

  if (!validationFunction || validationFunction(filters)) {
    return true;
  }

  console.warn(
    `ActivTrak Warn: Filter ${filtersName} was invalid. Setting to default.`
  );
  return false;
};
