import { createStore } from 'zustand';
import { IScreenshotRedactionSettingsStore } from './screenshotRedactionSettingsStore.models';
import { fetchSettings } from './screenshotSafeSearchSettingsStore.utils';
import { SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT } from './screenshotRedactionSettingsStore.constants';

export const screenshotRedactionSettingsStore =
  createStore<IScreenshotRedactionSettingsStore>(
    () => SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT
  );

/**
 * Fetches the Screenshot Redaction Settings Store and updates the store.
 */
export const fetchScreenshotRedactionSettings =
  async (): Promise<IScreenshotRedactionSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = screenshotRedactionSettingsStore.getState();
    screenshotRedactionSettingsStore.setState({
      ...currentSettings,
      ...response
    });
    return screenshotRedactionSettingsStore.getState();
  };

/**
 * Returns the current Screenshot Redaction Settings Store state.
 */
export const getScreenshotRedactionSettingsStore =
  (): IScreenshotRedactionSettingsStore =>
    screenshotRedactionSettingsStore.getState();

/**
 * Resets the Screenshot Redaction Settings Store to its default state.
 */
export const resetScreenshotRedactionSettingsStore = (): void =>
  screenshotRedactionSettingsStore.setState(
    SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT,
    true
  );
