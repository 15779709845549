import { IWebsocketSettingsStore } from './websocketSettingsStore.models';
import { WEBSOCKET_SETTINGS_STORE_DEFAULT } from './websocketSettingsStore.constants';
import { IRealtimeSettingsDtoCache } from '../realtimeSettingsDtoCacheStore/realttimeSettingsDtoCacheStore.models';
import { getRealtimeSettingsDtoCache } from '../realtimeSettingsDtoCacheStore/realtimeSettingsDtoCacheStore';

const mapRealtimeSettingsDtoStoreToRealtimeSettingsStore = (
  realtimeSettingsDtoStore: IRealtimeSettingsDtoCache
): IWebsocketSettingsStore => {
  const { pingInterval, reconnectDelayMax, reconnectDelayStart } =
    realtimeSettingsDtoStore.websocketSettings;

  return {
    pingInterval,
    reconnectDelayMax,
    reconnectDelayStart
  };
};

export const fetchSettings = async (): Promise<IWebsocketSettingsStore> => {
  try {
    const response = await getRealtimeSettingsDtoCache();
    return mapRealtimeSettingsDtoStoreToRealtimeSettingsStore(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Error fetching screenshot settings.',
      error
    );
    return WEBSOCKET_SETTINGS_STORE_DEFAULT;
  }
};
