import { useCallback, useEffect, useRef, useState } from 'react';
import { IGridColumnFilter } from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { GridColumnsFilter } from '../../../common/components/ReportFilters/components/GridColumnsFilter';
import { handleAllCheckbox } from '../../../common/components/ReportFilters/utils/gridColumnsFilter.utils';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { resetTopUsersColumnsStore } from '../stores/topUsersColumns.store';
import {
  mapTopUsersColumnFiltersToColumnLabels,
  mapTopUsersColumnsToColumnState
} from '../utils/topUsersReport.utils';
import { getTopUsersColumnLabels } from '../utils/topUsersColumns.utils';
import { useTopUsersReportsStore } from '../hooks/useTopUsersReportStore';
import { ITopUserReportStore } from '../models/TopUsersReport.models';
import { ApiStatus } from '../../../common/enums/ApiStatus';

type TopUsersColumnSelectorProps = {
  onSubmit: (view: ReportViewTypes) => void;
};

export const TopUsersColumnSelector = (props: TopUsersColumnSelectorProps) => {
  const { onSubmit } = props;

  const [currentColumnSettings, setCurrentColumnSettings] = useState<
    IGridColumnFilter[]
  >([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const { response, apiStatus } = useTopUsersReportsStore(
    (s: ITopUserReportStore) => s
  );

  const columnLabels = useRef(getTopUsersColumnLabels()).current;

  const resetColumns = useCallback(() => {
    const { view: currentView } = getReportView();
    const mappedColumnFilters = mapTopUsersColumnFiltersToColumnLabels(
      currentView,
      columnLabels
    );
    setCurrentColumnSettings(mappedColumnFilters);
  }, [columnLabels]);

  useEffect(() => {
    resetColumns();
  }, [resetColumns]);

  const handleColumnsSubmit = useCallback((): void => {
    const { view: currentView } = getReportView();
    mapTopUsersColumnsToColumnState(currentColumnSettings, currentView);
    onSubmit(currentView);

    setIsOpen(false);
  }, [currentColumnSettings, onSubmit]);

  const handleRestoreColumns = useCallback(() => {
    resetTopUsersColumnsStore();
    const { view: currentView } = getReportView();
    const mappedColumnFilters = mapTopUsersColumnFiltersToColumnLabels(
      currentView,
      columnLabels
    );
    setCurrentColumnSettings(mappedColumnFilters);
    onSubmit(currentView);

    setIsOpen(false);
  }, [columnLabels, onSubmit]);

  const handleClose = useCallback(() => {
    resetColumns();
    setIsOpen(false);
  }, [resetColumns]);

  const selectAll = () => {
    setCurrentColumnSettings((prevState) => {
      const clonedState = [...prevState];
      return clonedState.map((column) => ({ ...column, hide: false }));
    });
  };

  const unSelectAll = () => {
    setCurrentColumnSettings((prevState) => {
      const clonedState = [...prevState];
      return clonedState.map((column) => ({ ...column, hide: true }));
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      unSelectAll();
    } else {
      selectAll();
    }
    setIsAllSelected((prevState) => !prevState);
  };

  const handleSelect = (colId: string) => {
    if (colId === 'all') {
      handleSelectAll();
    } else {
      setCurrentColumnSettings((prevState) => {
        const clonedState = [...prevState];
        const mapped = clonedState.map((column) =>
          column.colId === colId ? { ...column, hide: !column.hide } : column
        );

        return handleAllCheckbox(mapped);
      });
    }
  };

  return (
    <GridColumnsFilter
      columns={currentColumnSettings}
      isOpen={isOpen}
      onSubmit={handleColumnsSubmit}
      onSelect={handleSelect}
      onRestoreDefaults={handleRestoreColumns}
      onClose={handleClose}
      setIsOpen={setIsOpen}
      isDisabled={!response?.data?.length || apiStatus !== ApiStatus.Loaded}
    />
  );
};
