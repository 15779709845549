import { createStore } from 'zustand';
import { CachedStoreType } from './cacheStore.models';

export const createCachedStore = <TEntity>(fetcher: () => Promise<TEntity>) => {
  const factory = createStore<CachedStoreType<TEntity>>();

  const store = factory((set, get) => {
    return {
      state: null,

      fetch: async () => {
        const cached = get().state;

        if (cached != null) {
          return cached;
        }

        const fresh = await fetcher();
        set({ state: fresh });
        return fresh;
      },

      reset: () => {
        set({ state: null });
      },

      refresh: async () => {
        get().reset();
        return await get().fetch();
      }
    };
  });

  return store;
};
