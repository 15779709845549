import React from 'react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import { durationFormat } from '../../../common/utils/datetime/time';
import { formatDate } from '../../../common/utils/datetime/datetimeFormatters';
import authorization from '../../../common/helpers/authorization';
import { isTodayByTimezone } from '../../../common/utils/datetime/isToday';
import { IReportColumnLabel } from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import {
  generateLinkedItem,
  getAccessForFeature,
  getProductivityTimeTemplate
} from '../../common/utils/reports.utils';
import { LocationHeader } from '../../common/components/LocationHeader';
import { OfflineMeetingsHeader } from '../../common/components/OfflineMeetingsHeader';
import { TotalProdAndOfflineMeetingsHeader } from '../components/TotalProdAndOfflineMeetingsHeader';
import { CustomHeaderTemplate } from '../../../common/components/AtGrid/CustomHeaderTemplate';
import { WorkingHoursDataId } from '../models/WorkingHours.models';
import Typography from '@mui/material/Typography';

const getHasLocationAccess = () =>
  getAccessForFeature(
    BundleFlag.LocationData,
    BundleFlag.LocationPromo,
    FeatureFlag.ShowLocationData
  );

const getHasOfflineAccess = () =>
  getAccessForFeature(
    BundleFlag.OfflineMeetingsData,
    BundleFlag.OfflineMeetingsPromo,
    FeatureFlag.ShowOfflineMeetingData
  );

//NOTE: Because the Columns Selector uses the 'hide' property to determine if a column is visible, a 'hasAccess' property is used to separately determine if a column should be included in the total list of columns to display.
export const getWorkingColumns = (): ColDef[] => {
  const hasLocationAccess = getHasLocationAccess();
  const hasOfflineAccess = getHasOfflineAccess();

  const hasLocationData = authorization.hasFeature(BundleFlag.LocationData);
  const hasOfflineMeetings = authorization.hasFeature(
    BundleFlag.OfflineMeetingsData
  );

  const cols = [
    {
      colId: WorkingHoursDataId.Date,
      field: WorkingHoursDataId.Date,
      headerName: 'Date',
      sortable: false,
      valueFormatter: (params) => formatDate(params?.value),
      minWidth: 150
    },
    {
      colId: WorkingHoursDataId.User,
      field: WorkingHoursDataId.User,
      headerName: 'User',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return generateLinkedItem(dataItem.users, 'user');
      },
      tooltipValueGetter: (params: ITooltipParams) => params?.data?.users[0],
      minWidth: 180
    },
    {
      colId: WorkingHoursDataId.Location,
      field: WorkingHoursDataId.Location,
      headerName: 'Location',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <LocationHeader />
      },
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;

        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.LocationData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!dataItem.location || !hasFeatureEnabled || isTodayDate) return '';

        return dataItem.location;
      },
      minWidth: hasLocationData ? 130 : 160,
      hasAccess: hasLocationAccess
    },
    {
      colId: WorkingHoursDataId.Productive,
      field: WorkingHoursDataId.Productive,
      headerName: 'Productive Time',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>Productive Time</Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.productiveTime, 1);
      },
      minWidth: 175
    },
    {
      colId: WorkingHoursDataId.ProductiveActive,
      field: WorkingHoursDataId.ProductiveActive,
      headerName: 'Productive Active',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>Productive Active</Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.productiveActiveTime, 1);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.ProductivePassive,
      field: WorkingHoursDataId.ProductivePassive,
      headerName: 'Productive Passive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Passive
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.productivePassiveTime, 2);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.Unproductive,
      field: WorkingHoursDataId.Unproductive,
      headerName: 'Unproductive Time',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>Unproductive Time</Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.unproductiveTime, -1);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.UnproductiveActive,
      field: WorkingHoursDataId.UnproductiveActive,
      headerName: 'Unproductive Active',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Active
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.unproductiveActiveTime, -1);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.UnproductivePassive,
      field: WorkingHoursDataId.UnproductivePassive,
      headerName: 'Unproductive Passive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Passive
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.unproductivePassiveTime,
          -2
        );
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.Undefined,
      field: WorkingHoursDataId.Undefined,
      headerName: 'Undefined Time',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>Undefined Time</Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.undefinedTime, 0);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.UndefinedActive,
      field: WorkingHoursDataId.UndefinedActive,
      headerName: 'Undefined Active',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>Undefined Active</Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.undefinedActiveTime, 0);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.UndefinedPassive,
      field: WorkingHoursDataId.UndefinedPassive,
      headerName: 'Undefined Passive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>Undefined Passive</Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.undefinedPassiveTime, -3);
      },
      minWidth: 200
    },
    {
      colId: WorkingHoursDataId.ScreenTime,
      field: WorkingHoursDataId.ScreenTime,
      headerName: 'Screen Time',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.screenTime, false);
      },
      minWidth: 150
    },
    {
      colId: WorkingHoursDataId.TotalTimeOffline,
      field: WorkingHoursDataId.TotalTimeOffline,
      headerName: 'Offline Meetings',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <OfflineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.OfflineMeetingsData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!hasFeatureEnabled || isTodayDate) return '';

        return getProductivityTimeTemplate(dataItem.totalTimeOffline || 0, -4);
      },
      minWidth: hasOfflineMeetings ? 180 : 160,
      hasAccess: hasOfflineAccess
    },
    {
      colId: WorkingHoursDataId.TotalTime,
      field: WorkingHoursDataId.TotalTime,
      headerName: 'Total',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <TotalProdAndOfflineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.totalTime, false);
      },
      minWidth: 150
    }
  ];

  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};

export const getWorkingHoursColumnLabels = (): IReportColumnLabel[] => {
  const hasLocationAccess = getHasLocationAccess();
  const hasOfflineAccess = getHasOfflineAccess();

  const cols = [
    { colId: 'all', label: 'All' },
    { colId: WorkingHoursDataId.Date, label: 'Date' },
    { colId: WorkingHoursDataId.User, label: 'User' },
    {
      colId: WorkingHoursDataId.Location,
      label: 'Location',
      hasAccess: hasLocationAccess
    },
    {
      colId: WorkingHoursDataId.Productive,
      label: 'Productive Time',
      customLabel: <Typography>Productive Time</Typography>
    },
    {
      colId: WorkingHoursDataId.ProductiveActive,
      label: 'Productive Active',
      customLabel: <Typography>Productive Active</Typography>
    },
    {
      colId: WorkingHoursDataId.ProductivePassive,
      label: 'Productive Passive',
      customLabel: <Typography>Productive Passive</Typography>
    },
    {
      colId: WorkingHoursDataId.Unproductive,
      label: 'Unproductive Time',
      customLabel: <Typography>Unproductive Time</Typography>
    },
    {
      colId: WorkingHoursDataId.UnproductiveActive,
      label: 'Unproductive Active',
      customLabel: <Typography>Unproductive Active</Typography>
    },
    {
      colId: WorkingHoursDataId.UnproductivePassive,
      label: 'Unproductive Passive',
      customLabel: <Typography>Unproductive Passive</Typography>
    },
    {
      colId: WorkingHoursDataId.Undefined,
      label: 'Undefined Time',
      customLabel: <Typography>Undefined Time</Typography>
    },
    {
      colId: WorkingHoursDataId.UndefinedActive,
      label: 'Undefined Active',
      customLabel: <Typography>Undefined Active</Typography>
    },
    {
      colId: WorkingHoursDataId.UndefinedPassive,
      label: 'Undefined Passive',
      customLabel: <Typography>Undefined Passive</Typography>
    },

    { colId: WorkingHoursDataId.ScreenTime, label: 'Screen Time' },
    {
      colId: WorkingHoursDataId.TotalTimeOffline,
      label: 'Offline Meetings',
      hasAccess: hasOfflineAccess
    },
    {
      colId: WorkingHoursDataId.TotalTime,
      label: 'Total'
    }
  ];

  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};
