import React from 'react';
import {
  TopUsersGroupFilterSort,
  TopUsersGroupsHeaderProps,
  WidgetMode
} from '../../models/ITopUsersGroupsTypes';
import { ProductivityColors } from '../../../common/constants';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LoaderContainer } from '../../../common/components/CustomGrid/CustomGrid.styles';
import {
  ColorCheckbox,
  DashboardMenuLabelContainer,
  DashboardMenuLabel,
  DashboardSelectMenu,
  DashboardSelectMenuItem
} from '../../styles/templates.styles';
import {
  SortFilterText,
  useTopUserGroupStyles as classes
} from '../../styles/TopUsersGroups.styles';
import { ITopUsersSortMetadata } from '../../models/IHeapMetadataTypes';
import { submitDashboardEvent } from '../../utils';
import { DashboardType } from '../../../common/enums';

export default function TopUsersGroupsHeader(props: TopUsersGroupsHeaderProps) {
  const {
    noResults,
    widgetMode,
    onChangeWidget,
    sortMode,
    onChangeSort,
    filterMode,
    onChangeFilter,
    isWidgetStateLoading,
    defaultSkeletonLoader
  } = props;

  const getTopUsersGroupFilterSort = (sortBy: TopUsersGroupFilterSort) => {
    let sortSelection;
    switch (sortBy) {
      case 0:
        sortSelection = 'Productive';
        break;
      case 1:
        sortSelection = 'Unproductive';
        break;
      case 2:
        sortSelection = 'Undefined';
        break;
      case 3:
        sortSelection = 'Total';
    }
    return sortSelection;
  };

  const getTopUsersSortEvent = (
    sortBy: TopUsersGroupFilterSort
  ): ITopUsersSortMetadata => {
    const currentSort = getTopUsersGroupFilterSort(sortBy);

    return {
      Selection: currentSort
    };
  };

  const handleSortChange = (e) => {
    const sortTarget: TopUsersGroupFilterSort = e.target.value;
    const eventMetadata = getTopUsersSortEvent(sortTarget);
    submitDashboardEvent(
      'Sort Top Users Widget',
      eventMetadata,
      DashboardType.admin
    );
    onChangeSort(sortTarget);
  };

  const handleToggleChange = (e) => {
    const targetMode: WidgetMode = e.target.value;
    onChangeWidget(targetMode);
  };

  const handleFilterChange = (e) => {
    const filterTarget: TopUsersGroupFilterSort = parseInt(e.target.value);
    const filterNameClicked = getTopUsersGroupFilterSort(filterTarget);

    if (filterMode.includes(filterTarget)) {
      onChangeFilter(filterMode.filter((mode) => mode !== filterTarget));
      submitDashboardEvent(
        'Filter Top Users Widget',
        {
          Checked: 'False',
          Productivity_Selection: filterNameClicked
        },
        DashboardType.admin
      );
    } else {
      onChangeFilter([...filterMode, filterTarget]);
      submitDashboardEvent(
        'Filter Top Users Widget',
        {
          Checked: 'True',
          Productivity_Selection: filterNameClicked
        },
        DashboardType.admin
      );
    }
  };

  return (
    <>
      {(!filterMode || isWidgetStateLoading) && (
        <LoaderContainer loaderHeight={'5vh'}>
          {defaultSkeletonLoader}
        </LoaderContainer>
      )}
      {!isWidgetStateLoading && (
        <Grid container>
          <Grid item xs={12} sx={classes.headerGrid}>
            {(!noResults || widgetMode === 'groups') && (
              <ToggleButtonGroup
                color="primary"
                value={widgetMode}
                exclusive
                onChange={handleToggleChange}
                aria-label="Platform"
              >
                <ToggleButton value="users" sx={classes.toggleButtonLeft}>
                  Users
                </ToggleButton>
                <ToggleButton value="groups" sx={classes.toggleButtonRight}>
                  Groups
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            <Box>
              <SortFilterText
                variant="body1"
                display="inline"
                sx={{ paddingRight: '10px' }}
              >
                Show:
              </SortFilterText>
              <FormControlLabel
                label={
                  <SortFilterText variant="body1">Productive</SortFilterText>
                }
                control={
                  <ColorCheckbox
                    checked={filterMode.includes(
                      TopUsersGroupFilterSort.productive
                    )}
                    value={TopUsersGroupFilterSort.productive}
                    onChange={handleFilterChange}
                    title="Productive"
                    boxcolor={
                      (noResults && ProductivityColors[-4]) ||
                      ProductivityColors[1]
                    }
                    disabled={
                      (filterMode.length === 1 &&
                        filterMode.includes(
                          TopUsersGroupFilterSort.productive
                        )) ||
                      noResults
                    }
                  />
                }
              />
              <FormControlLabel
                label={
                  <SortFilterText variant="body1">Unproductive</SortFilterText>
                }
                control={
                  <ColorCheckbox
                    checked={filterMode.includes(
                      TopUsersGroupFilterSort.unproductive
                    )}
                    value={TopUsersGroupFilterSort.unproductive}
                    onChange={handleFilterChange}
                    title="Unproductive"
                    boxcolor={
                      (noResults && ProductivityColors[-4]) ||
                      ProductivityColors[-1]
                    }
                    disabled={
                      (filterMode.length === 1 &&
                        filterMode.includes(
                          TopUsersGroupFilterSort.unproductive
                        )) ||
                      noResults
                    }
                  />
                }
              />
              <FormControlLabel
                label={
                  <SortFilterText variant="body1">Undefined</SortFilterText>
                }
                control={
                  <ColorCheckbox
                    checked={filterMode.includes(
                      TopUsersGroupFilterSort.undefined
                    )}
                    value={TopUsersGroupFilterSort.undefined}
                    onChange={handleFilterChange}
                    title="Undefined"
                    boxcolor={ProductivityColors[-4]}
                    disabled={
                      (filterMode.length === 1 &&
                        filterMode.includes(
                          TopUsersGroupFilterSort.undefined
                        )) ||
                      noResults
                    }
                  />
                }
              />
            </Box>
            <Box sx={{ marginTop: '5px' }}>
              <DashboardMenuLabelContainer>
                <DashboardMenuLabel>Sort by:</DashboardMenuLabel>
              </DashboardMenuLabelContainer>
              <FormControl>
                <DashboardSelectMenu
                  labelId="top-users-sort-by"
                  value={sortMode}
                  autoWidth
                  onChange={handleSortChange}
                  label="Sort By"
                  disabled={noResults}
                >
                  <DashboardSelectMenuItem
                    value={TopUsersGroupFilterSort.total}
                  >
                    Total
                  </DashboardSelectMenuItem>
                  <DashboardSelectMenuItem
                    value={TopUsersGroupFilterSort.productive}
                    disabled={
                      !filterMode.includes(TopUsersGroupFilterSort.productive)
                    }
                  >
                    Productive
                  </DashboardSelectMenuItem>
                  <DashboardSelectMenuItem
                    value={TopUsersGroupFilterSort.unproductive}
                    disabled={
                      !filterMode.includes(TopUsersGroupFilterSort.unproductive)
                    }
                  >
                    Unproductive
                  </DashboardSelectMenuItem>
                  <DashboardSelectMenuItem
                    value={TopUsersGroupFilterSort.undefined}
                    disabled={
                      !filterMode.includes(TopUsersGroupFilterSort.undefined)
                    }
                  >
                    Undefined
                  </DashboardSelectMenuItem>
                </DashboardSelectMenu>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
