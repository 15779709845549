import React from 'react';
import authorization from '../../../common/helpers/authorization';
import { ExportSize } from '../../../common/stores/exportsStore/exportStore.models';
import { EXPORT_DESTINATION_OPTIONS } from '../../../common/stores/exportsStore/exportStore.constants';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { useExportParamsStore } from '../../../common/stores/exportsStore/exportParamsStore.hooks';
import { ReportType } from '../../../common/enums/ReportType';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { createExportPayload } from '../utils/scheduleAdherence.utils';
import { AsyncExportButton } from '../../../common/components/ReportFilters/components/AsyncExportButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';

export const ScheduleAdherenceExport = () => {
  const isRefreshing = useRefreshStore((s) => s.isRefreshing);
  const hasDataForTable = useExportParamsStore((s) => s.hasDataForTable);

  const hasAsyncExport: boolean = authorization.hasFeature(
    BundleFlag.WorkingHoursReportExport
  );

  const hasLargeExport: boolean = authorization.hasFeature(
    BundleFlag.LargeExport
  );

  if (!hasAsyncExport) return <ExportButtonContainer />;

  return (
    <AsyncExportButton
      reportType={ReportType.ScheduleAdherence}
      createExportPayload={createExportPayload}
      showExportsLimitedTooltip={true}
      exportableItemCount={
        hasLargeExport ? ExportSize.LargeBatch : ExportSize.SmallBatch
      }
      menuOptions={EXPORT_DESTINATION_OPTIONS}
      isDisabled={!hasDataForTable || isRefreshing}
    />
  );
};
