import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { accountSelector } from '../utils';

export const displayMessage = {
  addAccountSuccess: 'Account successfully added to dashboard!',
  generalError: {
    addToPortal:
      'An error occurred while attempting to add the account to the dashboard. Please try again later.',
    redirect:
      "An error occurred while attempting to redirect to the account's app page. Please try again later."
  },
  modalError: 'Unable to open details modal at this time',
  apiError: {
    addToPortal: 'Unable to add account to dashboard at this time',
    redirect: 'Unable to redirect to account at this time'
  },
  nonExistentAccount: {
    addToPortal: (payload: IAddAccountPayloadDto) =>
      `Unable to add ${accountSelector(
        payload
      )} to the dashboard because the account does not exist`,
    redirect: (payload: IAddAccountPayloadDto) =>
      `Unable to redirect with ${accountSelector(
        payload
      )} because the account does not exist`
  },
  duplicateAccountError: {
    addToPortal: (accountId: string) =>
      `Acct#${accountId} already displayed on dashboard. Please check the displayed account cards.`,
    redirect: (accountId: string) =>
      `Unable to redirect since  acct#${accountId} is already displayed on dashboard.`
  },
  dataCollection: {
    success: (swapValue: string) =>
      `Data Collection successfully turned ${swapValue}!`,
    error: 'Cannot update Data Collection setting at this time'
  },
  verifyAccount: {
    success: (accountId: string) => `Acct#${accountId} successfully verified!`,
    error: 'Cannot verify account at this time'
  },
  unlockUser: {
    success: (username: string) => `User ${username} successfully unlocked!`,
    error: 'Cannot unlock the user at this time'
  }
};

export const consoleMessage = {
  tokenValidationError: {
    addToPortal:
      'Failed to add account to dashboard: Malformed data detected in the token data.',
    redirect:
      'Failed to redirect to account: Malformed data detected in the token data.'
  },
  accountFetchValidationError: {
    addToPortal:
      'Failed to add account to dashboard: Malformed data detected in the account details.',
    redirect:
      'Failed to redirect to account: Malformed data detected in the account details.',
    modalError:
      'Failed to open modal: Malformed data detected in the account details.'
  },
  duplicateAccountError: {
    addToPortal: (accountId: string) =>
      `Unable to add account ${accountId} to the portal because the account already exists in the dashboard`,
    redirect: (accountId: string) =>
      `Unable to redirect to account #${accountId} because the account already exists on the dashboard.`
  },
  tokenError: {
    400: 'Unable to fetch token data due to client error.',
    500: ' Unable to fetch token data due to server mishap.',
    network: 'Unable to fetch token data due to network error.',
    unknown: 'An unknown error occurred while fetching token data.'
  },
  accountFetchError: {
    400: 'Unable to fetch new account details due to client error.',
    500: ' Unable to fetch new account details due to server mishap.',
    network: 'Unable to fetch new account details due to network error.',
    unknown: 'An unknown error occurred while fetching new account details.'
  },
  snapshotError: {
    400: 'Unable to fetch account snapshot due to client error.',
    500: ' Unable to fetch account snapshot due to server mishap.',
    network: 'Unable to fetch account snapshot due to network error.',
    unknown: 'An unknown error occurred while fetching account snapshot.'
  },
  dataCollectionError: {
    400: 'Unable to update Data Collection setting due to client error.',
    500: 'Unable to update Data Collection setting due to server mishap.',
    network: 'Unable to update Data Collection setting due to network error.',
    unknown: 'An unknown error occurred while setting data collection.'
  },
  verifyAccountError: {
    400: 'Unable to verify account due to client error.',
    500: 'Unable to verify account due to server mishap.',
    network: 'Unable to verify account due to network error.',
    unknown: 'An unknown error occurred while attempting to verify the account.'
  },
  unlockUserError: {
    400: 'Unable to unlock user due to client error.',
    500: 'Unable to unlock user due to server mishap.',
    network: 'Unable to unlock user due to network error.',
    unknown: 'An unknown error occurred while attempting to unlock the user.'
  }
};
