import { useEffect, useState } from 'react';

import { useMenuItemStore, findItemById } from '../navigationStore';

import {
  useFavoritesStore,
  addToFavorites,
  removeFromFavorites,
  subscribe as subscribeToStore,
  unsubscribe as unsubscribeFromStore
} from '../favoritesStore';

export interface ISideBarState {
  favorites: any[];
  favoritesMenuOpen: boolean;
  isFavoriteNavigationEnabled: boolean;

  setFavorites: (favorites: any[]) => void;
  setFavoritesMenuOpen: (open: boolean) => void;

  isInFavorites: (item: any) => boolean;
  removeFromFavorites: (item: any) => void;
  addToFavorites: (item: any) => void;
  findItemById: (id: string) => any;
}

export const useNavigationState = () => {
  const items = useMenuItemStore((state) => state.menuItems);

  return {
    menu: [...items]
  };
};

export const useFavoritesState = (): ISideBarState => {
  const [favoritesMenuOpen, setFavoritesMenuOpen] = useState(true);

  const {
    favorites,
    setFavorites,
    isInFavorites,
    isFavoriteNavigationEnabled
  } = useFavoritesStore((s) => s);

  //Opens/Closes the favorites section based on the number of items
  useEffect(() => {
    subscribeToStore((event: string, args: any[]) => {
      if (
        (event === 'start:addToFavorites' && args.length === 0) ||
        (event === 'start:removeFromFavorites' && args.length === 1)
      ) {
        setFavoritesMenuOpen(true);
      }
    });

    return () => {
      unsubscribeFromStore();
    };
  });

  return {
    favorites,
    setFavorites,
    isInFavorites,
    removeFromFavorites,
    addToFavorites,
    findItemById,
    favoritesMenuOpen,
    setFavoritesMenuOpen,
    isFavoriteNavigationEnabled
  };
};
