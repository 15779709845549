import viewTemplate from 'views/widgets/productivityChartLegend.html?raw';
import { createProductivityLegendItems } from '_app/productivity/productivityService';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('productivityChartLegend', function () {
    return {
        restrict: 'E',
        scope: {
            toggleBar: '=',
            productivityList: '=',
            barToggleClass: '=',
            isUndefined: '=',
            showDetails: '='
        },
        template: viewTemplate,
        controller: ProductivityChartLegendCtrl
    };
});

ProductivityChartLegendCtrl.$inject = ['$scope', 'localStorageService', '$location'];

function ProductivityChartLegendCtrl($scope, localStorageService, $location) {
    var itemClicked = function (item) {
        $scope.toggleBar(item.productivity);
    };

    function setItems() {
        var { username } = profileStore.getState().profile;
        var view = localStorageService.get('productivity-view-' + username);
        var location = $location.path();
        $scope.items = createProductivityLegendItems(
            itemClicked,
            $scope.productivityList,
            $scope.barToggleClass,
            view,
            location
        );
    }

    $scope.$watch('showDetails', setItems);
    $scope.$watch('productivityList', setItems, true);
    $scope.$watch('barToggleClass', setItems, true);
}
