import React from 'react';
import { SortDirection } from '../../../common/enums';
import { ITopActivity } from '../../models';
import CustomGrid from '../../../common/components/CustomGrid/CustomGrid';
import { useCustomRowsSkeletonLoader } from '../../../common/components/CustomGrid/CustomGrid.utils';
import { useActivityColumns } from '../../utils';
import Grid from '@mui/material/Grid';

type ActivityTopUsersProps = {
  users: ITopActivity[];
  isActivityDetailLoading: boolean;
};

export default function ActivityTopUsers({
  users,
  isActivityDetailLoading
}: ActivityTopUsersProps) {
  const subpageColumn = {
    field: 'user',
    fieldName: 'User',
    headerName: 'User',
    type: 'string',
    isSortable: true,
    width: '75%',
    template: (dataItem: ITopActivity) => {
      return <div className="truncate">{dataItem.name}</div>;
    }
  };
  const columns = useActivityColumns();
  const gridColumns = [subpageColumn, ...columns];

  //For MVP: Next Iteration will handle navigation
  const takeThree = users?.slice(0, 3) ?? [];

  const activitySkeletonLoader = useCustomRowsSkeletonLoader(3, 2, false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomGrid
          data={[...takeThree]}
          columns={gridColumns}
          initialSortField="duration"
          initialSortDirection={SortDirection.Descending}
          uniqueRowProp="uniqueId"
          isLoading={isActivityDetailLoading}
          skeletonLoaderTemplate={activitySkeletonLoader}
          height="127px"
        />
      </Grid>
    </Grid>
  );
}
