import { useCallback, useState } from 'react';

import { useAuthorization } from '../../common/services/Authorization';
import { IRoleAccess } from '../../common/services/GetRoles';
import { useRoleAccess } from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { RoleAccessKeys, rowType } from '../../common/enums';
import { createRow } from '.';
import { rowSelection, IRoleAccessViewModel } from '../../common/models';

// TODO: Temporary - will relocate
import { create } from 'zustand';
import { PlanType } from '../../common/enums/PlanType';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import { BundleFlag } from '../../common/enums/BundleFlag';
interface IRoleAccessView {
  rows: IRoleAccessViewModel[];
  setRows: (rows: IRoleAccessViewModel[]) => void;
}

const useRoleAccessViewStore = create<IRoleAccessView>((set) => ({
  rows: [],
  setRows: (rows: IRoleAccessViewModel[]) => set({ rows })
}));

export enum keyMap {
  AdminCheck = 'adminCheck',
  ConfigCheck = 'configCheck',
  PowerCheck = 'powerCheck',
  ViewerCheck = 'viewerCheck'
}

export enum roleTypes {
  Administrator = 'admin',
  Configurator = 'configurator',
  User = 'user',
  Teamviewer = 'teamviewer'
}

const alwaysAccess: rowSelection = {
  disabled: true,
  checked: true,
  showReadOnly: false,
  alwaysAccess: true
};
const noAccess: rowSelection = {
  disabled: false,
  checked: false,
  showReadOnly: false
};
const notAvailable: rowSelection = {
  disabled: true,
  checked: false,
  showReadOnly: false
};
const noAccessReadOny: rowSelection = {
  disabled: false,
  checked: false,
  showReadOnly: true
};

export const useRoleAccessInitialData = () => {
  const authorization = useAuthorization();
  const { roleAccessService, getRolesService } = useRoleAccess();
  const notificationService = useNotifications();

  const [busy, setBusy] = useState(false);

  const { rows: rolesAccess, setRows: setRolesAccess } =
    useRoleAccessViewStore();
  const { screenshotsAllowed } = getPrivacySettings();

  const getAlarmsRows = useCallback(() => {
    const rows = [];

    rows.push(
      createRow(
        RoleAccessKeys.Alarms,
        noAccess,
        noAccess,
        noAccessReadOny,
        notAvailable,
        rowType.Container,
        ''
      )
    );

    const featureFlags = [];

    featureFlags.push({
      flag: 'isRiskLevelReportEnabled',
      name: RoleAccessKeys.AlarmsRiskLevel
    });
    featureFlags.push({
      flag: 'isAlertLogEnabled',
      name: RoleAccessKeys.AlarmsLog
    });
    featureFlags.push({
      flag: 'isConfigurationEnabled',
      name: RoleAccessKeys.AlarmsConfiguration
    });

    if (screenshotsAllowed) {
      featureFlags.push({
        flag: 'isScreenshotsHistoryEnabled',
        name: RoleAccessKeys.AlarmsScreenshotsReports,
        secondaryText: 'includes Latest per User and History'
      });
      featureFlags.push({
        flag: 'isScreenshotRedactionEnabled',
        name: RoleAccessKeys.AlarmsScreenshotsRedaction,
        powerUser: notAvailable
      });
      featureFlags.push({
        flag: 'isScreenshotFlaggingEnabled',
        name: RoleAccessKeys.AlarmsScreenshotsFlagging,
        powerUser: notAvailable
      });
      featureFlags.push({
        flag: 'isVideoEnabled',
        name: RoleAccessKeys.AlarmsVideos
      });
    }

    featureFlags.push({
      flag: 'isNotificationsEnabled',
      name: RoleAccessKeys.AlarmsNotifications
    });

    // TODO: replace this map with row by row entries - rules are too specific to be handled this way...
    const items = featureFlags
      .filter((feature) => authorization.hasFeature(feature.flag))
      .map((feature) => {
        return createRow(
          feature.name,
          noAccess,
          noAccess,
          feature.powerUser ? feature.powerUser : noAccessReadOny,
          notAvailable,
          rowType.SubItem,
          feature.secondaryText,
          [PlanType.Professional]
        );
      });

    return items && items.length > 0 ? [...rows, ...items] : [];
  }, [authorization, screenshotsAllowed]);

  const getDashboardsRows = useCallback(() => {
    const dashboardRows = [
      createRow(
        RoleAccessKeys.Dashboards,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.Container,
        ''
      )
    ];

    if (authorization.hasFeature('isPaidFunctionalityEnabled')) {
      dashboardRows.push(
        createRow(
          RoleAccessKeys.DashboardsAdmin,
          noAccess,
          noAccess,
          notAvailable,
          notAvailable,
          rowType.SubItem,
          '',
          [PlanType.Essentials, PlanType.Professional]
        )
      );
    }

    if (authorization.hasFeature('isOrganizationOverviewEnabled')) {
      dashboardRows.push(
        createRow(
          RoleAccessKeys.DashboardsOrgOverview,
          noAccess,
          noAccess,
          noAccess,
          noAccess,
          rowType.SubItem,
          '',
          [PlanType.Essentials, PlanType.Professional]
        )
      );
    }

    if (authorization.hasFeature('isTeamManagementEnabled')) {
      dashboardRows.push(
        createRow(
          RoleAccessKeys.DashboardsTeamMgmt,
          noAccess,
          noAccess,
          noAccess,
          noAccess,
          rowType.SubItem,
          '',
          [PlanType.Professional]
        )
      );
    }
    // All plans get Activity Dashboard
    dashboardRows.push(
      createRow(
        RoleAccessKeys.DashboardsActivity,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    return dashboardRows;
  }, [authorization]);

  const getLiveReportRows = useCallback(() => {
    const liveReportRows = [
      createRow(
        'Live Reports',
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.Container,
        ''
      )
    ];

    liveReportRows.push(
      createRow(
        RoleAccessKeys.TopUsers,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    liveReportRows.push(
      createRow(
        RoleAccessKeys.Productivity,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    liveReportRows.push(
      createRow(
        RoleAccessKeys.WorkingHours,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    if (authorization.hasFeature(BundleFlag.ScheduleAdherenceReport)) {
      liveReportRows.push(
        createRow(
          RoleAccessKeys.ScheduleAdherence,
          noAccess,
          noAccess,
          noAccess,
          noAccess,
          rowType.SubItem,
          ''
        )
      );
    }

    liveReportRows.push(
      createRow(
        RoleAccessKeys.TopWebsites,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    liveReportRows.push(
      createRow(
        RoleAccessKeys.TopApplications,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    liveReportRows.push(
      createRow(
        RoleAccessKeys.TopCategories,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    liveReportRows.push(
      createRow(
        RoleAccessKeys.ActivityLog,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        ''
      )
    );

    return liveReportRows;
  }, [authorization]);

  const getRoleAccessInitialData = useCallback((): IRoleAccessViewModel[] => {
    const alarmsRows = getAlarmsRows();
    const dashboardsRows = getDashboardsRows();
    const liveReportRows = getLiveReportRows();

    return [
      ...dashboardsRows,
      createRow(
        RoleAccessKeys.TeamPulse,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.Item,
        ''
      ),
      createRow(
        RoleAccessKeys.Coaching,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.Item,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.Insights,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.Container,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.ExecutiveSummary,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.TeamComparison,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.Location,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.ActivityBreakdown,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.EssentialsPlus, PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.TimeOnTask,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        'Coming Soon',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.WorkEfficiency,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.WorkloadBalance,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.TechnologyUsage,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.BenchmarkAndGoals,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.PersonalInsights,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.SubItem,
        '',
        [PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.Subscriptions,
        alwaysAccess,
        noAccess,
        notAvailable,
        notAvailable,
        rowType.SubItem,
        '',
        [PlanType.EssentialsPlus, PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.Configuration,
        alwaysAccess,
        noAccess,
        notAvailable,
        notAvailable,
        rowType.SubItem,
        '',
        [PlanType.EssentialsPlus, PlanType.Professional]
      ),
      createRow(
        RoleAccessKeys.Impact,
        noAccess,
        noAccess,
        noAccess,
        noAccess,
        rowType.Item,
        '',
        [PlanType.Professional]
      ),
      ...liveReportRows,
      createRow(
        RoleAccessKeys.Integrations,
        noAccess,
        noAccess,
        noAccess,
        notAvailable,
        rowType.Item,
        ''
      ),
      ...alarmsRows,
      createRow(
        RoleAccessKeys.Settings,
        alwaysAccess,
        noAccess,
        notAvailable,
        notAvailable,
        rowType.Item,
        ''
      )
    ];
  }, [getAlarmsRows, getDashboardsRows]);

  const mapRoleAccess = useCallback(
    (entities: IRoleAccess[]): IRoleAccessViewModel[] => {
      const accessRows = getRoleAccessInitialData();
      const newAccessRows = accessRows.map(function (target) {
        const origin = entities.find(function (item) {
          return (
            item.resource.replace(/\s/g, '').toLowerCase() ===
            target.resource.replace(/\s/g, '').toLowerCase()
          );
        });

        if (origin != undefined) {
          Object.keys(target).map(function (keyName) {
            const item = { ...target[keyName] };

            //Disabled items by default are excluded
            if (item['disabled'] !== undefined) {
              if (keyName === keyMap.AdminCheck) {
                item.checked = origin.roles.some((role) => {
                  return role.toLowerCase() === roleTypes.Administrator;
                });
              }

              if (keyName === keyMap.ConfigCheck) {
                item.checked = origin.roles.some((role) => {
                  return role.toLowerCase() === roleTypes.Configurator;
                });
              }

              if (keyName === keyMap.PowerCheck) {
                item.checked = origin.roles.some((role) => {
                  return role.toLowerCase() === roleTypes.User;
                });
              }

              if (keyName === keyMap.ViewerCheck) {
                item.checked = origin.roles.some((role) => {
                  return role.toLowerCase() === roleTypes.Teamviewer;
                });
              }
              target[keyName] = item;
            }
          });
        }
        return target;
      });

      return newAccessRows;
    },
    [getRoleAccessInitialData]
  );

  const handleChange = async (object) => {
    if (busy) return;
    setBusy(() => true);

    const checkSelected = (access: rowSelection): boolean =>
      (access.disabled === false && access.checked === true) ||
      access.alwaysAccess;

    const meta = object.target.id.split(`_`);
    const resource = meta[0];
    const roleCheck = meta[1];

    const newAccess = rolesAccess.map((access) => {
      if (access.resource === resource) {
        access.adminCheck.checked =
          roleCheck === 'adminCheck'
            ? object.target.checked
            : access.adminCheck.checked;
        access.configCheck.checked =
          roleCheck === 'configCheck'
            ? object.target.checked
            : access.configCheck.checked;
        access.powerCheck.checked =
          roleCheck === 'powerCheck'
            ? object.target.checked
            : access.powerCheck.checked;
        access.viewerCheck.checked =
          roleCheck === 'viewerCheck'
            ? object.target.checked
            : access.viewerCheck.checked;
      }
      return access;
    });

    setRolesAccess(newAccess);
    const data: IRoleAccess[] = newAccess.map((access) => {
      const roles = [
        checkSelected(access.adminCheck) ? 'Admin' : '',
        checkSelected(access.configCheck) ? 'Configurator' : '',
        checkSelected(access.powerCheck) ? 'User' : '',
        checkSelected(access.viewerCheck) ? 'TeamViewer' : ''
      ];

      const filterRoles = [...roles.filter((role) => role !== '')];

      return {
        resource: access.resource,
        roles: filterRoles
      } as IRoleAccess;
    });

    try {
      await roleAccessService.set(data);
      notificationService?.success('Success! Role Access saved.');
    } catch (error) {
      notificationService?.error('Failed to set role.');
    } finally {
      setBusy(() => false);
    }
  };

  const getSettings = useCallback(async () => {
    setBusy(() => true);
    try {
      const entities = await getRolesService.getAll();
      const roleAccessViewModel = mapRoleAccess(entities);
      setRolesAccess([...roleAccessViewModel]);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setBusy(() => false);
    }
  }, [getRolesService, mapRoleAccess, setRolesAccess]);

  const resetDefault = async () => {
    try {
      await roleAccessService.setDefault();
      const entities = await getRolesService.getAll();
      const roleAccessViewModel = mapRoleAccess(entities);
      setRolesAccess([...roleAccessViewModel]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { rolesAccess, busy, handleChange, resetDefault, getSettings };
};
