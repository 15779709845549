import {
  notifError,
  notifSuccess,
  notifWarn,
  notifInfo
} from '../../constants';

export const AT_CONSOLE_LOG_STYLES = {
  warn: `font-size:12px;background-color:${notifWarn};color:#000;`,
  error: `font-size:12px;background-color:${notifError};color:#000;`,
  info: `font-size:12px;background-color:${notifInfo};color:#fff;`,
  debug: `font-size:12px;background-color:${notifSuccess};color:#000;`
};
