import { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import {
  getParsedLocalStorageItem
} from '../../common/utils/localStorage';
import { AddAccountButton, CardsContainer } from '../components';
import {
  checkStoredAccounts
} from '../utils';
import { supportPortalLocalStorage } from '../constants';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { INewlyAddedAccount } from '../models/IAccountData';
import { restore } from '../../common/hooks/useImpersonate';

const SupportPortalView = () => {
  const [payload, setPayload] = useState<IAddAccountPayloadDto>(null);

  const localStorageAccounts: INewlyAddedAccount[] = getParsedLocalStorageItem(
    supportPortalLocalStorage.accounts
  );

  const [savedAccounts, setSavedAccounts] = useState<INewlyAddedAccount[]>(
    checkStoredAccounts(localStorageAccounts)
  );
  const [actionType, setActionType] = useState<string>('');

  useEffect(() => {
    (
      async () => {
        await restore();
      }
    )();
    
  });

  return (
    <Grid container p={4} ml={30} spacing={3}>
      <Grid item xs={12} sm>
        <Stack spacing={2}>
          <AddAccountButton
            setPayload={setPayload}
            savedAccounts={savedAccounts}
            setActionType={setActionType}
          />
          <CardsContainer
            setPayload={setPayload}
            payload={payload}
            setSavedAccounts={setSavedAccounts}
            savedAccounts={savedAccounts}
            actionType={actionType}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export const SupportPortal = () => (
  <ReactivTrakComponentWrapper>
    <SupportPortalView />
  </ReactivTrakComponentWrapper>
);
