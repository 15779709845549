import { durationFormat } from '../../common/utils/datetime/time';
import { ITopActivity } from '../models';

export const useActivityColumns = () => {
  return [
    {
      field: 'duration',
      fieldName: 'Duration',
      headerName: 'Duration',
      type: 'number',
      isSortable: true,
      width: '25%',
      template: (dataItem: ITopActivity) => {
        return durationFormat(dataItem.duration, true);
      }
    }
  ];
};
