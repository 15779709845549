import React from 'react';
import { IAgentCommand } from '../models';
import { CommandsContainer } from '../styles';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { TextOverflow } from '../../common/styles/app.component.styles';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';

type ComponentProps = {
  agentCommandHistory: IAgentCommand[];
  openDiagnosticsModal(agentCommand: any): void;
};

export const CommandGrid = (props: ComponentProps) => {
  const { agentCommandHistory, openDiagnosticsModal } = props;

  const commandColumn = (agentCommand: IAgentCommand) => {
    return (
      <Tooltip title={agentCommand.codeFormatted} placement="bottom">
        <TextOverflow>{agentCommand.codeFormatted}</TextOverflow>
      </Tooltip>
    );
  };

  const statusColumn = (agentCommand: IAgentCommand) => {
    return (
      <span>
        {agentCommand.status?.outcome
          ? agentCommand.status?.outcome
          : 'Pending'}
      </span>
    );
  };

  const messageColumn = (agentCommand: IAgentCommand) => {
    return (
      <TextOverflow>
        {agentCommand.status?.parsedStatusMessage ? (
          <Button
            onClick={() => openDiagnosticsModal(agentCommand)}
            sx={{ fontWeight: 'normal', letterSpacing: '0.5px' }}
          >
            {agentCommand.status?.message}{' '}
          </Button>
        ) : (
          <>{agentCommand.status?.message}</>
        )}
      </TextOverflow>
    );
  };

  const createdColumn = (agentCommand: IAgentCommand) => {
    return (
      <span>
        {agentCommand.creationTime && formatDateTime(agentCommand.creationTime)}{' '}
        UTC
      </span>
    );
  };

  const statusUpdatedColumn = (agentCommand: IAgentCommand) => {
    return (
      <span>
        {agentCommand.status?.receivedTime &&
          formatDateTime(agentCommand.status?.receivedTime)}{' '}
        UTC
      </span>
    );
  };

  const aliasColumn = (agentCommand: IAgentCommand) => {
    return (
      <span>
        {agentCommand.status?.executionTime &&
          formatDateTime(agentCommand.status?.executionTime)}{' '}
        UTC
      </span>
    );
  };

  const getCommandGridColumns = (): IGridColumn[] => {
    return [
      {
        field: 'code',
        fieldName: 'code',
        headerName: 'Type',
        type: 'string',
        width: '17%',
        template: commandColumn
      },
      {
        headerName: 'Status',
        align: 'center',
        type: 'string',
        width: '7%',
        template: statusColumn
      },
      {
        headerName: 'Message',
        align: 'center',
        type: 'string',
        width: '22%',
        template: messageColumn
      },
      {
        headerName: `Created`,
        align: 'center',
        type: 'string',
        width: '18%',
        template: createdColumn
      },
      {
        headerName: 'Status Updated',
        align: 'center',
        type: 'string',
        width: '18%',
        template: statusUpdatedColumn
      },
      {
        field: 'alias',
        align: 'center',
        headerName: 'Agent Execution',
        type: 'string',
        width: '18%',
        template: aliasColumn
      }
    ];
  };

  return (
    <CommandsContainer>
      <CustomGrid
        isVirtualized={false}
        data={agentCommandHistory}
        columns={getCommandGridColumns()}
        uniqueRowProp="uniqueRowId"
      />
    </CommandsContainer>
  );
};
