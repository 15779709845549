import { getApiDomainStore } from "../../../_reactivtrak/src/common/stores/apiDomainStore/apiDomainStore";

angular.module('core')
    .service('verificationApiService', VerificationApiService);

VerificationApiService.$inject = ['$http'];

function VerificationApiService($http) {
    const apiUrl = getApiDomainStore().frontendApi;

    this.sendEmail = function (payload) {
        return $http.post(apiUrl + '/account/verify/send/', payload);
    };

    this.verifyByToken = function (token) {
        return $http.post(apiUrl + '/account/verify?vtoken=' + token, {});
    };
}
