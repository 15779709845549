import { useLegacyPageToggleStore } from '../../../common/components/Navigation/legacyPageToggle.store';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { WorkingHoursLegacyReportView } from './WorkingHoursLegacyReport.view';
import { WorkingHoursReportView } from './WorkingHoursReport.view';

export const WorkingHoursReportFlowComponent = () => {
  const legacyToggleState = useLegacyPageToggleStore(
    (s) => s['app.reports.workingHours']
  );

  // if there is no legacy toggle state for this report in the Store, or it does exist and is true, then default to the legacy report
  const View =
    legacyToggleState == undefined || legacyToggleState
      ? WorkingHoursLegacyReportView
      : WorkingHoursReportView;

  return (
    <ReactivTrakComponentWrapper>
      <View />
    </ReactivTrakComponentWrapper>
  );
};
