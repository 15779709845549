import { ColumnState } from 'ag-grid-community';
import {
  ScheduleAdherenceExportExcludeColumn,
  ScheduleAdherenceDataId
} from '../models/ScheduleAdherence.models';

export const SCHEDULED_TIMELINE_STORE_KEY: string =
  'activTrak.reportTimes-scheduleAdherence';

export const SCHEDULE_SUMMARY_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: ScheduleAdherenceDataId.Date, hide: false },
  { colId: ScheduleAdherenceDataId.User, hide: false },
  { colId: ScheduleAdherenceDataId.Location, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveShiftTime, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveActiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.ProductivePassiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UnproductiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UnproductiveActiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UnproductivePassiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UndefinedShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UndefinedActiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UndefinedPassiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.ProductiveGoalAchievement, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveHoursGoal, hide: true },
  { colId: ScheduleAdherenceDataId.FirstActivity, hide: true },
  { colId: ScheduleAdherenceDataId.LastActivity, hide: true },
  { colId: ScheduleAdherenceDataId.ShiftPunctuality, hide: false },
  { colId: ScheduleAdherenceDataId.EarlyDeparture, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveNonShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.ScreenTime, hide: true },
  { colId: ScheduleAdherenceDataId.TotalTimeOffline, hide: true },
  { colId: ScheduleAdherenceDataId.TotalProductiveAndTimeOff, hide: false }
];

export const SCHEDULE_DETAILED_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: ScheduleAdherenceDataId.Date, hide: false },
  { colId: ScheduleAdherenceDataId.User, hide: false },
  { colId: ScheduleAdherenceDataId.Location, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveShiftTime, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveActiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.ProductivePassiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UnproductiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UnproductiveActiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UnproductivePassiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UndefinedShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UndefinedActiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.UndefinedPassiveShiftTime, hide: true },
  { colId: ScheduleAdherenceDataId.ProductiveGoalAchievement, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveHoursGoal, hide: true },
  { colId: ScheduleAdherenceDataId.FirstActivity, hide: false },
  { colId: ScheduleAdherenceDataId.LastActivity, hide: false },
  { colId: ScheduleAdherenceDataId.ShiftPunctuality, hide: false },
  { colId: ScheduleAdherenceDataId.EarlyDeparture, hide: false },
  { colId: ScheduleAdherenceDataId.ProductiveNonShiftTime, hide: false },
  { colId: ScheduleAdherenceDataId.ScreenTime, hide: false },
  { colId: ScheduleAdherenceDataId.TotalTimeOffline, hide: true },
  { colId: ScheduleAdherenceDataId.TotalProductiveAndTimeOff, hide: false }
];

export const SCHEDULE_ADHERENCE_EXPORT_EXCLUDE_COLUMNS_MAP: Record<
  ScheduleAdherenceDataId,
  ScheduleAdherenceExportExcludeColumn
> = {
  [ScheduleAdherenceDataId.Date]: ScheduleAdherenceExportExcludeColumn.Date,
  [ScheduleAdherenceDataId.User]: ScheduleAdherenceExportExcludeColumn.User,
  [ScheduleAdherenceDataId.Location]:
    ScheduleAdherenceExportExcludeColumn.Location,
  [ScheduleAdherenceDataId.ProductiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.ProductiveShiftTime,
  [ScheduleAdherenceDataId.ProductiveActiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.ProductiveActiveShiftTime,
  [ScheduleAdherenceDataId.ProductivePassiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.ProductivePassiveShiftTime,
  [ScheduleAdherenceDataId.UnproductiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.UnproductiveShiftTime,
  [ScheduleAdherenceDataId.UnproductiveActiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.UnproductiveActiveShiftTime,
  [ScheduleAdherenceDataId.UnproductivePassiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.UnproductivePassiveShiftTime,
  [ScheduleAdherenceDataId.UndefinedShiftTime]:
    ScheduleAdherenceExportExcludeColumn.UndefinedShiftTime,
  [ScheduleAdherenceDataId.UndefinedActiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.UndefinedActiveShiftTime,
  [ScheduleAdherenceDataId.UndefinedPassiveShiftTime]:
    ScheduleAdherenceExportExcludeColumn.UndefinedPassiveShiftTime,
  [ScheduleAdherenceDataId.ProductiveGoalAchievement]:
    ScheduleAdherenceExportExcludeColumn.ProductiveGoalAchievement,
  [ScheduleAdherenceDataId.ProductiveHoursGoal]:
    ScheduleAdherenceExportExcludeColumn.ProductiveHoursGoal,
  [ScheduleAdherenceDataId.FirstActivity]:
    ScheduleAdherenceExportExcludeColumn.FirstActivity,
  [ScheduleAdherenceDataId.LastActivity]:
    ScheduleAdherenceExportExcludeColumn.LastActivity,
  [ScheduleAdherenceDataId.ShiftPunctuality]:
    ScheduleAdherenceExportExcludeColumn.ShiftPunctuality,
  [ScheduleAdherenceDataId.EarlyDeparture]:
    ScheduleAdherenceExportExcludeColumn.EarlyDeparture,
  [ScheduleAdherenceDataId.ProductiveNonShiftTime]:
    ScheduleAdherenceExportExcludeColumn.ProductiveNonShiftTime,
  [ScheduleAdherenceDataId.ScreenTime]:
    ScheduleAdherenceExportExcludeColumn.ScreenTime,
  [ScheduleAdherenceDataId.TotalTimeOffline]:
    ScheduleAdherenceExportExcludeColumn.TotalTimeOffline,
  [ScheduleAdherenceDataId.TotalProductiveAndTimeOff]:
    ScheduleAdherenceExportExcludeColumn.TotalProductiveAndTimeOff
};
