import React, { useState, useRef } from 'react';
import BulkFilterMenuProps from './BulkFilterMenu.models';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { ButtonSC } from './BulkFilterMenu.styles';

export const BulkFilterMenu = (props: BulkFilterMenuProps) => {
  const {
    children,
    className,
    icon,
    menuOptions,
    onChangeOption,
    isIconRightAligned,
    hideselectedoption,
    disableSelectedItem,
    disabled,
    disablePortal,
    indexOption = 0,
    setIndexOption,
    popperPlacement
  } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    event.stopPropagation();
    setIndexOption(index);
    onChangeOption(event, menuOptions[index], index);
    setOpen(false);
  };

  const getMenuLabel = (option) => {
    const { label, template } = option;
    return template ? template(label) : label;
  };

  const getPrimaryButtonLabel = () => {
    if (children) {
      return children;
    } else {
      return hideselectedoption ? null : getMenuLabel(menuOptions[indexOption]);
    }
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    menuOptions && (
      <div className={className}>
        <div ref={anchorRef}>
          {/* NOTE: "hideselectedoption" - Properties that are not inherent to a component need to be lowercase. And if custom boolean property values are expected to be strings (in unit tests), you can force a boolean by passing the binary value instead. */}
          <ButtonSC
            id="bulk-filter-button"
            onClick={handleToggle}
            startIcon={isIconRightAligned ? null : icon}
            endIcon={isIconRightAligned ? icon : null}
            disabled={disabled}
            hideselectedoption={hideselectedoption ? 1 : 0}
          >
            {getPrimaryButtonLabel()}
          </ButtonSC>
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal={disablePortal}
          placement={(popperPlacement as PopperPlacementType) || 'bottom'}
          sx={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="bulk-filter-menu">
                    {menuOptions.map((option, index) => {
                      return (
                        <MenuItem
                          key={index}
                          selected={
                            disableSelectedItem ? false : index === indexOption
                          }
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {getMenuLabel(option)}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  );
};
