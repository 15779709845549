import React from 'react';
import Button from '@mui/material/Button';
import { useNotifications } from '../../common/services/Notifications';
import { SupportPortalService, useSupportPortalState } from '../hooks';
import { displayMessage, consoleMessage } from '../constants';
import { IAccountIdPayloadDto } from '../models/IPayloadDto';

interface IVerifyAccountButtonProps {
  value: boolean;
  accountId: string;
  setDetailsModalClose?: () => void;
}

export const VerifyAccountButton = (props: IVerifyAccountButtonProps) => {
  const { value, accountId, setDetailsModalClose } = props;

  const service = SupportPortalService();
  const { setVerifyAccount } = useSupportPortalState({ service });

  const notificationService = useNotifications();

  const buttonText = value ? 'Verified' : 'Verify Account ';

  const payload: IAccountIdPayloadDto = {
    accountId: accountId
  };

  const handleClick = async () => {
    try {
      await setVerifyAccount(payload);
      notificationService.success(
        displayMessage.verifyAccount.success(accountId)
      );
    } catch (error) {
      notificationService.error(displayMessage.verifyAccount.error);
      console.error(
        `ActivTrak Error: ${
          error?.message || consoleMessage.verifyAccountError.unknown
        }`
      );
    } finally {
      setDetailsModalClose();
    }
  };

  return (
    <Button
      variant="contained"
      disabled={value}
      onClick={() => handleClick()}
      name="verifyAccount"
      sx={{ marginBottom: '10px', maxWidth: '200px' }}
    >
      {buttonText}
    </Button>
  );
};
