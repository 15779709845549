'use strict';

angular.module('app').factory('rootScopeConfig', rootScopeConfig);

function rootScopeConfig() {
    return {
        config: function(
            $rootScope,
            $scope,
            $state,
            $window,
            localStorageService,
            url
        ) {
            //log10 polyfill
            Math.log10 =
                Math.log10 ||
                function(x) {
                    return Math.log(x) / Math.LN10;
                };

            $rootScope.dismissNotifications = function() {
                angular.element('.pgn-wrapper').empty();
            };

            $rootScope.getProductivityStateParams = function(activity) {
                if (activity.url) {
                    return {
                        site: url.getDomain(activity.url)
                    };
                }
                if (
                    activity.executable !== null &&
                    activity.executable !== ''
                ) {
                    return {
                        executable: activity.executable
                    };
                }
                return null;
            };

            $rootScope.comboboxOption = function(data) {
                return {
                    dataTextField: data.dataTextField || 'text',
                    dataValueField: data.dataValueField || 'value',
                    dataSource: data.dataSource || [],
                    select: function() {
                        if (
                            this &&
                            this.input &&
                            typeof this.input.blur === 'function'
                        ) {
                            this.input.blur();
                        }
                    },
                    dataBound: function(e) {
                        e.sender.input
                            .attr('readonly', true)
                            .on('keydown', function(e) {
                                if (e.keyCode === 8) {
                                    e.preventDefault();
                                }
                            })
                            .on('click', function() {
                                e.sender.toggle();
                            });

                        if (typeof data.dataBound === 'function') {
                            data.dataBound(e);
                        }
                    }
                };
            };
        }
    };
}
