import React from 'react';
import {
  AlarmsIcon,
  DashboardIcon,
  InsightsIcon,
  LiveReportsIcon,
  IntegrationsIcon,
  SettingsIcon,
  LatestUpdatesIcon,
  HelpIcon,
  LogoutIcon,
  TeamPulseIcon,
  ImpactIcon,
  CoachIcon,
  PlaygroundIcon
} from '../../../assets/Icons';

import { sidebarClasses } from './SideBar.styles';
import { gray19 } from '../../../constants';

export const IconResolver = (props: any) => {
  const { icon } = props;

  if (!icon || Object.keys(icon).length === 0) {
    return <></>;
  }

  const iconsMap = {
    dashboard: DashboardIcon,
    notifications: AlarmsIcon,
    'icon-at-coaching': CoachIcon,
    'icon-at-analytics': InsightsIcon,
    'icon-at-live-reports': LiveReportsIcon,
    'icon-at-integrations': IntegrationsIcon,
    settings: SettingsIcon,
    new_releases: LatestUpdatesIcon,
    help: HelpIcon,
    'icon-at-logout': LogoutIcon,
    view_day: TeamPulseIcon,
    compare: ImpactIcon,
    labs: PlaygroundIcon
  };

  const ResolvedIcon = iconsMap[icon.value];

  return !ResolvedIcon ? 
    <></> :
  (
      <ResolvedIcon
        style={sidebarClasses.navigationItemIcon}
        height={24}
        width={24}
        fillColor={gray19}
      />
    );
};
