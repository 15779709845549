import angular from 'angular';
import { react2angular } from '../common/third-party/react2angular';
import { ProductivityReportViewComponent } from '../reports/productivity-report/views/ProductivityReport.view';

angular
  .module('app')
  .component(
    'productivityReport',
    react2angular(ProductivityReportViewComponent, [])
  );
