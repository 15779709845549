import { useEffect, useState } from 'react';
import { useUIRouterState } from '../../../../hooks/useUIRouterHistory';
import { useAuthorization } from '../../../../services/Authorization';
import {
  getLegacyPageToggleStore,
  setLegacyPageToggleStore,
  useLegacyPageToggleStore
} from '../../legacyPageToggle.store';
import { BundleFlag } from '../../../../enums/BundleFlag';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const LegacyPageToggle = () => {
  const [showLegacyPageToggle, setShowLegacyPageToggle] =
    useState<boolean>(false);

  const state = useUIRouterState<any>();
  const pageName = state?.name;
  const legacyToggleState =
    useLegacyPageToggleStore((s) => s[pageName]) ?? false;
  const authService = useAuthorization();

  useEffect(() => {
    setShowLegacyPageToggle(false);

    const alternatePage = state?.data?.alternatePage;
    const showLegacyToggle = alternatePage?.every((flag: BundleFlag) =>
      authService.hasFeature(flag)
    );

    if (showLegacyToggle) {
      //display the legacy toggle in the header
      setShowLegacyPageToggle(showLegacyToggle);

      // add a state for this page in the LegacyPageStore, if it doesn't exist
      const legacyPageToggles = getLegacyPageToggleStore();
      if (!legacyPageToggles[pageName]) {
        setLegacyPageToggleStore({ [pageName]: false });
      }
    }
  }, [state?.data?.alternatePage, authService, pageName]);

  if (!showLegacyPageToggle) return <></>;

  return (
    <Box sx={{ ml: 3 }}>
      <FormGroup>
        <FormControlLabel
          id="3d013157-0653-4a53-a2fa-a98fee77cec7"
          control={
            <Switch
              color="primary"
              checked={legacyToggleState}
              onChange={() =>
                setLegacyPageToggleStore({ [pageName]: !legacyToggleState })
              }
            />
          }
          label={
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
              Legacy View
            </Typography>
          }
          sx={{ mb: 0 }}
        />
      </FormGroup>
    </Box>
  );
};
