import { useEffect, useState } from 'react';

import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import { useUIRouterParams } from '../../common/hooks/useUIRouterParams';

import { useNotifications } from '../../common/services/Notifications';
import { GroupsProvider, useGroups } from '../../common/services/Groups';
import { GroupProvider, useGroup } from '../services';
import { PermissionLevel, SortDirection } from '../../common/enums';
import {
  bulkMembersFilters,
  isNotATGroupType,
  useGroupMembersColumns,
  selectedActivTrakGroups
} from '../utils';

import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import MemberSearchBar from '../components/MemberSearchBar';
import GroupMembersSettings from '../components/GroupMembersSettings';
import GroupNameHeader from '../components/GroupNameHeader';
import AddGroupMembersButton from '../components/AddGroupMembersButton';
import GroupDetails from '../components/GroupDetails';
import GroupBulkRemoveMemberButton from '../components/GroupBulkRemoveMemberButton';
import GroupAlarms from '../components/GroupAlarms';
import GroupClassification from '../components/GroupClassifications';
import { Box, CircularProgress, Grid, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { groupClasses } from '../styles/Group.styles';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { AlarmsProvider } from '../../common/services/Alarms';
import { GroupClassificationsProvider } from '../../common/services/Classifications';
import { getRoutePermissionLevel } from '../../common/stores/userPermissionsStore/userPermissionStore';

type GroupViewParams = {
  groupId?: string;
};

export function GroupView() {
  const [filter, setFilter] = useState<string>();

  const notificationService = useNotifications();
  const params = useUIRouterParams<GroupViewParams>();
  const { groupsState } = useGroups();
  const { groupState } = useGroup();

  const { getGroups, updateName, deleteGroups } = groupsState;
  const {
    init,
    isGroupLoading,
    isMembersLoading,
    group,
    groupMembers,
    groupActivities,
    allGroupMembers,
    groupNotification,
    searchGroupMembers,
    setSortedGroupMembers,
    setGroupNotification,
    setSelectedGroupMembers,
    deleteGroupMembers
  } = groupState;

  const permission = getRoutePermissionLevel('app.settings.groups');
  const readonly =
    permission === PermissionLevel.Read || isNotATGroupType(group?.filter);
  const columns = useGroupMembersColumns({
    disabled: isMembersLoading,
    readonly,
    deletable: groupMembers?.length > 0
  });
  const history = useUIRouterHistory();


  useEffect(() => {
    if (params?.groupId) {
      // TODO: lets make sure this groupId is legit
      const groupId = parseInt(params.groupId);
      init(groupId);
    }
  }, [params?.groupId, init]);

  useEffect(() => {
    searchGroupMembers(filter);
  }, [filter, searchGroupMembers]);

  useEffect(() => {
    if (groupNotification?.msg && groupNotification?.type) {
      notificationService[groupNotification.type](groupNotification.msg);
      setGroupNotification(undefined);
    }
  }, [
    notificationService,
    groupNotification?.msg,
    groupNotification?.type,
    setGroupNotification
  ]);

  const bulkFilterMenuItems = [
    bulkMembersFilters.allMembers,
    bulkMembersFilters.users,
    bulkMembersFilters.computers
  ];

  const handleBackClick = () => {
    history.push('app.settings.groups');
  };

  const handleNameChangeSuccess = () => {
    setGroupNotification({ msg: 'Success: Name updated.', type: 'success' });
  };

  const handleDeleteGroupMembers = async (selectedMembers) => {
    await deleteGroupMembers(selectedMembers);
    getGroups();
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" sx={groupClasses.groupHeader}>
        <Button onClick={handleBackClick}>
          <KeyboardBackspaceIcon />
        </Button>
        {!isGroupLoading && (
          <GroupNameHeader
            group={group}
            readonly={readonly}
            onSubmit={updateName}
            onSuccess={handleNameChangeSuccess}
          />
        )}
      </Box>
      {isGroupLoading ? (
        <CircularProgress color="primary" sx={groupClasses.loader} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {!readonly ? (
                <AddGroupMembersButton allGroupMembers={allGroupMembers} />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={6}>
              <MemberSearchBar filter={filter} setFilter={setFilter} />
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* EMPTY */}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12} sx={groupClasses.deleteGroupWrapper}>
                  <Grid container>
                    <Grid item xs={4}>
                      {!readonly ? (
                        <GroupBulkRemoveMemberButton
                          disabled={isMembersLoading}
                          group={group}
                          members={selectedActivTrakGroups(groupMembers)}
                          onDelete={handleDeleteGroupMembers}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      {!readonly ? (
                        <GroupMembersSettings
                          group={group}
                          onDelete={deleteGroups}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box>
                <GroupDetails group={group} />
              </Box>
              <Box paddingTop="1em">
                <GroupClassification activities={groupActivities} />
              </Box>
              <Box paddingTop="1em">
                <GroupAlarms groupId={group?.id} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box height="65vh">
                <CustomGrid
                  height={'58vh'}
                  isVirtualized={true}
                  isLoading={isMembersLoading}
                  data={groupMembers}
                  columns={columns}
                  uniqueRowProp="uniqueId"
                  initialSortField="name"
                  initialSortDirection={SortDirection.Descending}
                  hasBulkSelection
                  bulkSelectFilterOptions={bulkFilterMenuItems}
                  onSelectAllClick={setSelectedGroupMembers}
                  onSortOrder={setSortedGroupMembers}
                  rowDisableCondition={() => readonly}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export const GroupComponent = () => (
  <ReactivTrakComponentWrapper>
    <GroupsProvider>
      <GroupProvider>
        <GroupClassificationsProvider>
          <AlarmsProvider>
            <GroupView />
          </AlarmsProvider>
        </GroupClassificationsProvider>
      </GroupProvider>
    </GroupsProvider>
  </ReactivTrakComponentWrapper>
);
