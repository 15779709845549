import {
  mapToAgentHealth,
  COMPUTERS_INITIAL_VIEW_STATE,
  fetchAllComputers,
  deleteComputersAdmin,
  deleteComputersFeapi,
  uninstallComputersAdmin,
  uninstallComputersFeapi
} from '../utils';
import {
  IComputer,
  IFetchAllComputers,
  IAllAgentHealth,
  IComputerAgentState,
  IComputerAgentHealth
} from '../models';
import { fetchData } from '../../common/helpers/apis';
import { useAuthorization } from '../../common/services/Authorization';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  defaultComparer,
  sorter,
  periodDelimitedNumberComparer
} from '../../common/utils/sorter/sorter';
import { filterDataBySpecificParamsAndQuery } from '../../common/utils/filterByQuery';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import authHelper from '../../common/helpers/authorization';
import { Role, RoleAccessKeys, SortDirection } from '../../common/enums';
import { getGlobalStatus } from '../utils/health.utils';
import { computerTextFields } from '../enums/AdvancedSearch';
import { useNotifications } from '../../common/services/Notifications';
import { getAccountSettings } from '../../common/stores/accountSettingsStore/accountSettingsStore';

export const useComputerAgentState = (): IComputerAgentState => {
  const { delayedDelete } = getAccountSettings();
  const notificationService = useNotifications();
  const authorization = useAuthorization();

  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);
  const { screenshotsAllowed = false } = getPrivacySettings();

  const hasAlarmsScreenshotsReportsRoleAccess = authHelper.hasRoleAccess(
    RoleAccessKeys.AlarmsScreenshotsReports
  );
  const userIsSuperAdminOrSupportBasic = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.SupportBasic
  ]);

  const [computers, setComputers] = useState<IComputer[]>(
    COMPUTERS_INITIAL_VIEW_STATE.reportingComputers
  );
  const [selectedComputers, setSelectedComputers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.searchTerm
  );
  const [viewIsLoading, setViewIsLoading] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.viewIsLoading
  );
  const [healthIsLoading, setHealthIsLoading] = useState(
    COMPUTERS_INITIAL_VIEW_STATE.healthIsLoading
  );
  const [filterButtonSelection, setFilterButtonSelection] = useState<number>(
    COMPUTERS_INITIAL_VIEW_STATE.filterButtonSelection
  );
  const [gridOrderBy, setGridOrderBy] = useState<string>();
  const [gridOrderDirection, setGridOrderDirection] = useState<string>();
  const [tabInputData, setTabInputData] = useState([]);

  const [openAgentHealthModal, setOpenAgentHealthModal] =
    useState<boolean>(false);

  const [
    openUninstallComputersConfirmationModal,
    setOpenUninstallComputersConfirmationModal
  ] = useState<boolean>(false);

  const [
    openDeleteComputersConfirmationModal,
    setOpenDeleteComputersConfirmationModal
  ] = useState<boolean>(false);

  const { dropDownButtonOptions: dropDownFilterButtonOptions } =
    COMPUTERS_INITIAL_VIEW_STATE;

  const uninstallComputerRef = useRef<IComputer>();
  const agentHealthComputerRef = useRef<IComputer>();
  const deleteComputerRef = useRef<IComputer>();

  const resetSelectedComputers = () => {
    setSelectedComputers([]);
    setComputers(
      computers.map((item) => {
        return { ...item, selected: false };
      })
    );
  };

  const handleDeleteComputersConfirmed = async () => {
    let computerIds = [];
    if (deleteComputerRef.current?.id && deleteComputerRef.current.id >= 0) {
      //set by the triple dot menu -> delete option
      computerIds = [deleteComputerRef.current.id];
    } else {
      //bulk selection on main page
      computerIds = selectedComputers.map((computer) => {
        const { id } = computer;
        return id;
      });
    }

    try {
      await deleteComputersByIds(computerIds);
      resetSelectedComputers();
      setOpenDeleteComputersConfirmationModal(false);
      deleteComputerRef.current = null;
    } catch (response) {
      //what should happen here?
    }
  };

  const filteredComputers = useMemo(() => {
    let newFilteredComputers;
    if (tabInputData.length) {
      newFilteredComputers = computers;
      tabInputData.forEach((tabInput) => {
        const { field, fieldValue } = tabInput;
        newFilteredComputers = filterDataBySpecificParamsAndQuery(
          newFilteredComputers,
          [field],
          fieldValue
        );
      });
    } else {
      newFilteredComputers = filterDataBySpecificParamsAndQuery(
        computers,
        COMPUTERS_INITIAL_VIEW_STATE.searchBarFilterParams,
        searchTerm
      );
      if (filterButtonSelection != 0) {
        newFilteredComputers = filterDataBySpecificParamsAndQuery(
          newFilteredComputers,
          ['os'],
          dropDownFilterButtonOptions.map((o) => o.label)[
            filterButtonSelection - 1
          ]
        );
      }
    }
    const sortedData = sorter(
      newFilteredComputers,
      gridOrderDirection == 'asc'
        ? SortDirection.Ascending
        : SortDirection.Descending,
      gridOrderBy,
      gridOrderBy == 'agentVersion'
        ? periodDelimitedNumberComparer
        : defaultComparer
    );
    return sortedData;
  }, [
    computers,
    searchTerm,
    tabInputData,
    filterButtonSelection,
    dropDownFilterButtonOptions,
    gridOrderDirection,
    gridOrderBy
  ]);

  const onStatusClick = async (dataItem, e) => {
    const event = e || window.event;
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    agentHealthComputerRef.current = dataItem;
    setOpenAgentHealthModal(true);
  };

  function appendComputerData(
    apiComputers: IComputer[],
    healthStatuses: IComputerAgentHealth[]
  ) {
    // Append computer data with health status
    if (healthStatuses) {
      healthStatuses.forEach(function (healthStatus) {
        const nameMatches = apiComputers.filter(
          (computer) => computer.computer === healthStatus.name
        );
        if (nameMatches) {
          nameMatches.forEach(function (computer) {
            computer.healthStatus = healthStatus;
            computer.globalStatus = getGlobalStatus(computer.healthStatus);
          });
        }
      });
    }
  }

  const retrieveAgentHealth = async (apiComputers) => {
    setViewIsLoading(false);
    try {
      setComputers(apiComputers);
      setHealthIsLoading(true);
      const response = await fetchData<IAllAgentHealth>({
        path: `/api/agent/health`
      });
      const computers = mapToAgentHealth(
        userIsSuperAdminOrSupportBasic,
        screenshotsAllowed,
        response?.computers
      );
      appendComputerData(apiComputers, computers);
    } finally {
      setComputers(apiComputers);
      setHealthIsLoading(false);
      setViewIsLoading(false);
    }
  };

  const refreshComputers = useCallback(() => {
    setViewIsLoading(true);
    const userIsAdminOrSupportBasic = authorization.hasAnyRole([
      Role.SuperAdmin,
      Role.SupportBasic,
      Role.Admin
    ]);
    fetchAllComputers(softDeletesEnabled)
      .then((response: IFetchAllComputers) => {
        if (userIsAdminOrSupportBasic) {
          retrieveAgentHealth(response.computers);
        } else {
          setComputers(response.computers);
          setViewIsLoading(false);
        }
      })
      .catch(() => {
        //what should happen here?
      });
    // this file requires too much refactoring to add the additional dependencies needed here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteComputersByIds = useCallback(
    async (computerIds: number[]) => {
      try {
        if (softDeletesEnabled) {
          await deleteComputersAdmin(computerIds);
        } else {
          await deleteComputersFeapi(computerIds);
        }

        notificationService.success(
          `Success! ${computerIds.length} computers deleted.`
        );

        if (!softDeletesEnabled && !delayedDelete) {
          const newComputers = computers.filter(
            (computer) => !computerIds.includes(computer.id)
          );
          setComputers(newComputers);
          refreshComputers();
        } else {
          const copiedComputers = [...computers];

          copiedComputers.forEach((computer) => {
            if (computerIds.includes(computer.id)) {
              //delayed delete just disables the row
              computer.scheduledActionId = 1;
            }
          });
          setComputers(copiedComputers);
          refreshComputers();
        }
      } catch (response) {
        notificationService.error(response.data.error);
      }
    },
    [
      softDeletesEnabled,
      notificationService,
      delayedDelete,
      computers,
      refreshComputers
    ]
  );

  const onChangeComputerOption = async (dataItem: any, option?: any) => {
    const { label } = option;

    if (label === 'Delete') {
      deleteComputerRef.current = dataItem;
      setOpenDeleteComputersConfirmationModal(true);
    } else if (label === 'Uninstall Agent') {
      uninstallComputerRef.current = dataItem;
      setOpenUninstallComputersConfirmationModal(true);
    }
  };

  const onSubmitAdvancedFilter = useCallback(
    (formData) => {
      const tabBarData = [];
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          const computerField = computerTextFields.find(
            (gridColumn) => gridColumn.name == key
          );
          tabBarData.push({
            field: key,
            fieldName: computerField?.label,
            fieldValue: formData[key]
          });
        }
      });
      setTabInputData(tabBarData);
    },
    [setTabInputData]
  );

  const uninstallComputersByIds = async (computersItems: IComputer[]) => {
    try {
      if (softDeletesEnabled) {
        await uninstallComputersAdmin(computersItems);
      } else {
        await uninstallComputersFeapi(computersItems);
      }
      notificationService.success(
        `Success! ${computersItems.length} computers uninstalled.`
      );

      const computerIds = computersItems.map((computer) => computer.id);
      if (!softDeletesEnabled && !delayedDelete) {
        const newComputers = computers.filter(
          (computer) => !computerIds.includes(computer.id)
        );
        setComputers(newComputers);
        refreshComputers();
      } else {
        const copiedComputers = [...computers];
        copiedComputers.forEach((computer) => {
          if (computerIds.includes(computer.id)) {
            //delayed delete just disables the row
            computer.scheduledActionId = 1;
          }
        });
        setComputers(copiedComputers);
      }
    } catch (response) {
      notificationService.error(response.data.error);
    }
  };

  return {
    setGridOrderBy,
    setGridOrderDirection,
    setSearchTerm,
    setFilterButtonSelection,
    setSelectedComputers,
    setComputers,
    computers,
    selectedComputers,
    refreshComputers,
    deleteComputersByIds,
    uninstallComputersByIds,
    viewIsLoading,
    filteredComputers,
    tabInputData,
    searchTerm,
    openAgentHealthModal,
    setOpenAgentHealthModal,
    setOpenDeleteComputersConfirmationModal,
    openDeleteComputersConfirmationModal,
    setTabInputData,
    onSubmitAdvancedFilter,
    handleDeleteComputersConfirmed,
    filterButtonSelection,
    dropDownFilterButtonOptions,
    uninstallComputerRef,
    agentHealthComputerRef,
    openUninstallComputersConfirmationModal,
    setOpenUninstallComputersConfirmationModal,
    screenshotsAllowed,
    hasAlarmsScreenshotsReportsRoleAccess,
    softDeletesEnabled,
    onStatusClick,
    onChangeComputerOption,
    healthIsLoading,
    deleteComputerRef
  };
};
