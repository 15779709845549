import moment from 'moment';
import { IReportFilters } from '../models/IReportFilters';

type GenerateParametersOptions = {
  showProductivity: boolean;
  period: string;
  rangeOverride: string;
  modeOverride: string;
  shiftStart: string;
  shiftEnd: string;
  page?: number;
  pageSize?: number;
};

type GenerateParametersBody = {
  from: string;
  to: string;
  userId: string | number;
  userType: string;
  userMode: string;
  productivity?: string;
  period?: string;
  range?: string;
  shiftStart?: string;
  shiftEnd?: string;
  page?: number;
  pageSize?: number;
};

export const generateBody = (
  reportFilters: Partial<IReportFilters>,
  options: Partial<GenerateParametersOptions>
): GenerateParametersBody => {
  const {
    showProductivity,
    period,
    rangeOverride,
    modeOverride,
    shiftStart,
    shiftEnd,
    page,
    pageSize
  } = options;
  const { users, dates, productivity } = reportFilters;
  const { fromDate, toDate } = dates.getDates();

  const user =
    users?.length > 0 ? users[0] : { userId: '', userType: '', filterMode: '' };
  const momentFromDate = moment(fromDate);
  const momentToDate = moment(toDate);

  const body: GenerateParametersBody = {
    from: momentFromDate.format('YYYY-MM-DD'),
    to: momentToDate.format('YYYY-MM-DD'),
    userId: user.userId,
    userType: user.userType,
    userMode: modeOverride || user.filterMode || 'users'
  };

  if (showProductivity) {
    body['productivity'] = productivity;
  }

  if (period) {
    body['period'] = period;
  }

  if (dates.range || rangeOverride) {
    body['range'] = rangeOverride || dates.range;
  }

  if (shiftStart) {
    body['shiftStart'] = shiftStart;
  }

  if (shiftEnd) {
    body['shiftEnd'] = shiftEnd;
  }

  if (page) {
    body['page'] = page;
  }

  if (pageSize) {
    body['pageSize'] = pageSize;
  }

  return body;
};

export const generateParameters = (
  reportFilters: Partial<IReportFilters>,
  options: Partial<GenerateParametersOptions>
): string => {
  const {
    from,
    to,
    userId,
    userType,
    userMode,
    productivity,
    period,
    range,
    shiftStart,
    shiftEnd,
    page,
    pageSize
  } = generateBody(reportFilters, options);
  let queryString = `from=${encodeURIComponent(from)}&to=${encodeURIComponent(
    to
  )}&userId=${userId}&userType=${userType}&mode=${userMode}`;

  //TODO: Move these into the specific reports that need them.
  if (productivity) {
    queryString += `&productivity=${productivity}`;
  }

  if (period) {
    queryString += `&period=${period}`;
  }

  if (range) {
    queryString += `&range=${range}`;
  }

  if (shiftStart) {
    queryString += `&shiftStart=${shiftStart}`;
  }

  if (shiftEnd) {
    queryString += `&shiftEnd=${shiftEnd}`;
  }

  if (page) {
    queryString += `&page=${page}`;
  }

  if (pageSize) {
    queryString += `&pageSize=${pageSize}`;
  }

  return queryString;
};
