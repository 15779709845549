'use strict';

angular.module('core').service('customUibModal', CustomUibModal);

CustomUibModal.$inject = ['$window', '$uibModal'];

function CustomUibModal($window, $uibModal) {
    var nextIndex = 0;
    this.open = function (dropdownScope, element, appendTo) {
        var topScroll =
            (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
        var modal = $uibModal.open(dropdownScope, element, appendTo);
        modal.index = nextIndex++;

        modal.opened.then(function () {
            $('.page-content-wrapper').css('top', -topScroll + 'px');
            $('div.header').css('top', -topScroll + 'px');
        });

        modal.closed.then(function () {
            $('.page-content-wrapper').css('top', '');
            $('div.header').css('top', '');
            $window.scrollTo(0, topScroll);
        });

        // Hack to bypass modal warning
        // refer to: https://github.com/angular-ui/bootstrap/issues/6501
        var oldDismiss = modal.dismiss;
        modal.dismiss = function () {
            oldDismiss('dismiss_modal');
        };

        // default modal handler
        modal.result.catch(angular.noop);

        return modal;
    };
}
