import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import {
  DialogActionsStyled,
  GetStartedTypogStyled
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import { useCategoriesState } from '../../hooks/useCategoriesState';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  getStarted: () => void;
  isAdminOrConfig: boolean;
  onBulkClick: () => void;
};

export const GetStartedDialog = (props: ComponentProps) => {
  const { open, onClose, getStarted, isAdminOrConfig, onBulkClick } = props;
  const { fetchRolesCategories } = useCategoriesState();

  useEffect(() => {
    if (open) {
      fetchRolesCategories();
    }
  }, [fetchRolesCategories, open]);

  const close = () => {
    onClose();
  };

  const title = 'Welcome to Activity Alignment!';
  const description = `Activity Alignment lets you measure the time teams spend on
              activities critical to their role.`;

  return open && isAdminOrConfig ? (
    <Dialog open={open} onClose={close}>
      <Box width={590} maxHeight={340}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box>
            <GetStartedTypogStyled>{description}</GetStartedTypogStyled>
            <GetStartedTypogStyled>
              Start by selecting Core Categories for a single team or configure
              multiple teams in bulk.{' '}
            </GetStartedTypogStyled>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={getStarted}
                disabled={false}
                color={'primary'}
                variant="contained"
              >
                Single team
              </Button>
              <Typography sx={{ margin: '0 15px' }}>or</Typography>
              <Button
                onClick={onBulkClick}
                disabled={false}
                color={'primary'}
                variant="contained"
              >
                Multiple teams
              </Button>
            </Box>{' '}
          </Box>
        </DialogContent>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: '0 12px 18px 24px' }}
        >
          <Typography>
            Read our{' '}
            <Link
              href="https://support.activtrak.com/hc/en-us/articles/31463500125979/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Configuration Guide
            </Link>{' '}
            for best practices.
          </Typography>
          <Button onClick={close} autoFocus>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  ) : (
    <Dialog open={open} onClose={close}>
      <Box width={590} maxHeight={320}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box>
            <GetStartedTypogStyled>{description}</GetStartedTypogStyled>
            <GetStartedTypogStyled>
              Start by selecting Core Categories for a team or read our{' '}
              <Link
                href="https://support.activtrak.com/hc/en-us/articles/31463500125979/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Configuration Guide
              </Link>{' '}
              for best practices.
            </GetStartedTypogStyled>
          </Box>
        </DialogContent>
        <DialogActionsStyled>
          <DialogActionsBox
            close={close}
            onClick={getStarted}
            disabled={false}
            confirmText={'Get started'}
          />
        </DialogActionsStyled>
      </Box>
    </Dialog>
  );
};
