import { Role, RoleAccessKeys } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { refreshInsightsSettings } from '../../../../../stores/insightsSettingsStore/insightsSettingsStore';
import { ShiftSchedulingViewComponent } from '../../../../../../scheduling/views/ShiftSchedulingViewComponent';

export const appSettingsShiftScheduling: IRoute = {
  name: 'app.settings.shiftscheduling',
  stateDefinition: {
    url: '/settings/shiftscheduling',

    // React based report - bound via hybrid router
    component: ShiftSchedulingViewComponent,
    resolve: {
      loadInsightsSettingsStore: [async () => await refreshInsightsSettings()]
    },
    data: {
      pageTitle: 'Shift Scheduling'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportBasic],
    bundleFlags: [BundleFlag.ShiftSchedulingConfiguration]
  }
};

export default appSettingsShiftScheduling;
