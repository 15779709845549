import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { useCallback, useEffect, useState } from 'react';
import { UserDetailsHeadings } from '../../account-configuration/constants/users.consts';
import Box from '@mui/material/Box';
import { mediumFontWeight } from '../../common/constants';
import TextField from '@mui/material/TextField';
import { useNewUserState } from '../hooks/useNewUserState';
import Button from '@mui/material/Button';
import { AgentSearchModal } from './AgentSearchModal';
import { useSelectedAgentColumns } from '../utils/useSelectedAgentColumns';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import {
  userHasMinimumRequiredFields,
  validateEmailUpnArray
} from '../utils/DetailEmailEmployeeIdUtils';
import _ from 'lodash';
import { IUserIdentity } from '../models/IUserIdentity';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {
  ENTER_VALID_EMAIL,
  ENTER_VALID_UPN
} from '../constants/errorMessages.constants';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { IAgentSearchState } from '../models/IAgentSearchState';
import { USER_IDENTITY_DEFAULT } from '../constants/defaultObjects.constants';

type AddNewUserModalProps = {
  openAddNewUserAgentModal: boolean;
  onClose: () => void;
  commonState: IIdentityCommonState;
  agentSearchState: IAgentSearchState;
};

export const AddNewUserModal = (props: AddNewUserModalProps) => {
  const { openAddNewUserAgentModal, commonState, onClose, agentSearchState } =
    props;

  const addNewUserState = useNewUserState();

  const agentGridColumns = useSelectedAgentColumns();

  const { agentsSelected, isLoadingAgents, setOpenAssignAgentModal } =
    agentSearchState;

  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);

  const {
    newUser,
    handleDisplayNameChange,
    handleEmployeeIdChange,
    handleEmailChange,
    updateEmailUpnValidations,
    handleUserPrincipalNameChange,
    setNewUser,
    onTrackingFlip
  } = addNewUserState;

  const handleDialogClick = useCallback((event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    event.stopPropagation();
  }, []);

  const onAssignAgent = () => {
    agentSearchState.initializeAgents();
    setOpenAssignAgentModal(true);
  };

  useEffect(() => {
    const hasRequiredFields = userHasMinimumRequiredFields(newUser);
    setSaveButtonEnabled(hasRequiredFields);
  }, [newUser]);

  const addNewAgentAndCloseModal = () => {
    agentSearchState.setOpenAssignAgentModal(false);
  };

  const resetNewUser = () => {
    setNewUser(_.cloneDeep(USER_IDENTITY_DEFAULT));
  };

  const onCancelChanges = () => {
    resetNewUser();
    onClose();
  };

  const validateChanges = () => {
    const passedValidation =
      validateEmailUpnArray(newUser?.emails, ENTER_VALID_EMAIL) &&
      validateEmailUpnArray(newUser?.upns, ENTER_VALID_UPN);
    return passedValidation;
  };

  const saveNewUser = async () => {
    const createDto = addNewUserState.getSaveDto(agentsSelected);
    const [tempIdentity, tempError] = await commonState.onCreateNewUser(
      createDto,
      newUser,
      agentsSelected
    );
    if (tempError) {
      setTempIdentityAndClose(tempIdentity, tempError);
    } else {
      resetNewUser();
      onClose();
    }
  };

  const setTempIdentityAndClose = (identity: IUserIdentity, error: any) => {
    if (identity) {
      setNewUser({ ...identity });
    }
    if (!error) {
      onClose();
    }
  };

  const onSaveChanges = async () => {
    if (validateChanges()) {
      await saveNewUser();
    } else {
      setNewUser({ ...newUser });
    }
  };

  return (
    <Dialog
      open={openAddNewUserAgentModal}
      onClose={handleDialogClick}
      maxWidth="xl"
      fullWidth={false}
    >
      <DialogTitle>{UserDetailsHeadings.Add}</DialogTitle>
      <DialogContent id="new-agent-dialog-content" sx={{ pb: 1 }}>
        <Box sx={{ minWidth: 750, minHeight: 405 }}>
          <Box
            sx={{
              display: 'flex',
              pb: 1,
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <RadioGroup row>
              <FormControlLabel
                value={newUser?.tracked}
                control={
                  <Radio
                    size="small"
                    color="primary"
                    onChange={() => onTrackingFlip()}
                    checked={newUser?.tracked ?? false}
                  />
                }
                label="Tracked"
              />

              <FormControlLabel
                value={!newUser?.tracked}
                control={
                  <Radio
                    size="small"
                    color="primary"
                    checked={!(newUser?.tracked ?? false)}
                    onChange={() => onTrackingFlip()}
                  />
                }
                label="Untracked"
                sx={{ pl: 2 }}
              />
            </RadioGroup>
          </Box>
          <Box>
            <Box sx={{ pb: 3, fontWeight: mediumFontWeight }}>
              Enter the available identifiers for the user
            </Box>
            <TextField
              name="displayName"
              label="Display name"
              value={newUser?.displayName?.value}
              onChange={(e) => handleDisplayNameChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              helperText={
                newUser?.displayName?.failedExplanation &&
                newUser?.displayName?.failedExplanation !== ''
                  ? newUser?.displayName?.failedExplanation
                  : 'Easily identity your reports by adding a unique display name.'
              }
              error={newUser?.displayName?.validationFailed}
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <span style={{ fontWeight: mediumFontWeight }}>
              At least one ID must be provided.
            </span>
            <span>
              {' '}
              IDs are unique. One default ID will be displayed in reports and
              exports, using this precedence: UPN &gt; Email &gt; Employee Id.
            </span>
          </Box>
          <Box sx={{ pt: 3 }}>
            <TextField
              name="userPrincipalName"
              label="User principal name"
              value={newUser?.upns?.length > 0 ? newUser?.upns[0].value : ''}
              onChange={(e) => handleUserPrincipalNameChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              error={
                newUser?.upns?.length > 0
                  ? newUser?.upns[0].validationFailed
                  : false
              }
              helperText={
                newUser?.upns?.length > 0
                  ? newUser?.upns[0].failedExplanation
                  : ''
              }
              onBlur={updateEmailUpnValidations}
            />
          </Box>

          <Box sx={{ pt: 1 }}>
            <TextField
              name="email"
              label="Email"
              value={newUser.emails?.length > 0 ? newUser.emails[0].value : ''}
              onChange={(e) => handleEmailChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              error={
                newUser?.emails?.length > 0
                  ? newUser?.emails[0].validationFailed
                  : false
              }
              helperText={
                newUser?.emails?.length > 0
                  ? newUser?.emails[0].failedExplanation
                  : ''
              }
              onBlur={updateEmailUpnValidations}
            />
          </Box>
          <Box sx={{ pt: 1 }}>
            <TextField
              name="employeeId"
              label="Employee Id"
              value={
                newUser?.employeeIds?.length > 0
                  ? newUser?.employeeIds[0].value
                  : ''
              }
              onChange={(e) => handleEmployeeIdChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              error={
                newUser?.employeeIds?.length > 0
                  ? newUser?.employeeIds[0].validationFailed
                  : false
              }
              helperText={
                newUser?.employeeIds?.length > 0
                  ? newUser?.employeeIds[0].failedExplanation
                  : ''
              }
            />
          </Box>
          <Box sx={{ pt: 1 }}>
            {agentsSelected && agentsSelected.length ? (
              <span>Agent assigned to user</span>
            ) : (
              <span>Assign an agent to this user</span>
            )}
          </Box>
          <Box sx={{ pt: 1 }}>
            {(agentsSelected == null || agentsSelected.length === 0) && (
              <Button
                onClick={onAssignAgent}
                disabled={!saveButtonEnabled}
                color="secondary"
                startIcon={<AddIcon />}
              >
                Assign agent
              </Button>
            )}
          </Box>
          {agentsSelected && agentsSelected.length > 0 && (
            <Box sx={{ pt: 1, pb: 1 }}>
              <CustomGrid
                data={agentsSelected}
                columns={agentGridColumns}
                isLoading={isLoadingAgents}
              />
            </Box>
          )}
          <AgentSearchModal
            agentSearchState={agentSearchState}
            onClose={addNewAgentAndCloseModal}
            commonState={commonState}
            //used to add an agent to a brand new user
            userDescription="the new user"
            readOnlyAgentIds={[]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelChanges}>Cancel</Button>
        <Button
          onClick={onSaveChanges}
          variant="contained"
          disabled={!saveButtonEnabled}
        >
          Add user
        </Button>
      </DialogActions>
    </Dialog>
  );
};
