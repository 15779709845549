import angular from 'angular';
import { UI_ROUTER_REACT_HYBRID } from '../../_entry/router-hybrid';

angular.module('core', []);
angular
    .module('core')
    .constant('googleAppClientId', '385938211088-92f2cuj6p1frqdq2l978ah1j9q3q248a.apps.googleusercontent.com');

angular.module('core').config([
    '$httpProvider',
    ($httpProvider) => {
        $httpProvider.interceptors.push('authInterceptor');
    }
]);

/**
 * @ngdoc overview
 * @name atbuildApp
 * @description
 * # atbuildApp
 *
 * Main module of the application.
 */
angular.module('app', [
    'config',
    'ui.router',
    UI_ROUTER_REACT_HYBRID,
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'kendo.directives',
    'ui.bootstrap',
    'ui.select',
    'LocalStorageModule',
    'googlechart',
    'ngFileUpload',
    'ngFileSaver',
    'com.2fdevs.videogular',
    'com.2fdevs.videogular.plugins.controls',
    'com.2fdevs.videogular.plugins.overlayplay',
    'com.2fdevs.videogular.plugins.poster',
    'angular-loading-bar',
    'angular-bind-html-compile',
    'chromebook',
    'ngWebSocket',
    'core'
]);
