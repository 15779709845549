import { apiService } from '../../common/helpers';
import { IPerformanceOptimizationService } from '../models';
import { getDefaultConfig } from '../../common/config';
import { HttpResponse } from '../../common/models';

export const PerformanceOptimizationService =
  (): IPerformanceOptimizationService => {
    return {
      postLiveReportSettings: async (
        payload: boolean
      ): Promise<HttpResponse<any>> => {
        return apiService.post<HttpResponse<object>>(
          '/api/settings/live_report_settings',
          {
            ...getDefaultConfig(),
            data: payload
          }
        );
      }
    };
  };
