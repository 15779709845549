import React, { useEffect, useMemo, useState } from 'react';
import { useObservableState } from 'observable-hooks';
import { findIndex } from 'lodash';
import { ActivityType, Role } from '../../common/enums';
import { useClassification, useClassificationStores } from '../services';
import { IClassificationActivity } from '../../common/models/IClassificationActivity';
import { IActivityDetail } from '../models';
import { backToClassificationsButton } from '../constants';
import { Grid, Button } from '@mui/material';
import ActivityDetails from '../components/ActivityDetails';
import ActivityItems from '../components/ActivityItems';
import ActivityReports from '../components/ActivityReports/ActivityReports';
import ActivityNavigationFooter from '../components/ActivityNavigationFooter';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { classificationClasses } from '../styles/Classifications.styles';
import { useAuthorization } from '../../common/services/Authorization';
import {
  useUIRouterHistory,
  useUIRouterState
} from '../../common/hooks/useUIRouterHistory';
import { getAccountPlanStore } from '../../common/stores/accountPlanStore';
import { formatDateByTimezone } from '../../common/utils/datetime/datetimeFormatters';

export default function Activity() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);

  const { activityDetailService, categoryService } = useClassification();
  const { classificationStore } = useClassificationStores();

  const { activitiesState, getActivityById } = classificationStore;
  const { activities, isClassified } = activitiesState;

  const [activity, setActivity] = useState<IClassificationActivity>();
  const [activityDetail, setActivityDetail] = useState<IActivityDetail>();

  const history = useUIRouterHistory();
  const state = useUIRouterState<{ id: string; type: ActivityType }>();
  const { id, type } = state.params;

  const { planType: storedPlanType } = getAccountPlanStore();
  const planType = storedPlanType?.toLowerCase();

  const authorizationService = useAuthorization();
  const isSupportAdvanced = authorizationService.hasAnyRole([
    Role.SupportAdvanced
  ]);

  const categories = useObservableState(categoryService.getAll());

  // Get Activity By Id
  useEffect(() => {
    const getActivity = async () => {
      try {
        setActivity(null);
        if (id && type) {
          const activity = await getActivityById(parseInt(id), type);
          if (cancel) return;
          setActivity(activity);
        }
      } catch (error) {
        console.error('ActivTrak Error: Error retrieving Activity', error);
        setIsLoading(false);
      }
    };

    let cancel = false;
    getActivity();
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  // Get Details
  useEffect(() => {
    const getActivityDetails = async () => {
      try {
        setIsLoading(true);
        setActivityDetail(null);
        if (id && type) {
          const ad = await activityDetailService.getById(parseInt(id), type);
          if (cancel) return;
          setActivityDetail(ad);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(
          'ActivTrak Error: Error retrieving Activity Details',
          error
        );
        setIsLoading(false);
      }
    };

    let cancel = false;
    getActivityDetails();
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    if (id && type && activities?.length) {
      let currIndex = findIndex(
        activities,
        (a: IClassificationActivity) => parseInt(id) === a.id && type === a.type
      );
      setCurrentActivityIndex(++currIndex);
    }
  }, [id, type, activities]);

  const shouldShowGroupClassifications = useMemo(() => {
    // TODO: Convert to hasFeature('isPaidFunctionalityEnabled')
    //Handle potential nulls/undefined
    if (!planType) return false;
    //Handle actual check
    return !(
      planType === 'free' ||
      planType === 'freechoice' ||
      planType === 'freemium'
    );
  }, [planType]);

  const handleActivityBackClick = () => {
    const tab = isClassified ? 'classified' : 'pending';
    history.push('app.settings.classification.tab', { activeTab: tab });
  };

  return (
    <Grid container>
      <Grid container sx={{ marginBottom: '24px' }}>
        <Button
          onClick={handleActivityBackClick}
          sx={classificationClasses.navigationButton}
          startIcon={<KeyboardBackspaceIcon />}
          size="large"
        >
          {backToClassificationsButton}
        </Button>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <ActivityDetails
            activity={activity}
            dateAdded={
              activityDetail?.dateAdded &&
              formatDateByTimezone(activityDetail.dateAdded)
            }
            totalUsers={activityDetail?.totalUsers || 0}
            classificationType={activity?.statusDisplay}
          />
          <ActivityItems
            activity={activity}
            categories={categories}
            isDisabled={isSupportAdvanced}
            setActivity={setActivity}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <ActivityReports
            activityDetail={activityDetail}
            isActivityDetailLoading={isLoading}
            currentActivityIndex={currentActivityIndex}
            shouldShowGroupClassifications={shouldShowGroupClassifications}
            id={parseInt(id)}
            type={type}
            categories={categories}
            isDisabled={isSupportAdvanced}
          />
        </Grid>
        <ActivityNavigationFooter
          currentActivity={activity}
          categories={categories}
        />
      </Grid>
    </Grid>
  );
}
