import React from 'react';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import { durationFormat } from '../../../common/utils/datetime/time';
import { htmlEscape } from '../../../common/utils/parse/parse';
import authorization from '../../../common/helpers/authorization';
import {
  stopLightProductivityColors,
  secondaryFontSize,
  gray5
} from '../../../common/constants';
import Link from '../../../common/components/Link';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';

export const getAccessForFeature = (
  dataBundleFlag: BundleFlag,
  promoBundleFlag: BundleFlag,
  featureFlag: FeatureFlag
): boolean => {
  const hasDataBundleFlag = authorization.hasFeature(dataBundleFlag);
  const hasPromoBundleFlag = authorization.hasFeature(promoBundleFlag);
  const hasFeatureFlag = authorization.hasFeature(featureFlag);
  const canAccessUpgrade = authorization.hasRouteByName('app.account.upgrade');

  const hasColumnBundleFlag =
    hasDataBundleFlag || (hasPromoBundleFlag && canAccessUpgrade);

  return hasColumnBundleFlag && hasFeatureFlag;
};

export const generateListOfItemsLinked = (
  array: string[],
  type: string
): React.ReactNode => {
  if (!array || typeof array.forEach !== 'function') {
    return <></>;
  }

  const item = array[0];

  const escapedName = htmlEscape(item);
  const query = {};
  query[type] = escapedName;

  return (
    <Link to="app.reports.activitylog" params={query}>
      {item}
    </Link>
  );
};

export const generateLinkedItem = (
  item: string,
  type: string
): React.ReactNode => {
  if (!item) {
    return <></>;
  }

  const escapedName = htmlEscape(item);
  const query = {};
  query[type] = escapedName;

  return (
    <Link to="app.reports.activitylog" params={query}>
      {item}
    </Link>
  );
};

export const getProductivityColor = (productivityStatus) => {
  let color: string;
  switch (productivityStatus) {
    case 1:
      color = stopLightProductivityColors.productive;
      break;
    case -1:
      color = stopLightProductivityColors.unproductive;
      break;
    case -3:
      color = stopLightProductivityColors.undefinedPassive;
      break;
    case 2:
      color = stopLightProductivityColors.productivePassive;
      break;
    case -2:
      color = stopLightProductivityColors.unproductivePassive;
      break;
    case -4:
    case 9:
      color = stopLightProductivityColors.offlineMeetings;
      break;
    case 10:
      color = stopLightProductivityColors.pto;
      break;
    default:
      color = stopLightProductivityColors.undefined;
  }
  return color;
};

export const getProductivityBubble = (
  productivityStatus,
  fontSize = secondaryFontSize,
  hasBorder = false
) => {
  const color: string = getProductivityColor(productivityStatus);

  let styles: React.CSSProperties = { color, fontSize };
  if (hasBorder)
    styles = { ...styles, border: `1px solid ${gray5}`, borderRadius: '50%' };

  return <CircleIcon sx={styles} />;
};

export const getProductivityTimeTemplate = (duration = 0, productivity) => {
  const productivityBubble = getProductivityBubble(productivity);

  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        lineHeight: '22px'
      }}
    >
      <Typography component="span" sx={{ mr: 1 }}>
        {durationFormat(duration, false)}
      </Typography>{' '}
      {productivityBubble}
    </Typography>
  );
};

export const getTotalPercent = (
  value: number,
  total: number,
  fixed: number = 1
) => ((total > 0 ? value / total : 0) * 100).toFixed(fixed);
