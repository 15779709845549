import insightDashboard from './insightDashboard';
import { http } from '_app/http';
import { atHelperFunctions } from '../serviceFunctions/atHelperFunctions';
import authorizationService from '../../_reactivtrak/src/common/helpers/authorization';
import { FeatureFlag } from '../../_reactivtrak/src/common/enums/FeatureFlag';

const createDashboard = (apiUrl, params) => {
    const dashboard = new insightDashboard(params);
    if (params.pageId === 'personal_insights') {
        setDefaultUserFilter(dashboard, apiUrl, params);
    } else if (params.pageId === 'activity_alignment' && params.subPageId !== 'Configuration') {
        setCoreCategoryTeamFilters(dashboard, apiUrl, params);
    } else {
        setDefaultFilters(dashboard, apiUrl, params);
    }

    return dashboard;
};

const setCoreCategoryTeamFilters = (dashboard, apiUrl, params) => {
    getCoreCategories(apiUrl)
        .then((data) => {
            dashboard.setCoreCategoryTeamFilters(data, params.range);
        })
        .catch((e) => {
            console.error('ActivTrak Error: Error retrieving core categories - filters', e);
        })
        .finally(() => {
            dashboard.getEmbedUrl(apiUrl);
        });
};

const setDefaultFilters = (dashboard, apiUrl, params) => {
    const localStorageService = params.services.localStorageService;
    const firstGroupName = localStorageService.get(`${params.username}_insights-first-group-name`, 'sessionStorage');
    const range = params.range;

    if (firstGroupName) {
        dashboard.setDefaultFilters(firstGroupName, range);
        dashboard.getEmbedUrl(apiUrl);
    } else if (authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
        getFirstOfAllVisibleGroups(apiUrl)
            .then(function (groupName) {
                if (groupName) {
                    localStorageService.set(
                        `${params.username}_insights-first-group-name`,
                        groupName,
                        'sessionStorage'
                    );
                    let defaultFilters = { Team: groupName };
                    if (range) {
                        defaultFilters = { ...defaultFilters, 'Activity Date': range };
                    }
                    dashboard.setDefaultFilters(defaultFilters);
                }
            })
            .catch((e) => {
                console.error('ActivTrak Error: Error retrieving Insights groups', e);
            })
            .finally(function () {
                dashboard.getEmbedUrl(apiUrl);
            });
    } else {
        dashboard.getEmbedUrl(apiUrl);
    }
};

const setDefaultUserFilter = (dashboard, apiUrl, params) => {
    const localStorageService = params.services.localStorageService;
    const firstUserName = localStorageService.get(`${params.username}_insights-first-user-name`, 'sessionStorage');
    if (firstUserName) {
        dashboard.setDefaultUserFilter(firstUserName);
        dashboard.getEmbedUrl(apiUrl);
    } else if (authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
        getFirstOfAllVisibleUsers(apiUrl)
            .then(function (userName) {
                if (userName) {
                    localStorageService.set(`${params.username}_insights-first-user-name`, userName, 'sessionStorage');
                    dashboard.setDefaultUserFilter(userName);
                }
            })
            .catch((e) => {
                console.error('ActivTrak Error: Error retrieving Insights users', e);
            })
            .finally(function () {
                dashboard.getEmbedUrl(apiUrl);
            });
    } else {
        dashboard.getEmbedUrl(apiUrl);
    }
};

const getAccountSettings = (apiUrl, notificationService) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/accountsettings`)
            .then((results) => {
                if (!results || !results.data) {
                    notificationService.showNotification('Error retrieving account settings', 'danger');
                    throw 'Empty insights settings values';
                }

                resolve({
                    accountSettings: results.data
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving account settings', 'danger');
                console.error('ActivTrak Error: Error retrieving insights account info', e);
            });
    });
};

const getLastUpdateTimestamp = (apiUrl) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/accountsettings`)
            .then((results) => {
                let lastRefreshTime = results && results.data && results.data.lastRefreshTime;
                let timeZone = results && results.data && results.data.timeZone;
                let lastRefreshMinutes = results && results.data && results.data.lastRefreshMinutes;
                let hourText = '';
                if (!timeZone || !lastRefreshTime) {
                    throw 'Empty time values';
                }

                let formattedRefreshTime = atHelperFunctions.formatDate(lastRefreshTime, {
                    span: false,
                    compact: false,
                    onlyDate: false
                });

                if (lastRefreshMinutes) {
                    if (lastRefreshMinutes > 60) {
                        hourText = 'Insights data updated ' + ~~(lastRefreshMinutes / 60) + 'h ago';
                    } else {
                        hourText = 'Insights data updated ' + lastRefreshMinutes + 'm ago';
                    }
                } else {
                    hourText = 'Data History up to: ' + formattedRefreshTime + ' (' + timeZone + ')';
                }

                let timeTooltip =
                    'Last update: ' +
                    formattedRefreshTime +
                    ' (' +
                    timeZone +
                    '). Any changes to configuration settings, account access or goals<br/>made after this date will not be reflected until the next data load. Data updates once per day.';

                resolve({
                    lastRefreshTime: formattedRefreshTime,
                    timeZone: timeZone,
                    timeText: hourText,
                    timeTooltip: timeTooltip
                });
            })
            .catch((e) => {
                console.error('ActivTrak Error: Error retrieving last update timestamp', e);
            });
    });
};

const getCoachingLastRefreshInfo = (apiUrl) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/coaching/lastrefreshinfo`)
            .then((results) => {
                let lastRefreshTime = results && results.data && results.data.lastRefreshTime;
                let timeZone = results && results.data && results.data.timeZone;
                let lastRefreshDays = results && results.data && results.data.lastRefreshDays;
                let dayText = '';
                if (!timeZone || !lastRefreshTime) {
                    throw 'Empty time values';
                }

                let formattedRefreshTime = atHelperFunctions.formatDate(lastRefreshTime, {
                    span: false,
                    compact: false,
                    onlyDate: false
                });

                if (!isNaN(lastRefreshDays)) {
                    if (lastRefreshDays === 0) {
                        dayText = 'Coach data updated today';
                    } else {
                        dayText = `Coach data updated ${lastRefreshDays}d ago`;
                    }
                } else {
                    dayText = 'Coach data: ' + formattedRefreshTime + ' (' + timeZone + ')';
                }

                let timeTooltip = `Last update: ${formattedRefreshTime} (${timeZone}).<br/>Coach data is refreshed every Monday`;

                resolve({
                    lastRefreshTime: formattedRefreshTime,
                    timeZone: timeZone,
                    timeText: dayText,
                    timeTooltip: timeTooltip
                });
            })
            .catch((e) => {
                console.error('ActivTrak Error: Error retrieving coaching last refresh info', e);
            });
    });
};

const setContextSwitchSettings = (apiUrl, params, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/contextswitch`, { data: params })
            .then((results) => {
                notificationService.showNotification('Success! Settings Saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving settings', 'danger');
                console.error('ActivTrak Error: Error saving context switch settings', e);
                reject(e);
            });
    });
};

const getGroups = (apiUrl) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/groups`)
            .then(function (results) {
                resolve(results.data);
            })
            .catch(function (e) {
                console.error('ActivTrak Error: Error fetching groups', e);
            });
    });
};

const getFirstOfAllVisibleGroups = (apiUrl) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/groups`)
            .then((results) => {
                let firstGroup = null;
                const groups = results.data; //already sorted by name by the api
                if (groups && groups.length > 0) {
                    //Prioritize activTrak groups (type: 1) over active directory groups (type: 0)
                    const atGroups = groups.filter((g) => g.type === 1);
                    if (atGroups.length > 0) {
                        firstGroup = atGroups[0].name;
                    } else {
                        //active directory groups
                        firstGroup = groups[0].name;
                    }
                }
                resolve(firstGroup);
            })
            .catch((e) => {
                console.error('ActivTrak Error: Error fetching all visible groups', e);
            });
    });
};

const getFirstOfAllVisibleUsers = (apiUrl) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/firstvisibleuser`)
            .then((results) => {
                let firstUser = null;
                const user = results.data; //returns 1 user object
                if (user) {
                    firstUser = user.userName;
                }
                resolve(firstUser);
            })
            .catch((e) => {
                console.error('ActivTrak Error: Error fetching all visible users', e);
            });
    });
};

const setInsightsSubscribeOthers = (apiUrl, param, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/subscribeothers`, { data: param })
            .then((results) => {
                notificationService.showNotification(
                    'Success! Allow Users to Share Personal Insights Saved.',
                    'success'
                );
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error Saving Allow Users to Share Personal Insights', 'danger');
                console.error('ActivTrak Error: Error setting Insights Allow Subscribe Others', e);
                reject(e);
            });
    });
};

const setExcludeNonBizActivity = (apiUrl, param, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/excludenonbizactivity`, { data: param })
            .then((results) => {
                notificationService.showNotification(
                    'Success! Exclude Non Business Activity Setting Saved.',
                    'success'
                );
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error Saving Exclude Non Business Activity Setting', 'danger');
                console.error('ActivTrak Error: Error setting Exclude Non Business Activity Setting', e);
                reject(e);
            });
    });
};

const setHideUserActivityData = (apiUrl, param, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/hideuseractivitydata`, { data: param })
            .then((results) => {
                notificationService.showNotification('Success! Hide User Activity Data Setting Saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving hide user activity data setting', 'danger');
                console.error('ActivTrak Error: Error setting hide user activity data', e);
                reject(e);
            });
    });
};

const getInsightsSchedules = (apiUrl, dashboardId, notificationService) => {
    return new Promise((resolve, reject) => {
        http.get(`${apiUrl}/api/insights/schedules/${dashboardId}`)
            .then((results) => {
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving insights subscriptions', 'danger');
                console.error('ActivTrak Error: Error retrieving insights subscriptions', e);
                reject(e);
            });
    });
};

const getInsightsScheduleById = (apiUrl, scheduleId, notificationService) => {
    return new Promise((resolve, reject) => {
        http.get(`${apiUrl}/api/insights/schedule/${scheduleId}`)
            .then((result) => {
                resolve(result.data);
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving insights subscription', 'danger');
                console.error(`ActivTrak Error: Error retrieving insights subscription: ${scheduleId}`, e);
                reject(e);
            });
    });
};

const saveInsightsSchedule = (apiUrl, scheduleDefinition, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/schedules`, { data: scheduleDefinition })
            .then((results) => {
                notificationService.showNotification('Success! Subscription Saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving subscription', 'danger');
                console.error('ActivTrak Error: Error saving Insights subscription', e);
                reject(e);
            });
    });
};

const deleteInsightsSchedules = (apiUrl, schedules, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/schedules/delete`, { data: schedules })
            .then((results) => {
                notificationService.showNotification('Success! Subscriptions Deleted.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving subscription', 'danger');
                console.error('ActivTrak Error: Error deleting Insights settings', e);
                reject(e);
            });
    });
};

const sendNowInsightsSchedule = (apiUrl, scheduleDefinition, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/schedules/sendNow`, { data: scheduleDefinition })
            .then((results) => {
                notificationService.showNotification('Success! Subscription sent.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error sending subscription', 'danger');
                console.error('ActivTrak Error: Error sending subscription', e);
                reject(e);
            });
    });
};

const getMetricValues = (apiUrl, schedules, notificationService) => {
    return new Promise((resolve, reject) => {
        http.get(`${apiUrl}/api/insights/metrics`, { data: schedules })
            .then((results) => {
                let metricValues = results && results.data;
                resolve({
                    metricValues
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving metric values', 'danger');
                console.error('ActivTrak Error: Error retrieving Insights metrics', e);
                reject(e);
            });
    });
};

const getAllCategories = (apiUrl, notificationService) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/categories`)
            .then((results) => {
                resolve(results.data);
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving categories)', 'danger');
                console.error('ActivTrak Error: Error fetching all categories', e);
            });
    });
};

const toggleCollaborationNextRefresh = (apiUrl, insightsCategories, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/categories/setcollaboration`, { data: insightsCategories })
            .then((result) => {
                notificationService.showNotification(
                    'Success, list of Categories included in Collaboration Time Updated',
                    'success'
                );
                resolve({ result });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving category selection(s)', 'danger');
                console.error('ActivTrak Error: Error modifying category settings', e);
                reject(e);
            });
    });
};

const getShareableEmails = (apiUrl, notificationService) => {
    return new Promise((resolve, reject) => {
        http.get(`${apiUrl}/api/insights/schedules/emails`)
            .then((results) => {
                resolve(results.data);
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving sharable emails', 'danger');
                console.error('ActivTrak Error: Error retrieving sharable emails', e);
                reject(e);
            });
    });
};

const setActiveDayProductiveHrsThreshold = (apiUrl, params, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/activedayproductivehrsthreshold`, { data: params })
            .then((results) => {
                notificationService.showNotification('Success! active day productive hrs threshold saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification(
                    'Error saving active day productive hrs threshold settings',
                    'danger'
                );
                console.error('ActivTrak Error: Error saving active day productive hrs threshold settings', e);
                reject(e);
            });
    });
};

const setLocationThreshold = (apiUrl, params, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/locationthreshold`, { data: params })
            .then((results) => {
                notificationService.showNotification('Success! office/remote threshold saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving office/remote threshold settings', 'danger');
                console.error('ActivTrak Error: Error saving office/remote threshold settings', e);
                reject(e);
            });
    });
};

const getImpactEvents = (apiUrl, notificationService) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/impactevents`)
            .then((results) => {
                resolve(results.data);
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving impact events)', 'danger');
                console.error('ActivTrak Error: Error fetching impact events', e);
            });
    });
};

const saveImpactEvent = (apiUrl, impactEvent, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/impactevents`, { data: impactEvent })
            .then((result) => {
                notificationService.showNotification(
                    'Success! Segment Saved. Newly created or edited Segments will not be available for comparison ' +
                        'in the Impact Analysis Dashboard until the next data load (data updates once per day).',
                    'success'
                );
                resolve(result);
            })
            .catch((e) => {
                notificationService.showNotification('Error saving segment', 'danger');
                console.error('ActivTrak Error: Error saving segment', e);
                reject(e);
            });
    });
};

const deleteImpactEvents = (apiUrl, impactEvents, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/impactevents/removelist`, { data: impactEvents })
            .then((result) => {
                notificationService.showNotification('Success! Segment(s) Deleted.', 'success');
                resolve(result);
            })
            .catch((e) => {
                notificationService.showNotification('Error deleting segment(s)', 'danger');
                console.error('ActivTrak Error: Error deleting segment(s)', e);
                reject(e);
            });
    });
};

const IMPACT_ANALYSIS_AUTO_MODAL = 'insights_impact_analysis_auto_modal';

const userHasSeenImpactEventsAutoModal = (username, localStorageService) => {
    return localStorageService.get(`${username}-${IMPACT_ANALYSIS_AUTO_MODAL}`, 'sessionStorage');
};

const setUserHasSeenImpactEventsAutoModal = (username, localStorageService) => {
    localStorageService.set(`${username}-${IMPACT_ANALYSIS_AUTO_MODAL}`, 'true', 'sessionStorage');
};

const shouldAutoShowImpactEventsModal = (authorizationService, username, localStorageService) => {
    if (!authorizationService.hasRole([authorizationService.roles.admin, authorizationService.roles.configurator])) {
        return false;
    }

    return !userHasSeenImpactEventsAutoModal(username, localStorageService);
};

const getLocations = (apiUrl, notificationService) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/locations`)
            .then((results) => {
                resolve(results.data);
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving locations', 'danger');
                console.error('ActivTrak Error: Error fetching locations', e);
            });
    });
};

const upsertLocations = (apiUrl, locations, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/locations`, { data: locations })
            .then((result) => {
                notificationService.showNotification('Success! Location(s) saved', 'success');
                resolve(result);
            })
            .catch((e) => {
                notificationService.showNotification('Error saving location(s)', 'danger');
                console.error('ActivTrak Error: Error saving location(s)', e);
                reject(e);
            });
    });
};

const deleteLocation = (apiUrl, location, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/location/delete`, { data: location })
            .then((result) => {
                notificationService.showNotification('Success! Location Deleted.', 'success');
                resolve(result);
            })
            .catch((e) => {
                notificationService.showNotification('Error deleting location', 'danger');
                console.error('ActivTrak Error: Error deleting location', e);
                reject(e);
            });
    });
};

const getLocationExhaustive = (apiUrl, notificationService) => {
    return new Promise((resolve) => {
        http.get(`${apiUrl}/api/insights/location/exhaustive`)
            .then((results) => {
                resolve(results.data);
            })
            .catch((e) => {
                notificationService.showNotification('Error retrieving location exhaustive', 'danger');
                console.error('ActivTrak Error: Error fetching location exhaustive', e);
            });
    });
};

const upsertLocationExhaustive = (apiUrl, flag, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/location/exhaustive/${flag}`)
            .then((result) => {
                notificationService.showNotification('Success! Full list of IP Addresses for Office Saved.', 'success');
                resolve(result);
            })
            .catch((e) => {
                notificationService.showNotification('Error Saving Full list of IP Addresses for Office', 'danger');
                console.error('ActivTrak Error: Error Saving Full list of IP Addresses for Office', e);
                reject(e);
            });
    });
};

const setAccountLevelGoal = (apiUrl, params, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettingstarget`, { data: params })
            .then((results) => {
                resolve({
                    success: results.data
                });
                if (results.data) {
                    // api call returns boolean
                    notificationService.showNotification('Success! Account Goal Saved.', 'success');
                } else {
                    notificationService.showNotification('Error saving account goal', 'danger');
                    console.error('ActivTrak Error: Error saving account goal: no data returned');
                }
            })
            .catch((e) => {
                notificationService.showNotification('Error saving account goal', 'danger');
                console.error('ActivTrak Error: Error saving account goal:', e);
                reject(e);
            });
    });
};

const setMeetingMinAttendees = (apiUrl, params, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/meetingminattendees`, { data: params })
            .then((results) => {
                notificationService.showNotification('Success! Minimum number of attendees saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving meeting min attendees settings', 'danger');
                console.error('ActivTrak Error: Error saving meeting min attendees settings', e);
                reject(e);
            });
    });
};

const setMeetingMaxLength = (apiUrl, params, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/meetingmaxlength`, { data: params })
            .then((results) => {
                notificationService.showNotification('Success! Maximum Meeting Time saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving meeting max length settings', 'danger');
                console.error('ActivTrak Error: Error saving meeting max length settings', e);
                reject(e);
            });
    });
};

const setUtilizationThreshold = (apiUrl, param, notificationService) => {
    return new Promise((resolve, reject) => {
        http.post(`${apiUrl}/api/insights/accountsettings/utilizationthreshold`, { data: param })
            .then((results) => {
                notificationService.showNotification('Success! Utilization level threshold saved.', 'success');
                resolve({
                    results
                });
            })
            .catch((e) => {
                notificationService.showNotification('Error saving utilization level threshold setting', 'danger');
                console.error('ActivTrak Error: Error saving utilization level threshold setting', e);
                reject(e);
            });
    });
};

const getCoreCategories = (apiUrl) => {
    return new Promise((resolve, reject) => {
        http.get(`${apiUrl}/api/insights/categories/core`)
            .then((results) => {
                resolve(results.data);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export {
    createDashboard,
    setDefaultFilters,
    getLastUpdateTimestamp,
    getCoachingLastRefreshInfo,
    setContextSwitchSettings,
    getGroups,
    getFirstOfAllVisibleGroups,
    getFirstOfAllVisibleUsers,
    setInsightsSubscribeOthers,
    setExcludeNonBizActivity,
    setHideUserActivityData,
    getInsightsSchedules,
    getInsightsScheduleById,
    saveInsightsSchedule,
    deleteInsightsSchedules,
    getMetricValues,
    getAllCategories,
    toggleCollaborationNextRefresh,
    sendNowInsightsSchedule,
    getShareableEmails,
    setActiveDayProductiveHrsThreshold,
    setLocationThreshold,
    getImpactEvents,
    saveImpactEvent,
    deleteImpactEvents,
    userHasSeenImpactEventsAutoModal,
    setUserHasSeenImpactEventsAutoModal,
    shouldAutoShowImpactEventsModal,
    getLocations,
    upsertLocations,
    deleteLocation,
    getLocationExhaustive,
    upsertLocationExhaustive,
    setAccountLevelGoal,
    getAccountSettings,
    setMeetingMinAttendees,
    setMeetingMaxLength,
    setUtilizationThreshold,
    getCoreCategories
};
