import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { ColumnState } from 'ag-grid-community';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import { ApiStatus } from '../../../common/enums/ApiStatus';

export type TopUsersReportLegacyHeaderProps = {
  reportFilters: IReportFilters;
  isRefreshing: boolean;
  onRefresh: () => void;
};

export type TopUsersReportProps = {
  reportFilters: IReportFilters;
  refreshTimestamp?: number;
  calendarIntegrated: boolean;
};

export interface ITopUsersColumnsStore {
  [ReportViewTypes.SUMMARY]: ColumnState[];
  [ReportViewTypes.DETAILED]: ColumnState[];
}

export interface ITopUsersReportRowDto {
  active: number;
  app: number;
  idle: number;
  offline: number;
  other: number; //same as 'undefined'
  productive: number;
  productiveActive: number;
  productiveActiveRatio: number;
  productivePassive: number;
  productivePassiveRatio: number;
  total: number;
  undefinedActive: number;
  undefinedActiveRatio: number;
  undefinedPassive: number;
  undefinedPassiveRatio: number;
  unproductive: number;
  unproductiveActive: number;
  unproductiveActiveRatio: number;
  unproductivePassive: number;
  unproductivePassiveRatio: number;
  user: string;
  userId: string;
  userType: string; //'User' | 'Computer' | 'Group';
  web: number;
}

export interface ITopUsersReportDto {
  error: string;
  rows: ITopUsersReportRowDto[];
  totalActivity: number;
}

//Gantt data only used with User or Computer mode, not Group mode
export interface ITopUsersGanttDataDto {
  activity: string;
  end: string;
  hasDescription: boolean;
  isWebsite: boolean;
  productivity: number;
  start: string;
  user: string;
  userId: number;
}

export interface ITopUsersGanttLocationDto {
  date: string;
  location: string;
  userId: number;
  userName: string;
}

export interface ITopUsersGanttDto {
  data: ITopUsersGanttDataDto[];
  location: ITopUsersGanttLocationDto[];
  total: number;
}

export interface ITopUserReport {
  active: number;
  offline: number;
  productive: number;
  productiveActive: number;
  productivePassive: number;
  total: number;
  undefined: number; //comes from 'other'
  undefinedActive: number;
  undefinedPassive: number;
  unproductive: number;
  unproductiveActive: number;
  unproductivePassive: number;
  user: string;
  userId: string;
  userType: string; //TODO: ReportMode = 'User' | 'Computer' | 'Group';
}

export interface ITopUsersMapped {
  data: ITopUserReport[];
  largestDuration: number;
}

export interface ITopUsersReport {
  data: Partial<ITopUserReport>[];
  largestDuration: number; //used to calculate productivity ratio bars
  totalActivity: number;
  error: string | Error;
}

export interface ITopUserReportStore {
  response: ITopUsersReport;
  apiStatus: ApiStatus;
}

export interface ITopUserSearchStore {
  searchOptions: { label: string; value: string }[];
  searchResults: Partial<ITopUserReport>[];
  searchValue: string;
  searchInputValue: string;
}

export enum TopUsersDataId {
  User = 'user',
  UserId = 'userId',
  Productive = 'productive',
  ProductiveActive = 'productiveActive',
  ProductivePassive = 'productivePassive',
  Unproductive = 'unproductive',
  UnproductiveActive = 'unproductiveActive',
  UnproductivePassive = 'unproductivePassive',
  Undefined = 'undefined',
  UndefinedActive = 'undefinedActive',
  UndefinedPassive = 'undefinedPassive',
  Total = 'total',
  Active = 'active',
  Offline = 'offline'
}
