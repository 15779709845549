import { useEffect, useState } from 'react';
import { getInjector, useInjector } from '../third-party/angular2react/useInjector';

export const useUIRouterHistory = () => {
  const $injector = useInjector();

  return {
    push: async (routeName: string, parameters?: unknown | undefined) => {
      const $state: any = $injector.get('$state');
      await $state.go(routeName, parameters);
    }
  };
};

interface IUIRouterState<TParams> {
  name: string;
  params?: TParams;
  data?: any;
}

export const getUIRouterState = () => {
  const $injector = getInjector();
  const $state: any = $injector.get('$state');

  const name = $state.current?.name;
  const data = $state.current?.data ?? {};
  const params = $state.params ?? {};

  return {
    name, data, params
  };
}

export const useUIRouterState = <TParams>() => {
  const $injector = getInjector();
  const $transitions: any = $injector.get('$transitions');

  const { name, params, data } = getUIRouterState();

  const [state, setState] = useState<IUIRouterState<TParams>>({
    name,
    params,
    data
  });

  useEffect(() => {
    const unsubscribe = $transitions.onSuccess(
      {
        to: 'app.**'
      },
      (transition) => {
        const current: string = transition.$to().name;
        const currentParams = transition.params();

        setState(() => ({
          name: current,
          params: currentParams,
          data: transition.$to().data
        }));
      }
    );

    return () => {
      unsubscribe();
    };
  }, [$injector, $transitions]);

  return state;
};
