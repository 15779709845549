import { ColDef } from 'ag-grid-community';
import { ReportType } from '../../../common/enums/ReportType';
import { IExportReportParams } from '../../../common/stores/exportsStore/exportStore.models';
import { getExportParamsStore } from '../../../common/stores/exportsStore/exportParamsStore';
import { createBaseReportFilterParams } from '../../../common/components/ReportFilters/utils/reportFilter.utils';
import { fetchReportingData } from '../../../common/helpers';
import {
  ITopUsersMapped,
  ITopUsersReport,
  ITopUsersReportDto
} from '../models/TopUsersReport.models';
import { convertObjectToQs } from '../../../common/utils/parse/parse';
import {
  getTopUsersColumnsStore,
  setTopUsersColumnsStore
} from '../stores/topUsersColumns.store';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import {
  handleAllCheckbox,
  mapColDefToColumnState,
  mapColumnStateToGridColumnFilter
} from '../../../common/components/ReportFilters/utils/gridColumnsFilter.utils';
import {
  IGridColumnFilter,
  IReportColumnLabel
} from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { getReportPageMode } from '../../../common/components/ReportFilters/stores/reportPageModeStore';
import { getTotalPercent } from '../../common/utils/reports.utils';

export const createExportPayload = (): IExportReportParams => {
  const exportParams = getExportParamsStore();

  const { from, to, userType, userId, userMode } =
    createBaseReportFilterParams();

  const reportPayload: IExportReportParams = {
    type: ReportType.TopUsersAndGroups,
    startDate: from,
    endDate: to,
    userType,
    userMode,
    userId
  };

  return { ...reportPayload, ...exportParams };
};

//use the sort order from the ColumnState
export const mapTopUsersColumnsToColumnState = (
  newColumns: ColDef[],
  view: ReportViewTypes
): void => {
  //get current saved state directly from store
  const currentColumnState = getTopUsersColumnsStore()[view];

  const newColumnsStates = currentColumnState.map((currentCol) => {
    if (!currentCol) return;

    const currentDefaultColId = currentCol.colId;

    const newColumnState = newColumns.find(
      (newCol) => newCol.colId === currentDefaultColId
    );
    if (!newColumnState) return;

    return mapColDefToColumnState(newColumnState);
  });

  //update state directly to store
  setTopUsersColumnsStore({ [view]: newColumnsStates });
};

//use the sort order from the Column Selector labels
export const mapTopUsersColumnFiltersToColumnLabels = (
  view: ReportViewTypes,
  labels: IReportColumnLabel[]
): IGridColumnFilter[] => {
  const sortedDefaultColumns = labels?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  //prefix the "All" column AFTER sorting
  const clonedSortedDefaultColumns = [...sortedDefaultColumns];
  clonedSortedDefaultColumns.unshift({ colId: 'all', label: 'All' });

  const currentColumnState = getTopUsersColumnsStore()[view];

  const cols = clonedSortedDefaultColumns?.map((col) => {
    return mapColumnStateToGridColumnFilter(col, currentColumnState);
  });

  return handleAllCheckbox(cols);
};

export const getTopUsersData = async (): Promise<ITopUsersReport> => {
  try {
    const { to, from, userId, groupId, userMode, userType, computerId } =
      createBaseReportFilterParams();
    const pageMode = getReportPageMode()['pageMode'];

    const reportFiltersStr = convertObjectToQs({
      to,
      from,
      userId: groupId || userId || computerId,
      userType,
      mode: userMode,
      groupBy: pageMode,
      range: 'Custom_Date_Range'
    });

    const response = await fetchReportingData<ITopUsersReportDto>({
      path: '/reports/v1/topusers?' + reportFiltersStr
    });
    const mapped = mapTopUsersReportDtoToTopUsers(response);

    return {
      data: mapped.data ?? [],
      largestDuration: mapped.largestDuration,
      totalActivity: response?.totalActivity,
      error: null
    };
  } catch (error) {
    return { data: [], largestDuration: 0, totalActivity: 0, error: error };
  }
};

export const mapTopUsersReportDtoToTopUsers = (
  response: ITopUsersReportDto
): ITopUsersMapped => {
  if (!response.rows) return { data: [], largestDuration: 0 };

  // Supplying same 'largestDuration' value to each user so the value is available as soon as the columns are created. Otherwise, the grid auto-selects the wrong item on load since columns are created AFTER 'handleGridReady' completes.
  const largestDuration = Math.max(...response.rows.map((o) => o.total));

  const data = response.rows.map((row) => ({
    active: row.active,
    offline: row.offline,
    productive: row.productive,
    productiveActive: row.productiveActive,
    productivePassive: row.productivePassive,
    total: row.total,
    undefined: row.other,
    undefinedActive: row.undefinedActive,
    undefinedPassive: row.undefinedPassive,
    unproductive: row.unproductive,
    unproductiveActive: row.unproductiveActive,
    unproductivePassive: row.unproductivePassive,
    user: row.user,
    userId: row.userId,
    userType: row.userType
  }));

  return { data, largestDuration };
};

// Calc percents from productivity statuses
export const getProductivityPercent = (value, total) => {
  const percent = getTotalPercent(value, total, 2);
  return (!percent ? '--' : percent) + '%';
};

export const productivityRatioComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending
) => {
  const dataA = nodeA?.data;
  const dataB = nodeB?.data;

  if (dataA === null && dataB === null) {
    return 0;
  }
  if (dataA === null) {
    return -1;
  }
  if (dataB === null) {
    return 1;
  }
  const field = isDescending ? 'unproductive' : 'productive';
  const a = parseInt(dataA[field]);
  const b = parseInt(dataB[field]);

  const direction = isDescending ? 1 : -1;
  return a < b ? -1 * direction : a > b ? 1 * direction : 0;
};
