import { createStore } from 'zustand/vanilla';
import { IEntityStore } from './entityStore.models';
import { createGroupComboList, getAllEntitiesList } from './entityStore.utils';
import {
  ENTITY_STORE_CACHE_LIFETIME,
  ENTITY_STORE_DEFAULT
} from './entityStore.constants';

export const entitiesStore = createStore<IEntityStore>(
  () => ENTITY_STORE_DEFAULT
);

export const getAllEntities = async (search?: string) => {
  // Define empty search and undefined search as null
  if (search === undefined || search === '') {
    search = null;
  }

  // Fetch data if search is different or cache is older than the timeout
  const { previousSearch, cacheTimestamp } = entitiesStore.getState();
  if (
    previousSearch !== search ||
    cacheTimestamp === undefined ||
    cacheTimestamp <= Date.now() - ENTITY_STORE_CACHE_LIFETIME
  ) {
    const currentState = entitiesStore.getState();
    entitiesStore.setState({ ...currentState, isLoading: true });
    try {
      const { users, computers, groups } = await getAllEntitiesList(search);
      entitiesStore.setState({
        users,
        computers,
        groups,
        previousSearch: search,
        cacheTimestamp: Date.now()
      });
    } catch (error) {
      console.error('ActivTrak Error: cannot load entities', error);
    } finally {
      const currentState = entitiesStore.getState();
      entitiesStore.setState({ ...currentState, isLoading: false });
    }
  }

  return entitiesStore.getState();
};

export const getUserGroupCombo = async (search: string) => {
  const { users, groups } = await getAllEntities(search);
  return createGroupComboList(users, groups, 'user');
};

export const getComputerGroupCombo = async (search: string) => {
  const { computers, groups } = await getAllEntities(search);
  return createGroupComboList(computers, groups, 'computer');
};

// export const initializeEntitiesStore = async () => {
//   resetEntitiesStore();
//   await getAllEntities();
// };

export const resetEntitiesStore = () =>
  entitiesStore.setState(ENTITY_STORE_DEFAULT);

export const invalidateEntitiesCache = () => {
  const currentState = entitiesStore.getState();
  entitiesStore.setState({ ...currentState, cacheTimestamp: undefined });
};
