import viewTemplate from 'views/screenshots/screenshotsSubpage.html?raw';
import messageModal from 'views/modals/messageModal.html?raw';
import { getScreenshotSafeSearchSettingsStore } from '../../../../_reactivtrak/src/common/stores/screenshotSettingsStore/screenshotSafeSearchSettingsStore';

angular.module('app').directive('screenshotsSubpage', screenshotsSubpage);

function screenshotsSubpage() {
    return {
        template: viewTemplate,
        controller: ScreenshotsSubpageCtrl
    };
}

ScreenshotsSubpageCtrl.$inject = [
    '$scope',
    '$state',
    'localStorageService',
    'messagesService',
    'customUibModal',
    'constantsService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions'
];

function ScreenshotsSubpageCtrl(
    $scope,
    $state,
    localStorageService,
    msg,
    customUibModal,
    constant,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions
) {
    $scope.filter = angular.copy($state.params);
    $scope.loading = false;
    $scope.isIeOrEdgeBrowser = browserServiceFunctions.isIeOrEdgeBrowser();

    var categoryMap = {
        Unknown: 0,
        VeryUnlikely: 1,
        Unlikely: 2,
        Possible: 3,
        Likely: 4,
        VeryLikely: 5
    };

    const { adultThreshold, medicalThreshold, racyThreshold, violenceThreshold } =
        getScreenshotSafeSearchSettingsStore();

    $scope.unsafeTooltip = function (image) {
        if (!image.safeSearchObject) {
            return;
        }
        var thresholds = {
            adultThreshold,
            medicalThreshold,
            racyThreshold,
            violenceThreshold
        };

        if (!thresholds) {
            return;
        }

        var indexNames = ['adultThreshold', 'medicalThreshold', 'racyThreshold', 'violenceThreshold'];

        var text = '';

        for (var key in indexNames) {
            var likelihoodLabel = image.safeSearchObject[indexNames[key]];
            var thresholdLabel = thresholds[indexNames[key]];
            var threshold = categoryMap[thresholdLabel];
            if (likelihoodLabel) {
                var likelihood = categoryMap[likelihoodLabel];
                if (likelihood > 0 && likelihood <= 5 && threshold < 6) {
                    var category = indexNames[key];
                    category = atHelperFunctions.capitalizeFirstLetter(category);
                    likelihoodLabel = atHelperFunctions.camelCaseToString(likelihoodLabel);
                    text +=
                        '<b>' +
                        category +
                        ':</b><span style="' +
                        (likelihood >= threshold ? 'color:' + constant.get('color', 'COLOR-DANGER-LIGHT') + ';' : '') +
                        '"> ' +
                        likelihoodLabel +
                        '</span><br/>';
                }
            }
        }
        return text.slice(0, text.length - 5);
    };

    $scope.applyFilter = function (filter, shouldReplaceFilter) {
        if (shouldReplaceFilter) {
            $scope.filter = filter;
        }
        if (!!filter) {
            var processedFilters = {
                logic: 'and',
                filters: []
            };
            for (var field in filter) {
                if (!!filter[field] && typeof filter[field] === 'string') {
                    processedFilters.filters.push({
                        field: field,
                        operator: 'contains',
                        value: filter[field]
                    });
                } else if (typeof filter[field] === 'boolean') {
                    processedFilters.filters.push({
                        field: field,
                        operator: 'eq',
                        value: filter[field]
                    });
                }
            }
            $scope.filterData.appliedFilter = {};
            angular.copy(filter, $scope.filterData.appliedFilter);
            $scope.source.filter(processedFilters);
        }
    };

    $scope.mode = localStorageService.get('screenshot-gallery-view-mode') || 1; // default view is 'list'
    $scope.newestFirst = localStorageService.get('screenshot-newest-first') || false;

    $scope.switchViewMode = function (mode) {
        $scope.mode = mode;
        localStorageService.set('screenshot-gallery-view-mode', mode);
    };

    var applySortToSource = function () {
        $scope.source.sort({
            field: 'time',
            dir: $scope.newestFirst ? 'desc' : 'asc'
        });
    };

    $scope.switchSort = function () {
        $scope.newestFirst = !$scope.newestFirst;
        localStorageService.set('screenshot-newest-first', $scope.newestFirst);
        applySortToSource();
    };

    $scope.showImage = function (i) {
        if (i.isSafe) {
            $scope.$broadcast('showScreenshotEvent', {
                index: i.index
            });
        } else {
            var modal = customUibModal.open({
                animation: false,
                template: messageModal,
                controller: 'messageModalController',
                windowClass: 'centered-modal',
                resolve: {
                    messageData: {
                        messageBody:
                            'This screenshot has been flagged unsafe.  Do you want to reveal the screenshot or keep it hidden?',
                        confirmLabel: 'Reveal',
                        secondActionLabel: 'Keep Hidden',
                        secondActionClass: 'btn-complete'
                    }
                }
            });

            modal.result.then(function (hide) {
                $scope.$broadcast('showScreenshotEvent', {
                    index: i.index,
                    showUnsafe: !hide
                });
            });
        }
    };

    $scope.openProductivity = function (e, item) {
        e.preventDefault();
        if (!$scope.hasViewLevel('edit') || browserServiceFunctions.isMobileAgent()) {
            return;
        }
        var stateParams = $scope.getProductivityStateParams(item);
        if (stateParams.item === null) {
            return;
        }
        $state.go('app.settings.productivity', stateParams);
    };

    $scope.filterData = {
        panelTitle: msg.get('filterScreenshots'),
        fields: [
            {
                label: msg.get('title'),
                name: 'title'
            },
            {
                label: msg.get('computer'),
                name: 'computer'
            },
            {
                label: msg.get('executable'),
                name: 'executable'
            },
            {
                label: msg.get('description'),
                name: 'description'
            },
            {
                label: msg.get('user'),
                name: 'user'
            },
            {
                label: msg.get('url'),
                name: 'url'
            }
        ],
        appliedFilter: angular.copy($state.params)
    };
    if ($scope.safeSearchFeatureFlag) {
        $scope.filterData.fields.push({
            label: msg.get('screenshotsLower'),
            name: 'safeScreenshot',
            type: 'buttons',
            filterOptions: [
                {
                    buttonLabel: 'Unsafe',
                    value: false
                }
            ]
        });
    }
    $scope.applyFilter($scope.filter, true);

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: '.overflow-ellipsis',
        onHover: function (e) {
            e.sender.popup.wrapper.prop('style')['z-index'] = '10100';
        }
    });
}
