import React, { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  StyledFormControlLabel,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import { IRolesCategories } from '../../models';

type ComponentProps = {
  selected: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  filtered: IRolesCategories[];
};

const RadioGroupRoleBox = ({
  selected,
  handleChange,
  filtered
}: ComponentProps) => (
  <Box sx={TotCss.scrollableBox}>
    <RadioGroup value={selected} onChange={handleChange}>
      {filtered?.map((rc) => (
        <StyledFormControlLabel
          key={rc.role}
          value={rc.role}
          name={rc.categories.join('::')}
          control={<Radio size="medium" color="primary" />}
          label={rc.role}
          sx={{ padding: '0' }}
        />
      ))}
    </RadioGroup>
  </Box>
);

export default RadioGroupRoleBox;
