import { GroupTypeBasic } from '../../common/enums';
import {
  IUserAgent,
  IUserIdentity,
  IValueValidation,
  IValueValidationPrimary
} from '../models/IUserIdentity';

export const VALUE_VALIDATION_DEFAULT: IValueValidation = {
  value: '',
  originalValue: '',
  validationFailed: false,
  failedExplanation: ''
};

export const VALUE_VALIDATION_PRIMARY: IValueValidationPrimary = {
  value: '',
  originalValue: '',
  validationFailed: false,
  failedExplanation: '',
  primary: false
};

export const USER_AGENT_DEFAULT: IUserAgent = {
  userId: 0,
  lastLog: new Date(),
  firstLog: new Date(),
  alias: '',
  userName: '',
  logonDomain: '',
  revision: '',
  selected: false,
  singleDescription: '',
  tracked: false,
  userLogonDomain: ''
};

export const USER_IDENTITY_DEFAULT: IUserIdentity = {
  firstUserId: 0,
  csvUsers: '',
  userCount: 0,
  maxLastLog: new Date().toISOString(),
  emails: [],
  employeeIds: [],
  agents: [],
  id: 0,
  emailsDisplay: '',
  nameColumnDisplay: '',
  firstName: { ...VALUE_VALIDATION_DEFAULT },
  middleName: { ...VALUE_VALIDATION_DEFAULT },
  lastName: { ...VALUE_VALIDATION_DEFAULT },
  displayName: { ...VALUE_VALIDATION_DEFAULT },
  revision: 0,
  selected: false,
  singleDescription: '',
  tracked: true,
  displayId: {
    value: '',
    originalValue: '',
    validationFailed: false,
    failedExplanation: '',
    source: GroupTypeBasic.ActivTrak
  },
  deviceLogons: [],
  upns: []
};
