import { useEffect, useState } from 'react';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { useAddAgentColumns } from '../utils/useAddAgentColumns';
import { setSingleAgentSelected } from '../utils/SearchUtils';
import { IAgentSearchState } from '../models/IAgentSearchState';
import { IAgentSearch } from '../models/IAgentSearch';
import { DialogVerifyAgentMove } from './DialogVerifyAgentMove';
import { GlobalUserFilters } from './GlobalUserFilters';
import { useCustomRowsSkeletonLoader } from '../../common/components/CustomGrid/CustomGrid.utils';
import { GridPaging } from './GridPaging';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type ComponentProps = {
  agentSearchState: IAgentSearchState;
  commonState: IIdentityCommonState;
  onClose: (selectedAgents: IAgentSearch[]) => void;
  userDescription: string;
  readOnlyAgentIds: number[];
};

export const AgentSearchModal = (props: ComponentProps) => {
  const { agentSearchState, onClose, userDescription, readOnlyAgentIds } =
    props;
  const {
    agents,
    isLoadingAgents,
    onAgentsChanged,
    openAssignAgentModal,
    setAgentsSelectedFromAgents,
    pageSize,
    currentCursor,
    cursorStack,
    nextPageDisabled,
    onChangePage,
    onChangePageSize,
    onSetSearchTerm,
    agentFilter,
    onSetAgentFilter
  } = agentSearchState;

  const [tempSearchTerm, setTempSearchTerm] = useState<string>('');
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState<boolean>(false);
  const [lastSelectedAgent, setLastSelectedAgent] =
    useState<IAgentSearch | null>(null);

  const onCancelChanges = () => {
    onClose(null);
    setTempSearchTerm('');
  };

  const onSaveChanges = () => {
    if (lastSelectedAgent) {
      setIsVerifyDialogOpen(true);
    } else {
      onCancelChanges();
    }
  };

  const closeModal = () => {
    agentSearchState.setOpenAssignAgentModal(false);
    setTempSearchTerm('');
  };

  const agentGridColumns = useAddAgentColumns();

  const handleClickRowGrid = (rowItem) => {
    setSingleAgentSelected(rowItem, agents);
    setLastSelectedAgent(rowItem);
    onAgentsChanged([...agents]);
  };

  const handleInputChange = (event) => {
    const value = event.target?.value;
    if (!event && (!value || value.length == 0)) {
      //not sure why we get this event.  happens after clicking two enter keys
      return;
    }
    setTempSearchTerm(value);
  };

  const closeVerifyModal = () => {
    setIsVerifyDialogOpen(false);
  };

  const agreeVerifyModal = () => {
    const selectedAgents = setAgentsSelectedFromAgents(agents);
    onClose(selectedAgents);
    setIsVerifyDialogOpen(false);
  };

  const userGridRowDisableCondition = (rowItem) => {
    return readOnlyAgentIds?.includes(rowItem.userId) ?? false;
  };

  //debounce getting temporary search terms
  useEffect(
    () => {
      const timeoutDuration = 700;
      const timeOutId = setTimeout(async () => {
        onSetSearchTerm(tempSearchTerm);
      }, timeoutDuration);
      return () => clearTimeout(timeOutId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tempSearchTerm]
  );

  const onSortAgentOrder = (newOrderDirection, newOrderBy) => {
    agentSearchState.onSetAgentSort(newOrderBy, newOrderDirection);
  };

  const agentSkeletonLoader = useCustomRowsSkeletonLoader(16, 6, false);

  return (
    <>
      <Dialog
        open={openAssignAgentModal}
        onClose={closeModal}
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogTitle>Assign agent</DialogTitle>
        <DialogContent id="assign-agent-dialog-content">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Box sx={{ pb: 2 }}>
                <TextField
                  name="searchTerm"
                  placeholder="Search by Username, Logon domain, User, UPN, Employee id, Email, Username, or Device login"
                  value={tempSearchTerm}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  autoFocus
                  onClick={(e) => e.stopPropagation()}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <GlobalUserFilters
                gridFilter={agentFilter}
                onSetGridFilter={onSetAgentFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomGrid
                height={'52vh'}
                isVirtualized={true}
                isLoading={isLoadingAgents}
                data={agents}
                columns={agentGridColumns}
                uniqueRowProp="id"
                initialSortField={agentSearchState.sortBy}
                initialSortDirection={agentSearchState.sortDirection}
                onSortOrder={onSortAgentOrder}
                onClickRow={handleClickRowGrid}
                skeletonLoaderTemplate={agentSkeletonLoader}
                rowDisableCondition={userGridRowDisableCondition}
              />
            </Grid>
            <Grid item xs={12}>
              <GridPaging
                currentCursor={currentCursor}
                cursorStack={cursorStack}
                nextPageDisabled={nextPageDisabled}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                pageSize={pageSize}
                idPrefix="agent"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onCancelChanges}>
            Cancel
          </Button>
          <Button onClick={onSaveChanges} variant="contained" color="primary">
            Assign agent
          </Button>
        </DialogActions>
      </Dialog>
      <DialogVerifyAgentMove
        isOpen={isVerifyDialogOpen}
        onCancel={closeVerifyModal}
        onAgree={agreeVerifyModal}
        agentDescription={
          lastSelectedAgent?.userLogonDomain ??
          lastSelectedAgent?.displayName ??
          lastSelectedAgent?.logonDomain ??
          lastSelectedAgent?.userId?.toString() ??
          ''
        }
        userDescription={userDescription}
      />
    </>
  );
};
