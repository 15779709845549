import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { primaryColor } from '../../common/constants';

export const StyledLabel = styled('label')`
  /* display: block; */
  background: #ffffff;
  width: 312.38px;
  height: 72px;
  left: 78.44px;
  top: 36px;
  position: absolute;

  /* Body - Normal */

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  align-items: center;
  letter-spacing: 0.5px;
  cursor: pointer;
  user-select: none;
`;

const getBorderColor = (selected, borderColor) => {
  if (selected) {
    return `border: 2px solid ${borderColor ? borderColor : 'yellow'}`;
  }
};

interface StyledDivProps {
  selected?: boolean;
  borderColor?: string;
}

export const StyledDiv = styled(Box)<StyledDivProps>`
  cursor: pointer;
  align-items: center;
  letter-spacing: 0.5px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 46px 46px 48px;

  width: 423px;
  height: 139px;
  position: relative;

  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  &:hover {
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
      0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  }

  ${({ selected, borderColor }) =>
    selected &&
    `
        ${getBorderColor(selected, borderColor)};
    `}

  .at-icon {
    font-size: 2.1em;
    position: relative;
    bottom: 0.3em;
    right: 10px;
  }
`;

export const StyledInput = styled('input')`
  &:checked,
  &:not(:checked) {
    display: none;
  }

  position: absolute;
`;

export const SimpleLabel = styled('label')`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  /* align-items: center; */
  letter-spacing: 0.5px;
  & > span {
    margin-left: 10px;
  }
`;

export const SimpleRadio = styled('input')`
  appearance: none;
  width: 1.15em;
  height: 1.15em;

  border: 0.15em solid ${primaryColor};
  border-radius: 50%;

  /* this adds the checked effect */
  &:checked {
    background: radial-gradient(
      ${primaryColor} 0%,
      ${primaryColor} 40%,
      transparent 50%,
      transparent
    );
  }
`;
