import { useCallback, useEffect, useState } from 'react';
import {
  IPerformanceOptimizationState,
  PerformanceOptimizationStateProps
} from '../models';
import { useLiveReportSettingsStore } from '../../common/stores/liveReportsSettingsStore/liveReportSettingsStore.hooks';
import { setLiveReportSettingsStore } from '../../common/stores/liveReportsSettingsStore/liveReportSettingsStore';

export const usePerformanceOptimizationState = ({
  service,
  notificationService
}: PerformanceOptimizationStateProps): IPerformanceOptimizationState => {
  const { postLiveReportSettings } = service;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [optimize, setOptimize] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const { includeTodaysDate, canUpdate } = useLiveReportSettingsStore((s) => s);

  useEffect(() => {
    setOptimize(!includeTodaysDate);
    setIsLocked(!canUpdate);
  }, [includeTodaysDate, canUpdate]);

  const updateOptimize = useCallback(
    async (val: string) => {
      const formattedVal = val === 'true';
      if (optimize !== formattedVal) {
        setIsLoading(true);
        try {
          await postLiveReportSettings(formattedVal);
          setLiveReportSettingsStore({ includeTodaysDate: !formattedVal });

          notificationService.success(
            'Success! Performance Optimization Setting is updated!'
          );
        } catch (error) {
          notificationService.error(
            'Unable to update Performance Optimization Settings'
          );
          console.error(
            'ActivTrak Error: Unable to update Performance Optimization Settings',
            error
          );
        } finally {
          setIsLoading(false);
        }
      }
    },
    [optimize, postLiveReportSettings, notificationService]
  );

  return {
    optimize,
    isLoading,
    isLocked,
    updateOptimize
  };
};
