import { Role } from '../../../../../../enums';
import { FeatureFlag } from '../../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../../models/IRoute';

export const appDevelopmentDesignId: IRoute = {
  name: 'app.development.design.id',
  stateDefinition: {
    url: '/{demoId}',
    data: { pageTitle: 'Development Playground' },
    params: {
      demoId: 'buttons'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: Object.values(Role), // All roles have access to design examples
    read: [],
    featureFlags: [FeatureFlag.DevelopmentEnvironment]
  }
};

export default appDevelopmentDesignId;
