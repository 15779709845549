import { GroupTypeBasic } from '../../common/enums';

export type IUserIdentitySource =
  | GroupTypeBasic.AzureAD
  | GroupTypeBasic.ActivTrak
  | GroupTypeBasic.Api;

export interface IIdentityResults {
  cursor: string;
  results: IUserIdentity[];
}

export interface IValidationFailure {
  validationFailed: boolean;
  failedExplanation: string;
}

export interface IUserIdentity {
  firstUserId: number;
  csvUsers: string;
  userCount: number;
  maxLastLog: string;
  emails: IValueValidationPrimary[];
  upns: IValueValidation[];
  employeeIds: IValueValidation[];
  agents: IUserAgent[];
  id: number;
  emailsDisplay: string;
  nameColumnDisplay: string;
  firstName: IValueValidation;
  middleName: IValueValidation;
  lastName: IValueValidation;
  displayName: IValueValidation;
  revision: number;
  selected: boolean;
  singleDescription: string;
  tracked: boolean;
  displayId: IValueValidation;
  deviceLogons?: IDeviceLogon[];
}

export interface IUserAgent {
  userId: number;
  lastLog: Date;
  firstLog: Date;
  alias: string;
  userName: string;
  logonDomain: string;
  revision: string;
  selected: boolean;
  singleDescription: string;
  userLogonDomain: string;
  tracked: boolean;
}

export interface IDeviceLogon extends IValidationFailure {
  user: string;
  logonDomain: string;
  uniqueId: string;
  originalUser: string;
  originalLogonDomain: string;
  primary: boolean;
  canBeMerged: boolean;
  isGlobal: boolean;
}

export interface IValueValidation extends IValidationFailure {
  value: string;
  originalValue: string;
  source?: IUserIdentitySource;
}

export interface IValueValidationPrimary extends IValueValidation {
  primary: boolean;
}

export enum UserDataSource {
  ActivTrak = 'ActivTrak'
}
