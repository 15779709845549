import { useEffect } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Box } from '@mui/material';
import { useNavigationState } from './useSideBarState';

import { setSideBarStatus, useSideBarStatusStore } from '../sideBarStatusStore';

import activtrakLogo from '../../../../../../app/images/at-logo-drk-theme.svg';
import { SlidingList } from './SideBar.styles';
import { HomeComponent } from './HomeComponent';
import { FavoritesComponent } from './FavoritesComponent';
import { NavigationItem } from './NavigationItem';
import { useFavoritesStore } from '../favoritesStore';

//TODO: This could be defined in a shared module
const hideOnLargeScreenStyle: SxProps = {
  display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' }
};

export const SideBarComponent = () => {

  const { menu } = useNavigationState();
  
  const sideBarStatus = useSideBarStatusStore();

  //TODO: update to authorization.hasFeature(BundleFlag.FavoriteNavigation)
  const isFavoriteNavigationEnabled = useFavoritesStore(
    (s) => s.isFavoriteNavigationEnabled
  );

  const home = menu.find((item) => item.label === 'Home');

  const togglePinSideBar = () => {
    if (sideBarStatus === 'hidden') {
      return;
    }

    const toggle = sideBarStatus === 'open' ? 'closed' : 'open';
    setSideBarStatus(toggle);
  };


  useEffect(() => {

    if (sideBarStatus === 'open') {
      window.document.body.classList.add('menu-open');
      window.document.body.classList.add('menu-pin');
    } else if (
      (sideBarStatus === 'closed' || sideBarStatus === 'hidden') &&
      window.document.body.classList.contains('menu-open')
    ) {
      window.document.body.classList.remove('menu-open');
      window.document.body.classList.remove('menu-pin');
    }
  }, [sideBarStatus]);

  return (
    <div className={`at-sidebar-menu-wrapper font-roboto ${sideBarStatus === 'hidden' ? 'hide-sidebar' : ''}`}>
      <Box
        sx={{ ...hideOnLargeScreenStyle }}
        className="at-mobile-sidebar-background"
        onClick={() => {togglePinSideBar()}}
      ></Box>
      <nav className="at-sidebar-menu">
        <div className="at-sidebar-header">
          <div className="at-menu-log-wrapper">
            <img
              src={activtrakLogo}
              alt="ActivTrak logo"
              style={{ padding: 25, width: '100%' }}
            />
          </div>
          <div className="at-menu-pin-wrapper">
            <div
              id={`sidebar_pin_4f439f25-c688-4d5b-be52-e12ca5f536af`}
              className="at-menu-pin"
              onClick={togglePinSideBar}
            >
              <div
                className={`hamburger hamburger--minus js-hamburger ${
                  sideBarStatus === 'open' ? 'is-active' : ''
                }`}
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="at-sidebar-menu-list p-b-50">
          <SlidingList className="at-sidebar-menu-items">
            {/* Home is Fixed */}
            <HomeComponent home={home}></HomeComponent>

            {isFavoriteNavigationEnabled && <FavoritesComponent />}
          </SlidingList>
          <SlidingList className="at-sidebar-menu-items">
            {menu
              .filter((item) => item.label !== 'Home')
              .map((item, index) => {
                return <NavigationItem key={index} item={item} index={index} />;
              })}
          </SlidingList>
        </div>
      </nav>
    </div>
  );
};
