import { useState } from 'react';
import {
  USER_IDENTITY_DEFAULT,
  VALUE_VALIDATION_DEFAULT
} from '../constants/defaultObjects.constants';
import { runEmailUpnValidations } from '../utils/DetailEmailEmployeeIdUtils';
import { IAgentSearch } from '../models/IAgentSearch';
import _ from 'lodash';
import { IUserIdentityEditDto } from '../models/IUserIdentityDto';
import {
  ENTER_VALID_EMAIL,
  ENTER_VALID_UPN
} from '../constants/errorMessages.constants';
import { IUserIdentity } from '../models/IUserIdentity';
import { getDefaultDeviceLogon } from '../utils/DetailEmailEmployeeIdUtils';
import { trimValueIdOrNull } from '../utils/TrimUtils';

export interface INewUserState {
  newUser: IUserIdentity;
  handleDisplayNameChange?: (e) => void;
  handleEmployeeIdChange?: (e) => void;
  handleEmailChange?: (e) => void;
  updateEmailUpnValidations?: () => void;
  handleUserPrincipalNameChange?: (e) => void;
  addDeviceLogon: (userName: string, logonDomain: string) => void;
  getSaveDto: (agentsSelected: IAgentSearch[]) => IUserIdentityEditDto;
  setNewUser?: (newUser: IUserIdentity) => void;
  onTrackingFlip: () => void;
}

export const useNewUserState = (): INewUserState => {
  const [newUser, setNewUser] = useState<IUserIdentity>(
    _.cloneDeep(USER_IDENTITY_DEFAULT) as IUserIdentity
  );

  const handleDisplayNameChange = (e) => {
    setNewUser((prevState) => {
      return {
        ...prevState,
        displayName: {
          ...(prevState?.displayName ?? VALUE_VALIDATION_DEFAULT),
          value: e.target.value
        }
      };
    });
  };

  const handleEmployeeIdChange = (e) => {
    const clone = { ...newUser };

    if (clone.employeeIds == null) {
      clone.employeeIds = [];
    }
    if (clone.employeeIds.length == 0) {
      clone.employeeIds.push({
        value: e.target.value,
        originalValue: '',
        validationFailed: false,
        failedExplanation: ''
      });
    } else {
      clone.employeeIds[0].value = e.target.value;
    }

    setNewUser(clone);
  };

  const handleUserPrincipalNameChange = (e) => {
    const clone = { ...newUser };

    if (clone.upns == null) {
      clone.upns = [];
    }
    if (clone.upns.length == 0) {
      clone.upns.push({
        value: e.target.value,
        originalValue: '',
        validationFailed: false,
        failedExplanation: ''
      });
    } else {
      clone.upns[0].value = e.target.value;
    }

    setNewUser(clone);
  };

  const handleEmailChange = (e) => {
    const clone = { ...newUser };

    if (clone.emails == null) {
      clone.emails = [];
    }
    if (clone.emails.length == 0) {
      clone.emails.push({
        value: e.target.value,
        originalValue: '',
        validationFailed: false,
        failedExplanation: '',
        primary: false
      });
    } else {
      clone.emails[0].value = e.target.value;
    }

    setNewUser(clone);
  };

  const updateEmailUpnValidations = () => {
    const validationEmailChanged = runEmailUpnValidations(
      newUser.emails,
      ENTER_VALID_EMAIL
    );
    const validationUpnChanged = runEmailUpnValidations(
      newUser.upns,
      ENTER_VALID_UPN
    );
    if (validationEmailChanged || validationUpnChanged) {
      setNewUser({ ...newUser });
    }
  };

  const addDeviceLogon = (user: string, logonDomain: string) => {
    const clone = { ...newUser };

    if (clone.deviceLogons == null) {
      clone.deviceLogons = [];
    }
    const newDeviceLogon = getDefaultDeviceLogon();
    newDeviceLogon.user = user;
    newDeviceLogon.logonDomain = logonDomain;
    clone.deviceLogons.push(newDeviceLogon);

    setNewUser(clone);
  };

  const getSaveDto = (agentsSelected: IAgentSearch[]): IUserIdentityEditDto => {
    const saveDto: IUserIdentityEditDto = {
      displayName: trimValueIdOrNull(newUser?.displayName),
      emails:
        newUser?.emails?.length > 0 && newUser.emails[0].value
          ? [
              {
                id: null,
                value: newUser.emails[0].value
              }
            ]
          : null,
      employeeIds:
        newUser?.employeeIds?.length > 0 && newUser.employeeIds[0].value
          ? [
              {
                value: newUser?.employeeIds[0].value,
                id: null
              }
            ]
          : null,
      userid:
        agentsSelected?.length > 0
          ? { value: agentsSelected[0].userId.toString() }
          : null,
      entity:
        agentsSelected?.length > 0
          ? { value: agentsSelected[0].userId?.toString() }
          : null,
      revision:
        agentsSelected?.length > 0
          ? { value: agentsSelected[0].revision?.toString() }
          : null,
      upns:
        newUser?.upns?.length > 0 && newUser.upns[0].value
          ? [
              {
                value: newUser.upns[0].value,
                id: null
              }
            ]
          : null,
      deviceLogons: [],
      tracking: newUser.tracked
    };
    return saveDto;
  };

  const onTrackingFlip = () => {
    newUser.tracked = !newUser.tracked;
    setNewUser({ ...newUser });
  };

  return {
    newUser,
    handleDisplayNameChange,
    handleEmployeeIdChange,
    handleEmailChange,
    updateEmailUpnValidations,
    handleUserPrincipalNameChange,
    addDeviceLogon,
    getSaveDto,
    setNewUser,
    onTrackingFlip
  };
};
