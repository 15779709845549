import React, { useState } from 'react';
import { MemberType } from '../enums';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

type Prop = {
  defaultType?: MemberType;
  exclusive?: boolean;
  onChange?: (selected: MemberType) => void;
};

export default function MemberTypeToggle(props: Prop) {
  const { defaultType, onChange } = props;

  const [selected, setSelected] = useState<MemberType>(
    defaultType ?? MemberType.User
  );

  const handleChange = (type?: MemberType) => {
    setSelected(type);
    if (onChange !== null) onChange(type);
  };

  return (
    <ToggleButtonGroup
      exclusive={props.exclusive ?? false}
      value={selected}
      size="small"
      onChange={(e, type) => handleChange(type)}
    >
      <ToggleButton value={MemberType.User}>Users</ToggleButton>
      <ToggleButton value={MemberType.Computer}>Computers</ToggleButton>
    </ToggleButtonGroup>
  );
}
