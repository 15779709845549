import { handleLogOut } from './logout';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import versionJSON from '../../../../version.json';
import { getUserToken } from '../../../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';

async function handleResponse(response) {
  if (response.ok) return response;
  if (response.status >= 500) {
    throw new Error(
      'Something went wrong with the server, please try again later'
    );
  } else if (response.status === 401) {
    handleLogOut();
  } else if (response.status === 400) {
    if (typeof response.text === 'function') {
      const errorResponse = await response.text();
      throw new Error(errorResponse);
    }
  }
  throw new Error(
    `Something went wrong, please contact with support and provide the following message: ${response.statusText}`
  );
}

export async function xfetch(url, args = { method: 'GET' }, body = null) {
  args.headers = args.headers || { 'content-type': 'application/json' };
  args.credentials = 'include';

  const token = getUserToken();

  if (token !== 'invalid') {
    args.headers['authorization'] = token;
  }

  args.headers['X-AT-Version'] = versionJSON?.version || 'x.x.x';

  if (body != null) args.body = JSON.stringify(body);

  const response = await fetch(url, args);
  return handleResponse(response);
}


export function getIsCCEnterprise() {
  return userServiceFunctions.getIsCCEnterprise();
}

export function evaluateResourceAccess(user, resource) {
  const result =
    (user?.roleAccess?.includes(resource) ?? false) || user.isSupportUser;
  return result;
}
