import { DayAbbreviation, DayName } from '../utils/datetime/enums/dayName';

export const ABBREVIATED_WEEKDAYS = [
  DayAbbreviation.Sunday,
  DayAbbreviation.Monday,
  DayAbbreviation.Tuesday,
  DayAbbreviation.Wednesday,
  DayAbbreviation.Thursday,
  DayAbbreviation.Friday,
  DayAbbreviation.Saturday
];
export const FULL_WEEKDAYS = [
  DayName.Sunday,
  DayName.Monday,
  DayName.Tuesday,
  DayName.Wednesday,
  DayName.Thursday,
  DayName.Friday,
  DayName.Saturday
];
