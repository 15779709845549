import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  useNotifications,
  INotification,
  Severity
} from '../../services/Notifications';
import { useSubscription } from 'observable-hooks';
import {
  notifError,
  notifInfo,
  notifSuccess,
  notifWarn,
  primaryColor
} from '../../constants';

export default function Notifications() {
  const notificationService = useNotifications();

  const [notification, setNotification] = useState<INotification>(null);
  useSubscription(notificationService.getNotification(), setNotification);

  const getBackgroundColor = (severity: Severity) => {
    switch (severity) {
      case 'info':
        return notifInfo;
      case 'warning':
        return notifWarn;
      case 'error':
        return notifError;
      default:
        return notifSuccess;
    }
  };

  return (
    notification && (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          top: 60
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          role="alert"
          severity={notification.severity}
          sx={{
            backgroundColor: getBackgroundColor(notification.severity),
            color: primaryColor
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    )
  );
}
