import classificationsWidgetTemplate from '../../../../views/widgets/dashboard/classificationsWidget.html?raw';

angular.module('app').directive('classificationsWidget', ClassificationsWidget);

function ClassificationsWidget() {
    return {
        restrict: 'E',
        template: classificationsWidgetTemplate
    };
}
