import viewTemplate from 'views/widgets/teamPulseWrapper.html?raw';
import { inMemoryStore } from 'activtrak-ui-utilities';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').directive('teamPulseWrapper', TeamPulseWrapper);

function TeamPulseWrapper() {
    return {
        restrict: 'E',
        scope: {
            options: '=atOptions'
        },
        template: viewTemplate,
        controller: TeamPulseWrapperCtrl
    };
}

TeamPulseWrapperCtrl.$inject = ['$scope', '$timeout', 'authorizationService'];

function TeamPulseWrapperCtrl($scope, $timeout, authorizationService) {
    var teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');

    var { timeFormat } = getAccountSettings();

    teamPulseDataControl.isReport = $scope.options.isReport;
    teamPulseDataControl.timeFormat = timeFormat;
    teamPulseDataControl.containerHeight = $scope.options.containerHeight;
    teamPulseDataControl.isViewer = authorizationService.hasRole([authorizationService.roles.viewer]);

    $('#tp-app').removeClass('hide');
    $timeout(function () {
        $('#tp-app').appendTo('#tp-app-wrapper');
    });

    $scope.$on('$destroy', function () {
        $('#tp-app').appendTo('#tp-app-holder');
        $('#tp-app').addClass('hide');
    });
}
