import Typography from '@mui/material/Typography';
import { PrimaryUploadBox } from '../../styles/TimeOnTask.view.styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { IBulkPreprocess } from '../../models';
import { red3, yellow2 } from '../../../common/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import { Bulk } from '../../utils/TimeOnTask.view.utils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { alpha } from '@mui/material/styles';

type OwnProps = {
  currentUploadView: Bulk;
  bulkPreprocess?: IBulkPreprocess;
  isLoading: boolean;
};

const BulkUploadWarning: React.FC<OwnProps> = ({
  currentUploadView,
  bulkPreprocess,
  isLoading
}) => {
  const {
    totalGroupCount,
    totalGroupsWithErrorCount,
    missingGroupCount,
    missingRoleCount,
    incorrectPercentage
  } = bulkPreprocess || {};

  const isError = () => {
    return totalGroupsWithErrorCount === totalGroupCount;
  };

  return (
    Bulk.HAS_WARNINGS === currentUploadView && (
      <>
        <PrimaryUploadBox
          sx={{
            height: '187px',
            textAlign: 'left',
            alignContent: 'normal',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                height: '100vh'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ margin: '-6px 0 8px 0' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
                  {totalGroupsWithErrorCount} teams could not be configured due
                  to errors
                </Typography>
              </Box>
              <Box>
                {missingGroupCount > 0 && (
                  <Typography marginBottom="8px">
                    <strong>{missingGroupCount} unmatched team name(s).</strong>{' '}
                    Team name must match the name of an existing Group in your
                    ActivTrak account.
                  </Typography>
                )}
                {missingRoleCount > 0 && (
                  <Typography marginBottom="8px">
                    <strong>{missingRoleCount} invalid role(s).</strong> Role
                    must match a selection from the "Role" drop-down in the
                    template.
                  </Typography>
                )}
                {incorrectPercentage > 0 && (
                  <Typography>
                    <strong>{incorrectPercentage} invalid goal(s).</strong> Goal
                    must be a whole number between 1 and 100.
                  </Typography>
                )}
              </Box>
            </>
          )}
        </PrimaryUploadBox>
        <Box
          sx={{
            marginTop: '16px',
            padding: '8px',
            borderRadius: '4px',
            backgroundColor: isError() ? alpha(red3, 0.25) : yellow2
          }}
        >
          <Grid container>
            {isError() ? (
              <>
                <Grid item width="5%" paddingLeft="8px">
                  <WarningAmberIcon fontSize="small" color="error" />
                </Grid>
                <Grid item width="95%" paddingLeft="10px">
                  <Typography>
                    <strong> Error:</strong>
                  </Typography>
                  <Typography>
                    Review your file to correct these errors, then re-upload the
                    file.
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item width="5%" paddingLeft="8px">
                  <InfoOutlinedIcon fontSize="small" />
                </Grid>
                <Grid item width="95%" paddingLeft="10px">
                  <Typography>
                    <strong> Warning:</strong>
                  </Typography>
                  <Typography>
                    Review your file to correct these errors, then re-upload the
                    file. All rows with errors will be ignored if you proceed
                    as-is.
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </>
    )
  );
};

export default BulkUploadWarning;
