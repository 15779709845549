import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AggregationPeriod } from '../models/Productivity.models';
import { determineAvailablePeriods } from '../utils/productivityReport.utils';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import {
  setAggregationPeriod,
  setAvailablePeriods
} from '../stores/productivityAggregation.store';
import { gray13 } from '../../../common/constants/styleGuide';
import { fetchProductivityReport } from '../stores/productivityReport.store';
import { useProductivityReportStore } from '../hooks/useProductivityReportStore';
import { useAggregationPeriodStore } from '../hooks/useAggregatedPeriodStore';
import { useUIRouterState } from '../../../common/hooks/useUIRouterHistory';

export const ProductivityAggregationsButtons = () => {
  const { response } = useProductivityReportStore((state) => state);
  const period = useAggregationPeriodStore((state) => state.period);
  const availablePeriods = useAggregationPeriodStore(
    (state) => state.availablePeriods
  );
  const { aggregatePeriod } = useUIRouterState<{ aggregatePeriod: string }>()
    .params;
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);
  const refreshTimestamp = useRefreshStore((s) => s.refreshTimestamp);

  useEffect(() => {
    if (aggregatePeriod) {
      const typedPeriod =
        AggregationPeriod[aggregatePeriod as keyof typeof AggregationPeriod];
      if (typedPeriod) {
        setAggregationPeriod(typedPeriod);
      }
    }
  }, [aggregatePeriod]);

  useEffect(() => {
    fetchProductivityReport();
  }, [refreshTimestamp, period, reportFilters]);

  useEffect(() => {
    const newAvailablePeriods = determineAvailablePeriods(reportFilters);
    if (response?.data) {
      setAvailablePeriods(newAvailablePeriods);
    }
    if (!newAvailablePeriods.includes(period)) {
      setAggregationPeriod(AggregationPeriod.Day);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const handleButtonClick = (periodValue: AggregationPeriod) => {
    setAggregationPeriod(periodValue);
  };

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      {availablePeriods.map((periodValue) => (
        <Button
          key={periodValue}
          sx={{
            color: period === periodValue ? 'inherit' : gray13,
            '&:hover': {
              color: 'inherit'
            }
          }}
          onClick={() => handleButtonClick(periodValue)}
        >
          {periodValue}
        </Button>
      ))}
    </Box>
  );
};
