import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  blue9,
  gray5,
  heading6FontSize,
  mediumFontWeight
} from '../../../common/constants';
import { useTodaysProductivityOutlookState } from '../../hooks/useTodaysProductivityOutlookState';
import { useLastUpdate } from '../../hooks';
import { errorMsg, lastUpdateText } from '../../constants';
import { ModalLoadingView } from '../templates';
import { WidgetProps } from '../../models';
import { highlightNumbers } from '../../utils/TodaysProductivityOutlook.utils';
import { ErrorMessage } from '../templates/ErrorMessage';
import { newEvent } from '../../../common/analytics/eventHeap';

export const TodaysProductivityOutlook = (props: Readonly<WidgetProps>) => {
  const { reportFilters, refreshTimestamp } = props;
  const {
    init,
    summaryData,
    isLoading,
    isTileError,
    postTodaysProductivityOutlookFeedback
  } = useTodaysProductivityOutlookState();

  useEffect(() => {
    init(reportFilters?.groupId[0]);
    setFeedback(null);
  }, [init, reportFilters?.groupId, refreshTimestamp]);

  const lastUpdate = useLastUpdate(summaryData?.updateTime);
  const [feedback, setFeedback] = useState<null | boolean>(null);
  const [expanded, setExpanded] = useState(true);

  const handleFeedbackSubmit = (value: boolean) => {
    setFeedback(value);
    postTodaysProductivityOutlookFeedback(value);
    const feedbackParams = {
      'TPO Summary': summaryData?.output ?? '',
      'TPO Timestamp': summaryData?.updateTime ?? '',
      'TPO Positive Feedback': value
    };
    newEvent('TPO Feedback', feedbackParams);
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const betaBadgeStyles = () => ({
    position: 'relative',
    left: '3%',
    top: '12px',
    '& .MuiBadge-badge': {
      backgroundColor: blue9,
      color: '#ffffff',
      borderRadius: '5px',
      padding: '0px 5px'
    }
  });

  return (
    <Accordion
      id="cc8e8385-206f-40ed-a5fa-596a05e0730b"
      expanded={expanded}
      onChange={handleAccordionChange}
      elevation={0}
      sx={{ border: `1px solid ${gray5}`, height: '100%' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="AI-Summary-content"
        id="AI-Summary-header"
        sx={{
          fontSize: heading6FontSize,
          fontWeight: mediumFontWeight
        }}
      >
        Today's Productivity Outlook
        <Badge badgeContent={'Beta'} sx={betaBadgeStyles} />
      </AccordionSummary>
      {!isTileError && !isLoading && (
        <>
          <AccordionDetails sx={{ height: '72px', overflow: 'auto' }}>
            {highlightNumbers(summaryData?.output || '')}
          </AccordionDetails>
          <AccordionActions
            sx={{
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '16px',
              display: 'flex'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <Typography>Did you find this information useful?</Typography>
              <Box
                sx={{
                  margin: '0px !important',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <IconButton
                  id="d2258f34-2089-4fcc-9d21-df5ab5a721f6"
                  sx={{
                    pl: 0
                  }}
                  onClick={() => {
                    handleFeedbackSubmit(true);
                  }}
                >
                  {feedback === true ? (
                    <ThumbUpAltIcon />
                  ) : (
                    <ThumbUpOffAltIcon />
                  )}
                </IconButton>
                <IconButton
                  id="8998a79c-b98c-4950-aca4-ff8385db3ffc"
                  onClick={() => {
                    handleFeedbackSubmit(false);
                  }}
                >
                  {feedback === false ? (
                    <ThumbDownAltIcon />
                  ) : (
                    <ThumbDownOffAltIcon />
                  )}
                </IconButton>
              </Box>
            </Box>
            {lastUpdate && (
              <Typography
                sx={{
                  position: 'relative',
                  color: gray5,
                  top: '20px',
                  bottom: '16px'
                }}
              >
                {lastUpdateText} {lastUpdate}
              </Typography>
            )}
          </AccordionActions>
        </>
      )}
      {(isLoading || isTileError) && (
        <Box sx={{ padding: '0px 16px 32px 16px' }}>
          <ModalLoadingView
            isLoading={isLoading}
            isModalError={isTileError}
            errorMsg={
              <ErrorMessage
                msg={errorMsg}
                sx={{ pt: '34px', height: '141px' }}
              />
            }
            loaderHeight="141px"
          />
        </Box>
      )}
    </Accordion>
  );
};
