import { ColDef, IDatasource } from 'ag-grid-community';
import {
  getWorkingHoursColumns,
  resetWorkingHoursColumns,
  setWorkingHoursColumns
} from '../stores/workingHoursColumns.store';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import {
  handleAllCheckbox,
  mapColDefToColumnState,
  mapColumnStateToGridColumnFilter
} from '../../../common/components/ReportFilters/utils/gridColumnsFilter.utils';
import {
  IGridColumnFilter,
  IReportColumnLabel
} from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { createBaseReportFilterParams } from '../../../common/components/ReportFilters/utils/reportFilter.utils';
import { convertObjectToQs } from '../../../common/utils/parse/parse';
import { fetchReportingData } from '../../../common/helpers';
import { IWorkingHoursUsersDto } from '../models/WorkingHours.models';
import { setExportParamsStore } from '../../../common/stores/exportsStore/exportParamsStore';
import { IExportReportParams } from '../../../common/stores/exportsStore/exportStore.models';
import { ReportType } from '../../../common/enums/ReportType';
import { getWorkingColumns } from './workingHoursColumns.utils';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { AtConsoleLogger } from '../../../common/utils/consoleLogger/AtConsoleLogger';
import {
  getWorkingHoursExportExcludeColumns,
  setWorkingHoursExportExcludeColumns
} from '../stores/workingHoursExportExcludeColumns.store';
import { WORKING_HOURS_EXPORT_EXCLUDE_COLUMNS_MAP } from '../constants/workingHours.constants';

//use the sort order from the ColumnState
export const mapWHColumnsToColumnState = (
  newColumns: ColDef[],
  view: ReportViewTypes
): void => {
  //get current saved state directly from store
  const currentColumnState = getWorkingHoursColumns()[view];

  const newColumnsStates = currentColumnState.map((currentCol) => {
    if (!currentCol) return;

    const currentDefaultColId = currentCol.colId;

    const newColumnState = newColumns.find(
      (newCol) => newCol.colId === currentDefaultColId
    );
    if (!newColumnState) return;

    return mapColDefToColumnState(newColumnState);
  });

  //update state directly to store
  setWorkingHoursColumns({ [view]: newColumnsStates });
};

//use the sort order from the Column Selector labels
export const mapWHColumnFiltersToColumnLabels = (
  view: ReportViewTypes,
  labels: IReportColumnLabel[]
): IGridColumnFilter[] => {
  const sortedDefaultColumns = labels?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const currentColumnState = getWorkingHoursColumns()[view];

  const cols = sortedDefaultColumns?.map((col) => {
    return mapColumnStateToGridColumnFilter(col, currentColumnState);
  });

  //Map current state to Export's excludeColumns array
  const excludeColumns = cols
    .filter((col) => col.colId !== 'all' && col.hide)
    .map((col) => WORKING_HOURS_EXPORT_EXCLUDE_COLUMNS_MAP[col.colId]);
  setWorkingHoursExportExcludeColumns(excludeColumns);

  return handleAllCheckbox(cols);
};

export const getValidatedWorkingColumns = (): ColDef[] => {
  const defaultColumns: ColDef[] = getWorkingColumns(); //allowed columns
  const { view: currentView } = getReportView(); //current view
  const currentColumnState = getWorkingHoursColumns()[currentView]; //current persisted column state

  //SIMPLE VALIDATION: If length of default columns does not equal the length of the persisted column state, reset the persisted column state
  if (defaultColumns.length !== currentColumnState.length) {
    resetWorkingHoursColumns();
  }
  return defaultColumns;
};

export const getDataSource = (gridRef, notificationService): IDatasource => {
  const pageSize = 150;

  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: async (params) => {
      gridRef.current.api.hideOverlay();
      const page = Math.ceil(params.endRow / pageSize);

      setTimeout(async () => {
        const { to, from, userId, computerId, groupId, userMode } =
          createBaseReportFilterParams();

        // TODO: Remove this check when computer based filtering is supported
        if (computerId || userMode === 'computers') {
          notificationService.warning(
            'Computer based filtering is not currently supported for Working Hours Report. Please update your filter to a user or user group.'
          );
          gridRef.current.api.setGridOption('loading', false);
          params.failCallback();
          gridRef.current.api.showNoRowsOverlay();
          return;
        }

        const reportFiltersStr = convertObjectToQs({
          to,
          from,
          userId,
          computerId,
          groupId,
          pageSize,
          page
        });

        gridRef.current.api.setGridOption('loading', true);

        try {
          const response = await fetchReportingData<IWorkingHoursUsersDto>({
            path: '/reports/v3/workinghours?' + reportFiltersStr
          });
          const data = response?.data;
          const total = response?.total;

          const exportParams = {
            hasDataForTable: total > 0
          };

          setExportParamsStore(exportParams);

          if (data.length === 0) {
            gridRef.current.api.setGridOption('loading', false);
            gridRef.current.api.showNoRowsOverlay();
          }
          params.successCallback(data, total);
        } catch (error) {
          params.failCallback();
          notificationService.error('Unable to load Working Hours data');
          AtConsoleLogger(
            'ActivTrak Error: Error fetching Working Hours data: ',
            error
          ).error();
        } finally {
          gridRef.current.api.setGridOption('loading', false);
        }
      }, 0);
    }
  };
  return dataSource;
};

export const createExportPayload = (): IExportReportParams => {
  const { from, groupId, to, userType, userId, computerId, userMode } =
    createBaseReportFilterParams();

  return {
    type: ReportType.WorkingHours,
    startDate: from,
    endDate: to,
    userType,
    userMode,
    userId: groupId ?? userId ?? computerId,
    excludeColumns: getWorkingHoursExportExcludeColumns().excludeColumns ?? []
  };
};
