import { createStore } from 'zustand';
import { IDateSettingsStore } from '../models/IDateSettingsStore';
import { fetchSettings } from '../utils/dateSettingsStore.utils';

export const DATE_SETTING_STORE_DEFAULT: IDateSettingsStore = {
  timezoneLabel: '',
  timezoneKey: '',
  isDateFormatDDMM: false,
  isTimeFormat24Hour: false,
  showLocalTimezone: true
};

/**
 * Creates a date settings store.
 */
export const dateSettingsStore = createStore<IDateSettingsStore>(() => {
  return DATE_SETTING_STORE_DEFAULT;
});

export const fetchDateSettings = async (): Promise<IDateSettingsStore> => {
  const response = await fetchSettings();
  const current = dateSettingsStore.getState();
  dateSettingsStore.setState({ ...current, ...response });
  return dateSettingsStore.getState();
};

export const getDateSettingsStore = (): IDateSettingsStore =>
  dateSettingsStore.getState();

export const setDateSettingsStore = (
  newSettings: Partial<IDateSettingsStore>
): void => {
  const currentSettings = dateSettingsStore.getState();
  dateSettingsStore.setState({ ...currentSettings, ...newSettings });
};

/**
 * Resets the date settings store to default state.
 */
export const resetDateSettingsStore = (): void => {
  dateSettingsStore.setState(DATE_SETTING_STORE_DEFAULT);
};
