import React from 'react';
import Box from '@mui/material/Box';
import { ProductivityLegendsProps } from '../models/Productivity.models';
import { useReportViewStore } from '../../../common/components/ReportFilters/hooks/useReportViewToggleStore';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';

export const ProductivityLegends = (props: ProductivityLegendsProps) => {
  const { legendItems } = props;
  const currentView = useReportViewStore((state) => state.view);

  const formatLegendLabel = (label: string): string => {
    const words = label.split(' ');
    if (currentView === ReportViewTypes.SUMMARY) {
      return label;
    } else {
      return `${words[0]}\n${words.slice(1).join(' ')}`;
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      {legendItems.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            mx: 1,
            opacity: item.hidden ? 0.5 : 1
          }}
          onClick={item.onClick}
        >
          {item.icon}
          <span style={{ whiteSpace: 'pre-line' }}>
            {formatLegendLabel(item.label)}
          </span>
        </Box>
      ))}
    </Box>
  );
};
