export enum StoreCacheName {
  AccountPlanStore = 'accountPlanStore'
}

export interface StoreCache {
  storeName: StoreCacheName;
  fetchData: () => void;
  lifetime: number;
  timeoutId?: NodeJS.Timeout;
}

export type CachedStoreStateType<TEntity> = {
  state?: TEntity;
};

export type CachedStoreStateActionsType<TEntity> = {
  fetch: () => Promise<TEntity>;
  reset: () => void;
  refresh: () => Promise<TEntity>;
};

export type CachedStoreType<TEntity> = CachedStoreStateType<TEntity> &
  CachedStoreStateActionsType<TEntity>;
