import moment from 'moment';
import { atHelperFunctions } from '../../../../_app/serviceFunctions/atHelperFunctions';
import { parseJson } from '../../common/utils/parse/parse';

interface IGlobals {
  currentUser?: {
    token: string;
    username: string;
  }
}

// TODO: This is now supporting the whole app - not only support-portal
// Relocate?
export const decodeToken = (token: string) => {
  if (!token || typeof token !== 'string' || token === 'invalid')  {
    return {};
  }

  const base64Url = token.split('.')[1];
  if (!base64Url) {
    console.error(
      `Support Portal: Invalid token`
    );
    return {};
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload;

  try {
    jsonPayload = decodeURIComponent(
      // Adding window here since atob() itself is deprecated
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  } catch (error) {
    console.error(`Support Portal: ${error}`);
    return {};
  }

  try {
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error(
      `Support Portal: Cannot set user token due to invalid Json Object. ${error}`
    );
    return {};
  }
};

export const getTokenExpiration = (userToken) => {
  if (userToken?.exp) {
    return moment.unix(userToken.exp);
  }

  return moment.unix(0);
};

export const getBackupObjectString = () => {
  return window.localStorage.getItem('activTrak.support.backup') || '';
};

export const getBackupToken = () => {
  const backupObject = parseJson<IGlobals>(getBackupObjectString());
  return backupObject?.currentUser?.token;
};

export const hasBackupObject = () => {
  return !atHelperFunctions.isEmpty(getBackupObjectString());
};
