'use strict';

/*
 * Used to auto resize virtual scroll grids when the view is resized.
 * Options to disable auto-resize and set a minimum height.
 * The resizeGrid function is attached to the grid object allow for manual resize calls.
 */

angular.module('app').directive('atGridAutoSize', atGridAutoSize);

atGridAutoSize.$inject = ['$window', 'atHelperFunctions'];

function atGridAutoSize($window, atHelperFunctions) {
    return {
        link: function($scope, element, attr) {
            var grid;
            var headerHeight = $scope[attr.atHeaderHeight];
            var minHeight = $scope[attr.atMinHeight];
            var changingState = false;
            var disableResize = false;

            var resizeGrid = function() {
                if (!!grid && !changingState && !disableResize) {
                    var height =
                        typeof headerHeight === 'function'
                            ? headerHeight()
                            : headerHeight;
                    var minimum =
                        typeof minHeight === 'function'
                            ? minHeight()
                            : minHeight;
                    var gridElement = angular.element(grid.element);
                    var dataArea = gridElement.find('.k-grid-content');
                    var newHeight = atHelperFunctions.getGridHeight(
                        height,
                        minimum
                    );
                    var diff =
                        gridElement.innerHeight() - dataArea.innerHeight();
                    gridElement.height(newHeight);
                    dataArea.height(newHeight - diff);

                    if (
                        grid &&
                        grid.element &&
                        typeof grid.resize === 'function'
                    ) {
                        grid.resize();
                    }
                }
            };

            // Resizing can be disabled by passing boolean in.
            $scope.$watch(
                function() {
                    return $scope[attr.atGridAutoSize];
                },
                function(disable) {
                    disableResize = disable;
                }
            );

            // Bind window resizing.
            angular.element($window).bind('resize', function() {
                resizeGrid();
            });

            // Add resizeGrid function to attached grid for use in controllers
            $scope.$watch(
                function() {
                    return (
                        $scope[attr.kendoGrid] ||
                        $scope[attr.kendoCustomGrid] ||
                        $scope[attr.kendoListView]
                    );
                },
                function() {
                    grid =
                        $scope[attr.kendoGrid] ||
                        $scope[attr.kendoCustomGrid] ||
                        $scope[attr.kendoListView];
                    if (grid) {
                        grid.resizeGrid = function() {
                            resizeGrid();
                        };
                    }
                }
            );
        }
    };
}
