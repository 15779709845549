import React, { useCallback, useEffect, useState } from 'react';
import {
  getButtonValue,
  getPresetDateRangeList,
  getCustomDates,
  isCustomAllowed,
  getDateInAccountTimeZone,
  getAllDateRangeList,
  createDatesObject
} from '../utils/dateRange.utils';
import { ReportDatePicker } from './ReportDatePicker';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { TabPanel } from '../../Tabs/TabPanel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { BaseTooltipSC } from '../styles';
import { DateRanges } from '../constants/dateRanges';
import {
  DateRangeFilterProps,
  LitepickerDate
} from '../models/IDateRangePicker';
import moment from 'moment';
import { getAccountSetting } from '../../../stores/accountSettingsStore/accountSettingsStore';
import { PresetGroupList } from './PresetGroupList';

export const DateRangeFilter = (props: DateRangeFilterProps) => {
  const { dates, ignoreRestrictions = false, onSubmitFilter } = props;
  const { range: dateRange, getDates } = dates;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [buttonValue, setButtonValue] = useState<string>();
  const [tab, setTab] = useState(0);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const presetDateRanges = ignoreRestrictions
    ? getAllDateRangeList()
    : getPresetDateRangeList();
  const customAllowed = ignoreRestrictions || isCustomAllowed();
  const customDates = getCustomDates(endDate, ignoreRestrictions);
  const currentIanaTimeZone = getAccountSetting('currentIanaTimeZone');

  useEffect(() => {
    const { fromDate, toDate } = getDates();
    if (fromDate) {
      setStartDate(
        getDateInAccountTimeZone(fromDate, currentIanaTimeZone).toDate()
      );
    }

    if (toDate) {
      setEndDate(
        getDateInAccountTimeZone(toDate, currentIanaTimeZone).toDate()
      );
    }

    setTab(dateRange === DateRanges.Custom && customAllowed ? 1 : 0);
  }, [currentIanaTimeZone, dateRange, getDates, customAllowed]);

  useEffect(() => {
    const label = getButtonValue(dateRange, moment(startDate), moment(endDate));
    setButtonValue(label);
  }, [startDate, endDate, dateRange]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCustomSubmit = useCallback(
    (start: LitepickerDate, end: LitepickerDate) => {
      onSubmitFilter({
        dates: createDatesObject(
          DateRanges.Custom,
          moment(start.dateInstance),
          moment(end.dateInstance)
        ),
        fromTimestamp: null
      });

      handleClose();
    },
    [onSubmitFilter]
  );

  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    newTab: number
  ) => {
    setTab(newTab);
  };

  if (!presetDateRanges?.length && !customAllowed) return <></>;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <BaseTooltipSC
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title={
            <>
              <Tabs value={tab} onChange={handleTabChange}>
                {presetDateRanges?.length && (
                  <Tab
                    id="b2e93e43-eeb0-4165-badb-5f771245e6ff"
                    label="Preset Ranges"
                    key="daterange-tab-0"
                  />
                )}
                {customAllowed && (
                  <Tab
                    id="d0b73c12-7d64-4dd9-aa1a-906a60494d72"
                    label="Custom Range"
                    key="daterange-tab-1"
                  />
                )}
              </Tabs>
              {presetDateRanges?.length && (
                <TabPanel value={tab} index={0}>
                  <PresetGroupList
                    dateRange={dateRange}
                    onSubmitFilter={onSubmitFilter}
                    handleClose={handleClose}
                    ignoreRestrictions={ignoreRestrictions}
                  />
                </TabPanel>
              )}
              {customAllowed && (
                <TabPanel value={tab} index={1}>
                  <Box sx={{ margin: '8px 16px' }}>
                    <ReportDatePicker
                      startDate={startDate}
                      endDate={endDate}
                      customDates={customDates}
                      onSubmit={handleCustomSubmit}
                    />
                  </Box>
                </TabPanel>
              )}
            </>
          }
        >
          <Button
            variant="outlined"
            onClick={() => setIsOpen(!isOpen)}
            endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            {buttonValue}
          </Button>
        </BaseTooltipSC>
      </div>
    </ClickAwayListener>
  );
};
