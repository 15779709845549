import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from 'react';
import { useAuthorization } from '../../common/services/Authorization';
import { useNotifications } from '../../common/services/Notifications';
import { SortDirection } from '../../common/enums/SortDirection';
import { sorter } from '../../common/utils/sorter/sorter';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { InsightsProvider, UseInsightsContext } from '../services';
import { IManagerSchedule, ISchedule } from '../models';
import {
  dropDownConstants,
  INITAL_VIEW_STATE,
  filterAndRepeatManagerScheduleDataBySpecificParamsAndQuery,
  filterManagerScheduleDataBySpecificParamsAndQuery
} from '../utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton/DropdownOptionsButton';
import { Grid, Box, Button } from '@mui/material';
import { GridRowActionButtonContainer } from '../styles/SubscriptionManager.view.styles';
import DeleteIcon from '@mui/icons-material/Delete';

import { ViewSubscriptionModal } from '../components/ViewSubscriptionModal';
import { ReassignSubscriptionModal } from '../components/ReassignSubscriptionModal';
import { DeleteSubscriptionsConfirmationModal } from '../components/DeleteSubscriptionsConfirmationModal';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { PermissionLevel } from '../../common/enums';

export default function SubscriptionManagerView() {
  const [schedule, setSchedule] = useState<ISchedule>();
  const [managerSchedule, setManagerSchedule] = useState<IManagerSchedule>();
  const [managerSchedules, setManagerSchedules] = useState<IManagerSchedule[]>(
    INITAL_VIEW_STATE.managerSchedules
  );
  const [lookerUsers, setLookerUsers] = useState<IManagerSchedule[]>();
  const [selectedManagerSchedules, setSelectedManagerSchedules] = useState([]);
  const [viewIsLoading, setViewIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(INITAL_VIEW_STATE.searchTerm);
  const [
    openDeleteSubscriptionsConfirmationModal,
    setOpenDeleteSubscriptionsConfirmationModal
  ] = useState<boolean>(false);
  const [openViewSubscriptionModal, setOpenViewSubscriptionModal] =
    useState<boolean>(false);
  const [openReassignSubscriptionModal, setOpenReassignSubscriptionModal] =
    useState<boolean>(false);
  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState();

  const notificationService = useNotifications();
  const { insightsService } = UseInsightsContext();
  const authorizationService = useAuthorization();

  const isReadOnly = useRef<boolean>(
    !authorizationService.hasAuthorizationLevel(
      [PermissionLevel.Edit],
      'app.reports.insightsSubscriptionMgmt'
    )
  ).current;

  const deleteSubscriptionCallbackRef = useRef(() => {
    /* no-op */
  });

  const refreshManagerSchedules = useCallback(() => {
    setViewIsLoading(true);
    insightsService
      .getAllSchedules()
      .then((response: IManagerSchedule[]) => {
        setManagerSchedules(response);
      })
      .catch(() => {
        console.error('ActivTrak Error: Error fetching subscriptions');
        notificationService.error('Error with subscription');
      })
      .finally(() => {
        setViewIsLoading(false);
      });
  }, [insightsService, notificationService]);

  useEffect(() => {
    refreshManagerSchedules();
  }, [refreshManagerSchedules]);

  const filteredManagerSchedules = useMemo(() => {
    const filteredSchedules = filterManagerScheduleDataBySpecificParamsAndQuery(
      managerSchedules,
      INITAL_VIEW_STATE.searchBarFilterParams,
      searchTerm
    );
    return sorter(filteredSchedules, orderDirection, orderBy);
  }, [managerSchedules, searchTerm, orderDirection, orderBy]);

  const dropDownButtonOptions = INITAL_VIEW_STATE.dropDownButtonOptions;

  if (isReadOnly) {
    dropDownButtonOptions.splice(1, dropDownButtonOptions.length);
  }

  const subscriptionManagerGridColumns: IGridColumn[] = [
    {
      field: 'ownerName',
      fieldName: 'Owner',
      headerName: `Owner`,
      type: 'string',
      isSortable: true,
      width: '15%'
    },
    {
      field: 'dashboardId',
      fieldName: 'Dashboard',
      headerName: 'Dashboard',
      type: 'string',
      isSortable: true,
      width: '31%'
    },
    {
      field: 'subscriptionName',
      fieldName: 'Subscription Name',
      headerName: 'Subscription Name',
      type: 'string',
      isSortable: true,
      width: '40%'
    },
    {
      width: '4%',
      type: 'string',
      template: (dataItem) => {
        const { selected } = dataItem;
        return (
          <Box>
            <GridRowActionButtonContainer sx={{ textAlign: 'right' }}>
              <DropdownOptionsButton
                id="f6e895cf-d196-4ad2-b07b-27a96347d66a"
                icon={<MoreVertIcon />}
                dropDownOptions={dropDownButtonOptions}
                onChangeOption={(e, option) => {
                  const { label } = option;
                  switch (label) {
                    case dropDownConstants.View: {
                      setScheduleAndOpenViewSubscriptionModal(dataItem);
                      break;
                    }
                    case dropDownConstants.Reassign: {
                      setManagerScheduleAndUsersAndOpenReassignSubscriptionModal(
                        dataItem
                      );
                      break;
                    }
                    case dropDownConstants.Delete: {
                      deleteSubscriptionCallbackRef.current = async () => {
                        await deleteSubscriptions([dataItem]);
                      };
                      setOpenDeleteSubscriptionsConfirmationModal(true);
                    }
                  }
                }}
                hideSelectedOption
                disableSelectedItem
                disabled={selected}
              />
            </GridRowActionButtonContainer>
          </Box>
        );
      }
    }
  ];

  const setScheduleAndOpenViewSubscriptionModal = (
    managerSchedule: IManagerSchedule
  ) => {
    setViewIsLoading(true);
    insightsService
      .getScheduleById(managerSchedule)
      .then((response: ISchedule) => {
        setSchedule(response);
        setOpenViewSubscriptionModal(true);
      })
      .catch(() => {
        console.error(
          'ActivTrak Error: Error with subscription: ' +
            managerSchedule.subscriptionId
        );
        notificationService.error('Error with subscription');
      })
      .finally(() => {
        setViewIsLoading(false);
      });
  };

  const setManagerScheduleAndUsersAndOpenReassignSubscriptionModal = (
    managerSchedule: IManagerSchedule
  ) => {
    setViewIsLoading(true);
    setManagerSchedule(managerSchedule);
    insightsService
      .getAllUsers()
      .then((response: IManagerSchedule[]) => {
        setLookerUsers(response);
        setOpenReassignSubscriptionModal(true);
      })
      .catch(() => {
        notificationService.error('Error fetching subscriptions');
      })
      .finally(() => {
        setViewIsLoading(false);
      });
  };

  const reassignSubscription = async (
    subscriptionId: string,
    toOwnerId: string
  ) => {
    setViewIsLoading(true);
    try {
      await insightsService.reassignSchedule(subscriptionId, toOwnerId);
      refreshManagerSchedules();
      notificationService.success(
        `Success! Subscription successfully reassigned.`
      );
    } catch (response) {
      notificationService.error('Error reassigning subscription');
      console.error(
        'ActivTrak Error: Error reassigning insights subscription: ' +
          subscriptionId
      );
    } finally {
      setViewIsLoading(false);
    }
  };

  const handleGridDataChange = (selected) => {
    setSelectedManagerSchedules(selected);
  };

  const deleteSubscriptions = async (managerSchedules: IManagerSchedule[]) => {
    setViewIsLoading(true);
    try {
      await insightsService.deleteSchedules(managerSchedules);
      refreshManagerSchedules();
      notificationService.success(
        `Success! ${managerSchedules.length} subscription(s) deleted.`
      );
    } catch (response) {
      notificationService.error('Error deleting subscription(s)');
      const ids = managerSchedules.map((ms) => ms.subscriptionId).join(', ');
      console.error(
        'ActivTrak Error: Error deleting insights subscription(s): ' + ids
      );
    } finally {
      setViewIsLoading(false);
    }
  };

  const handleDeleteSubscriptionsClick = async () => {
    deleteSubscriptionCallbackRef.current = async () => {
      setViewIsLoading(true);
      try {
        await deleteSubscriptions(selectedManagerSchedules);
        setSelectedManagerSchedules([]);
      } catch (response) {
        notificationService.error('Error deleting subscription(s)');
        const ids = selectedManagerSchedules
          .map((ms) => ms.subscriptionId)
          .join(', ');
        console.error(
          'ActivTrak Error: Error deleting insights subscription(s): ' + ids
        );
      } finally {
        setViewIsLoading(false);
      }
    };
    setOpenDeleteSubscriptionsConfirmationModal(true);
  };

  const getFilteredManagerSchedulesForAutocomplete = () => {
    const filteredData =
      filterAndRepeatManagerScheduleDataBySpecificParamsAndQuery(
        managerSchedules,
        subscriptionManagerGridColumns,
        INITAL_VIEW_STATE.searchBarFilterParams,
        searchTerm
      );

    const newDataMap = {};
    const uniqueGroups = {};
    for (let i = 0; filteredData.length > i; i++) {
      const { id, type, label } = filteredData[i];
      if (type !== INITAL_VIEW_STATE.searchBarFilterParams[0]) {
        newDataMap[id + type] = filteredData[i];
      } else {
        if (!uniqueGroups[label]) {
          newDataMap[id + type] = filteredData[i];
          uniqueGroups[label] = true;
        }
      }
    }

    return Object.keys(newDataMap).map(function (key) {
      return newDataMap[key];
    });
  };

  const handleChangeAutocompleteSearchBar = (value) => {
    setSearchTerm(value);
  };

  const onSortOrder = useCallback((newOrderDirection, newOrderBy) => {
    setOrderBy(newOrderBy);
    setOrderDirection(newOrderDirection);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={6} lg={6}>
          <Button
            id="id_16ed26cc-c6a0-401e-af10-0504ac53b327"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteSubscriptionsClick}
            disabled={!selectedManagerSchedules.length || isReadOnly}
            color="secondary"
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <AutocompleteSearchBar
            autocompleteOptions={getFilteredManagerSchedulesForAutocomplete()}
            searchLabelPlaceholder="Search by Owner, Dashboard, or Subscription"
            onChange={handleChangeAutocompleteSearchBar}
          />
        </Grid>
        <Grid item xs={12}>
          {(viewIsLoading || filteredManagerSchedules.length > 0) && (
            <CustomGrid
              isVirtualized={true}
              isLoading={viewIsLoading}
              data={filteredManagerSchedules}
              columns={subscriptionManagerGridColumns}
              uniqueRowProp="subscriptionId"
              hasBulkSelection={!isReadOnly}
              onSelectAllClick={handleGridDataChange}
              initialSortField="ownerName"
              initialSortDirection={SortDirection.Ascending}
              onSortOrder={onSortOrder}
            />
          )}
          {!viewIsLoading && filteredManagerSchedules.length == 0 && (
            <span>{'No subscriptions found.'}</span>
          )}
        </Grid>
      </Grid>
      <DeleteSubscriptionsConfirmationModal
        open={openDeleteSubscriptionsConfirmationModal}
        onClose={() => setOpenDeleteSubscriptionsConfirmationModal(false)}
        onDelete={deleteSubscriptionCallbackRef.current}
        subscriptionsCount={
          !selectedManagerSchedules.length ? 1 : selectedManagerSchedules.length
        }
      />
      <ViewSubscriptionModal
        open={openViewSubscriptionModal}
        onClose={() => setOpenViewSubscriptionModal(false)}
        schedule={schedule}
      />
      <ReassignSubscriptionModal
        open={openReassignSubscriptionModal}
        onClose={() => setOpenReassignSubscriptionModal(false)}
        onReassign={reassignSubscription}
        managerSchedule={managerSchedule}
        users={lookerUsers}
      />
    </>
  );
}

export const SubscriptionManagerViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <InsightsProvider>
      <SubscriptionManagerView />
    </InsightsProvider>
  </ReactivTrakComponentWrapper>
);
