import { setUserToken } from '../../../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { invalidateBackupToken } from '../../../../_reactivtrak/src/common/hooks/useImpersonate';

export function handleLogOut() {
  try {
    invalidateBackupToken();
    setUserToken('invalid');

    const state = window.history.state;

    window.history.replaceState(state, "", "/#/login");
    window.location.reload();

  } catch (e) {
    throw new Error('Could not perform logout action, please try again later');
  }
}
