export const defaultLogSettings = {
  off: [
    { name: 'Activtrak', level: 'OFF' },
    { name: 'ActivtrakLib', level: 'OFF' },
    { name: 'Svctcom', level: 'OFF' },
    { name: 'Scthost', level: 'OFF' },
    { name: 'ActivTrak.UserManagement.UserProxy', level: 'CRITICAL' },
    { name: 'ActivTrak.Database.SqliteConnection', level: 'ERROR' },
    { name: 'ActivTrak.Alarms.UserAlarmManager', level: 'ERROR' },
    { name: 'ActivTrak.Alarm.AlarmResponse.FromString', level: 'CRITICAL' },
    { name: 'ActivTrak.Alarms.AlarmGenerator', level: 'CRITICAL' }
  ],
  on: [
    { name: 'Activtrak', level: 'TRACE' },
    { name: 'ActivtrakLib', level: 'TRACE' },
    { name: 'Svctcom', level: 'TRACE' },
    { name: 'Scthost', level: 'TRACE' }
  ]
};
