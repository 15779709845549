'use strict';

angular.module('app').service('exportInfoService', ExportInfoService);

ExportInfoService.$inject = ['$transitions'];

function ExportInfoService($transitions) {

    var service = this;
    var _exportInfo;
    var _getSecondaryParameters;
    var _getSortParameters;
    var _getCacheKey;
    var _isExporting = false;
    var _mainDataSource;

    service.getExportInfo = function() {
        return _exportInfo;
    };

    service.setExportInfo = function(exportInfo) {
        _exportInfo = exportInfo;
    };

    service.getSecondaryParameters = function() {
        if (typeof _getSecondaryParameters !== 'function') {
            return null;
        }
        return _getSecondaryParameters();
    };

    service.getSortParameters = function() {
        if (typeof _getSortParameters !== 'function') {
            return null;
        }
        return _getSortParameters();
    };

    service.getCacheKey = function() {
        if (typeof _getCacheKey !== 'function') {
            return null;
        }
        return _getCacheKey();
    };

    service.getIsExporting = function() {
        return _isExporting;
    };

    service.setIsExporting = function(value) {
        _isExporting = value;
    };

    service.getMainDataSource = function() {
        return _mainDataSource;
    };

    service.setMainDataSource = function(source) {
        _mainDataSource = source;
    };

    service.setExportInfo = function(exportInfo) {
        _mainDataSource = exportInfo.mainDataSource;
        _getCacheKey = exportInfo.getCacheKey;
        _getSecondaryParameters = exportInfo.getSecondaryParameters;
        _getSortParameters = exportInfo.getSortParameters;
    };

    $transitions.onStart({}, () => {
        _mainDataSource = null;
        _getCacheKey = null;
        _getSecondaryParameters = null;
        _getSortParameters = null;
    });
}

