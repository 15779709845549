import { filter, find, map, uniqBy } from 'lodash';

angular.module('app').service('redactionTypesService', RedactionTypesService);

RedactionTypesService.$inject = ['$q', 'screenshotsRedactionService', 'notificationService'];

function RedactionTypesService($q, screenshotsRedactionService, notificationService) {
    var service = this;

    // Define infoTypeList Class
    function infoTypeList(infoTypes) {
        return {
            infoTypes: infoTypes || [],

            // Sort InfoTypes by given region
            sortByRegion: function () {
                this.infoTypes = this.infoTypes.sort(function (a, b) {
                    // Bring Global and United States to top of sort
                    if (a.region === 'Global' && b.region == 'Global') {
                        return a.displayName.localeCompare(b.displayName);
                    } else if (a.region === 'Global') {
                        return -1;
                    } else if (b.region === 'Global') {
                        return 1;
                    }

                    if (a.region === 'United States' && b.region == 'United States') {
                        return a.displayName.localeCompare(b.displayName);
                    } else if (a.region === 'United States') {
                        return -1;
                    } else if (b.region === 'United States') {
                        return 1;
                    }

                    // Compare display name if region matches
                    if (a.region === b.region) {
                        return a.displayName.localeCompare(b.displayName);
                    }

                    // Move Other to bottom of sort.
                    if (a.region === 'Other') {
                        return 1;
                    } else if (b.region === 'Other') {
                        return -1;
                    }

                    // Else, sort by region name
                    return a.region.localeCompare(b.region);
                });
                return this;
            },

            // Inject region to rows for grids
            injectRegionRows: function () {
                var injectedRegions = [];
                var currentRegion = '';
                this.infoTypes.forEach(function (type) {
                    if (type.region !== currentRegion) {
                        injectedRegions.push({
                            region: type.region,
                            displayName: type.region,
                            isRegionRow: true
                        });
                        currentRegion = type.region;
                    }
                    injectedRegions.push(type);
                });

                this.infoTypes = injectedRegions;
                return this;
            }
        };
    }

    // Get InfoTypes by region or all
    function getInfoTypes(region) {
        if (region) {
            return infoTypeList(
                filter(dynamicInfoTypes, function (type) {
                    return type.region === region;
                })
            );
        }

        return infoTypeList(dynamicInfoTypes);
    }

    // Get single InfoType by name.  Return single field if given.
    function getInfoType(name, field) {
        var infoType = find(dynamicInfoTypes, function (type) {
            return type.name === name;
        });

        // If InfoType does not exist, log error for tracking and create one based on the name.
        if (!infoType) {
            console.error('Missing dynamic info type with name: ', name);
            return {
                region: 'Other',
                name: name,
                displayName: name,
                description: ''
            };
        }

        if (field) {
            return (infoType && infoType[field]) || name;
        }

        return infoType;
    }

    // Get list of InfoType regions.
    function getRegions(customSort) {
        var regions = map(uniqBy(dynamicInfoTypes, 'region'), function (type) {
            return type.region;
        });

        // Define sort function based on given function or default.
        var sortFunction =
            typeof customSort === 'function'
                ? customSort
                : function (a, b) {
                      // Bring Global and United States to top of sort
                      if (a === 'Global') {
                          return -1;
                      } else if (b === 'Global') {
                          return 1;
                      }

                      if (a === 'United States') {
                          return -1;
                      } else if (b === 'United States') {
                          return 1;
                      }

                      // Move Other to bottom of sort.
                      if (a === 'Other') {
                          return 1;
                      } else if (b === 'Other') {
                          return -1;
                      }

                      return a.localeCompare(b);
                  };

        return regions.sort(sortFunction);
    }

    // Initialize Service.  Get Dynamic InfoTypes
    service.initialize = function () {
        var deferred = $q.defer();

        screenshotsRedactionService
            .getInfoTypes()
            .then(function (result) {
                var data = (result.data && result.data.redactionInfoTypeList) || [];

                dynamicInfoTypes = [];
                data.forEach(function (dynamicType) {
                    if (
                        !dynamicType.name.includes('DOCUMENT') &&
                        (!dynamicType.region || dynamicType.region === 'Other')
                    ) {
                        console.warn('ActivTrak Warning: Missing static info type with name: ', dynamicType.name);
                        dynamicType.region = 'Other';
                    }

                    dynamicInfoTypes.push(dynamicType);
                });

                deferred.resolve();
            })
            .catch(function () {
                // Set dynamic types to static types if error occurs trying to get dynamic.  Error will automatically be logged.
                notificationService.showNotification(
                    'An error occurred while loading the redaction info types.',
                    'danger'
                );
                deferred.resolve();
            });

        return deferred.promise;
    };

    service.getInfoTypes = function (region) {
        return getInfoTypes(region);
    };

    service.getRegions = function (customSort) {
        return getRegions(customSort);
    };

    // Create InfoType list based on given name array
    service.getInfoTypeDefinitions = function (infoTypeNames) {
        var typeList = new infoTypeList();

        if (!infoTypeNames) {
            return typeList;
        }

        infoTypeNames.forEach(function (name) {
            typeList.infoTypes.push(getInfoType(name));
        });

        return typeList;
    };

    // Define lists
    var dynamicInfoTypes = [];
}
