'use strict';

angular.module('app').service('accessApiService', AccessApiService);

AccessApiService.$inject = ['$http', 'envConfig'];

function AccessApiService($http, envConfig) {
    this.getGroups = function () {
        return $http.get(envConfig.apiUrl() + '/api/settings/groups', {
            ignoreLoadingBar: true
        });
    };

    this.getAccounts = function () {
        return $http.get(envConfig.apiUrl() + '/api/settings/viewableAccounts', {
            ignoreLoadingBar: true
        });
    };

    this.getUsers = function () {
        return $http.get(envConfig.apiUrl() + '/api/settings/access/users', {
            ignoreLoadingBar: true
        });
    };

    this.validateMember = function (userName) {
        return $http.post(envConfig.apiUrl() + '/api/settings/access/users/validate', { userName: userName });
    };

    this.setUserSsoEnabled = function (userId, ssoEnabled) {
        return $http.patch(envConfig.apiUrl() + '/api/settings/access/users/' + userId + '/ssoenabled', ssoEnabled);
    };

    this.savePassword = function (payload) {
        return $http.put(envConfig.apiUrl() + '/api/settings/access/password', payload);
    };

    this.setAdmin = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/access/admin', payload);
    };

    this.setUserRole = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/access/changeUserRole', payload);
    };

    this.createUsers = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/access/users', payload);
    };

    this.deleteUser = function (payload) {
        return $http.post(envConfig.apiUrl() + '/api/settings/access/users/delete', payload);
    };

    this.saveGroups = function (payload) {
        return $http.put(envConfig.apiUrl() + '/api/settings/access/groups', payload);
    };

    this.changePassword = function (payload) {
        return $http.put(envConfig.apiUrl() + '/api/settings/access/changePassword', payload);
    };

    this.getSsoSettings = function (accountId) {
        return $http.get(envConfig.apiUrl() + '/accounts/' + accountId + '/sso', { ignoreLoadingBar: true });
    };

    this.getSsoEnabled = function (accountId) {
        return $http.get(envConfig.apiUrl() + '/accounts/' + accountId + '/sso/enabled', { ignoreLoadingBar: true });
    };

    this.getSsoEligible = function (accountId) {
        return $http.get(envConfig.apiUrl() + '/accounts/' + accountId + '/sso/eligible', { ignoreLoadingBar: true });
    };

    this.saveSsoSettings = function (accountId, ssoSettings) {
        return $http.put(envConfig.apiUrl() + '/accounts/' + accountId + '/sso', ssoSettings);
    };

    this.saveFilterDateLimits = function (filterDateLimits) {
        return $http.put(envConfig.apiUrl() + '/api/settings/account/filter-date-limits', filterDateLimits);
    };

    this.saveViewableAccounts = function (payload) {
        return $http.put(envConfig.apiUrl() + '/api/settings/access/viewableChildAccounts', payload);
    };

    this.getLiveReportSettings = function () {
        return $http.get(envConfig.apiUrl() + '/api/settings/live_report_settings', { ignoreLoadingBar: true });
    };
}
