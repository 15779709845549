import { createStore } from 'zustand';
import { fetchSettings } from './insightsSettingsStore.utils';
import { IInsightsSettingsStore } from './insightsSettingsStore.models';
import {
  INSIGHTS_SETTINGS_STORE_CACHE_LIFETIME,
  INSIGHTS_SETTINGS_STORE_DEFAULT
} from './insightsSettingsStore.constants';

export const insightsSettingsStore = createStore<IInsightsSettingsStore>(
  () => ({
    ...INSIGHTS_SETTINGS_STORE_DEFAULT
  })
);

/**
 * Fetches the insights settings from the API and sets it in the insights settings store
 * if the cache is older than the cache lifetime.
 */
export const refreshInsightsSettings = async () => {
  const { cacheTimestamp } = insightsSettingsStore.getState();

  if (
    cacheTimestamp === undefined ||
    cacheTimestamp <= Date.now() - INSIGHTS_SETTINGS_STORE_CACHE_LIFETIME
  ) {
    const insightsSettings = await fetchSettings();
    insightsSettingsStore.setState(insightsSettings);
  }
};

/**
 * Returns the current Insights Settings Store state after confirming the state has not expired.
 * If the state has expired, it will refresh the state before returning it.
 */
export const getInsightsSettingsStore =
  async (): Promise<IInsightsSettingsStore> => {
    await refreshInsightsSettings();
    return insightsSettingsStore.getState();
  };

/**
 * Resets the export store to its default state.
 */
export const resetInsightsSettingsStore = (): void =>
  insightsSettingsStore.setState(INSIGHTS_SETTINGS_STORE_DEFAULT);
