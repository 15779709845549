import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  REPORT_PAGE_MODE_STORAGE_PREFIX,
  ReportPageModeTypes
} from '../constants/reportPageModeToggle.constants';
import { IReportPageModeStoreProps } from '../models/ReportPageModeStore.models';

export const reportPageModeStore = createStore<IReportPageModeStoreProps>()(
  persist(
    () => ({
      pageMode: ReportPageModeTypes.Users
    }),
    {
      name: REPORT_PAGE_MODE_STORAGE_PREFIX
    }
  )
);

export const setReportPageMode = (pageMode: ReportPageModeTypes) => {
  reportPageModeStore.setState({ pageMode });
};

export const getReportPageMode = () => reportPageModeStore.getState();
