import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { getWebsocketSettingsStore } from '../../../_reactivtrak/src/common/stores/websocketSettingsStore/websocketSettingsStore';
import lodash from 'lodash';

angular.module('app').service('websocketService', WebsocketService);

WebsocketService.$inject = [
    'envConfig',
    '$rootScope',
    '$q',
    '$websocket',
    '$interval',
    '$timeout',
    'atHelperFunctions'
];

function WebsocketService(envConfig, $rootScope, $q, $websocket, $interval, $timeout, atHelperFunctions) {
    const service = this;

    // TODO: Move all states to the Websocket Settings Store
    let _connection = null;
    let _closeCalled;
    let _pingInterval;
    let normalCloseCode;
    let keepAliveInterval;
    let _reconnectDelay;
    let reconnectTimeout;
    let _maxReconnectDelay;
    let _initialized = false;

    const messageListeners = [];
    const disconnectListeners = [];

    function initialize() {
        _initialized = true;
        _closeCalled = false;
        const { pingInterval, reconnectDelayMax, reconnectDelayStart } = getWebsocketSettingsStore();
        _pingInterval = pingInterval;
        normalCloseCode = 1000;
        keepAliveInterval = null;
        _reconnectDelay = reconnectDelayStart;
        _maxReconnectDelay = reconnectDelayMax;
    }

    // Websocket message received, send to matching message listener
    function processMessage(message) {
        let data;

        try {
            data = JSON.parse(message.data);
        } catch (ex) {
            console.error('Error parsing Websocket message JSON string.', ex);
        }

        if (data.Error === 'null') {
            data.Error = null;
        }

        if (data.Error && data.Error !== 'Could not find Agent.') {
            console.error('Error processing websocket message.', data);
        }

        let messageListenerFound = false;
        messageListeners.forEach(function (listener) {
            if (listener.id === data.Id && typeof listener.listener === 'function') {
                listener.listener(data);
                lodash.remove(messageListeners, function (l) {
                    return l.id === listener.id;
                });
                messageListenerFound = true;
            }
        });
        if (!messageListenerFound) {
            console.warn('No message listener found for incoming websocket message', data);
        }
    }

    // Websocket close event received, cancel ping, remove connection,
    // and send to all disconnect listeners if not a normal disconnect
    function processClose(event) {
        if (keepAliveInterval) {
            $interval.cancel(keepAliveInterval);
        }

        _connection = null;
        $timeout.cancel(reconnectTimeout);

        if (event.code !== normalCloseCode) {
            reconnectTimeout = $timeout(function () {
                _reconnectDelay = Math.min(_reconnectDelay * 2, _maxReconnectDelay);
                service.connect('Reconnect');
            }, _reconnectDelay);

            disconnectListeners.forEach(function (listener) {
                if (typeof listener === 'function') {
                    listener(event);
                }
            });
            return;
        }

        if (!_closeCalled) {
            service.connect('Server Close');
        }
    }

    function addToList(newItem, list) {
        if (newItem) {
            let found = false;
            list.forEach(function (item) {
                if (item === newItem) {
                    found = true;
                }
            });

            if (!found) {
                list.push(newItem);
            }
        }
    }

    service.isConnected = function () {
        return _connection !== null;
    };

    // Connect to websocket service
    service.connect = function (caller, disconnectListener) {
        if (!_initialized || _closeCalled) {
            initialize();
        }

        _connection = $websocket(envConfig.websocketUrl(), null, {
            maxTimeout: 5000
        });

        keepAliveInterval = $interval(service.keepAlive, _pingInterval);

        addToList(disconnectListener, disconnectListeners);

        _connection.onClose(processClose);
        _connection.onMessage(processMessage);

        const authenticateParams = {
            Type: 'ClientAuthenticationEvent',
            Parameters: {
                Token: userServiceFunctions.getEncodedUserToken()
            }
        };

        const deferred = $q.defer();
        _connection
            .send(JSON.stringify(authenticateParams))
            .then(function (result) {
                const { reconnectDelayStart } = getWebsocketSettingsStore();
                _reconnectDelay = reconnectDelayStart;
                deferred.resolve(result);
            })
            .catch(function (error) {
                console.error('Websocket error authenticating', error);
                deferred.reject(error);
            });

        return deferred.promise;
    };

    // Send command
    service.sendCommand = function (type, parameters, messageListener) {
        const deferred = $q.defer();

        if (!service.isConnected()) {
            deferred.reject('Cannot send command, websocket not connected.');
        } else {
            const callbackId = atHelperFunctions.generateGuid();

            const params = {
                Type: type,
                Parameters: parameters,
                CallbackId: callbackId
            };

            addToList(
                {
                    id: callbackId,
                    listener: messageListener
                },
                messageListeners
            );

            _connection
                .send(JSON.stringify(params))
                .then(function () {
                    deferred.resolve(callbackId);
                })
                .catch(function (error) {
                    deferred.reject(error);
                });
        }

        return deferred.promise;
    };

    // Keep connection alive
    service.keepAlive = function () {
        if (!service.isConnected()) {
            console.error('Cannot send keep alive command, service is not connected.');
            $interval.cancel(keepAliveInterval);
            return;
        }

        const callbackId = atHelperFunctions.generateGuid();

        addToList(
            {
                id: callbackId,
                listener: angular.noop
            },
            messageListeners
        );

        return _connection.send(
            JSON.stringify({
                Type: 'GetPingCommand',
                Parameters: null,
                CallbackId: callbackId
            })
        );
    };

    // Close websocket connection
    service.close = function () {
        _closeCalled = true;
        if (service.isConnected()) {
            _connection.close();
        }
    };
}
