import moment from 'moment';
import {
  ProductivityIntervals,
  ProductivityColors
} from '../../common/constants';
import { getName, isPassive, getClass } from '../../common/helpers';

//interval comes from dropdown selection
const interval = '5';
const intervalUnit = undefined;
const chartTimeFormat = 'h:mm a';

const decodeColor = (value, intervalUnit) => {
  return {
    prod: intervalUnit ? undefined : value,
    level: intervalUnit ? value : undefined,
    color: ProductivityColors[value] || 'white'
  };
};

const timelineTooltipTemplate = (
  user,
  time,
  duration,
  productivity,
  activity,
  showActiveLabel = false
) => {
  if (duration === 0 || typeof productivity === 'undefined') {
    return '';
  }

  const productivityName = getName(productivity, showActiveLabel, true);
  const activityLabel = isPassive(productivity)
    ? 'Prior Activity'
    : 'Longest Activity';
  const productivityClass = getClass(productivity);

  const nameHtml = `<div class="at-timeline-tooltip-row text-medium">${user}</div>`;
  const productivityHtml = `<div class="at-timeline-tooltip-row">${productivityName}</div>`;
  const durationHtml = `<div class="at-timeline-tooltip-row">${duration}</div>`;
  const timeHtml = `<div class="at-timeline-tooltip-row">${time}</div>`;
  const activityHtml = activity
    ? `<div class="at-timeline-tooltip-row">${activityLabel}: ${activity}</div>`
    : '';

  return `<div class="at-timeline-tooltip left-border-${productivityClass}">${nameHtml}${productivityHtml}${durationHtml}${timeHtml}${activityHtml}</div>`;
};

export const customizeProductivityTooltip = (userInfo, startDate, endDate) => {
  const timeFormat = chartTimeFormat;
  const time =
    moment(startDate).format(timeFormat) +
    ' - ' +
    moment(endDate).format(timeFormat);

  const realInterval: any = moment(startDate).diff(moment(endDate)) / 1000 / 60;
  const duration =
    ProductivityIntervals[realInterval] || ProductivityIntervals[interval];

  if (!intervalUnit) {
    return timelineTooltipTemplate(
      userInfo.user,
      time,
      duration,
      userInfo.productivity,
      userInfo.activity,
      true
    );
  }
};

export const processTimelineData = (ganttData) => {
  const dataTable = [
    [
      { type: 'string', id: 'user' },
      { type: 'string', id: intervalUnit ? 'level' : 'prod' },
      {
        type: 'string',
        role: 'tooltip'
      },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' }
    ]
  ];
  const field = intervalUnit ? 'level' : 'prod';
  const colorMap = [];

  ganttData.forEach((value) => {
    const start = moment(
      (intervalUnit ? '' : '1900-01-01T') + value.start
    ).toDate();
    const end = moment(
      (intervalUnit ? '' : '1900-01-01T') + value.end
    ).toDate();

    dataTable.push([
      value.date || value.user,
      String(intervalUnit ? value.level : value.productivity),
      customizeProductivityTooltip(value, start, end),
      start,
      end
    ]);

    // Make sure Google timeline assigns the right colors
    const color = decodeColor(value.productivity, intervalUnit);
    let contains = false;
    for (let j = 0; j < colorMap.length; j++) {
      if (color[field] === colorMap[j][field]) {
        contains = true;
      }
    }
    if (!contains) {
      colorMap.push(color);
    }
  });

  const colors = colorMap.map((item) => item.color);

  return {
    dataTable,
    colors
  };
};
