import React, { useEffect } from 'react';
import { convertObjectToQs } from '../../../common/utils/parse/parse';
import {
  useTodaysProductivityGoalUsersState,
  useLastUpdate
} from '../../hooks';
import { WidgetProps } from '../../models';
import {
  errorMsg,
  lastUpdateText,
  modalButtonLabel,
  modalTitle,
  noUsersMeetingCriteria,
  widgetTitle,
  teamWidgetSubheader
} from '../../constants';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import {
  ErrorMsgDisplay,
  BaseLoadingView,
  DashboardActivityDiff,
  OverallStatus,
  TemplateShadow,
  WidgetModal,
  WidgetModalList
} from '../templates';
import { LastUpdatedSC, ModalHeader } from '../../styles/templates.styles';

export default function TodaysProductivityRatios(props: Readonly<WidgetProps>) {
  const { refreshTimestamp, reportFilters } = props;

  const { init, todaysGoalStats, isLoading, isTileError, hasNoResults } =
    useTodaysProductivityGoalUsersState();

  const lastUpdate = useLastUpdate(todaysGoalStats?.date);
  const primaryFiltersQuery = convertObjectToQs(reportFilters);

  useEffect(
    () => {
      init(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [init, primaryFiltersQuery, refreshTimestamp]
  );

  return (
    <TemplateShadow
      sx={{
        height: '450px'
      }}
    >
      <WidgetLinkWrapper direction="row" display="inline-block">
        <WidgetLinkTypography variant="h4">
          {widgetTitle.productivity}
        </WidgetLinkTypography>
      </WidgetLinkWrapper>
      {!isLoading && !isTileError && todaysGoalStats && (
        <>
          <Box
            sx={{
              textAlign: 'center',
              position: 'relative',
              paddingTop: '34px'
            }}
          >
            <OverallStatus
              header={`${todaysGoalStats?.goal}%`}
              subheader={teamWidgetSubheader.todaysProductivityGoal}
              reverseResponsive
            />
            <DashboardActivityDiff
              diff={todaysGoalStats?.previous}
              title={`vs average ${todaysGoalStats?.todaysLabel} at this time`}
            />
            <Box
              sx={{
                paddingTop: { xs: '45px', md: '28px' },
                paddingBottom: {
                  xs: '50px',
                  sm: '72px',
                  md: '40px',
                  lg: '72px'
                }
              }}
            >
              <OverallStatus
                header={`${todaysGoalStats?.achieved}/${todaysGoalStats?.total}`}
                subheader={teamWidgetSubheader.todaysProductivityUsers}
                reverseResponsive
              />
              <WidgetModal
                buttonLabel={modalButtonLabel.todaysProductivity}
                id="b5240580-fba2-4f0c-a48b-a799b8e0dd18"
                isDisabled={hasNoResults}
              >
                <ModalHeader variant="h6">
                  {modalTitle.productivity}
                </ModalHeader>
                {!todaysGoalStats ||
                todaysGoalStats?.userGoals?.length === 0 ? (
                  <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
                ) : (
                  <WidgetModalList data={todaysGoalStats.userGoals} />
                )}
              </WidgetModal>
            </Box>
          </Box>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
            </LastUpdatedSC>
          )}
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
