import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

interface ILegacyPageToggleStore {
  [key: string]: boolean; //key = page's state.name
}

interface ILegacyPageToggleStoreSelector {
  (state: ILegacyPageToggleStore): boolean;
}

export const legacyPageToggleStore = createStore<ILegacyPageToggleStore>(
  () => ({})
);

export const getLegacyPageToggleStore = () => {
  return legacyPageToggleStore.getState();
};

export const setLegacyPageToggleStore = (newValue: ILegacyPageToggleStore) => {
  legacyPageToggleStore.setState((prev) => ({ ...prev, ...newValue }));
};

export const useLegacyPageToggleStore = (s: ILegacyPageToggleStoreSelector) =>
  useStore(legacyPageToggleStore, s);
