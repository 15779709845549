import angular from 'angular';
import { getInjector, setInjector } from '../_reactivtrak/src/common/third-party/angular2react/useInjector';
import { getUserToken } from '../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';

export const rewire = () => {

    const $injector = getInjector();

    if ($injector) {
        const $rootScope = $injector.get('$rootScope');
        $rootScope?.$destroy();
    }

    // destroy previous app - uirouter needs a fresh div
    const currentApp = document.getElementById('core-app');
    angular.element(currentApp).removeData();
    currentApp?.remove();

    // create new element
    const uiViewEl = document.createElement('div');
    uiViewEl.id = 'core-app';
    uiViewEl.setAttribute('ui-view', '');

    document.body.append(uiViewEl);

    const token = getUserToken();

    // TODO: Dedupe with main.ts
    const modules =
        token === 'invalid' ? ['login', 'verify', 'chromebook'] : ['login', 'verify', 'chromebook', 'app', 'cc'];

    const injector = angular.bootstrap(uiViewEl, modules);
    injector.get('$rootScope').$apply();

    setInjector(injector);

    window.Intercom({
        app_id: import.meta.env.VITE_INTERCOM_APP_ID
    });
};
