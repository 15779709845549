import React, { useEffect, useState } from 'react';
import UserHealthChart from './UserHealthChart';
import { useUserHealthState } from '../../hooks';
import { AvailableDays } from '../../models';
import Grid from '@mui/material/Grid';
import WidgetLink from '../../../common/components/WidgetLink';
import UserHealthModal from './UserHealthModal';
import { UserHealthCanvasBox } from '../../styles/UserHealth.styles';
import { useWidgetTemplateStyles as templateClasses } from '../../styles/templates.styles';
import { TemplateShadow, PopoverDotMenu } from '../templates';
import { getUserHealthConfig, setUserHealthConfigStorage } from '../../utils';
import { errorMsg } from '../../constants/errorMessages';
import BaseLoadingView from '../templates/BaseLoadingView';
import { ErrorMsgDisplay } from '../templates/ErrorMsgDisplay';

export default function UserHealth() {
  const [userHealthConfig, setUserHealthConfig] = useState<AvailableDays>(
    getUserHealthConfig()
  );

  const {
    init,
    chartData,
    activeSinceDays,
    dataPercentages,
    isWidgetStateLoading,
    setActiveSinceDays,
    setModalState,
    modalState,
    getData,
    widgetError
  } = useUserHealthState();

  useEffect(() => {
    init({ activeSinceDays: userHealthConfig });
  }, [init, userHealthConfig]);

  useEffect(() => {
    if (activeSinceDays && userHealthConfig != activeSinceDays) {
      getData({ activeSinceDays: activeSinceDays });
      setUserHealthConfigStorage(activeSinceDays);
      setUserHealthConfig(activeSinceDays);
    }
  }, [activeSinceDays, getData, userHealthConfig]);

  const handleConfigClick = (e) => {
    e.stopPropagation();
    setModalState(true);
  };

  return (
    <TemplateShadow>
      <Grid item xs={12} sx={templateClasses.spacedContentHeader}>
        <WidgetLink
          text="User Health"
          redirectTo={'app.settings.useragents'}
          redirectParams={{ limit: 30 }}
          id="315d38b6-bd3a-11ed-afa1-0242ac120002"
        />
        <UserHealthModal
          activeSinceDays={activeSinceDays}
          setActiveSinceDays={setActiveSinceDays}
          modalState={modalState}
          setModalState={setModalState}
        />
        {!widgetError && (
          <PopoverDotMenu
            popoverText="Configure chart"
            handlePopoverItemClick={handleConfigClick}
            id="c5977db4-c8e5-11ed-afa1-0242ac120002"
          />
        )}
      </Grid>
      {(isWidgetStateLoading && widgetError) ||
        (!chartData && (
          <UserHealthCanvasBox
            sx={{
              padding: '10px'
            }}
          >
            <BaseLoadingView
              isLoading={isWidgetStateLoading}
              isTileError={widgetError}
              errorMsg={<ErrorMsgDisplay msg={errorMsg} notWidget={true} />}
            />
          </UserHealthCanvasBox>
        ))}
      {chartData && !isWidgetStateLoading && (
        <UserHealthCanvasBox>
          <UserHealthChart
            chartData={chartData}
            activeSinceDays={activeSinceDays}
            dataPercentages={dataPercentages}
          />
        </UserHealthCanvasBox>
      )}
    </TemplateShadow>
  );
}
