import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { IReportViewStoreProps } from '../models/ReportViewStore.models';
import {
  REPORT_VIEW_STORAGE_PREFIX,
  ReportViewTypes
} from '../constants/reportViewToggle.constants';

export const reportViewStore = createStore<IReportViewStoreProps>()(
  persist(
    () => ({
      view: ReportViewTypes.SUMMARY
    }),
    {
      name: REPORT_VIEW_STORAGE_PREFIX
    }
  )
);

export const setReportView = (view: ReportViewTypes) => {
  reportViewStore.setState({ view });
};

export const getReportView = () => reportViewStore.getState();
