import { Role } from '../../../../../../enums';
import { FeatureFlag } from '../../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../../models/IRoute';

export const appDevelopmentPlaygroundId: IRoute = {
  name: 'app.development.playground.id',
  stateDefinition: {
    url: '/{demoId}',
    data: { pageTitle: 'Development Playground' },
    params: {
      demoId: 'example'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: Object.values(Role), // All roles have access to playground
    read: [],
    featureFlags: [FeatureFlag.DevelopmentEnvironment]
  }
};

export default appDevelopmentPlaygroundId;
