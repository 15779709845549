'use strict';

import { getDateSettingsStore } from '../../../_reactivtrak/src/common/stores/dateSettingsStore';

angular.module('app').directive('scheduleBar', [scheduleBar]);

function scheduleBar() {
    var { isTimeFormat24Hour } = getDateSettingsStore();
    var options = {
        min: moment().startOf('day').format('H:mm'),
        max: moment().endOf('day').format('H:mm'),
        valueFormat: function (ts) {
            return moment(ts).format('HH:mm');
        },
        valueParse: function (date) {
            return moment(date, 'HH:mm').valueOf();
        },
        values: [['00:00', '23:59']],
        label: function (value) {
            if (value && value.length === 2) {
                if (value[0] === '00:00' && (value[1] === '00:00' || value[1] === '23:59')) {
                    return 'Always';
                }

                var result = [];
                for (var v = 0; v < value.length; v++) {
                    if (isTimeFormat24Hour) {
                        var time = value[v].split(':');
                        var hours = parseInt(time[0]);
                        var minutes = time[1];
                        var ampm = hours < 12 || hours === 24 ? 'AM' : 'PM';
                        result[v] = (hours % 12 || 12) + ':' + minutes + ' ' + ampm;
                    } else {
                        result[v] = value[v];
                    }
                }

                return result[0] + ' - ' + result[1];
            }
            return '';
        },
        snap: 1000 * 60 * 15,
        minSize: 1000 * 60 * 15,
        allowDelete: true,
        readonly: false,
        bgMark: {
            count: 12,
            label: function (number) {
                var timeFormat = isTimeFormat24Hour ? 'HH:mm' : 'hh:mm A';
                var time = moment(number, 'HH:mm');

                if (number !== '00:00') {
                    time = time.add(1, 'm');
                }
                return time.format(timeFormat);
            }
        }
    };

    return {
        restrict: 'E',
        scope: {
            model: '=',
            scheduleType: '=',
            readOnly: '='
        },
        link: function (scope, elem) {
            var buildRangeBar = function () {
                // eslint-disable-next-line
                var bar = new RangeBar(options).on('change', function (ev, ranges, changed) {
                    scope.model = ranges;
                    if (changed) {
                        scope.$apply();
                    }
                });

                $(elem).append(bar.$el);
                return bar;
            };

            var rangeBar = buildRangeBar();

            scope.$watch('model', function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }

                rangeBar.val(newValue);
            });

            scope.$watch('scheduleType', function (newValue) {
                if (typeof newValue === 'undefined') {
                    return;
                }

                if (newValue === 'AlwaysSchedule') {
                    options.values = [['00:00', '23:59']];
                    options.readonly = true;
                } else if (newValue === 'WeeklySchedule') {
                    options.readonly = scope.readOnly;
                    if (scope.model && scope.model.length > 0) {
                        options.values = scope.model;
                    } else {
                        options.values = [];
                    }
                }

                scope.model = options.values;

                rangeBar.$el.remove();
                rangeBar = buildRangeBar();
            });
        }
    };
}
