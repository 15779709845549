export const calculateTimeAxis = (
  dayRange: number,
  timelineWidth: number
): { scale: 'minute' | 'hour'; step: number } => {
  const useMinutesCutoff = 0.75;
  const dayRangeMultiplier = 2880; // Minutes in 2 days
  // Step should get smaller as the timeline gets wider.
  let step = (dayRangeMultiplier * dayRange) / timelineWidth;
  let scale: 'minute' | 'hour' = 'hour';

  // Step of 1 with a scale of 'hour' is equal to 1 hour steps (24 steps in a day).
  // Step of 8 with a scale of 'hour' is equal to 8 hour steps (3 steps in a day).
  // Step of less than 0.75 (45 minutes) with a scale of 'hour' is converted to minutes scale.
  step = Math.max(0, Math.min(8, step));
  if (step < useMinutesCutoff) {
    scale = 'minute';
    step = Math.ceil((step * 60) / 15) * 15;
  } else {
    step = Math.ceil(step / 2) * 2;
  }

  return { scale, step: step };
};
