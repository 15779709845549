export enum EntityType {
  User = 'User',
  UserAlias = 'Alias',
  Computer = 'Computer',
  Group = 'Group',
  AllUsersGroup = 'AllUsers', // TODO: Research why this type is around and convert to just "Group"
  AllComputersGroup = 'AllComputers' // TODO: Research why this type is around and convert to just "Group"
}

export enum EntitySource {
  AllUsers = 'AllUsers',
  ActivTrak = 'Group',
  AdGroup = 'AdGroup'
}

export enum EntityMix {
  User = 'User',
  Computer = 'Computer',
  Both = 'Both'
}

export interface IEntity {
  id: string;
  name: string;
  type: EntityType;
  source?: EntitySource;
  mix?: EntityMix;
}

export interface IEntityFilterData {
  alias: string;
  computerCount: number;
  computerMemberList: string[];
  domain: string;
  groupType: string;
  skipMemberCount: boolean;
  tag: string;
  userCount: number;
  userId: string;
  userMemberList: string[];
  userName: string;
  userType: string;
}

export interface IFilterDto {
  data: IEntityFilterData[];
  total: number;
}

export interface IEntityStore {
  users: IEntity[];
  computers: IEntity[];
  groups: IEntity[];
  isLoading: boolean;
  previousSearch?: string;
  cacheTimestamp?: number;
}
