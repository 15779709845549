import { useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DownloadProvider } from '../../../common/services';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { getTopUsersColumnsStore } from '../stores/topUsersColumns.store';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { TopUsersReportHeader } from '../components/TopUsersReportHeader';
import { TopUsersGrid } from '../components/TopUsersGrid';
import { TopUsersExport } from '../components/TopUsersExport';
import { TopUsersColumnSelector } from '../components/TopUsersColumnSelector';
import { TopUsersReportViewToggle } from '../components/TopUsersReportViewToggle';
import { TopUsersPageModeToggle } from '../components/TopUsersPageModeToggle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TopUsersSearch } from '../components/TopUsersSearch';

export const TopUsersReportView = () => {
  const gridRef = useRef<AgGridReact>(null);

  const handleColumnUpdate = useCallback(() => {
    const { view: currentView } = getReportView();
    const currentColumnState = getTopUsersColumnsStore();

    //this vanilla JS is needed to apply the new column state when sources outside the grid change it
    gridRef.current?.api.applyColumnState({
      state: currentColumnState[currentView],
      //needed when columns are re-ordered
      applyOrder: true
    });
  }, []);

  //TODO: hide the gantt chart if report mode = Group

  return (
    <>
      <TopUsersReportHeader />
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: 4, mb: 2 }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box sx={{ mr: 1 }}>
            <TopUsersPageModeToggle />
          </Box>
          <TopUsersReportViewToggle onChange={handleColumnUpdate} />
        </Box>
        <Box display="flex" alignItems="center">
          <Box sx={{ mr: 1 }}>
            <TopUsersColumnSelector onSubmit={handleColumnUpdate} />
          </Box>
          <Box sx={{ mr: 0 }}>
            <TopUsersExport />
          </Box>
        </Box>
      </Stack>
      <Box sx={{ mb: 2, width: { xs: '100%', md: '66%' } }}>
        <TopUsersSearch />
      </Box>
      <TopUsersGrid gridRef={gridRef} onColumnUpdate={handleColumnUpdate} />
    </>
  );
};

export const TopUsersReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <TopUsersReportView />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
