import { getState } from "../_reactivtrak/src/common/config/routing";
import { ClaimType } from "../_reactivtrak/src/common/enums";
import { getUserClaims } from "../_reactivtrak/src/common/helpers/authentication/useUserTokenStore";
import { getAccountSetting } from "../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore";
import { getUserFeatureFlags } from "../_reactivtrak/src/common/stores/userPermissionsStore/userPermissionStore";

export const resolveLandingState = (): [string, unknown?] => {
    
    const claims = getUserClaims();
    if (claims === 'invalid') {
        return ['login'];
    }

    const roles = claims.filter((claim) => claim.type === ClaimType.Role).map((role) => role.value?.toLowerCase());

    if (roles.includes('supportportal')) {
        //TODO: relocate these removeItem commands
        window.localStorage.removeItem('activTrak.supportPortal.accounts');
        window.localStorage.removeItem('activTrak.supportPortal.supportRole');
        window.localStorage.removeItem('activTrak.support.backup');

        return ['supportportal'];
    }

    if (roles.includes('ccenterpriseaccess')) {
        return ['cc.dashboard', { route: 'enterprise' }];
    }
    
    if (roles.includes('ccaccess')) {
        return ['cc.dashboard', { route: 'paid-subscriptions' }];
    }    

    const user_feature_flags = getUserFeatureFlags();
    if (user_feature_flags?.showActivationFeatureFlag) {
        const has_activation_state = getState('app.agentactivation');
        if (has_activation_state !== 'invalid') {
            return ['app.agentactivation'];
        }
    }

    //check the configured landing
    const landing = getAccountSetting('landingPage');
    if (landing && getState(landing) !== 'invalid') {
        return [landing];
    }

    // try dashboards
    const has_app_dashboard = getState('app.dashboard');
    if (has_app_dashboard !== 'invalid') {
        return ['app.dashboard'];
    }

    const has_app_dashboard_current_admin = getState('app.dashboardCurrent.admin');
    if (has_app_dashboard_current_admin !== 'invalid') {
        return ['app.dashboardCurrent.admin'];
    }

    const has_app_dashboard_current_management = getState('app.dashboardCurrent.management');
    if (has_app_dashboard_current_management !== 'invalid') {
        return ['app.dashboardCurrent.management'];
    }

    const has_app_dashboard_current_organization = getState('app.dashboardCurrent.organization');
    if (has_app_dashboard_current_organization !== 'invalid') {
        return ['app.dashboardCurrent.organization'];
    }

    // unrestricted landing
    return ['app.account.profile'];
};