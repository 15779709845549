import angular from 'angular';

import { UI_ROUTER_REACT_HYBRID } from '../../_entry/router-hybrid';
import { install } from '../../_reactivtrak/src/common/config/routing/routeDefinitions/install/install';
import { getUserToken } from '../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { getUserName } from "../../_reactivtrak/src/common/services/Profile/useProfileState";
import { logout } from '../../_reactivtrak/src/common/helpers/authentication';

const CHROMEBOOK_MODULE  = angular.module('chromebook', [UI_ROUTER_REACT_HYBRID, 'config', 'utils']);
CHROMEBOOK_MODULE.controller('ChromeAgentInstallCtrl', ChromeAgentInstallCtrl);

CHROMEBOOK_MODULE.config([
    '$uiRouterProvider',
    '$locationProvider',
    ($uiRouter, $locationProvider) => {
        $locationProvider.hashPrefix('');
        $locationProvider.html5Mode(false);
        
        // $uiRouter.trace.enable(1);

        // Login Module Routes
        const $stateRegistry = $uiRouter.stateRegistry;
        $stateRegistry.register({
            name: install.name,
            ...install.stateDefinition
        });
    }
]);

ChromeAgentInstallCtrl.$inject = ['$scope', '$window', '$state', 'chromeAgentConfirmationService'];

function ChromeAgentInstallCtrl($scope, $window, $state, chromeAgentConfirmationService) {

    $scope.token = $state.params.token;
    $scope.email = $state.params.email;

    chromeAgentConfirmationService
        .confirm($scope.token, $scope.email)
        .then(function (result) {
            $window.location.href = result.data.url;
        })
        .catch(function (result) {
            console.log('%c result: ', 'font-size:12px;background-color: #C5B37A;color:#fff;', result);
            // If logged in and email in url query does not match logged in email, logout user
            const token = getUserToken();
            const username = getUserName();
            if (token === 'invalid' && $scope.email !== username) {
                logout();
            } else {
                // TODO: this needs updating
                $state.go('app.dashboard', {
                    chromebookInstallError: result.error
                });
            }
        });
}

CHROMEBOOK_MODULE.service('chromeAgentConfirmationService', ChromeAgentConfirmationService);

ChromeAgentConfirmationService.$inject = ['$http', 'loginServiceUrl'];

function ChromeAgentConfirmationService($http, loginServiceUrl) {
    this.confirm = function (token, email) {
        const result = $http.post(loginServiceUrl.apiUrl() + '/api/settings/chromebook/users/confirm', {
            secret: token,
            email: email
        });
        return result;
    };
}
