'use strict';

import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').controller('FeatureRequestsCtrl', FeatureRequestsCtrl);

FeatureRequestsCtrl.$inject = [
    '$http',
    'localStorageService',
    'envConfig',
    'messagesService',
    'notificationService',
    '$scope',
    '$sce'
];

function FeatureRequestsCtrl($http, localStorageService, envConfig, msg, notificationService, $scope, $sce) {
    var { username } = profileStore.getState().profile;
    var productBoardSession = 'productBoard-' + username;
    var tokenExpiration = moment.duration(6, 'hours');

    var tokenSession = {
        token: null,
        dateCreated: null
    };

    function getProductTokenSession() {
        return localStorageService.get(productBoardSession);
    }

    function setTokenObject(token, date) {
        tokenSession.token = token;
        tokenSession.dateCreated = date;
    }

    function isTokenExpired() {
        var timeDiff = moment.utc().diff(session.dateCreated, 'hours');
        var isExpired = timeDiff > tokenExpiration.asHours();
        return isExpired;
    }

    function setEmbeddedUrl(token) {
        var url = 'https://portal.productboard.com/qk4nfmtxoscdxxtoe7xs6yff?token=';
        $scope.embeddedUrl = $sce.trustAsResourceUrl(url + token);
    }

    var session = getProductTokenSession();
    var errorTypes = {
        pageNotAvailable: 'pageNotAvailable',
        authenticationError: 'authenticationError'
    };

    if (session && !isTokenExpired()) {
        setTokenObject(session.token, session.dateCreated);
        setEmbeddedUrl(session.token);
    } else {
        generateNewToken();
    }

    function showError(errorType) {
        switch (errorType) {
            case errorTypes.pageNotAvailable:
                notificationService.showNotification(msg.get('pageNotAvailableError'), 'danger');
                return;
            case errorTypes.authenticationError:
                notificationService.showNotification(msg.get('userAuthenticationError'), 'danger');
                return;
            default:
                notificationService.showNotification(msg.get('unexpectedErrorOccurred'), 'danger');
        }
    }

    function generateNewToken() {
        $scope.embeddedUrl = null;
        $http
            .get(envConfig.apiUrl() + '/api/pab/sso')
            .then(function (response) {
                var token = response && response.data && response.data.token;
                var tokenCreatedDate = moment.utc().format();

                if (token) {
                    setTokenObject(token, tokenCreatedDate);
                    localStorageService.set(productBoardSession, tokenSession);
                    setEmbeddedUrl(token);
                } else {
                    showError(errorTypes.pageNotAvailable);
                }
            })
            .catch(function (e) {
                if (e.status === 400) {
                    showError(errorTypes.pageNotAvailable);
                } else {
                    showError(errorTypes.authenticationError);
                }
            });
    }
}
