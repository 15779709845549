import { useEffect, useMemo, useState } from 'react';
import { favoritesService } from '../../../services/Navigation/FavoritesService';
import { useFavoritesState } from './useSideBarState';
import { findItemByName } from '../navigationStore';
import { FavoritesIcon } from '../../../assets/Icons';
import { sidebarClasses } from './SideBar.styles';
import { gray19 } from '../../../constants';
import { ExpandIcon } from './ExpandIcon';
import { Typography } from '@mui/material';

import { FavoritesNavigationItem } from './FavoritesNavigationItem';

export const FavoritesComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [mappedFavorites, setMappedFavorites] = useState([]);
  const service = useMemo(() => {
    return favoritesService();
  }, []);

  const {
    favorites,
    setFavorites,
    findItemById,
    favoritesMenuOpen,
    setFavoritesMenuOpen
  } = useFavoritesState();

  useEffect(() => {
    const getFavorites = async () => {
      try {
        const result = await service.getFavorites();
        setFavorites(result);
      } catch (error) {
        console.error(error);
        setFavorites([]);
      } finally {
        setIsLoaded(true);
      }
    };
    getFavorites();
  }, [service, setFavorites]);

  useEffect(() => {
    const resolveFavorite = (favorite: any) => {
      const found = findItemById(favorite.id) ?? findItemByName(favorite.name);

      if (found === undefined) {
        return { invalid: true, ...favorite };
      }

      return found;
    };
    const mapped = favorites.map((f) => resolveFavorite(f)).filter((f) => !!f);

    setMappedFavorites(mapped);
  }, [favorites, findItemById]);

  return (
    <li id={`favorites_menu_5306cdb8-1bd4-412c-b121-383135e65642`}>
      <div className="at-menu-item-container">
        <div
          className={`at-menu-item-row ${
            favoritesMenuOpen ? 'expanded' : 'collapsed'
          }`}
        >
          <a
            className="at-flex-container at-menu-item at-pointer"
            onClick={() => setFavoritesMenuOpen(!favoritesMenuOpen)}
          >
            <div className="at-flex-child at-menu-item-icon">
              <FavoritesIcon
                style={sidebarClasses.navigationItemIcon}
                height={24}
                width={24}
                fillColor={gray19}
              />
            </div>
            <div className="at-flex-child at-menu-item-label">
              Favorites {favorites?.length > 0 ? ` (${favorites.length})` : ''}
            </div>

            {isLoaded && favorites && (
              <div className="at-flex-child at-menu-item-dropdown-control at-menu-item-icon">
                <ExpandIcon subMenuOpen={favoritesMenuOpen} />
              </div>
            )}
          </a>
        </div>
        {isLoaded && favorites && favoritesMenuOpen && favorites.length == 0 && (
          <div className="at-menu-item-submenu slide-down">
            <div className="at-menu-item-container">
              <div style={sidebarClasses.emptyMessage}>
                <Typography sx={sidebarClasses.emptyMessageTitle}>
                  Set up your favorites
                </Typography>
                <Typography sx={sidebarClasses.emptyMessageBody}>
                  Click on the star icon in either the navigation or the top of
                  the page.
                </Typography>
              </div>
            </div>
          </div>
        )}
        {isLoaded && favorites && favoritesMenuOpen && favorites?.length > 0 && (
          <div
            className="at-menu-item-submenu slide-down"
            style={{ ['--h' as any]: favorites.length * 51 }}
          >
            <ul className="at-submenu-items">
              {mappedFavorites.map((favorite, subindex) => (
                <FavoritesNavigationItem key={subindex} item={favorite} index={subindex} />
              ))}
            </ul>
          </div>
        )}
      </div>
    </li>
  );
};
