import { createStore } from 'zustand';

export interface IIdentitySearchResultsStore {
  lastUserSearchResultIds: number[];
}

export const IDENTITY_SEARCH_STORE_RESULTS_KEY: string =
  'activTrak.identity-search-results-store';

//todo: split this into two stores so we can partialize(store) the pageSize
export const IDENTITY_SEARCH_STORE_RESULTS_DEFAULT: IIdentitySearchResultsStore =
  {
    lastUserSearchResultIds: []
  };

export const identitySearchResultsStore =
  createStore<IIdentitySearchResultsStore>()(() => ({
    ...IDENTITY_SEARCH_STORE_RESULTS_DEFAULT
  }));

// get the current state of the identity store
export const getIdentitySearchResultsStore = (): IIdentitySearchResultsStore =>
  identitySearchResultsStore.getState();

export const setIdentitySearchResultsState = (
  newIdentityState: IIdentitySearchResultsStore
): void => {
  const current = identitySearchResultsStore.getState();
  identitySearchResultsStore.setState({ ...current, ...newIdentityState });
};

export const getIdentitySearchResultsState =
  (): IIdentitySearchResultsStore => {
    return identitySearchResultsStore.getState();
  };

/**
 * Resets the identity store to its default state.
 */
export const resetIdentitySearchResultsStore = (): void =>
  identitySearchResultsStore.setState(IDENTITY_SEARCH_STORE_RESULTS_DEFAULT);
