import { createStore } from 'zustand';
import { IWebsocketSettingsStore } from './websocketSettingsStore.models';
import { fetchSettings } from './websocketSettingsStore.utils';
import { WEBSOCKET_SETTINGS_STORE_DEFAULT } from './websocketSettingsStore.constants';

export const websocketSettingsStore = createStore<IWebsocketSettingsStore>(
  () => WEBSOCKET_SETTINGS_STORE_DEFAULT
);

/**
 * Fetches the Websocket Settings from the API and sets it in the store.
 */
export const fetchWebsocketSettings =
  async (): Promise<IWebsocketSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = websocketSettingsStore.getState;
    websocketSettingsStore.setState({ ...currentSettings, ...response });
    return websocketSettingsStore.getState();
  };

/**
 * Returns the current Websocket Settings Store state.
 */
export const getWebsocketSettingsStore = (): IWebsocketSettingsStore =>
  websocketSettingsStore.getState();

/**
 * Resets the Websocket Settings Store to its default state.
 */
export const resetWebsocketSettingsStore = (): void =>
  websocketSettingsStore.setState(WEBSOCKET_SETTINGS_STORE_DEFAULT);
