import { getPrivacySettings } from '_reactivtrak/src/common/hooks/privacySettingsStore';
import { alarmObjectVerifierFunctions } from '_app/serviceFunctions/alarmObjectVerifierFunctions';
import lodash from 'lodash';

angular.module('app').controller('alarmDetailsCtrl', AlarmDetailsCtrl);

AlarmDetailsCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'messagesService',
    'alarmService',
    'accessApiService',
    'trackEventService',
    'authorizationService',
    'atHelperFunctions',
    'browserServiceFunctions'
];

function AlarmDetailsCtrl(
    $scope,
    $rootScope,
    $state,
    $timeout,
    msg,
    alarmService,
    accessApiService,
    trackEventService,
    authorizationService,
    atHelperFunctions,
    browserServiceFunctions
) {
    var { screenshotsAllowed = false, alarmActionsAllowed = false, activityAllowed = false } = getPrivacySettings();

    $scope.isRiskLevelReportEnabled = authorizationService.hasFeature('isRiskLevelReportEnabled');
    $scope.isUSBAlarmEnabled = authorizationService.hasFeature('isUSBAlarmEnabled');
    $scope.isAuditAlarmEnabled = authorizationService.hasFeature('isAuditAlarmEnabled');
    $scope.isPaidFunctionalityEnabled = authorizationService.hasFeature('isPaidFunctionalityEnabled');
    $scope.paidPlanUSBAlertMessage = msg.get('usbStorageDevicesAreVeryAccessible');
    $scope.paidPlanUSBAlertShortMessage = msg.get('usbAlarmPaidPlanAvailableOnly');
    $scope.paidPlanAuditAlertMessage = msg.get('auditAlarmPaidPlanAvailableOnly');
    $scope.paidPlanRiskLevelAlertMessage = msg.get('riskLevelPaidPlanAvailableOnly');
    var lastDisableSaveCount;
    var registeredComponents = [];

    // ***************************
    // Alarm Definitions
    // ****************************
    var alarmConfigDefinitions = {
        Activity: {
            type: 'Activity',
            components: [
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.controls,
                    options: {
                        master: true
                    }
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.groups
                    // options: {
                    //     hidden: !authorizationService.hasFeature('isPaidFunctionalityEnabled')
                    // }
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.conditions
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.actionSeparator
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.screenCaptures
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.popupMessage
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.emailNotification
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.webhookNotification
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.terminate
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.riskLevel
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.controls
                }
            ]
        },
        Usb: {
            type: 'Usb',
            components: [
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.controls,
                    options: {
                        master: true
                    }
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.conditions
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.actionSeparator
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.screenCaptures
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.popupMessage
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.emailNotification
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.webhookNotification
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.riskLevel
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.controls
                }
            ]
        },
        Audit: {
            type: 'Audit',
            components: [
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.controls,
                    options: {
                        master: true
                    }
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.conditions
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.actionSeparator
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.emailNotification
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.webhookNotification
                },
                {
                    type: alarmObjectVerifierFunctions.moduleTypes.controls
                }
            ]
        }
    };

    function getDuplicateName() {
        return $state.params.duplicate;
    }

    function showSaveButton() {
        switch (true) {
            case $scope.alarm.isUsb:
                return $scope.isUSBAlarmEnabled;
            case $scope.alarm.type === 'Audit':
                return $scope.isAuditAlarmEnabled;
            default:
                return true;
        }
    }

    // ***************************
    // Restricted fields for or condition
    // ****************************
    function isRestrictedFieldWithOrFlag(field) {
        return field === 'User' || field === 'Computer' || field === 'IpAddress' || field === 'DurationSeconds';
    }

    function getRestrictedOrFlagFields(conditions) {
        var restrictedOrFlagFields = [];
        conditions.forEach(function (c) {
            if (isRestrictedFieldWithOrFlag(c.field)) {
                restrictedOrFlagFields.push(c.fieldName);
            }
        });

        return restrictedOrFlagFields;
    }

    // ***************************
    // Macro Buttons
    // ****************************
    function macroButtonWillExceedFieldLength(macro, current, max) {
        var macroLength = macros[macro].length;
        var remainingLength = max - current;
        return macroLength >= remainingLength;
    }

    // jscs:disable disallowQuotedKeysInObjects
    var macros = {
        Time: '$Time$',
        User: '$User$',
        Computer: '$Computer$',
        Executable: '$Executable$',
        Description: '$Description$',
        Website: '$Website$',
        URL: '$URL$',
        Title: '$Titlebar$',
        'Event Name': '$EventName$',
        'Action Type': '$ActionType$',
        'Action Data': '$ActionData$',
        'Alarm Name': '$AlarmName$',
        'Company Name': '$CompanyName$'
    };
    // jscs:ensable disallowQuotedKeysInObjects

    /*jslint bitwise: true */
    function generateId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0;
            var v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
    /*jslint bitwise: false */

    function getMacroButtons(actionType) {
        var macros = [];

        if ($scope.alarm.type === 'Audit') {
            macros = ['Time', 'User', 'Description', 'Event Name', 'Action Type'];

            if (actionType !== 'webhook') {
                macros.push('Action Data');
            }
        } else if ($scope.alarm.isUsb) {
            macros = ['Time', 'User', 'Computer'];
        } else if (activityAllowed) {
            macros = ['Title', 'Time', 'User', 'Computer', 'Executable', 'Description', 'URL'];
        } else {
            macros = ['Time', 'User', 'Computer', 'Executable', 'Description', 'Website'];
        }

        if (actionType === 'emailNotification' || actionType === 'webhook') {
            macros.unshift('Alarm Name');
        }

        if (actionType === 'webhook') {
            macros.push('Company Name');
        }

        return macros;
    }

    function appendMacro(field, fieldId, source) {
        // Set result based on field pressed

        var result = ' ' + macros[field];

        var insertText = function (fieldId, source, text) {
            if (source === null) {
                source = '';
            }

            var field = angular.element(fieldId);
            var cursorPos = field.prop('selectionStart');
            var textBefore = source.substring(0, cursorPos);
            var textAfter = source.substring(cursorPos, source.length);

            field.focus();

            $timeout(function () {
                field[0].setSelectionRange(cursorPos + text.length, cursorPos + text.length);
            });

            return textBefore + text + textAfter;
        };

        return insertText(fieldId, source, result);
    }

    // ***************************
    // ActivTrak ID Data Source
    // ****************************
    var emailsDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                accessApiService.getUsers().success(function (result) {
                    var emails = result.map(function (user) {
                        return user.user;
                    });
                    // if the current email is not on the list, add it to the end
                    if ($scope.alarm.emailto !== undefined && $scope.alarm.emailto !== null) {
                        angular.forEach($scope.alarm.emailto, function (email) {
                            if (emails.indexOf(email) === -1) {
                                emails.push(email);
                            }
                        });
                    }
                    options.success(emails);
                });
            }
        }
    });

    function navigateToAlarms(wasSaved) {
        $state.current.data.pageTitle = 'Alarm Configuration';
        if ($state.params.returnState) {
            $state.go($state.params.returnState, {
                filter: $state.params.returnFilter,
                wasSaved: wasSaved
            });
        } else if ($state.params.riskReportParams) {
            $state.go('app.reports.riskLevel', $state.params.riskReportParams);
        } else if ($state.params.fromAuditLog) {
            $state.go('app.account.audit');
        } else {
            $state.go('app.alarms.settings', {
                wasSaved: wasSaved
            });
        }
    }
    $scope.navigateToAlarms = navigateToAlarms;

    function disableSaveUpdated(reasons) {
        var reasonCount = (reasons && reasons.length) || 0;
        if (lastDisableSaveCount !== reasonCount || reasonCount > 0) {
            lastDisableSaveCount = reasonCount;
            var listeningComponents = lodash.filter(registeredComponents, function (c) {
                return typeof c.disableSaveUpdated === 'function';
            });
            listeningComponents.forEach(function (component) {
                component.disableSaveUpdated(reasons);
            });
        }
    }

    function isConfigValid() {
        var disableSaveReasons = [];
        var listeningComponents = lodash.filter(registeredComponents, function (c) {
            return typeof c.isValid === 'function';
        });
        listeningComponents.forEach(function (component) {
            var validationObject = component.isValid();
            var componentReasons = validationObject.invalidReasons;
            if (componentReasons && componentReasons.length > 0) {
                componentReasons.forEach(function (reason) {
                    disableSaveReasons.push(reason);
                });
            }
        });
        if (!alarmObjectVerifierFunctions.verifyActiveActions($scope.alarm)) {
            disableSaveReasons.push(msg.get('noActiveAction'));
        }
        disableSaveUpdated(disableSaveReasons);
        return disableSaveReasons.length === 0;
    }

    function generatePayload() {
        var payload = angular.copy($scope.alarm);
        var listeningComponents = lodash.filter(registeredComponents, function (c) {
            return typeof c.updatePayload === 'function';
        });
        var inValidConfig = true;
        listeningComponents.forEach(function (component) {
            inValidConfig = inValidConfig && component.updatePayload(payload);
        });
        return inValidConfig && payload;
    }

    function decodeHtmlEncodedAlarmParams(alarm) {
        alarm.name = browserServiceFunctions.htmlDecode(alarm.name);
        alarm.conditions.forEach(function (condition) {
            condition.value = browserServiceFunctions.htmlDecode(condition.value);
        });
        alarm.popuptext = browserServiceFunctions.htmlDecode(alarm.popuptext);
        alarm.emailbody = browserServiceFunctions.htmlDecode(alarm.emailbody);
        alarm.emailsubject = browserServiceFunctions.htmlDecode(alarm.emailsubject);
        return alarm;
    }

    function alarmUpdated(oldAlarm) {
        var listeningComponents = lodash.filter(registeredComponents, function (c) {
            return typeof c.alarmUpdated === 'function';
        });
        listeningComponents.forEach(function (component) {
            component.alarmUpdated(oldAlarm);
        });
        isConfigValid();
    }

    function generateTrackParameters(payload) {
        return {
            alarmActive: payload.active,
            alarmName: payload.name,
            groups: payload.groups ? payload.groups.toString() : 'None',
            conditionsText: payload.conditionstext,
            emailActive: payload.email,
            type: payload.isUsb ? 'USB' : payload.type,
            popupActive: payload.popup,
            screenshots: payload.screenshotmultiple ? 'Multi' : payload.screenshot ? 'Single' : 'None',
            screenshotDuration: payload.screenshotsec,
            terminateActive: payload.terminate,
            videoActive: payload.video,
            webhookActive: payload.webhook,
            riskLevelActive: payload.weightEnabled,
            riskLevel: payload.weight,
            parentAlarmId: payload.parentid,
            newAlarm: payload.alarmid === -1
        };
    }

    function save() {
        if (!isConfigValid()) {
            return;
        }

        var payload = generatePayload();
        if (payload === false) {
            return;
        }

        lastDisableSaveCount = true;
        trackEventService.track('Alarm Created', generateTrackParameters(payload));
        if (payload.alarmid === -1 || !!$state.params.duplicate) {
            // TODO: Need to fix this ID mess
            payload.alarmid = 0;
            payload.parentid = null;

            alarmService
                .createAlarm(payload)
                .success(function () {
                    navigateToAlarms(true);
                })
                .error(function (result) {
                    if (result.errors) {
                        result.errors.map(function (error) {
                            $scope.$emit('showNotification', {
                                message: error,
                                color: 'danger',
                                skipEscape: true
                            });
                        });
                    }
                    lastDisableSaveCount = false;
                });
        } else {
            alarmService
                .saveAlarm(payload)
                .success(function () {
                    navigateToAlarms(true);
                })
                .error(function (result) {
                    if (result.errors) {
                        result.errors.map(function (error) {
                            $scope.$emit('showNotification', {
                                message: error,
                                color: 'danger',
                                skipEscape: true
                            });
                        });
                    }
                    lastDisableSaveCount = false;
                });
        }
    }

    function registerComponent(component) {
        component.id = generateId();
        registeredComponents.push(component);
    }

    var componentDefaults = {
        Controls: {
            options: {
                showSaveButton: showSaveButton,
                getDuplicateName: getDuplicateName,
                disableSaveUpdated: disableSaveUpdated,
                save: save,
                cancel: navigateToAlarms,
                register: registerComponent
            },
            componentName: 'alarm-details-controls'
        },
        Groups: {
            options: {
                register: registerComponent,
                isConfigValid: isConfigValid,
                triggerAlarmUpdated: alarmUpdated
            },
            componentName: 'alarm-details-groups'
        },
        Conditions: {
            options: {
                register: registerComponent,
                emailsDataSource: emailsDataSource,
                getRestrictedOrFlagFields: getRestrictedOrFlagFields,
                isConfigValid: isConfigValid,
                triggerAlarmUpdated: alarmUpdated
            },
            componentName: 'alarm-details-conditions'
        },
        ActionSeparator: {
            componentName: 'alarm-details-action-separator'
        },
        ScreenCaptures: !screenshotsAllowed
            ? {}
            : {
                  options: {
                      register: registerComponent,
                      isPaidFunctionalityEnabled: $scope.isPaidFunctionalityEnabled,
                      getRestrictedOrFlagFields: getRestrictedOrFlagFields
                  },
                  componentName: 'alarm-details-screen-captures'
              },
        PopupMessage: !alarmActionsAllowed
            ? {}
            : {
                  options: {
                      register: registerComponent,
                      getMacroButtons: getMacroButtons,
                      macroButtonWillExceedFieldLength: macroButtonWillExceedFieldLength,
                      appendMacro: appendMacro
                  },
                  componentName: 'alarm-details-popup-message'
              },
        EmailNotification: {
            options: {
                register: registerComponent,
                getMacroButtons: getMacroButtons,
                macroButtonWillExceedFieldLength: macroButtonWillExceedFieldLength,
                appendMacro: appendMacro,
                emailsDataSource: emailsDataSource
            },
            componentName: 'alarm-details-email-notification'
        },
        WebhookNotification: {
            options: {
                getMacroButtons: getMacroButtons,
                macroButtonWillExceedFieldLength: macroButtonWillExceedFieldLength,
                appendMacro: appendMacro,
                register: registerComponent
            },
            componentName: 'alarm-details-webhook-notification'
        },
        Terminate: !alarmActionsAllowed
            ? {}
            : {
                  options: {
                      register: registerComponent
                  },
                  componentName: 'alarm-details-terminate'
              },
        RiskLevel: !$scope.isRiskLevelReportEnabled
            ? {}
            : {
                  options: {
                      register: registerComponent
                  },
                  componentName: 'alarm-details-risk-level'
              }
    };

    var readConditionsFromState = function () {
        var id = 0;
        $scope.alarm.conditions = [];

        if ($state.params.url) {
            $scope.alarm.conditions.push({
                id: id++,
                casesensitive: false,
                field: 'Url',
                fieldName: 'Url',
                op: 'Contains',
                opName: 'Contains',
                value: $state.params.url
            });
        }

        if ($state.params.title) {
            $scope.alarm.conditions.push({
                id: id++,
                casesensitive: false,
                field: 'Titlebar',
                fieldName: 'Titlebar',
                op: 'Contains',
                opName: 'Contains',
                value: $state.params.title
            });
        }

        if ($state.params.description) {
            $scope.alarm.conditions.push({
                id: id++,
                casesensitive: false,
                field: 'Description',
                fieldName: 'Description',
                op: 'Contains',
                opName: 'Contains',
                value: $state.params.description
            });
        }

        if ($state.params.executable) {
            $scope.alarm.conditions.push({
                id: id++,
                casesensitive: false,
                field: 'Executable',
                fieldName: 'Executable',
                op: 'Contains',
                opName: 'Contains',
                value: $state.params.executable
            });
        }

        $scope.alarm.orflag = $scope.alarm.conditions.length < 2;
    };

    function completeInitialization() {
        if ($scope.alarm.type === 'Audit') {
            $scope.alarm.weight = 0;
            $scope.alarm.weightEnabled = false;
        }

        $scope.alarm.editor.usbAlarm = {
            inserted: false,
            written: false
        };

        $scope.stateParams = $state.params;
        $scope.alarmConfig = alarmConfigDefinitions[$scope.alarm.type || 'Activity'] || alarmConfigDefinitions.Activity;
        $scope.alarmConfig.components.forEach(function (component) {
            if (!component.options) {
                component.options = {};
            }
            var defaults = componentDefaults[component.type];
            component.options =
                defaults && defaults.options ? Object.assign(component.options, defaults.options) : undefined;
            component.componentName = defaults && defaults.componentName ? defaults.componentName : undefined;
        });

        // Remove hidden components from alarm config
        lodash.remove($scope.alarmConfig.components, function (component) {
            return component.options && component.options.hidden;
        });

        $state.current.data.pageTitle = $scope.alarmConfig.type + ' Alarm Configuration';
        $scope.initializeComplete = true;

        // Remove duplicated alarm id.
        if ($state.params.duplicate) {
            $scope.alarm.alarmid = 0;
        }

        isConfigValid();
    }

    function initialize() {
        $scope.initializeComplete = false;
        var alarmId = parseInt($state.params.id);
        if (isNaN(alarmId)) {
            $scope.alarm = {
                alarmid: -1,
                name: $state.params.name,
                popuptext: '',
                emailbody: '',
                emailsubject: '',
                webhookurl: '',
                webhookparam: '',
                screenshotsec: 10,
                orflag: true,
                default: false,
                isUsb: $state.params.type === 'Usb',
                weight: 3,
                weightEnabled: true,
                type: $state.params.type,
                active: true,
                editor: {}
            };

            if ($state.params) {
                if ($state.params.alarm) {
                    var fields = ['conditions', 'screenshot', 'screenshotmultiple', 'screenshotsec', 'video', 'orflag'];
                    fields.forEach(function (field) {
                        $scope.alarm[field] = $state.params.alarm[field];
                    });
                } else {
                    readConditionsFromState();
                }
            } else if (typeof $rootScope.sampleAlarm !== 'undefined') {
                $scope.alarm = $rootScope.sampleAlarm;
                $scope.alarm.editor = {};
            }

            completeInitialization();
            return;
        }

        alarmService.getAlarm(alarmId).success(function (alarm) {
            if (alarm.length === 0) {
                navigateToAlarms();
                return;
            }

            $scope.alarm = alarm[0];
            $scope.alarm = decodeHtmlEncodedAlarmParams($scope.alarm);
            $scope.alarm.editor = {};

            if (atHelperFunctions.isEmpty($scope.alarm.name)) {
                $scope.alarm.editor.allowBlankName = true;
            }

            if ($state.params && $state.params.alarm) {
                var fields = ['conditions', 'screenshot', 'screenshotmultiple', 'screenshotsec', 'video'];
                fields.forEach(function (field) {
                    $scope.alarm[field] = $state.params.alarm[field];
                });
            }

            completeInitialization();
        });
    }

    $scope.$watch(
        'alarm',
        function (newAlarm, oldAlarm) {
            alarmUpdated(oldAlarm);
        },
        true
    );

    initialize();
}
