import React from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

type TextFieldContainerProps = {
  showSearch?: boolean;
  setShowSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
  setShowCategorySearch?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TextFieldContainer = ({
  showSearch,
  setShowSearch,
  placeholder,
  setShowCategorySearch
}: TextFieldContainerProps) => {
  const handleClick = () => {
    if (setShowSearch) setShowSearch(!showSearch);
    if (setShowCategorySearch) setShowCategorySearch(showSearch);
  };

  return (
    <TextField
      placeholder={placeholder || 'Teams'}
      variant="outlined"
      fullWidth
      onClick={handleClick}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <IconButton>
            {!showSearch ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        )
      }}
    />
  );
};

export default TextFieldContainer;
