import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import availableMenus from '../../../../../_app/navigation/navigation-files/availableMenus.json';
import { IMenuItem } from './Header/models/IMenuItem';

export const menuItemsStore = createStore(() => ({
  menuItems: []
}));

const sideBarResetFunctionStore = createStore(() => ({
  sidebarReset: null
}));


const useMenuItemStore = (s) => useStore<any, any>(menuItemsStore, s);

const sidebarReset = () => {
  const { sidebarReset } = sideBarResetFunctionStore.getState();
  if (sidebarReset) {
    sidebarReset();
  }
};

const find = (items: any[], predicate: (item: any) => boolean) => {
  return items.reduce((current, item) => {
    if (current) return current;
    if (predicate(item)) return item;
    if (item.submenus) return find(item.submenus, predicate);
  }, null);
};

export const findItemByName = (name: string) => {
  const items = menuItemsStore.getState().menuItems;
  return find(items, (item) => item.action?.options?.ref === name);
};

const findItemById = (id: string) => {
  const items = menuItemsStore.getState().menuItems;
  return find(items, (item) => item.id == id);
};

//List containing items excluded from fav navigation
// TODO: Move definitions to new router state definitions (TBD)
const EXCLUDED_ITEM_FILTER = [
  'ActivTrak Profile',
  'Logout',
  'Activation',
  'Upgrade Plan',
  'Home',
  'Admin Panel',
  'Organization Overview',
  'Team Management',
  'Activity Dashboard'
];

const isExcludedPage = (label: string): boolean => {
  return EXCLUDED_ITEM_FILTER.includes(label);
};


const pageTitleStore = createStore(() => ({
  pageTitle: '' as string
}));

const usePageTitleStore = (s: any) => useStore<any, any>(pageTitleStore, s);

export const setPageTitle = (title: string) => {
  pageTitleStore.setState({ pageTitle: title });
};


export {
  useMenuItemStore,
  findItemById,
  isExcludedPage,
  find,
  usePageTitleStore,
  sidebarReset
};

export const ensureOpen = (state:string) => {

  const items = menuItemsStore.getState().menuItems;

  const active = find(
    items,
    (item) => item.action?.options?.ref === state
  );
  if (active?.parentId) {
    let parent = find(items, (item) => item.id === active.parentId);
    while (parent) {
      parent.submenuOpen = true;
      if (parent.parentId) {
        parent = find(items, (item) => item.id === parent.parentId);
      } else {
        break;
      }
    }
  }

  menuItemsStore.setState({ menuItems: [...items]});
}

/** @deprecated retrieves metadata from the json file - instead map fields to IMenuItem DTO  */
export const fetchMenuRecord = (routeName:string): IMenuItem => {
  return Object.values(availableMenus).find(
    (item: IMenuItem) => item.action?.options?.ref === routeName
  );
};
