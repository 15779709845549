import { getReportFilters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import { generateParameters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import template from 'views/widgets/dashboard/aggregateProductivity.html?raw';

angular.module('app').directive('aggregateProductivity', aggregateProductivity);

function aggregateProductivity() {
    return {
        restrict: 'E',
        template,
        scope: {
            period: '@',
            aggregatePeriod: '=',
            isMobile: '=',
            transitions: '='
        },
        controller: [
            '$scope',
            '$state',
            'atDashboardWidgetService',
            'trackEventService',
            function ($scope, $state, atDashboardWidgetService, trackEventService) {
                $scope.productivityChartSeriesClick = function (e) {
                    trackEventService.track('p-graph');
                    $state.go('app.reports.productivityLegacy', {
                        date: encodeURIComponent(e.category),
                        aggregatePeriod: $scope.aggregatePeriod
                    });
                };

                var bindWidget = function () {
                    if ($scope.aggregatePeriod === undefined) {
                        return;
                    }

                    $scope.productivityChartParams = {
                        queryParameters:
                            generateParameters(getReportFilters(), {}) + '&period=' + $scope.aggregatePeriod,
                        seriesClick: $scope.productivityChartSeriesClick,
                        isMobile: $scope.isMobile,
                        showDetails: false,
                        transitions: $scope.transitions
                    };
                };

                atDashboardWidgetService.registerWidget('aggregateProductivity', 2, bindWidget);

                $scope.$watch('aggregatePeriod', function () {
                    bindWidget();
                });
            }
        ]
    };
}
