import { useCallback, useState } from 'react';
import { refreshScreenshotSettingsStore } from '../screenshotSettingsStore';
import { useScreenshotSettingsStore } from '../../hooks/useScreenshotSettingsStore';
import {
  IRealTimeSettingsState,
  RealTimeSettingsStateProps
} from './realtimeSettingsStore.models';

export const useRealTimeSettingsState = ({
  service,
  notificationService
}: RealTimeSettingsStateProps): IRealTimeSettingsState => {
  const { postRealTimeSettings } = service;

  const { screenshotsEnabled } = useScreenshotSettingsStore((s) => s);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateRealTimeSettings = useCallback(
    async (value: boolean) => {
      setIsLoading(true);
      try {
        await postRealTimeSettings({ screenshotFeatureFlag: value });
        notificationService.success(
          'Success! Your Real Time Setting is Updated!'
        );
      } catch (error) {
        notificationService.error('Unable to update Real Time Settings');
        console.error(
          'ActivTrak Error: Unable to update Real Time Settings',
          error
        );
      } finally {
        await refreshScreenshotSettingsStore();
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    screenshotsEnabled,
    isLoading,
    updateRealTimeSettings
  };
};
