import { Moment } from 'moment';
import 'moment-timezone';
import {
  getDateFormat,
  getDateTimeFormat,
  getTimeFormat
} from './datetimeFormatStrings';
import moment from 'moment';
import { getDateSettingsStore } from '../../stores/dateSettingsStore';
import {
  IFormatDateOptions,
  IFormatDateTimeOptions,
  IFormatTimeOptions
} from './models/IFormatDateTimeOptions';

/**
 * Formats the provided date in the account's timezone using the account's TIME format settings.
 */
export const formatTime = (
  date: string | Moment,
  options: IFormatTimeOptions = {}
): string => {
  const { excludeSeconds: removeSeconds, format } = options;
  const baseFormat = format ?? getTimeFormat();
  const timeFormat = removeSeconds ? baseFormat.replace(':ss', '') : baseFormat;
  const dateMoment =
    typeof date === 'string' ? moment.parseZone(date) : moment(date);
  return dateMoment.isValid() ? dateMoment.format(timeFormat) : null;
};

/**
 * Formats the provided UTC date string in the account's timezone using the account's TIME format settings.
 */
export const formatTimeByTimezone = (
  utcDate: string,
  options?: IFormatTimeOptions
): string => {
  const { timezoneKey } = getDateSettingsStore();
  return formatTime(moment.tz(moment.parseZone(utcDate), timezoneKey), options);
};

/**
 * Formats the provided date in the account's timezone using the account's DATE format settings.
 */
export const formatDate = (
  date: string | Moment | Date,
  options: IFormatDateOptions = {}
): string => {
  const { excludeYear, format } = options;
  const baseFormat = format ?? getDateFormat();
  const dateFormat = excludeYear ? baseFormat.replace('/YYYY', '') : baseFormat;
  const dateMoment =
    typeof date === 'string' ? moment.parseZone(date) : moment(date);
  return dateMoment.isValid() ? dateMoment.format(dateFormat) : null;
};

/**
 * Formats the provided UTC date string in the account's timezone using the account's DATE format settings.
 */
export const formatDateByTimezone = (
  utcDate: string,
  options?: IFormatDateOptions
): string => {
  const { timezoneKey } = getDateSettingsStore();
  return formatDate(moment.utc(utcDate).tz(timezoneKey), options);
};

/**
 * Formats the provided date in the account's timezone using the account's DATE/TIME format settings.
 */
export const formatDateTime = (
  date: string | Moment | Date,
  options: IFormatDateTimeOptions = {}
): string => {
  const { excludeSeconds, excludeYear, format } = options;
  const baseFormat = format ?? getDateTimeFormat();
  const timeFormat = excludeSeconds
    ? baseFormat.replace(':ss', '')
    : baseFormat;
  const dateTimeFormat = excludeYear
    ? timeFormat.replace('/YYYY', '')
    : timeFormat;
  const dateMoment =
    typeof date === 'string' ? moment.parseZone(date) : moment(date);
  return dateMoment.isValid() ? dateMoment.format(dateTimeFormat) : null;
};

/**
 * Formats the provided UTC date string in the account's timezone using the account's DATE/TIME format settings.
 */
export const formatDateTimeByTimezone = (
  utcDate: string,
  options?: IFormatDateTimeOptions
): string => {
  const { timezoneKey } = getDateSettingsStore();
  return formatDateTime(moment.utc(utcDate).tz(timezoneKey), options);
};
