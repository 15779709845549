import { IAccountSettingsDto } from '../models';
import authorizationService from '../helpers/authorization';
import { BundleFlag } from '../enums/BundleFlag';

const environment_name = import.meta.env.VITE_NAME;

export const initialize = (accountSettings: IAccountSettingsDto) => {
  try {
    // This conditional will go away
    // Keeping them separate...
    if (
      !(
        accountSettings.creator?.endsWith('activtrak.com') ||
        accountSettings.creator?.endsWith('bgrove.com')
      )
    ) {
      return;
    }

    if (!authorizationService.hasFeature(BundleFlag.PaidFunctionality)) {
        return;
    }

    const isSupportOrSuperAdmin = authorizationService.isAnySupportUser();

    // Pendo only supports one accountId field per application (using concat to avoid collisions):
    // For lower environments: dev_XXXXXXX rain_XXXXXX eph_XXXXXXX
    // For production no prefix is added (for cleaner reporting)
    // If impersonating via support will prepend `support_`
    const support_prefix = `${isSupportOrSuperAdmin ? 'support_' : ''}`;
    const environment_prefix = `${environment_name !== 'prod' ? `${environment_name}_` : ''}`;
    const account_prefix = `${support_prefix}${environment_prefix}`;
    const account_id = `${account_prefix}${accountSettings.account}`;

    window.pendo.initialize({
      visitor: {
        id: accountSettings.username,
        email: accountSettings.username,
        firstName: accountSettings.firstName
      },
      account: {
        id: account_id,
        accountName: `[${account_prefix}] ${accountSettings.companyName}`
      }
    });
    console.info('[ActivTrak]: pendo initialized:', new Date());

  } catch (ex) {
    console.error('Cannot initialize library.', ex);
  }
};
