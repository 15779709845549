import { setUserToken } from '../../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';

angular.module('core').service('responseErrorService', ResponseErrorService);

ResponseErrorService.$inject = ['$q', '$injector'];

function ResponseErrorService($q, $injector) {
    this.handleResponseError = function (response) {
        if (response.status === 401) {
            handle401Response();
        }
        return $q.reject(response);
    };


    function handle401Response() {
        var $state = $injector.get('$state');

        // Do not reload if 401 returned while on login or touch page.
        if ($state.current.name === 'login' && !$state.transition) {
            return;
        }

        setUserToken('invalid');
        var loginService = $injector.get('loginService');
        loginService.clearScope();

        const state = window.history.state;
        window.history.replaceState(state, '', '/#/login');
        window.location.reload();

    }
}
