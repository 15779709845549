import React from 'react';
import { ClockIcon } from '@mui/x-date-pickers';
import { ReportTimeSlot } from '../models/reportTimelineFilters.models';
import { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { SelectInlineLabelSC } from '../../../common/components/Select/SelectInlineLabel.styles';

type ReportHourSelectorProps = {
  idSelector: string;
  value?: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  timeList: ReportTimeSlot[];
  containerStyles?: object;
};

export const ReportHourSelector = (
  props: ReportHourSelectorProps
): JSX.Element => {
  const {
    idSelector,
    value,
    onChange,
    defaultValue,
    timeList,
    containerStyles = {}
  } = props;

  const handleChange = (event: SelectChangeEvent) =>
    onChange(event.target.value);

  return (
    <FormControl
      sx={{ verticalAlign: 'middle', ...containerStyles }}
      variant="outlined"
      size="small"
    >
      <SelectInlineLabelSC
        id={`b61d907d-e0d8-40b0-8d41-e2d4f216cbef-select-${idSelector}`}
        value={value ?? defaultValue}
        inputProps={{ sx: { minWidth: '140px' } }}
        onChange={handleChange}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        IconComponent={() => (
          <ClockIcon sx={{ fontSize: '16px', m: '0px 16px 2px -24px' }} />
        )}
        MenuProps={{
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          sx: { zIndex: 1500 }
        }}
      >
        {timeList?.map((timeSlot) => (
          <MenuItem key={`hour-${timeSlot.value}`} value={timeSlot.value}>
            {timeSlot.label}
          </MenuItem>
        ))}
      </SelectInlineLabelSC>
    </FormControl>
  );
};
