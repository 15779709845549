import { BehaviorSubject, Observable } from 'rxjs';
import { sorter } from '../../common/utils/sorter/sorter';
import { mapToCategory } from '../../common/helpers/classifications';
import { BaseClient, getDefaultConfig } from '../../common/config';
import { HttpResponse } from '../../common/models/IApiService';
import { ICategory, ICategoryDto } from '../../common/models/ICategory';
import { withIgnoreCategory } from '../utils';
import { IGNORE_CATEGORY_ID } from '../../common/constants/category';
import { SortDirection } from '../../common/enums/SortDirection';

export interface ICategoryService {
  getAll(): Observable<ICategory[]>;
  create(name: string): Promise<ICategory>;
  delete(payload: string[]): Promise<void>;
  update(id: string, name: string): Promise<void>;
  setSelected(ids: string[]): void;
}

export default class CategoryService
  extends BaseClient
  implements ICategoryService
{
  private categories: BehaviorSubject<ICategory[]>;
  private fetchingAll: boolean;

  constructor() {
    super();

    this.categories = new BehaviorSubject<ICategory[]>(undefined);
    this.fetchingAll = false;
  }

  private setCategories = (categories: ICategory[]): void => {
    categories = withIgnoreCategory(categories);
    categories = sorter(categories, SortDirection.Ascending, 'name');
    this.categories.next([...categories]);
  };

  private fetchAll = (): Promise<ICategory[]> => {
    return new Promise((resolve) => {
      this.apiService
        .get<ICategoryDto[]>('/api/settings/category/activity/detail', {
          ...getDefaultConfig()
        })
        .then((response: HttpResponse<ICategoryDto[]>) => {
          const categories = response.data.map(mapToCategory);
          resolve(categories);
        });
    });
  };

  public getAll = (): Observable<ICategory[]> => {
    if (!this.categories.value && !this.fetchingAll) {
      this.fetchingAll = true;
      this.categories.next([]);
      this.fetchAll()
        .then((categories) => {
          this.setCategories(categories);
        })
        .finally(() => (this.fetchingAll = false));
    }

    return this.categories.asObservable();
  };

  public create = async (name: string): Promise<ICategory> => {
    const response = await this.apiService.post<ICategory>(
      `/api/settings/categories`,
      {
        ...getDefaultConfig(),
        ...{ data: { name } }
      }
    );

    const category: ICategory = {
      ...response.data,
      appCount: 0,
      websiteCount: 0,
      selected: false,
      disabled: false
    };
    const categories = this.categories.value;
    categories.push(category);
    this.setCategories(categories);
    return category;
  };

  public delete = async (payload: string[]): Promise<void> => {
    // TODO: Eww. Gross. Barf. Removing locally first because this call takes forever. Fire and forget.
    const categories = this.categories.value.filter(
      (c) => !payload.includes(c.id)
    );
    this.setCategories(categories);

    await this.apiService.delete(`/api/settings/categories`, {
      ...getDefaultConfig(),
      ...{ data: payload }
    });
  };

  public update = async (id: string, name: string): Promise<void> => {
    await this.apiService.post('/api/settings/category', {
      ...getDefaultConfig(),
      ...{ data: { id, name } }
    });

    const categories = this.categories.value.map((c) => {
      if (c.id === id) {
        c.name = name;
      }
      return c;
    });
    this.setCategories(categories);
  };

  public setSelected = (ids: string[]): void => {
    const categories = this.categories.value.map((item) => {
      return { ...item, selected: false };
    });
    ids.forEach((id) => {
      const i = categories.findIndex((category) => id === category.id);
      if (i === -1 || id === IGNORE_CATEGORY_ID) {
        return;
      }
      categories[i].selected = true;
    });
    this.setCategories(categories);
  };
}
