import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { removeColons } from '../utils/reportTimelineFilter.utils';
import { REPORT_TIMELINE_DEFAULT_FILTERS } from '../constants/reports.constants';
import { ReportHourSelector } from './ReportHourSelector';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReportTimeSlot } from '../models/reportTimelineFilters.models';

type ReportTimelineFilterProps = {
  selectedTimeRange: { shiftStart: string; shiftEnd: string };
  startTimesList: ReportTimeSlot[];
  endTimesList: ReportTimeSlot[];
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
};

const ReportTimelineFilter = (props: ReportTimelineFilterProps) => {
  const {
    selectedTimeRange,
    startTimesList,
    endTimesList,
    onStartDateChange,
    onEndDateChange
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: 'inline-block' }}>
          <ReportHourSelector
            idSelector={`start-${removeColons(selectedTimeRange?.shiftStart)}`}
            value={selectedTimeRange?.shiftStart}
            onChange={onStartDateChange}
            defaultValue={REPORT_TIMELINE_DEFAULT_FILTERS.shiftStart}
            timeList={startTimesList}
          />
          <ReportHourSelector
            idSelector={`end-${removeColons(selectedTimeRange?.shiftEnd)}`}
            value={selectedTimeRange?.shiftEnd}
            onChange={onEndDateChange}
            defaultValue={REPORT_TIMELINE_DEFAULT_FILTERS.shiftEnd}
            timeList={endTimesList}
            containerStyles={{ ml: 1 }}
          />
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};

export default ReportTimelineFilter;
