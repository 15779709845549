import { ColDef } from 'ag-grid-community';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import { durationFormat } from '../../../common/utils/datetime/time';
import authorization from '../../../common/helpers/authorization';
import { isTodayByTimezone } from '../../../common/utils/datetime/isToday';
import { smallFontSize } from '../../../common/constants';
import { getReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import {
  getAccessForFeature,
  getProductivityTimeTemplate
} from '../../common/utils/reports.utils';
import { productivityRatioComparator } from './topUsersReport.utils';
import { OfflineMeetingsHeader } from '../../common/components/OfflineMeetingsHeader';
import { CustomHeaderTemplate } from '../../../common/components/AtGrid/CustomHeaderTemplate';
import Typography from '@mui/material/Typography';
import { IReportColumnLabel } from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { TopUsersDataId } from '../models/TopUsersReport.models';
import { TopUsersProductivityRatio } from '../components/TopUsersProductivityRatio';

const getHasOfflineAccess = () =>
  getAccessForFeature(
    BundleFlag.OfflineMeetingsData,
    BundleFlag.OfflineMeetingsPromo,
    FeatureFlag.ShowOfflineMeetingData
  );

//NOTE: Because the Columns Selector uses the 'hide' property to determine if a column is visible, a 'hasAccess' property is used to separately determine if a column should be included in the total list of columns to display.
export const getTopUsersColumns = (largestDuration): ColDef[] => {
  const hasOfflineAccess = getHasOfflineAccess();

  const { dates } = getReportFilters();
  const { fromDate, toDate } = dates.getDates();

  const cols = [
    {
      colId: TopUsersDataId.User,
      field: TopUsersDataId.User,
      headerName: 'User',
      sortable: true,
      minWidth: 180
    },
    {
      colId: TopUsersDataId.UserId,
      field: TopUsersDataId.UserId,
      headerName: 'Productivity Ratio',
      sortable: true,
      comparator: productivityRatioComparator,
      minWidth: 180,
      cellRenderer: (params) =>
        TopUsersProductivityRatio(params, largestDuration)
    },
    {
      colId: TopUsersDataId.Productive,
      field: TopUsersDataId.Productive,
      headerName: 'Productive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.productive, 1);
      },
      minWidth: 175
    },
    {
      colId: TopUsersDataId.ProductiveActive,
      field: TopUsersDataId.ProductiveActive,
      headerName: 'Productive Active',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.productiveActive, 1);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.ProductivePassive,
      field: TopUsersDataId.ProductivePassive,
      headerName: 'Productive Passive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.productivePassive, 2);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.Unproductive,
      field: TopUsersDataId.Unproductive,
      headerName: 'Unproductive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.unproductive, -1);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.UnproductiveActive,
      field: TopUsersDataId.UnproductiveActive,
      headerName: 'Unproductive Active',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.unproductiveActive, -1);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.UnproductivePassive,
      field: TopUsersDataId.UnproductivePassive,
      headerName: 'Unproductive Passive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.unproductivePassive, -2);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.Undefined,
      field: TopUsersDataId.Undefined,
      headerName: 'Undefined',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Undefined
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.undefined, 0);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.UndefinedActive,
      field: TopUsersDataId.UndefinedActive,
      headerName: 'Undefined Active',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Undefined Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.undefinedActive, 0);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.UndefinedPassive,
      field: TopUsersDataId.UndefinedPassive,
      headerName: 'Undefined Passive',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Undefined Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(TOOLTIP)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(dataItem.undefinedPassive, -3);
      },
      minWidth: 200
    },
    {
      colId: TopUsersDataId.Total,
      field: TopUsersDataId.Total,
      headerName: 'Total Time',
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.total, false);
      },
      minWidth: 150
    },
    {
      colId: TopUsersDataId.Active,
      field: TopUsersDataId.Active,
      headerName: 'Active Time',
      type: 'rightAligned',
      sortable: true,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.active, false);
      },
      minWidth: 150
    },
    {
      colId: TopUsersDataId.Offline,
      field: TopUsersDataId.Offline,
      headerName: 'Offline Meetings',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <OfflineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.OfflineMeetingsData
        );
        const isTodayDate =
          isTodayByTimezone(fromDate) && isTodayByTimezone(toDate);

        if (!hasFeatureEnabled || isTodayDate) return '';

        return getProductivityTimeTemplate(dataItem.offline || 0, -4);
      },
      minWidth: 150,
      hasAccess: hasOfflineAccess
    }
  ];
  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};

export const getTopUsersColumnLabels = (): IReportColumnLabel[] => {
  const hasOfflineAccess = getHasOfflineAccess();
  const cols = [
    {
      colId: TopUsersDataId.User,
      label: 'User'
    },
    {
      colId: TopUsersDataId.UserId,
      label: 'Productivity Ratio'
    },
    {
      colId: TopUsersDataId.Productive,
      label: 'Productive'
    },
    {
      colId: TopUsersDataId.ProductiveActive,
      label: 'Productive Active'
    },
    {
      colId: TopUsersDataId.ProductivePassive,
      label: 'Productive Passive'
    },
    {
      colId: TopUsersDataId.Unproductive,
      label: 'Unproductive'
    },
    {
      colId: TopUsersDataId.UnproductiveActive,
      label: 'Unproductive Active'
    },
    {
      colId: TopUsersDataId.UnproductivePassive,
      label: 'Unproductive Passive'
    },
    {
      colId: TopUsersDataId.Undefined,
      label: 'Undefined'
    },
    {
      colId: TopUsersDataId.UndefinedActive,
      label: 'Undefined Active'
    },
    {
      colId: TopUsersDataId.UndefinedPassive,
      label: 'Undefined Passive'
    },
    {
      colId: TopUsersDataId.Total,
      label: 'Total Time'
    },
    {
      colId: TopUsersDataId.Active,
      label: 'Active Time'
    },
    {
      colId: TopUsersDataId.Offline,
      label: 'Offline Meetings',
      hasAccess: hasOfflineAccess
    }
  ];
  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};
