// Import of old AngularJS templates
// Do not add new templates here
// React components are imported directly using react2angular '
// with the template being defined as '<component-name></component-name>'

import access from '../../../../../../app/views/account/access.html?raw';
import activitylog from '../../../../../../app/views/reports/activitylog.html?raw';
import agentactivation from '../../../../../../app/views/agentactivation.html?raw';
import alarmDetails from '../../../../../../app/views/alarmDetails/alarmDetails.html?raw';
import alarmlog from '../../../../../../app/views/reports/alarmlog.html?raw';
import app from '../../../../../../app/views/app.html?raw';
import auditLog from '../../../../../../app/views/account/auditLog.html?raw';
import blocking from '../../../../../../app/views/blocking.html?raw';
import cart from '../../../../../../app/views/upgrade/cart.html?raw';
import coachingPromo from '../../../../../../app/views/reports/insights/coachingPromo.html?raw';
import dashboard from '../../../../../../app/views/dashboard.html?raw';
import doNotTrack from '../../../../../../app/views/settings/doNotTrack.html?raw';
import downloads from '../../../../../../app/views/downloads.html?raw';
import featureRequests from '../../../../../../app/views/help/featureRequests.html?raw';
import impactPromo from '../../../../../../app/views/reports/insights/impactPromo.html?raw';
import insightsPromoReport from '../../../../../../app/views/reports/insights/insightsPromoReport.html?raw';
import insightsReport from '../../../../../../app/views/reports/insights/insightsReport.html?raw';
import insightsSettings from '../../../../../../app/views/reports/insights/insightsSettings.html?raw';
import integrations from '../../../../../../app/views/integrations.html?raw';
import latestUpdates from '../../../../../../app/views/help/latestUpdates.html?raw';
import login from '../../../../../../app/views/login.html?raw';
import printableDashboard from '../../../../../../app/views/printableDashboard.html?raw';
import productivityLegacy from '../../../../../../app/views/reports/productivity.html?raw';
import riskLevel from '../../../../../../app/views/reports/riskLevelReport/riskLevel.html?raw';
import schedule from '../../../../../../app/views/settings/schedule.html?raw';
import scheduling from '../../../../../../app/views/settings/scheduling.html?raw';
import screenshots from '../../../../../../app/views/screenshots.html?raw';
import screenshotsLatest from '../../../../../../app/views/screenshots/screenshotsLatest.html?raw';
import screenshotsRedaction from '../../../../../../app/views/screenshots/screenshotsRedaction.html?raw';
import screenshotsSafeSearch from '../../../../../../app/views/screenshots/screenshotsSafeSearch.html?raw';
import security from '../../../../../../app/views/settings/security.html?raw';
import settingsalarms from '../../../../../../app/views/settings/settings-alarms.html?raw';
import sqlData from '../../../../../../app/views/reports/sqlData.html?raw';
import teamPulse from '../../../../../../app/views/reports/teamPulse.html?raw';
import teamPulsePromo from '../../../../../../app/views/reports/teamPulsePromo.html?raw';
import topapplications from '../../../../../../app/views/reports/topapplications.html?raw';
import topcategories from '../../../../../../app/views/reports/topcategories.html?raw';
import topUsersLegacy from '../../../../../../app/views/reports/topusers.html?raw';
import topwebsites from '../../../../../../app/views/reports/topwebsites.html?raw';
import unsupportedBrowser from '../../../../../../app/views/unsupportedBrowser.html?raw';
import videos from '../../../../../../app/views/alarmVideo/videos.html?raw';
import webhookLog from '../../../../../../app/views/account/webhookLog.html?raw';
import workingHoursLegacy from '../../../../../../app/views/reports/workingHoursReport/workingHoursLegacy.html?raw';
import supportModal from '../../../../../../app/views/modals/supportModal.html?raw';

export const accessTemplate = access;
export const activitylogTemplate = activitylog;
export const agentactivationTemplate = agentactivation;
export const alarmDetailsTemplate = alarmDetails;
export const alarmlogTemplate = alarmlog;
export const appTemplate = app;
export const auditLogTemplate = auditLog;
export const blockingTemplate = blocking;
export const cartTemplate = cart;
export const coachingPromoTemplate = coachingPromo;
export const dashboardTemplate = dashboard;
export const doNotTrackTemplate = doNotTrack;
export const downloadsTemplate = downloads;
export const featureRequestsTemplate = featureRequests;
export const impactPromoTemplate = impactPromo;
export const insightsPromoReportTemplate = insightsPromoReport;
export const insightsReportTemplate = insightsReport;
export const insightsSettingsTemplate = insightsSettings;
export const integrationsTemplate = integrations;
export const latestUpdatesTemplate = latestUpdates;
export const loginTemplate = login;
export const printableDashboardTemplate = printableDashboard;
export const productivityLegacyTemplate = productivityLegacy;
export const riskLevelTemplate = riskLevel;
export const scheduleTemplate = schedule;
export const schedulingTemplate = scheduling;
export const screenshotsTemplate = screenshots;
export const screenshotsLatestTemplate = screenshotsLatest;
export const screenshotsRedactionTemplate = screenshotsRedaction;
export const screenshotsSafeSearchTemplate = screenshotsSafeSearch;
export const securityTemplate = security;
export const settingsalarmsTemplate = settingsalarms;
export const sqlDataTemplate = sqlData;
export const teamPulseTemplate = teamPulse;
export const teamPulsePromoTemplate = teamPulsePromo;
export const topapplicationsTemplate = topapplications;
export const topcategoriesTemplate = topcategories;
export const topUsersLegacyTemplate = topUsersLegacy;
export const topwebsitesTemplate = topwebsites;
export const unsupportedBrowserTemplate = unsupportedBrowser;
export const videosTemplate = videos;
export const webhookLogTemplate = webhookLog;
export const workingHoursLegacyTemplate = workingHoursLegacy;
export const supportModalTemplate = supportModal;
