import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { NotificationType } from '../../common/enums';
import { deleteIdentityData, postIdentityData } from '../../common/helpers';
import { useState } from 'react';
import { IUserIdentity } from '../models/IUserIdentity';
import { mapToIdentity } from '../utils/IdentityMappers';
import { IAgentSearch } from '../models/IAgentSearch';
import {
  IUserIdentityDto,
  IUserIdentityEditDto
} from '../models/IUserIdentityDto';
import {
  CreateDetailErrorMessage,
  SetUserIdentityErrorMessage
} from '../utils/ErrorMessages';

export type CommonStateProps = {
  dateTimeFormat: string;
  dateFormat: string;
};

//the common state is shared between the list and detail views
//we'll use this for notifications, snackbar, and common apis
export const useIdentityCommonState = (
  props: CommonStateProps
): IIdentityCommonState => {
  const { dateFormat } = props;
  const [identityNotification, setIdentityNotification] =
    useState<NotificationType>(undefined);
  const [selectedIdentityId, setSelectedIdentityId] = useState<number>(0);
  const [snackBarMessage, setSnackBarMessage] = useState<string>(null);
  const [snackBarHyperlinkText, setSnackBarHyperlinkText] =
    useState<string>(null);
  const [snackBarUrl, setSnackBarUrl] = useState<string>(null);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);

  const onSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const deleteIdentityUser = async (identityId: number) => {
    const fullPath = `/identity/v1/entities/${identityId}`;
    await deleteIdentityData<any>({
      path: fullPath
    });
  };

  //force build

  const onCreateNewUser = async (
    details: IUserIdentityEditDto,
    user: IUserIdentity,
    agentsSelected: IAgentSearch[]
  ): Promise<[IUserIdentity, any]> => {
    let exception: any = null;
    try {
      const userAgent = agentsSelected?.length > 0 ? agentsSelected[0] : null;

      if (userAgent) {
        details.userid = userAgent?.userId
          ? { value: userAgent?.userId?.toString() }
          : null;
        details.entity = userAgent?.entityId
          ? { value: userAgent?.entityId?.toString() }
          : null;
        details.revision = userAgent?.revision
          ? { value: userAgent?.revision?.toString() }
          : null;
      }
      //userIdentityDto = mapToIdentityCreateDto(details, agentSelected);

      const data = await postIdentityData<IUserIdentityDto>({
        path: `/identity/v1/entities/`,
        args: details
      });
      const newUserIdentity = mapToIdentity(data);
      showSnackBarWithResults(newUserIdentity, null, true);
      return [newUserIdentity, null];
    } catch (error) {
      exception = error;
      const message = CreateDetailErrorMessage(error);
      if (SetUserIdentityErrorMessage(message, user, details)) {
        return [user, exception];
      } else {
        setIdentityNotification({ msg: message, type: 'error' });
        console.error(message, error);
      }
    }
    return [user, exception];
  };

  const showSnackBarWithResults = (
    newUserIdentity: IUserIdentity,
    agentMovedDescription: string,
    newUserCreated: boolean
  ) => {
    if (!newUserIdentity) return;
    setSnackBarHyperlinkText(
      'Go to the ' +
        (newUserCreated ? ' newly created' : '') +
        ' user detail page for ' +
        newUserIdentity.singleDescription
    );
    let snackBarMessage = '';
    if (newUserCreated) {
      snackBarMessage =
        'Success! The user ' +
        newUserIdentity.singleDescription +
        ' has been created';
    } else {
      snackBarMessage =
        'Success! The agent ' + agentMovedDescription + ' has been moved.';
    }
    setSnackBarMessage(snackBarMessage);
    setSnackBarUrl('#/app/settings/users/' + newUserIdentity?.id);
    setSnackBarOpen(true);
  };

  return {
    identityNotification,
    setIdentityNotification,
    selectedIdentityId,
    setSelectedIdentityId,
    deleteIdentityUser,
    onCreateNewUser,
    dateFormat,
    snackBarMessage,
    snackBarHyperlinkText,
    snackBarUrl,
    snackBarOpen,
    onSnackBarClose,
    showSnackBarWithResults
  };
};
