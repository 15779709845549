import { createStore } from 'zustand/vanilla';
import { ITopUserReportStore } from '../models/TopUsersReport.models';
import { TOP_USERS_REPORT_STORE_DEFAULT } from '../constants/topUsersReport.constants';

export const topUsersReportStore = createStore<ITopUserReportStore>(
  () => TOP_USERS_REPORT_STORE_DEFAULT
);

export const getTopUsersReportStore = () => topUsersReportStore.getState();

export const setTopUsersReportStore = (
  newState: Partial<ITopUserReportStore>
) => {
  topUsersReportStore.setState(newState);
};
