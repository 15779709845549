import React from 'react';
import { IGridColumnFilter } from '../models/IGridColumnFilter.models';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  GridColumnsFilterSC,
  GridColumnsFilterActionsSC
} from '../../ReportFilters/styles';

type GridColumnsFilterProps = {
  columns: IGridColumnFilter[];
  isOpen: boolean;
  isDisabled?: boolean;
  onSelect: (colId: string) => void;
  onSubmit: () => void;
  onRestoreDefaults: () => void;
  onClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const GridColumnsFilter = (props: GridColumnsFilterProps) => {
  const {
    columns,
    isOpen,
    isDisabled = false,
    onSelect,
    onSubmit,
    onRestoreDefaults,
    onClose,
    setIsOpen
  } = props;

  const hasNoSelections = columns.every((column) => column.hide === true);

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <GridColumnsFilterSC
            PopperProps={{
              disablePortal: true
            }}
            onClose={onClose}
            open={isOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom-start"
            title={
              <>
                <Typography variant="h6" sx={{ margin: '8px 24px' }}>
                  Select Columns
                </Typography>
                <List sx={{ overflowY: 'auto', height: 320 }}>
                  {columns.map((column) => (
                    <ListItemButton
                      onClick={() => onSelect(column.colId)}
                      key={column.colId}
                      sx={{ pt: 0, pb: 0 }}
                      id={`6d0fea87-c18f-4f8b-b9cb-09111a568244-select-${
                        column.colId
                      }-${!column.hide}`}
                    >
                      <Checkbox color="primary" checked={!column.hide} />
                      <ListItemText>{column.label}</ListItemText>
                    </ListItemButton>
                  ))}
                </List>
                <GridColumnsFilterActionsSC>
                  <Button
                    variant="outlined"
                    onClick={onRestoreDefaults}
                    sx={{
                      paddingLeft: '8px !important',
                      paddingRight: '8px !important'
                    }}
                    id="6d0fea87-c18f-4f8b-b9cb-09111a568244-restore-defaults"
                  >
                    Restore defaults
                  </Button>
                  <Box>
                    <Button
                      onClick={onClose}
                      id="6d0fea87-c18f-4f8b-b9cb-09111a568244-reset"
                    >
                      Reset
                    </Button>
                    <ApplyButton
                      isDisabled={hasNoSelections}
                      onSubmit={onSubmit}
                    />
                  </Box>
                </GridColumnsFilterActionsSC>
              </>
            }
          >
            <Button
              variant="outlined"
              onClick={() => setIsOpen(!isOpen)}
              disabled={isDisabled}
              endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
              id="99b3b09c-fb5c-40ab-8ad3-92881578eac1"
            >
              Columns
            </Button>
          </GridColumnsFilterSC>
        </div>
      </ClickAwayListener>
    </>
  );
};

type ApplyButtonProps = { isDisabled: boolean; onSubmit: () => void };

const ApplyButton = (props: ApplyButtonProps) => {
  const { isDisabled, onSubmit } = props;

  if (isDisabled) {
    return (
      <Tooltip title="There must be at least 1 column selected">
        <span>
          <Button
            variant="contained"
            disabled={isDisabled}
            id="6d0fea87-c18f-4f8b-b9cb-09111a568244-apply-disabled"
          >
            Apply
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button
      variant="contained"
      onClick={onSubmit}
      id="6d0fea87-c18f-4f8b-b9cb-09111a568244-apply"
    >
      Apply
    </Button>
  );
};
