import { ProductivityReportLegacyViewComponent } from '../../../../../../reports/productivity-report/views/ProductivityReportLegacy.view';
import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appReportsProductivityLegacy: IRoute = {
  name: 'app.reports.productivityLegacy',
  stateDefinition: {
    url: '/productivity',
    component: ProductivityReportLegacyViewComponent,
    params: { date: null, aggregatePeriod: null },
    data: { pageTitle: 'Productivity', filter: {} }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Productivity,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.ProductivityReport]
  }
};

export default appReportsProductivityLegacy;
