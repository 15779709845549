import { agentInstallApiService } from '_app/serviceFunctions/agentInstallApiService';
import { initializeDataDog } from '../../_reactivtrak/src/common/config/dataDog';
import {
    setAccountSettings
} from '../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { http as httpService } from '_app/http';

import exportProgressBarTemplate from '../views/widgets/exportProgressBar.html?raw';
import downloadButtonTemplate from '../views/widgets/downloadButton.html?raw';


angular.module('app').run([
    '$rootScope',
    '$window',
    '$state',
    '$templateCache',
    '$timeout',
    'atkHttpDecorator',
    'atHelperFunctions',
    'envService',
    'envConfig',
    function (
        $rootScope,
        $window,
        $state,
        $templateCache,
        $timeout,
        atkHttpDecorator,
        atHelperFunctions,
        envService,
        envConfig,
    ) {
        initializeDataDog();
        // Initialize UTM script
        if (typeof $window.UTMscripInit === 'function') {
            $window.UTMscripInit();
        }

        // eslint-disable-next-line no-console
        console.log('angularjs version ==>', angular.version);
        // eslint-disable-next-line no-console
        console.log(
            'ActivTrak Version (vite):',
            // eslint-disable-next-line no-undef
            __VERSION__,
            ' Commit:',
            // eslint-disable-next-line no-undef
            __COMMIT_HASH__,
            ' @ ' + envService.get()
        );

        //Must be first method to run:
        atkHttpDecorator.init();

        agentInstallApiService.initialize(httpService, envConfig);


        //TODO: Convert to $transition
        $rootScope.$on('$locationChangeSuccess', function () {
            $window.scrollTo(0, 0);
        });

        angular.element($window).bind('scroll', function () {
            var headerHeight = this.innerWidth > 991 ? 60 : 48;
            $rootScope.windowScrolledDown = this.pageYOffset >= headerHeight;
            $rootScope.$applyAsync();
        });

        var fullScreenTimer;
        angular.element($window).bind('resize', function () {
            if (typeof fullScreenTimer !== 'undefined') {
                clearTimeout(fullScreenTimer);
            }
            fullScreenTimer = setTimeout(function () {
                $rootScope.windowFullScreen =
                    $window.fullScreen ||
                    ($window.innerWidth === screen.width && $window.innerHeight === screen.height);
                $rootScope.$applyAsync();
            }, 2000);
        });

        
        var body = $('body');
        $rootScope.$watch(
            function () {
                return body.hasClass('menu-pin');
            },
            function (newValue) {
                $rootScope.app.layout.menuPin = newValue;
            }
        );

        // Bind window resizing
        var resizeDelay;
        var oldWindowSize = {
            innerWidth: 0,
            innerHeight: 0
        };
        angular.element($window).bind('resize', function () {
            $timeout.cancel(resizeDelay);

            var windowSize = {
                newWidth: $window.innerWidth,
                newHeight: $window.innerHeight,
                oldWidth: oldWindowSize.innerWidth,
                oldHeight: oldWindowSize.innerHeight
            };

            $rootScope.$broadcast('atWindowResizing', angular.copy(windowSize));
            $rootScope.$broadcast('authorizationServiceUpdated');

            resizeDelay = $timeout(function () {
                $rootScope.$broadcast('atWindowResized', angular.copy(windowSize));
                oldWindowSize.innerWidth = $window.innerWidth;
                oldWindowSize.innerHeight = $window.innerHeight;
            }, 500);
        });

        var roleAccessUpdatePromise;
        // TODO: Update this to update the userPermissionStore when roleAccess is added
        angular.element($window).bind('roleAccessUpdated', function (roleAccessUpdatedEvent) {
            setAccountSettings({ roleAccess: roleAccessUpdatedEvent.detail.roleAccess });
            $timeout.cancel(roleAccessUpdatePromise);
            roleAccessUpdatePromise = $timeout(function () {
                $rootScope.$broadcast('AccountSettingsChanged');
            }, 500);
        });

        var roleDateFiltersUpdatePromise;
        angular.element($window).bind('roleDateFiltersUpdated', function () {
            $timeout.cancel(roleDateFiltersUpdatePromise);
            roleDateFiltersUpdatePromise = $timeout(function () {
                $rootScope.$broadcast('roleDateFiltersRefresh');
            }, 500);
        });

        // this enables ng-include
        $templateCache.put('exportProgressBar.html', exportProgressBarTemplate);
        $templateCache.put('downloadButton.html', downloadButtonTemplate);
    }
]);

angular.module('app').run([
    '$rootScope',
    '$state',
    '$stateParams',
    'messagesService',
    function ($rootScope, $state, $stateParams, msg) {
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

        // App globals
        $rootScope.app = {
            name: msg.get('appName'),
            title: msg.get('appName'),
            layout: {
                menuPin: false,
                menuBehind: false,
                // theme: 'pages/css/pages.min.css',
                screenshotView: false
            }
        };
        $rootScope.isAccountCreationMode = false;
    }
]);

if (import.meta.env.VITE_ENABLE_UI_ROUTER_TRACE === 'true' && import.meta.env.VITE_NAME === 'dev') {
    angular.module('app').run(['$trace', ($trace) => $trace.enable()]);
}
