import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { IDntDeleteState } from '../models/IDntDeleteState';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import { IUserIdentity } from '../models/IUserIdentity';
type ComponentProps = {
  dntState: IDntDeleteState;
  openTrackModal: boolean;
  commonState: IIdentityCommonState;
  handleIdentityChanged: (
    oldUser: IUserIdentity,
    newUser: IUserIdentity
  ) => void;
};

export const TrackModal = (props: ComponentProps) => {
  const { dntState, openTrackModal, commonState, handleIdentityChanged } =
    props;

  const closeModal = () => {
    dntState.setOpenTrackModal(false);
  };

  const onCancelChanges = () => {
    dntState.setOpenTrackModal(false);
  };

  const onStartTracking = () => {
    dntState.setTracking(true).then(([error, newIdentity]) => {
      if (!error) {
        commonState.setIdentityNotification({
          msg: 'Tracking started successfully',
          type: 'success'
        });
        dntState.userToModify.tracked = true;
        handleIdentityChanged(dntState.userToModify, newIdentity);
      } else {
        commonState.setIdentityNotification({
          msg: 'Error occurred while starting tracking',
          type: 'error'
        });
      }
    });
    closeModal();
  };

  return (
    <Dialog open={openTrackModal} onClose={closeModal} maxWidth="sm">
      <DialogTitle>Start tracking this user?</DialogTitle>
      <DialogContent id="dnt-dialog-content">
        <Box>Please confirm you want to start tracking this user.</Box>
        <Box sx={{ padding: '16px 0px 16px 0px' }}>
          <Box sx={{ fontWeight: '500' }}>Start user tracking will:</Box>
          <ul>
            <li> Begin collecting new data</li>
            <li> Include the user in reports going forward.</li>
          </ul>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancelChanges}>
          Cancel
        </Button>
        <Button onClick={onStartTracking} variant="contained" color="primary">
          Start Tracking
        </Button>
      </DialogActions>
    </Dialog>
  );
};
