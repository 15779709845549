import React from 'react';
import { GroupMix, GroupTypeBasic } from '../../enums';
import { GroupIconType } from '../../components/GroupType/GroupIconType';
import {
  ActiveDirectoryIcon,
  ActivTrakIcon,
  AzureAdIcon,
  ComputerGroupIcon,
  MixedGroupIcon,
  UserAllIcon,
  UserGroupIcon,
  UserIndividualIcon,
  ComputerIcon,
  HRDataConnector,
  ApiIcon
} from '../Icons';

export type GroupIconSourceProps = {
  iconSource: GroupTypeBasic | GroupMix | GroupIconType;
};

export const GroupIconSource = (props: GroupIconSourceProps) => {
  const iconSource = props?.iconSource;

  if (!iconSource) return <></>;

  const iconSourceMap = {
    ActivTrak: ActivTrakIcon,
    'Active Directory': ActiveDirectoryIcon,
    'Azure AD': AzureAdIcon,
    allusers: UserAllIcon,
    [GroupTypeBasic.Api]: ApiIcon,
    individual: UserIndividualIcon,
    individualcomputer: ComputerIcon,
    Computer: ComputerGroupIcon,
    User: UserGroupIcon,
    Mixed: MixedGroupIcon,
    'HR Data Connector': HRDataConnector,
    'HRDC': HRDataConnector
  };
  const Icon = iconSourceMap[iconSource];

  if (!Icon) {
    console.error(`No icon found for source "${JSON.stringify(iconSource)}"`);
    return null;
  }

  return <Icon />;
};
