import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Kebab } from '../styles/Integrations.view.styles';
import { IBaseIntegrationConfigurationState } from '../models/IBaseIntegrationConfigurationState';
import { IntegrationStateType } from '../models';

export const IntegrationDropdown = ({
  configState,
  isHRISCard
}: {
  configState: IBaseIntegrationConfigurationState;
  isHRISCard?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    instanceId,
    integrationState,
    pauseIntegration,
    deleteIntegration,
    refreshIntegration,
    deleteMergeIntegration
  } = configState;

  const handleKebabClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClick = (event, menuAction: IntegrationStateType) => {
    handleClose(event);
    switch (menuAction) {
      case IntegrationStateType.Running:
        refreshIntegration(instanceId);
        break;
      case IntegrationStateType.Paused:
        pauseIntegration(instanceId);
        break;
      case IntegrationStateType.Deleted:
        if (isHRISCard) {
          deleteMergeIntegration(instanceId);
        } else {
          deleteIntegration(instanceId);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ height: '2rem' }}>
      <Kebab>
        <IconButton
          color="secondary"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleKebabClick}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            transform: 'scale(1.4)',
            padding: '4px'
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Kebab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {!isHRISCard && integrationState === IntegrationStateType.Waiting && (
          <MenuItem
            onClick={(e) => handleMenuClick(e, IntegrationStateType.Running)}
          >
            Refresh
          </MenuItem>
        )}

        {!isHRISCard &&
          integrationState !== IntegrationStateType.Paused &&
          integrationState !== IntegrationStateType.Idle &&
          integrationState !== IntegrationStateType.Error &&
          integrationState !== IntegrationStateType.NoFetchWarning && (
            <MenuItem
              onClick={(e) => handleMenuClick(e, IntegrationStateType.Paused)}
            >
              Pause
            </MenuItem>
          )}

        <MenuItem
          onClick={(e) => handleMenuClick(e, IntegrationStateType.Deleted)}
        >
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
