export const getGoogleAccessToken = async (): Promise<string> => {
  const promise = new Promise<string>((resolve, reject) => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: import.meta.env.VITE_OAUTH_CORE_APP_GOOGLE_CLIENT_ID,
      scope: import.meta.env.VITE_OAUTH_CORE_APP_GOOGLE_REDIRECT_URI,
      callback: function (response) {
        if (response.error) {
          reject(new Error(response.error));
        } else {
          resolve(response.access_token);
        }
      }
    });
    client.requestAccessToken();
  });

  return promise;
};
