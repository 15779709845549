import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import Box from '@mui/material/Box';
import {
  generateLegendItems,
  getDatasets,
  getFilteredKeys,
  getPassiveTime,
  sortChartData
} from '../utils/productivityReport.utils';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import { PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG } from '../constants/productivityReportChartConfig.constants';
import { useReportViewStore } from '../../../common/components/ReportFilters/hooks/useReportViewToggleStore';
import { useDefaultSkeletonLoader } from '../../../common/components/CustomGrid/CustomGrid.utils';
import { ApiStatus } from '../../../common/enums/ApiStatus';
import { NoReportData } from '../../common/components/NoReportData';
import { LoaderContainer } from '../../../common/components/CustomGrid/CustomGrid.styles';
import { useProductivityReportStore } from '../hooks/useProductivityReportStore';
import { combinedProductivityColors } from '../../../common/constants';
import { ProductivityLegends } from './ProductivityLegends';
import { AtConsoleLogger } from '../../../common/utils/consoleLogger/AtConsoleLogger';
import { useNotifications } from '../../../common/services/Notifications/NotificationProvider';
import { getUIRouterState } from '../../../common/hooks/useUIRouterHistory';
import { setAggregationPeriod } from '../stores/productivityAggregation.store';
import { getAccountSettings } from '../../../common/stores/accountSettingsStore/accountSettingsStore';

export const ProductivityBarChart = () => {
  const currentView = useReportViewStore((state) => state.view);
  const { response, apiStatus } = useProductivityReportStore((state) => state);
  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>();
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    labels: [],
    datasets: []
  });

  const defaultSkeletonLoader = useDefaultSkeletonLoader(2, false);
  const notificationService = useNotifications();

  const { date, aggregatePeriod } = getUIRouterState().params;
  const accountSettings = getAccountSettings();
  const dateFormat = accountSettings.dateFormat.toUpperCase();

  const getDateFromRange = (range) => {
    return range.split('\n')[0];
  };

  const handleBarClick = (event, elements) => {
    let dateLabel;
    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      const label = chartData.labels[elementIndex];
      dateLabel = Array.isArray(label) ? label[0] : label;
    } else {
      dateLabel = event.date;
    }

    console.log('Bar clicked', {
      date: dateLabel,
      aggregatePeriod: event.aggregatePeriod
    });

    if (event.aggregatePeriod) {
      setAggregationPeriod(event.aggregatePeriod);
    }
  };

  useEffect(() => {
    if (response?.data) {
      const filteredKeys = getFilteredKeys(currentView);
      const { datasets, labels } = getDatasets(
        response.data,
        filteredKeys,
        combinedProductivityColors
      );

      const resetDatasets = datasets.map((dataset) => ({
        ...dataset,
        hidden: false
      }));

      setChartData({ labels, datasets: resetDatasets });
    }
  }, [response, currentView]);

  useEffect(() => {
    if (date || aggregatePeriod) {
      if (date) {
        const decodeDate = decodeURIComponent(date);
        const formatDecodedDate = moment(
          getDateFromRange(decodeDate),
          dateFormat
        );
        console.log('Params', {
          date: formatDecodedDate.format(dateFormat),
          aggregatePeriod
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, aggregatePeriod]);

  useEffect(() => {
    if (response?.error) {
      notificationService.error('Unable to load Productivity Chart data');
      AtConsoleLogger(
        'Unable to load Productivity Chart data',
        response?.error
      ).error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus, response?.error]);

  const footerCallback = useMemo(() => {
    return (context) => {
      if (currentView === ReportViewTypes.SUMMARY) {
        const label = context[0].dataset.label.toLowerCase();
        if (['productive', 'unproductive', 'undefined'].includes(label)) {
          const passiveTime = getPassiveTime(context);
          const footerText = `Includes ${passiveTime} of ${context[0].dataset.label} (Passive)`;
          return footerText;
        }
      }
      return '';
    };
  }, [currentView]);

  useEffect(() => {
    const ProductivityChartOptions: ChartOptions<'bar'> = {
      maintainAspectRatio: false,
      responsive: true,
      datasets: {
        ...PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG.datasets
      },
      plugins: {
        legend: {
          ...PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG.legend
        },
        tooltip: {
          ...PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG.tooltip,
          callbacks: {
            ...PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG.tooltip.callbacks,
            footer: footerCallback
          }
        },
        datalabels: {
          ...PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG.dataLabels
        }
      },
      scales: {
        ...PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG.scales
      },
      onHover: (event, chartElement) => {
        if (event.native.target instanceof HTMLElement) {
          event.native.target.style.cursor = chartElement[0]
            ? 'pointer'
            : 'default';
        }
      }
    };
    setChartOptions(ProductivityChartOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%', height: '500px' }}>
      {apiStatus === ApiStatus.Loading ? (
        <LoaderContainer loaderHeight={'90%'}>
          {defaultSkeletonLoader}
        </LoaderContainer>
      ) : !response?.data || response.data.length === 0 ? (
        <NoReportData height="500px" />
      ) : (
        <>
          <Chart
            type="bar"
            options={{ ...chartOptions, onClick: handleBarClick }}
            data={sortChartData(chartData)}
          />
          <ProductivityLegends
            legendItems={generateLegendItems(chartData, setChartData)}
          />
        </>
      )}
    </Box>
  );
};
