import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';
import { GridColumnParams } from '../models/GridColumnParams';
import { TextField, Tooltip } from '@mui/material';
import { TextOverflow } from '../../common/styles/app.component.styles';

export const useUserColumns = (props: GridColumnParams) => {
  const { onAliasChanged, isReadOnly } = props;
  return [
    {
      field: 'userid',
      fieldName: 'userid',
      headerName: 'userid',
      type: 'number',
      isSortable: true,
      hidden: true,
      width: '0'
    },
    {
      field: 'logon_Domain',
      fieldName: 'Logon Domain',
      headerName: 'Logon Domain',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem.logon_Domain} placement="bottom">
            <TextOverflow>{dataItem.logon_Domain}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'user',
      fieldName: 'User ID',
      headerName: 'User ID',
      type: 'string',
      isSortable: true,
      width: '24%',
      template: (dataItem) => {
        return (
          <Tooltip title={dataItem.user} placement="bottom">
            <TextOverflow>{dataItem.user}</TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'alias',
      headerName: 'User Alias',
      fieldName: 'User Alias',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return isReadOnly ? (
          <span>{dataItem.alias}</span>
        ) : (
          <TextField
            defaultValue={dataItem.alias}
            name={dataItem.userid.toString()}
            variant="outlined"
            onChange={onAliasChanged}
          />
        );
      }
    },
    {
      field: 'logCount',
      headerName: '# of Log Records',
      type: 'number',
      isSortable: true,
      width: '15%'
    },
    {
      field: 'lastLog',
      headerName: 'Last Log Record',
      type: 'string',
      isSortable: true,
      width: '18%',
      template: (dataItem) => {
        return <>{formatDateTime(dataItem.lastLog)}</>;
      }
    }
  ];
};
