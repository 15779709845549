import { IntegrationCodes } from './IntegrationCodes';
import { HRISIntegrationType } from './IntegrationDetails';

export const CLIENT_ID = 'client_id';
export const CLIENT_SECRET = 'client_secret';
export const DOMAIN = 'domain';
export const ENABLED = 'enabled';
export const REFRESH_TOKEN = 'refresh_token';
export const TENANT_ID = 'tenant_id';
export const ACTIVTRAK_DELETE_GROUP = 'activtrak_delete_group';
export const ACTIVTRAK_DNT_GROUP = 'activtrak_dnt_group';
export const ACTIVTRAK_GROUP = 'activtrak_group';

export const heapIdsMap = {
  [IntegrationCodes.AzureAD]: 'be6690-5ca0-11ee-8c99-0242ac120002',
  [IntegrationCodes.GoogleCalendar]: 'a8f0de4a-5ca0-11ee-8c99-0242ac120002',
  [IntegrationCodes.OutlookCalendar]: 'aef42aa4-5ca0-11ee-8c99-0242ac120002',
  [HRISIntegrationType.Workday]: 'b3b0e7b0-5ca0-11ee-8c99-0242ac120002'
};
