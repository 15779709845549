import { createStore } from 'zustand/vanilla';
import { ITopUserSearchStore } from '../models/TopUsersReport.models';
import { TOP_USERS_SEARCH_STORE_DEFAULT } from '../constants/topUsersReport.constants';
import { getTopUsersReportStore } from './topUsersReport.store';

export const topUsersSearchStore = createStore<ITopUserSearchStore>(
  () => TOP_USERS_SEARCH_STORE_DEFAULT
);

export const getTopUsersSearchStore = () => topUsersSearchStore.getState();

export const setTopUsersSearchStore = (
  newState: Partial<ITopUserSearchStore>
) => topUsersSearchStore.setState(newState);

export const clearTopUsersSearch = () => {
  setTopUsersSearchStore({
    searchResults: [],
    searchValue: '',
    searchInputValue: ''
  });
};

export const filterTopUsersResults = (query: string) => {
  if (!query) return clearTopUsersSearch();

  const searchResults = getTopUsersReportStore().response.data.filter(
    (result) => result.userId.includes(query)
  );
  setTopUsersSearchStore({ searchResults });
};
