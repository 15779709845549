import { ApiStatus } from '../../../common/enums/ApiStatus';
import { ReportPageModeToggle } from '../../../common/components/ReportFilters/components/ReportPageModeToggle';
import { useTopUsersReportsStore } from '../hooks/useTopUsersReportStore';
import { ITopUserReportStore } from '../models/TopUsersReport.models';

export const TopUsersPageModeToggle = () => {
  const { apiStatus } = useTopUsersReportsStore((s: ITopUserReportStore) => s);

  return <ReportPageModeToggle isDisabled={apiStatus !== ApiStatus.Loaded} />;
};
