import { applicationRoles } from '../constants';
import { IAuthorization } from '../models';
import { checkArrayIncludes } from '../utils/validation/validation';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import {
  Role,
  RoleName,
  PermissionLevel
} from '../enums';

import { FeatureFlag } from '../enums/FeatureFlag';
import { BundleFlag } from '../enums/BundleFlag';
import { getUserRoles } from './authentication/useUserTokenStore';
import { some, includes, find, map } from 'lodash';
import { getAccountSettings } from '../stores/accountSettingsStore/accountSettingsStore';
import { hasFlag } from '../stores/userPermissionsStore/userPermissionStore.utils';
import { getRoutePermissionLevel } from '../stores/userPermissionsStore/userPermissionStore';
import { getState } from '../config/routing';

/**
 * TODO: this version of 'hasRole' should not be multi-purpose. Keep it pure.
 * - hasRole = single role to check
 * - hasAnyRole = array of roles to check using 'hasRole'
 * @param rolesToCheck 
 * @returns 
 */
export const hasRole = (rolesToCheck: string | string[]): boolean => {  

  // Bad input, log error
  if (!rolesToCheck) {
    console.error('ActivTrak Error: Undefined roles to check.', rolesToCheck);
    return false;
  }

  const userRoles = getUserRoles();

  // Handle invalid state
  if (userRoles === 'invalid') {
    return false;
  }

  if (typeof rolesToCheck === 'string') {
    rolesToCheck = [rolesToCheck];
  }

  return some(userRoles, (role) => {
    return includes(rolesToCheck, role.toLowerCase());
  });
};

//This is just used in Analytics
const getUserType = (): RoleName => {
  const accountSettings = getAccountSettings();
  const userRoles = getUserRoles();

  if (!accountSettings || !userRoles) {
    console.error(
      'ActivTrak Error: Cannot determine user type due to account settings or authorizations missing.'
    );
    return RoleName.Unknown;
  }

  // If creator of the account, return creator
  if (
    accountSettings.creator &&
    accountSettings.username === accountSettings.creator
  ) {
    return RoleName.Creator;
  } else {
    if (hasRole(Role.SupportAdvanced)) {
      return RoleName.SupportAdvanced;
    } else if (hasRole(Role.SupportIntermediate)) {
      return RoleName.SupportIntermediate;
    } else if (hasRole(Role.SupportBasic)) {
      return RoleName.SupportBasic;
    } else if (hasRole(Role.SuperAdmin)) {
      return RoleName.SuperAdmin;
    } else if (hasRole(Role.MultiAccount)) {
      return RoleName.MultiAccount;
    } else {
      const roleName = Object.keys(Role).find(
        (key) => Role[key as keyof typeof Role] === userRoles[0].toLowerCase()
      );
      return RoleName[roleName];
    }
  }
};

const getParentAccount = (): string => {
  const accountSettings = getAccountSettings();
  return accountSettings?.parentAccountId;
};

const isMspChildAccount = (): boolean => {
  const accountSettings = getAccountSettings();
  return Boolean(
    accountSettings?.parentAccountId &&
      accountSettings?.totalLicenses === 2147483647
  );
};

//TODO: remove this when areas that still use this can just use Roles.ts
const getRole = (key: string | number): object => {
  return Object.values(applicationRoles).find(function (role) {
    return role.id == key || role.name === key || role.key === key;
  });
};

const hasAnyRole = (roles): boolean => {
  return find(roles, (role) => hasRole(role)) !== undefined;
};

export const hasFeature = (flag: FeatureFlag | BundleFlag): boolean => {
  return hasFlag(flag);
};

/*
    TODO: this version of 'hasRoleAccess' should not be multi-purpose. Keep it pure.
    - hasRoleAccess = single role to check
    - hasAnyRoleAccess = array of roleAccesses to check using 'hasRoleAccess'
    */
export const hasRoleAccess = (roleAccessKey): boolean => {
  const userRoleAccesses = getUserRoles();
  if (!roleAccessKey || !userRoleAccesses) {
    return false;
  }

  const search = ' ';
  const replaceWith = '';

  // TODO: Research why we need to create an array of roleAccess
  roleAccessKey =
    typeof roleAccessKey === 'string'
      ? [roleAccessKey.replaceAll(search, replaceWith).toLowerCase()]
      : map(roleAccessKey, (access) => {
          return access.toLowerCase().replaceAll(search, replaceWith);
        });

  return some(userRoleAccesses, (key) => {
    return includes(roleAccessKey, key.toLowerCase());
  });
};


// TODO: Refactor this into single functions for canEdit, canView, canPreview (if needed)
const hasAuthorizationLevel = (
  levels: PermissionLevel[],
  routeName: string
): boolean => {
  if (!levels) {
    console.error(
      'ActivTrak Error: Cannot determine authorization level due to invalid inputs.',
      levels
    );
    return;
  }

  if (typeof levels === 'string') {
    levels = [levels];
  }

  const authorizationLevel = getRoutePermissionLevel(routeName);
  let hasLevel = false;

  authorizationLevel &&
    levels.forEach((level) => {
      switch (authorizationLevel) {
        case 'edit':
          hasLevel =
            hasLevel ||
            !!checkArrayIncludes(['edit', 'read'], level);
          break;
        case 'read':
          hasLevel =
            hasLevel || !!checkArrayIncludes(['read'], level);
          break;
      }
    });

  return hasLevel;
};

/**
 * There is no need to check for routes - available routes are wired already wired up
 * use useUIRouter hook if need to grab the current state
 * @deprecated
 * @returns boolean
 */
const hasRouteByName = (route: string): boolean => {
  const state = getState(route);
  return state !== 'invalid';
};


// TODO: Review and refactor role check functions
/**
 * Returns true if the user has any support role (including SuperAdmin)
 *
 * EXCLUDES impersonation
 */
const isSupportOrSuperAdmin = (): boolean => {
  return hasAnyRole([
    Role.SupportBasic,
    Role.SupportIntermediate,
    Role.SupportAdvanced,
    Role.SuperAdmin
  ]);
};

/**
 * Returns true if the user has any support role (including SuperAdmin) or if the user is impersonating
 *
 * INCLUDES impersonation
 */
const isAnySupportUser = (): boolean => {

  return (
    userServiceFunctions.getIsImposter() ||
    hasAnyRole([
      Role.SupportBasic,
      Role.SupportIntermediate,
      Role.SupportAdvanced,
      Role.SuperAdmin
    ])
  );
};

export const authorizationService: IAuthorization = {
  hasFeature,
  getRole,
  hasRole,
  hasAnyRole,
  hasRoleAccess,
  getUserType,
  getParentAccount,
  isMspChildAccount,
  hasAuthorizationLevel,
  hasRouteByName,
  isSupportOrSuperAdmin,
  isAnySupportUser
};

export default authorizationService;
