import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').controller('signupTeamModalCtrl', SignupTeamModalCtrl);

SignupTeamModalCtrl.$inject = [
    '$scope',
    '$timeout',
    'accessApiService',
    '$uibModalInstance',
    'messagesService',
    'atHelperFunctions',
    'notificationService'
];

function SignupTeamModalCtrl(
    $scope,
    $timeout,
    accessApiService,
    $uibModalInstance,
    msg,
    atHelperFunctions,
    notificationService
) {
    $scope.members = [];
    $scope.xsWindow = atHelperFunctions.isExtraSmallWindow();
    $scope.makeAdminTooltip = msg.get('makeAdminTooltip');
    var validatingMember = false;

    function closeTeamModal(errorMessages) {
        $uibModalInstance.close();

        if (errorMessages && errorMessages.forEach) {
            errorMessages.forEach(function (error) {
                notificationService.showNotification(error.message, error.color, error.timeout);
            });
        }
    }

    $scope.closeTeamModal = closeTeamModal;

    function startProvisioning() {
        $scope.provisioning = true;
        $scope.showError = false;

        var modal = $('.modal-dialog');
        modal.removeClass('modal-md');
    }

    $scope.createUsers = function () {
        if ($scope.disableContinue()) {
            return;
        }

        startProvisioning();
        var payload = { users: [] };
        var i = 0;
        for (i = 0; i < 4; i++) {
            if ($scope.members[i] && $scope.members[i].userName && !$scope.members[i].error) {
                payload.users.push({
                    userName: $scope.members[i].userName,
                    //TODO: Temporary fix AC-11094
                    //Will replace with a dropdown control
                    userRole: $scope.members[i].isAdmin ? '1' : '2'
                });
            }
        }

        if (payload.users.length === 0) {
            closeTeamModal();
            return;
        }

        accessApiService
            .createUsers(payload)
            .success(function () {
                closeTeamModal();
            })
            .error(function (result) {
                var errorMessages = [];
                if (result.users) {
                    result.users.forEach(function (user) {
                        if (user.error) {
                            errorMessages.push({
                                message: msg.get('errorAddingProfile', user.userName, user.error.Message),
                                color: 'danger',
                                timeout: 0
                            });
                        }
                    });
                } else if (result.error) {
                    errorMessages.push({
                        message: result.error,
                        color: 'danger',
                        timeout: 0
                    });
                } else {
                    errorMessages.push({
                        message: msg.get('creatingUserError'),
                        color: 'danger',
                        timeout: 0
                    });
                }

                closeTeamModal(errorMessages);
            });
    };

    function memberIsDuplicate(userName, index) {
        var i;
        for (i = 0; i < 4; i++) {
            if (
                i !== index &&
                $scope.members[i] &&
                $scope.members[i].userName &&
                $scope.members[i].userName.toLowerCase() === userName.toLowerCase()
            ) {
                return true;
            }
        }
        return false;
    }

    function checkValid(member, i) {
        if (member.userName) {
            const { username } = profileStore.getState().profile;
            if (member.userName.toLowerCase() === username.toLowerCase()) {
                member.error = msg.get('signupErrorMessage_isMember');
                validatingMember = false;
            } else if (memberIsDuplicate(member.userName, i)) {
                member.error = msg.get('signupErrorMessage_duplicateMember');
                validatingMember = false;
            } else {
                accessApiService
                    .validateMember(member.userName)
                    .then(function (result) {
                        if (result && result.data && result.data.message) {
                            if (result.data.message === 'exists on account') {
                                member.error = msg.get('signupErrorMessage_memberExists');
                            } else {
                                member.error = msg.get('signupErrorMessage_memberExistsOnAnother');
                            }
                        } else {
                            member.error = null;
                        }
                        validatingMember = false;
                    })
                    .catch(function (result) {
                        if (result.data && result.data.error.indexOf('We need your real email address') >= 0) {
                            member.error = msg.get('signupErrorMessage_temporaryEmailService');
                        } else if (result.data && result.data.error.indexOf('Enter a valid email') >= 0) {
                            member.error = msg.get('signupErrorMessage_invalidEmail');
                        } else {
                            console.error('Unable to check if email exists', result);
                        }
                        validatingMember = false;
                    });
            }
        } else {
            member.error = null;
        }
    }

    var validateTimer = [];
    $scope.validateMember = function () {
        var i;
        for (i = 0; i < 4; i++) {
            var member = $scope.members[i];
            validatingMember = true;
            $timeout.cancel(validateTimer[i]);
            if (member) {
                validateTimer[i] = $timeout(checkValid, 500, false, member, i);
            }
        }
    };

    $scope.disableContinue = function () {
        if ($scope.team.$invalid || validatingMember) {
            return true;
        }

        var i;
        var emailString = '';
        for (i = 0; i < 4; i++) {
            if ($scope.members[i]) {
                if ($scope.members[i].error) {
                    return true;
                } else if ($scope.members[i].userName) {
                    emailString += $scope.members[i].userName;
                }
            }
        }

        return emailString === '';
    };

    $scope.$on('atWindowResizing', function (event, windowSize) {
        if (windowSize.newWidth !== windowSize.oldWidth) {
            $scope.xsWindow = atHelperFunctions.isExtraSmallWindow();
        }
    });
}
