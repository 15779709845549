import { useCallback } from 'react';
import { getAccountForSupport } from '../../common/services/Application/SupportPortalLoad';
import { StorageService, useStorageState } from '../../common/hooks';
import { IAccountSettingsDto } from '../../common/models';
import { consoleMessage } from '../constants';
import { decodeToken, getTokenExpiration } from '../utils';
import { ISupportPortalServiceProps } from '../models/ISupportPortalService';
import { ISupportPortalState } from '../models/ISupportPortalState';
import {
  IAccountIdPayloadDto,
  IAddAccountPayloadDto,
  ISetDataCollectionPayloadDto,
  IUnlockUserPayloadDto
} from '../models/IPayloadDto';
import { IAccountSnapshotDto, ITokenDto } from '../models/IApiResponseDto';
import { IFullDetails } from '../models/IAccountData';

export const useSupportPortalState = ({
  service
}: ISupportPortalServiceProps): ISupportPortalState => {
  const {
    getAccountSnapshot,
    getToken,
    setDataCollection,
    unlockUser,
    verifyAccount
  } = service;

  const storageService = StorageService();
  const { getStorageInfo } = useStorageState({
    storageService
  });

  const fetchAccountToken = useCallback(
    async (payload: IAddAccountPayloadDto): Promise<ITokenDto> => {
      try {
        const userToken = await getToken(payload);
        userToken.expiration = getTokenExpiration(decodeToken(userToken.token));

        return userToken;
      } catch (error) {
        if (error?.status === 400) {
          if (error?.data === 'Account not found') {
            throw new Error(error.data);
          }

          throw new Error(consoleMessage.tokenError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.tokenError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.tokenError.network);
        }
        throw new Error(consoleMessage.tokenError.unknown);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchAccountDetails = useCallback(
    async (token: string): Promise<IAccountSettingsDto> => {
      try {
        const accountInfo = await getAccountForSupport(token);

        return accountInfo;
      } catch (error) {
        if (error?.status === 400) {
          if (error?.data?.code === 'INVALID_ACCOUNT') {
            throw new Error('Account not found');
          }

          throw new Error(consoleMessage.accountFetchError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.accountFetchError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.accountFetchError.network);
        }
        throw new Error(consoleMessage.accountFetchError.unknown);
      }
    },
    []
  );

  const getSnapshot = useCallback(
    async (
      token: string,
      payload: IAccountIdPayloadDto
    ): Promise<IAccountSnapshotDto> => {
      try {
        return await getAccountSnapshot(token, payload);
      } catch (error) {
        if (error?.status === 400) {
          throw new Error(consoleMessage.snapshotError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.snapshotError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.snapshotError.network);
        }
        throw new Error(consoleMessage.snapshotError.unknown);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchAllDetailsForModal = useCallback(
    async (
      token: string,
      payload: IAccountIdPayloadDto
    ): Promise<IFullDetails> => {
      const fullDetails = await Promise.all([
        fetchAccountDetails(token),
        getSnapshot(token, payload),
        getStorageInfo(token)
      ]);

      return fullDetails;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setDataCollectionSetting = useCallback(
    async (
      payload: ISetDataCollectionPayloadDto,
      token: string
    ): Promise<void> => {
      try {
        await setDataCollection(payload, token);
      } catch (error) {
        if (error?.status === 400) {
          throw new Error(consoleMessage.dataCollectionError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.dataCollectionError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.dataCollectionError.network);
        }
        throw new Error(consoleMessage.dataCollectionError.unknown);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setUnlockUser = useCallback(
    async (payload: IUnlockUserPayloadDto): Promise<void> => {
      try {
        await unlockUser(payload);
      } catch (error) {
        if (error?.status === 400) {
          throw new Error(consoleMessage.unlockUserError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.unlockUserError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.unlockUserError.network);
        }
        throw new Error(consoleMessage.unlockUserError.unknown);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setVerifyAccount = useCallback(
    async (payload: IAccountIdPayloadDto): Promise<void> => {
      try {
        await verifyAccount(payload);
      } catch (error) {
        if (error?.status === 400) {
          throw new Error(consoleMessage.verifyAccountError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.verifyAccountError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.verifyAccountError.network);
        }
        throw new Error(consoleMessage.verifyAccountError.unknown);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetchAccountToken,
    fetchAccountDetails,
    setDataCollectionSetting,
    setUnlockUser,
    setVerifyAccount,
    fetchAllDetailsForModal
  };
};
