import { useEffect, useState } from 'react';
import { Box, Divider, SxProps } from '@mui/material';
import {
  HeaderSC,
  ToolbarSC,
  TypographySC,
  TypographyTitleSC,
  TypographyPlanNameSC,
  FilledStarSC,
  HallowStarSC,
  LinkUserLicensesSC,
  MenuWrapper
} from '../styles/Header.styles';
import { HeaderMenu } from './HeaderMenu';
import { StatuspageProvider } from '../../../../../third-party/StatusPageProvider';
import { useAuthorization } from '../../../../services/Authorization';
import { HeaderButton } from './HeaderButton';
import { Role } from '../../../../enums';
import { Routes } from '../../../../constants';
import { ArrowCircleUp, Domain, Menu, SupportAgent } from '@mui/icons-material';
import {
  usePageTitleStore,
  fetchMenuRecord,
} from '../../../Navigation/navigationStore';

import {
  useHeaderFavorites,
} from '../../../Navigation/favoritesStore';

import { UNLIMITED_LICENSE_COUNT } from '../constants/HeaderConstants';
import authorizationService from '../../../../helpers/authorization';
import { BundleFlag } from '../../../../enums/BundleFlag';
import {
  getAccountPlanStore,
  isAccountPlanInTrial
} from '../../../../stores/accountPlanStore';
import { getBackupToken, getSupportBackupToken, isImpersonating, setSupportBackupToken } from '../../../../hooks/useImpersonate';
import { useImpersonate } from '../../../../hooks/useImpersonate';
import { useAccountSettingsStore } from '../../../../stores/accountSettingsStore/accountSettingsStore.hooks';
import { FeatureState } from '../../../../enums/FeatureState';
import FeatureBadge from '../../SideBar/FeatureBadge';
import { addBaseRoute, hasRole } from '../../../../helpers';
import { getState } from '../../../../config/routing';
import { setUserToken } from '../../../../helpers/authentication/useUserTokenStore';
import { LoadApplication } from '../../../../services/Application/AppLoad';
import { rewire } from '../../../../../../../_entry/rewire';
import { useUIRouterState } from '../../../../hooks/useUIRouterHistory';
import { setSideBarStatus } from '../../sideBarStatusStore';
import { LegacyPageToggle } from './LegacyPageToggle';

// Allows feature badge on subpages headings
const EA_BADGE_SUBPAGES = ['app.settings.identity_id'];
const BETA_BADGE_SUBPAGES = [];

export const Header = (props) => {
  const { hideNavigation } = props;
  const showPageTitleOnly = hideNavigation();

  const pageTitle = usePageTitleStore((s) => s.pageTitle);

  const { planTypeLabel, planRemainingDays } = getAccountPlanStore();
  const planName = planTypeLabel;
  const authService = useAuthorization();

  const accountSettings = useAccountSettingsStore((s) => s.accountSettings);
  const daysRemainingInTrial = Math.ceil(planRemainingDays);
  const [userLicense, setUserLicense] = useState<string>('');

  const [restoreCommandCenterParent] = useImpersonate();

  const [isFavorite, toggleFavorite] = useHeaderFavorites();

  const [featureBadge, setFeatureBadge] = useState<FeatureState>(
    FeatureState.None
  );

  const state = useUIRouterState<any>();

  useEffect(() => {
    // TODO: Consolidate - there should not be metadata in multiple places
    const stateDefinitionMetadata = fetchMenuRecord(state.name);
    if (!stateDefinitionMetadata) {
      return;
    }

    // Badge
    if (stateDefinitionMetadata.beta) {
      setFeatureBadge(() => FeatureState.Beta);
    } else if (stateDefinitionMetadata.earlyAccess) {
      setFeatureBadge(() => FeatureState.EarlyAccess);
    } else if (EA_BADGE_SUBPAGES.includes(state.name)) {
      setFeatureBadge(() => FeatureState.EarlyAccess);
    } else if (BETA_BADGE_SUBPAGES.includes(state.name)) {
      setFeatureBadge(() => FeatureState.Beta);
    } else {
      setFeatureBadge(() => FeatureState.None);
    }
  }, [state]);

  const hasCCBackupToken = isImpersonating();

  const isSupportUserLoggedIntoCCAccessAccount = Boolean(
    // authService.hasAnyRole([Role.SupportAdvanced, Role.SuperAdmin]) &&
    (hasRole(Role.SupportAdvanced) || hasRole(Role.SuperAdmin)) &&
      accountSettings.roles &&
      accountSettings.roles.find(function (key) {
        return key.toUpperCase() === Role.CommandCenter.toUpperCase();
      })
  );

  const isCCAccessAccount = Boolean(
    hasRole([Role.CommandCenter])
  );

  const isCCEnterpriseAccessAccount = Boolean(
    hasRole([Role.CommandCenterEnterprise])
  );
  const showCommandCenterButton =
    isCCAccessAccount ||
    isSupportUserLoggedIntoCCAccessAccount ||
    hasCCBackupToken;
  const showSupportPortalButton = authService?.isSupportUser();

  const upgradeState = getState('app.account.upgrade');
  const showUpgradeButton =
    upgradeState !== undefined && upgradeState !== 'invalid';

  useEffect(() => {
    const { usedLicenses = 0, totalLicenses = 0 } = accountSettings;

    setUserLicense(
      `Users / Licenses: ${usedLicenses}${
        totalLicenses === UNLIMITED_LICENSE_COUNT ? '' : '/' + totalLicenses
      }`
    );
  }, [accountSettings]);

  const showFavoriteControls = isFavorite !== 'unavailable';

  const openSidebar = () => {
    setSideBarStatus('open');
  };

  const hideOnSmallScreenStyle: SxProps = {
    display: { xs: 'none', sm: 'none', md: 'inline-flex' }
  };

  const hideOnLargeScreenStyle: SxProps = {
    display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' }
  };

  const isTrialOrFree: () => boolean = () => {
    return (
      isAccountPlanInTrial() ||
      !authorizationService.hasFeature(BundleFlag.PaidFunctionality)
    );
  };

  const hideAccountConfiguration = !authService?.hasAnyRole([
    Role.Admin,
    Role.SupportBasic,
    Role.SuperAdmin
  ]);

  return (
    <HeaderSC maxWidth={false} className="header hide-on-print">
      <ToolbarSC disableGutters>
        {showPageTitleOnly ? (
          <TypographyTitleSC>
            <strong>{pageTitle}</strong>
          </TypographyTitleSC>
        ) : (
          <>
            <MenuWrapper>
              <Menu
                onClick={openSidebar}
                sx={{
                  margin: '5px 0px 0px 5px',
                  padding: '5px'
                }}
              />
            </MenuWrapper>
            <Box
              sx={{ flexGrow: 4, display: 'inline-flex', alignItems: 'center' }}
            >
              <TypographyTitleSC>
                <strong>{pageTitle}</strong>
              </TypographyTitleSC>
              {featureBadge !== FeatureState.None && (
                <FeatureBadge label={featureBadge} />
              )}
              <LegacyPageToggle />
              {showFavoriteControls && (
                <TypographySC
                  onClick={async () => {
                    await toggleFavorite('header');
                  }}
                  id="19d850ec-30df-4a94-9fab-13ddd0c542f0"
                  variant="body2"
                  sx={hideOnLargeScreenStyle}
                >
                  {isFavorite ? (
                    <FilledStarSC fontSize="small" />
                  ) : (
                    <HallowStarSC fontSize="small" />
                  )}
                </TypographySC>
              )}
            </Box>
            {showFavoriteControls && (
              <>
                <TypographySC
                  onClick={async () => {
                    await toggleFavorite('header');
                  }}
                  id="19d850ec-30df-4a94-9fab-13ddd0c542f0"
                  variant="body2"
                  sx={hideOnSmallScreenStyle}
                >
                  {isFavorite ? (
                    <>
                      Remove Favorite <FilledStarSC fontSize="small" />
                    </>
                  ) : (
                    <>
                      Add Favorite <HallowStarSC fontSize="small" />
                    </>
                  )}
                </TypographySC>
                <Divider
                  orientation="vertical"
                  sx={{
                    ...hideOnSmallScreenStyle,
                    borderColor: 'rgba(68, 68, 68, 0.8)',
                    height: '20px'
                  }}
                />
              </>
            )}
            {showSupportPortalButton && (
              <>
                <LinkUserLicensesSC
                  href={addBaseRoute(Routes.UserAgents)}
                  underline="none"
                >
                  {userLicense}
                </LinkUserLicensesSC>
                <Divider
                  orientation="vertical"
                  sx={{
                    ...hideOnSmallScreenStyle,
                    borderColor: 'rgba(68, 68, 68, 0.8)',
                    height: '20px'
                  }}
                />
              </>
            )}
            <TypographyPlanNameSC variant="body2" sx={hideOnSmallScreenStyle}>
              {planName}
            </TypographyPlanNameSC>
            {showUpgradeButton && !isCCEnterpriseAccessAccount && (
              <HeaderButton
                onClick={(e) => {
                  e.preventDefault();
                  history.pushState(null, '', '/#/app/account/upgrade');
                }}
                color={isTrialOrFree() ? 'secondary' : 'primary'}
                sx={hideOnSmallScreenStyle}
                id="072a853e-70f0-4fc0-8040-c1b03fd269c0"
              >
                <ArrowCircleUp sx={{ marginRight: '8px' }} fontSize="small" />
                {isTrialOrFree() ? 'Upgrade' : 'Add Licenses'}
                {daysRemainingInTrial
                  ? ` - ${daysRemainingInTrial} days left`
                  : ''}
              </HeaderButton>
            )}
            {isAccountPlanInTrial() &&
              !showUpgradeButton &&
              !isCCEnterpriseAccessAccount && (
                <>
                  <Divider
                    orientation="vertical"
                    sx={{
                      ...hideOnSmallScreenStyle,
                      borderColor: 'rgba(68, 68, 68, 0.8)',
                      height: '20px'
                    }}
                  />
                  <TypographyPlanNameSC
                    variant="body2"
                    sx={hideOnSmallScreenStyle}
                  >
                    {`${
                      daysRemainingInTrial > 0 ? daysRemainingInTrial : 0
                    } days left`}
                  </TypographyPlanNameSC>
                </>
              )}
            {showSupportPortalButton && (
              <HeaderButton
                onClick={async (e) => {
                  e.preventDefault();

                  const backup = getSupportBackupToken();
                  setUserToken(backup);
                  setSupportBackupToken('invalid');

                  await LoadApplication();
                  history.pushState(null, '', '/#/support');
                  rewire();

                  return;
                }}
                sx={hideOnSmallScreenStyle}
              >
                <SupportAgent sx={{ marginRight: '8px' }} fontSize="small" />
                Support Portal
              </HeaderButton>
            )}
            {showCommandCenterButton && (
              <HeaderButton
                onClick={(e) => {
                  e.preventDefault();
                  (async () => {
                    const backupToken = getBackupToken();

                    // if there's not backup token go back to dashboard
                    if (backupToken === 'invalid') {
                      window.location.href = '/#/cc/dashboard';
                      return;
                    }

                    await restoreCommandCenterParent('/#/cc/dashboard');
                  })();
                }}
                sx={hideOnSmallScreenStyle}
              >
                <Domain sx={{ marginRight: '8px' }} fontSize="small" /> Command
                Center
              </HeaderButton>
            )}
            <StatuspageProvider>
              <HeaderMenu
                {...props}
                userLicense={userLicense}
                accountSettings={accountSettings}
                showCommandCenterButton={showCommandCenterButton}
                showSupportPortalButton={showSupportPortalButton}
                showUpgradeButton={showUpgradeButton}
                hideOnLargeScreenStyle={hideOnLargeScreenStyle}
                hideAccountConfiguration={hideAccountConfiguration}
              />
            </StatuspageProvider>
          </>
        )}
      </ToolbarSC>
    </HeaderSC>
  );
};
