import { getParsedLocalStorageItem } from '../../common/utils/localStorage';
import authorizationService from '../../common/helpers/authorization';
import { SupportRole } from '../../common/enums/Roles';
import { IAccountSettingsDto } from '../../common/models';
import { supportPortalLocalStorage } from '../constants';
import { ITokenDto } from '../models/IApiResponseDto';
import { INewlyAddedAccount } from '../models/IAccountData';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';

export const accountSelector = (payload: IAddAccountPayloadDto): string => {
  const selector = `${
    payload.accountId ? `acct#${payload.accountId}` : payload.username
  }`;

  return selector;
};

export const createAccountProfile = (
  accountData: IAccountSettingsDto,
  tokenData: ITokenDto,
  payload: IAddAccountPayloadDto
): INewlyAddedAccount => {
  return {
    accountId: accountData.account,
    companyName: accountData.companyName,
    userCount: accountData.usedLicenses,
    associatedEmail: tokenData.userName,
    tokenData: tokenData,
    payload: payload
  };
};

export const isDuplicate = (
  storedAccounts: INewlyAddedAccount[],
  newAccountId: string
): boolean => {
  const duplicateExists = storedAccounts.filter(
    (storedAccount) => storedAccount.accountId === newAccountId
  );
  return duplicateExists.length > 0;
};


export const getAccount = (accountId: string): INewlyAddedAccount => {
  const accounts: INewlyAddedAccount[] = getParsedLocalStorageItem(
    supportPortalLocalStorage.accounts
  );
  const account = accounts.find((account) => account.accountId === accountId);

  return account;
};

export const checkStoredAccounts = (storedValue) => {
  let stored = storedValue;

  if (Array.isArray(storedValue)) {
    return stored;
  } else {
    stored = [];
  }

  return stored;
};

export const getSupportRole = (): string => {
  let supportRole: string | SupportRole = '';

  Object.values(SupportRole).forEach((role) => {
    if (authorizationService.hasRole(role)) {
      supportRole = role;
    }
  });

  return supportRole;
};
