import React from 'react';
import { workingHoursFunctions } from '../../../../../_app/reports/workingHoursFunctions';
import { getAccountSettings } from '../../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import gridColumnSelectorModalCtrlTemplate from 'views/widgets/gridColumnSelectorModal.html?raw';
import { showModal } from '_reactivtrak/src/common/stores/globalModalStore';
import { LocationPromoModal } from '_reactivtrak/src/_global/modals/components/LocationPromoModal';
import { OfflineMeetingsPromoModal } from '_reactivtrak/src/_global/modals/components/OfflineMeetingsPromoModal';

// Register report component
import './workingHours.component.js';

angular.module('app').controller('WorkingHoursCtrl', WorkingHoursCtrl);

WorkingHoursCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'envConfig',
    'notificationService',
    'authorizationService',
    'exportInfoService',
    'customUibModal',
    'gridPropertiesService'
];

function WorkingHoursCtrl(
    $rootScope,
    $scope,
    $state,
    $window,
    envConfig,
    notificationService,
    authorizationService,
    exportInfoService,
    customUibModal,
    gridPropertiesService
) {
    const { calendarIntegrated } = $scope.$parent;

    if ($window.Intercom) {
        $window.Intercom('trackEvent', 'viewed-working-hours');
    }

    const workingHoursUrl = envConfig.reportingServiceUrl() + '/reports/v1/workinghours?';

    $scope.isLoading = false;
    $scope.noResults = false;
    $scope.workingHoursGridHeight = workingHoursFunctions.gridHeight;
    $scope.workingHoursGridOptions = workingHoursFunctions.initializeGridOptions(
        workingHoursUrl,
        notificationService,
        $scope
    );
    $scope.download = $rootScope.download;
    const accountSettings = getAccountSettings();
    $scope.usedLicenses = accountSettings.usedLicenses;

    $scope.bindReport = (reportFilters) => {
        $scope.workingHoursGridOptions?.dataSource?.read({ reportFilters });
    };

    $scope.tooltipOptions = workingHoursFunctions.generateTooltipOptions();

    $scope.showLocationPromoModal = function () {
        const modalContent = React.createElement(LocationPromoModal);

        showModal(modalContent);
    };

    $scope.showOfflineMeetingsPromoModal = function () {
        const modalContent = React.createElement(OfflineMeetingsPromoModal);

        showModal(modalContent);
    };

    $scope.goToActivityLog = function (index, nameType, dataItem) {
        const name = dataItem.escapedNames[nameType][index];
        const params = {};
        params[nameType] = name;
        $state.go('app.reports.activitylog', params);
    };

    $scope.createLocationTooltipTemplate = function () {
        return `<div style="width: 380px"><div style="text-align:left"><p>Location data is available historically and not available for today's activity. </p></div><div style="width:100%; text-align:right"><span><a class="text-productive" href="https://support.activtrak.com/hc/en-us/articles/8298409034267-The-Location-Insights-Dashboard" target="_blank">LEARN MORE</a></span></div></div>`;
    };

    $scope.createTooltipTemplate = function () {
        const templateValues = {
            enabled: {
                text: 'Offline Meeting data is available historically and not live. It is not included in Productive, Total Hours, or Active Hours.',
                ctaText: 'LEARN MORE',
                ctaLink:
                    'href="https://support.activtrak.com/hc/en-us/articles/14157396964123-Offline-Meetings-EA-" target="_blank"'
            },
            disabled: {
                text: 'Offline Meeting data can take up to 24 hours to begin populating after enabling the integration.',
                ctaText: 'ENABLE INTEGRATION',
                ctaLink: 'ui-sref="app.integrations"'
            }
        };

        const templateValue = calendarIntegrated ? templateValues.enabled : templateValues.disabled;

        return `<div style="width: 380px"><div style="text-align:left"><p>${templateValue.text}</p></div><div style="width:100%; text-align:right"><span><a class="text-productive" ${templateValue.ctaLink}>${templateValue.ctaText}</a></span></div></div>`;
    };

    $scope.$on('restoreColumns', function () {
        workingHoursFunctions.resetGridOptions($scope.workingHoursGrid);
        gridPropertiesService.hideLeftBorder();
    });

    $scope.$on('selectColumns', function () {
        const grid = $scope.workingHoursGrid;

        customUibModal.open({
            animation: false,
            template: gridColumnSelectorModalCtrlTemplate,
            controller: 'gridColumnSelectorModalCtrl',
            size: 'md',
            resolve: {
                grid: grid
            }
        });
    });

    // Export...
    exportInfoService.setExportInfo({
        mainDataSource: $scope.workingHoursGridOptions.dataSource,
        getCacheKey: workingHoursFunctions.getCacheKey,
        getSecondaryParameters: function () {
            return {
                filter: $scope.workingHoursGridOptions.dataSource.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: $scope.workingHoursGridOptions.dataSource.sort()
            };
        }
    });
}
