import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { reset as dataStoreManagerReset } from '_app/datastoreManager/DatastoreManager';
import { authenticatedUserService, inMemoryStore } from 'activtrak-ui-utilities';

import { logout as logoutService } from '../serviceFunctions/logoutApiService';
import { removeDataDogContext, stopDataDogRumSessionRecording } from '../../_reactivtrak/src/common/config/dataDog';
import { logout as logoutV2 } from '../../_reactivtrak/src/common/helpers/authentication';
import { getAccountSettings } from '../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { profileStore } from '../../_reactivtrak/src/common/services/Profile/useProfileState';
import authorization from '../../_reactivtrak/src/common/helpers/authorization';
import { Role } from '../../_reactivtrak/src/common/enums';
import { getUserToken } from '../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { getInjector } from '../../_reactivtrak/src/common/third-party/angular2react/useInjector';

const constructor = () => {
    
    let _eventListeners = {};
    let globalsSubscription;
    let _isLoggingOut = false;

    const clearScope = () => {
        for (let listener in _eventListeners) {
            if (_eventListeners[listener] && typeof _eventListeners[listener] === 'function') {
                _eventListeners[listener]();
            }
        }
    };

    const logout = async (params) => {
        if (_isLoggingOut) {
            return;
        }

        _isLoggingOut = true;
        
        const $injector = getInjector();
        const notificationService = $injector.get('notificationService');

        try {
            // Make api calls to tell the backend the session is terminated
            try {
                await logoutService();
            } catch (e) {
                console.error('ActivTrak Error: Error occurred calling the Logout API', e);
            }
            const { account } = getAccountSettings();
            const { username } = profileStore.getState().profile;
            
            const teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');
            teamPulseDataControl?.resetSubjectDefaults();

            localStorage.removeItem('productBoard-' + username);
            // Cleans out all local states
            notificationService.cancelPolling();
            const analyticsService = $injector.get('analyticsService');
            analyticsService.accountLogout();
            stopDataDogRumSessionRecording();
            removeDataDogContext();
            
            dataStoreManagerReset();
            if (globalsSubscription) {
                globalsSubscription.unsubscribe();
            }
            clearScope();
            // Removes local storage and local session information
            localStorage.removeItem('msp.backup');
            localStorage.removeItem('activTrak.support.backup');

            // Removing key 'teamNotSetDate_' at logout so that the team modal can appear on the next login regardless of the date
            const currentAccountId = account;
            localStorage.removeItem('teamNotSetDate_' + currentAccountId);
            clearLastLogin();

            logoutV2(params);

            _isLoggingOut = false;
        } catch (e) {
            notificationService.showNotification(e, 'danger');
            console.error('ActivTrak Error: Error occured during the logout process', e);
            _isLoggingOut = false;
        }
    };

    inMemoryStore.set('logout', logout);

    const clearLastLogin = () => {
        authenticatedUserService.clearLastLogin();
    };

    const addEventListener = (name, listener) => {
        _eventListeners[name] = listener;
    };

    const appLoaded = (accountId, teamModalTemplate) => {
        let upgradeParams;
        const teamNotSetDateString = localStorage.getItem('teamNotSetDate_' + accountId);
        let isPastDelay = true;
        const teamModalDelay = moment.duration(60, 'minutes');

        if (teamNotSetDateString) {
            const teamNotSetDate = teamNotSetDateString ? moment(teamNotSetDateString) : null;
            const timeDiff = moment.utc().diff(teamNotSetDate, 'minutes');
            isPastDelay = timeDiff > teamModalDelay.asMinutes();
        }

        const teamNotSet = Boolean(localStorage.getItem('teamNotSet_' + accountId));

        // Here, we check if the team have not been set and if we are past the window refresh delay period
        if (teamNotSet && isPastDelay) {
            localStorage.setItem('teamNotSet_' + accountId, false);
            const $injector = getInjector();
            const customUibModal = $injector.get('customUibModal');
            let teamModal = customUibModal.open({
                animation: false,
                template: teamModalTemplate,
                controller: 'signupTeamModalCtrl',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'centered-modal'
            });

            teamModal.result.catch(angular.noop);
        } else if (localStorage.getItem('newAccount_' + accountId)) {
            // CJ Lead Event
            // TODO: Refactor this into analytics provider....
            upgradeParams = {
                oid: accountId,
                itemSku: 'FREE',
                amount: 0,
                quantity: 3,
                itemDiscount: 0,
                currency: 'USD',
                coupon: ''
            };
        }

        const setLocalStorageValues = () => {
            localStorage.setItem('newAccount_' + accountId, false);
            localStorage.setItem('teamNotSet_' + accountId, true);
            localStorage.setItem('teamNotSetDate_' + accountId, moment.utc().format());
        };

        if (localStorage.getItem('newAccount_' + accountId)) {
            setLocalStorageValues();
        }

        // Return CJ upgradeParams if set
        return upgradeParams;
    };

    const isRestrictedPage = () => {
        return ![
            '/login',
            '/signup',
            '/signup-login',
            '/verify',
            '/unsupportedBrowser',
            '/signup/google',
            '/chromebook/install'
        ].includes(window.location.pathname.split('?')[0]);
    };

    const isDifferentAccount = (url) => {
        let isDifferent;
        let userToken = getUserToken();
        let accountId = userToken && userToken.decoded && userToken.decoded.AccountId;
        let urlParts = url.split('?');
        if (urlParts.length < 2) {
            return;
        }
        let params = urlParts[1].split(/[&]|%26/);
        params.forEach((param) => {
            let paramSplit = param.split('=');
            if (accountId && paramSplit[0].toLowerCase() === 'acctid') {
                isDifferent = decodeURIComponent(paramSplit[1]) !== accountId;
            }
        });

        return isDifferent === undefined
            ? undefined
            : {
                  isDifferent: isDifferent,
                  isAdmin: authorization.hasRole([Role.Admin, Role.SuperAdmin])
              };
    };

    const getLoginEmail = (url) => {
        let loginEmail;
        let urlParts = url.split('?');
        if (urlParts.length < 2) {
            return;
        }
        let params = urlParts[1].split(/[&]|%26/);
        params.forEach((param) => {
            let paramSplit = param.split('=');

            if (paramSplit[0].toLowerCase() === 'email') {
                loginEmail = decodeURIComponent(paramSplit[1]);
            }
        });

        return loginEmail;
    };


    let enableAccountWizard = () => {
        return userServiceFunctions.enableAccountWizard();
    };

    return {
        clearScope,
        logout,
        clearLastLogin,
        addEventListener,
        appLoaded,
        isRestrictedPage,
        isDifferentAccount,
        getLoginEmail,
        enableAccountWizard
    };
};

export const loginServiceFunctions = inMemoryStore.get('loginServiceFunctions') || constructor();
inMemoryStore.set('loginServiceFunctions', loginServiceFunctions);
