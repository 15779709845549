'use strict';

angular.module('app').service('alarmService', ['$http', 'envConfig',
    function ($http, envConfig) {
        this.getAlarmUsers = function (skip, take, filter) {
            var params = { take: take, skip: skip, filter: filter };
            return $http.get(envConfig.apiUrl() + '/api/alarms/users', { params: params });
        };
        this.getAlarm = function (alarmId) {
            return $http.get(envConfig.apiUrl() + '/api/alarms/' + alarmId);
        };
        this.getAlarmDetails = function (alarmId) {
            return $http.get(envConfig.apiUrl() + '/api/alarmdetails/' + alarmId);
        };
        this.getAlarms = function () {
            return $http.get(envConfig.apiUrl() + '/api/alarms');
        };
        this.getConditions = function (type) {
            return $http.get(envConfig.apiUrl() + '/api/alarms/conditions?type=' + type);
        };
        this.getFields = function (type, alarmId) {
            return $http.get(envConfig.apiUrl() + '/api/alarms/fields?type=' + type + '&alarmid=' + alarmId);
        };
        this.createAlarm = function (payload) {
            return $http.post(envConfig.apiUrl() + '/api/alarms', payload);
        };
        this.saveAlarm = function (payload) {
            return $http.put(envConfig.apiUrl() + '/api/alarms', payload);
        };
        this.deleteAlarm = function (alarmId) {
            return $http.delete(envConfig.apiUrl() + '/api/alarms/' + alarmId);
        };
    }
]);
