import { SvgIconProps } from './SvgIconProps';

export const ExcelIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  const height = props.height ?? '24px';
  const width = props.width ?? '24px';

  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 3H7.25006C7.01799 3.00002 6.79543 3.09222 6.63133 3.25633C6.46722 3.42043 6.37502 3.64299 6.375 3.87506V7.5H14.25V3Z"
        fill="#21A366"
      />
      <path
        d="M21.2499 3H14.25V7.5H22.125V3.87506C22.125 3.64299 22.0328 3.42043 21.8687 3.25633C21.7046 3.09222 21.482 3.00002 21.2499 3Z"
        fill="#33C481"
      />
      <path d="M22.125 12H14.25V16.5H22.125V12Z" fill="#107C41" />
      <path
        d="M14.25 16.5V12H6.375V20.1249C6.37502 20.357 6.46722 20.5796 6.63133 20.7437C6.79543 20.9078 7.01799 21 7.25006 21H21.2499C21.482 21 21.7046 20.9078 21.8687 20.7437C22.0328 20.5796 22.125 20.357 22.125 20.1249V16.5H14.25Z"
        fill="#185C37"
      />
      <path d="M14.25 7.5H6.375V12H14.25V7.5Z" fill="#107C41" />
      <path d="M22.125 7.5H14.25V12H22.125V7.5Z" fill="#21A366" />
      <path
        opacity="0.2"
        d="M12.375 7.62378V17.5012C12.3758 17.5516 12.3708 17.6019 12.36 17.6512C12.3259 17.8541 12.2208 18.0382 12.0635 18.1708C11.9062 18.3034 11.7069 18.3757 11.5012 18.375H6.375V6.75H11.5012C11.616 6.7498 11.7297 6.77226 11.8358 6.8161C11.9419 6.85994 12.0383 6.9243 12.1195 7.00548C12.2007 7.08665 12.2651 7.18306 12.3089 7.28916C12.3527 7.39526 12.3752 7.50898 12.375 7.62378Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M12.75 7.62378V16.7512C12.7469 17.0815 12.6143 17.3973 12.3808 17.6308C12.1473 17.8643 11.8315 17.9969 11.5012 18H6.375V6.375H11.5012C11.8321 6.37616 12.149 6.50809 12.383 6.74204C12.6169 6.97598 12.7488 7.29294 12.75 7.62378Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M12.375 7.62378V16.7512C12.3752 16.866 12.3527 16.9797 12.3089 17.0858C12.2651 17.1919 12.2007 17.2883 12.1195 17.3695C12.0383 17.4507 11.9419 17.5151 11.8358 17.5589C11.7297 17.6027 11.616 17.6252 11.5012 17.625H6.375V6.75H11.5012C11.616 6.7498 11.7297 6.77226 11.8358 6.8161C11.9419 6.85994 12.0383 6.9243 12.1195 7.00548C12.2007 7.08665 12.2651 7.18306 12.3089 7.28916C12.3527 7.39526 12.3752 7.50898 12.375 7.62378Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M12 7.62378V16.7512C12.0002 16.866 11.9777 16.9797 11.9339 17.0858C11.8901 17.1919 11.8257 17.2883 11.7445 17.3695C11.6633 17.4507 11.5669 17.5151 11.4608 17.5589C11.3547 17.6027 11.241 17.6252 11.1262 17.625H6.375V6.75H11.1262C11.241 6.7498 11.3547 6.77226 11.4608 6.8161C11.5669 6.85994 11.6633 6.9243 11.7445 7.00548C11.8257 7.08665 11.8901 7.18306 11.9339 7.28916C11.9777 7.39526 12.0002 7.50898 12 7.62378Z"
        fill="black"
      />
      <path
        d="M11.125 6.75H2.375C1.89175 6.75 1.5 7.14175 1.5 7.625V16.375C1.5 16.8582 1.89175 17.25 2.375 17.25H11.125C11.6082 17.25 12 16.8582 12 16.375V7.625C12 7.14175 11.6082 6.75 11.125 6.75Z"
        fill="#107C41"
      />
      <path
        d="M4.20972 14.8438L6.05006 11.992L4.3644 9.15613H5.72087L6.64104 10.9687C6.72563 11.1406 6.78381 11.2689 6.81555 11.3534H6.82745C6.88825 11.216 6.95171 11.0824 7.01783 10.9528L8.00146 9.15613H9.24687L7.51757 11.9761L9.29049 14.8438H7.96577L6.90281 12.8527C6.85272 12.768 6.81025 12.6791 6.77589 12.587H6.76002C6.72899 12.6773 6.68774 12.7638 6.63707 12.8448L5.54238 14.8438H4.20972Z"
        fill="white"
      />{' '}
    </svg>
  );
};
