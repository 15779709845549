import { useCallback } from 'react';
import { consoleMessage } from '../constants/notificationMessages';
import { IStorageDto, IStorageServiceProps, IStorageState } from '../models';

export const useStorageState = ({
  storageService
}: IStorageServiceProps): IStorageState => {
  const { getTotalStorage } = storageService;

  const getStorageInfo = useCallback(
    async (token: string): Promise<IStorageDto> => {
      try {
        return await getTotalStorage(token);
      } catch (error) {
        if (error?.status === 400) {
          throw new Error(consoleMessage.storageError[400]);
        }
        if (error?.status === 500) {
          throw new Error(consoleMessage.storageError[500]);
        }
        if (!error.response) {
          throw new Error(consoleMessage.storageError.network);
        }
        throw new Error(consoleMessage.storageError.unknown);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    getStorageInfo
  };
};
