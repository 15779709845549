import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { getDateTimeFormat } from '../../../common/utils/datetime/datetimeFormatStrings';
import { buildReportsApiRequest } from '../../../dashboard/utils/widget.utils';
import { ProductivityStatus } from '../../../common/enums/ProductivityStatus';
import { generateGuid } from '../../../common/utils/generateGuid';
import {
  AtTimelineGroup,
  AtTimelineItem
} from '../../../common/components/AtTimeline/atTimeline.models';
import { getClass } from '../../../common/helpers/productivity';
import { customizeProductivityTooltip } from '../../../react-charts-demo/utils/timeline.utils';
import {
  setTimelineOptions,
  setTimelineStatus
} from '../../../common/components/AtTimeline/atTimeline.store';
import { createBaseReportFilterParams } from '../../../common/components/ReportFilters/utils/reportFilter.utils';
import { ApiStatus } from '../../../common/enums/ApiStatus';

export const useTimelineData = () => {
  const [data, setData] = useState({ rows: [], segments: [] });

  const getSingleDayData = useCallback(
    async (baseFilters, from: Moment, to: Moment): Promise<any> => {
      const dateTimeFormat = getDateTimeFormat();

      const filters = {
        ...baseFilters,
        from: from.format(dateTimeFormat),
        to: to.format(dateTimeFormat)
      };

      const response = await buildReportsApiRequest<any>({
        path: '/reports/v1/productivity/gantt?',
        params: filters
      });

      return response;
    },
    []
  );

  const mapProductivityStatus = useCallback((productivity) => {
    const tempView = 'detailedView';

    switch (productivity) {
      case 1:
        return ProductivityStatus.Productive;
      case 2:
        return ProductivityStatus.Unproductive;
      case 3:
        return ProductivityStatus.Undefined;
      case 4:
        return tempView === 'detailedView'
          ? ProductivityStatus.UndefinedPassive
          : ProductivityStatus.Undefined;
      case 5:
        return tempView === 'detailedView'
          ? ProductivityStatus.ProductivePassive
          : ProductivityStatus.Productive;
      case 6:
        return tempView === 'detailedView'
          ? ProductivityStatus.UnproductivePassive
          : ProductivityStatus.Unproductive;
      case 9:
        return ProductivityStatus.OfflineMeetings;
      default:
        return ProductivityStatus.None;
    }
  }, []);

  const getLocation = useCallback((details, location) => {
    const locationObj = location?.find(function (location) {
      return location.userName === details.user;
    });

    return locationObj?.location ?? undefined;
  }, []);

  //   const startTemp = 8;
  //   const endTemp = 17;

  const createRows = useCallback(
    (data, location) => {
      const rows: AtTimelineGroup[] = [];

      data.forEach((details) => {
        const group = rows.find(
          (group) => group.content === details.user // This should be based on id but the API does not support yet
        );
        const _location = getLocation(details, location);
        if (!group) {
          const rowId = generateGuid();
          // Future use case of adding schedule start/end. It should come from the controls for setting up the schedule
          //   const startOffset = Math.floor(Math.random() * 2);
          //   const endOffset = Math.floor(Math.random() * 2);
          rows.push({
            id: rowId,
            content: details.user,
            metadata: {
              id: details.userId,
              dates: [details.date],
              location: [_location]
              //   scheduleStart: startTemp + startOffset + ':00:00',
              //   scheduleEnd: endTemp + endOffset + ':00:00'
            }
          });
        } else if (!group.metadata.dates.includes(details.date)) {
          group.metadata.dates.push(details.date);
        } else if (!group.metadata.location.includes(_location)) {
          group.metadata.location.push(_location);
        }
      });

      return rows;
    },
    [getLocation]
  );

  const createSegments = useCallback(
    (response, rows, from) => {
      const userDate = from.clone().format('YYYY-MM-DD');
      const responseId = generateGuid();

      let dataStart;
      let dataEnd;
      let segments = response
        .map((details, index): AtTimelineItem => {
          const productivityStatus = mapProductivityStatus(
            details.productivity
          );

          const start = moment(userDate + ' ' + details.start);
          const end = moment(userDate + ' ' + details.end);

          const group = rows.find((group) => group.content === details.user);
          const isStartOfSchedule = details.start.includes(
            group?.metadata.scheduleStart
          );
          const isEndOfSchedule = details.end.includes(
            group?.metadata.scheduleEnd
          );

          const segmentWithData =
            productivityStatus !== ProductivityStatus.None ||
            isStartOfSchedule ||
            isEndOfSchedule;

          if (segmentWithData && (!dataStart || start.diff(dataStart) < 0)) {
            dataStart = start;
          }

          if (segmentWithData && (!dataEnd || dataEnd.diff(end) < 0)) {
            dataEnd = end;
          }

          const classes = [];

          if (isStartOfSchedule) {
            classes.push('timeline-schedule-start');
          }

          if (isEndOfSchedule) {
            classes.push('timeline-schedule-end');
          }

          const productivityClass = getClass(productivityStatus);

          const hasData =
            productivityStatus !== ProductivityStatus.None ||
            classes.length > 0;

          if (!hasData) {
            return;
          }

          if (productivityStatus !== ProductivityStatus.None) {
            classes.push('bg-' + productivityClass);
            classes.push('border-' + productivityClass);
          } else {
            classes.push('timeline-bg-none');
            classes.push('timeline-border-none');
          }

          const item = {
            id:
              responseId + '_' + group?.id + '_' + details.userId + '_' + index,
            group: group?.id,
            start: start.toDate(),
            end: end.toDate(),
            metadata: {
              user: details.user,
              productivityStatus,
              activity: details.activity,
              hasDescription: details.hasDescription,
              isWebsite: details.isWebsite,
              isScheduleStart: details.start === group?.metadata.scheduleStart,
              isScheduleEnd: details.end === group?.metadata.scheduleEnd,
              tooltipTemplate: customizeProductivityTooltip(
                {
                  user: details.user,
                  activity: details.activity,
                  productivity: productivityStatus
                },
                start,
                end
              )
            },
            content: '',
            className: classes.join(' ')
          };

          return item;
        })
        .filter((item) => item?.metadata?.productivityStatus !== undefined);

      segments = segments.sort(
        (a, b) => a.groupId - b.groupId && a.start.getTime() - b.start.getTime()
      );

      return { rows, segments, dataStart, dataEnd };
    },
    [mapProductivityStatus]
  );

  const formatResponseData = useCallback(
    (data, location, from) => {
      const rows = createRows(data, location);
      return createSegments(data, rows, from);
    },
    [createRows, createSegments]
  );

  const fetchTimelineData = useCallback(
    async (
      date: Moment,
      interval: 5 | 15 | 30 | 60 | 120 | 240 = 30
    ): Promise<void> => {
      setTimelineStatus(ApiStatus.Loading);
      setData({ rows: [], segments: [] });

      const { groupId, userId, computerId, userType } =
        createBaseReportFilterParams();

      const _from = moment(date).clone().startOf('day');
      const _to = moment(date).clone().endOf('day');
      const dateTimeFormat = getDateTimeFormat();

      const baseFilters = {
        userId: groupId || userId || computerId,
        userType,
        from: _from.format(dateTimeFormat),
        to: _to.format(dateTimeFormat),
        strict: false,
        interval,
        ganttSort: 'TotalActivity',
        mode: 'users',
        showOfflineMeetings: true
      };

      setTimelineOptions({ min: _from.toDate(), max: _to.toDate() });

      try {
        const response = await getSingleDayData(baseFilters, _from, _to);
        const { data, location } = response;
        const { rows, segments, dataStart, dataEnd } = formatResponseData(
          data,
          location,
          _from
        );

        // Convert the data start and end times to make the data visible on the timeline with some padding
        // Data start should be the data's start time, minus 1 hour, and converted to the hour
        // Data end should be the data's end time, plus 1 hour, and converted to the hour
        setTimelineOptions({
          start: dataStart.clone().add(-1, 'h').startOf('hour').toDate(),
          end: dataEnd.clone().add(1, 'h').startOf('hour').toDate()
        });

        setData({
          segments,
          rows
        });
      } catch (error) {
        console.error(
          'ActivTrak Error: Unable to load timeline data =>',
          error
        );
        setTimelineStatus(ApiStatus.Error);
      }
    },
    [getSingleDayData, formatResponseData]
  );

  return {
    getTimelineData: fetchTimelineData,
    data
  };
};
