import angular from 'angular';
import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { latestUpdatesServiceFunctions } from '_app/serviceFunctions/latestUpdatesServiceFunctions';
import { gridServiceFunctions } from '../../../_app/serviceFunctions/gridServiceFunctions';
import { getAccountPlanStore, isAccountPlanInTrial } from '../../../_reactivtrak/src/common/stores/accountPlanStore';

import messageModalTemplate from '../../views/modals/messageModal.html?raw';
import trialEndedModalTemplate from '../../views/modals/_trialEndedModal.html?raw';
import trialReminderModalTemplate from '../../views/modals/_trialReminderModal.html?raw';
import signupTeamModalTemplate from '../../views/modals/signupTeamModal.html?raw';
import {
    accountSettingsStore,
    getAccountSettings,
    setAccountSettings
} from '../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { Role } from '../../../_reactivtrak/src/common/enums';
import { loginServiceFunctions } from '../../../_app/serviceFunctions/loginServiceFunctions';
import { getUserName } from '../../../_reactivtrak/src/common/services/Profile/useProfileState';

import { inMemoryStore } from '../../../_ui-utils/src/inMemoryStore';
import { constructor as teamPulseDataControl } from '../../../_app/serviceFunctions/teamPulseDataControl';

/* ============================================================
 * File: main.js
 * Main Controller to set global scope variables.
 * ============================================================ */

angular.module('app').controller('MainCtrl', MainCtrl);

MainCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$locale',
    'customUibModal',
    'localStorageService',
    '$window',
    '$timeout',
    'analyticsService',
    'accountApiService',
    'messagesService',
    'rootScopeConfig',
    'url',
    'profileService',
    'notificationService',
    'loginService',
    'msaService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'authorizationService',
    'accessApiService',
    '$transitions'
];

function MainCtrl(
    $scope,
    $rootScope,
    $state,
    $locale,
    customUibModal,
    localStorageService,
    $window,
    $timeout,
    analyticsService,
    accountApiService,
    msg,
    rootScopeConfig,
    url,
    profileService,
    notificationService,
    loginService,
    msaService,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions,
    authorizationService,
    accessApiService,
    $transitions
) {
    var {
        account: accountId,
        isDataConsoleEnabled,
        roles,
        subscriptionType,
        msaAcceptanceRequired
    } = getAccountSettings();

    $scope.ssoEligible = false;

    // Initialize modules
    gridServiceFunctions.initialize(localStorageService);
    latestUpdatesServiceFunctions.clearLocals();

    accessApiService.getSsoEligible(accountId).success(function (response) {
        $scope.ssoEligible = response;
    });

    latestUpdatesServiceFunctions.getUserLastSeen();

    $rootScope.isAccountCreationMode = false;

    var shouldShowCommandCenterLink = function () {
        if (authorizationService.hasRole([Role.CommandCenter])) {
            return true;
        }
        if (
            authorizationService.hasRole([Role.SupportAdvanced, Role.SuperAdmin]) &&
            roles.find(function (key) {
                return key.toUpperCase() === authorizationService.roles.ccAccess.toUpperCase();
            }) // currently, support users do not have cc roles in the token. Have to use what is returned in post_login
        ) {
            return true;
        }

        //TODO: Temporarily rely on a backup token to show the link back to command center.
        return Boolean($window.localStorage.getItem('activTrak.msp.backup'));
    };

    $scope.hasCommandCenter = shouldShowCommandCenterLink();

    const username = getUserName();

    $scope.getUserString = function () {
        return $scope.profileInfo.firstName
            ? 'Hello ' + $scope.profileInfo.firstName + ' (' + username + ')'
            : username;
    };

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.videos.history');
    };

    $scope.logout = function (e) {
        if (e) {
            e.preventDefault();
        }

        loginService.logout();
    };

    $rootScope.ignoreCategoryTooltip = function () {
        return '<div>Activities assigned to the "Ignore" category will not<br/>be included in any reports other than the Activity Log.</div>';
    };

    //TODO: Evaluate
    $scope.isIeOrEdgeBrowser = browserServiceFunctions.isIeOrEdgeBrowser();
    $scope.helpers = atHelperFunctions;

    // Used in the trial reminder modal only, remove when modal is updated
    function updateCurrentPlanText() {
        var { planTypeLabel } = getAccountPlanStore();
        $rootScope.currentPlan = planTypeLabel;
    }
    updateCurrentPlanText();

    $scope.showTopBar = function () {
        var leftTopBar = document.getElementById('left-topbar');
        var isHidden = function (element) {
            return element.classList.contains('hide-topbar');
        };

        if (leftTopBar && isHidden(leftTopBar)) {
            var profileMenu = document.getElementById('profile-menu');
            var profileSmallMenu = document.getElementById('profile-small-menu');
            if (profileMenu && isHidden(profileMenu) && profileSmallMenu && isHidden(profileSmallMenu)) {
                profileMenu.classList.remove('hide-topbar');
                profileSmallMenu.classList.remove('hide-topbar');
            }

            leftTopBar.classList.remove('hide-topbar');
        }
    };

    $scope.hasRoute = function (routeName) {
        return authorizationService.hasRouteByName(routeName);
    };

    $scope.hasFeature = function (featureName) {
        return authorizationService.hasFeature(featureName);
    };

    $scope.hasRole = function (roles) {
        return authorizationService.hasRole(roles);
    };

    $scope.supportRole = authorizationService.hasRole(authorizationService.roles.supportBasic);
    $scope.supportPortalRole =
        authorizationService.hasRole(authorizationService.roles.supportPortal) || userServiceFunctions.getIsImposter();

    $scope.isImpersonating = userServiceFunctions.getIsImposter();

    // Set currency negative formatter
    $locale.NUMBER_FORMATS.PATTERNS[1].negPre = '(\u00A4';
    $locale.NUMBER_FORMATS.PATTERNS[1].negSuf = ')';

    function downloadAgentCallback(url) {
        if (isEdgeBrowser) {
            $window.location.href = url;
        } else {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = url;
            link.click();
        }
    }

    $scope.getAgentDownloadUrl = function (os, callback) {
        $scope.downloading = true;
        accountApiService
            .downloadAgent(accountId, os)
            .success(function (result) {
                if (result.url === '' || typeof result.url !== 'string') {
                    console.error('Unable to get agent download link', result);
                    $scope.$emit('showNotification', {
                        message: msg.get('agentDownloadFailed'),
                        color: 'danger',
                        timeout: 0
                    });
                } else if (typeof callback === 'function') {
                    callback(result.url);

                    if ((os === 'mac' || result.url.search(/ATAcct[0-9]{6}\.msi/) === -1) && os !== 'chrome') {
                        var osStrings = {
                            mac: 'Mac',
                            win: 'Windows'
                        };

                        var title = 'Thank you for downloading the ActivTrak ' + osStrings[os] + ' Installer';
                        var body =
                            "The name of ActivTrak's " +
                            osStrings[os] +
                            ' Agent installer cannot be modified. Modifying the name of this installer will prevent the agent from being properly installed on any computer. ';
                        if (os === 'mac') {
                            body +=
                                'For more information on install-time configurations or troubleshooting, read our ' +
                                '<a href="https://support.activtrak.com/hc/en-us/articles/17188986842011/" target="_blank" rel="noreferrer">macOS Agent article. </a>';
                        } else {
                            body += 'For assistance, please contact Support.';
                        }

                        customUibModal
                            .open({
                                animation: false,
                                template: messageModalTemplate,
                                controller: 'messageModalController',
                                windowClass: 'centered-modal',
                                keyboard: false,
                                backdrop: 'static',
                                resolve: {
                                    messageData: {
                                        hideCancelButton: true,
                                        confirmClass: 'btn-success',
                                        messageTitle: title,
                                        messageBody: body
                                    }
                                }
                            })
                            .result.then(angular.noop)
                            .catch(angular.noop);
                    }
                }

                $scope.downloading = false;
            })
            .error(function (result) {
                console.error('Unable to get agent download link', result);
                $scope.$emit('showNotification', {
                    message: msg.get('agentDownloadFailed'),
                    color: 'danger',
                    timeout: 0,
                    skipEscape: true
                });
                $scope.downloading = false;
            });
    };

    $rootScope.download = function (os) {
        if (!os) {
            var platform = navigator.platform.toUpperCase();
            var userAgent = navigator.userAgent.toUpperCase();

            if (platform.indexOf('WIN') >= 0) {
                os = 'win';
            } else if (userAgent.indexOf('CROS') >= 0) {
                os = 'chrome';
            } else {
                os = 'mac';
            }
        }

        if (os === 'chrome') {
            accountApiService
                .registerChromeUser()
                .success(function () {
                    $scope.getAgentDownloadUrl(os, function (url) {
                        $window.open(url, '_blank');
                    });
                })
                .error(function (result) {
                    if (result.code === 'another_account') {
                        var modal = customUibModal.open({
                            animation: false,
                            template: messageModalTemplate,
                            controller: 'messageModalController',
                            windowClass: 'centered-modal',
                            resolve: {
                                messageData: {
                                    messageTitle: msg.get('doYouWantToChangeChromeAgentTitle'),
                                    messageBody: msg.get('doYouWantToChangeChromeAgentBody'),
                                    confirmLabel: msg.get('yes')
                                }
                            }
                        });

                        modal.result.then(
                            function () {
                                accountApiService.registerChromeUser(true);

                                $scope.getAgentDownloadUrl(os, function (url) {
                                    $window.location.href = url;
                                });
                            },
                            function () {
                                $scope.downloading = false;
                            }
                        );
                    } else {
                        console.error('Unable to register Chrome user', result);
                        $scope.downloading = false;
                    }
                });
        } else {
            $scope.getAgentDownloadUrl(os, downloadAgentCallback);
        }
    };

    var isEdgeBrowser = function () {
        var ua = window.navigator.userAgent;

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return true;
        }

        // other browser
        return false;
    };

    // Checks if the given state is the current state
    $scope.is = function (name) {
        return $state.is(name);
    };

    $scope.isTrial = isAccountPlanInTrial();
    $scope.isAdmin = authorizationService.hasRole([authorizationService.roles.admin]);

    function getRemainingTrial() {
        var { planRemainingDays } = getAccountPlanStore();

        if (!$scope.isTrial || planRemainingDays === -1) {
            return;
        }

        function getTrialMessage(days) {
            if (days === 1) {
                return 'Last Day of Trial!';
            } else if (days > 1) {
                return Math.ceil(days) + ' Days Remaining';
            } else {
                return 'Trial Expired';
            }
        }

        return {
            message: getTrialMessage(planRemainingDays),
            messageClass: planRemainingDays <= 2 ? 'text-danger' : ''
        };
    }

    $scope.remainingTrial = getRemainingTrial();

    $scope.goToSupportPortal = function () {
        if ($scope.supportPortalRole) {
            $state.go('supportportal');
        } else {
            $scope.goToAccountAccess();
        }
    };

    $scope.goToAccountAccess = function () {
        $state.go('app.account.access');
    };

    // TODO: Start to refactor this into decorators
    loginService.addEventListener(
        'locationChangeStart',
        $rootScope.$on('$locationChangeStart', function () {
            $rootScope.dismissNotifications();
        })
    );

    $scope.convertLogToScreenshot = function (logEntry) {
        if (!logEntry.ssUrl) {
            return null;
        }

        var time = logEntry.time_utc ? atHelperFunctions.convertTimezone(logEntry.time_utc) : logEntry.time;

        return {
            fullsize: logEntry.ssUrl,
            time: atHelperFunctions.formatDate(time, {
                span: false
            }),
            user: logEntry.user,
            computer: logEntry.computer,
            titlebar: logEntry.titleBar,
            url: logEntry.url,
            description: logEntry.description,
            executable: logEntry.executable,
            prod: logEntry.prod,
            appId: logEntry.appId,
            webId: logEntry.webId,
            isSafe: logEntry.isSafe,
            safeSearchObject: logEntry.safeSearchObject
        };
    };

    $scope.isSignup = function () {
        return $state.current.name === 'signup.modal' || $state.current.name === 'signup.google';
    };

    $scope.shortLabelsFilter = function (maxSize, property, formatLabel) {
        return kendo.template(function (data) {
            var value = data[property];
            if (formatLabel) {
                value = formatLabel(value);
            }
            if (value.length > maxSize) {
                value = value.substring(0, maxSize - 1) + 'â€¦';
            }
            return value;
        });
    };

    $scope.loadImage = function (image) {
        var url = `/images/${image}`;
        return url;
    };

    $rootScope.loadImage = $scope.loadImage;

    profileService.loadProfile();

    $scope.$watch(
        function () {
            return profileService.profileInfo;
        },
        function () {
            $scope.profileInfo = profileService.profileInfo;
        }
    );

    // rootScope Decorator
    rootScopeConfig.config($rootScope, $scope, $state, $window, localStorageService, url);

    // Reset localstorage Settings for version upgrade
    var build = localStorageService.get('activtrak-ui-build');
    /* globals __VERSION__:false */
    var version = __VERSION__;
    if (!build || build !== version) {
        localStorageService.remove('activity-log-grid');
        localStorageService.set('activtrak-ui-build', version);
    }

    function checkForExpiredTrial() {
        if (authorizationService.hasRouteByName('app.account.upgrade')) {
            userServiceFunctions.getUserLastSeen('expired_trial_do_not_show').then(function (doNotShowDate) {
                if (!profileService.profileInfo.firstName || !profileService.profileInfo.lastName) {
                    console.error('ActivTrak Error: User first or last name missing from profile info.');
                }

                // AC-9813: Added paid plan check to ensure modal does not show for paid plans
                var { planRemainingDays, isPlanDowngraded } = getAccountPlanStore();
                if (
                    !authorizationService.hasFeature('isPaidFunctionalityEnabled') &&
                    isPlanDowngraded &&
                    !doNotShowDate &&
                    subscriptionType !== 'MSP Official - Child' &&
                    subscriptionType !== 'Enterprise - Child'
                ) {
                    customUibModal.open({
                        animation: false,
                        template: trialEndedModalTemplate,
                        controller: 'TrialReminderModalCtrl',
                        windowClass: 'centered-modal',
                        keyboard: true,
                        backdrop: 'static'
                    });
                } else if ($scope.isTrial && planRemainingDays === 2) {
                    customUibModal.open({
                        animation: false,
                        template: trialReminderModalTemplate,
                        controller: 'TrialReminderModalCtrl',
                        windowClass: 'centered-modal',
                        keyboard: true,
                        backdrop: 'static'
                    });
                }
            });
        }
    }

    $timeout(function () {
        // TODO: Calling login here may produce more hits, please review
        analyticsService.accountLogin();

        // TODO: convert to await on setting the expired trial message once code is updated to ES6
        if (localStorageService.get('activtrak-ui-do-not-show-expired_' + accountId)) {
            // Set expired_trial_do_not_show for users who used the older local storage system.
            userServiceFunctions.setUserLastSeen('expired_trial_do_not_show').then(function () {
                localStorageService.remove('activtrak-ui-do-not-show-expired_' + accountId);
                checkForExpiredTrial();
            });
        } else {
            checkForExpiredTrial();
        }

        //TODO: Quick hack to compensate user count for single path
        if (localStorageService.get('newAccount_' + accountId)) {
            setAccountSettings({ totalLicenses: 100 });
        }

        $scope.upgradeParams = loginService.appLoaded(accountId, signupTeamModalTemplate);

        // Hamburger Touch Feedback
        // TODO: Improve this...
        angular.element('.header a.pg').on('touchstart', function (e) {
            // console.log('touchstart==>', e);
            e.target.style.background = '#DDD';
        });

        angular.element('.header a.pg').on('touchend', function (e) {
            // console.log('touchend==>', e);
            e.target.style.background = '#FFF';
        });

        if (msaAcceptanceRequired) {
            if (authorizationService.hasRole([Role.Admin])) {
                msaService.showAcceptanceModal();
            } else if (!authorizationService.hasAnyRole([Role.SupportBasic, Role.SuperAdmin])) {
                loginServiceFunctions.logout({
                    logoutReason:
                        'Account Locked. An admin of your ActivTrak account needs to finish the upgrade process.'
                });
            }
        }
    });

    $rootScope.isInDom = function (element, inBody) {
        var e = element;
        var last;

        while (e) {
            last = e;
            if (inBody && last === document.body) {
                break;
            }
            e = e.parentNode;
        }

        return inBody ? last === document.body : last === document;
    };

    notificationService.init();

    $scope.setAppFullHeightHere = function () {
        angular.element('.app-wrapper').addClass('full-height-app');
    };

    var accountSettingsStoreUnsubscribe = accountSettingsStore.subscribe(() => {
        updateCurrentPlanText();
    });

    $scope.$on('$destroy', function () {
        accountSettingsStoreUnsubscribe();
    });

    //Tag to help identify pre/post dc sales
    $scope.dataConnectTag = isDataConsoleEnabled ? 'dc-post-sales' : 'dc-pre-sales';

    // Object.assign polyfill
    if (!Object.assign) {
        Object.defineProperty(Object, 'assign', {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (target) {
                if (target === undefined || target === null) {
                    throw new TypeError('Cannot convert first argument to object');
                }

                var to = Object(target);
                for (var i = 1; i < arguments.length; i++) {
                    var nextSource = arguments[i];
                    if (nextSource === undefined || nextSource === null) {
                        continue;
                    }

                    var keysArray = Object.keys(Object(nextSource));
                    for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                        var nextKey = keysArray[nextIndex];
                        var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                        if (desc !== undefined && desc.enumerable) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
                return to;
            }
        });
    }

    // Number.isFinite polyfill
    if (!Number.isFinite) {
        Object.defineProperty(Number, 'isFinite', {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (target) {
                return typeof target === 'number' && isFinite(target);
            }
        });
    }

    var tpdcInstance = teamPulseDataControl();
    inMemoryStore.set('teamPulseDataControl', tpdcInstance);

    // Global transition hanlder
    // TODO: consider consolidating
    $transitions.onStart(
        {
            from: 'app.**'
        },
        () => {
            angular.element('div.k-animation-container .k-tooltip.k-popup').parent().hide();

            templateServiceFunctions.resetFilterElements();
            angular.element('.app-wrapper').removeClass('full-height-app');
        }
    );
}
