import { IScreenshotRedactionSettingsStore } from './screenshotRedactionSettingsStore.models';

export const SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT: IScreenshotRedactionSettingsStore =
  {
    isNearingLimit: false,
    isOverLimit: false,
    redactionQuotaLimit: 0,
    redactionQuotaRemaining: 0,
    redactionQuotaUsage: 0,
    redactionRenewDate: null,
    redactionQuotaUsageLabel: '',
    redactionQuotaLimitLabel: '',
    redactionRenewDateLabel: '',
    redactionQuotaRemainingLabel: ''
  };
