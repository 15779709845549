import React from 'react';
import { InfoButton } from '../../../common/components/Button';
import Typography from '@mui/material/Typography';

const TotalProdAndTimeOffTooltipText = () => (
  <Typography>
    Total time includes All Productive Time throughout the day
  </Typography>
);

export const TotalProdAndTimeOffHeader = () => {
  const infoButton = (
    <InfoButton tooltipText={<TotalProdAndTimeOffTooltipText />} />
  );

  //TODO: this will be dynamic based on the future PTO feature flag
  return (
    <>
      <Typography sx={{ display: 'flex' }}>
        <Typography component="span" sx={{ textAlign: 'center' }}>
          Total
        </Typography>
        {infoButton}
      </Typography>
    </>
  );
};
