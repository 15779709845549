import { inMemoryStore } from 'activtrak-ui-utilities';
import { useUIRouterHistory } from './useUIRouterHistory';
import authorizationService from '../helpers/authorization';

/**
 * @deprecated
 * @returns
 */
export const useSideBarNavigation = () => {

  const { push } = useUIRouterHistory();

  const navigate = async (item: any) => {
    if (item.action.type === 'event') {
      if (item.action.options && item.action.options.eventName === 'logout') {
        // TODO: logout needs to be moved out of inMemoryStore
        const logout = inMemoryStore.get('logout');
        await logout();
        return;
      }
    } else if (item.action.type === 'external') {
      window.open(item.action.options.ref, '_blank');
      return;
    }

    await push(item.action.options.ref, {
      modelId: item.action.options.modelId,
      pageId: item.action.options.pageId,
      subPageId: item.action.options.initialSubPageId,
      tempFilters: '',
      fromNavigation: true,
      activeTab: item.action.options.activeTab
    });
  }

  const canNavigate = (route: string): boolean => {
    const result = authorizationService.hasRouteByName(route);
    return result;
  }

  return { navigate, canNavigate }
}