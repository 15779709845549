import React, { useEffect } from 'react';
import TimelineFilter from '../../../common/components/ReportFilters/components/TimelineFilter';
import { TimelineContainer } from '../../styles/templates.styles';
import { ErrorMsgDisplay, ModalLoadingView } from '../templates';
import { errorMsg, overFilteredMsg } from '../../constants';
import { useUIRouterHistory } from '../../../common/hooks/useUIRouterHistory';
import { ITodaysUserActivityTeamUser } from '../../models';
import { processProductivityTimelineData } from '../../../common/helpers';
import { getMomentDateTimeFromHour } from '../../../common/utils/datetime/dateHourConversion';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { getTimeFormat } from '../../../common/utils/datetime/datetimeFormatStrings';

type TodaysUserActivityModalProps = {
  open: boolean;
  timelineData: ITodaysUserActivityTeamUser[];
  isLoading: boolean;
  chartId?: string;
  date: string;
  onInit: () => void;
  onSubmitFilters: (filters) => void;
  isChartError: boolean;
  timezone: string;
  loaderHeight?: string;
};

export const TodaysUserActivityModal = (
  props: TodaysUserActivityModalProps
) => {
  const {
    open,
    timelineData,
    isLoading,
    chartId,
    date,
    onInit,
    onSubmitFilters,
    isChartError,
    timezone,
    loaderHeight
  } = props;

  const history = useUIRouterHistory();
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  useEffect(() => {
    if (open && onInit) onInit();
  }, [onInit, open]);

  useEffect(() => {
    const chartData = processProductivityTimelineData(timelineData, timezone);

    const container = document.getElementById(chartId);

    let chart;

    if (container && !isLoading) {
      const chartOptions = {
        allowHtml: true,
        timeline: {
          groupByRowLabel: true,
          allowHtml: true,
          showBarLabels: false
        },
        height: 300,
        alternatingRowStyle: false,
        colors: chartData?.colors,
        hAxis: {
          format: ' ' + getTimeFormat() + ' ',
          minValue: getMomentDateTimeFromHour(reportFilters.timelineFromHour),
          maxValue: getMomentDateTimeFromHour(reportFilters.timelineToHour)
        }
      };

      chart = new window.google.visualization.Timeline(container);
      const dataTable = new window.google.visualization.DataTable();

      const columns = chartData.dataTable[0] as [];

      columns.map((col) => {
        dataTable.addColumn(col);
      });

      const rows = [];
      for (let i = 1; i < chartData.dataTable?.length; i++) {
        const row = chartData.dataTable[i] as [];
        rows.push(row);
      }

      dataTable.addRows(rows);

      chart.draw(dataTable, chartOptions);
    }

    return () => {
      if (chart) {
        chart.clearChart();
      }
    };
  }, [chartId, history, isLoading, reportFilters, timelineData, timezone]);

  return (
    <>
      <TimelineFilter
        date={date}
        filterValues={reportFilters}
        setFilterValues={onSubmitFilters}
      />
      {!isLoading && !isChartError && timelineData?.length === 0 && (
        <ErrorMsgDisplay msg={overFilteredMsg} notWidget={true} />
      )}
      {!isLoading && !isChartError && timelineData?.length > 0 && (
        <TimelineContainer>
          <div id={chartId} className="google-timeline" />
        </TimelineContainer>
      )}
      <ModalLoadingView
        isLoading={isLoading}
        isModalError={isChartError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} notWidget={true} />}
        loaderHeight={loaderHeight}
      />
    </>
  );
};
