import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';

// Register report component
import './screenshotsLatest.component.js';
import { fetchScreenshotRedactionSettings } from '../../../../_reactivtrak/src/common/stores/screenshotSettingsStore/screenshotRedactionSettingsStore';

angular.module('app').controller('ScreenshotsLatestCtrl', ScreenshotsLatestCtrl);

ScreenshotsLatestCtrl.$inject = [
    '$scope',
    '$timeout',
    'authorizationService',
    'messagesService',
    'screenshotsService',
    'pageSizeService',
    'browserServiceFunctions',
    'atHelperFunctions'
];

function ScreenshotsLatestCtrl(
    $scope,
    $timeout,
    authorizationService,
    msg,
    screenshotsService,
    pageSizeService,
    browserServiceFunctions,
    atHelperFunctions
) {
    const accountSettings = getAccountSettings();
    $scope.safeSearchFeatureFlag = accountSettings.safeSearchFeatureFlag;
    $scope.isLatestScreenshotsPage = true;
    $scope.showProductivity = true;
    $scope.pageMinImages = browserServiceFunctions.isMobileAgent() ? 16 : 32;
    const rowSize = screenshotsService.getScreenshotsRowSize();
    const storedOrDefaultPageSize = pageSizeService.loadPageSize('latestScreenshots-master', $scope.pageMinImages);
    const pageSize = screenshotsService.getClosestMultipleOfRowSize(storedOrDefaultPageSize, rowSize);
    const pageSizes = screenshotsService.getPageSizes(rowSize);
    $scope.reportFilters = {};

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.screenshots.latest');
    };

    fetchScreenshotRedactionSettings();

    const getProductivityLabel = function (status) {
        switch (status) {
            case 1:
                return 'Productive';
            case -1:
                return 'Unproductive';
            default:
                return 'Undefined';
        }
    };

    $scope.source = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (Object.keys($scope.reportFilters).length === 0) {
                    options.success({ data: [], total: 0, pagerTotal: 0 });
                    return;
                }
                $scope.loading = true;

                screenshotsService
                    .getLatestScreenshotsPerUser(generateParameters($scope.reportFilters, {}), {
                        params: options.data
                    })
                    .success(function (result) {
                        $scope.loading = false;
                        result?.data?.forEach((r) => {
                            r.time = r.time.includes('T')
                                ? atHelperFunctions.formatDate(atHelperFunctions.convertTimezone(r.time), {
                                      span: false
                                  })
                                : r.time;
                        });
                        options.success(result);
                    })
                    .error(function (result) {
                        $scope.loading = false;
                        options.error(result);
                    });
            }
        },
        schema: {
            data: 'data',
            total: 'total'
        },
        sort: {
            field: 'user',
            dir: 'asc'
        },
        requestEnd: function (e) {
            if (!e.response) {
                console.error(msg.get('screenshotsResponseError'));
                $scope.$emit('showNotification', {
                    message: msg.get('screenshotsRetrievingError'),
                    color: 'danger'
                });
                return;
            }

            const images = [];
            function addTokenToImageUrl(baseUrl) {
                const trimmedUrl = baseUrl.split('?')[0];
                return trimmedUrl + '?token=' + userServiceFunctions.getEncodedUserToken();
            }

            const result = e.response.data;
            for (let index = 0; index < result.length; index++) {
                const image = result[index];
                image.index = index;
                image.productivityLabel = getProductivityLabel(image.prod);
                const imageThumbnail = image.image;
                if (imageThumbnail) {
                    image.image = imageThumbnail.includes('data:image/png')
                        ? imageThumbnail
                        : addTokenToImageUrl(imageThumbnail);
                }
                const imageFullSize = image.fullsize;
                if (imageFullSize) {
                    image.fullsize = addTokenToImageUrl(imageFullSize);
                }
                if (imageFullSize) {
                    images.push(image);
                }
            }

            $scope.images = images;
            $scope.screenshotViewerConfig = {
                dataSource: $scope.source,
                images: images
            };
        },
        pageSize: pageSize,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true
    });

    $scope.pagerOptions = {
        dataSource: $scope.source,
        pageSizes: pageSizes,
        buttonCount: 5,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'screenshots'),
            display: msg.get('itemsDisplay', 'screenshots'),
            empty: msg.get('noItemsToDisplay', 'screenshots')
        }
    };

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters || $scope.reportFilters;
        pageSizeService.dataSourceReload($scope.source);
    };

    $timeout(function () {
        $scope.bindReport();

        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('latestScreenshots-master');
        }
    });
}
