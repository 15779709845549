import React from 'react';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material';
import { BoxMarginStyled } from '../../styles/TimeOnTask.view.styles';

type DialogActionsBoxProps = {
  close: () => void;
  onClick: () => void;
  disabled: boolean;
  abortText?: string;
  confirmText: string;
  buttonColor?: ButtonProps['color'];
};

const DialogActionsBox = ({
  close,
  onClick,
  disabled,
  abortText,
  confirmText,
  buttonColor
}: DialogActionsBoxProps) => (
  <BoxMarginStyled>
    <Button onClick={close} autoFocus>
      {abortText || 'Cancel'}
    </Button>
    <Button
      id=""
      onClick={onClick}
      disabled={disabled}
      color={buttonColor || 'primary'}
      variant="contained"
    >
      {confirmText}
    </Button>
  </BoxMarginStyled>
);

export default DialogActionsBox;
