import { useCallback, useState } from 'react';
import moment from 'moment';
import { useAuthorization } from '../../common/services/Authorization';
import { useNotifications } from '../../common/services/Notifications';
import { Role } from '../../common/enums';
import { SupportPortalService, useSupportPortalState } from './';
import { isValidTokenDto } from '../utils/checkIfValidData.utils';
import { consoleMessage, displayMessage } from '../constants';
import { RedirectStatus } from '../enums/RedirectStatus';
import { INewlyAddedAccount } from '../models/IAccountData';
import { getUserToken, setUserToken } from '../../common/helpers/authentication/useUserTokenStore';
import { setSupportBackupToken } from '../../common/hooks/useImpersonate';
import { LoadApplication } from '../../common/services/Application/AppLoad';
import { ITokenDto } from '../models/IApiResponseDto';
import { rewire } from '../../../../_entry/rewire';

type RedirectingStatus =
  | RedirectStatus.Done
  | RedirectStatus.Error
  | RedirectStatus.Loading;

const swapTokens = (token: string) => {
  const backup = getUserToken();
  setSupportBackupToken(backup);
  setUserToken(token);
};

const getRedirectToPath = (
  path: string,
  token: ITokenDto,
  isSuperAdmin: boolean
) => {
  if (path) {
    return `#/app${path}`;
  } else if (token.ccAccess) {
    return '/#/cc/dashboard';
  } else if (isSuperAdmin) {
    return '/#/app/home';
  } else {
    return '/#/app/settings/configuration';
  }
};

// TODO: This hook needs a more descriptive name
// useRedirect is too generic, this hook supports a very specific use case
export const useRedirect = () => {
  const service = SupportPortalService();
  const { fetchAccountToken } = useSupportPortalState({
    service
  });

  const [status, setStatus] = useState<RedirectingStatus>(RedirectStatus.Done);

  const authorizationService = useAuthorization();
  const notificationService = useNotifications();
  

  const impersonateAndRedirect = useCallback(
    async (account: INewlyAddedAccount, path?: string) => {
      const { payload, tokenData } = account;

      setStatus(RedirectStatus.Loading);

      try {
        if (
          tokenData.expiration &&
          moment(tokenData.expiration).isAfter(moment())
        ) {

          const newUserToken = await fetchAccountToken(payload);

          if (!isValidTokenDto(newUserToken)) {
            setStatus(RedirectStatus.Error);
            notificationService.error(displayMessage.apiError.redirect);
            console.error(consoleMessage.tokenValidationError.redirect);
            return;
          }

          const isSuperAdmin: boolean = authorizationService.hasRole(Role.SuperAdmin);

          if (isSuperAdmin) {
            // TODO: For CC and CCE SuperAdmin only works with the impersonated token
            // Team is working on a fix.
            if (newUserToken.ccAccess && !newUserToken.impersonateToken) {
              setStatus(RedirectStatus.Error);
              notificationService.error('For CC and CCE please use impersonate user.');
              console.error('For CC and CCE please use impersonate user.');
              return;
            }
            
            swapTokens(newUserToken.impersonateToken ?? newUserToken.token);
          } else {
            swapTokens(newUserToken.token);
          }
          
          await LoadApplication();          
          const redirectToPath = getRedirectToPath(path, tokenData, isSuperAdmin);

          history.pushState(null, '', redirectToPath);
          rewire();
        }
      } catch (error) {
        setStatus(RedirectStatus.Error);

        notificationService.error(displayMessage.apiError.redirect);
        console.error(error?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { impersonateAndRedirect, status, setStatus };
};
