import { createStore, useStore } from 'zustand';

type SideBarStatus = 'open' | 'closed' | 'hidden';

type SideBarStatusStore = {
  status: SideBarStatus;
  setStatus: (status: SideBarStatus) => void;
};


const sideBarStatusStore = createStore<SideBarStatusStore>()((set) => ({
  status: 'open',
  setStatus: (status) => set({ status })
}));


export const useSideBarStatusStore = () =>
  useStore(sideBarStatusStore, (s) => s.status);

export const setSideBarStatus = (status: SideBarStatus) =>
  sideBarStatusStore.setState({ status });
