import { createStore } from 'zustand';

export type IRefreshStore = { refreshTimestamp: number; isRefreshing: boolean };

export const refreshStore = createStore<IRefreshStore>(() => ({
  refreshTimestamp: 0,
  isRefreshing: false
}));

export const setRefreshTimestamp = () =>
  refreshStore.setState({ refreshTimestamp: Date.now() });

export const setIsRefreshing = () => {
  setRefreshTimestamp();
  refreshStore.setState({ isRefreshing: true });
  setTimeout(() => {
    refreshStore.setState({ isRefreshing: false });
  }, 5000);
};
