import angular from 'angular';
import { react2angular } from '../common/third-party/react2angular';
import { IntegrationsComponentHybrid } from '../integrations/views/Integrations.view';

angular
  .module('app')
  .component(
    'integrationsHybrid',
    react2angular(IntegrationsComponentHybrid, [])
  );
