import React, { useState } from 'react';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { GlobalUserFilters } from './GlobalUserFilters';
import { IUserSearchState } from '../models/IUserSearchState';
import { useUserSearchColumns } from '../utils/useUserSearchColumns';
import { useCustomRowsSkeletonLoader } from '../../common/components/CustomGrid/CustomGrid.utils';
import { DialogVerifyAgentMove } from './DialogVerifyAgentMove';
import { IUserIdentity } from '../models/IUserIdentity';
import { GridPaging } from './GridPaging';
import { SEARCH_TYPE_ALL } from '../../common/constants/identitySearchTypes';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type ComponentProps = {
  userSearchState: IUserSearchState;
  onClose: (selectedUser: IUserIdentity) => Promise<void>;
  openUserSearchModal: boolean;
  agentDescription: string;
  readOnlyEntityId: number;
};

export const DialogUserSearch = (props: ComponentProps) => {
  const {
    userSearchState,
    onClose,
    openUserSearchModal,
    agentDescription,
    readOnlyEntityId
  } = props;
  const {
    searchTerm,
    onSetSearchTerm,
    identities,
    currentCursor,
    cursorStack,
    onChangePage,
    onChangePageSize,
    pageSize,
    nextPageDisabled
  } = userSearchState;

  const userSearchColumns = useUserSearchColumns();

  const onCancelChanges = async () => {
    await onClose(null);
  };

  const handleInputChange = (e: any) => {
    onSetSearchTerm(e.target.value, SEARCH_TYPE_ALL);
  };

  const handleClickRowGrid = (dataItem) => {
    deselectAllUsers();
    dataItem.selected = true;
    setLastSelectedUser(dataItem);
  };

  const deselectAllUsers = () => {
    identities.forEach((identity) => {
      identity.selected = false;
    });
  };

  const onSaveChanges = () => {
    if (lastSelectedUser) {
      setIsVerifyDialogOpen(true);
    } else {
      onCancelChanges();
    }
  };

  const closeVerifyModal = () => {
    setIsVerifyDialogOpen(false);
  };

  const agreeVerifyModal = () => {
    setIsVerifyDialogOpen(false);
    onClose(lastSelectedUser);
  };

  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState<boolean>(false);
  const [lastSelectedUser, setLastSelectedUser] =
    useState<IUserIdentity | null>(null);
  const userSkeletonLoader = useCustomRowsSkeletonLoader(24, 6, false);

  return (
    <>
      <Dialog
        open={openUserSearchModal}
        onClose={onCancelChanges}
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogTitle>Reassign Agent</DialogTitle>
        <DialogContent id="user-search-dialog-content">
          <Grid container spacing={1} sx={{ paddingBottom: 2 }}>
            <Grid item xs={9}>
              <Box sx={{ paddingBottom: '24px' }}>
                <TextField
                  name="searchTerm"
                  placeholder="Search by Display name, UPN, Employee id, Email, Username, or Device login"
                  value={searchTerm}
                  onChange={(e) => handleInputChange(e)}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  autoFocus
                  onClick={(e) => e.stopPropagation()}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <GlobalUserFilters
                gridFilter={userSearchState.gridFilter}
                onSetGridFilter={userSearchState.onSetGridFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomGrid
                height={'52vh'}
                isVirtualized={true}
                isLoading={userSearchState.isLoadingIdentities}
                data={identities}
                columns={userSearchColumns}
                uniqueRowProp="id"
                initialSortField={userSearchState.gridOrderBy}
                initialSortDirection={userSearchState.gridOrderDirection}
                onSortOrder={userSearchState.onSortOrder}
                onClickRow={handleClickRowGrid}
                skeletonLoaderTemplate={userSkeletonLoader}
                rowDisableCondition={(row) => row.id === readOnlyEntityId}
              />
            </Grid>
            <Grid item xs={12}>
              <GridPaging
                currentCursor={currentCursor}
                cursorStack={cursorStack}
                nextPageDisabled={nextPageDisabled}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                pageSize={pageSize}
                idPrefix={'users'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelChanges}>Cancel</Button>
          <Button onClick={onSaveChanges} variant="contained">
            Reassign Agent
          </Button>
        </DialogActions>
      </Dialog>
      <DialogVerifyAgentMove
        isOpen={isVerifyDialogOpen}
        onCancel={closeVerifyModal}
        onAgree={agreeVerifyModal}
        agentDescription={agentDescription ?? ''}
        userDescription={'user ' + lastSelectedUser?.singleDescription}
      />
    </>
  );
};
