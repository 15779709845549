import { IScreenshotSafeSearchSettingsStore } from './screenshotSafeSearchSettingsStore.models';
import { ThresholdLikelihood } from './screenshotSafeSearchSettingsStore.enums';

export const SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT: IScreenshotSafeSearchSettingsStore =
  {
    enabled: false,
    quotaLimit: 0,
    quotaUsage: 0,
    quotaRemaining: 0,
    renewDate: null,
    quotaUsageLabel: '',
    quotaLimitLabel: '',
    quotaRemainingLabel: '',
    renewDateLabel: null,
    adultThreshold: ThresholdLikelihood.Unknown,
    medicalThreshold: ThresholdLikelihood.Unknown,
    racyThreshold: ThresholdLikelihood.Unknown,
    violenceThreshold: ThresholdLikelihood.Unknown
  };
