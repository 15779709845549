import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  Button
} from '@mui/material';
import { setLocalStorageItem } from '../../common/utils/localStorage';
import { localStorageItems } from '../../common/constants/localStorageItems';
import {
  classificationClasses,
  FormControlCheckboxSC
} from '../styles/Classifications.styles';
import { profileStore } from '../../common/services/Profile';

type AllActivitiesDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export default function AllActivitiesDialog(props: AllActivitiesDialogProps) {
  const { open, onClose, onSubmit = () => {} } = props;

  const [checked, setChecked] = useState(false);

  const close = () => {
    setChecked(false);
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    onSubmit();
    close();
  };

  const handleChecked = () => {
    const value = !checked;

    setChecked(value);
    const { username } = profileStore.getState().profile;
    setLocalStorageItem(localStorageItems.allActivitiesModal + username, value);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        close();
      }}
      aria-labelledby="all-activities-title"
      aria-describedby="all-activities-description"
    >
      <DialogTitle id="all-activities-title">Show all activities</DialogTitle>
      <DialogContent sx={classificationClasses.deleteCategoryCopy}>
        <p id="all-activities-description">
          By default, the Classification page displays activities from the last
          30 days with a duration over 5 minutes. Activities under five minutes
          of duration are not a large part of the employees workday and do not
          typically need to be classified. Click accept below to show and
          classify all activities no matter the duration.
        </p>
        <p>
          <strong>Note:</strong> Showing all activities will potentially slow
          the performance of this page.
        </p>
      </DialogContent>
      <DialogActions>
        <FormControlCheckboxSC
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChecked}
            />
          }
          label="Don't show this message again"
          sx={{ marginTop: '29px' }}
        />
        <Button onClick={close} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Show all activities
        </Button>
      </DialogActions>
    </Dialog>
  );
}
