import { getDateSettingsStore } from '../../../../_reactivtrak/src/common/stores/dateSettingsStore';
import { analyticEventServiceFunctions } from '../../../../_app/serviceFunctions/analyticEventServiceFunctions';

angular.module('app').controller('ScheduleCtrl', ScheduleCtrl);

ScheduleCtrl.$inject = [
    '$http',
    '$scope',
    '$rootScope',
    '$stateParams',
    'schedulingService',
    '$state',
    '$timeout',
    'messagesService',
    'envConfig',
    'atHelperFunctions',
    'authorizationService'
];

function ScheduleCtrl(
    $http,
    $scope,
    $rootScope,
    $stateParams,
    schedulingService,
    $state,
    $timeout,
    msg,
    envConfig,
    atHelperFunctions,
    authorizationService
) {
    var scheduleTypes = {
        always: 'AlwaysSchedule',
        weekly: 'WeeklySchedule'
    };

    $scope.monday = [];
    $scope.tuesday = [];
    $scope.wednesday = [];
    $scope.thursday = [];
    $scope.friday = [];
    $scope.saturday = [];
    $scope.sunday = [];

    $scope.name = ''; // this initialization is also necessary for the name to populate in the breadcrumb
    $scope.maxReportingTimeHours = 23;
    $scope.maxReportingEnabled = false;

    var id = $stateParams.id;
    var incomingSchedule = $stateParams.schedule;

    $scope.isSuperAdmin = authorizationService.hasRole([authorizationService.roles.superAdmin]);

    function buildDayArray(input) {
        var newValue = [];
        angular.forEach(input, function (day) {
            var start = moment([2000, 1, 1, day.start.hour, day.start.minute]);
            var end = moment([2000, 1, 1, day.end.hour, day.end.minute]);
            newValue.push([start.format('HH:mm'), end.format('HH:mm')]);
        });
        return newValue;
    }

    function populateDayPayload(day, payload) {
        angular.forEach(day, function (item) {
            payload.push({
                start: { string: item[0] },
                end: { string: item[1] }
            });
        });
    }

    function incrementDuplicateName(name) {
        if (atHelperFunctions.isEmpty(name)) {
            return;
        }

        name = name.trim();
        var closeIndex = name.lastIndexOf(')');
        if (closeIndex !== -1) {
            var openIndex = name.lastIndexOf('(');
            var count = name.substring(openIndex + 1, closeIndex);
            count = parseInt(count);
            if (isNaN(count)) {
                count = 0;
            }
            count++;
            name = name.substring(0, openIndex + 1);
            name += count + ')';
        } else {
            name += ' (1)';
        }
        return name;
    }

    $scope.timeZonesDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                $http.get(envConfig.apiUrl() + '/api/settings/timezones').success(function (result) {
                    options.success(result);
                });
            }
        }
    });

    if (id === 'duplicate' && incomingSchedule) {
        $timeout(function () {
            $scope.name = incrementDuplicateName(incomingSchedule.name);
            $scope.maxReportingEnabled = incomingSchedule.maxReportingTimeMinutes ? true : false;
            $scope.maxReportingTimeHours = incomingSchedule.maxReportingTimeMinutes
                ? incomingSchedule.maxReportingTimeMinutes / 60
                : 23;
            $scope.scheduleType = incomingSchedule.type;
            $scope.isDefault = incomingSchedule.isDefault;
            $scope.isAlwaysSchedule = incomingSchedule.type === scheduleTypes.always;

            if (!incomingSchedule.days) {
                return;
            }
            $scope.monday = buildDayArray(incomingSchedule.days.monday);
            $scope.tuesday = buildDayArray(incomingSchedule.days.tuesday);
            $scope.wednesday = buildDayArray(incomingSchedule.days.wednesday);
            $scope.thursday = buildDayArray(incomingSchedule.days.thursday);
            $scope.friday = buildDayArray(incomingSchedule.days.friday);
            $scope.saturday = buildDayArray(incomingSchedule.days.saturday);
            $scope.sunday = buildDayArray(incomingSchedule.days.sunday);
        });
    } else if (id !== 'new') {
        schedulingService.getSchedule(id).then(function (result) {
            $scope.name = result.data.name;
            $scope.scheduleType = result.data.type;
            $scope.isDefault = result.data.isDefault;
            $scope.isAlwaysSchedule = result.data.type === scheduleTypes.always;
            $scope.maxReportingEnabled = result.data.maxReportingTimeMinutes ? true : false;
            $scope.maxReportingTimeHours = result.data.maxReportingTimeMinutes
                ? result.data.maxReportingTimeMinutes / 60
                : 23;

            if (!result.data.days) {
                return;
            }
            $scope.monday = buildDayArray(result.data.days.monday);
            $scope.tuesday = buildDayArray(result.data.days.tuesday);
            $scope.wednesday = buildDayArray(result.data.days.wednesday);
            $scope.thursday = buildDayArray(result.data.days.thursday);
            $scope.friday = buildDayArray(result.data.days.friday);
            $scope.saturday = buildDayArray(result.data.days.saturday);
            $scope.sunday = buildDayArray(result.data.days.sunday);
        });
    } else {
        var { timezoneKey } = getDateSettingsStore();
        $scope.name = msg.get('newSchedule');
        $scope.isAlwaysSchedule = true;
        $scope.scheduleType = scheduleTypes.always;
        $scope.currentTimeZone = timezoneKey;
    }

    $scope.loadRegularWorkingHours = function () {
        $scope.clearAll();
        $timeout(function () {
            $scope.monday = [
                ['08:00', '12:00'],
                ['13:00', '17:00']
            ];
            $scope.tuesday = [
                ['08:00', '12:00'],
                ['13:00', '17:00']
            ];
            $scope.wednesday = [
                ['08:00', '12:00'],
                ['13:00', '17:00']
            ];
            $scope.thursday = [
                ['08:00', '12:00'],
                ['13:00', '17:00']
            ];
            $scope.friday = [
                ['08:00', '12:00'],
                ['13:00', '17:00']
            ];
        });
    };

    $scope.clearAll = function () {
        $scope.scheduleType = scheduleTypes.weekly;
        $scope.monday = [];
        $scope.tuesday = [];
        $scope.wednesday = [];
        $scope.thursday = [];
        $scope.friday = [];
        $scope.saturday = [];
        $scope.sunday = [];
    };

    $scope.save = function () {
        if ($scope.invalid()) {
            return;
        }

        var payload = {
            type: $scope.scheduleType,
            name: $scope.name,
            timeZone: $scope.currentTimeZone,
            maxReportingTimeMinutes: $scope.maxReportingEnabled ? $scope.maxReportingTimeHours * 60 : null,
            days: {
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: []
            }
        };

        populateDayPayload($scope.monday, payload.days.monday);
        populateDayPayload($scope.tuesday, payload.days.tuesday);
        populateDayPayload($scope.wednesday, payload.days.wednesday);
        populateDayPayload($scope.thursday, payload.days.thursday);
        populateDayPayload($scope.friday, payload.days.friday);
        populateDayPayload($scope.saturday, payload.days.saturday);
        populateDayPayload($scope.sunday, payload.days.sunday);

        var isNotNew = id !== 'new' && id !== 'duplicate';

        if (isNotNew) {
            payload.id = id;
        }

        $scope.saving = true;
        schedulingService
            .saveSchedule(payload)
            .then(function (result) {
                $scope.saving = false;
                $scope.$emit('showNotification', {
                    message: msg.get('scheduleSaved'),
                    color: 'success'
                });

                if (isNotNew) {
                    analyticEventServiceFunctions.newEvent('Updated Schedule', {});
                } else {
                    analyticEventServiceFunctions.newEvent('Created Schedule', {});
                }

                $state.go('app.settings.scheduling', {
                    selectedScheduleId: result.data
                });
            })
            .catch(function (response) {
                $scope.saving = false;
                $scope.$emit('showNotification', {
                    message: response.data.message,
                    color: 'danger'
                });
            });
    };

    $scope.cancel = function () {
        var selectedScheduleId = id === 'new' || id === 'duplicate' ? null : id;
        $state.go('app.settings.scheduling', {
            selectedScheduleId: selectedScheduleId
        });
    };

    $scope.nameValid = function () {
        return $scope.name === '';
    };

    $scope.maxReportingTimeHoursValid = function () {
        return !$scope.isSuperAdmin || !$scope.maxReportingEnabled || !isNaN($scope.maxReportingTimeHours);
    };

    $scope.invalid = function () {
        return $scope.nameValid() || !$scope.maxReportingTimeHoursValid();
    };

    $scope.scheduleTwentyFourChange = function () {
        if ($scope.isAlwaysSchedule) {
            $scope.scheduleType = scheduleTypes.always;
        } else {
            $scope.scheduleType = scheduleTypes.weekly;
        }
    };
}
