import lodash from 'lodash';

import {
    getUserClaims,
    getUserToken,
    setUserToken
} from '../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { fetchData, postData } from '../../_reactivtrak/src/common/helpers';
import { decodeToken } from '../../_reactivtrak/src/support-portal/utils';
import moment from 'moment';

let _userLastSeenList = [];

/** @deprecated */
const findClaims = (claimTypeFragment, claims) => {
    const results = lodash.find(claims, (claim) => {
        return claim.type.includes(claimTypeFragment);
    });
    return results;
};

/** @deprecated */
const filterClaims = (claimTypeFragment, claims) => {
    const results = lodash.filter(claims, (claim) => {
        return claim.type.includes(claimTypeFragment);
    });
    return results;
};

// TODO: Mapout and check all unit tests and dependcies before increasing flexiblity
const _getClaimValue = (claimTypeFragment) => {
    const claims = getUserClaims();
    if (claims === 'invalid') {
        return;
    }

    const claim = findClaims(claimTypeFragment, claims);
    return claim && claim.value;
};

const _getClaimsValue = (claimTypeFragment) => {
    const claims = getUserClaims();

    if (claims === 'invalid') {
        return [];
    }

    const results = filterClaims(claimTypeFragment, claims);
    return results;
};

let getUserLastSeenApiCall = (tag) => {
    return fetchData({
        path: '/api/userlastseen/' + tag
    });
};

let setUserLastSeenApiCall = (tag) => {
    return postData({
        path: '/api/userlastseen/' + tag
    });
};

const getEncodedUserToken = () => {
    const token = getUserToken();
    return token;
};

const clearUserToken = () => {
    setUserToken('invalid');
};

let _promises = [];

const getUserLastSeen = (tag, options) => {
    _promises[tag] =
        _promises[tag] && !_promises[tag].done && !(options && options.force)
            ? _promises[tag]
            : new Promise((resolve, reject) => {
                  let userLastSeenItem = lodash.find(_userLastSeenList, (i) => i.tag === tag);
                  if (userLastSeenItem && userLastSeenItem.lastSeen) {
                      resolve(userLastSeenItem.lastSeen);
                  } else {
                      updateUserLastSeen(tag)
                          .then((lastSeen) => {
                              _promises[tag].done = true;
                              resolve(lastSeen);
                          })
                          .catch((e) => {
                              _promises[tag].done = true;
                              reject(e);
                          });
                  }
              });

    return _promises[tag];
};

const updateUserLastSeenList = (tag, userLastSeen) => {
    if (userLastSeen) {
        let userLastSeenItem = lodash.find(_userLastSeenList, (i) => i.tag === tag) || {};
        let pushToList = !(userLastSeenItem && userLastSeenItem.tag);

        userLastSeenItem.tag = tag;
        userLastSeenItem.lastSeen = userLastSeen;

        if (pushToList) {
            _userLastSeenList.push(userLastSeenItem);
        }

        return userLastSeenItem.lastSeen;
    } else {
        return;
    }
};

const setUserLastSeen = (tag) => {
    return new Promise((resolve, reject) => {
        setUserLastSeenApiCall(tag)
            .then(() => {
                updateUserLastSeen(tag).then((results) => {
                    resolve(results);
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const updateUserLastSeen = (tag) => {
    return new Promise((resolve, reject) => {
        getUserLastSeenApiCall(tag)
            .then((results) => {
                resolve(updateUserLastSeenList(tag, results && results.data && results.data.userLastSeen));
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const getTokenExpiration = () => {
    const userToken = getUserToken();
    if (userToken === 'invalid') {
        return moment.unix(0);
    }

    const decoded = decodeToken(userToken);

    if (decoded?.exp) {
        return moment.unix(decoded.exp);
    }

    return moment.unix(0);
};

const getIsImposter = () => {
    const imposterValue = _getClaimValue('imposter');
    return imposterValue !== undefined;
};

const enableAccountWizard = () => {
    return postData({
        path: '/api/settings/accountwizard/enable'
    });
};

const getCCImposter = () => {
    const isSupport = localStorage.getItem('activTrak.support.backup');
    if (!isSupport) {
        return false;
    }
    try {
        const token = getUserToken();
        if (!token || typeof token !== 'string' || token === 'invalid') {
            return false;
        }
        const cleanToken = decodeToken(token);
        if (!cleanToken) {
            return false;
        }
        const claims = cleanToken?.Claims;
        if (!claims) {
            return false;
        }
        // TODO: Replace with seperate _getClaimsValue
        const claim = findClaims('imposter', claims);
        return claim !== undefined;
    } catch (error) {
        console.error(`ActivTrak Error:`, error);
        return false;
    }
};

const getIsMSPAccount = () => {
    return _getClaimValue('channeltype')?.includes('MSP') ?? false;
};

const getIsCCEnterprise = () => {
    const roleClaims = _getClaimsValue('identity/claims/role');

    const ccEnterpriseAccess = lodash.find(roleClaims, (claim) => {
        return claim?.value?.toLowerCase() === 'CCEnterpriseAccess'.toLocaleLowerCase();
    });
    return ccEnterpriseAccess !== undefined;
};

export const userServiceFunctions = {
    getTokenExpiration,
    getIsImposter,
    getCCImposter,
    clearUserToken,
    getEncodedUserToken,
    getUserLastSeen,
    setUserLastSeen,
    updateUserLastSeen,
    enableAccountWizard,
    getIsMSPAccount,
    getIsCCEnterprise
};
