import { IUsersFilter } from '../../components/ReportFilters/models/IReportFilters';
import {
  IEntity,
  IEntityFilterData,
  IEntityStore,
  IFilterDto,
  EntityMix,
  EntitySource,
  EntityType
} from './entityStore.models';
import { commonApis } from '../../constants/routesApis';
import { filterIconsMap } from '../../constants/filterIconsMap';
import { fetchData } from '../../helpers/apis/apiRequest';
import { GroupIconType } from '../../components/GroupType/GroupIconType';
import { GroupMix } from '../../enums/GroupMix';

export const sortEntities = (a: IEntity, b: IEntity) => {
  if (a.id === '-1') {
    return -1;
  }

  if (b.id === '-1') {
    return 1;
  }

  if (a.type === 'Group' && b.type !== 'Group') {
    return -1;
  }

  if (a.type !== 'Group' && b.type === 'Group') {
    return 1;
  }

  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};

// Map legacy users to entities
export const mapUsersToEntities = (
  users: Partial<IEntityFilterData>[]
): IEntity[] => {
  return users.map((item) => ({
    id: item.userId,
    name: item.userName,
    type: item.userType as EntityType
  }));
};

// Map legacy computers to entities
export const mapComputersToEntities = (
  computers: Partial<IEntityFilterData>[]
): IEntity[] => {
  return computers.map((item) => ({
    id: item.userId,
    name: item.userName,
    type: item.userType as EntityType
  }));
};

// Map legacy groups to entities
export const mapGroupsToEntities = (
  groups: Partial<IEntityFilterData>[]
): IEntity[] => {
  return groups.map((item) => ({
    id: item.userId,
    name: item.userName,
    type: EntityType.Group,
    source: item.userType as EntitySource,
    mix: Object.keys(EntityMix).includes(item.groupType)
      ? (item.groupType as EntityMix)
      : EntityMix.Both
  }));
};

export const mapUserFilterToEntity = (user: IUsersFilter): IEntity => {
  if (!user) return null;

  if (user.userType === 'Group' || user.groupType) {
    return mapGroupsToEntities([
      {
        userId: user.userId.toString(),
        userName: user.name,
        userType: user.userType,
        groupType: user.groupType
      }
    ])[0];
  }

  if (user.userType === 'Computer') {
    return mapComputersToEntities([
      {
        userId: user.userId.toString(),
        userName: user.name,
        userType: user.userType
      }
    ])[0];
  }

  return mapUsersToEntities([
    {
      userId: user.userId.toString(),
      userName: user.name,
      userType: user.userType
    }
  ])[0];
};

export const createGroupComboList = (
  entityList: IEntity[],
  groupList: IEntity[],
  comboType: string
) => {
  const filteredGroups = groupList.filter(
    (item) =>
      item.mix.toLowerCase() === comboType || item.mix.toLowerCase() === 'both'
  );

  return entityList.concat(filteredGroups).sort(sortEntities);
};

export const renderIcon = (entity: IEntity): GroupIconType | GroupMix => {
  if (!entity) {
    console.error(
      'ActivTrak Error: Unable to create icon map. Entity is missing.'
    );
    return null;
  }

  const { source, type, mix, name } = entity;

  if (source === 'AllUsers') {
    return filterIconsMap.AllUsers[mix];
  }

  // TODO: We need to identify why we still use these older types and convert them to the new Group type
  // Added warning message to help identify the issue
  if (
    type === EntityType.AllUsersGroup ||
    type === EntityType.AllComputersGroup
  ) {
    console.warn(
      'ActivTrak Warning: Entity type is invalid. Mapping icon to AllUsers',
      entity
    );
    return filterIconsMap.AllUsers[name];
  }

  if (
    (source === 'AdGroup' || source === 'Group') &&
    ['User', 'Both', 'Computer'].includes(mix)
  ) {
    return filterIconsMap.Group[mix];
  }

  if (source === 'AdGroup' || source === 'Group') {
    console.error(
      'ActivTrak Error: Entity structure is invalid for a group type. Missing mix. Mapping icon to mixed group',
      entity
    );
    return filterIconsMap.Group.Both;
  }

  const iconType = filterIconsMap[source || type];

  if (!iconType) {
    console.error(
      'ActivTrak Error: Entity type is invalid. Mapping icon to IndividualUser',
      entity
    );
    return filterIconsMap.User;
  }

  return iconType;
};

export const renderIconFromUserFilter = (user: IUsersFilter) => {
  const entity = mapUserFilterToEntity(user);
  return renderIcon(entity);
};

export const validateEntity = (
  entities: IEntityStore,
  entityFilter: IUsersFilter
) => {
  if (entityFilter.userId === -1) {
    return true;
  }

  const combinedEntities = entities.users.concat(
    entities.computers,
    entities.groups
  );

  return combinedEntities.some((entity) => entity.id === entityFilter.userId);
};

export const getAllEntitiesList = async (
  search: string
): Promise<{ users: IEntity[]; computers: IEntity[]; groups: IEntity[] }> => {
  const response = await fetchData<IFilterDto>({
    path: commonApis.entities.allEntityFilters,
    params: { search }
  });
  const users = mapUsersToEntities(
    response.data.filter(
      (item) =>
        item.userType.toLowerCase() === 'user' ||
        item.userType.toLowerCase() === 'alias'
    )
  );
  const computers = mapComputersToEntities(
    response.data.filter((item) => item.userType.toLowerCase() === 'computer')
  );
  const groups = mapGroupsToEntities(
    response.data.filter(
      (item) =>
        item.userType.toLowerCase() !== 'user' &&
        item.userType.toLowerCase() !== 'alias' &&
        item.userType.toLowerCase() !== 'computer'
    )
  );

  return { users, computers, groups };
};

// Temporary filter validation until we can get an API endpoint that returns all entities
export const validateUserFilter = async (users: IUsersFilter[]) => {
  const userChecks = users.map(async (user) => {
    const entities = (await getAllEntitiesList(user.name)) as IEntityStore;
    return validateEntity(entities, user);
  });
  const results = await Promise.all(userChecks);

  return results.every((isValid) => isValid);
};
