import { createStore } from 'zustand/vanilla';
import {
  AggregationPeriod,
  IAggregationPeriodState
} from '../models/Productivity.models';

export const aggregationPeriodStore = createStore<IAggregationPeriodState>(
  () => ({
    period: AggregationPeriod.Day,
    availablePeriods: []
  })
);

export const getAggregationPeriod = () => aggregationPeriodStore.getState();

export const setAggregationPeriod = (period: AggregationPeriod) => {
  aggregationPeriodStore.setState({
    period
  });
};

export const setAvailablePeriods = (availablePeriods: AggregationPeriod[]) => {
  aggregationPeriodStore.setState({
    availablePeriods
  });
};

export const resetAggregationPeriodStore = () => {
  aggregationPeriodStore.setState({
    period: AggregationPeriod.Day
  });
};
