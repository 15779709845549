import { SortDirection } from '../../enums/SortDirection';

//used for agent Version sorting
export const periodDelimitedNumberComparer = (a, b) => {
  if (!a?.length || !b?.length) {
    return defaultComparer(a, b);
  }
  const aSplit = a.split('.'),
    bSplit = b.split('.');
  let comparison = 0;
  for (
    let i = 0;
    comparison === 0 && i < aSplit.length && i < bSplit.length;
    i++
  ) {
    comparison = parseInt(aSplit[i]) - parseInt(bSplit[i]);
  }
  return comparison;
};

export const defaultComparer = (itemA, itemB) => {
  return itemA > itemB ? 1 : itemA === itemB ? 0 : -1;
};

export function sorter<T>(
  data: T[],
  sortType: SortDirection,
  orderBy: string,
  compareFn: (a: T, b: T) => number = defaultComparer
) {
  if (!data) {
    return data;
  }

  const compareObjects = (a, b) => {
    // if it's null, use empty string
    // if it's a number, leave as is
    // else convert to string, trim and lowercase (includes booleans)
    const itemA = !a[orderBy]
      ? ''
      : typeof a[orderBy] === 'number'
      ? a[orderBy]
      : a[orderBy].toString().trim().toLowerCase();

    const itemB = !b[orderBy]
      ? ''
      : typeof b[orderBy] === 'number'
      ? b[orderBy]
      : b[orderBy].toString().trim().toLowerCase();

    const comparison = compareFn(itemA, itemB);
    return sortType == SortDirection.Ascending ? comparison : -1 * comparison;
  };

  return data.sort(compareObjects);
}

export const convertSort = <T>(obj): T => {
  const objToArray = Object.keys(obj).map((key) => {
    return { label: key, value: obj[key] };
  });

  const orderedArray = sorter(objToArray, SortDirection.Ascending, 'label');

  const sortedArrayToObj = orderedArray.reduce(
    (obj, item) => Object.assign(obj, { [item.label]: item.value }),
    {}
  );

  return sortedArrayToObj as T;
};
