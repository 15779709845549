import React from 'react';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { TimeAgo } from '../../common/components/TimeAgo';
import { TextOverflow } from '../../common/styles/app.component.styles';
import Tooltip from '@mui/material/Tooltip';
import { getTimeLapsedSinceNowString } from '../../common/utils/datetime/datetimeCalculations';
import { displayIdColumn } from './useUserColumns';

export const useUserSearchColumns = (): IGridColumn[] => {
  return [
    {
      field: 'displayName',
      fieldName: 'displayName',
      headerName: 'User',
      type: 'string',
      isSortable: true,
      width: '32%',
      template: (dataItem) => {
        const { displayName } = dataItem;
        const displayNameOrId =
          displayName?.value && displayName.value.length > 0
            ? displayName.value
            : dataItem?.displayId?.value;
        return (
          displayNameOrId && (
            <Tooltip title={displayNameOrId ?? ''} placement="bottom-start">
              <TextOverflow>{displayNameOrId}</TextOverflow>
            </Tooltip>
          )
        );
      }
    },
    {
      field: 'email',
      fieldName: 'email',
      headerName: 'Source & ID',
      type: 'string',
      isSortable: true,
      width: '36%',
      template: displayIdColumn
    },
    {
      field: 'tracked',
      headerName: 'Tracking',
      type: 'string',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return (
          <Tooltip
            title={dataItem?.tracked ? 'Tracked' : 'Untracked'}
            placement="bottom-start"
          >
            <TextOverflow>
              {dataItem?.tracked ? 'Tracked' : 'Untracked'}
            </TextOverflow>
          </Tooltip>
        );
      }
    },
    {
      field: 'userCount',
      headerName: 'Agents',
      type: 'string',
      isSortable: true,
      width: '7%',
      align: 'right'
    },
    {
      field: 'lastLog',
      headerName: 'Last Activity',
      type: 'date',
      isSortable: true,
      width: '10%',
      template: (dataItem) => {
        const timeAgo = getTimeLapsedSinceNowString(dataItem.maxLastLog);
        return (
          <TimeAgo
            lastLog={dataItem.maxLastLog}
            learnMoreLink="https://support.activtrak.com/"
            timeAgo={timeAgo}
          />
        );
      }
    }
  ];
};
