// TODO: Move feature flags to the BE post_login call

import { Role } from '../enums';
import { BundleFlag } from '../enums/BundleFlag';
import { ChannelType } from '../enums/ChannelType';
import { FeatureFlag } from '../enums/FeatureFlag';
import { PlanType } from '../enums/PlanType';
import authorization from '../helpers/authorization';
import { IAccountSettingsDto } from '../models';
import { IFeatureFlags } from '../models/IFeatureFlags';
import {
  getAccountPlanStore,
  isAccountPlanInTrial,
  isAccountPlanTypeAndInTrial
} from '../stores/accountPlanStore';

const hasReactivateFeatureFlag = (settings: IAccountSettingsDto) => {
  return settings.isSubscriptionCancelled && !isAccountPlanInTrial();
};

const hasCommandCenterAppAccessSettingsFeatureFlag = () => {
  return (
    authorization.hasAnyRole([
      Role.CommandCenter,
      Role.CommandCenterEnterprise
    ]) &&
    authorization.hasAnyRole([
      Role.SuperAdmin,
      Role.SupportAdvanced,
      Role.Admin
    ])
  );
};

const hasInsightsAvailableFeatureFlag = (settings: IAccountSettingsDto) => {
  return (
    settings?.insightsEnabled &&
    !settings?.insightsSetting?.inProgress &&
    Boolean(settings?.insightsSetting?.onBoardRequestTime)
  );
};

const hasInsightsSchedulingFeatureFlag = (settings: IAccountSettingsDto) => {
  return (
    settings.insightsEnabled &&
    hasInsightsAvailableFeatureFlag(settings) &&
    !settings?.insightsSetting?.schedulingDisabled
  );
};

const hasShowActivationFeatureFlag = (settings: IAccountSettingsDto) => {
  const { usedLicenses = 0 } = settings;
  return (
    !authorization.hasAnyRole([
      Role.CommandCenter,
      Role.CommandCenterEnterprise
    ]) && usedLicenses === 0
  );
};

const hasSupportTicketsFeatureFlag = (settings: IAccountSettingsDto) => {
  const { planAge } = getAccountPlanStore();
  return planAge <= 30 || settings.bundleFlags[BundleFlag.PaidFunctionality];
};

// Account based feature flag overrides
// These overrides should be used as a temporary solution during POC/Beta work
// or as a stop gap for a fully developed feature setting.
// Account numbers are given as strings to match the account number in the settings object
const accountFeatureFlagOverrides: Partial<
  Record<FeatureFlag, { false: string[]; true: string[] }>
> = {
  [FeatureFlag.ShowOfflineMeetingData]: {
    false: [],
    true: []
  },
  [FeatureFlag.ShowLocationData]: {
    false: ['686707'],
    true: []
  }
};

// All accounts have Offline Meeting Data feature flag except for accounts listed in the accountFeatureFlagOverrides
const hasOfflineMeetingDataFeatureFlag = (settings: IAccountSettingsDto) => {
  return !accountFeatureFlagOverrides[
    FeatureFlag.ShowOfflineMeetingData
  ].false.includes(settings.account);
};

// All accounts have Location Data feature flag except for accounts listed in the accountFeatureFlagOverrides
const hasLocationDataFeatureFlag = (settings: IAccountSettingsDto) => {
  return !accountFeatureFlagOverrides[
    FeatureFlag.ShowLocationData
  ].false.includes(settings.account);
};

const hasShowPromosFeatureFlag = (settings: IAccountSettingsDto) => {
  const channelType = settings.subscriptionType as ChannelType;
  const isMspAccount = [
    ChannelType.OfficialMSP,
    ChannelType.UnofficialMSP,
    ChannelType.OfficialMSPParent,
    ChannelType.OfficialMSPChild,
    ChannelType.OfficialMSPChildNFR,
    ChannelType.OfficialMSPChildHistoricNFR
  ].includes(channelType);

  // MSP Parent or Child accounts should not have the promos feature flag
  return !isMspAccount;
};

const hasRestrictedUpgradeFeatureFlag = (settings: IAccountSettingsDto) => {
  const channelType = settings.subscriptionType ?? '';

  if (channelType === '') {
    console.warn('[ATK] channelType missing');
  }

  return channelType.toLocaleLowerCase().includes('child');
};

const hasDevelopmentEnvironmentFeatureFlag = () => {
  const isEphEnvironment = window.location.href.includes('ephdev');

  return isEphEnvironment || import.meta.env.DEV;
};

export const createFeatureFlags = (
  settings: IAccountSettingsDto
): IFeatureFlags => {
  return {
    reactivateFeatureFlag: hasReactivateFeatureFlag(settings),
    videoAlarmsFeatureFlag: settings.videoAlarmsFeatureFlag,
    commandCenterAppAccessSettingsFeatureFlag:
      hasCommandCenterAppAccessSettingsFeatureFlag(),
    insightsAvailableFeatureFlag: hasInsightsAvailableFeatureFlag(settings),
    insightsEnabledFeatureFlag: settings.insightsEnabled,
    insightsSchedulingFeatureFlag: hasInsightsSchedulingFeatureFlag(settings),
    showTeamPulseScreenViewsFeatureFlag: settings.showTeamPulseScreenViews,
    showActivationFeatureFlag: hasShowActivationFeatureFlag(settings),
    showSupportTicketsFeatureFlag: hasSupportTicketsFeatureFlag(settings),
    showOfflineMeetingDataFeatureFlag:
      hasOfflineMeetingDataFeatureFlag(settings),
    showLocationDataFeatureFlag: hasLocationDataFeatureFlag(settings),
    showPromosFeatureFlag: hasShowPromosFeatureFlag(settings),
    restrictUpgradeFeatureFlag: hasRestrictedUpgradeFeatureFlag(settings),
    developmentEnvironmentFeatureFlag: hasDevelopmentEnvironmentFeatureFlag(),
    showWizardFeatureFlag: isAccountPlanTypeAndInTrial(PlanType.Professional)
  };
};
