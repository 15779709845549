import { createStore } from 'zustand';
import { IScreenshotSafeSearchSettingsStore } from './screenshotSafeSearchSettingsStore.models';
import { fetchSettings } from './screenshotSafeSearchSettingsStore.utils';
import { SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT } from './screenshotSafeSearchSettingsStore.constants';

/**
 * The store for the Safe Search Settings (aka Screenshot Flagging).
 */
export const screenshotSafeSearchSettingsStore =
  createStore<IScreenshotSafeSearchSettingsStore>(
    () => SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT
  );

/**
 * Fetches the Safe Search Settings (aka Screenshot Flagging) and updates the store.
 */
export const fetchScreenshotSafeSearchSettings =
  async (): Promise<IScreenshotSafeSearchSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = screenshotSafeSearchSettingsStore.getState();
    screenshotSafeSearchSettingsStore.setState({
      ...currentSettings,
      ...response
    });
    return screenshotSafeSearchSettingsStore.getState();
  };

/**
 * Returns the current Safe Search Settings Store (aka Screenshot Flagging) state.
 */
export const getScreenshotSafeSearchSettingsStore =
  (): IScreenshotSafeSearchSettingsStore =>
    screenshotSafeSearchSettingsStore.getState();

/**
 * Resets the Safe Search Settings Store (aka Screenshot Flagging) to its default state.
 */
export const resetScreenshotSafeSearchSettingsStore = (): void =>
  screenshotSafeSearchSettingsStore.setState(
    SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT,
    true
  );
