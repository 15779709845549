import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import insightsImpactEventsTemplate from '../../../../app/views/reports/insights/insightsImpactEvents.html?raw';
import {
    setUserHasSeenImpactEventsAutoModal,
    getImpactEvents,
    getGroups,
    shouldAutoShowImpactEventsModal,
    deleteImpactEvents,
    saveImpactEvent
} from '../../../../_app/insights/insightsServiceFunctions';
import insightsImpactEventsCreateModalTemplate from '../../../../app/views/reports/insights/insightsImpactEventsCreateModal.html?raw';
import impactEventsConfirmDeleteModalTemplate from '../../../../app/views/reports/insights/ImpactEventsConfirmDeleteModal.html?raw';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('insightsImpactEvents', function () {
    return {
        restrict: 'E',
        template: insightsImpactEventsTemplate,
        controller: InsightsImpactEventsCtrl
    };
});

InsightsImpactEventsCtrl.$inject = [
    '$scope',
    'customUibModal',
    'localStorageService',
    'envConfig',
    'notificationService',
    'authorizationService'
];

function InsightsImpactEventsCtrl(
    $scope,
    customUibModal,
    localStorageService,
    envConfig,
    notificationService,
    authorizationService
) {
    $scope.showProgress = true;

    function openEventsModal() {
        customUibModal.open({
            animation: false,
            template: insightsImpactEventsCreateModalTemplate,
            controller: 'insightsImpactEventsModal',
            windowClass: 'centered-modal impact-modals',
            resolve: {
                modalData: {
                    addImpactEvent: $scope.addImpactEvent
                }
            }
        });
        const { username } = profileStore.getState().profile;
        setUserHasSeenImpactEventsAutoModal(username, localStorageService);
    }

    function locationFormat(locArr) {
        if (locArr.length === 4) {
            return 'All';
        }

        const str = locArr[0];
        if (locArr.length > 1) {
            return `${str} + ${locArr.length - 1}`;
        }
        return str;
    }

    const DAYS = [
        { id: 2, value: 'M' },
        { id: 3, value: 'T' },
        { id: 4, value: 'W' },
        { id: 5, value: 'Th' },
        { id: 6, value: 'F' },
        { id: 7, value: 'Sa' },
        { id: 1, value: 'Su' }
    ];

    function daysFormat(intArr) {
        if (intArr.length === 7) {
            return 'All';
        }

        const dayStrArr = [];
        intArr.forEach(function (num) {
            DAYS.forEach(function (day) {
                if (num === day.id) {
                    dayStrArr.push(day.value);
                }
            });
        });
        return dayStrArr.join(', ');
    }

    function teamsFormat(groupIds) {
        let firstTeamName = '';
        if (groupIds && groupIds[0] && groupIds[0].groupName) {
            firstTeamName = groupIds[0].groupName;

            if (firstTeamName.length > 25) {
                firstTeamName = firstTeamName.slice(0, 21) + '...';
            }
            if (groupIds.length > 1) {
                return `${firstTeamName} + ${groupIds.length - 1}`;
            }
        }
        return firstTeamName;
    }

    function addAttributes(impactEvents) {
        impactEvents.forEach(function (i) {
            i.isSelected = false;
            i.locationDisplay = locationFormat(i.locationEvent);
            i.daysDisplay = daysFormat(i.dayOfWeek);
            i.teamsDisplay = teamsFormat(i.groupList);
        });
    }

    const impactEventsDataSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                const insightsAvailable = authorizationService.hasFeature(FeatureFlag.InsightsAvailable);
                if (!insightsAvailable) {
                    $scope.showProgress = false;
                    options.success();
                }

                const eventsPromise = getImpactEvents(envConfig.apiUrl(), notificationService);
                const groupsPromise = getGroups(envConfig.apiUrl());

                Promise.all([eventsPromise, groupsPromise]).then(function (data) {
                    addAttributes(data[0]);
                    options.success(data[0]);
                    $scope.groups = data[1];
                    $scope.showProgress = false;
                    $scope.hasGroups = $scope.groups && $scope.groups.length > 0;
                    $scope.disableDelete = true;
                    $scope.disableDeleteAllCheckbox = false;
                    $('#impact-check-all')[0].checked = false;
                    if (data[0].length === 2) {
                        $scope.disableDeleteAllCheckbox = true;
                        const { username } = profileStore.getState().profile;
                        if (
                            $scope.hasGroups &&
                            shouldAutoShowImpactEventsModal(authorizationService, username, localStorageService)
                        ) {
                            openEventsModal();
                        }
                    }
                });
            }
        }
    });

    function refreshCallback() {
        $scope.showProgress = true;
        impactEventsDataSource.read();
    }

    function selectedEventsCount() {
        return impactEventsDataSource.data().filter(function (item) {
            return !item.defaultEvent && item.selected;
        }).length;
    }

    function cohortNameExists(eventName, eventId) {
        let found;
        if (eventId) {
            //implies an edit - ignore itself
            found = impactEventsDataSource.data().find(function (d) {
                return d.eventName.trim().toLowerCase() === eventName.trim().toLowerCase() && d.eventId !== eventId;
            });
            return found;
        }

        found = impactEventsDataSource.data().find(function (d) {
            return d.eventName.trim().toLowerCase() === eventName.trim().toLowerCase();
        });
        return found;
    }

    const getGridColumns = function () {
        return [
            {
                headerTemplate: `
                    <div class="checkbox">
                        <input
                            type="checkbox"
                            id="impact-check-all"
                            ng-click="handleCheckAll($event)"
                            ng-disabled="disableDeleteAllCheckbox || !isAdminOrConfig"
                        />
                        <label for="impact-check-all"></label>
                    </div>
                `,
                template: `
                    <div class="checkbox">
                        <input
                            type="checkbox"
                            ng-model="dataItem.selected"
                            id="{{dataItem.eventId}}"
                            ng-click="handleItemCheck($event, dataItem)"
                            ng-disabled="dataItem.defaultEvent || !isAdminOrConfig"
                        />
                        <label for="{{dataItem.eventId}}"></label>
                    </div>
                `,
                width: '5%'
            },
            {
                field: 'eventName',
                title: 'Name',
                template: '<div>{{dataItem.eventName}}</div>',
                width: '25%'
            },
            {
                field: 'locationDisplay',
                title: 'Location',
                template: '<div>{{dataItem.locationDisplay}}</div>',
                width: '15%'
            },
            {
                field: 'startDate',
                title: 'Timetable',
                width: '17%',
                template: kendo.template(function (item) {
                    const str = moment(item.startDate).format('MM/DD/YYYY');
                    if (item.endDate) {
                        return str + ' - ' + moment(item.endDate).format('MM/DD/YYYY');
                    }
                    return str;
                })
            },
            {
                field: 'daysDisplay',
                title: 'Days',
                template: '<div>{{dataItem.daysDisplay}}</div>',
                width: '13%'
            },
            {
                field: 'teamsDisplay',
                title: 'Teams',
                template: '<div>{{dataItem.teamsDisplay}}</div>',
                width: '20%'
            },
            {
                command: [
                    {
                        template: `
                            <div ng-if="hasGroups">
                                <i
                                    class="material-icons actions"
                                    ng-click="editEvent(dataItem)"
                                    ng-if="!dataItem.defaultEvent && isAdminOrConfig"
                                >
                                    edit
                                </i>
                                <i
                                    class="material-icons actions"
                                    ng-click="viewEvent(dataItem)"
                                    ng-if="dataItem.defaultEvent || !isAdminOrConfig"
                                >
                                    visibility
                                </i>
                            </div>
                            <div ng-if="!hasGroups">
                                <i
                                    class="material-icons actions disabled"
                                    ng-if="!dataItem.defaultEvent && isAdminOrConfig"
                                >
                                    edit
                                </i>
                                <i
                                    class="material-icons actions disabled"
                                    ng-if="dataItem.defaultEvent || !isAdminOrConfig"
                                >
                                    visibility
                                </i>
                            </div>
                        `
                    }
                ],
                width: '5%'
            }
        ];
    };

    $scope.isAdminOrConfig = authorizationService.hasRole([
        authorizationService.roles.admin,
        authorizationService.roles.configurator
    ]);

    $scope.addDisabledTooltip = `
        <div style="text-align:left;width:300px;">
            Segments require groups to be created in the account with at least one user in it. 
            Create groups first, then come back the next day once they've synced to create segments.
        <div>`;

    $scope.impactEventsGridOptions = {
        dataSource: impactEventsDataSource,
        sortable: {
            mode: 'single',
            allowUnsort: false,
            initialDirection: 'asc'
        },
        height: 650,
        scrollable: true,
        columns: getGridColumns()
    };

    $scope.handleCheckAll = function (e) {
        if (e.target.checked) {
            impactEventsDataSource.data().forEach(function (ie) {
                if (!ie.defaultEvent) {
                    ie.selected = true;
                }
            });
            $scope.disableDelete = false;
        } else {
            impactEventsDataSource.data().forEach(function (ie) {
                if (!ie.defaultEvent) {
                    ie.selected = false;
                }
            });
            $scope.disableDelete = true;
        }
    };

    $scope.handleItemCheck = function (e, dataItem) {
        if (e.target.checked) {
            dataItem.selected = true;
            $scope.disableDelete = false;
            if (selectedEventsCount() === impactEventsDataSource.data().length - 2) {
                $('#impact-check-all')[0].checked = true;
            }
        } else {
            dataItem.selected = false;
            $('#impact-check-all')[0].checked = false;
            if (selectedEventsCount() === 0) {
                $scope.disableDelete = true;
            }
        }
    };

    $scope.showConfirmDeleteImpactEventsModal = function () {
        const selections = impactEventsDataSource
            .data()
            .filter(function (item) {
                return item.selected;
            })
            .map(function (evt) {
                return evt.eventId;
            });

        //Just in case
        if (selections.length === 0) {
            return;
        }

        customUibModal.open({
            animation: false,
            template: impactEventsConfirmDeleteModalTemplate,
            controller: 'impactEventsConfirmDeleteModal',
            windowClass: 'centered-modal impact-modals',
            resolve: {
                modalData: {
                    selections,
                    deleteImpactEvents: $scope.deleteImpactEvents
                }
            }
        });
    };

    $scope.deleteImpactEvents = function (impactEvents) {
        $scope.showProgress = true;

        deleteImpactEvents(envConfig.apiUrl(), impactEvents, notificationService)
            .then(function () {
                impactEventsDataSource.read();
            })
            .catch(function () {
                $scope.showProgress = false;
            });
    };

    $scope.addImpactEvent = function () {
        customUibModal.open({
            animation: false,
            template: insightsImpactEventsCreateModalTemplate,
            controller: 'insightsImpactEventsCreateModal',
            windowClass: 'centered-modal impact-modals',
            resolve: {
                modalData: {
                    edit: false,
                    viewOnly: false,
                    teams: $scope.groups,
                    days: DAYS,
                    saveImpactEvent,
                    notificationService: notificationService,
                    refreshCallback: refreshCallback,
                    cohortNameExists: cohortNameExists
                }
            }
        });
    };

    $scope.viewEvent = function (item) {
        customUibModal.open({
            animation: false,
            template: insightsImpactEventsCreateModalTemplate,
            controller: 'insightsImpactEventsCreateModal',
            windowClass: 'centered-modal impact-modals',
            resolve: {
                modalData: {
                    edit: false,
                    viewOnly: true,
                    impactEvent: item,
                    teams: $scope.groups,
                    days: DAYS,
                    saveImpactEvent,
                    notificationService
                }
            }
        });
    };

    $scope.editEvent = function (item) {
        customUibModal.open({
            animation: false,
            template: insightsImpactEventsCreateModalTemplate,
            controller: 'insightsImpactEventsCreateModal',
            windowClass: 'centered-modal impact-modals',
            resolve: {
                modalData: {
                    edit: true,
                    viewOnly: false,
                    impactEvent: item,
                    teams: $scope.groups,
                    days: DAYS,
                    saveImpactEvent,
                    notificationService,
                    refreshCallback,
                    cohortNameExists
                }
            }
        });
    };
}
