import { useCallback, useState } from 'react';
import {
  fetchProfileData,
  postProfileData,
  putProfileData
} from '../../helpers';
import { decodeProfileValues } from '../../utils/parse/parse';
import {
  IProfileState,
  IProfile,
  IProfileDto,
  ICredentialsSettingsDto
} from '../../models';
import { IDigestDto, PasswordInfo } from '../../../profile-settings/models';
import { StoreApi, createStore } from 'zustand/vanilla';
import { useNotifications } from '../Notifications';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { profileSettingsRoutes } from '../../../profile-settings/constants/profileSettingsRoutes';

interface IProfileStore {
  profile: IProfile;
  isLoading: boolean;

  setProfile: (profile: IProfile) => void;
  fetch: () => Promise<void>;
}

const PROFILE_DEFAULT: IProfile = {
  firstName: null,
  lastName: null,
  phone: null,
  title: null,
  industry: null,
  creationDate: null,
  ssoEnabled: false,
  username: null,
  settingsDigest: {
    digest: false
  },
  credentialsSettings: { password: { advancedSecurity: false } }
};

const combineAndDecode = (
  profile: IProfileDto,
  digest: IDigestDto,
  credentials: ICredentialsSettingsDto
): IProfile => {
  const { creationDate, ssoEnabled, username } = profile;

  const result: IProfile = {
    ...profile.profileInfo,
    creationDate,
    ssoEnabled,
    username,
    settingsDigest: digest,
    credentialsSettings: credentials
  };

  const decoded = decodeProfileValues(result);
  return decoded;
};

const createBoundedUseStore = ((store) => (selector, equals) =>
  useStoreWithEqualityFn(store, selector as never, equals)) as <
  S extends StoreApi<unknown>
>(
  store: S
) => {
  (): ExtractState<S>;
  <T>(
    selector: (state: ExtractState<S>) => T,
    equals?: (a: T, b: T) => boolean
  ): T;
};

type ExtractState<S> = S extends { getState: () => infer X } ? X : never;

export const profileStore = createStore<IProfileStore>()((set) => ({
  profile: PROFILE_DEFAULT,
  isLoading: true,
  setProfile: (profile) => set({ profile }),
  fetch: async () => {
    const profile: IProfileDto = await fetchProfileData<IProfileDto>({
      path: profileSettingsRoutes.getProfile
    });
    const digest: IDigestDto = await fetchProfileData<IDigestDto>({
      path: profileSettingsRoutes.settingsDigest
    });
    const credentials: ICredentialsSettingsDto =
      await fetchProfileData<ICredentialsSettingsDto>({
        path: profileSettingsRoutes.getCredentialsSettings
      });

    const decoded = combineAndDecode(profile, digest, credentials);
    set({ profile: decoded, isLoading: false });
  }
}));

export const useProfileStore = createBoundedUseStore(profileStore);

export const useProfileState = (): IProfileState => {
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { profile, isLoading, setProfile } = useProfileStore();
  const notifications = useNotifications();

  const onPasswordChange = useCallback(
    async (formData: PasswordInfo): Promise<void> => {
      setSavingInProgress(true);
      try {
        await putProfileData<PasswordInfo>({
          path: profileSettingsRoutes.updatePassword,
          args: formData
        });
        notifications.success('Password changed!');
      } catch (error) {
        notifications.error('Incorrect password');
        console.error('ActivTrak Error: Error saving new password.', error);
      }
      setSavingInProgress(false);
    },
    [notifications]
  );

  const onFormSubmit = useCallback(
    async (payload: IProfile) => {
      setSavingInProgress(true);
      try {
        await postProfileData<IProfileDto>({
          path: profileSettingsRoutes.setProfile,
          args: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            phoneNumber: payload.phone,
            industry: payload.industry,
            title: payload.title
          }
        });

        await postProfileData<IDigestDto>({
          path: profileSettingsRoutes.settingsDigest,
          args: {
            digest: payload.settingsDigest.digest
          }
        });

        setProfile({ ...profile, ...payload });
        notifications.success('Changes have been saved!');
      } catch (error) {
        notifications.error('There was an error while applying the changes');

        console.error(
          `ActivTrak Error: Profile Settings Saving: ${error}`,
          error
        );
      } finally {
        setSavingInProgress(false);
      }
    },
    [profile, setProfile, notifications]
  );

  return {
    profile,
    isLoading,
    savingInProgress,

    onFormSubmit,
    onPasswordChange
  };
};

export const getUserName = () => {
  const { username } = profileStore.getState().profile;
  return username;
}
