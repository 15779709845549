import { apiService as apiServiceDefault } from '../../helpers/apis/apiService';

import { getDefaultConfig } from '../../config/env.config';
import { IApiService } from '../../models/IApiService';
import { IGetRolesService } from './IGetRolesService';
import { IRoleAccess } from './IRoleAccess';

export class GetRolesService implements IGetRolesService {
  protected apiService: IApiService;

  constructor(apiService?: IApiService) {
    this.apiService = apiService ?? apiServiceDefault;
  }

  async getAll(): Promise<IRoleAccess[]> {
    return new Promise((resolve) => {
      this.apiService
        .get(`/api/settings/roleaccess`, getDefaultConfig())
        .then((response: any) => {
          return resolve(response.data);
        });
    });
  }
}
