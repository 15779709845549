import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography
} from '@mui/material';
import {
  DescriptionBox,
  StateBox,
  IntegrationCardContent,
  IntegrationCardHeader,
  TitleBox
} from '../styles/Integrations.view.styles';
import { AboutAzureADModal } from './AboutAzureADModal';
import { AboutGoogleCalendarModal } from './AboutGoogleCalendarModal';
import { AboutOutlookCalendarModal } from './AboutOutlookCalendarModal';
import { ConfigureOutlookCalendarModal } from './ConfigureOutlookCalendarModal';
import { ConfigureGoogleCalendarModal } from './ConfigureGoogleCalendarModal';
import { ConfigureAzureADModal } from './ConfigureAzureADModal';
import {
  CallToActionType,
  ConfigModalProps,
  DispositionType,
  IIntegration,
  IntegrationStateErrorCodes,
  IntegrationStateType
} from '../models';
import { IntegrationCodes } from '../constants/IntegrationCodes';
import { useBaseIntegrationConfigurationState } from '../hooks/useBaseIntegrationConfigState';
import { useNotifications } from '../../common/services/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import { IntegrationErrors } from '../constants/IntegrationMessages';
import { IntegrationDropdown } from './IntegrationDropdown';
import { IBaseIntegrationConfigurationState } from '../models/IBaseIntegrationConfigurationState';
import { useIntegrationContextProvider } from './IntegrationProvider';
import {
  heading3FontSize,
  mediumFontWeight,
  primaryColor,
  smallFontSize
} from '../../common/constants';
import {
  determineStateMessage,
  isHRISIntegration
} from '../utils/integration.utils';
import { heapIdsMap } from '../constants/configuration';
import { IntegrationDropdownTray } from './IntegrationDropdownTray';
import { HRISIntegrationType } from '../constants/IntegrationDetails';
import { FeatureState } from '../../common/enums/FeatureState';
import FeatureBadge from '../../common/components/Navigation/SideBar/FeatureBadge';
import { ConfigureMergeModal } from './ConfigureMergeModal';

const aboutModalMap = {
  [IntegrationCodes.AzureAD]: AboutAzureADModal,
  [IntegrationCodes.GoogleCalendar]: AboutGoogleCalendarModal,
  [IntegrationCodes.OutlookCalendar]: AboutOutlookCalendarModal,
  [HRISIntegrationType.Workday]: AboutOutlookCalendarModal
};

function AboutModalContent({
  code,
  description,
  exampleImage,
  exampleAltText,
  hrisIntegrationCardType
}) {
  const ModalComponent =
    (isHRISIntegration(code) && aboutModalMap[hrisIntegrationCardType]) ||
    aboutModalMap[code] ||
    (() => <Box>More information coming soon.</Box>);

  return (
    <ModalComponent
      description={description}
      exampleImage={exampleImage}
      exampleAltText={exampleAltText}
    />
  );
}

const configModalMap = {
  [IntegrationCodes.AzureAD]: ConfigureAzureADModal,
  [IntegrationCodes.GoogleCalendar]: ConfigureGoogleCalendarModal,
  [IntegrationCodes.OutlookCalendar]: ConfigureOutlookCalendarModal
};

const ConfigModal = ({ code, modalProps }) => {
  const ModalComponent =
    configModalMap[code] || (() => <div>More information coming soon.</div>);

  return <ModalComponent {...modalProps} />;
};

export const IntegrationCard = ({
  logo: Logo,
  integrationcode,
  modalTitle,
  modalDescription,
  modalExampleImage,
  modalExampleAltText,
  title,
  description,
  error,
  isPlanAccessible,
  disposition,
  integrationName,
  badge
}: IIntegration) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [creationDate, setCreationDate] = useState<string>();
  const configState: IBaseIntegrationConfigurationState =
    useBaseIntegrationConfigurationState(integrationcode);
  const [hrisIntegrationCardType, setHrisIntegrationCardType] =
    useState<HRISIntegrationType>();

  const [isHRISCard, setIsHRISCard] = useState<boolean>();
  const [mergeInitiateConfig, setMergeInitiateConfig] = useState<() => void>();
  const [mergeIsReady, setMergeIsReady] = useState(false);
  const {
    integrationConfigurationNotification,
    setIntegrationConfigurationNotification,
    integrationState,
    setIntegrationState,
    setInstanceId,
    instanceId,
    enableIntegration,
    hasConfigurations,
    setHasConfigurations,
    traySolutionInstanceId,
    setTraySolutionInstanceId,
    enableTrayIntegration,
    apiRequestError,
    initiateMergeDev,
    mergeLink,
    initiatedNotYetCreated,
    integrationCardEnabled,
    setIntegrationCardEnabled,
    setIsLoading,
    isLoading,
    setInitiatedNotYetCreated
  } = configState;

  const notificationService = useNotifications();
  const { integrationState: integrationStateContext } =
    useIntegrationContextProvider();

  const { integrationData, init } = integrationStateContext;

  useEffect(() => {
    if (isHRISCard) {
      // TODO: Revisit after MVP. For MVP we are only showing one HRIS integration (Workday).
      setHrisIntegrationCardType(HRISIntegrationType.Workday);
    }
  }, [isHRISCard, integrationData, integrationName]);

  useEffect(() => {
    if (
      integrationConfigurationNotification?.msg &&
      integrationConfigurationNotification?.type
    ) {
      notificationService[integrationConfigurationNotification.type](
        integrationConfigurationNotification.msg
      );
      setIntegrationConfigurationNotification(undefined);
      init();
    }
  }, [
    init,
    notificationService,
    integrationConfigurationNotification?.msg,
    integrationConfigurationNotification?.type,
    setIntegrationConfigurationNotification
  ]);

  useEffect(() => {
    setIsLoading(true);
    setTraySolutionInstanceId(
      integrationData.find((i) => i.integrationcode == integrationcode)
        .traySolutionInstance
    );

    setIntegrationState(
      integrationData.find((i) => i.integrationcode === integrationcode)
        .stateType ?? IntegrationStateType.None
    );

    if (traySolutionInstanceId) {
      setIntegrationCardEnabled(
        integrationData.find((i) => i.integrationcode === integrationcode)
          .trayEnabled ?? false
      );
    } else {
      setIntegrationCardEnabled(
        integrationData.find((i) => i.integrationcode === integrationcode)
          .isIntegrationEnabled ?? false
      );
    }

    setHasConfigurations(
      integrationData.find((i) => i.integrationcode === integrationcode)
        .hasConfigurations ?? false
    );

    if (
      integrationData.find((i) => i.integrationcode === integrationcode)
        .hasConfigurations
    ) {
      setInstanceId(
        integrationData.find((i) => i.integrationcode === integrationcode)
          .integrationConfigurations[0]?.instanceid
      );

      setCreationDate(
        integrationData.find((i) => i.integrationcode === integrationcode)
          .integrationConfigurations[0]?.creationdate
      );
    }
    setIsLoading(false);
  }, [integrationData, traySolutionInstanceId]);

  const { errorcode, updatedtime } =
    integrationData.find((i) => i.integrationcode === integrationcode)
      .integrationState ?? {};

  const handleAboutClose = () => setOpenDialog(false);
  const handleConfigClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    setOpenConfigDialog(false);
  };

  const handleAboutOpen = (e) => {
    setOpenDialog(true);
    e.stopPropagation();
  };

  const handleConfigOpen = useCallback(
    (e) => {
      if (isHRISCard && mergeInitiateConfig) {
        setInitiatedNotYetCreated(false);
        mergeInitiateConfig();
      } else {
        setOpenConfigDialog(true);
      }
      e.stopPropagation();
    },
    [isHRISCard, mergeInitiateConfig, setInitiatedNotYetCreated]
  );

  const handleResumeClick = (event) => {
    event.stopPropagation();

    if (traySolutionInstanceId) {
      enableTrayIntegration(traySolutionInstanceId);
    } else {
      enableIntegration(instanceId);
    }
  };

  const modalProps: ConfigModalProps = {
    setOpenConfigDialog,
    configState
  };

  const callToActionText = useMemo(() => {
    const callToAction: CallToActionType = integrationData.find(
      (i) => i.integrationcode === integrationcode
    )?.callToAction;
    return callToAction === CallToActionType.none
      ? CallToActionType.upgrade
      : callToAction;
  }, [integrationData, integrationcode]);

  const showEnableButton = useMemo(() => {
    return (
      !traySolutionInstanceId &&
      integrationCardEnabled &&
      (integrationState === IntegrationStateType.Waiting ||
        integrationState === IntegrationStateType.Running)
    );
  }, [integrationCardEnabled, integrationState]);

  const showIntegrateButton = useMemo(() => {
    return (
      !isLoading &&
      !hasConfigurations &&
      integrationState !== IntegrationStateType.Error &&
      integrationState !== IntegrationStateType.Idle &&
      integrationState !== IntegrationStateType.Paused &&
      integrationState !== IntegrationStateType.Waiting &&
      integrationState !== IntegrationStateType.NoFetchWarning &&
      integrationState !== IntegrationStateType.Running
    );
  }, [hasConfigurations, integrationState, isLoading]);

  const showDropdown = useMemo(() => {
    return (
      (integrationCardEnabled &&
        integrationState !== IntegrationStateType.None &&
        integrationState !== IntegrationStateType.Deleted) ||
      integrationState === IntegrationStateType.Idle
    );
  }, [integrationCardEnabled, integrationState]);

  const showResumeBtn = useMemo(() => {
    return (
      !isLoading &&
      !integrationCardEnabled &&
      integrationState === IntegrationStateType.Paused
    );
  }, [isLoading, integrationCardEnabled, integrationState]);

  const showErrorWithSupportLink = useMemo(() => {
    return (
      errorcode !== IntegrationStateErrorCodes.None &&
      IntegrationErrors[errorcode]?.showContactSupport
    );
  }, [error]);

  const showCtaIntegrateText = useMemo(() => {
    return (
      !isLoading &&
      !initiatedNotYetCreated &&
      !traySolutionInstanceId &&
      showIntegrateButton &&
      callToActionText
    );
  }, [
    isLoading,
    initiatedNotYetCreated,
    traySolutionInstanceId,
    showIntegrateButton,
    callToActionText
  ]);

  const CTANavigation = useMemo(() => {
    const isExternal = callToActionText === CallToActionType.learnMore;

    // TODO refine beta link for HRIS MVP
    const getBetaLink = isHRISCard && 'https://support.activtrak.com';

    const link =
      disposition === DispositionType.beta
        ? getBetaLink
        : '/#/app/account/upgrade';

    return { link, isExternal };
  }, [disposition, isPlanAccessible, isHRISCard]);

  useEffect(() => {
    if (isHRISIntegration(integrationcode)) {
      setIsHRISCard(true);
      if (showIntegrateButton) initiateMergeDev();
    }
  }, []);

  const showError = useMemo(() => {
    return (
      (integrationState === IntegrationStateType.Error ||
        integrationState === IntegrationStateType.NoFetchWarning) &&
      errorcode !== 0
    );
  }, [errorcode, integrationState]);

  const showReconfigureBtn = useMemo(() => {
    return (
      !showError &&
      !isLoading &&
      traySolutionInstanceId &&
      integrationState !== IntegrationStateType.Paused &&
      integrationState !== IntegrationStateType.Error &&
      integrationState !== IntegrationStateType.Idle &&
      integrationState !== IntegrationStateType.Running
    );
  }, [showError, isLoading, traySolutionInstanceId, integrationState]);

  const showIntegrationConfigError = useMemo(() => {
    return !isLoading && integrationState === IntegrationStateType.Idle;
  }, [isLoading, integrationState]);

  const showDisabledHris = useMemo(() => {
    return isHRISCard && (apiRequestError || !mergeIsReady);
  }, [apiRequestError, isHRISCard, mergeIsReady]);

  const showInitiatingHris = useMemo(() => {
    return isHRISCard && !mergeIsReady;
  }, [isHRISCard, mergeIsReady]);

  const integrationBtnStateNotLoaded = useMemo(() => {
    return (
      !showEnableButton &&
      !showIntegrateButton &&
      !showResumeBtn &&
      !showReconfigureBtn &&
      !showCtaIntegrateText &&
      !showError
    );
  }, [
    showCtaIntegrateText,
    showEnableButton,
    showError,
    showIntegrateButton,
    showReconfigureBtn,
    showResumeBtn
  ]);

  return (
    <>
      {/* About Modal Template */}
      <Dialog open={openDialog} onClose={handleAboutClose}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: `${heading3FontSize} !important`
          }}
        >
          <Box>
            <Logo height="50px" width="50px" />
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>{modalTitle}</Box>
        </DialogTitle>
        <DialogContent>
          <AboutModalContent
            code={integrationcode}
            description={modalDescription}
            exampleImage={modalExampleImage}
            exampleAltText={modalExampleAltText}
            hrisIntegrationCardType={hrisIntegrationCardType}
          />
        </DialogContent>
      </Dialog>
      {/* Config Modal Template */}
      {!isHRISCard && (
        <Dialog open={openConfigDialog} onClose={handleConfigClose}>
          <DialogTitle>{integrationName} Integration Configuration</DialogTitle>
          <ConfigModal code={integrationcode} modalProps={modalProps} />
        </Dialog>
      )}

      {isHRISCard && mergeLink && (
        <ConfigureMergeModal
          setMergeInitiateConfig={setMergeInitiateConfig}
          setMergeIsReady={setMergeIsReady}
          showIntegrateButton={showIntegrateButton}
          baseModalProps={modalProps}
        />
      )}

      <Grid item>
        <Card
          elevation={3}
          sx={{
            maxWidth: '360px',
            minHeight: '400px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            cursor: 'pointer',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow:
                '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)'
            }
          }}
          onClick={handleAboutOpen}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              left: 10,
              p: 1
            }}
          >
            {badge && badge === FeatureState.Beta && (
              <FeatureBadge label={badge} isPrimary={false} />
            )}
          </Box>
          {showDropdown &&
            !traySolutionInstanceId &&
            !initiatedNotYetCreated && (
              <IntegrationDropdown
                configState={configState}
                isHRISCard={isHRISCard}
              />
            )}
          {traySolutionInstanceId && (
            <IntegrationDropdownTray {...configState} />
          )}

          <IntegrationCardHeader sx={{ width: '100%' }}>
            <Logo height="70px" width="70px" />
            <TitleBox>{title}</TitleBox>
            <DescriptionBox>{description}</DescriptionBox>
          </IntegrationCardHeader>

          <IntegrationCardContent>
            {showEnableButton && (
              <Typography variant="body1" color="primary" pb={1}>
                <CheckIcon sx={{ verticalAlign: 'bottom' }} />
                Enabled
              </Typography>
            )}

            {showResumeBtn && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleResumeClick}
              >
                Resume
              </Button>
            )}

            {showCtaIntegrateText && (
              <>
                {callToActionText === CallToActionType.integrate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfigOpen}
                    id={
                      hrisIntegrationCardType
                        ? heapIdsMap[hrisIntegrationCardType]
                        : heapIdsMap[integrationcode]
                    }
                    disabled={showDisabledHris}
                  >
                    {showInitiatingHris ? 'Initiating...' : callToActionText}
                  </Button>
                ) : (
                  <Button
                    component={Link}
                    href={CTANavigation.link}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target={CTANavigation.isExternal ? '_blank' : '_self'}
                    sx={{
                      color: `${primaryColor}!important`
                    }}
                    variant="contained"
                  >
                    {callToActionText}
                  </Button>
                )}
              </>
            )}

            {showReconfigureBtn && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfigOpen}
              >
                Reconfigure
              </Button>
            )}

            {showError && (
              <Typography color="error" pb={1}>
                Error
              </Typography>
            )}

            {showIntegrationConfigError && (
              <Typography color="error" pb={1}>
                Configuration Error
              </Typography>
            )}

            {(isLoading || integrationBtnStateNotLoaded) && (
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            )}

            {integrationState !== IntegrationStateType.Waiting &&
              initiatedNotYetCreated && (
                <Typography color="primary" pb={1}>
                  Integration initiated. Please wait for setup to complete.
                </Typography>
              )}

            {!initiatedNotYetCreated &&
              !apiRequestError &&
              integrationState !== IntegrationStateType.None && (
                <StateBox>
                  <Typography
                    sx={{
                      fontSize: `${smallFontSize}!important`,
                      fontWeight: mediumFontWeight
                    }}
                  >
                    {determineStateMessage({
                      integrationState,
                      errorcode,
                      updatedtime,
                      createddate: creationDate,
                      integrationcode
                    })}
                    {showErrorWithSupportLink && (
                      <>
                        {' '}
                        If the problem persists, please contact{' '}
                        <Link
                          onClick={(e) => e.stopPropagation()}
                          href="mailto:support@activtrak.com"
                          sx={{
                            fontSize: `${smallFontSize}!important`
                          }}
                        >
                          ActivTrak Support
                        </Link>
                      </>
                    )}
                  </Typography>
                </StateBox>
              )}

            {apiRequestError && (
              <Typography
                sx={{ fontSize: `${smallFontSize}!important`, pb: 1, pt: 1 }}
              >
                Need additional help? Please contact{' '}
                <Link
                  href="https://www.activtrak.com/support"
                  target="_blank"
                  rel="noopener"
                  sx={{ fontSize: smallFontSize }}
                >
                  support.
                </Link>
              </Typography>
            )}
          </IntegrationCardContent>
        </Card>
      </Grid>
    </>
  );
};
