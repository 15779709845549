import {
  ExportDestination,
  ExportDetail,
  ExportPage,
  IExportMenuOption,
  IExportStore
} from './exportStore.models';
import FileDownload from '@mui/icons-material/FileDownload';
import Google from '@mui/icons-material/Google';

export const STATUS_POLLING_INTERVAL: number = 5000;

export const EXPORT_STORE_DEFAULT: IExportStore = {
  exports: [],
  exportStatusIntervals: {},
  exportError: false
};

export const EXPORT_DESTINATION_OPTIONS: IExportMenuOption[] = [
  {
    key: ExportDestination.GCS,
    label: 'Download',
    icon: <FileDownload />,
    id: (reportType) =>
      `36083bf7-6e92-4e8c-bcb3-51e0e14210c-${reportType}-device`
  },
  {
    key: ExportDestination.GoogleDrive,
    label: 'Save to Google',
    icon: <Google />,
    id: (reportType) =>
      `36083bf7-6e92-4e8c-bcb3-51e0e14210c-${reportType}-google`
  }
];

export const PAGE_EXPORT_OPTIONS: IExportMenuOption[] = [
  {
    key: ExportPage.Current,
    label: 'Current Page',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportPage.Current}`,
    exportType: ExportPage.Current
  },
  {
    key: ExportPage.All,
    label: 'All Pages',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportPage.All}`,
    exportType: ExportPage.All
  }
];

export const WEBSITE_DETAILS_EXPORT_OPTIONS: IExportMenuOption[] = [
  {
    key: `top-websites-${ExportDetail.Only}`,
    label: 'Websites Only',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.Only}`,
    exportType: ExportDetail.Only
  },
  {
    key: `top-websites-${ExportDetail.Title}`,
    label: 'Websites and Titles',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.Title}`,
    exportType: ExportDetail.Title,
    visible: 'activityAllowed'
  },
  {
    key: `top-websites-${ExportDetail.SubPage}`,
    label: 'Websites and Subpages',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.SubPage}`,
    exportType: ExportDetail.SubPage,
    visible: 'activityAllowed'
  },
  {
    key: `top-websites-${ExportDetail.User}`,
    label: 'Websites and Users',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.User}`,
    exportType: ExportDetail.User
  }
];

export const APP_DETAILS_EXPORT_OPTIONS: IExportMenuOption[] = [
  {
    key: `top-apps-${ExportDetail.Only}`,
    label: 'Applications Only',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.Only}`,
    exportType: ExportDetail.Only
  },
  {
    key: `top-apps-${ExportDetail.Title}`,
    label: 'Applications and Titles',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.Title}`,
    exportType: ExportDetail.Title,
    visible: 'activityAllowed'
  },
  {
    key: `top-apps-${ExportDetail.User}`,
    label: 'Applications and Users',
    subItems: EXPORT_DESTINATION_OPTIONS,
    id: (reportType) =>
      `d0e7eb2c-de65-452e-8a9f-9a1d583cac47-${reportType}-${ExportDetail.User}`,
    exportType: ExportDetail.User
  }
];
