'use strict';

angular.module('app').directive('alarmDetailsConditions', AlarmDetailsConditions);

function AlarmDetailsConditions() {
    return {
        restrict: 'A',
        scope: {
            parentAlarm: '=alarm',
            options: '='
        },
        template: require('views/alarmDetails/components/alarmDetailsConditions.html'),
        controller: AlarmDetailsConditionsCtrl
    };
}

AlarmDetailsConditionsCtrl.$inject = [
    '$scope',
    '$timeout',
    '$document',
    'messagesService',
    'alarmService',
    'customUibModal',
    'gridPropertiesService',
    'atHelperFunctions',
    'filterService'
];

function AlarmDetailsConditionsCtrl(
    $scope,
    $timeout,
    $document,
    msg,
    alarmService,
    customUibModal,
    gridPropertiesService,
    atHelperFunctions,
    filterService
) {
    $scope.alarm = $scope.parentAlarm;
    var alarmObjectVerifierFunctions =
        require('_app/serviceFunctions/alarmObjectVerifierFunctions').alarmObjectVerifierFunctions;

    $scope.alarm.editor.usbAlarm = {
        inserted: false,
        written: false
    };
    var userEmails = [];
    $scope.alarm.editor.safeSearchConditions = [];

    var fieldsSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                alarmService.getFields($scope.alarm.type, $scope.alarm.alarmid).then(function (res) {
                    res.data.forEach(function (field) {
                        if ($scope.alarm.type === 'Audit') {
                            if (field.name === 'User') {
                                field.altName = 'User';
                                field.name = msg.get('atUser');
                            }
                            if (field.name === 'IP Address') {
                                field.altName = 'IP Address';
                                field.name = msg.get('IpAddressPublic');
                            }
                        } else {
                            if (field.name === 'IP Address') {
                                field.altName = 'IP Address';
                                field.name = msg.get('IpAddressPrivate');
                            }
                        }
                    });
                    options.success(res.data);
                });
            }
        }
    });

    var operatorsSource = new kendo.data.DataSource({
        transport: {
            read: function (options) {
                alarmService.getConditions($scope.alarm.type).then(function (res) {
                    options.success(res.data);
                });
            }
        }
    });

    var addUsbConditions = function (alarm) {
        alarm.orflag = false;
        alarm.terminate = false;

        var id = alarm.conditions.length;

        alarm.conditions.push({
            id: ++id,
            casesensitive: true,
            field: 'Executable',
            fieldName: 'Executable',
            op: 'EqualTo',
            opName: 'Equal To',
            value: 'SYSTEM EVENT'
        });
        alarm.conditions.push({
            id: ++id,
            casesensitive: true,
            field: 'Titlebar',
            fieldName: 'Titlebar',
            op: 'EqualTo',
            opName: 'Equal To',
            value: 'USB STORAGE'
        });

        if ($scope.alarm.editor.usbAlarm.inserted && $scope.alarm.editor.usbAlarm.written) {
            return;
        }

        var description = $scope.alarm.editor.usbAlarm.inserted ? 'insert' : 'Writing';
        alarm.conditions.push({
            id: ++id,
            casesensitive: true,
            field: 'Description',
            fieldName: 'Description',
            op: 'Contains',
            opName: 'Contains',
            value: description
        });
    };

    var filterFieldsAndOperationsForUsb = function () {
        fieldsSource.filter({
            logic: 'or',
            filters: [
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Computer'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Logon Domain'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Primary Domain'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'User'
                }
            ]
        });
        operatorsSource.filter({
            field: 'name',
            operator: 'neq',
            value: 'Greater Than'
        });
    };

    var filterFieldsAndOperationsForSafeSearch = function () {
        fieldsSource.filter({
            logic: 'or',
            filters: [
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Computer'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Description'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Executable'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'IP Address'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Logon Domain'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Primary Domain'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Titlebar'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Url'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'Website'
                },
                {
                    field: 'name',
                    operator: 'eq',
                    value: 'User'
                }
            ]
        });
        operatorsSource.filter(
            {
                field: 'name',
                operator: 'neq',
                value: 'Greater Than'
            },
            {
                field: 'name',
                operator: 'neq',
                value: 'Greater Than Or Equals To'
            }
        );
    };

    var displayAliasWarning = function (username) {
        alarmService.getAlarmUsers(0, 250, username).success(function (res) {
            var existingAlias = res.data.filter(function (item) {
                return item.userName === username && item.userType === 'User' && item.matchesAlias;
            });

            if (existingAlias.length) {
                $scope.$emit('showNotification', {
                    message: msg.get('alarmMatchesOnlyUserName', username),
                    color: 'warning'
                });
            }
        });
    };

    var getFieldName = function (fieldId) {
        var result = '';
        angular.forEach(fieldsSource.data(), function (item) {
            if (item.value === fieldId) {
                result = item.name;
            }
        });
        return result;
    };

    var getConditionName = function (conditionId) {
        var result = '';
        angular.forEach(operatorsSource.data(), function (item) {
            if (item.value === conditionId) {
                result = item.name;
            }
        });
        return result;
    };

    function isRestrictedFieldWithOrFlag(field) {
        return field === 'User' || field === 'Computer' || field === 'IpAddress' || field === 'DurationSeconds';
    }

    function isRestrictedFieldByItself(field) {
        return (
            (field === 'IpAddress' ||
                field === 'User' ||
                field === 'Computer' ||
                field === 'LoginDomain' ||
                field === 'PrimaryDomain') &&
            $scope.alarm.type !== 'Audit'
        );
    }

    function displayConditionWarningModal(type, callback) {
        var modal = customUibModal.open({
            animation: false,
            template: require('views/modals/messageModal.html'),
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageBody: msg.get('switchToMatchAll', type),
                    confirmLabel: msg.get('switchToMatchAllLabel', type)
                }
            }
        });

        modal.result.then(
            function () {
                $scope.alarm.orflag = false;
                if (typeof callback === 'function') {
                    callback();
                }
            },
            function () {
                alarmConditionsDataSource.cancelChanges();
            }
        );
    }

    var alarmConditionsDataSource = new kendo.data.DataSource({
        transport: {
            // Need to have this empty function to make the grid happy
            // Do not remove
            read: function (options) {
                options.success([]);
            },
            create: function (options) {
                var result = options.data;
                result.opName = getConditionName(options.data.op);
                result.fieldName = getFieldName(options.data.field);
                result.id = (alarmConditionsDataSource.data().length + 1).toString();
                $scope.alarm.editor.isEditingCondition = false;

                if (
                    !($scope.alarm.isUsb || $scope.alarm.type === 'Audit' || $scope.alarm.type === 'SafeSearch') &&
                    $scope.alarm.orflag &&
                    isRestrictedFieldWithOrFlag(result.field)
                ) {
                    displayConditionWarningModal(result.fieldName, function () {
                        if (result.field === 'User') {
                            displayAliasWarning(result.value);
                        }
                        options.success(result);
                        $scope.selectedField = '';
                    });
                } else {
                    if (result.field === 'User') {
                        displayAliasWarning(result.value);
                    }
                    options.success(result);
                    $scope.selectedField = '';
                }
            },
            update: function (options) {
                var result = {};
                result.opName = getConditionName(options.data.op);
                result.fieldName = getFieldName(options.data.field);
                result.op = options.data.op;
                result.field = options.data.field;
                result.value = options.data.value;
                $scope.alarm.editor.isEditingCondition = false;

                if (
                    !($scope.alarm.isUsb || $scope.alarm.type === 'Audit' || $scope.alarm.type === 'SafeSearch') &&
                    $scope.alarm.orflag &&
                    isRestrictedFieldWithOrFlag(result.field)
                ) {
                    displayConditionWarningModal(result.fieldName, function () {
                        if (result.field === 'User') {
                            displayAliasWarning(result.value);
                        }
                        options.success(result);
                    });
                } else {
                    if (result.field === 'User') {
                        displayAliasWarning(result.value);
                    }
                    options.success(result);
                }
            },
            destroy: function (options) {
                $scope.alarm.editor.isEditingCondition = false;
                options.success();
            }
        },
        schema: {
            model: {
                id: 'id',
                fields: {
                    field: {
                        editable: true,
                        nullable: false,
                        defaultValue: '',
                        validation: {
                            required: true,
                            fieldValidation: function (input) {
                                if (input.val() === 'DurationSeconds') {
                                    var existingConditions = alarmConditionsDataSource.data().filter(function (item) {
                                        return (
                                            item.field === 'DurationSeconds' &&
                                            item.id !== input[0].kendoBindingTarget.source.id
                                        );
                                    });
                                    if (existingConditions.length) {
                                        input.attr('data-fieldvalidation-msg', msg.get('onlyOneDuration'));
                                        input.data('kendoDropDownList').value('');
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }
                    },
                    fieldName: {
                        editable: true,
                        nullable: false
                    },
                    op: {
                        editable: true,
                        nullable: false,
                        defaultValue: '',
                        validation: {
                            opValidation: function (input) {
                                if (input.is('[name="op"]') && input.val() !== '') {
                                    var field = input[0].kendoBindingTarget.source.field;
                                    if (field === 'DurationSeconds' && input.val() !== 'GreaterThan') {
                                        input.attr('data-opvalidation-msg', msg.get('mustBeGreaterThan'));
                                        input.data('kendoDropDownList').value('');
                                        return false;
                                    } else if (field !== 'DurationSeconds' && input.val() === 'GreaterThan') {
                                        input.attr('data-opvalidation-msg', msg.get('canNotBeGreaterThan'));
                                        input.data('kendoDropDownList').value('');
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }
                    },
                    opName: {
                        editable: true,
                        nullable: false
                    },
                    value: {
                        editable: true,
                        nullable: false,
                        validation: {
                            required: true,
                            durationValidation: function (input) {
                                var field = input[0].kendoBindingTarget.source.field;
                                var value = input.val();
                                if (input.is('[name="value"]') && value !== '' && field === 'DurationSeconds') {
                                    if (isNaN(value) || value.indexOf('.') === value.length - 1) {
                                        input.attr('data-durationvalidation-msg', msg.get('onlyDigits'));
                                        return false;
                                    } else if (value < 10) {
                                        input.attr('data-durationvalidation-msg', msg.get('mustBeAtLeast', 10));
                                        return false;
                                    } else if (value > 2000000000) {
                                        input.attr('data-durationvalidation-msg', msg.get('valueIsTooLarge'));
                                        return false;
                                    } else if (Number(value) !== parseInt(value, 10)) {
                                        input.attr('data-durationvalidation-msg', msg.get('mustBeAnInteger'));
                                        return false;
                                    }
                                }
                                return true;
                            }
                        }
                    }
                }
            }
        }
    });

    $scope.alarmConditionsDataSource = alarmConditionsDataSource;

    $scope.showAlarmConditionsGrid = function () {
        var data = alarmConditionsDataSource.data();
        return data.length > 0;
    };

    $scope.conditionValueDataSource = new kendo.data.DataSource({
        serverFiltering: true,
        transport: {
            read: function (options) {
                if ($scope.selectedField) {
                    var filter =
                        options.data.filter &&
                        options.data.filter.filters &&
                        options.data.filter.filters[0] &&
                        options.data.filter.filters[0].value
                            ? options.data.filter.filters[0].value
                            : null;

                    if ($scope.selectedField === 'User' && $scope.alarm.type !== 'Audit' && filter !== null) {
                        filterService.getEntities('users', filter).then(function (result) {
                            if (result && result.data && result.data.data) {
                                options.success(
                                    _.compact(
                                        _.map(result.data.data, function (item) {
                                            return item.userType === 'User' && item.userId > 0 ? item.userName : null;
                                        })
                                    )
                                );
                            } else {
                                options.success([]);
                            }
                        });
                        return;
                    }

                    if ($scope.selectedField === 'Computer' && filter !== null) {
                        filterService.getEntities('computers', filter).then(function (result) {
                            if (result && result.data && result.data.data) {
                                options.success(
                                    _.compact(
                                        _.map(result.data.data, function (item) {
                                            return item.userType === 'Computer' && item.userId > 0
                                                ? item.userName
                                                : null;
                                        })
                                    )
                                );
                            } else {
                                options.success([]);
                            }
                        });
                        return;
                    }

                    if ($scope.selectedField === 'User' && $scope.alarm.type === 'Audit') {
                        options.success(userEmails);
                        return;
                    }
                    options.success([]);
                } else {
                    options.success([]);
                }
            }
        }
    });

    var fieldEditor = function (container, options) {
        var element = $(
            '<input name="' +
                options.field +
                '" ng-model="$parent.selectedField" required  data-required-msg="Field is required"/>'
        );
        element.appendTo(container).kendoDropDownList({
            dataSource: fieldsSource,
            dataTextField: 'name',
            dataValueField: 'value',
            optionLabel: msg.get('select')
        });
        var widget = element.data('kendoDropDownList');
        stopScroll(widget.ul.parent());
    };

    var conditionEditor = function (container, options) {
        var element = $('<input name="' + options.field + '" required data-required-msg="Operator is required"/>');
        element.appendTo(container).kendoDropDownList({
            dataSource: operatorsSource,
            dataTextField: 'name',
            dataValueField: 'value',
            optionLabel: msg.get('select')
        });
        var widget = element.data('kendoDropDownList');
        stopScroll(widget.ul.parent());
    };

    $scope.showConditionsTable = function () {
        return alarmConditionsDataSource.data().length > 0;
    };

    var gridColumns = [
        {
            field: 'field',
            title: msg.get('field'),
            editor: fieldEditor,
            template: '#=fieldName#',
            activTrakMinWidth: 'auto',
            attributes: {
                class: 'overflow-visible'
            }
        },
        {
            field: 'op',
            title: msg.get('operator'),
            editor: conditionEditor,
            template: '#=opName#',
            activTrakMinWidth: 'auto',
            attributes: {
                class: 'overflow-visible'
            }
        },
        {
            field: 'value',
            title: msg.get('value'),
            editor: '<input type="text" kendo-auto-complete k-data-source="conditionValueDataSource" k-value-primitive="true"  k-no-data-template="\'\'" class="k-input condition-field form-control default-field" name="value" required="required" data-required-msg="Value is required" data-bind="value:value">',
            attributes: {
                class: 'overflow-hidden'
            }
        },
        {
            field: 'casesensitive',
            title: msg.get('caseSensitive'),
            width: 115,
            editor: '<div class="switch-wrapper p-l-10"><label class="switch switch-success"><input type="checkbox" data-bind="checked:casesensitive" id="casesensitive-{{dataItem.uid}}"><i></i></label><label for="casesensitive-{{dataItem.uid}}"></label></div>',
            attributes: {
                class: 'text-center'
            },
            template: "#if (typeof casesensitive !== 'undefined' && casesensitive === true) {# Yes #} else {# No #}#"
        },
        {
            command: ['edit', 'destroy'],
            width: 245
        }
    ];

    $scope.alarmConditionsGridOptions = {
        autoBind: false,
        dataSource: alarmConditionsDataSource,
        columns: gridColumns,
        editable: 'inline',
        dataBound: function () {
            gridPropertiesService.applyMinColWidths(gridColumns);
        },
        edit: function (e) {
            $timeout(function () {
                $scope.alarm.editor.isEditingCondition = true;
            });
            // if default alarm, only allow the value to change
            if ($scope.alarm.default) {
                var field = e.container.find('input[name=field]').data('kendoDropDownList');
                var operator = e.container.find('input[name=op]').data('kendoDropDownList');
                field.enable(false);
                operator.enable(false);
            }
            $scope.selectedField = e.container.scope().dataItem.field;
        },
        cancel: function () {
            $scope.alarm.editor.isEditingCondition = false;
            $scope.$apply();
            $timeout(function () {
                conditionsChanged();
            });
        },
        save: function () {
            $scope.alarm.editor.isEditingCondition = false;
            $scope.$apply();
        },
        remove: function () {
            $scope.alarm.editor.isEditingCondition = false;
            $scope.$apply();
            $timeout(function () {
                conditionsChanged();
            });
        }
    };

    $scope.fieldDropdownOptions = {
        dataSource: fieldsSource,
        dataTextField: 'name',
        dataValueField: 'value',
        optionLabel: msg.get('select'),
        select: function () {
            $document[0].activeElement.blur();
        }
    };

    $scope.operatorDropdownOptions = {
        dataSource: operatorsSource,
        dataTextField: 'name',
        dataValueField: 'value',
        optionLabel: msg.get('select'),
        select: function () {
            $document[0].activeElement.blur();
        }
    };

    $scope.alarmConditionsListViewOptions = {
        dataSource: alarmConditionsDataSource,
        editTemplate: function (dataItem) {
            return (
                '<div class="k-listView title-v-align-middle" data-uid="' +
                dataItem.uid +
                '">' +
                '<table>' +
                '<tbody>' +
                '<tr><td>Field:</td><td><select kendo-dropdownlist k-options="fieldDropdownOptions" data-bind="value:field" required="required" data-required-msg="Value is required"></select></td></tr>' +
                '<tr><td>Operator:</td><td><select name="op" kendo-dropdownlist k-options="operatorDropdownOptions" data-bind="value:op" required="required" data-required-msg="Value is required"></select></td></tr>' +
                '<tr><td>Value:</td><td><input type="text" kendo-auto-complete k-data-source="conditionValueDataSource" k-value-primitive="true"  k-no-data-template="\'\'" class="k-input condition-field form-control default-field" name="value" required="required" data-required-msg="Value is required" data-bind="value:value"></td></tr>' +
                '<tr><td>Case Sensitive:</td><td><div class="switch-wrapper p-l-10"><label class="switch switch-success"><input type="checkbox" data-bind="checked:casesensitive" id="casesensitive-{{dataItem.uid}}"><i></i></label><label for="casesensitive-{{dataItem.uid}}"></label></div></td></tr>' +
                '</tbody>' +
                '</table>' +
                '<div class="m-t-10 m-b-10">' +
                '<a class="k-button k-button-icontext k-primary k-grid-update k-update-button m-r-10" href="\\#">' +
                '<span class="k-icon k-i-check"></span>Update' +
                '</a>' +
                '<a class="k-button k-button-icontext k-grid-cancel k-cancel-button" href="\\#">' +
                '<span class="k-icon k-i-cancel"></span>Cancel' +
                '</a>' +
                '</div>'
            );
        },
        edit: function () {
            $timeout(function () {
                $scope.alarm.editor.isEditingCondition = true;
            });
        },
        cancel: function () {
            $scope.alarm.editor.isEditingCondition = false;
            $scope.$apply();
        },
        save: function () {
            $scope.alarm.editor.isEditingCondition = false;
            $scope.$apply();
        },
        remove: function () {
            $scope.alarm.editor.isEditingCondition = false;
            $scope.$apply();
            $timeout(function () {
                conditionsChanged();
            });
        }
    };

    $scope.addNewCondition = function (isMobile) {
        alarmConditionsDataSource.add({});

        if (!isMobile) {
            $scope.alarmConditionsGrid.editRow($scope.alarmConditionsGrid.tbody.children().last());
        } else {
            var listView = angular.element('#alarmConditionsListView').getKendoListView();

            listView.edit(listView.element.children().last());
        }
    };

    function isUnderDefinedConditions(conditions) {
        var underDefined = true;

        conditions.forEach(function (condition) {
            underDefined = underDefined && isRestrictedFieldByItself(condition.field);
        });

        return underDefined && $scope.alarm.type !== 'Usb';
    }

    function getRequiredFields() {
        var requiredFields = [];
        var fields = fieldsSource.data();

        fields.forEach(function (field) {
            if (!isRestrictedFieldByItself(field.value)) {
                requiredFields.push(field.name);
            }
        });

        return requiredFields;
    }

    function addSafeSearchConditions(additions, conditions) {
        conditions = conditions || [];
        additions.forEach(function (condition) {
            condition.id = (conditions.length + 2).toString();
            conditions.push(condition);
        });
    }

    function conditionsChanged() {
        $scope.alarm.conditions = [];
        var conditions = alarmConditionsDataSource.data();
        conditions.forEach(function (condition) {
            if (!atHelperFunctions.isEmpty(condition.field)) {
                $scope.alarm.conditions.push(condition);
            }
        });
    }

    function updateConditions() {
        // console.log('Update Conditions', angular.copy($scope.alarm));

        var nonSpecialConditions = [];
        if ($scope.alarm.isUsb) {
            $scope.alarm.editor.usbAlarm.inserted = true;
            $scope.alarm.editor.usbAlarm.written = true;

            angular.forEach($scope.alarm.conditions, function (item) {
                if (item.fieldName === 'Description') {
                    if (item.value === 'insert') {
                        $scope.alarm.editor.usbAlarm.written = false;
                    } else if (item.value === 'write' || item.value === 'Writing') {
                        // TODO remove support for 'write' when all databases are updated
                        $scope.alarm.editor.usbAlarm.inserted = false;
                    }
                } else if (item.fieldName !== 'Executable' && item.fieldName !== 'Titlebar') {
                    nonSpecialConditions.push(item);
                }
            });
            alarmConditionsDataSource.data(nonSpecialConditions);
            $scope.alarm.conditions = nonSpecialConditions;
            filterFieldsAndOperationsForUsb();
        } else if ($scope.alarm.type === 'SafeSearch') {
            angular.forEach($scope.alarm.conditions, function (item) {
                var index = item.field.indexOf('SafeSearch');
                if (index >= 0) {
                    $scope.alarm.editor.safeSearchConditions.push(item);
                } else {
                    nonSpecialConditions.push(item);
                }
            });
            alarmConditionsDataSource.data(nonSpecialConditions);
            filterFieldsAndOperationsForSafeSearch();
        } else {
            angular.forEach($scope.alarm.conditions, function (item) {
                if (item.fieldName === 'IP Address') {
                    item.fieldName =
                        $scope.alarm.type === 'Audit' ? msg.get('IpAddressPublic') : msg.get('IpAddressPrivate');
                } else if (item.fieldName === 'User') {
                    item.fieldName = $scope.alarm.type === 'Audit' ? msg.get('atUser') : msg.get('User');
                }
            });

            alarmConditionsDataSource.data($scope.alarm.conditions);
        }

        operatorsSource.read();
        fieldsSource.read();
        $scope.options.isConfigValid();
    }

    /** Kendo fix for scroll bug in webkit
     * http://docs.telerik.com/kendo-ui/controls/editors/dropdownlist/how-to/prevent-close-on-scroll */
    function stopScroll(element) {
        var activeElement;
        $(document).bind('mousewheel DOMMouseScroll', function (e) {
            var scrollTo = null;
            if (!$(activeElement).closest('.k-popup').length) {
                return;
            }
            if (e.type === 'mousewheel') {
                scrollTo = e.originalEvent.wheelDelta * -1;
            } else if (e.type === 'DOMMouseScroll') {
                scrollTo = 40 * e.originalEvent.detail;
            }
            if (scrollTo) {
                e.preventDefault();
                element.scrollTop(scrollTo + element.scrollTop());
            }
        });
        $(document).on('mouseover', function (e) {
            activeElement = e.target;
        });
    }

    $timeout(function () {
        updateConditions();
    });

    function isComponentValid() {
        // console.log('Conditions Validation', $scope.alarm.conditions);
        $scope.alarm.editor.fields = fieldsSource.data();
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule($scope.alarm, $scope.component.type)
        };
    }

    function updatePayload(payload) {
        if (
            !(!$scope.alarm.conditions || $scope.alarm.conditions.length === 0) &&
            isUnderDefinedConditions(payload.conditions)
        ) {
            $scope.$emit('showNotification', {
                message: msg.get('underDefinedAlarm', getRequiredFields()),
                color: 'danger'
            });
            return false;
        }

        var restrictedOrFlagFields = $scope.options.getRestrictedOrFlagFields(payload.conditions);
        if (
            payload.orflag &&
            restrictedOrFlagFields.length > 0 &&
            $scope.alarm.type !== 'Audit' &&
            $scope.alarm.type !== 'Usb'
        ) {
            $scope.$emit('showNotification', {
                message: msg.get('matchAnyRestrictionMessage', restrictedOrFlagFields),
                color: 'warning',
                skipEscape: true
            });
            return false;
        }

        if (
            (!$scope.alarm.conditions || $scope.alarm.conditions.length === 0) &&
            $scope.alarm.type !== 'Usb' &&
            $scope.alarm.type !== 'Usb'
        ) {
            $scope.$emit('showNotification', {
                message: msg.get('missingAlarmCondition'),
                color: 'warning'
            });
        }

        if (payload.type === 'SafeSearch') {
            if ($scope.alarm.editor.safeSearchConditions.length < 1) {
                $scope.$emit('showNotification', {
                    message: msg.get('missingSafeSearchCondition'),
                    color: 'danger'
                });
                return false;
            }
        }

        if (payload.isUsb && !$scope.alarm.editor.usbAlarm.inserted && !$scope.alarm.editor.usbAlarm.written) {
            $scope.$emit('showNotification', {
                message: msg.get('selectConditions'),
                color: 'danger'
            });
            return false;
        }

        if ($scope.alarm.editor.isEditingCondition) {
            $scope.$emit('showNotification', {
                message: msg.get('saveConditionsBeforeAlarm'),
                color: 'danger'
            });
            return false;
        }

        if (payload.type === 'SafeSearch') {
            payload.orflag = false;
            addSafeSearchConditions($scope.alarm.editor.safeSearchConditions, payload.conditions);
        }

        if (payload.type === 'Usb') {
            addUsbConditions(payload);
        }

        return true;
    }

    function alarmUpdated(oldAlarm) {
        // console.log('Conditions Alarm Updated');
        if (!oldAlarm.orflag && $scope.alarm.orflag && $scope.alarm.type !== 'Audit') {
            var restrictedOrFlagFields = $scope.options.getRestrictedOrFlagFields(alarmConditionsDataSource.data());
            if (restrictedOrFlagFields.length > 0) {
                $scope.alarm.orflag = false;
                $scope.$emit('showNotification', {
                    message: msg.get('matchAnyRestrictionMessage', restrictedOrFlagFields),
                    color: 'warning',
                    skipEscape: true
                });
            }
        }
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.conditions,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);

    var previousValue;
    $scope.$watch(
        function () {
            return $scope.alarm.editor.isEditingCondition;
        },
        function (newValue) {
            if (
                !newValue &&
                previousValue !== newValue &&
                $scope.alarm.conditions !== alarmConditionsDataSource.data()
            ) {
                conditionsChanged();
            }

            previousValue = newValue;
        }
    );

    $scope.$watch('selectedField', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.conditionValueDataSource.read();
        }
    });

    if ($scope.alarm.type === 'Audit') {
        $scope.$watch(
            function () {
                return $scope.options.emailsDataSource.data();
            },
            function (newValue) {
                userEmails = newValue;
            }
        );
    }

    if ($scope.alarm.type === 'Usb') {
        $scope.$watch(
            'usbAlarm',
            function () {
                $scope.options.triggerAlarmUpdated($scope.alarm);
            },
            true
        );
    }
}
