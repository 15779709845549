import { useEffect, useState } from 'react';
import { formatDateTimeByTimezone } from '../../common/utils/datetime/datetimeFormatters';

export const useLastUpdate = (date: string) => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (date) {
      setFormattedDate(
        formatDateTimeByTimezone(date, {
          excludeYear: true,
          excludeSeconds: true
        })
      );
    }
  }, [date]);

  return formattedDate;
};
