import { useState } from 'react';
import { useNotifications } from '../../common/services/Notifications';
import { setLocalStorageItem } from '../../common/utils/localStorage';
import { SupportPortalService } from './SupportPortalService';
import { useSupportPortalState } from './useSupportPortalState';
import { useRedirect } from '../hooks/useRedirect';
import { RedirectStatus } from '../enums/RedirectStatus';
import { accountSelector, createAccountProfile, isDuplicate } from '../utils';
import {
  isValidAccountCredentialsDto,
  isValidTokenDto
} from '../utils/checkIfValidData.utils';
import {
  supportPortalLocalStorage,
  displayMessage,
  consoleMessage
} from '../constants';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';
import { INewlyAddedAccount } from '../models/IAccountData';

export const useAddAccount = (
  savedAccounts: INewlyAddedAccount[],
  setSavedAccounts: React.Dispatch<React.SetStateAction<INewlyAddedAccount[]>>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notificationService = useNotifications();
  const service = SupportPortalService();
  const { fetchAccountToken, fetchAccountDetails } = useSupportPortalState({
    service
  });

  const { impersonateAndRedirect, setStatus, status } = useRedirect();

  const handleAddToPortal = async (payload: IAddAccountPayloadDto) => {
    setIsLoading(true);

    try {
      const newAccountTokenData = await fetchAccountToken(payload);

      if (!isValidTokenDto(newAccountTokenData)) {
        console.error(consoleMessage.tokenValidationError.addToPortal);
        notificationService.error(displayMessage.apiError.addToPortal);
        setIsLoading(false);

        return;
      }

      const fetchedAccountDetails = await fetchAccountDetails(
        newAccountTokenData.token
      );

      if (!isValidAccountCredentialsDto(fetchedAccountDetails)) {
        console.error(consoleMessage.accountFetchValidationError.addToPortal);
        notificationService.error(displayMessage.apiError.addToPortal);
        setIsLoading(false);
        return;
      }

      const newAccountId: string = fetchedAccountDetails.account;

      if (isDuplicate(savedAccounts, newAccountId)) {
        notificationService.error(
          displayMessage.duplicateAccountError.addToPortal(newAccountId)
        );
        console.error(
          consoleMessage.duplicateAccountError.addToPortal(newAccountId)
        );
        setIsLoading(false);
        return;
      }

      const accountProfile: INewlyAddedAccount = createAccountProfile(
        fetchedAccountDetails,
        newAccountTokenData,
        payload
      );

      setLocalStorageItem(
        supportPortalLocalStorage.accounts,
        JSON.stringify([...savedAccounts, accountProfile])
      );

      setSavedAccounts((savedAccounts) => [...savedAccounts, accountProfile]);
      notificationService.success(displayMessage.addAccountSuccess);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error?.message === 'Account not found') {
        console.error(error + ` for ${accountSelector(payload)}`);
        notificationService.error(
          displayMessage.nonExistentAccount.addToPortal(payload)
        );
        return;
      }

      console.error(error);
      notificationService.error(displayMessage.generalError.addToPortal);
    }
  };

  const handleRedirect = async (payload: IAddAccountPayloadDto) => {
    setStatus(RedirectStatus.Loading);

    try {
      const newAccountTokenData = await fetchAccountToken(payload);

      if (!isValidTokenDto(newAccountTokenData)) {
        console.error(consoleMessage.tokenValidationError.redirect);
        notificationService.error(displayMessage.apiError.redirect);
        setStatus(RedirectStatus.Error);

        return;
      }

      const fetchedAccountDetails = await fetchAccountDetails(
        newAccountTokenData.token
      );

      if (!isValidAccountCredentialsDto(fetchedAccountDetails)) {
        console.error(consoleMessage.accountFetchValidationError.redirect);
        notificationService.error(displayMessage.apiError.redirect);
        setStatus(RedirectStatus.Error);
        return;
      }

      const newAccountId: string = fetchedAccountDetails.account;

      if (isDuplicate(savedAccounts, newAccountId)) {
        notificationService.error(
          displayMessage.duplicateAccountError.redirect(newAccountId)
        );
        console.error(
          consoleMessage.duplicateAccountError.redirect(newAccountId)
        );
        setStatus(RedirectStatus.Error);
        return;
      }

      const accountProfile: INewlyAddedAccount = createAccountProfile(
        fetchedAccountDetails,
        newAccountTokenData,
        payload
      );

      setLocalStorageItem(
        supportPortalLocalStorage.accounts,
        JSON.stringify([...savedAccounts, accountProfile])
      );

      impersonateAndRedirect(accountProfile);
    } catch (error) {
      setStatus(RedirectStatus.Error);

      if (error?.message === 'Account not found') {
        console.error(error + ` for ${accountSelector(payload)}`);
        notificationService.error(
          displayMessage.nonExistentAccount.redirect(payload)
        );
        return;
      }

      console.error(error);
      notificationService.error(displayMessage.generalError.redirect);
    }
  };

  return {
    isLoading,
    status,
    handleAddToPortal,
    handleRedirect
  };
};
