//https://www.ag-grid.com/react-data-grid/getting-started/
import { useMemo, forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  GridOptions,
  ModuleRegistry,
  ColumnApiModule,
  RowSelectionOptions,
  RowSelectionModule,
  InfiniteRowModelModule,
  ClientSideRowModelModule,
  ClientSideRowModelApiModule,
  PaginationModule,
  CellStyleModule,
  TooltipModule,
  ValidationModule,
  themeQuartz //default theme
} from 'ag-grid-community';
import { GridWrapperContainer } from './atGrid.styles';
import { AtGridProps } from './atGrid.models';
import { AtGridLoader } from './AtGridLoader';

ModuleRegistry.registerModules([
  ColumnApiModule,
  CellStyleModule,
  RowSelectionModule,
  PaginationModule,
  ClientSideRowModelModule,
  InfiniteRowModelModule,
  ClientSideRowModelApiModule,
  TooltipModule,
  ValidationModule /* Development Only */
]);

const AtGrid = forwardRef((props: AtGridProps, ref) => {
  const { gridWrapperStyles, ...gridOptionOverrides } = props;

  const containerStyles = useMemo(
    () => ({ height: '65vh', ...gridWrapperStyles }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const rowSelectionOption =
    gridOptionOverrides?.rowSelection as RowSelectionOptions;
  const includeCheckboxesInLoader =
    rowSelectionOption?.mode === 'multiRow' ||
    rowSelectionOption?.mode === 'singleRow';

  const defaultGridOptions: GridOptions = useMemo<GridOptions>(
    () => ({
      suppressCellFocus: true, // NOTE: This just removes ability to focus each cell. Cell editing can still happen, if needed.
      animateRows: false,
      suppressRowHoverHighlight: true,
      loadingOverlayComponent: AtGridLoader,
      loadingOverlayComponentParams: {
        height: containerStyles.height,
        columnCount: gridOptionOverrides?.columnDefs?.length,
        includeCheckboxes: includeCheckboxesInLoader
      }
    }),
    [
      includeCheckboxesInLoader,
      gridOptionOverrides?.columnDefs?.length,
      containerStyles.height
    ]
  );

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      cellStyle: { alignContent: 'center' },
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
                  <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                    <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                    <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                    <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                  </div>
                </div>`
      }
    };
  }, []);

  return (
    <GridWrapperContainer sx={containerStyles}>
      <AgGridReact
        ref={ref as React.LegacyRef<AgGridReact>}
        theme={themeQuartz}
        defaultColDef={defaultColDef}
        {...defaultGridOptions}
        {...gridOptionOverrides}
      />
    </GridWrapperContainer>
  );
});

export default AtGrid;
