import { Status } from '../enums';
import { formatDateTimeByTimezone } from '../../common/utils/datetime/datetimeFormatters';
import {
  ITicketDto,
  ITicket,
  ITicketCommentDto,
  ITicketComment
} from '../models';
import { MAX_FILE_SIZE } from '../constants';
import { FileError } from 'react-dropzone';

const mapToStatus = (status: string) => {
  switch (status) {
    case 'closed':
      return Status.Closed;
    case 'solved':
      return Status.Solved;
    case 'hold':
      return Status.OnHold;
    case 'pending':
      return Status.Pending;
    default:
      return Status.New;
  }
};

export const mapToTicket = (ticket: ITicketDto): ITicket => {
  return {
    id: ticket.id,
    attachments: ticket.attachments,
    updatedAt: ticket.updatedAt,
    createdAt: ticket.createdAt,
    updatedAtDisplay: formatDateTimeByTimezone(ticket.updatedAt),
    createdAtDisplay: formatDateTimeByTimezone(ticket.createdAt),
    subject: ticket.subject,
    description: ticket.description,
    status: mapToStatus(ticket.status)
  };
};

export const mapToComment = (comment: ITicketCommentDto): ITicketComment => {
  return {
    id: comment.id,
    attachments: comment.attachments,
    createdAt: comment.createdAt,
    createdAtDisplay: formatDateTimeByTimezone(comment.createdAt),
    body: comment.body
  };
};

export const searchSupportTickets = (
  tickets: ITicket[],
  filter: string
): ITicket[] => {
  if (!tickets || !filter || filter === '') {
    return tickets;
  }

  const includes = (main: string, sub: string): boolean => {
    if (!sub) {
      return true;
    }
    if (!main) {
      return false;
    }
    return main.toLowerCase().includes(sub.toLowerCase());
  };

  return tickets.filter(
    (ticket) =>
      includes(ticket.id?.toString(), filter) ||
      includes(ticket.subject, filter) ||
      includes(ticket.description, filter)
  );
};

export const fileValidator = (file: File): FileError => {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-large',
      message: 'File too large. Max file size is 7MB.'
    };
  }
  return null;
};

export const getFollowUpTicketDescription = (ticket: ITicket) => {
  return (
    '\n\n*** Follow Up Ticket For Below Ticket ***\nTicket #:\n' +
    ticket.id +
    '\n\nCreated:\n' +
    ticket.createdAtDisplay +
    '\n\nSubject:\n' +
    ticket.subject
  );
};
