import React, { forwardRef } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { hideModal } from '../../../common/stores/globalModalStore';
import { fontColor900 } from '../../../common/constants';
import { GlobalModalContent } from '../templates/GlobalModalContent';
import { PrimaryButton } from '../styles/ModalBtn.styles';

export const ExportErrorModalContent = forwardRef<HTMLDivElement>((_, ref) => {
  const handleClick = () => {
    hideModal();
  };

  return (
    <GlobalModalContent ref={ref}>
      <DialogTitle>Export Error</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: fontColor900
          }}
        >
          There was a problem exporting your data. Refresh the page and try
          again. If the error persists, please&nbsp;
          <Link
            href="mailto:support@activtrak.com"
            sx={{ textDecoration: 'none' }}
          >
            contact Support
          </Link>
          &nbsp;or use the chat button in the bottom right corner of your
          screen.
        </Typography>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={handleClick}>Close</PrimaryButton>
      </DialogActions>
    </GlobalModalContent>
  );
});
