import { useCallback, useState } from 'react';
import {
  IApiKeyDto,
  IApiKeyPayload,
  IApiKeyService,
  IApiKeyState,
  IApiKeysDto,
  INewApiKeyDto
} from '../models';
import { NotificationType, SortDirection } from '../../common/enums';

import { sorter } from '../../common/utils/sorter/sorter';

type ApiKeyStateProps = {
  service: IApiKeyService;
};

export const useApiKeyState = ({ service }: ApiKeyStateProps): IApiKeyState => {
  const {
    getPublicApiKeys,
    createPublicApiKey,
    deletePublicApiKey,
    updatePublicApiKey
  } = service;

  const [allApiKeyMembers, setApiKeys] = useState<IApiKeyDto[]>();
  const [isApiKeyMemberLoading, setIsApiKeyMemberLoading] =
    useState<boolean>(false);
  const [isApiKeyMembersLoading, setIsApiKeyMembersLoading] =
    useState<boolean>(false);
  const [keysNotification, setApiNotification] = useState<NotificationType>();




  const getApiKeys = useCallback(
    async (): Promise<void> => {
      setIsApiKeyMembersLoading(true);
      try {
        const apiKeysDto: IApiKeysDto = await getPublicApiKeys();
        {
          setApiKeys(apiKeysDto?.keys);
        }
      } catch (error) {
        console.error('ActivTrak Error: Unable to load list API keys');

        setApiNotification({
          msg: 'Unable to load Api Keys',
          type: 'error'
        });
      } finally {
        setIsApiKeyMembersLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const init = useCallback(async (): Promise<void> => {
    if (!allApiKeyMembers) {
      await getApiKeys();
    }
  }, [getApiKeys, allApiKeyMembers]);

  const addApiKeyMembers = useCallback(
    async (payload: IApiKeyPayload): Promise<string> => {
      setIsApiKeyMemberLoading(true);
      let newApiKey: INewApiKeyDto = undefined;
      try {
        newApiKey = await createPublicApiKey(payload);
        {
          setApiNotification({
            msg: 'Api Key Created',
            type: 'success'
          });
          await getApiKeys();
          return Promise.resolve(newApiKey.key);
        }
      } catch (error) {
        {
          setApiNotification({
            msg: 'Unable to create API key',
            type: 'error'
          });
          return Promise.reject(error);
        }
        console.error('ActivTrak Error: Unable to create API key');
      } finally {
        setIsApiKeyMemberLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteApiKeyMember = useCallback(
    async (keyId: number): Promise<void> => {
      setIsApiKeyMemberLoading(true);
      try {
        await deletePublicApiKey(keyId);
        {
          setApiNotification({
            msg: 'API key deleted',
            type: 'success'
          });
        }
        await getApiKeys();
      } catch (error) {
        {
          setApiNotification({
            msg: 'Unable to delete API key',
            type: 'error'
          });
        }
        console.error('ActivTrak Error: Unable to delete API key');
      } finally {
        {
          setIsApiKeyMemberLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateApiKeyMembers = useCallback(
    async (keyId: number, updatedDescription: string): Promise<void> => {
      setIsApiKeyMemberLoading(true);
      try {
        await updatePublicApiKey(keyId, updatedDescription);
        setApiNotification({
          msg: 'Api Key Updated',
          type: 'success'
        });
        getApiKeys();
      } catch (error) {
        setApiNotification({
          msg: 'Unable to Update Api Key',
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to update API key');
      } finally {
        setIsApiKeyMemberLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );



  const setSortedApiKeys = useCallback(
    (sortDirection: SortDirection, sortOrderBy: string): void => {
      const newOrder = sorter(allApiKeyMembers, sortDirection, sortOrderBy);
      setApiKeys(newOrder);
    },
    [allApiKeyMembers]
  );

  return {
    allApiKeyMembers,
    isApiKeyMemberLoading,
    isApiKeyMembersLoading,
    apiNotification: keysNotification,
    setApiNotification,
    init,
    addApiKeyMembers,
    deleteApiKeyMember,
    setSortedApiKeys,
    updateApiKeyMembers
  };
};
