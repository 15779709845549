import { templateServiceFunctions } from '_app/serviceFunctions/templateServiceFunctions';

export const convertUnixTimeStamp = (unixTimeStamp) => {
    if (!unixTimeStamp || unixTimeStamp <= 0) {
        return;
    }

    const creationDateObject = moment.unix(unixTimeStamp);
    return `${creationDateObject.format('Y-MM-DD hh:mm:ss A Z')} (${templateServiceFunctions.getCreationLengthString(
        creationDateObject
    )})`;
};

export const stringify = (data) => {
    if (!data || typeof data !== 'object') {
        return data;
    }

    try {
        return JSON.stringify(data);
    } catch {
        return 'invalid value';
    }
};

export const convertTotalStorage = (totalStorage) => {
    const numOfMegabytesInTerabyte = 1000000;
    const numOfBytesInGigabyte = 1000;
    if (totalStorage > numOfMegabytesInTerabyte) {
        return `${totalStorage / numOfMegabytesInTerabyte} TB`;
    } else {
        if (totalStorage > numOfBytesInGigabyte) {
            return `${totalStorage / numOfBytesInGigabyte} GB`;
        } else {
            return `${totalStorage} MB`;
        }
    }
};
