import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IInsightsCategory } from '../../models';

type CategoryCheckboxLabelProps = {
  category: IInsightsCategory;
  searchString: string;
};

const CategoryCheckboxLabel: React.FC<CategoryCheckboxLabelProps> = ({
  category,
  searchString
}) => {
  let parts = [];
  let matchPart = '',
    preMatchPart = '',
    postMatchPart = '',
    matchedAppOrSite = '';
  if (category.matchedApps?.length > 0 || category.matchedSites?.length > 0) {
    const matchedItem = category.matchedSites[0] || category.matchedApps[0];
    parts = matchedItem.split(new RegExp(`(${searchString})`, 'gi'));
    matchedAppOrSite = category.matchedSites[0] ? 'site' : 'app';
    const firstMatchingIndex = parts.findIndex(
      (part) => part.toLowerCase() === searchString.toLowerCase()
    );
    preMatchPart = parts.slice(0, firstMatchingIndex).join('');
    matchPart = parts[firstMatchingIndex];
    postMatchPart = parts.slice(firstMatchingIndex + 1).join('');
  }

  const getCountStr = (listItems: string[], type: string) => {
    let count = listItems.length;
    if (count === 0 || (count === 1 && type === matchedAppOrSite)) {
      return '';
    }
    if (type === matchedAppOrSite) {
      count--;
    }
    return `; +${count} ${type}${count === 1 ? '' : 's'}`;
  };

  return (
    <>
      {category.category}
      {(category.matchedApps?.length > 0 ||
        category.matchedSites?.length > 0) && (
        <Box sx={{ display: 'flex' }}>
          <Typography color="textSecondary" sx={{ fontStyle: 'oblique' }}>
            Includes:&nbsp;
          </Typography>
          <Typography color="textSecondary">{preMatchPart}</Typography>
          <Typography sx={{ fontWeight: 400 }}>{matchPart}</Typography>
          <Typography color="textSecondary">
            {postMatchPart}
            {getCountStr(category.sites, 'site')}
            {getCountStr(category.apps, 'app')}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CategoryCheckboxLabel;
