import React, { useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { HourSelector } from '../../Select';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { WhiteBackgroundTooltip } from '../../Tooltip/TextWithTooltip.styles';
import { InfoOutlinedIcon } from '../../../styles/app.component.styles';
import { IReportFilters } from '../models/IReportFilters';
import { DEFAULT_REPORT_FILTERS } from '../constants/defaultReportFilters';
import { LinkWithIcon } from '../../Link';
import { useDateSettingsStore } from '../../../hooks/useDateSettingsStore';

type TimelineFilterProps = {
  date: string;
  filterValues: Partial<IReportFilters>;
  setFilterValues: (filters) => void;
};

const TimelineFilter = (props: TimelineFilterProps) => {
  const { date, filterValues, setFilterValues } = props;
  const { isTimeFormat24Hour } = useDateSettingsStore();

  const handleStartDate = useCallback(
    (value) => {
      setFilterValues({
        from: value
      });
    },
    [setFilterValues]
  );

  const handleEndDate = useCallback(
    (value) => {
      setFilterValues({
        to: value
      });
    },
    [setFilterValues]
  );

  const handleStrictToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterValues({ strict: event.target.checked });
    },
    [setFilterValues]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
        <LinkWithIcon
          text="Productivity"
          redirectTo={'app.reports.productivityLegacy'}
          redirectParams={{ range: 'Today' }}
        ></LinkWithIcon>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Typography>{date}</Typography>
        <Box sx={{ display: 'inline-block' }}>
          <Typography
            sx={{
              display: 'inline-block',
              verticalAlign: 'middle',
              mr: 1
            }}
          >
            Start:
          </Typography>
          <HourSelector
            id="2ae19a9a-6b36-45ec-bafd-6eb0e16cfe2e"
            value={filterValues?.timelineFromHour}
            onChange={handleStartDate}
            startHour={0}
            endHour={Math.min(filterValues?.timelineToHour - 1, 23)}
            defaultValue={DEFAULT_REPORT_FILTERS.timelineFromHour}
            isTimeFormat24Hour={isTimeFormat24Hour}
          />
          <Typography
            sx={{
              display: 'inline-block',
              verticalAlign: 'middle',
              mr: 1,
              ml: 4
            }}
          >
            End:
          </Typography>
          <HourSelector
            id="1d3daa7c-fb56-422f-aaee-965fc9e651a2"
            value={filterValues?.timelineToHour}
            onChange={handleEndDate}
            startHour={Math.max(filterValues?.timelineFromHour + 1, 1)}
            endHour={24}
            defaultValue={DEFAULT_REPORT_FILTERS.timelineToHour}
            isTimeFormat24Hour={isTimeFormat24Hour}
          />
        </Box>
        <Box sx={{ display: 'inline-block', position: 'relative' }}>
          <FormControlLabel
            control={
              <Switch
                name="strict-mode"
                checked={filterValues?.timelineStrict}
                color="primary"
                onChange={handleStrictToggle}
              />
            }
            label={
              <>
                <Typography>Strict Mode</Typography>
                <WhiteBackgroundTooltip title="In Strict mode, intervals with any unproductive activity will be marked unproductive.">
                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: '-8px',
                      top: '3px'
                    }}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </WhiteBackgroundTooltip>
              </>
            }
          />
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};

export default TimelineFilter;
