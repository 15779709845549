import { useEffect } from 'react';
import { useUIRouterParams } from '../../common/hooks/useUIRouterParams';
import {
  getDateFormat,
  getDateTimeFormat
} from '../../common/utils/datetime/datetimeFormatStrings';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useNotifications } from '../../common/services/Notifications';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { useIdentityCommonState } from '../hooks/useIdentityCommonState';
import { useIdentityDetailState } from '../hooks/useIdentityDetailState';
import { DetailNavHeader } from '../components/DetailNavHeader';
import { DetailUserInfo } from '../components/DetailUserInfo';
import { useUserSearchState } from '../hooks/useUserSearchState';
import SnackbarWithUrl from '../components/SnackbarUrl';
import { PermissionLevel } from '../../common/enums';
import { DetailUserDataSource } from '../components/DetailUserDataSource';
import { useDntDeleteState } from '../hooks/useDntDeleteState';
import { getIdentitySearchResultsStore } from '../../common/stores/identitySearchResultsStore';
import { getRoutePermissionLevel } from '../../common/stores/userPermissionsStore/userPermissionStore';

export function UserDetail() {
  const dateTimeFormat = getDateTimeFormat();
  const commonState = useIdentityCommonState({
    dateFormat: getDateFormat(),
    dateTimeFormat
  });
  const detailState = useIdentityDetailState({ commonState });
  const { selectedIdentity } = detailState;
  const userSearchState = useUserSearchState({
    persistSearchToStore: true
  });

  const permission = getRoutePermissionLevel('app.settings.identity');
  const isReadOnly = permission === null || permission === PermissionLevel.Read;

  const { identities, initializeUserIdentities } = userSearchState;

  const {
    setSelectedIdentityId,
    identityNotification,
    setIdentityNotification,
    snackBarHyperlinkText,
    snackBarMessage,
    snackBarOpen,
    snackBarUrl,
    onSnackBarClose
  } = commonState;

  const { getUserDetail, detailNotification, setDetailNotification } =
    detailState;
  const dntDeleteState = useDntDeleteState();
  const notifications = useNotifications();

  useEffect(() => {
    //we get notifications from detail and common states
    let currentNotification = null;
    let setCurrentNotification = null;
    if (identityNotification?.msg && identityNotification?.type) {
      currentNotification = identityNotification;
      setCurrentNotification = setIdentityNotification;
    }
    if (detailNotification?.msg && detailNotification?.type) {
      currentNotification = detailNotification;
      setCurrentNotification = setDetailNotification;
    }
    if (currentNotification) {
      notifications[currentNotification.type](currentNotification.msg);
      setCurrentNotification(undefined);
    }
  }, [
    notifications,
    identityNotification,
    setIdentityNotification,
    detailNotification,
    setDetailNotification
  ]);

  const { id: identityId }: { id: number } = useUIRouterParams();
  const searchResultsStore = getIdentitySearchResultsStore();

  useEffect(() => {
    if (identityId != 0) {
      setSelectedIdentityId(identityId);
      getUserDetail(identityId);
      const { lastUserSearchResultIds } = searchResultsStore;
      if (
        lastUserSearchResultIds.length == 0 &&
        (!identities || identities.length == 0)
      ) {
        initializeUserIdentities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityId]);

  useEffect(() => {
    dntDeleteState.setUserToModify(selectedIdentity);
  }, [selectedIdentity, dntDeleteState]);

  return (
    <Box>
      <Grid container>
        <DetailNavHeader
          userSearchState={userSearchState}
          identityDetailState={detailState}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <DetailUserInfo
              identityDetailState={detailState}
              commonState={commonState}
              newUserMode={false}
              isReadOnly={isReadOnly}
              dntDeleteState={dntDeleteState}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DetailUserDataSource
                  detailState={detailState}
                  isReadOnly={isReadOnly}
                  dntDeleteState={dntDeleteState}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SnackbarWithUrl
        open={snackBarOpen}
        message={snackBarMessage}
        hyperlinkText={snackBarHyperlinkText}
        url={snackBarUrl}
        onClose={onSnackBarClose}
      />
    </Box>
  );
}

export const UserIdentityDetailComponent = () => (
  <ReactivTrakComponentWrapper>
    <UserDetail />
  </ReactivTrakComponentWrapper>
);
