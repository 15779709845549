import axios from 'axios';
import { getUserToken } from '../authentication/useUserTokenStore';
import apiInterceptors from './apiInterceptors';
import {
  IActivTrakRequestConfig,
  IHeaders,
  IApiService,
  HttpResponse
} from '../../models';
import versionJSON from '../../../../../version.json';

/**
 * Retrieves child account token from local storage using available URL parameter.
 *
 * @returns {string | undefined} Encoded Child Account Token
 */
const _getChildAccountToken = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get('accountId');

  // Return undefined if no account id found in URL
  if (!accountId) {
    return;
  }

  const child = window.localStorage.getItem('activTrak.child-' + accountId);

  // Return undefined if no child object found in local storage
  if (!child) {
    return;
  }

  try {
    // Return encoded child account token
    return JSON.parse(child).token;
  } catch (e) {
    console.error(
      `ActivTrak Error: Unable to parse child token from storage object.\nAccound Id: ${accountId}\nJSON String: ${child}\nError:`,
      e
    );
    // Return undefined if an error occurred parsing JSON string.
    return;
  }
};

/**
 * Sends an Axios API request and returns a promise.
 *
 * @param {string} path
 * @param {IActivTrakRequestConfig} config
 */
export const apiRequest = async <T>(
  path: string,
  props: IActivTrakRequestConfig | undefined = {}
): Promise<HttpResponse<T>> => {
  const {
    headers = {},
    responseType = 'json',
    authorizationRequired = false
  } = props;
  const defaultHeaders: IHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-AT-Version': versionJSON?.version || 'x.x.x'
  };

  if (authorizationRequired) {
    const childToken = _getChildAccountToken();
    const userToken = getUserToken();

    
    if (userToken === '' || userToken === 'invalid') {
      // Temporarily logging invalid token status
      console.warn('invalid token');
      // return;
    }  

    defaultHeaders.Authorization =
      childToken || userToken;
  }

  // NOTE: Enabling withCredentials for FEAPI requests only
  // Google Storage requests fail with this setting
  // Remove after FEAPI goes stateless
  const enableWithCredentials = path.indexOf('/api') >= 0;

  const combinedHeaders = { ...defaultHeaders, ...headers };

  // 'async' should be removed, but doing so causes a rabbit hole of other issues
  // needs refactor
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const args = {
        ...props,
        url: path,
        responseType,
        headers: combinedHeaders,
        withCredentials: enableWithCredentials
      };

      const response = (await axios(args)) as HttpResponse<T>;
      if (!response.status || response.status >= 400) {
        throw new Error(JSON.stringify(response));
      }
      resolve(response);
    } catch (error) {
      console.error(
        `ActivTrak Error: An error occurred during a network request.\nError: ${error}\nUrl: ${path}\nHeaders:`,
        combinedHeaders
      );
      reject(error.response ?? error);
    }
  });
};

apiInterceptors();

export const apiService: IApiService = {
  get: <T>(
    path: string,
    config: IActivTrakRequestConfig | undefined
  ): Promise<HttpResponse<T>> =>
    apiRequest<T>(path, { ...config, method: 'get' }),
  post: <T>(
    path: string,
    config: IActivTrakRequestConfig
  ): Promise<HttpResponse<T>> =>
    apiRequest<T>(path, { ...config, method: 'post' }),
  put: <T>(
    path: string,
    config: IActivTrakRequestConfig
  ): Promise<HttpResponse<T>> =>
    apiRequest<T>(path, { ...config, method: 'put' }),
  delete: <T>(
    path: string,
    config: IActivTrakRequestConfig | undefined
  ): Promise<HttpResponse<T>> =>
    apiRequest<T>(path, { ...config, method: 'delete' }),
  patch: <T>(
    path: string,
    config: IActivTrakRequestConfig
  ): Promise<HttpResponse<T>> =>
    apiRequest<T>(path, { ...config, method: 'patch' })
};
