import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { AuditLogWrapper } from '../utils/auditLogWrapper';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { AuditLogHeader } from '../components/AuditLogHeader';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import authorization from '../../../common/helpers/authorization';
import { Role } from '../../../common/enums';

const AuditLogView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  const isPaid = authorization.hasFeature(BundleFlag.PaidFunctionality);
  const isSupport = authorization.hasAnyRole([
    Role.SupportAdvanced,
    Role.SuperAdmin
  ]);
  const showHeader = isPaid || isSupport;

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      {showHeader && (
        <AuditLogHeader
          reportFilters={reportFilters}
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
          refreshTimestamp={refreshTimestamp}
        />
      )}
      <AuditLogWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
      />
    </>
  );
};

export default AuditLogView;

export const AuditLogViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <AuditLogView />
  </ReactivTrakComponentWrapper>
);
