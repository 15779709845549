import React from 'react';
import { ArrowUp, ArrowDown } from './SideBar.styles';

export const ExpandIcon = (props) => {
  const { subMenuOpen } = props;

  return subMenuOpen ? (
    <ArrowUp fontSize="medium"></ArrowUp>
  ) : (
    <ArrowDown fontSize="medium"></ArrowDown>
  );
};
