import { applicationRoles } from '_app/serviceFunctions/constantsServiceFunctions';
import authService from '../../../_reactivtrak/src/common/helpers/authorization';

angular.module('core').service('authorizationService', AuthorizationService);

AuthorizationService.$inject = ['$rootScope', '$state'];

function AuthorizationService() {
    this.roles = {
        user: applicationRoles.user.key,
        admin: applicationRoles.admin.key,
        viewer: applicationRoles.viewer.key,
        configurator: applicationRoles.configurator.key,
        supportBasic: applicationRoles.supportBasic.key,
        supportIntermediate: applicationRoles.supportIntermediate.key,
        supportAdvanced: applicationRoles.supportAdvanced.key,
        superAdmin: applicationRoles.superAdmin.key,
        multiAccount: applicationRoles.multiAccount.key,
        ccAccess: applicationRoles.ccAccess.key,
        supportPortal: applicationRoles.supportPortal.key
    };

    this.applicationRoles = { ...applicationRoles };

    this.clearAuthorizations = () => {
        return authService.clearAuthorizations();
    };

    this.hasFeature = (featureName) => {
        return authService.hasFeature(featureName);
    };

    /// key can be string or numeric
    this.getRole = (key) => {
        return authService.getRole(key);
    };

    this.hasRole = (rolesToCheck) => {
        return authService.hasRole(rolesToCheck);
    };

    //added to match functions used in React
    this.hasAnyRole = (roles) => {
        return authService.hasAnyRole(roles);
    };
    this.hasRoleAccess = (roleAccess) => {
        return authService.hasRoleAccess(roleAccess);
    };

    this.getUserType = () => {
        return authService.getUserType();
    };

    this.getParentAccount = () => {
        return authService.getParentAccount();
    };

    this.isMspChildAccount = () => {
        return authService.isMspChildAccount();
    };

    this.hasAuthorizationLevel = (levels, routeName, featureName) => {
        return authService.hasAuthorizationLevel(levels, routeName, featureName);
    };

    this.hasRouteByName = (routeName) => {
        return authService.hasRouteByName(routeName);
    };
}
