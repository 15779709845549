import React from 'react';
import { ExportWrapper } from '../utils/exportWrapper';
import { ExportContainerSC } from '../styles';
import { Role } from '../../../enums';
import authorization from '../../../helpers/authorization';

export const ExportButtonContainer = () => {
  if (
    authorization.hasAnyRole([
      Role.SupportBasic,
      Role.SupportIntermediate,
      Role.SupportAdvanced
    ])
  ) {
    return <></>;
  }

  return (
    <ExportContainerSC>
      <ExportWrapper />
    </ExportContainerSC>
  );
};
