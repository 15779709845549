import InsightsPromoService from '../../../../_app/insights/insightsPromoService';
import { INSIGHTS_UPGRADE_PLAN_LABEL } from '../../../../_reactivtrak/src/common/constants/plans';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').controller('impactPromoCtrl', ImpactPromoCtrl);

ImpactPromoCtrl.$inject = [
    '$scope',
    'authorizationService',
    'envService',
    'envConfig',
    'notificationService',
    'utmService'
];

function ImpactPromoCtrl($scope, authorizationService, envService, envConfig, notificationService, utmService) {
    var accountSettings = getAccountSettings();
    var insightsPromoService = new InsightsPromoService(
        envService.getMarketoConfig(),
        envConfig,
        accountSettings,
        notificationService,
        utmService,
        authorizationService
    );

    $scope.requestDemo = function () {
        insightsPromoService.requestFormImpact('demoRequestFormId', 'Demo');
    };

    $scope.requestTrial = function () {
        insightsPromoService.requestFormImpact('trialRequestFormId', 'Trial');
    };

    $scope.planLabel = INSIGHTS_UPGRADE_PLAN_LABEL;
}
