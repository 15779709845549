import React from 'react';
import { useProfileStore } from '../../../../services/Profile';

import {
  Box,
  List,
  Divider,
  Popover,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  LinkSecondarySC,
  LogoutBoxSC,
  LogoutSC,
  ProfileInfoSC,
  StatusAvatarSC,
  TextOverflowSC,
  TypographyNamesSC
} from '../styles/Header.styles';
import { HeaderMenuIcons } from '../utils/HeaderMenuIcons';
import { useStatuspage } from '../../../../../third-party/StatusPageProvider';
import { SupportAgent } from '@mui/icons-material';
import authorization from '../../../../helpers/authorization';

export const HeaderMenu = (props) => {
  const {
    logout,
    openSupportModal,
    showCommandCenterButton,
    showSupportPortalButton,
    showUpgradeButton,
    hideOnLargeScreenStyle,
    hideAccountConfiguration,
    userLicense,
    accountSettings
  } = props;
  const profile = useProfileStore((p) => p.profile);

  const onLogout = () => {
    logout();
  };

  const statusContext = useStatuspage();
  const { status, isLoading } = statusContext;

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isSupportOrSuperAdmin = authorization?.isAnySupportUser();

  return (
    <Box sx={{ flexGrow: 0, elevation: 8 }}>
      <IconButton
        sx={{ padding: '0px !important' }}
        onClick={handleOpenUserMenu}
        id="5135ad1e-661f-4ebd-8636-8b48461c514b"
      >
        <StatusAvatarSC status={status}>
          {isSupportOrSuperAdmin ? <SupportAgent /> : profile?.firstName?.[0]}
        </StatusAvatarSC>
      </IconButton>
      <Popover
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        PaperProps={{
          style: { width: '252px' }
        }}
        disableScrollLock
      >
        <List component="nav" disablePadding>
          <ProfileInfoSC>
            <TypographyNamesSC variant="subtitle2">
              {profile?.firstName} {profile?.lastName}
            </TypographyNamesSC>
            <Tooltip placement="bottom-start" title={profile?.username || ''}>
              <TextOverflowSC>{profile?.username}</TextOverflowSC>
            </Tooltip>
          </ProfileInfoSC>
          <Divider flexItem />
          {!isLoading && (
            <HeaderMenuIcons
              userLicense={userLicense}
              accountSettings={accountSettings}
              handleCloseUserMenu={handleCloseUserMenu}
              openSupportModal={openSupportModal}
              isSupportOrSuperAdmin={isSupportOrSuperAdmin}
              showCommandCenterButton={showCommandCenterButton}
              showSupportPortalButton={showSupportPortalButton}
              showUpgradeButton={showUpgradeButton}
              hideOnLargeScreenStyle={hideOnLargeScreenStyle}
              hideAccountConfiguration={hideAccountConfiguration}
              status={status}
            />
          )}
          <Divider flexItem sx={{ marginTop: '16px' }} />
          <LogoutBoxSC
            onClick={onLogout}
            id="8e38d173-1033-4d71-aa42-7a60ba4b6fcf"
          >
            <LinkSecondarySC underline="none">
              Log Out
              <LogoutSC fontSize="small" />
            </LinkSecondarySC>
          </LogoutBoxSC>
        </List>
      </Popover>
    </Box>
  );
};
