import React from 'react';
import { ChartData } from 'chart.js';
import { createBaseReportFilterParams } from '../../../common/components/ReportFilters/utils/reportFilter.utils';
import { convertObjectToQs } from '../../../common/utils/parse/parse';
import { fetchReportingData } from '../../../common/helpers/apis/apiRequest';
import {
  ILegendItem,
  IProductivityReports,
  IProductivityReportDto,
  IProductivityReport,
  ProductivityDetailLegendLabels,
  ProductivitySummaryLegendLabels,
  AggregationPeriod,
  ProductivityName
} from '../models/Productivity.models';
import { getStringDurationFormat } from '../../../dashboard/utils/widget.utils';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import { getAggregationPeriod } from '../stores/productivityAggregation.store';
import moment from 'moment';
import { getDateFormat } from '../../../common/utils/datetime/datetimeFormatStrings';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { ProductivityIcons } from '../constants/productivityIcons.constants';

export const getProductivityReportData =
  async (): Promise<IProductivityReport> => {
    const { to, from, userId, groupId, userMode, userType } =
      createBaseReportFilterParams();
    const period = getAggregationPeriod()['period'];

    const reportFilterStr = convertObjectToQs({
      from,
      to,
      userId: groupId || userId,
      userType: userType,
      mode: userMode,
      period: period,
      range: 'Custom_Date_Range'
    });
    try {
      const response = await fetchReportingData<IProductivityReportDto>({
        path: '/reports/v1/productivity?' + reportFilterStr
      });
      const data = transformProductivityApiResponse(response);
      const dates = response.dates;
      return { data: data ?? [], dates: dates, error: null };
    } catch (error) {
      return { data: [], dates: [], error: error };
    }
  };

export const transformProductivityApiResponse = (
  response: IProductivityReportDto
): IProductivityReports[] => {
  if (!response.data) return [];

  const dataByDate: { [key: string]: { [key: string]: number } } = {};

  response.dates.forEach((date, index) => {
    dataByDate[date] = {};
    response.data.forEach((item) => {
      const key =
        item.productivity.charAt(0).toLowerCase() + item.productivity.slice(1);
      dataByDate[date][key] = item.totals[index] || 0;
    });
  });

  // Transform dataByDate into the desired format
  const chartData = Object.keys(dataByDate)
    .map((date) => ({
      date,
      data: {
        productive: dataByDate[date].productive || 0,
        unproductive: dataByDate[date].unproductive || 0,
        undefined: dataByDate[date].undefined || 0,
        offlineMeetings: dataByDate[date].offline || 0,
        productiveActive: dataByDate[date].productiveActive || 0,
        productivePassive: dataByDate[date].productivePassive || 0,
        unproductiveActive: dataByDate[date].unproductiveActive || 0,
        unproductivePassive: dataByDate[date].unproductivePassive || 0,
        undefinedActive: dataByDate[date].undefinedActive || 0,
        undefinedPassive: dataByDate[date].undefinedPassive || 0
      }
    }))
    .filter((item) => item.date !== undefined);

  return chartData;
};

// Modify chart data to have a specific order
export const sortChartData = (
  chartData: ChartData<'bar'>
): ChartData<'bar'> => {
  const productivityOrder = [
    ProductivityName.Productive,
    ProductivityName.ProductiveActive,
    ProductivityName.ProductivePassive,
    ProductivityName.Unproductive,
    ProductivityName.UnproductiveActive,
    ProductivityName.UnproductivePassive,
    ProductivityName.Undefined,
    ProductivityName.UndefinedActive,
    ProductivityName.UndefinedPassive,
    ProductivityName.OfflineMeetings
  ];

  const modifiedChartData = {
    ...chartData,
    datasets: [...chartData.datasets].sort((a, b) => {
      const aIndex = productivityOrder.indexOf(a.label as ProductivityName);
      const bIndex = productivityOrder.indexOf(b.label as ProductivityName);
      return bIndex - aIndex;
    })
  };
  return modifiedChartData;
};

// Generate legend items for the chart
export const generateLegendItems = (
  chartData: ChartData<'bar'>,
  setChartData: (data: ChartData<'bar'>) => void
): ILegendItem[] => {
  if (!chartData || !chartData.datasets) {
    return [];
  }

  return chartData.datasets
    .map((dataset, index) => {
      const IconComponent =
        ProductivityIcons[dataset.label as ProductivityName]?.icon;
      const iconColor =
        ProductivityIcons[dataset.label as ProductivityName]?.color;

      if (!IconComponent) {
        return null;
      }

      return {
        icon: <IconComponent style={{ color: iconColor, marginRight: 4 }} />,
        label: dataset.label as string,
        hidden: dataset.hidden || false,
        onClick: () => {
          const updatedDatasets = chartData.datasets.map((d, i) => {
            if (i === index) {
              return { ...d, hidden: !d.hidden };
            }
            return d;
          });
          setChartData({ ...chartData, datasets: updatedDatasets });
        }
      };
    })
    .filter((item) => item !== null) as ILegendItem[];
};

export const getPassiveTime = (context): string => {
  if (!context || context.length === 0 || !context[0].dataset.passiveTimes) {
    return '';
  }
  const passiveTime = context[0].dataset.passiveTimes[context[0].dataIndex];
  return getStringDurationFormat(passiveTime, false);
};

export const getFilteredKeys = (currentView: ReportViewTypes) => {
  if (currentView === ReportViewTypes.SUMMARY) {
    return Object.values(ProductivitySummaryLegendLabels);
  } else if (currentView === ReportViewTypes.DETAILED) {
    return Object.values(ProductivityDetailLegendLabels);
  }
  return [];
};

export const getDatasets = (data: any[], keys: string[], colors: any) => {
  const labels = data.map((item) => {
    if (item.date.includes('\n')) {
      return item.date
        .split('\n')
        .map((date) => moment(date).format(getDateFormat()));
    } else {
      return moment(item.date).format(getDateFormat());
    }
  });

  const datasets = keys.map((key) => {
    const normalizedLabel = key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
    return {
      label: normalizedLabel,
      data: data.map((item) => item.data[key] || 0),
      backgroundColor: colors[key] || colors[normalizedLabel],
      passiveTimes: data.map((item) => item.data[`${key}Passive`] || 0)
    };
  });

  return { datasets, labels };
};

// Determine the available periods for the report in the ProductivityAggregationsButtons component
export const determineAvailablePeriods = (reportFilters: IReportFilters) => {
  const { dates } = reportFilters;
  const { _fromDate, _toDate } = dates;

  const start = moment(_fromDate);
  const end = moment(_toDate);
  const diff = end.diff(start, 'days') + 1;

  return [
    AggregationPeriod.Day,
    diff >= 7 && AggregationPeriod.Week,
    diff >= 30 && AggregationPeriod.Month,
    diff >= 180 && AggregationPeriod.Year
  ].filter(Boolean) as AggregationPeriod[];
};
