import { FeatureState } from '../../../enums/FeatureState';
import { IMenuItem } from '../model';

export const NavigationFeatureBadge = (item: IMenuItem) => {

  return item?.beta || item?.earlyAccess ? (
    <div
      className="at-menu-item-beta"
      style={{
        ['marginTop']: '2px',
        ['marginLeft']: `${item.level === 0 ? '-2px' : '2px'}`
      }}
    >
      <div className="label-content">
        {item?.beta
          ? FeatureState.Beta.toUpperCase()
          : FeatureState.EA.toUpperCase()}
      </div>
    </div>
  ) : (
    <></>
  );
};
