import {
  EMAIL_ALREADY_ASSOCIATED,
  API_EMAIL_NOT_UNIQUE,
  API_EMPLOYEE_ID_NOT_UNIQUE,
  API_DISPLAY_NAME_NOT_UNIQUE,
  DISPLAY_IN_USE,
  EMPLOYEE_ID_IN_USE,
  REVISION_NO_MATCH,
  DATA_STALE,
  DEVICE_LOGON_ALREADY_ASSOCIATED,
  ALREADY_ASSOCIATED,
  API_DEVICE_LOGON_NOT_UNIQUE,
  DEVICE_LOGON_NOT_UNIQUE
} from '../constants/errorMessages.constants';
import {
  IValueValidation,
  IUserIdentity,
  IDeviceLogon
} from '../models/IUserIdentity';
import { IUserIdentityEditDto } from '../models/IUserIdentityDto';
import { trimValueOrEmptyString } from './TrimUtils';

export function CreateDetailErrorMessage(error: any): string {
  const errorData = error?.data?.value;
  if (errorData == API_EMAIL_NOT_UNIQUE) {
    SetErrorEmail(
      EMAIL_ALREADY_ASSOCIATED,
      error?.data?.userIdentity,
      error?.data?.fieldEdit
    );
    return EMAIL_ALREADY_ASSOCIATED;
  }
  if (errorData == API_EMPLOYEE_ID_NOT_UNIQUE) {
    return EMPLOYEE_ID_IN_USE;
  }
  if (errorData == API_DISPLAY_NAME_NOT_UNIQUE) {
    return DISPLAY_IN_USE;
  }
  if (errorData.indexOf(REVISION_NO_MATCH) >= 0) {
    return DATA_STALE;
  }
  if (errorData == DEVICE_LOGON_ALREADY_ASSOCIATED) return ALREADY_ASSOCIATED;

  if (errorData.indexOf(API_DEVICE_LOGON_NOT_UNIQUE) >= 0) {
    return DEVICE_LOGON_NOT_UNIQUE;
  }

  return 'Unable to save:' + errorData;
}

export function SetUserIdentityErrorMessage(
  errorMessage: string,
  userIdentity: IUserIdentity,
  fieldEdit: IUserIdentityEditDto
): boolean {
  let setValidation = false;
  ClearEditErrors(userIdentity);
  if (errorMessage && userIdentity && fieldEdit) {
    switch (errorMessage) {
      case EMAIL_ALREADY_ASSOCIATED:
        setValidation = SetErrorEmail(errorMessage, userIdentity, fieldEdit);
        break;
      case DISPLAY_IN_USE:
        if (userIdentity.displayName) {
          userIdentity.displayName.validationFailed = true;
          userIdentity.displayName.failedExplanation = errorMessage;
          setValidation = true;
        }
        break;
      case EMPLOYEE_ID_IN_USE:
        setValidation = SetErrorEmployeeId(
          errorMessage,
          userIdentity,
          fieldEdit
        );
        break;
      case ALREADY_ASSOCIATED:
        setValidation = SetErrorDeviceLogon(
          errorMessage,
          userIdentity,
          fieldEdit
        );
        break;
    }
  }
  return setValidation;
}

function SetErrorEmployeeId(
  errorMessage: string,
  userIdentity: IUserIdentity,
  fieldEdit: IUserIdentityEditDto
): boolean {
  if (userIdentity?.employeeIds) {
    let idToChange: IValueValidation = null;
    fieldEdit.employeeIds?.forEach((editEmployeeId) => {
      idToChange = userIdentity.employeeIds.find(
        (employeeId) => employeeId.value == editEmployeeId.value
      );
    });
    if (idToChange) {
      idToChange.validationFailed = true;
      idToChange.failedExplanation = errorMessage;
      return true;
    }
  }
  return false;
}

const getUserLogonCsv = (user: string, logonDomain: string): string => {
  const trimmedUser = trimValueOrEmptyString(user);
  const trimmedDomain = trimValueOrEmptyString(logonDomain);
  return trimmedUser && trimmedUser.length > 0
    ? user + ',' + trimmedDomain
    : null;
};

function SetErrorDeviceLogon(
  errorMessage: string,
  userIdentity: IUserIdentity,
  fieldEdit: IUserIdentityEditDto
): boolean {
  if (userIdentity?.deviceLogons) {
    let idToChange: IDeviceLogon = null;
    fieldEdit.deviceLogons?.forEach((editDl) => {
      idToChange = userIdentity.deviceLogons.find(
        (existingDl) =>
          getUserLogonCsv(existingDl.user, existingDl.logonDomain) ==
          editDl.value
      );
    });
    if (idToChange) {
      idToChange.validationFailed = true;
      idToChange.failedExplanation = errorMessage;
      return true;
    }
  }
  return false;
}

function SetErrorEmail(
  errorMessage: string,
  userIdentity: IUserIdentity,
  fieldEdit: IUserIdentityEditDto
): boolean {
  if (userIdentity?.emails) {
    let idToChange: IValueValidation = null;
    fieldEdit.emails?.forEach((email) => {
      idToChange = userIdentity.emails.find(
        (emailId) => emailId.value == email.value
      );
    });
    if (idToChange) {
      idToChange.validationFailed = true;
      idToChange.failedExplanation = errorMessage;
      return true;
    }
  }
  return false;
}

export function ClearEditErrors(userIdentity: IUserIdentity) {
  if (userIdentity?.employeeIds) {
    userIdentity.employeeIds.forEach((employeeId) => {
      employeeId.validationFailed = false;
      employeeId.failedExplanation = '';
    });
  }
  if (userIdentity?.displayName) {
    userIdentity.displayName.validationFailed = false;
    userIdentity.displayName.failedExplanation = '';
  }
}
