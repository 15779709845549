import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Role, SortDirection } from '../../common/enums';
import { digestService } from '../../common/services';
import { useDigestState } from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { useAuthorization } from '../../common/services/Authorization';

import { useDigestColumns } from '../utils';
import { Grid } from '@mui/material';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import DigestSearchBar from '../components/DigestSearchBar';
import { IDigestSettingPost } from '../../common/models';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

const DigestView = () => {
  const [filter, setFilter] = useState<string>();

  const service = digestService();
  const digestsState = useDigestState({ service });
  const notificationService = useNotifications();
  const authorizationService = useAuthorization();


  const {
    digests,
    isLoading,
    digestsNotification,
    setDigestsNotification,
    init,
    setSortedDigests,
    searchDigests,
    updateDigestSettings
  } = digestsState;

  const isDisabled = useRef(
    !authorizationService.hasAnyRole([
      Role.Configurator,
      Role.Admin,
      Role.SuperAdmin
    ])
  );

  const handleDigestSettingToggle = useCallback(
    (event, userId: number): void => {
      const update: IDigestSettingPost = {
        userId: userId,
        digest: event.target?.checked
      };
      updateDigestSettings([update]);
    },
    [updateDigestSettings]
  );

  const columns = useDigestColumns({ handleDigestSettingToggle, isDisabled });

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    searchDigests(filter);
  }, [filter, searchDigests]);

  useEffect(() => {
    if (digestsNotification?.msg && digestsNotification?.type) {
      notificationService[digestsNotification.type](digestsNotification.msg);
      setDigestsNotification(undefined);
    }
  }, [
    notificationService,
    digestsNotification?.msg,
    digestsNotification?.type,
    setDigestsNotification
  ]);

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        md={4}
        xl={6}
        sx={{ display: { xs: 'none', md: 'block' } }}
      ></Grid>
      <Grid item xs={12} md={8} xl={6}>
        <DigestSearchBar filter={filter} setFilter={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <CustomGrid
          height={'65vh'}
          isVirtualized={true}
          isLoading={isLoading}
          data={digests}
          columns={columns}
          initialSortField="username"
          initialSortDirection={SortDirection.Ascending}
          onSortOrder={setSortedDigests}
        />
      </Grid>
    </Grid>
  );
};

export default DigestView;

export const DigestViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DigestView />
  </ReactivTrakComponentWrapper>
);
