import React from 'react';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { TimeAgo } from '../../common/components/TimeAgo';
import { getTimeLapsedSinceNowString } from '../../common/utils/datetime/datetimeCalculations';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';

export const useSelectedAgentColumns = (): IGridColumn[] => {
  //IAgentSearch is dataItem
  return [
    {
      field: 'userName',
      headerName: 'Username',
      isSortable: false,
      type: 'string',
      width: '25%',
      template: (dataItem) => {
        return <>{dataItem ? dataItem.userName : ''}</>;
      }
    },
    {
      field: 'logonDomain',
      headerName: 'Logon Domain',
      type: 'string',
      isSortable: false,
      width: '25%',
      template: (dataItem) => {
        return <>{dataItem ? dataItem.logonDomain : ''}</>;
      }
    },

    {
      field: 'firstLog',
      headerName: 'First Log',
      type: 'date',
      isSortable: false,
      width: '25%',
      template: (dataItem) => {
        return <>{dataItem ? formatDateTime(dataItem.firstLog) : ''}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log',
      type: 'date',
      isSortable: false,
      width: '25%',
      template: (dataItem) => {
        if (dataItem) {
          const timeAgo = getTimeLapsedSinceNowString(dataItem.lastLog);
          return (
            <TimeAgo
              lastLog={dataItem.lastLog}
              learnMoreLink="https://support.activtrak.com/"
              timeAgo={timeAgo}
            />
          );
        }
        return <></>;
      }
    }
  ];
};
