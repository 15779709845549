import _ from 'lodash';
import {
  Role,
  ClaimType,
  PermissionLevel,
} from '../../enums';
import {
  IToken,
  IAuthorizationService
} from '../../models';

import authorizationService from '../../helpers/authorization';
export default class AuthorizationService implements IAuthorizationService {

  public isImpersonating: boolean;

  constructor(token: IToken) {
    const imposter = this.getClaimsByType(token, ClaimType.Imposter);
    this.isImpersonating = imposter !== null && imposter.length > 0;
    
  }

  public hasRole(role: Role): boolean {
    return authorizationService.hasRole(role.toString().toLowerCase());
  }

  public hasFeature(featureFlag: string): boolean {
    return authorizationService.hasFeature(featureFlag);
  }



  public getClaimsByType(token: IToken, claimType: string): string[] {
    return (
      token?.Claims?.filter((c) => c.Type === claimType.toString()).map(
        (c) => c.Value
      ) ?? []
    );
  }

  public hasAnyRole(roles: Role[]) {
    return _.find(roles, (role) => this.hasRole(role)) !== undefined;
  }

  public hasAuthorizationLevel(
    levels: PermissionLevel[],
    routeName: string,
  ): boolean {
    return authorizationService.hasAuthorizationLevel(
      levels,
      routeName
    );
  }

  public canDeleteGroup(): boolean {
    return this.hasAnyRole([
      Role.Admin,
      Role.SuperAdmin,
      Role.SupportAdvanced,
      Role.Configurator
    ]);
  }

  public isSupportUser(): boolean {
    return (
      this.isImpersonating ||
      this.hasAnyRole([
        Role.SupportBasic,
        Role.SupportIntermediate,
        Role.SupportAdvanced,
        Role.SuperAdmin
      ])
    );
  }
}
