import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { secondaryFontSize } from '../../common/constants';

export const useTopUserGroupStyles = {
  headerGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    flexWrap: 'wrap'
  },
  toggleButtonGroup: {
    alignItems: 'center'
  },
  toggleButtonLeft: {
    borderRadius: '4px 0px 0px 4px'
  },
  toggleButtonRight: {
    borderRadius: '0px 4px 4px 0px'
  }
};

export const BarGrid = styled(Grid)`
  display: flex;
  &.MuiGrid-root {
    max-width: 95%;
  }
`;

export const BarsExpandCollapse = styled(Button)`
  span.MuiButton-label {
    font-size: 13px;
  }
  &.MuiButton-root:hover {
    background-color: white;
  }
`;

export const SortFilterText = styled(Typography)`
  font-size: ${secondaryFontSize} !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

//TODO: prefer to pass dynamic height based on how many bars are rendered
export const TopUsersContainer = styled(Box)`
  & canvas {
    max-height: 400px;
  }
`;
