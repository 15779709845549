import { handleStarToggle } from './handleStarToggle';
import { MenuItemLabel } from './SideBar.styles';
import { FavoritesStar } from './FavoritesStar';
import { isExcludedPage } from '../navigationStore';
import { redirectToAcademy } from './redirectToAcademy';

export const ActivtrakAcademyNavigationItem = (props) => {
  const { item, index, isInFavorites } = props;

  const showStar = isInFavorites({ id: item.id });

  return (
    <li id={`id_${item.id}`} key={index}>
      <div className="at-menu-item-container">
        <div className="at-menu-item-row">
          <a
            className="at-flex-container at-menu-item at-pointer"
            onClick={async (e) => {
              e.preventDefault();
              await redirectToAcademy();
            }}
          >
            <div className="at-flex-child at-menu-item-icon"></div>
            <MenuItemLabel level={item.level} className="at-flex-child">
              {item.label}
              {!item.submenus && !isExcludedPage(item.label) && (
                <FavoritesStar
                  id={`nav_star_${item.id}`}
                  favorited={showStar}
                  onClick={(e) => handleStarToggle(e, item)}
                />
              )}
            </MenuItemLabel>
          </a>
        </div>
      </div>
    </li>
  );
};
