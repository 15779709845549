'use strict';

import { templateServiceFunctions } from '_app/serviceFunctions/templateServiceFunctions';
import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';
import { browserServiceFunctions } from '_app/serviceFunctions/browserServiceFunctions';
import { http } from '_app/http';
import { gridServiceFunctions } from '_app/serviceFunctions/gridServiceFunctions';
import authorizationService from '../../_reactivtrak/src/common/helpers/authorization';
import { BundleFlag } from '../../_reactivtrak/src/common/enums/BundleFlag';
import { FeatureFlag } from '../../_reactivtrak/src/common/enums/FeatureFlag';
import { generateParameters } from '../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import { setExportParamsStore } from '../../_reactivtrak/src/common/stores/exportsStore/exportParamsStore';

let _dataSource;
let _cacheKey;
let _gridName = 'working-hours-grid';
let _reportFilters = {};

const generateDataSource = (apiUrl, notificationService, scope) => {
    return new kendo.data.DataSource({
        transport: {
            read: (options) => {
                const { data } = options;
                const { reportFilters, ...filteredData } = data;
                _reportFilters = reportFilters ?? _reportFilters;
                scope.isLoading = true;

                http.get(apiUrl + generateParameters(_reportFilters, {}), {
                    data: filteredData
                })
                    .then((results) => {
                        const data = results.data;
                        scope.isLoading = false;
                        scope.noResults = !data?.data || data?.data?.length === 0;
                        _cacheKey = data?.cacheKey;
                        options.success(data);
                    })
                    .catch((e) => {
                        options.error(e);
                        scope.isLoading = false;
                        const errorMessage = 'An error occurred while loading the Working Hours report.';
                        notificationService.showNotification(errorMessage, 'danger');
                        console.error(`ActivTrak Error: ${errorMessage}`, e);
                    });
            }
        },
        pageSize: 150,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        schema: {
            data: 'data',
            total: 'total'
        }
    });
};

const gridHeight = () => {
    return 220;
};

const generateTooltipOptions = () => {
    return templateServiceFunctions.getTooltipOptions({
        filter: 'td.text-nowrap:not(.no-tooltip)',
        position: 'right'
    });
};

const arrayToStringTemplate = (array, type, dataItem) => {
    if (!array || typeof array.forEach !== 'function') {
        return array;
    }

    let string = '';
    dataItem.escapedNames = dataItem.escapedNames || {};
    dataItem.escapedNames[type] = [];

    array.forEach(function (item, index) {
        const escapedName = browserServiceFunctions.htmlEscape(item);
        dataItem.escapedNames[type][index] = escapedName;
        const separator = index === array.length - 1 ? '' : ', ';

        if (type === 'group') {
            string += escapedName + separator;
        } else {
            string +=
                '<a class="at-pointer" ng-click="goToActivityLog(' +
                index +
                ", '" +
                type +
                '\', dataItem)">' +
                escapedName +
                separator +
                '</a>';
        }
    });

    return string;
};

const locationHeaderTemplate = () => {
    const isFeatureEnabled = authorizationService.hasFeature(BundleFlag.LocationData);

    const html = `
      <div class="location-column-header">
        <div>Location
          <span style="padding-left: 5px;">
            <i-tooltip placement="top" top-offset="4px" template="createLocationTooltipTemplate" ng-show="${isFeatureEnabled}"></i-tooltip>
          </span>
        </div>
        <div>
          <button class="enable-now-button" ng-click="showLocationPromoModal()" ng-hide="${isFeatureEnabled}" id="8b666e7b-e4b6-4c43-b258-7c70c4e84368">Enable Now</button>
        </div>
      </div>
    `;

    return html;
};

const offlineMeetingsHeaderTemplate = () => {
    const isFeatureEnabled = authorizationService.hasFeature(BundleFlag.OfflineMeetingsData);

    const title = isFeatureEnabled ? 'Offline<br/>Meetings' : 'Offline Meetings';

    const html = `
      <div>
        <div class="offline-meetings-column-header">
          <div>${title}</div>
            <span style="padding-left: 5px;">
            <i-tooltip placement="top" top-offset="4px" template="createTooltipTemplate" ng-show="${isFeatureEnabled}"></i-tooltip>
            </span>
        </div>
        <div>
          <button class="enable-now-button" ng-click="showOfflineMeetingsPromoModal()" ng-hide="${isFeatureEnabled}" id="29c35d21-8954-4698-8ef6-3821f690b912">Enable Now</button>
        </div>
    </div>
    `;

    return html;
};

const showColumnForFeature = (dataBundleFlag, promoBundleFlag, featureFlag) => {
    const hasDataBundleFlag = authorizationService.hasFeature(dataBundleFlag);
    const hasPromoBundleFlag = authorizationService.hasFeature(promoBundleFlag);
    const hasFeatureFlag = authorizationService.hasFeature(featureFlag);
    const canAccessUpgrade = authorizationService.hasRouteByName('app.account.upgrade');

    const hasColumnBundleFlag = hasDataBundleFlag || (hasPromoBundleFlag && canAccessUpgrade);

    return hasColumnBundleFlag && hasFeatureFlag;
};

const generateGridColumns = () => {
    let columns = [
        {
            field: 'date',
            title: 'Date',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return atHelperFunctions.formatDate(dataItem.date, {
                    onlyDate: true,
                    span: false
                });
            }),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: 100
        },
        {
            field: 'groups',
            title: 'Groups',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return arrayToStringTemplate(dataItem.groups, 'group', dataItem);
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: 180
        },
        {
            field: 'computers',
            title: 'Computer',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return arrayToStringTemplate(dataItem.computers, 'computer', dataItem);
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: 180
        },
        {
            field: 'users',
            title: 'User',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return arrayToStringTemplate(dataItem.users, 'user', dataItem);
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: 180
        },
        {
            field: 'location',
            title: 'Location',
            headerTemplate: locationHeaderTemplate(),
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                const hasFeatureEnabled = authorizationService.hasFeature(BundleFlag.LocationData);
                const isTodayDate = atHelperFunctions.isToday(dataItem.date);
                if (!dataItem.location || !hasFeatureEnabled || isTodayDate) return '';

                return dataItem.location;
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: 120,
            minResizableWidth: 120,
            removeColumn: !showColumnForFeature(
                BundleFlag.LocationData,
                BundleFlag.LocationPromo,
                FeatureFlag.ShowLocationData
            )
        },
        {
            field: 'firstActivity',
            title: 'First<br/>Activity',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return atHelperFunctions.formatDate(dataItem.firstActivity, {
                    onlyTime: true,
                    span: false
                });
            }),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: 100
        },
        {
            field: 'lastActivity',
            title: 'Last<br/>Activity',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return atHelperFunctions.formatDate(dataItem.lastActivity, {
                    onlyTime: true,
                    span: false
                });
            }),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: 100
        },
        {
            field: 'lastActivityLog',
            title: 'Last<br/>Activity Log',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return atHelperFunctions.formatDate(dataItem.lastActivityLog, {
                    span: false
                });
            }),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: 180
        },
        {
            field: 'productiveTime',
            title: 'Productive',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.productiveTime, 1);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'productiveActiveTime',
            title: 'Productive<br/>Active',
            filterable: false,
            hidden: true,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.productiveActiveTime, 1);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'productivePassiveTime',
            title: 'Productive<br/>Passive',
            filterable: false,
            hidden: true,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.productivePassiveTime, 2);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'unproductiveTime',
            title: 'Unproductive',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.unproductiveTime, -1);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'unproductiveActiveTime',
            title: 'Unproductive<br/>Active',
            filterable: false,
            hidden: true,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.unproductiveActiveTime, -1);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'unproductivePassiveTime',
            title: 'Unproductive<br/>Passive',
            filterable: false,
            hidden: true,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.unproductivePassiveTime, -2);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'undefinedTime',
            title: 'Undefined',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.undefinedTime, 0);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'undefinedActiveTime',
            title: 'Undefined<br/>Active',
            filterable: false,
            hidden: true,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.undefinedActiveTime, 0);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'undefinedPassiveTime',
            title: 'Undefined<br/>Passive',
            filterable: false,
            hidden: true,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.productivityTimeTemplate(dataItem.undefinedPassiveTime, -3);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120
        },
        {
            field: 'totalTime',
            title: 'Total<br/>Time',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.friendlyViewTimeFormat(dataItem.totalTime);
            }),
            attributes: {
                class: 'text-nowrap text-right'
            },
            width: 100
        },
        {
            field: 'activeTime',
            title: 'Active<br/>Time',
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                return templateServiceFunctions.friendlyViewTimeFormat(dataItem.activeTime);
            }),
            attributes: {
                class: 'text-nowrap text-right'
            },
            width: 100
        },
        {
            field: 'totalTimeOffline',
            title: 'Offline Meetings',
            headerTemplate: offlineMeetingsHeaderTemplate(),
            filterable: false,
            sortable: false,
            template: kendo.template((dataItem) => {
                const hasFeatureEnabled = authorizationService.hasFeature(BundleFlag.OfflineMeetingsData);
                const isTodayDate = atHelperFunctions.isToday(dataItem.date);
                if (!hasFeatureEnabled || isTodayDate) return '';

                return templateServiceFunctions.productivityTimeTemplate(dataItem.totalTimeOffline || 0, -4);
            }),
            attributes: {
                class: 'text-nowrap text-right no-tooltip'
            },
            width: 120,
            minResizableWidth: 120,
            removeColumn: !showColumnForFeature(
                BundleFlag.OfflineMeetingsData,
                BundleFlag.OfflineMeetingsPromo,
                FeatureFlag.ShowOfflineMeetingData
            )
        }
    ];
    return atHelperFunctions.filterDefaultColumns(columns);
};

const initializeGridOptions = (apiUrl, notificationService, scope) => {
    _dataSource = generateDataSource(apiUrl, notificationService, scope);

    return generateGridOptions();
};

const generateGridOptions = () => {
    return {
        autoBind: false,
        dataSource: _dataSource,
        filterable: {
            mode: 'row'
        },
        sortable: true,
        columns: gridServiceFunctions.loadSavedGridOptions(generateGridColumns(), _gridName),
        resizable: true,
        reorderable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight(gridHeight()),
        dataBound: (e) => {
            const exportParams = {
                hasDataForTable: e.sender.dataSource.total() > 0
            };

            setExportParamsStore(exportParams);
            gridServiceFunctions.bindGridSave(e.sender, _gridName);
            e.sender.resizeGrid();
        }
    };
};

const resetGridOptions = (grid) => {
    gridServiceFunctions.resetSavedGridOptions(grid, _gridName, generateGridOptions);
};

const getCacheKey = () => _cacheKey;

export const workingHoursFunctions = {
    gridHeight: gridHeight,
    generateTooltipOptions: generateTooltipOptions,
    initializeGridOptions: initializeGridOptions,
    resetGridOptions: resetGridOptions,
    getCacheKey: getCacheKey
};
