import React from 'react';
import BulkFilterMenuExpandedProps from './BulkFilterMenuExpanded.models';
import { NestedList } from '../../../common/components/Filter/NestedList';
import Popover from '@mui/material/Popover';
import { ButtonSC } from './BulkFilterMenu.styles';

export const BulkFilterMenuExpanded = (props: BulkFilterMenuExpandedProps) => {
  const {
    className,
    icon,
    menuOptions,
    onSubmitFilters,
    isIconRightAligned,
    hideselectedoption,
    disabled
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (filters) => {
    onSubmitFilters(filters);
    handleClose();
  };

  return (
    menuOptions && (
      <div className={className}>
        {/* NOTE: "hideselectedoption" - Properties that are not inherent to a component need to be lowercase. And if custom boolean property values are expected to be strings (in unit tests), you can force a boolean by passing the binary value instead. */}
        <ButtonSC
          id="bulk-filter-button"
          onClick={handleToggle}
          startIcon={isIconRightAligned ? null : icon}
          endIcon={isIconRightAligned ? icon : null}
          disabled={disabled}
          hideselectedoption={hideselectedoption ? 1 : 0}
        />

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <NestedList filterOptions={menuOptions} onSubmit={handleSubmit} />
        </Popover>
      </div>
    )
  );
};
