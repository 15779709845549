import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { getHealthStatusColor } from './health.utils';
import {
  AgentHealthStatusCircle,
  AgentVersionHeaderTableCell,
  GridRowActionButtonContainer
} from '../styles';
import { filterAndRepeatDataBySpecificParamsAndQuery } from '../../common/utils/filterByQuery';
import moment from 'moment';
import React from 'react';
import { getOSIcon } from '../../common/components/OsIcon';
import { TimeAgo } from '../../common/components/TimeAgo/TimeAgo';
import Tooltip from '@mui/material/Tooltip';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { IComputerAgentState } from '../models';
import { COMPUTERS_INITIAL_VIEW_STATE } from './Computers.view.utils';
import { IAuthorizationService } from '../../common/models';
import { Role } from '../../common/enums';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';
import {
  getTimeLapsedSinceNowString,
  getTimeLapsedSinceNowStringByTimezone
} from '../../common/utils/datetime/datetimeCalculations';

const ActionColumnTemplate = (
  dataItem,
  userIsSuperAdminOrAdmin: boolean,
  onChangeComputerOption: (dataItem: any, option?: any) => Promise<void>
) => {
  const { selected } = dataItem;
  return (
    <GridRowActionButtonContainer>
      <DropdownOptionsButton
        id="78bbb98d-ec86-429b-a4c3-ee6e0e0be52a"
        icon={<MoreVertIcon />}
        dropDownOptions={[{ label: 'Delete' }, { label: 'Uninstall Agent' }]}
        onChangeOption={(
          event: React.MouseEvent<HTMLLIElement>,
          option?: any
        ) => onChangeComputerOption(dataItem, option)}
        hideSelectedOption
        disableSelectedItem
        disabled={selected || !userIsSuperAdminOrAdmin}
        sx={{ width: '24px', minWidth: 'auto' }}
      />
    </GridRowActionButtonContainer>
  );
};

export const computerGridColumnsBasic = (
  computerAgentState: IComputerAgentState,
  authorization: IAuthorizationService
): IGridColumn[] => {
  const userIsSuperAdminOrAdmin = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin
  ]);
  const basicColumns = [
    {
      field: 'id',
      fieldName: 'id',
      headerName: 'ID',
      type: 'string',
      isSortable: true,
      width: '7%'
    },
    {
      field: 'domainComputerAlias',
      fieldName: 'domainComputerAlias',
      headerName: `Primary Domain${String.fromCharCode(92)}Computer (Alias)`,
      type: 'string',
      isSortable: true,
      width:
        computerAgentState.screenshotsAllowed &&
        computerAgentState.hasAlarmsScreenshotsReportsRoleAccess
          ? '28%'
          : '43%'
    },
    {
      field: 'agentVersion',
      headerName: 'Agent Version',
      type: 'date',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return (
          <AgentVersionHeaderTableCell>
            {getOSIcon(dataItem.os)} <span>{dataItem.agentVersion}</span>
          </AgentVersionHeaderTableCell>
        );
      }
    },
    {
      field: 'firstLog',
      headerName: 'First Log Record',
      type: 'date',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        let firstLog = dataItem.firstLog;
        if (computerAgentState.softDeletesEnabled) {
          firstLog = moment(dataItem.firstLog).toDate();
        }
        return <>{formatDateTime(firstLog)}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log Record',
      isSortable: true,
      type: 'date',
      width: '15%',
      template: (dataItem) => {
        const { lastLog } = dataItem;
        const timeAgo = computerAgentState.softDeletesEnabled
          ? getTimeLapsedSinceNowString(lastLog)
          : getTimeLapsedSinceNowStringByTimezone(lastLog);

        return (
          <TimeAgo
            lastLog={lastLog}
            learnMoreLink="https://support.activtrak.com/hc/en-us/articles/4407833915803-Managing-Computer-Agents"
            timeAgo={timeAgo}
          />
        );
      }
    },
    {
      field: 'lastss',
      headerName: 'Last Screenshot',
      isSortable: true,
      hidden:
        (!computerAgentState.screenshotsAllowed ||
          !computerAgentState.hasAlarmsScreenshotsReportsRoleAccess) ??
        true,
      type: 'date',
      width: '15%',
      template: (dataItem) => {
        const { lastss } = dataItem;
        const timeAgo =
          computerAgentState.softDeletesEnabled && lastss
            ? getTimeLapsedSinceNowString(lastss)
            : getTimeLapsedSinceNowStringByTimezone(lastss);

        return (
          <Tooltip title={formatDateTime(dataItem.lastss)} placement="bottom">
            <div>{timeAgo}</div>
          </Tooltip>
        );
      }
    },
    {
      type: 'string',
      isSortable: false,
      width: '5%',
      template: (dataItem) => {
        return ActionColumnTemplate(
          dataItem,
          userIsSuperAdminOrAdmin,
          computerAgentState.onChangeComputerOption
        );
      }
    }
  ];
  return basicColumns;
};

export const computerGridColumns = (
  computerAgentState: IComputerAgentState,
  authorization: IAuthorizationService
): IGridColumn[] => {
  const basicColumns = computerGridColumnsBasic(
    computerAgentState,
    authorization
  );

  const showAgentHealthColumn =
    authorization.hasAnyRole([Role.SuperAdmin, Role.SupportBasic]) ||
    (authorization.hasAnyRole([Role.Admin]) &&
      authorization.hasFeature('isAgentHealthAdminEnabled'));

  if (!showAgentHealthColumn) {
    return basicColumns;
  }
  const columnAgentStatus: IGridColumn = {
    field: 'globalStatus',
    fieldName: 'globalStatus',
    headerName: 'Status',
    type: 'string',
    isSortable: true,
    width: '5%',
    template: (dataItem) => {
      return (
        <>
          {!computerAgentState.healthIsLoading && (
            <Box onClick={(e) => computerAgentState.onStatusClick(dataItem, e)}>
              <AgentHealthStatusCircle
                color={getHealthStatusColor(dataItem.globalStatus)}
              />
            </Box>
          )}
          {computerAgentState.healthIsLoading && (
            <Box>
              <CircularProgress size="25px" />
            </Box>
          )}
        </>
      );
    },
    hidden: null
  };
  const newColumns = [...basicColumns];
  newColumns.splice(6, 0, columnAgentStatus);
  return newColumns;
};

export const getFilteredComputersForAutocomplete = (
  computerAgentState: IComputerAgentState,
  authorization: IAuthorizationService
) => {
  const filteredData = filterAndRepeatDataBySpecificParamsAndQuery(
    computerAgentState.computers,
    computerGridColumns(computerAgentState, authorization),
    COMPUTERS_INITIAL_VIEW_STATE.searchBarFilterParams,
    computerAgentState.searchTerm
  );

  const newDataMap = {};
  const uniqueGroups = {};
  for (let i = 0; filteredData.length > i; i++) {
    const { id, type, label } = filteredData[i];
    if (type !== COMPUTERS_INITIAL_VIEW_STATE.searchBarFilterParams[0]) {
      newDataMap[id + type] = filteredData[i];
    } else {
      if (!uniqueGroups[label]) {
        newDataMap[id + type] = filteredData[i];
        uniqueGroups[label] = true;
      }
    }
  }

  return Object.keys(newDataMap).map(function (key) {
    return newDataMap[key];
  });
};
