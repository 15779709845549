import React from 'react';
import { durationFormat } from '../../common/utils/datetime/time';
import ActivityUrl from '../../common/components/ActivityUrl/ActivityUrl';
import { ClassificationColumns } from '../models';
import CategoryDropdown from '../components/CategoryDropdown';
import ProductivityDropdown from '../components/ProductivityDropdown';

export const useClassificationColumns = (props: ClassificationColumns) => {
  const { categories, onChange, isTruncated, isDisabled } = props;
  return [
    {
      field: 'name',
      fieldName: 'Name',
      headerName: 'Name',
      type: 'string',
      isSortable: true,
      width: '25%',
      template: (dataItem) => {
        return (
          <ActivityUrl
            activity={dataItem}
            isDomain={true}
            isTruncated={isTruncated}
          />
        );
      }
    },
    {
      field: 'statusDisplay',
      fieldName: 'Classification Type',
      headerName: 'Classification Type',
      type: 'string',
      isSortable: true,
      width: '25%',
      template: (dataItem) => {
        const { statusDisplay } = dataItem;
        return statusDisplay;
      }
    },
    {
      field: 'categoryName',
      fieldName: 'Category',
      headerName: 'Category',
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        const { categoryId, disabled } = dataItem;
        return (
          categories && (
            <CategoryDropdown
              categoryId={categoryId}
              onChange={(e) => onChange(e, dataItem, categories)}
              categories={categories}
              isDisabled={disabled || isDisabled}
              variant="standard"
            />
          )
        );
      }
    },
    {
      field: 'productivity',
      fieldName: 'Status',
      headerName: 'Status',
      type: 'number',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        const { productivity, categoryId, disabled } = dataItem;

        return (
          <ProductivityDropdown
            productivityStatus={productivity}
            onChange={(e) => onChange(e, dataItem, categories)}
            categoryId={categoryId}
            isDisabled={disabled || isDisabled}
            variant="standard"
          />
        );
      }
    },
    {
      field: 'duration',
      fieldName: 'Duration',
      headerName: 'Duration',
      type: 'number',
      isSortable: true,
      width: '10%',
      align: 'right',
      template: (dataItem) => {
        const { duration } = dataItem;
        return durationFormat(duration, true);
      }
    }
  ];
};
