import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TotCss } from '../../styles/TimeOnTask.view.styles';
import CircularProgress from '@mui/material/CircularProgress';

type CalculateCoreActivityHoursProps = {
  percentValue: number | null;
  productiveHrsDayGoal: number;
};

const CalculateCoreActivityHours: React.FC<CalculateCoreActivityHoursProps> = ({
  percentValue,
  productiveHrsDayGoal
}) => {
  return (
    <Box sx={{ ...TotCss.instructions, marginTop: '12px' }}>
      <Typography>
        This will set a <strong>Core Activity Goal</strong> of{' '}
        <strong>
          {isNaN(productiveHrsDayGoal) ? (
            <CircularProgress size={15} />
          ) : (
            ((percentValue * productiveHrsDayGoal) / 100).toFixed(1)
          )}
        </strong>{' '}
        Hrs/Day based on your Team's Productivity Goal of {productiveHrsDayGoal}{' '}
        Hrs/Day.
      </Typography>
    </Box>
  );
};

export default CalculateCoreActivityHours;
