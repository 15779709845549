const SUFFIXES = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
const DIVISOR = 1000;

export const formatBytes = (
  units: number = 0,
  unitString: string = 'B'
): string => {
  if (isNaN(units)) {
    return 'NaN';
  }

  if (units === 0) {
    return `0 ${unitString}`;
  }

  const sign = Math.sign(units);
  units = Math.abs(units);
  let i = 0;

  while (units >= DIVISOR && i < SUFFIXES.length - 1) {
    units /= DIVISOR;
    i++;
  }

  const suffixUnitString = `${SUFFIXES[i]}${
    unitString === 'B' ? '' : ' '
  }${unitString}`;
  return `${(units * sign).toFixed(i === 0 ? 0 : 2)} ${suffixUnitString}`;
};

export const commaSeparateNumber = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
