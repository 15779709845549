'use strict';

var atHelperFunctions = require('_app/serviceFunctions/atHelperFunctions')
    .atHelperFunctions;

angular.module('core').service('atHelperFunctions', AtHelperFunctions);

AtHelperFunctions.$inject = [];

function AtHelperFunctions() {
    var service = this;

    angular.extend(service, atHelperFunctions);
}
