import React from 'react';
import { InfoButton } from '../../../common/components/Button';
import Typography from '@mui/material/Typography';

const TotalProdAndOfflineMeetingsTooltipText = () => (
  <Typography>
    Total Time includes Productive and Offline Meetings when integrated
  </Typography>
);

export const TotalProdAndOfflineMeetingsHeader = () => {
  const infoButton = (
    <InfoButton tooltipText={<TotalProdAndOfflineMeetingsTooltipText />} />
  );

  //TODO: this will be dynamic based on the future PTO feature flag
  return (
    <>
      <Typography sx={{ display: 'flex' }}>
        <Typography component="span" sx={{ textAlign: 'center' }}>
          Total
        </Typography>
        {infoButton}
      </Typography>
    </>
  );
};
