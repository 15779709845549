export const EXPIRED_DASHBOARD_TITLE = 'Expired Trials';
export const PAID_DASHBOARD_TITLE = 'Paid Subscriptions';
export const TRIAL_DASHBOARD_TITLE = 'Active Trials';
export const ENTERPRISE_DASHBOARD_TITLE = 'Accounts';
export const EXPIRED_DASHBOARD_TYPE = 'free';
export const PAID_DASHBOARD_TYPE = 'paid';
export const TRIAL_DASHBOARD_TYPE = 'trial';
export const ALL_DASHBOARD_TYPE = 'all';
export const PLAN_TYPES = {
  enterpriseCC: 'activtrak_command_center_usd_1_year_plan',
  mspCC: 'activtrak_command_center_usd_monthly'
};
export const CHANNEL_TYPE = {
  MSPOfficialParent: 'MSP Official - Parent',
  EnterpriseParent: 'Enterprise - Parent',
  PartnerParent: 'Partner - Parent'
};
export const CC_ROUTES = {
  PaidSubscriptions: {
    url: '/paid-subscriptions',
    title: PAID_DASHBOARD_TITLE
  },
  ExpiredSubscriptions: {
    url: '/expired-trial-subscriptions',
    title: EXPIRED_DASHBOARD_TITLE
  },
  TrialSubscriptions: {
    url: '/trial-subscriptions',
    title: TRIAL_DASHBOARD_TITLE
  },
  Enterprise: {
    url: '/enterprise',
    title: ENTERPRISE_DASHBOARD_TITLE
  }
};
export const CC_TYPE = {
  Standard: 'CC',
  Enterprise: 'Enterprise'
};