export const ApiRoutes = {
  exports: {
    fetchExport: '/reports/v2/exports/:id',
    fetchAllExports: '/reports/v2/exports',
    create: '/reports/v2/exports',
    cancel: '/reports/v2/exports/:id/cancel',
    delete: '/reports/v2/exports/:id'
  },
  settings: {
    postLogin: '/api/v2/post_login',
    realtime: '/api/realtime/settings',
    teamPulse: '/api/teampulse/settings',
    liveReport: '/api/settings/live_report_settings',
    screenshotRedaction: '/api/screenshots/redaction/info',
    safeSearchInfo: '/api/screenshots/safesearch/info',
    safeSearchThresholds: '/api/screenshots/safesearch/threshold',
    timezone: '/api/settings/timezone',
    showLocalTimezone: '/admin/v1/accountsettings/showlocaltimezone'
  },
  liveReports: {
    liveReports: '/api/settings/live_report_settings'
  },
  insights: {
    subNavigationSettings: '/api/insights/accountsettings/subnavfeatures'
  },
  account: {
    urls: '/account/urlsv2'
  }
};
