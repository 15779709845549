import React, { useEffect, useState } from 'react';
import { IAccountTimezone, timezoneService } from '../../common/services';
import { useTimezoneState } from '../services';

import { useNotifications } from '../../common/services/Notifications';
import { Grid } from '@mui/material';
import { AccountTimezoneForm } from '../components/AccountTimezoneForm';
import { ActivityTimezoneForm } from '../components/ActivityTimezoneForm';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

const Timezone = () => {
  const service = timezoneService();
  const timezoneState = useTimezoneState({ service });
  const notificationService = useNotifications();

  const {
    allTimezones,
    timezoneLabel,
    isDateFormatDDMM,
    isTimeFormat24Hour,
    showLocalTimezone,
    isFormDisabled,
    timezoneNotification,
    init,
    setTimezoneNotification,
    updateAccountTimezone,
    updateShowLocalTimezone
  } = timezoneState;

  const [accountTimezone, setAccountTimezone] = useState<IAccountTimezone>();

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (timezoneNotification?.msg && timezoneNotification?.type) {
      notificationService[timezoneNotification.type](timezoneNotification.msg);
      setTimezoneNotification(undefined);
    }
  }, [
    notificationService,
    timezoneNotification?.msg,
    timezoneNotification?.type,
    setTimezoneNotification
  ]);

  useEffect(() => {
    setAccountTimezone({
      timeZone: timezoneLabel,
      isDateFormatDDMM: isDateFormatDDMM.toString(),
      isTimeFormat24Hour: isTimeFormat24Hour.toString()
    });
  }, [timezoneLabel, isDateFormatDDMM, isTimeFormat24Hour]);

  return (
    <>
      <Grid container spacing={3} sx={{ marginBottom: '24px' }}>
        <Grid item xs={12} lg={6} xl={5}>
          <AccountTimezoneForm
            allTimezones={allTimezones}
            accountTimezone={accountTimezone}
            isFormDisabled={isFormDisabled.current}
            onSubmit={updateAccountTimezone}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} xl={5}>
          <ActivityTimezoneForm
            showLocalTimezone={showLocalTimezone}
            isFormDisabled={isFormDisabled.current}
            onSubmit={updateShowLocalTimezone}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Timezone;

export const TimezoneComponent = () => (
  <ReactivTrakComponentWrapper>
    <Timezone />
  </ReactivTrakComponentWrapper>
);
