import { useCallback, useEffect, useRef, useState } from 'react';
import { Timeline } from 'vis-timeline/peer';
import 'vis-timeline/dist/vis-timeline-graph2d.min.css';
import moment, { Moment } from 'moment';
import { ApiStatus } from '../../enums/ApiStatus';
import { AtTimelineProps } from './atTimeline.models';
import { AtTimelineWrapper } from './atTimeline.styles';
import { getTimelineStore, setTimelineStore } from './atTimeline.store';
import { calculateTimeAxis } from './atTimeline.utils';
import { DataItemCollectionType } from 'vis-timeline/types';

export const AtTimeline = (props: AtTimelineProps) => {
  const {
    segments: items,
    rows: groups,
    options = {},
    status,
    setStatus,
    styleOverrides
  } = props;

  const [timelineEl, setTimelineEl] = useState<Timeline | null>(null);
  const timelineRef = useRef<HTMLDivElement>();

  const updateTimeAxis = useCallback(
    (start: Moment, end: Moment) => {
      const { timelineWidth } = getTimelineStore();
      const dayRange = moment(end).diff(moment(start), 'm') / (24 * 60);
      const timeAxis = calculateTimeAxis(dayRange, timelineWidth);
      timelineEl?.setOptions({ timeAxis, showMinorLabels: dayRange <= 4 });
    },
    [timelineEl]
  );

  const updateStatus = useCallback(
    (timeline) => {
      if ((timeline as any)?.initialRangeChangeDone) {
        const visibleItems = timeline.getVisibleItems();
        if (status !== ApiStatus.Loaded && visibleItems.length > 0) {
          clearTimeout(getTimelineStore().redrawTimeout);
          setTimelineStore({
            redrawTimeout: setTimeout(() => setStatus(ApiStatus.Loaded), 250)
          });
        }

        const { timelineWidth } = getTimelineStore();
        const currentTimelineWidth = timelineRef?.current.clientWidth;
        const { start, end } = timeline.getWindow();
        if (timelineWidth !== currentTimelineWidth && start && end) {
          setTimelineStore({ timelineWidth: currentTimelineWidth });
          updateTimeAxis(moment(start), moment(end));
        }
      }
    },
    [timelineRef, status, setStatus, updateTimeAxis]
  );

  const rangeChangedEventHandler = useCallback(
    (props) => {
      if (props.byUser) {
        updateTimeAxis(moment(props.start), moment(props.end));
      }
    },
    [updateTimeAxis]
  );

  //initialize a timeline element
  useEffect(() => {
    if (!timelineEl && timelineRef) {
      const _timeline = new Timeline(timelineRef.current, [], []);

      _timeline?.on('changed', () => updateStatus(_timeline));
      _timeline?.on('rangechanged', rangeChangedEventHandler);

      setTimelineEl(_timeline);
    }
  }, [timelineEl, timelineRef, rangeChangedEventHandler, updateStatus]);

  //update timeline on change
  useEffect(() => {
    if (groups && items) {
      timelineEl?.setOptions(options);
      timelineEl?.setData({
        groups,
        items: items as DataItemCollectionType
      });

      timelineEl?.redraw();
    }
  }, [groups, items, options, timelineEl]);

  const onUnmount = useCallback(() => {
    timelineEl?.destroy();
  }, [timelineEl]);

  useEffect(() => {
    return onUnmount;
  }, [onUnmount]);

  return (
    <>
      <AtTimelineWrapper
        ref={timelineRef}
        sx={{
          opacity: status === ApiStatus.Loaded ? 1 : 0,
          height: status === ApiStatus.Loaded ? 'auto' : 0,
          ...styleOverrides
        }}
      />
      {status === ApiStatus.Loading && <div>Loading...</div>}
      {status === ApiStatus.Error && <div>Error...</div>}
    </>
  );
};
