//https://developer.mozilla.org/en-US/blog/learn-javascript-console-methods/
import { AT_CONSOLE_LOG_STYLES } from './AtConsoleLogger.constants';

interface IAtConsoleLogger {
  warn: () => void;
  error: () => void;
  info: () => void;
  debug: () => void;
}

/**
 * @param summaryMsg - OPTIONAL custom summary message to log
 * @param detailsMsg - OPTIONAL details or server-side message to log
 * @returns void
 * @description
 * This function is used to log messages to the console with custom styling.
 * AtConsoleLogger(summaryMsg, detailsMsg).error()
 * AtConsoleLogger(null, detailsMsg).error()
 * AtConsoleLogger(summaryMsg).warn()
 * AtConsoleLogger(summaryMsg).info()
 * AtConsoleLogger(summaryMsg).debug()
 */

const consoleLoggerDefault = (
  summaryMsg: string,
  detailsMsg: any
): IAtConsoleLogger => ({
  warn: () =>
    console.warn(
      `%c ActivTrak Warning:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.warn,
      detailsMsg
    ),
  error: () =>
    console.error(
      `%c ActivTrak Error:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.error,
      detailsMsg
    ),
  info: () =>
    console.info(
      `%c ActivTrak Info:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.info,
      detailsMsg
    ),
  debug: () =>
    console.log(
      `%c ActivTrak Debug:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.debug,
      detailsMsg
    )
});

const consoleLoggerNoDetailsMsg = (summaryMsg: string) => ({
  warn: () =>
    console.warn(
      `%c ActivTrak Warning:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.warn
    ),
  error: () =>
    console.error(
      `%c ActivTrak Error:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.error
    ),
  info: () =>
    console.info(
      `%c ActivTrak Info:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.info
    ),
  debug: () =>
    console.log(
      `%c ActivTrak Debug:${summaryMsg ? ` ${summaryMsg}` : ''}`,
      AT_CONSOLE_LOG_STYLES.debug
    )
});

export const AtConsoleLogger = (
  summaryMsg?: string,
  detailsMsg?: any
): IAtConsoleLogger =>
  detailsMsg
    ? consoleLoggerDefault(summaryMsg, detailsMsg)
    : consoleLoggerNoDetailsMsg(summaryMsg);
