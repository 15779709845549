import { Role } from '../../../../../enums';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';

export const appAccountWizard: IRoute = {
  name: 'app.account.wizard',
  stateDefinition: {
    url: '/account/wizard?roleAccess&firstVisit',
    template: '<account-wizard></account-wizard>',
    data: { pageTitle: 'Guided Account Setup' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportBasic],
    featureFlags: [FeatureFlag.ShowWizard]
  }
};

export default appAccountWizard;
