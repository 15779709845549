import { ColumnState } from 'ag-grid-community';
import {
  WorkingHoursDataId,
  WorkingHoursExportExcludeColumn
} from '../models/WorkingHours.models';

export const WORKING_HOURS_SUMMARY_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: WorkingHoursDataId.Date, hide: false },
  { colId: WorkingHoursDataId.User, hide: false },
  { colId: WorkingHoursDataId.Location, hide: false },
  { colId: WorkingHoursDataId.Productive, hide: false },
  { colId: WorkingHoursDataId.ProductiveActive, hide: true },
  { colId: WorkingHoursDataId.ProductivePassive, hide: true },
  { colId: WorkingHoursDataId.Unproductive, hide: true },
  { colId: WorkingHoursDataId.UnproductiveActive, hide: true },
  { colId: WorkingHoursDataId.UnproductivePassive, hide: true },
  { colId: WorkingHoursDataId.Undefined, hide: true },
  { colId: WorkingHoursDataId.UndefinedActive, hide: true },
  { colId: WorkingHoursDataId.UndefinedPassive, hide: true },
  { colId: WorkingHoursDataId.ScreenTime, hide: false },
  { colId: WorkingHoursDataId.TotalTimeOffline, hide: false },
  { colId: WorkingHoursDataId.TotalTime, hide: false }
];

export const WORKING_HOURS_DETAILED_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: WorkingHoursDataId.Date, hide: false },
  { colId: WorkingHoursDataId.User, hide: false },
  { colId: WorkingHoursDataId.Location, hide: false },
  { colId: WorkingHoursDataId.Productive, hide: true },
  { colId: WorkingHoursDataId.ProductiveActive, hide: false },
  { colId: WorkingHoursDataId.ProductivePassive, hide: false },
  { colId: WorkingHoursDataId.Unproductive, hide: true },
  { colId: WorkingHoursDataId.UnproductiveActive, hide: false },
  { colId: WorkingHoursDataId.UnproductivePassive, hide: false },
  { colId: WorkingHoursDataId.Undefined, hide: true },
  { colId: WorkingHoursDataId.UndefinedActive, hide: false },
  { colId: WorkingHoursDataId.UndefinedPassive, hide: false },
  { colId: WorkingHoursDataId.ScreenTime, hide: false },
  { colId: WorkingHoursDataId.TotalTimeOffline, hide: false },
  { colId: WorkingHoursDataId.TotalTime, hide: false }
];

export const WORKING_HOURS_EXPORT_EXCLUDE_COLUMNS_MAP: Record<
  WorkingHoursDataId,
  WorkingHoursExportExcludeColumn
> = {
  [WorkingHoursDataId.Date]: WorkingHoursExportExcludeColumn.Date,
  [WorkingHoursDataId.User]: WorkingHoursExportExcludeColumn.User,
  [WorkingHoursDataId.Location]: WorkingHoursExportExcludeColumn.Location,
  [WorkingHoursDataId.Productive]: WorkingHoursExportExcludeColumn.Productive,
  [WorkingHoursDataId.ProductiveActive]:
    WorkingHoursExportExcludeColumn.ProductiveActive,
  [WorkingHoursDataId.ProductivePassive]:
    WorkingHoursExportExcludeColumn.ProductivePassive,
  [WorkingHoursDataId.Unproductive]:
    WorkingHoursExportExcludeColumn.Unproductive,
  [WorkingHoursDataId.UnproductiveActive]:
    WorkingHoursExportExcludeColumn.UnproductiveActive,
  [WorkingHoursDataId.UnproductivePassive]:
    WorkingHoursExportExcludeColumn.UnproductivePassive,
  [WorkingHoursDataId.Undefined]: WorkingHoursExportExcludeColumn.Undefined,
  [WorkingHoursDataId.UndefinedActive]:
    WorkingHoursExportExcludeColumn.UndefinedActive,
  [WorkingHoursDataId.UndefinedPassive]:
    WorkingHoursExportExcludeColumn.UndefinedPassive,
  [WorkingHoursDataId.ScreenTime]: WorkingHoursExportExcludeColumn.ScreenTime,
  [WorkingHoursDataId.TotalTimeOffline]:
    WorkingHoursExportExcludeColumn.TotalTimeOffline,
  [WorkingHoursDataId.TotalTime]: WorkingHoursExportExcludeColumn.TotalTime
};
