import { IRoute } from '../../../../models/IRoute';
import { loginTemplate as angularJsTemplate } from '../templates';

export const login: IRoute = {
  name: 'login',
  stateDefinition: {
    url: '/login?tempcode&setid&resetid&email&next&sso&search',
    template: angularJsTemplate,
    controller: 'LoginController as vm',

    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      apiFailure: false,
      maintenanceModeReason: null,
      showLockoutError: false,
      logoutReason: null,
      sso: null
    },
    data: { pageTitle: 'Login' }
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default login;
