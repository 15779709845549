import { createStore } from 'zustand';
import { IAlarmSettingsStore } from './alarmSettingsStore.types';
import {
  ALARM_SETTINGS_STORE_CACHE_LIFETIME,
  ALARM_SETTINGS_STORE_DEFAULT
} from './alarmSettingsStore.constants';
import { fetchSettings } from './alarmSettingsStore.utils';

/**
 * The alarm settings store that holds the alarm settings.
 *
 * Do not use this store directly, use the fetchAlarmSettingsStore, setAlarmSettingsStore, and resetAlarmSettingsStore functions instead.
 * @private
 */
export const alarmSettingsStore = createStore<IAlarmSettingsStore>(
  () => ALARM_SETTINGS_STORE_DEFAULT
);

/**
 * Returns the alarm settings that are set in the store.
 */
export const fetchAlarmSettingsStore = async () => {
  const { cacheTimestamp } = alarmSettingsStore.getState();
  if (
    cacheTimestamp === undefined ||
    cacheTimestamp <= Date.now() - ALARM_SETTINGS_STORE_CACHE_LIFETIME
  ) {
    const alarmSettings = await fetchSettings();
    alarmSettingsStore.setState({
      ...alarmSettings,
      cacheTimestamp: Date.now()
    });
  }

  return alarmSettingsStore.getState();
};

/**
 * Resets the alarm settings back to default in the store.
 */
export const resetAlarmSettingsStore = (): void =>
  alarmSettingsStore.setState(ALARM_SETTINGS_STORE_DEFAULT, true);
