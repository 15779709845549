import { IRealtimeSettingsStore } from './realtimeSettingsStore.models';

export const REALTIME_SETTINGS_STORE_DEFAULT: IRealtimeSettingsStore = {
  thumbnailPollDelay: 1000,
  messageDelayThreshold: 5000,
  messageTimeoutThreshold: 10000,
  lastSeenThreshold: 2000,
  realtimePollDelay: 2000,
  realtimePollAfterError: 10000
};
