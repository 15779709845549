import { useCallback, useRef, useState } from 'react';
import {
  IIntegrationConfigurationDto,
  IMergeLinkDto,
  IMergeLinkedAccountsDto,
  IntegrationStateType
} from '../models/IIntegrationTypes';
import { NotificationType } from '../../common/enums';
import {
  addIntegrationConfiguration,
  createIntegrationInstance,
  enableIntegrationInstance,
  resumeTrayIntegrationInstance,
  deleteIntegrationInstance,
  pauseTrayIntegrationInstance,
  deleteTrayIntegrationInstance,
  syncIntegrationInstance,
  disableIntegrationInstance,
  refreshTrayIntegrationInstance,
  fetchMergeLink,
  fetchMergeLinkedAccounts,
  deleteMergeIntegrationInstance
} from '../utils/integrationInstance.utils';
import {
  CLIENT_ID,
  CLIENT_SECRET,
  DOMAIN,
  TENANT_ID
} from '../constants/configuration';
import { IntegrationNotifications } from '../constants/IntegrationNotifications';
import {
  IntegrationCodes,
  IntegrationTypeCodes
} from '../constants/IntegrationCodes';
import { IBaseIntegrationConfigurationState } from '../models/IBaseIntegrationConfigurationState';
import { HRISIntegrationType } from '../constants/IntegrationDetails';

export const useBaseIntegrationConfigurationState = (
  integrationCodeValue: IntegrationCodes
): IBaseIntegrationConfigurationState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [instanceId, setInstanceId] = useState<string>();
  const [apiRequestError, setApiRequestError] = useState<boolean>(false);
  const [traySolutionInstanceId, setTraySolutionInstanceId] =
    useState<string>(null);
  const [hasConfigurations, setHasConfigurations] = useState<boolean>();
  const [integrationState, setIntegrationState] =
    useState<IntegrationStateType>();
  const [
    integrationConfigurationNotification,
    setIntegrationConfigurationNotification
  ] = useState<NotificationType>();
  const integrationCode =
    useRef<IntegrationCodes>(integrationCodeValue).current;
  const [isDeletingTrayInstance, setIsDeletingTrayInstance] = useState(false);
  const [trayMigrationInProgress, setTrayMigrationInProgress] = useState(false);
  const [mergeLink, setMergeLink] = useState<IMergeLinkDto>();
  const [initiatedNotYetCreated, setInitiatedNotYetCreated] = useState();
  const [integrationCardEnabled, setIntegrationCardEnabled] = useState(false);

  const setCurrentInstanceId = useCallback(
    async (id?: string) => {
      try {
        if (!id || (instanceId && id !== instanceId)) {
          const newInstanceId = await createIntegrationInstance(
            integrationCode
          );
          setInstanceId(newInstanceId);
          return newInstanceId;
        }
        return id;
      } catch (error) {
        console.error('ActivTrak Error: Unable to create Integration Instance');
      }
    },
    [instanceId, integrationCode]
  );

  const enableIntegration = useCallback(
    async (instanceId, integrationNotification?: string) => {
      const currentInstanceId = await setCurrentInstanceId(instanceId);
      setIsLoading(true);
      try {
        await enableIntegrationInstance(integrationCode, currentInstanceId);
        setIntegrationState(IntegrationStateType.Waiting);
        setIntegrationConfigurationNotification({
          msg: integrationNotification ?? IntegrationNotifications.Enabled,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to enable Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [integrationCode, setCurrentInstanceId]
  );

  const createAndEnableIntegration = useCallback(
    async ({ instanceId, tenantId, clientId, clientSecret, domain }) => {
      setIsLoading(true);
      try {
        const currentInstanceId = await setCurrentInstanceId(instanceId);

        let configurationDetails: { name: string; value: string }[] = [];
        const hasRequiredParams = (values: any[]): boolean =>
          values.every((value) => value !== undefined);

        if (
          integrationCode === IntegrationCodes.GoogleCalendar &&
          hasRequiredParams([domain])
        ) {
          configurationDetails = [{ name: DOMAIN, value: domain }];
        } else if (
          integrationCode === IntegrationCodes.OutlookCalendar &&
          hasRequiredParams([tenantId, clientId, clientSecret])
        ) {
          configurationDetails = [
            { name: CLIENT_ID, value: clientId },
            { name: CLIENT_SECRET, value: clientSecret },
            { name: TENANT_ID, value: tenantId }
          ];
        }

        const configurations: IIntegrationConfigurationDto[] =
          configurationDetails.map(({ name, value }) => ({
            instanceid: currentInstanceId,
            integrationcode: integrationCode,
            name,
            value,
            typecode: IntegrationTypeCodes.String
          }));

        await Promise.all(
          configurations.map((config) => addIntegrationConfiguration(config))
        );
        await enableIntegration(
          currentInstanceId,
          IntegrationNotifications.Initiated
        );
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.GenericError,
          type: 'error'
        });
        console.error(
          `ActivTrak Error: ${IntegrationNotifications.GenericError} - ${error?.message}`
        );
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const refreshIntegration = useCallback(
    async (instanceId) => {
      const currentInstanceId = await setCurrentInstanceId(instanceId);
      setIsLoading(true);
      try {
        await syncIntegrationInstance(currentInstanceId);
        setIntegrationState(IntegrationStateType.Running);
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Refreshed,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to sync Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [setCurrentInstanceId]
  );

  const pauseIntegration = useCallback(
    async (instanceId) => {
      const currentInstanceId = await setCurrentInstanceId(instanceId);
      setIsLoading(true);
      try {
        await disableIntegrationInstance(integrationCode, currentInstanceId);
        setIntegrationState(IntegrationStateType.Paused);
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Paused,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to pause Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [integrationCode, setCurrentInstanceId]
  );

  const deleteIntegration = useCallback(
    async (instanceId) => {
      const currentInstanceId = await setCurrentInstanceId(instanceId);
      setIsLoading(true);
      try {
        await deleteIntegrationInstance(currentInstanceId);
        setInstanceId(undefined);
        setIntegrationState(IntegrationStateType.Deleted);
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Deleted,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to delete Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [setCurrentInstanceId]
  );

  const enableTrayIntegration = useCallback(
    async (traySolutionInstanceId, integrationNotification?: string) => {
      setIsLoading(true);
      try {
        await resumeTrayIntegrationInstance(
          traySolutionInstanceId,
          integrationCode
        );
        setIntegrationState(IntegrationStateType.Waiting);
        setIntegrationConfigurationNotification({
          msg: integrationNotification ?? IntegrationNotifications.Enabled,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.GenericError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to resume Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [integrationCode]
  );

  const pauseTrayIntegration = useCallback(
    async (traySolutionInstanceId, integrationNotification?: string) => {
      setIsLoading(true);
      try {
        await pauseTrayIntegrationInstance(
          traySolutionInstanceId,
          integrationCode
        );
        setIntegrationState(IntegrationStateType.Waiting);
        setIntegrationConfigurationNotification({
          msg: integrationNotification ?? IntegrationNotifications.Paused,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to pause Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [integrationCode]
  );
  const refreshTrayIntegration = useCallback(
    async (traySolutionInstanceId, integrationNotification?: string) => {
      setIsLoading(true);
      try {
        await refreshTrayIntegrationInstance(
          traySolutionInstanceId,
          integrationCode
        );
        setIntegrationState(IntegrationStateType.Running);
        setIntegrationConfigurationNotification({
          msg: integrationNotification ?? IntegrationNotifications.Refreshed,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to sync Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [integrationCode]
  );
  const deleteTrayIntegration = useCallback(
    async (traySolutionInstanceId) => {
      setIsLoading(true);
      try {
        await deleteTrayIntegrationInstance(
          traySolutionInstanceId,
          integrationCode
        );
        setInstanceId(undefined);
        setIntegrationState(IntegrationStateType.Deleted);
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Deleted,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to delete Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [integrationCode]
  );

  const initiateTrayMigration = useCallback(
    async (integrationCode: IntegrationCodes, trayId: string) => {
      try {
        setIsDeletingTrayInstance(true);
        setTrayMigrationInProgress(true);
        await deleteTrayIntegrationInstance(trayId, integrationCode);
        setIntegrationState(IntegrationStateType.Deleted);
        setIsDeletingTrayInstance(false);
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to delete Tray Instance', error);
        setApiRequestError(true);
      }
    },
    []
  );

  const initiateOauth = useCallback(
    async (
      integrationCode,
      startOauthFlow,
      traySolutionInstanceId,
      instanceId
    ) => {
      if (traySolutionInstanceId) {
        await initiateTrayMigration(integrationCode, traySolutionInstanceId);
      }
      const currentInstanceId = instanceId || (await setCurrentInstanceId());

      if (currentInstanceId) {
        startOauthFlow(currentInstanceId);
      } else {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.OauthError,
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to Initiate OAuth');
        setApiRequestError(true);
      }
    },
    [initiateTrayMigration, setCurrentInstanceId]
  );

  const initiateMergeDev = useCallback(async () => {
    // TODO: Revisit after MVP hardcoding workday param
    const link = await fetchMergeLink(HRISIntegrationType.Workday);
    if (link) {
      setMergeLink(link);
    } else {
      setIntegrationConfigurationNotification({
        msg: IntegrationNotifications.APIError,
        type: 'error'
      });
      setApiRequestError(true);
    }
  }, [
    setMergeLink,
    setIntegrationConfigurationNotification,
    setApiRequestError
  ]);

  const fetchMergeAccounts = useCallback(async (): Promise<
    IMergeLinkedAccountsDto[]
  > => {
    const mergeLinkedAccounts = await fetchMergeLinkedAccounts();
    if (mergeLinkedAccounts) {
      return mergeLinkedAccounts;
    } else {
      setIntegrationConfigurationNotification({
        msg: IntegrationNotifications.APIError,
        type: 'error'
      });
      setApiRequestError(true);
      return [];
    }
  }, [setIntegrationConfigurationNotification, setApiRequestError]);

  const fetchMergeAccount = useCallback(
    async (
      accountName: HRISIntegrationType
    ): Promise<IMergeLinkedAccountsDto> => {
      const mergeLinkedAccounts = await fetchMergeLinkedAccounts();

      return mergeLinkedAccounts?.find(
        (account) => account?.integration?.name.toLowerCase() === accountName
      );
    },
    []
  );

  const deleteMergeIntegration = useCallback(
    async (instanceId) => {
      const currentInstanceId = await setCurrentInstanceId(instanceId);
      setIsLoading(true);
      try {
        await deleteMergeIntegrationInstance(currentInstanceId);
        setInstanceId(undefined);
        setIntegrationState(IntegrationStateType.Deleted);
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Deleted,
          type: 'success'
        });
      } catch (error) {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.APIError,
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to delete Merge Integration Instance',
          error
        );
        setApiRequestError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [setCurrentInstanceId]
  );

  return {
    isLoading,
    setIsLoading,
    integrationConfigurationNotification,
    setIntegrationConfigurationNotification,
    createAndEnableIntegration,
    instanceId,
    setInstanceId,
    integrationCode,
    integrationState,
    setIntegrationState,
    refreshIntegration,
    refreshTrayIntegration,
    enableIntegration,
    enableTrayIntegration,
    pauseIntegration,
    pauseTrayIntegration,
    deleteIntegration,
    deleteTrayIntegration,
    setCurrentInstanceId,
    setHasConfigurations,
    hasConfigurations,
    traySolutionInstanceId,
    setTraySolutionInstanceId,
    apiRequestError,
    isDeletingTrayInstance,
    setTrayMigrationInProgress,
    trayMigrationInProgress,
    initiateTrayMigration,
    initiateOauth,
    initiateMergeDev,
    mergeLink,
    deleteMergeIntegration,
    fetchMergeAccounts,
    fetchMergeAccount,
    setInitiatedNotYetCreated,
    initiatedNotYetCreated,
    integrationCardEnabled,
    setIntegrationCardEnabled
  };
};
