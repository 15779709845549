import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { primaryBlue } from '../../constants';

export const AtTimelineWrapper = styled(Box)`
  max-height: calc(100vh - 240px);
  overflow: auto;
  & .vis-item {
    top: 4px !important;
    height: 24px !important;
    &.timeline-border-none {
      border: none;
    }
    &.timeline-bg-none {
      background: none;
    }
    &.timeline-schedule-start {
      &::before {
        background-color: ${primaryBlue};
        content: '';
        position: absolute;
        border: 1px solid ${primaryBlue};
        left: -2px !important;
        top: -6px !important;
        height: 120% !important;
        width: 4px !important;
        border-radius: 3px !important;
      }
    }
  }
  & .vis-text.vis-minor {
    font-weight: 500;
  }
  & .vis-text.vis-major {
    font-weight: 500;
  }
`;
