import React from 'react';
import Button from '@mui/material/Button';
import { useNotifications } from '../../common/services/Notifications';
import { SupportPortalService, useSupportPortalState } from '../hooks';
import { consoleMessage, displayMessage } from '../constants';
import { IUnlockUserPayloadDto } from '../models/IPayloadDto';

interface IUnlockUserButtonProps {
  setDetailsModalClose?: () => void;
  username: string;
}

export const UnlockUserButton = (props: IUnlockUserButtonProps) => {
  const { username, setDetailsModalClose } = props;

  const service = SupportPortalService();
  const { setUnlockUser } = useSupportPortalState({ service });

  const notificationService = useNotifications();

  const payload: IUnlockUserPayloadDto = {
    username: username
  };

  const handleClick = async () => {
    try {
      await setUnlockUser(payload);
      notificationService.success(displayMessage.unlockUser.success(username));
    } catch (error) {
      notificationService.error(displayMessage.unlockUser.error);
      console.error(
        `ActivTrak Error: ${
          error?.message || consoleMessage.unlockUserError.unknown
        }`
      );
    } finally {
      setDetailsModalClose();
    }
  };

  return (
    <Button
      variant="contained"
      onClick={() => handleClick()}
      sx={{ maxWidth: '200px' }}
    >
      Unlock User
    </Button>
  );
};
