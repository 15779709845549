import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { PermissionLevel, SortDirection } from '../../common/enums';
import { useNotifications } from '../../common/services/Notifications';
import AliasSearch from '../components/ComputerAliasSearch';
import InfoIcon from '@mui/icons-material/Info';
import {
  HeaderBarActionButtons,
  HeaderBarLeftActionButtons,
  HeaderBarRightActionButtons,
  InfoContainer,
  ProgressContainer
} from '../styles/Computers.view.styles';
import { useComputerAliasState } from '../services/useComputerAliasState';
import { useComputerColumns } from '../utils/useComputerColumns';
import { AliasService } from '../services/AliasService';
import { useAuthorization } from '../../common/services/Authorization';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

export function ComputerAlias() {
  const aliasService = AliasService();
  const AliasState = useComputerAliasState({ aliasService });

  const {
    getAlias,
    handleAliasChange,
    onApplyClick,
    filteredAliases,
    isLoading,
    onSortOrder,
    aliasNotification,
    setAliasNotification,
    onCancelClick,
    isSaving
  } = AliasState;

  const notificationService = useNotifications();

  useEffect(() => {
    getAlias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (aliasNotification?.msg && aliasNotification?.type) {
      notificationService[aliasNotification.type](aliasNotification.msg);
      setAliasNotification(undefined);
    }
  }, [
    notificationService,
    aliasNotification?.msg,
    aliasNotification?.type,
    setAliasNotification
  ]);

  const authorizationService = useAuthorization();
  const [isReadOnly] = useState<boolean>(
    !authorizationService.hasAuthorizationLevel(
      [PermissionLevel.Edit],
      'app.settings.computerAlias'
    )
  );

  const onAliasChanged = (event) => {
    event.stopPropagation();
    const idChanged = parseInt(event.target.name);
    handleAliasChange(idChanged, event.target.value);
  };

  const computerAliasGridColumns = useComputerColumns({
    onAliasChanged,
    isReadOnly
  });

  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <Box sx={{ minWidth: 1200 }}>
        {(isLoading || isSaving) && (
          <ProgressContainer>
            <LinearProgress />
          </ProgressContainer>
        )}
        <InfoContainer>
          <InfoIcon fontSize="inherit" /> Use aliases to change the name of a
          computer as it appears in your reports. Computer aliases must be
          unique.
        </InfoContainer>
        <HeaderBarActionButtons>
          <HeaderBarLeftActionButtons>
            {isReadOnly ? null : (
              <>
                <Button
                  onClick={onApplyClick}
                  color="primary"
                  variant="contained"
                >
                  Apply
                </Button>
                <Button onClick={onCancelClick}>Cancel</Button>
              </>
            )}
          </HeaderBarLeftActionButtons>
          <HeaderBarRightActionButtons>
            <AliasSearch
              gridColumns={computerAliasGridColumns}
              aliasState={AliasState}
            ></AliasSearch>
          </HeaderBarRightActionButtons>
        </HeaderBarActionButtons>
        <CustomGrid
          isLoading={isLoading}
          isVirtualized={true}
          data={filteredAliases}
          columns={computerAliasGridColumns}
          uniqueRowProp="id"
          initialSortField="user"
          initialSortDirection={SortDirection.Ascending}
          onSortOrder={onSortOrder}
        />
      </Box>
    </Box>
  );
}

export const ComputerAliasComponent = () => (
  <ReactivTrakComponentWrapper>
    <ComputerAlias />
  </ReactivTrakComponentWrapper>
);
