import { TopUsersReportViewComponent } from '../../../../../../reports/top-users-report/views/TopUsersReport.view';
import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { refreshInsightsSettings } from '../../../../../stores/insightsSettingsStore/insightsSettingsStore';

export const appReportsTopUsers: IRoute = {
  name: 'app.reports.topUsers',
  stateDefinition: {
    url: '/topusersv2?groupBy&groupId&name',
    component: TopUsersReportViewComponent,
    resolve: {
      loadInsightsSettingsStore: [async () => await refreshInsightsSettings()]
    },
    data: {
      pageTitle: 'Top Users and Groups'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.TopUsers,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.TopUsersReport]
  }
};

export default appReportsTopUsers;
