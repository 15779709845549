import { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DownloadAgentButton } from '../../common/components/DownloadAgentButton';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { PermissionLevel, Role } from '../../common/enums';
import { useNotifications } from '../../common/services/Notifications';
import UserIdentitySearch from '../components/UserIdentitySearch';
import Grid from '@mui/material/Grid';
import { useUserColumns } from '../utils/useUserColumns';

import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';

import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DialogOrphanedAgent } from '../components/DialogOrphanedAgent';
import { GlobalUserFilters } from '../components/GlobalUserFilters';
import { DownloadProvider } from '../../common/services';
import MergeTypeIcon from '@mui/icons-material/Merge';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuthorization } from '../../common/services/Authorization';
import { IUserIdentity } from '../models/IUserIdentity';
import { DialogVerifyDeleteUser } from '../components/DialogVerifyDeleteUser';
import {
  getDateFormat,
  getDateTimeFormat
} from '../../common/utils/datetime/datetimeFormatStrings';
import { useIdentityCommonState } from '../hooks/useIdentityCommonState';
import { useUserSearchState } from '../hooks/useUserSearchState';
import { useAgentSearchState } from '../hooks/useAgentSearchState';
import { GridPaging } from '../components/GridPaging';
import SnackbarWithUrl from '../components/SnackbarUrl';
import { useDntDeleteState } from '../hooks/useDntDeleteState';
import { DoNotTrackModal } from '../components/DoNotTrackModal';
import {
  DELETE_USER_DATA,
  START_TRACKING,
  STOP_TRACKING
} from '../constants/buttonLabels.constants';
import { TrackModal } from '../components/TrackModal';
import { DeleteUser } from '../components/DeleteUser';
import { BaseNoResults } from '../../common/components/NoResults';
import { HeaderBarRightActionButtons } from '../../alias/styles/Users.view.styles';
import { identityHeaderRight } from '../styles/UserList.view.styles';
import { AddNewUserModal } from '../components/AddNewUserModal';
import { getRoutePermissionLevel } from '../../common/stores/userPermissionsStore/userPermissionStore';

export function UserIdentities() {
  const history = useUIRouterHistory();
  const dateTimeFormat = getDateTimeFormat();
  const commonState = useIdentityCommonState({
    dateFormat: getDateFormat(),
    dateTimeFormat
  });
  const newUserAgentSearchState = useAgentSearchState();
  const dntDeleteState = useDntDeleteState();
  const userSearchState = useUserSearchState({ persistSearchToStore: true });

  const authorization = useAuthorization();
  const permission = getRoutePermissionLevel('app.settings.identity');
  const isReadOnly = permission === null || permission === PermissionLevel.Read;

  const isSuperAdmin = useRef<boolean>(
    authorization.hasAnyRole([Role.SuperAdmin])
  );

  const {
    identityNotification,
    setIdentityNotification,
    deleteIdentityUser,
    snackBarHyperlinkText,
    snackBarMessage,
    snackBarOpen,
    snackBarUrl,
    onSnackBarClose
  } = commonState;

  const {
    isLoadingIdentities,
    identities,
    onSortOrder,
    pageSize,
    onChangePageSize,
    onChangePage,
    initializeUserIdentities,
    cursorStack,
    currentCursor,
    setIdentities,
    gridOrderBy,
    gridOrderDirection,
    gridFilter,
    onSetGridFilter
  } = userSearchState;

  const notifications = useNotifications();
  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(false);
  const [showOrphanedAgentDialog, setShowOrphanedAgentDialog] =
    useState<boolean>(false);
  const [showVerifyDeleteDialog, setShowVerifyDeleteDialog] =
    useState<boolean>(false);
  const [openCreateUserModal, setOpenCreateUserModal] =
    useState<boolean>(false);

  const [selectedUsers, setSelectedUsers] = useState<IUserIdentity[]>(null);

  useEffect(() => {
    if (identityNotification?.msg && identityNotification?.type) {
      notifications[identityNotification.type](identityNotification.msg);
      setIdentityNotification(undefined);
    }
  }, [notifications, identityNotification, setIdentityNotification]);

  useEffect(() => {
    initializeUserIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pageSizeInt = parseInt(pageSize);
    const disableNextPage =
      cursorStack?.length == 0 || identities.length < pageSizeInt;
    setNextPageDisabled(disableNextPage);
  }, [currentCursor, cursorStack, pageSize, identities]);

  const onChangeMenuOption = (option?: any, dataItem?: any) => {
    dntDeleteState.setUserToModify(dataItem);

    switch (option?.label) {
      case STOP_TRACKING:
        dntDeleteState.setOpenDoNotTrackDeleteModal(true);
        break;
      case START_TRACKING:
        dntDeleteState.setOpenTrackModal(true);
        break;
      case DELETE_USER_DATA:
        dntDeleteState.setOpenDeleteModal(true);
        break;
      default:
        console.log('Unknown option: ', option?.label);
    }
  };

  const identityGridColumns = useUserColumns(
    dateTimeFormat,
    onChangeMenuOption,
    isReadOnly
  );

  const handleClickRowGrid = useCallback(
    (rowItem) => {
      if (rowItem.id) {
        history.push('app.settings.identity_id', { id: rowItem.id });
      } else {
        setShowOrphanedAgentDialog(true);
      }
    },
    [history]
  );

  const closeOrphanedAgentDialog = () => {
    setShowOrphanedAgentDialog(false);
  };

  const closeVerifyDeleteUserDialog = () => {
    setShowVerifyDeleteDialog(false);
  };

  const onDeleteUserConfirmed = async () => {
    {
      selectedUsers.forEach(async (user) => {
        await deleteIdentityUser(user.id);
      });

      //give it a moment before calling to get new identity list
      await new Promise((f) => setTimeout(f, 500));
      setSelectedUsers([]);
      initializeUserIdentities();
    }
  };

  const handleIdentityGridChange = (
    oldIdentity: IUserIdentity,
    newIdentity: IUserIdentity
  ) => {
    const clonedIdentities = [...identities];
    if (oldIdentity && newIdentity) {
      const index = clonedIdentities.findIndex(
        (usr) => usr.id === oldIdentity.id
      );
      clonedIdentities[index] = newIdentity;
    }
    const selectedIdentities = clonedIdentities?.filter((usr) => usr.selected);
    setSelectedUsers(selectedIdentities);
    setIdentities(clonedIdentities);
  };

  //disable until fully tested
  const showBetaDeleteUser = isSuperAdmin.current;
  const showBetaMergeUser = false;

  function handleDeleteUsersClick(): void {
    const deleteNotAllowedUsers = selectedUsers.filter(
      (x) => (x.agents && x.agents.length > 0) || x.tracked === false
    );
    if (deleteNotAllowedUsers.length > 0) {
      setIdentityNotification({
        msg: 'Cannot delete untracked users, or users that have agents assigned.',
        type: 'error'
      });
    } else {
      setShowVerifyDeleteDialog(true);
    }
  }

  const userGridRowDisableCondition = useCallback((rowItem) => {
    return !rowItem.id;
  }, []);

  const openCreateUser = () => {
    newUserAgentSearchState.resetAgentsSelected();
    setOpenCreateUserModal(true);
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: 'inline-flex',
              alignSelf: 'center'
            }}
          >
            <Button
              onClick={openCreateUser}
              disabled={isReadOnly}
              variant="contained"
              color="primary"
            >
              Add user{' '}
            </Button>
            {showBetaMergeUser && (
              <Button
                startIcon={<MergeTypeIcon />}
                // onClick={handleMergeUsersClick}
                disabled={
                  isReadOnly ||
                  selectedUsers == null ||
                  selectedUsers?.length < 2
                }
                variant="contained"
                color="primary"
              >
                Merge Users
              </Button>
            )}
            {showBetaDeleteUser && (
              <>
                {' '}
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteUsersClick}
                  disabled={
                    isReadOnly || !selectedUsers || selectedUsers?.length == 0
                  }
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 8 }}
                >
                  Delete
                </Button>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <UserIdentitySearch
              commonState={commonState}
              userSearchState={userSearchState}
              gridFilter={gridFilter}
              onSetGridFilter={onSetGridFilter}
            />
          </Grid>
          <Grid item xs={12} sx={identityHeaderRight}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={4}
                lg={5}
                sx={{ display: 'inline-flex' }}
              >
                {' '}
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={7}>
                <HeaderBarRightActionButtons>
                  <GlobalUserFilters
                    gridFilter={gridFilter}
                    onSetGridFilter={onSetGridFilter}
                  />
                  <DownloadAgentButton />
                </HeaderBarRightActionButtons>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isLoadingIdentities && !identities?.length ? (
          <BaseNoResults />
        ) : (
          <>
            <CustomGrid
              isVirtualized={false}
              isLoading={isLoadingIdentities}
              data={identities}
              columns={identityGridColumns}
              initialSortField={gridOrderBy}
              uniqueRowProp="id"
              initialSortDirection={gridOrderDirection}
              onSelectAllClick={() => handleIdentityGridChange(null, null)}
              onClickRow={handleClickRowGrid}
              onSortOrder={onSortOrder}
              rowDisableCondition={userGridRowDisableCondition}
              hasBulkSelection={showBetaDeleteUser || showBetaMergeUser}
            />
            <Box sx={{ mt: 1 }}>
              <GridPaging
                currentCursor={currentCursor}
                cursorStack={cursorStack}
                nextPageDisabled={nextPageDisabled}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                pageSize={pageSize}
                idPrefix="identities"
              />
            </Box>
          </>
        )}
      </Box>

      <SnackbarWithUrl
        open={snackBarOpen}
        message={snackBarMessage}
        hyperlinkText={snackBarHyperlinkText}
        url={snackBarUrl}
        onClose={onSnackBarClose}
      />
      <DoNotTrackModal
        dntState={dntDeleteState}
        openDoNotTrackDeleteModal={dntDeleteState.openDoNotTrackDeleteModal}
        commonState={commonState}
        handleIdentityGridChange={handleIdentityGridChange}
      />
      <TrackModal
        dntState={dntDeleteState}
        openTrackModal={dntDeleteState.openTrackModal}
        commonState={commonState}
        handleIdentityChanged={handleIdentityGridChange}
      />
      <DeleteUser
        dntState={dntDeleteState}
        commonState={commonState}
        handleIdentityGridChange={handleIdentityGridChange}
      />
      <DialogOrphanedAgent
        closeDialog={closeOrphanedAgentDialog}
        dialogOpen={showOrphanedAgentDialog}
      ></DialogOrphanedAgent>
      <DialogVerifyDeleteUser
        closeDialog={closeVerifyDeleteUserDialog}
        dialogOpen={showVerifyDeleteDialog}
        onDelete={onDeleteUserConfirmed}
        itemsToDelete={selectedUsers}
      />
      <AddNewUserModal
        openAddNewUserAgentModal={openCreateUserModal}
        onClose={() => setOpenCreateUserModal(false)}
        commonState={commonState}
        agentSearchState={newUserAgentSearchState}
      />
    </>
  );
}

export const UserIdentitiesComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UserIdentities />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
