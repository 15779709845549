import { createGroupTemplate } from '../../../reports/productivity-report/utils/singleDayReport.utils';
import { ApiStatus } from '../../enums/ApiStatus';
import { AtTimelineItem, ITimelineStore } from './atTimeline.models';

export const TIMELINE_STORE_DEFAULT: ITimelineStore = {
  timelineWidth: 0,
  timeAxis: {
    scale: 'hour',
    step: 2
  },
  redrawTimeout: null,
  status: ApiStatus.Loading,
  options: {
    stack: false,
    template: () => '',
    tooltip: {
      delay: 300,
      template: (item: AtTimelineItem) => {
        return item?.metadata?.tooltipTemplate;
      }
    },
    groupTemplate: createGroupTemplate,
    showMinorLabels: true,
    showMajorLabels: false,
    showCurrentTime: false,
    groupHeightMode: 'fixed',
    zoomKey: 'shiftKey',
    verticalScroll: true,
    horizontalScroll: true,
    orientation: { axis: 'both' },
    timeAxis: { scale: 'hour', step: 2 }
  }
};
