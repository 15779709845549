import { useCallback, useState } from 'react';
import { PRODUCTIVE_HRS_DAY } from '../utils/TimeOnTask.view.utils';
import { fetchData } from '../../common/helpers';
import { IGoal } from '../models/IGoal';
import { IGoalState } from '../models/IGoalState';

export const useGoalState = (): IGoalState => {
  const [isGoalLoading, setIsGoalLoading] = useState<boolean>(false);
  const [goal, setGoal] = useState<IGoal>();

  const fetchGoal = useCallback(async (groupId: string) => {
    setIsGoalLoading(true);
    try {
      const cachedData = sessionStorage.getItem(
        `${PRODUCTIVE_HRS_DAY}_${groupId}`
      );
      if (cachedData) {
        setGoal(JSON.parse(cachedData));
      } else {
        const data = await fetchData<IGoal>({
          path: `/api/insights/usergrouptarget?metricId=1&groupId=${groupId}`
        });
        setGoal(data);
        sessionStorage.setItem(
          `${PRODUCTIVE_HRS_DAY}_${groupId}`,
          JSON.stringify(data)
        );
      }
    } catch (error) {
      console.error(`Error fetching usergrouptarget:`, error);
    } finally {
      setIsGoalLoading(false);
    }
  }, []);

  return { goal, isGoalLoading, fetchGoal };
};
