import { analyticEventServiceFunctions } from '_app/serviceFunctions/analyticEventServiceFunctions';
import { getAccountSettings } from '../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular
    .module('app')
    .service('trackEventService', TrackEventService)
    .directive('trackEvent', TrackEvent)
    .directive('trackEventBarren', TrackEventBarren);

TrackEvent.$inject = ['trackEventService'];
TrackEventBarren.$inject = ['trackEventService'];

function TrackEventService() {
    this.track = function (eventTitle, parameters) {
        if (!eventTitle) {
            return;
        }

        const { account } = getAccountSettings();

        parameters = parameters || {
            category: 'All',
            account: account,
            label: account
        };

        analyticEventServiceFunctions.newEvent(eventTitle, parameters);
    };
}

function TrackEvent(trackEventService) {
    return {
        restrict: 'A',
        scope: {
            eventTitle: '@trackEvent'
        },
        link: function (scope, element) {
            element.on('click', function () {
                trackEventService.track(scope.eventTitle);
            });
        }
    };
}

function TrackEventBarren(trackEventService) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.on('click', function () {
                trackEventService.track(attrs.trackEvent);
            });
        }
    };
}
