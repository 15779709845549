import React, { useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { getWorkingHoursColumns } from '../stores/workingHoursColumns.store';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { WorkingHoursColumnSelector } from '../components/WorkingHoursColumnSelector';
import { ReportViewToggle } from '../../../common/components/ReportFilters/components/ReportViewToggle';
import { WorkingHoursExport } from '../components/WorkingHoursExport';
import { WorkingHoursGrid } from '../components/WorkingHoursGrid';
import { WorkingHoursReportHeader } from '../components/WorkingHoursReportHeader';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const WorkingHoursReportView = () => {
  const gridRef = useRef<AgGridReact>(null);

  const handleColumnUpdate = useCallback(() => {
    const { view: currentView } = getReportView();
    const currentColumnState = getWorkingHoursColumns();

    gridRef.current.api?.applyColumnState({
      state: currentColumnState[currentView],
      //needed when columns are re-ordered
      applyOrder: true
    });
  }, []);

  return (
    <>
      <WorkingHoursReportHeader />
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: 4, mb: 2 }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <ReportViewToggle onChange={handleColumnUpdate} />
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            sx={{ mr: { xs: 0, md: 1 }, display: { xs: 'none', md: 'block' } }}
          >
            <WorkingHoursColumnSelector onSubmit={handleColumnUpdate} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}>
            <WorkingHoursExport />
          </Box>
        </Box>
      </Stack>
      <WorkingHoursGrid gridRef={gridRef} onColumnUpdate={handleColumnUpdate} />
    </>
  );
};
