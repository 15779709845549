import { useMemo, useState } from 'react';
import {
  IAgentCommand,
  IAgentCommandDto,
  IAllAgentHealth,
  IComputer,
  IComputerAgentHealth,
  IUserAgentHealth
} from '../models';
import { fetchData } from '../../common/helpers';
import {
  mapToAgentCommand,
  mapToAgentHealth,
  mapToAgentSettings
} from '../utils';
import { getGlobalStatus } from '../utils/health.utils';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';

interface IAgentHealthState {
  loggingEnabled: boolean;
  isLoading: boolean;
  openDiagnostics: boolean;
  diagnosticsAgentCommand: IAgentCommand | null;
  setLoggedOn: () => Promise<boolean>;
  onOpenDiagnostics: (agentCommand: any) => void;
  onCloseDiagnostics: () => void;
  setIsLoading: (isLoading: boolean) => void;
  systemAgentHealth: IUserAgentHealth | null;
  userAgentsHealth: IUserAgentHealth[] | null;
  emptyAgentCommands: () => void;
  refreshAgentCommands: () => Promise<void>;
  onStatusRefresh: () => Promise<void>;
  setLoggingEnabled(isAgentLoggingOn);
  visibleAgentCommands: IAgentCommand[] | null;
  isCommandPending: boolean;
}

interface IAgentHealthStateProps {
  selectedComputer: IComputer;
  userIsSuperAdminOrSupportBasic: boolean;
  selectedComputerId: number | null;
}

export const useAgentHealthState = (
  props: IAgentHealthStateProps
): IAgentHealthState => {
  const {
    selectedComputer,
    userIsSuperAdminOrSupportBasic,
    selectedComputerId
  } = props;

  const [loggingEnabled, setLoggingEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [allAgentCommands, setAllAgentCommands] =
    useState<IAgentCommand[]>(null);
  const [visibleAgentCommands, setVisibleAgentCommands] =
    useState<IAgentCommand[]>(null);
  const [openDiagnostics, setOpenDiagnostics] = useState(false);
  const [diagnosticsAgentCommand, setDiagnosticsAgentCommand] =
    useState<IAgentCommand>(null);

  const [selectedComputerAgentHealth, setSelectedComputerAgentHealth] =
    useState<IComputerAgentHealth | null>(selectedComputer?.healthStatus);

  const { screenshotsAllowed = false } = getPrivacySettings();

  const refreshAgentCommands = async () => {
    setIsLoading(true);
    try {
      const response = await fetchData<IAgentCommandDto[]>({
        path: `/api/agent/cmd/` + selectedComputerId
      });

      const mappedCommands = mapToAgentCommand(response);
      setAllAgentCommands(mappedCommands);
      if (userIsSuperAdminOrSupportBasic) {
        setVisibleAgentCommands(mappedCommands);
      } else {
        //admins only see Restart commands
        const filteredCommands = mappedCommands.filter(
          (cmd) => cmd.code === 'Restart'
        );
        setVisibleAgentCommands(filteredCommands);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isCommandPending: boolean = useMemo(() => {
    if (!allAgentCommands) return false;
    const statusNullExists = allAgentCommands?.some(
      (cmd) => cmd.status === null
    );

    return statusNullExists;
  }, [allAgentCommands]);

  const setLoggedOn = async () => {
    let isAgentLoggingOn = false;
    if (selectedComputerId) {
      const response = await fetchData<any>({
        path: `/api/agent/logging/` + selectedComputerId
      });
      const agentSettings = mapToAgentSettings(response);
      agentSettings?.logSettings?.forEach((element) => {
        if (
          element &&
          element.level !== 'OFF' &&
          element.level !== '0' &&
          element.name
        ) {
          isAgentLoggingOn = [
            'activtrak',
            'activtraklib',
            'svctcom',
            'scthost'
          ].includes(element.name.toLowerCase());
        }
      });
      setLoggingEnabled(isAgentLoggingOn);
      refreshAgentCommands();
    }
    return isAgentLoggingOn;
  };

  const onOpenDiagnostics = (agentCommand: IAgentCommand) => {
    if (agentCommand) {
      setDiagnosticsAgentCommand(agentCommand);
      setOpenDiagnostics(true);
    }
  };

  const onCloseDiagnostics = () => {
    setDiagnosticsAgentCommand(null);
    setOpenDiagnostics(false);
  };

  useMemo(() => {
    setSelectedComputerAgentHealth(selectedComputer?.healthStatus);
  }, [selectedComputer]);

  const systemAgentHealth = useMemo(() => {
    return selectedComputerAgentHealth?.users?.find(
      (user) => user.name === 'SYSTEM'
    );
  }, [selectedComputerAgentHealth]);

  const userAgentsHealth = useMemo(() => {
    return selectedComputerAgentHealth?.users?.filter(
      (user) => user.name !== 'SYSTEM'
    );
  }, [selectedComputerAgentHealth]);

  const emptyAgentCommands = () => {
    setAllAgentCommands(null);
  };

  function appendComputerData(healthStatuses: IComputerAgentHealth[]) {
    // Append computer data with health status
    if (healthStatuses) {
      healthStatuses.forEach(function (healthStatus) {
        if (
          selectedComputer &&
          selectedComputer?.computer === healthStatus.name
        ) {
          selectedComputer.healthStatus = healthStatus;

          selectedComputer.globalStatus = getGlobalStatus(
            selectedComputer.healthStatus
          );
          setSelectedComputerAgentHealth(selectedComputer.healthStatus);
        }
      });
    }
  }

  const onStatusRefresh = async () => {
    refreshAgentCommands();
    const response = await fetchData<IAllAgentHealth>({
      path: `/api/agent/health`
    });
    const computers = mapToAgentHealth(
      userIsSuperAdminOrSupportBasic,
      screenshotsAllowed,
      response.computers
    );

    appendComputerData(computers);
  };

  return {
    loggingEnabled,
    isLoading,
    openDiagnostics,
    diagnosticsAgentCommand,
    setLoggedOn,
    onOpenDiagnostics,
    onCloseDiagnostics,
    setIsLoading,
    systemAgentHealth,
    userAgentsHealth,
    emptyAgentCommands,
    refreshAgentCommands,
    onStatusRefresh,
    setLoggingEnabled,
    visibleAgentCommands,
    isCommandPending
  };
};
