import React from 'react';
import authorization from '../../../common/helpers/authorization';
import { ExportSize } from '../../../common/stores/exportsStore/exportStore.models';
import { EXPORT_DESTINATION_OPTIONS } from '../../../common/stores/exportsStore/exportStore.constants';
import { ReportType } from '../../../common/enums/ReportType';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { createExportPayload } from '../utils/topUsersReport.utils';
import { AsyncExportButton } from '../../../common/components/ReportFilters/components/AsyncExportButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { useTopUsersReportsStore } from '../hooks/useTopUsersReportStore';
import { ITopUserReportStore } from '../models/TopUsersReport.models';
import { ApiStatus } from '../../../common/enums/ApiStatus';

export const TopUsersExport = () => {
  const { response, apiStatus } = useTopUsersReportsStore(
    (s: ITopUserReportStore) => s
  );

  const hasAsyncExport: boolean = authorization.hasFeature(
    BundleFlag.TopUsersReportExport
  );

  const hasLargeExport: boolean = authorization.hasFeature(
    BundleFlag.LargeExport
  );

  if (!hasAsyncExport) return <ExportButtonContainer />;

  return (
    <AsyncExportButton
      reportType={ReportType.TopUsersAndGroups}
      createExportPayload={createExportPayload}
      showExportsLimitedTooltip
      exportableItemCount={
        hasLargeExport ? ExportSize.LargeBatch : ExportSize.SmallBatch
      }
      menuOptions={EXPORT_DESTINATION_OPTIONS}
      isDisabled={!response?.data?.length || apiStatus !== ApiStatus.Loaded}
    />
  );
};
