import { useState } from 'react';
import { SortDirection } from '../../common/enums';
import {
  ReassignToExistingUser,
  ReassignToNewUser
} from '../utils/useDetailAgentColumns';
import Card from '@mui/material/Card';

import { IUserAgent, IUserIdentity } from '../models/IUserIdentity';

import {
  BoxAddAgent,
  AgentDetailHeader,
  DetailAgentGridWrapper
} from '../styles/UserDetail.view.styles';
import AddIcon from '@mui/icons-material/Add';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import { AgentSearchModal } from './AgentSearchModal';
import { useAgentSearchState } from '../hooks/useAgentSearchState';
import { IAgentSearch } from '../models/IAgentSearch';
import { DialogUserSearch } from './DialogUserSearch';
import { useUserSearchState } from '../hooks/useUserSearchState';
import { useAgentAssignedState } from '../hooks/useAgentAssignedState';
import { nullIfEmpty } from '../utils/SearchUtils';
import Button from '@mui/material/Button';
import { useDetailDataSourceColumns } from '../utils/useDetailDataSourceColumns';
import { mapToAgentSearch } from '../utils/AgentMappers';
import Box from '@mui/material/Box';
import { AddNewUserModal } from './AddNewUserModal';
import { IDntDeleteState } from '../models/IDntDeleteState';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';

type ComponentProps = {
  detailState: IIdentityDetailState;
  dntDeleteState: IDntDeleteState;
  isReadOnly: boolean;
};

export const DetailUserDataSource = (props: ComponentProps) => {
  const { detailState, isReadOnly, dntDeleteState } = props;
  const { commonState, isLoadingDetail, selectedIdentity } = detailState;
  const [openUserSearchModal, setOpenUserSearchModal] =
    useState<boolean>(false);
  const [openNewUserModal, setOpenNewUserModal] = useState<boolean>(false);

  //we need an agent search state if they add a new agent
  const agentSearchState = useAgentSearchState();

  //if they reassign to existing users
  const userSearchModalState = useUserSearchState({
    persistSearchToStore: false
  });

  const { onSortAgentOrder, menuAgent, setMenuAgent, sortedAgentDetails } =
    useAgentAssignedState(selectedIdentity);

  const addNewAgentAndCloseModal = (selectedAgents: IAgentSearch[]) => {
    detailState.onAddNewAgent(selectedAgents);
    agentSearchState.setOpenAssignAgentModal(false);
  };

  const onAgentAssignModalOpen = () => {
    agentSearchState.initializeAgents();
    agentSearchState.setOpenAssignAgentModal(true);
    agentSearchState.resetAgentsSelected();
  };

  const onUserSearchClose = async (selectedUser: IUserIdentity) => {
    if (selectedUser) {
      await detailState.moveAgentToUser(
        menuAgent,
        selectedUser,
        selectedIdentity,
        true
      );
    }
    setOpenUserSearchModal(false);
  };

  const handleChangeAgent = (event, option, dataItem: IUserAgent) => {
    setMenuAgent(dataItem);
    const { label } = option;
    if (label === ReassignToExistingUser) {
      userSearchModalState.initializeUserIdentities();
      setOpenUserSearchModal(true);
    }
    if (label === ReassignToNewUser) {
      const agentSearch: IAgentSearch = mapToAgentSearch(
        dataItem,
        selectedIdentity
      );
      agentSearchState.setAgentsSelectedFromAgents([agentSearch]);
      setOpenNewUserModal(true);
    }
  };

  const onNewUserClose = () => {
    setOpenNewUserModal(false);
  };

  const dataSourceGridColumns = useDetailDataSourceColumns(
    handleChangeAgent,
    isReadOnly
  );

  return (
    <Card elevation={3}>
      <AgentDetailHeader>Agents associated with user</AgentDetailHeader>
      <DetailAgentGridWrapper>
        <CustomGrid
          data={sortedAgentDetails}
          columns={dataSourceGridColumns}
          initialSortField="lastLog"
          initialSortDirection={SortDirection.Descending}
          onSortOrder={onSortAgentOrder}
          isLoading={isLoadingDetail}
        />
      </DetailAgentGridWrapper>
      <BoxAddAgent>
        <Box
          sx={{
            display: 'flex',
            pb: 1,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Box>
            <Button
              onClick={onAgentAssignModalOpen}
              startIcon={<AddIcon />}
              color="secondary"
              disabled={isReadOnly}
            >
              Assign agent
            </Button>
          </Box>

          <Box sx={{ pr: 4 }}>
            <Button
              disabled={isReadOnly}
              color="error"
              size="small"
              variant="contained"
              onClick={() => dntDeleteState.setOpenDeleteModal(true)}
            >
              Delete historical data
            </Button>
          </Box>
        </Box>
      </BoxAddAgent>
      <AgentSearchModal
        agentSearchState={agentSearchState}
        onClose={addNewAgentAndCloseModal}
        commonState={commonState}
        //used to add an agent to an existing user
        userDescription={'user ' + selectedIdentity?.singleDescription}
        readOnlyAgentIds={
          selectedIdentity?.agents?.map((agent) => agent.userId) ?? []
        }
      />
      <DialogUserSearch
        userSearchState={userSearchModalState}
        onClose={onUserSearchClose}
        openUserSearchModal={openUserSearchModal}
        agentDescription={
          nullIfEmpty(menuAgent?.userLogonDomain) ??
          menuAgent?.alias ??
          menuAgent?.userId?.toString() ??
          ''
        }
        readOnlyEntityId={selectedIdentity?.id}
      />

      <AddNewUserModal
        openAddNewUserAgentModal={openNewUserModal}
        onClose={() => onNewUserClose()}
        commonState={commonState}
        agentSearchState={agentSearchState}
      />
    </Card>
  );
};
