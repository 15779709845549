import { Role, RoleAccessKeys } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../enums/FeatureFlag';
import { IRoute } from '../../../../models/IRoute';
import { coachingPromoTemplate as angularJsTemplate } from '../templates';

export const appCoachingpromo: IRoute = {
  name: 'app.coachingpromo',
  stateDefinition: {
    url: '/coachingpromo',
    template: angularJsTemplate,
    controller: 'coachingPromoCtrl',
    data: { pageTitle: 'Coach' }
  },
  access: {
    redirectTo: 'app.coaching',
    roleAccessKey: RoleAccessKeys.Coaching,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    excludedBundleFlags: [BundleFlag.CoachInsightsReport],
    featureFlags: [FeatureFlag.ShowPromos]
  }
};

export default appCoachingpromo;
