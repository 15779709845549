import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { NestedListProps } from './NestedCheckboxFilter.props';
import { NestedFilterItem } from './NestedFilterItem';
import { NestedFilterSection } from './NestedFilterSection';
import { IListFilter } from '../../models/IListFilter';
import { getFlattenedObjectArray } from '../../utils/flatten';
import {
  CheckboxFilterContainerSC,
  CheckboxFilterSubmitSC
} from './NesterCheckboxFilter.styles';

export const NestedList = (props: NestedListProps) => {
    const { filterOptions, onSubmit, handleNone = false } = props;

    const [currentFilters, setCurrentFilters] = useState<any[]>(filterOptions);
    const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

    const checkAllStatus = useCallback((currentFilters): boolean => {
      //it's easier to check all states within a nested menu if it's flattened
      const currentFiltersFlattened: IListFilter[] =
        getFlattenedObjectArray(currentFilters);
      //remove the items that we don't care about for this check
      const filteredFilters = currentFiltersFlattened?.filter(
        (item) => item !== null && item.filterValue !== 'All'
      );
      if (filteredFilters.every((item) => item.selected)) {
        return true;
      }
      return false;
    }, []);

    const checkIndeterminateStatus = useCallback((currentFilters): boolean => {
      //it's easier to check all states within a nested menu if it's flattened
      const currentFiltersFlattened: IListFilter[] =
        getFlattenedObjectArray(currentFilters);
      //remove the items that we don't care about for this check
      const filteredFilters = currentFiltersFlattened?.filter(
        (item) => item !== null && item.filterValue !== 'All'
      );
      if (filteredFilters.every((item) => item.selected)) {
        return false;
      } else if (filteredFilters.some((item) => item.selected)) {
        return true;
      }
      return false;
    }, []);

    useEffect(() => {
      const isAllChecked = () => {
        const index = filterOptions.findIndex(
          (item) => item.filterValue === 'All' && item.selected
        );
        return index > -1;
      };
      if (filterOptions.length) {
        const indeterminateStatus = checkIndeterminateStatus(filterOptions);
        setIsIndeterminate(indeterminateStatus);
        const allStatus = isAllChecked();
        setIsAllChecked(allStatus);
      }
    }, [checkAllStatus, checkIndeterminateStatus, filterOptions]);

    const updateAllStatus = useCallback((menuItems, status) => {
      return menuItems.map((menuItem) => {
        const updatedMenuItem = menuItem;

        if (!updatedMenuItem?.children) {
          updatedMenuItem.selected = status;
        } else {
          updatedMenuItem.children.forEach((item) => {
            item.selected = status;
          });
        }
        return updatedMenuItem;
      });
    }, []);

    const updateIndividualStatus = useCallback((menuItems, filter) => {
      return menuItems.map((menuItem) => {
        const updatedMenuItem = menuItem;

        if (!updatedMenuItem?.children) {
          if (updatedMenuItem?.filterValue === filter) {
            updatedMenuItem.selected = !updatedMenuItem.selected;
          }
        } else {
          updatedMenuItem.children.forEach((item) => {
            if (item?.filterValue === filter) {
              item.selected = !item.selected;
            }
          });
        }
        return updatedMenuItem;
      });
    }, []);

    const handleSelected = useCallback(
      (filter: string) => {
        //because of timing issues, need to use the latest state of 'currentFilters' for an update
        setCurrentFilters((prevState) => {
          let selections = [];
          if (filter === 'All') {
            if (checkAllStatus(prevState)) {
              setIsAllChecked(false);
              //uncheck all items
              selections = updateAllStatus(prevState, false);
              //make sure 'All' sets 'handleNone' if parent requested
              if (handleNone) {
                selections = selections.map((item) => {
                  if (item.filterValue === 'All') {
                    item.handleNone = true;
                  }
                  return item;
                });
              }
            } else {
              setIsAllChecked(true);
              //check all items
              selections = updateAllStatus(prevState, true);
              //make sure 'All' sets 'handleNone' if parent requested
              if (handleNone) {
                selections = selections.map((item) => {
                  if (item.filterValue === 'All') {
                    item.handleNone = false;
                  }
                  return item;
                });
              }
            }
            setIsIndeterminate(false);
          } else {
            setIsAllChecked(false);
            //toggle matched items
            selections = updateIndividualStatus(prevState, filter);

            //now check the indeterminate state of latest changes
            const isIndeterminate = checkIndeterminateStatus(selections);
            setIsIndeterminate(isIndeterminate);

            //extra cleanup using current checked states
            const currentIsAllChecked = checkAllStatus(selections);
            selections = selections.map((item) => {
              if (item.filterValue === 'All') {
                //make sure it's set to false when indeterminate so it's not added as a filter
                if (currentIsAllChecked) {
                  item.selected = true;
                } else {
                  item.selected = false;
                }
                //make sure 'All' sets 'handleNone' if parent requested
                if (handleNone) {
                  if (!currentIsAllChecked && !isIndeterminate) {
                    item.handleNone = true;
                  } else {
                    item.handleNone = false;
                  }
                }
              }
              return item;
            });
          }
          return selections;
        });
      },
      [
        checkAllStatus,
        updateAllStatus,
        handleNone,
        updateIndividualStatus,
        checkIndeterminateStatus
      ]
    );

    const handleSubmit = () => {
      //just send the parent all the checkbox statuses and it can decide how to use them
      onSubmit(currentFilters);
    };

    const getLabel = (item: IListFilter): any => {
      const { label, template } = item;
      if (typeof template === 'function') {
        return template();
      } else {
        return label;
      }
    };

    return (
      <>
        <CheckboxFilterContainerSC
          component="nav"
          aria-labelledby="nested-menu-list"
        >
          {currentFilters?.map((item, i) => {
            if (item?.children?.length) {
              const menuItems = item.children;
              let isParentOpen = false;
              const selectedItemIndex = menuItems.findIndex(
                (item) => item.selected
              );
              if (!isAllChecked && selectedItemIndex > -1) {
                isParentOpen = true;
              }

              return (
                <NestedFilterSection
                  key={`filter-section-${i}`}
                  item={getLabel(item)}
                  subMenuItems={menuItems}
                  onSelected={handleSelected}
                  isParentOpen={isParentOpen}
                />
              );
            } else {
              return (
                <NestedFilterItem
                  key={`filter-section-${i}`}
                  label={item?.label}
                  selected={item?.selected}
                  onClick={() => handleSelected(item?.filterValue)}
                  isIndeterminate={
                    item?.filterValue === 'All' && isIndeterminate
                  }
                />
              );
            }
          })}
        </CheckboxFilterContainerSC>
        <CheckboxFilterSubmitSC>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </CheckboxFilterSubmitSC>
      </>
    );
};
