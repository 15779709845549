import { forwardRef } from 'react';
import { useReportViewStore } from '../../../common/components/ReportFilters/hooks/useReportViewToggleStore';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import {
  getProductivityBubble,
  getProductivityColor,
  getTotalPercent
} from '../../common/utils/reports.utils';
import { gray0, primaryFontSize } from '../../../common/constants';
import { getProductivityPercent } from '../utils/topUsersReport.utils';
import { ITopUserReport } from '../models/TopUsersReport.models';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ProductivityRatioTooltipProps = {
  currentRatios: {
    status: number;
    duration: number;
  }[];
  total: number;
};

type TopUsersProductivityRatioProps = {
  data: ITopUserReport;
};

const ProductivityRatioTooltip = forwardRef<
  HTMLDivElement,
  ProductivityRatioTooltipProps
>((props, ref) => {
  const { currentRatios, total } = props;

  return (
    <Box ref={ref}>
      {currentRatios?.map((item, i) => {
        const { status, duration } = item;

        const label = getProductivityPercent(duration, total);

        return (
          <Box
            key={`productivity-tooltip-${duration}-${i}`}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {getProductivityBubble(status, primaryFontSize, true)}
            <Typography sx={{ ml: 1, color: gray0 }}>{label}</Typography>
          </Box>
        );
      })}
    </Box>
  );
});

const ProductivityRatioBars = forwardRef<
  HTMLDivElement,
  ProductivityRatioTooltipProps
>((props, ref) => {
  const { currentRatios, total, ...rest } = props;

  return (
    <Box
      ref={ref}
      {...rest}
      sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
    >
      {currentRatios?.map((item, i) => {
        const { status, duration } = item;

        const percent = getTotalPercent(duration, total, 2);
        const color = getProductivityColor(status);
        return (
          <Box
            key={`productivity-bar-${duration}-${i}`}
            sx={{
              minWidth: '1px',
              width: `${percent}%`,
              height: '24px',
              backgroundColor: color
            }}
          />
        );
      })}
    </Box>
  );
});

export const TopUsersProductivityRatio = (
  params: TopUsersProductivityRatioProps,
  largestDuration: number
) => {
  const { data } = params;

  const view = useReportViewStore((s) => s.view);

  const currentRatios =
    view === ReportViewTypes.DETAILED
      ? [
          { duration: data.productiveActive, status: 1 },
          { duration: data.productivePassive, status: 2 },
          { duration: data.unproductiveActive, status: -1 },
          { duration: data.unproductivePassive, status: -2 },
          { duration: data.undefinedActive, status: 0 },
          { duration: data.undefinedPassive, status: -3 }
        ]
      : [
          { duration: data.productive, status: 1 },
          { duration: data.unproductive, status: -1 },
          { duration: data.undefined, status: 0 }
        ];

  return (
    <Tooltip
      title={
        <ProductivityRatioTooltip
          currentRatios={currentRatios}
          total={data.total}
        />
      }
    >
      <ProductivityRatioBars
        currentRatios={currentRatios}
        total={largestDuration}
      />
    </Tooltip>
  );
};
