import { getDateSettingsStore } from '../../../common/stores/dateSettingsStore';
import { getHoursValue } from '../../../common/components/ReportFilters/utils/dateRange.utils';
import { TimeFormat } from '../../../common/utils/datetime/enums/dateTimeFormats';
import { ReportTimeSlot } from '../models/reportTimelineFilters.models';

export const getListOfReportTimes = (interval: number): ReportTimeSlot[] => {
  const dateSettings = getDateSettingsStore();
  const isTimeFormat24Hour = dateSettings.isTimeFormat24Hour;
  const labelFormat = isTimeFormat24Hour
    ? TimeFormat.TwentyFourHourNoSeconds
    : TimeFormat.TwelveHourNoSeconds;

  const items = [];

  new Array(24).fill(null).forEach((_, index) => {
    const baseValue = getHoursValue({
      hour: index,
      minutes: 0,
      format: TimeFormat.TwentyFourHourNoSeconds
    });
    items.push({
      value: baseValue,
      label: getHoursValue({ hour: index, minutes: 0, format: labelFormat })
    });

    //TODO: if feature changes to 5-min intervals, we need to update this to instead use a loop to push the below for each interval within an hour
    const intervalValue = getHoursValue({
      hour: index,
      minutes: interval,
      format: TimeFormat.TwentyFourHourNoSeconds
    });
    items.push({
      value: intervalValue,
      label: getHoursValue({
        hour: index,
        minutes: interval,
        format: labelFormat
      })
    });
  });

  return items;
};

export const getShiftedReportTimesList = (
  arr: ReportTimeSlot[],
  itemIndex: number
): ReportTimeSlot[] => {
  //remove the item at the selected index from the array, and move it to the end of the array
  const [shiftedItem] = arr.splice(itemIndex, 1);
  return [...arr, shiftedItem];
};

export const removeColons = (time: string): string => {
  return time.replace(/:/g, '');
};
