import { IAuthenticatedUserService, ILastLogin } from './interfaces';
import { BehaviorSubject } from 'rxjs';
import { inMemoryStore } from '../inMemoryStore';

/**
 * Parses JSON string until an object is created.
 *
 * @param {string | null} stringToParse - Stringified JSON
 * @returns {T} - Generic Object
 */
const _parseJson = <T>(stringToParse: string | null): T => {
    if (!stringToParse) {
        return {} as T;
    }

    try {
        const parsedString = JSON.parse(stringToParse);
        return typeof parsedString === 'string'
            ? (_parseJson(parsedString) as T)
            : (parsedString as T);
    } catch (error) {
        console.error(
            `ActivTrak Error: Cannot load object due to invalid JSON.\nObject String: ${stringToParse}\nError: `,
            error
        );
        return {} as T;
    }
};

/**
 * Parses JSON as LastLogin
 *
 * @param {string | null} stringToParse - Stringified JSON
 * @returns {ILastLogin} - LastLogin Object
 */
const _parseLastLoginJson = (stringToParse: string | null): ILastLogin => {
    return _parseJson<ILastLogin>(stringToParse);
};

const _showNewLoginNotification: BehaviorSubject<boolean> =
    inMemoryStore.get('utilities.showNewLoginNotification') ||
    new BehaviorSubject(false);

inMemoryStore.set(
    'utilities.showNewLoginNotification',
    _showNewLoginNotification
);

/**
 * Updates the value if subscribers have to show New Login Notification.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @param {boolean} showNewLoginNotification - Boolean
 * @returns {void} Void
 */
const setShowNewLoginNotification = (
    showNewLoginNotification: boolean
): void => {
    if (typeof showNewLoginNotification !== 'boolean') {
        console.error(`ActivTrak Error: Passed value is not a boolean.`);
        return;
    }

    _showNewLoginNotification.next(showNewLoginNotification);
};

/**
 * Retrieves showNewLoginNotification object as an RxJS observable.
 *
 * _Use `.subscribe(callback)` to subscribe to changes to the globals object_
 *
 * @returns {BehaviorSubject<boolean>} - Observable showNewLoginNotification Object
 */
const getShowNewLoginNotification = (): BehaviorSubject<boolean> => {
    return _showNewLoginNotification;
};

/**
 * Retrieves stringified lastLogin object from local storage.
 *
 * @returns {ILastLogin} - LastLogin Object
 */
const _getLastLoginFromStorage = (): ILastLogin => {
    const lastLoginString = window.localStorage.getItem('activTrak.lastLogin');
    return _parseLastLoginJson(lastLoginString);
};

/**
 * Load lastLogin object from window if defined or as an RxJS observable
 */
const _lastLogin: BehaviorSubject<ILastLogin> =
    inMemoryStore.get('utilities.lastLogin') ||
    new BehaviorSubject(_getLastLoginFromStorage());

inMemoryStore.set('utilities.lastLogin', _lastLogin);

/**
 * Save lastLogin object with the properties of date and IP.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @param {ILastLogin} lastLogin - lastLogin Object
 * @returns {void} Void
 */
const setLastLogin = (lastLogin: ILastLogin): void => {
    if (!lastLogin) {
        return;
    }

    try {
        // Convert object into JSON string
        const stringifiedLastLogin = JSON.stringify(lastLogin);
        window.localStorage.setItem(
            'activTrak.lastLogin',
            stringifiedLastLogin
        );
        _lastLogin.next(lastLogin);
    } catch (error) {
        console.error(
            'ActivTrak Error: Cannot set lastLogin due to JSON stringify error.\nlastLogin:',
            lastLogin,
            '\nError:',
            error
        );
    }
};

/**
 * Checks the last login date matches the saved last login date.
 *
 * @param {ILastLogin} lastLogin - lastLogin Object
 * @returns {void} Void
 */
const checkLastLogin = (lastLogin: ILastLogin): void => {
    if (!lastLogin) {
        console.error('ActivTrak Error: Last login is not provided.');
        return;
    }

    if (!lastLogin.date || !lastLogin.ip) {
        console.info(
            'ActivTrak Info: Cannot check last login due to a missing one of last login parameters.\nLastLogin:',
            lastLogin
        );
        return;
    }

    if (!_lastLogin.value.ip || !_lastLogin.value.date) {
        setLastLogin(lastLogin);
        return;
    }

    if (
        _lastLogin.value.ip !== lastLogin.ip ||
        _lastLogin.value.date !== lastLogin.date
    ) {
        setLastLogin(lastLogin);
        console.warn(
            `ActivTrak Warning: New login detected.\nNew Login IP/Date: ${lastLogin.ip}/${lastLogin.date}\nOld Login IP/Date: ${_lastLogin.value.ip}/${_lastLogin.value.date}`
        );
        // setShowNewLoginNotification(true);
    }
};

/**
 * Removes the stored lastLogin object from local storage and sets the loaded lastLogin object to an empty object.
 *
 * _Notifies all observable subscribers of the change._
 *
 * @returns {void} Void
 */
const clearLastLogin = (): void => {
    window.localStorage.removeItem('activTrak.lastLogin');
    _lastLogin.next({
        ip: undefined,
        date: undefined
    });
};

export const authenticatedUserService: IAuthenticatedUserService = {
    setShowNewLoginNotification,
    getShowNewLoginNotification,
    setLastLogin,
    checkLastLogin,
    clearLastLogin
};
