import React from 'react';
import { isEmpty } from '../utilities/helpers';
import { InfoPanelVariant } from '../components/Panels';
import { convertSecondsToHoursString } from '../utilities/helpers';
import { hasRole } from '../../../_reactivtrak/src/common/helpers/authorization';
import { Role } from '../../../_reactivtrak/src/common/enums';

class TopActivity {
  constructor({ topActivities, type, variant, classes }) {
    const _generateFilteredActivities = (activities) => {
      return activities && activities.sort((a, b) => b.duration - a.duration);
    };

    const _generateTopActivityLink = (filteredActivities) => {
      const isSupportAdvanced = hasRole(Role.SupportAdvanced);
      const link = isEmpty(filteredActivities) ? (
        'No Activity'
      ) : isSupportAdvanced ? (
        <span id="id_19e37e94-a50c-483c-96f5-e2bf7439d4b7">{filteredActivities[0].name}</span>
      ) : (
        <a href={_generateLink(filteredActivities[0])} id="id_19e37e94-a50c-483c-96f5-e2bf7439d4b7">
          {filteredActivities[0].name}
        </a>
      );

      if (variant === InfoPanelVariant.Stacked) {
        return link;
      } else {
        return <strong>{link}</strong>;
      }
    };

    const _generateLink = ({ type: activityType, id: activityId }) => {
      const report = activityType === 'Website' ? 'topwebsites' : 'topapplications';
      const field = activityType === 'Website' ? 'site' : 'app';
      return `/#/app/reports/${report}?${field}=${activityId}&tab=users`;
    };

    const _generateDetails = (filteredActivities) => {
      const isSupportAdvanced = hasRole(Role.SupportAdvanced);
      return (
        (filteredActivities &&
          filteredActivities.map((item) => {
            return {
                label: isSupportAdvanced ? (
                    <div style={classes.activityLink}>
                        <strong>{convertSecondsToHoursString(item.duration, 'hrs')}</strong> {item.name}
                    </div>
                ) : (
                    <a style={classes.activityLink} href={_generateLink(item)}>
                        <strong>{convertSecondsToHoursString(item.duration, 'hrs')}</strong> {item.name}
                    </a>
                )
            };
          })) ||
        null
      );
    };

    this.variant = type;
    this.filteredActivities = _generateFilteredActivities(topActivities);
    this.link = _generateTopActivityLink(this.filteredActivities);
    this.details = _generateDetails(this.filteredActivities);
  }
}

export default TopActivity;
