import { useCallback, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import { IActivity } from '../../common/models/IActivity';
import {
  IActivityDto,
  IActivityDtoApiResponse
} from '../../common/models/IClassificationActivityDto';
import { IGroup } from '../../common/models/IGroup';
import { SortDirection } from '../../common/enums/SortDirection';
import { NotificationType } from '../../common/enums/NotificationType';
import { mapToActivity } from '../../common/helpers/classifications';
import { sorter } from '../../common/utils/sorter/sorter';
import {
  GroupStateProps,
  IGroupMember,
  IGroupMemberDto,
  IGroupWithMembersDto,
  IGroupState,
  IMemberAdded,
  IMember,
  IGroupMembersDtoApiResponse
} from '../models';
import { MemberType } from '../enums';
import {
  filterMembers,
  mapGroupWithMembersFilter,
  mapGroupWithMembersMix,
  mapGroupWithMembersType,
  mapMembersToAddMembers
} from '../utils';
import { WHITESPACE_REG_EX } from '../../common/constants';
import {
  mapIActivityDtoDefinition,
  mapIGroupMemberAdminDtoDefinition,
  mapIGroupWithMembersDefinition,
  mapIMemberDtoDefinition
} from '../utils/groupMappingDefinitions';
import { mapApiObjectTypes } from '../../dashboard/utils/apiValidations.utils';
import { invalidateEntitiesCache } from '../../common/stores/entityStore/entityStore';

export const useGroupState = ({ service }: GroupStateProps): IGroupState => {
  const {
    fetchGroup,
    fetchGroupActivities,
    fetchClientMembers,
    fetchDeviceMembers,
    addClientMembers,
    addDeviceMembers,
    deleteMembers
  } = service;

  const [group, setGroup] = useState<IGroup>();
  //members already assigned to a group
  const [groupMembers, setGroupMembers] = useState<IGroupMember[]>();
  //original members is like an auto-reset before new search/filter
  const [originalGroupMembers, setOriginalGroupMembers] =
    useState<IGroupMember[]>();

  //all available members in account
  const [allGroupMembers, setAllGroupMembers] = useState<IGroupMember[]>();
  //original members is like an auto-reset before new search/filter
  const [originalAllGroupMembers, setOriginalAllGroupMembers] =
    useState<IGroupMember[]>();
  const [groupActivities, setGroupActivities] = useState<IActivity[]>();
  const [isGroupLoading, setIsGroupLoading] = useState<boolean>(false);
  const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
  const [groupNotification, setGroupNotification] =
    useState<NotificationType>();
  // using existingMembers to avoid Hook’s infinite loop
  const existingMembers = useRef([]);

  const getGroup = useCallback(
    async (groupId: number, params?: object): Promise<void> => {
      setIsGroupLoading(true);
      setIsMembersLoading(true);
      try {
        const groupApiResponse: IGroupMembersDtoApiResponse = await fetchGroup(
          groupId,
          params
        );

        if (typeof groupApiResponse !== 'undefined') {
          const data: IGroupWithMembersDto =
            mapIGroupWithMembersDefinition(groupApiResponse);

          const mappedData: IGroupWithMembersDto = {
            ...data,
            clients: data.clients.map((client) =>
              mapApiObjectTypes(client, mapIGroupMemberAdminDtoDefinition)
            ),
            devices: data.devices.map((device) =>
              mapApiObjectTypes(device, mapIGroupMemberAdminDtoDefinition)
            )
          };

          const clientGroupMembers: IGroupMember[] = mappedData?.clients?.map(
            (gm: IGroupMemberDto): IGroupMember => {
              const id = gm?.memberId;
              const type = MemberType.User;
              return {
                id: id,
                uniqueId: `${id}-${type.toLowerCase()}-${gm?.memberName
                  .toLowerCase()
                  .replace(WHITESPACE_REG_EX, '')}`,
                name: gm?.memberName,
                alias: gm?.memberAlias,
                type: type,
                domain: gm?.memberDomain,
                groupId: groupId,
                selected: false
              };
            }
          );

          const deviceGroupMembers: IGroupMember[] = mappedData?.devices?.map(
            (gm: IGroupMemberDto): IGroupMember => {
              const id = gm?.memberId;
              const type = MemberType.Computer;
              return {
                id: id,
                uniqueId: `${id}-${type.toLowerCase()}-${gm?.memberName
                  .toLowerCase()
                  .replace(WHITESPACE_REG_EX, '')}`,
                name: gm?.memberName,
                alias: gm?.memberAlias,
                type: type,
                domain: gm?.memberDomain,
                groupId: groupId,
                selected: false
              };
            }
          );

          const grp: IGroup = {
            id: mappedData?.groupId,
            name: mappedData?.groupName,
            userCount: clientGroupMembers?.length,
            computerCount: deviceGroupMembers?.length,
            type: mapGroupWithMembersType(mappedData),
            userPreview: null,
            computerPreview: null,
            clientGroupMembers: clientGroupMembers,
            deviceGroupMembers: deviceGroupMembers,
            selected: false,
            mix: mapGroupWithMembersMix(mappedData),
            filter: mapGroupWithMembersFilter(mappedData)
          };

          setGroup(grp);
          const members = clientGroupMembers?.concat(deviceGroupMembers);
          // members need to be saved to a ref here for getGroupMembers, so we can avoid Hook’s infinite loop
          existingMembers.current = members;
          setGroupMembers(members);
          setOriginalGroupMembers(members);
          setIsMembersLoading(false);
        }
      } catch (error) {
        //keep page in loading state since group does not exist, but show notification
        setGroupNotification({ msg: 'Group does not exist', type: 'error' });
        console.error(`ActivTrak Error: Unable to load Group ${groupId}`);
      } finally {
        setIsGroupLoading(false);
        setIsMembersLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getGroupMembers = useCallback(
    async (groupId: number): Promise<void> => {
      setIsMembersLoading(true);
      try {
        const getClientMembers = async () => {
          return await fetchClientMembers();
        };
        const getDeviceMembers = async () => {
          return await fetchDeviceMembers();
        };

        const promises = [];
        promises.push(getClientMembers());
        promises.push(getDeviceMembers());
        const [clientDataApiRes, deviceDataApiRes] = await Promise.all(
          promises
        );

        const clientData = clientDataApiRes.map((response) =>
          mapApiObjectTypes(response, mapIMemberDtoDefinition)
        );

        const deviceData = deviceDataApiRes.map((response) =>
          mapApiObjectTypes(response, mapIMemberDtoDefinition)
        );

        const clientMembers: IMember[] = clientData.map((item) => {
          const type = MemberType.User;
          item.type = type;
          item.windowsdomain = item.domain;
          item.uniqueId = `${item.id}-${type?.toLowerCase()}-${item?.name
            ?.toLowerCase()
            .replace(WHITESPACE_REG_EX, '')}`;
          return item;
        });
        const deviceMembers: IMember[] = deviceData.map((item) => {
          const type = MemberType.Computer;
          item.type = type;
          item.windowsdomain = item.domain;
          item.uniqueId = `${item.id}-${type?.toLowerCase()}-${item?.name
            ?.toLowerCase()
            .replace(WHITESPACE_REG_EX, '')}`;
          return item;
        });

        const availableMembers: IMember[] = clientMembers
          .concat(deviceMembers)
          .map((item) => {
            if (groupId > 0 && typeof existingMembers.current !== 'undefined') {
              item.selected = existingMembers.current.some(function (el) {
                return el.uniqueId === item.uniqueId;
              });
            }
            return item;
          });

        const members: IGroupMember[] = orderBy(
          availableMembers,
          'selected',
          'desc'
        ).map((member: IMember) => {
          const id = member.id;
          const type = member.type;

          const selectableMember: IGroupMember = {
            id: member.id,
            uniqueId: `${id}-${type?.toLowerCase()}-${member.name
              ?.toLowerCase()
              .replace(WHITESPACE_REG_EX, '')}`,
            name: member.name,
            alias: member.alias,
            type: type,
            domain: member.windowsdomain,
            alreadySelected: member.selected,
            selected: member.selected,
            groupId: null
          };
          if (member.selected) {
            selectableMember.groupId = groupId;
          }
          return selectableMember;
        });
        setAllGroupMembers(members);
        setOriginalAllGroupMembers(members);
      } catch (error) {
        setGroupNotification({
          msg: 'Unable to load All Group Members',
          type: 'error'
        });
        console.error(
          `ActivTrak Error: Unable to load All Group Members for group ${groupId}`,
          error
        );
      } finally {
        setIsMembersLoading(false);
      }
    },
    // using existingMembers dependency to avoid Hook’s infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [existingMembers]
  );

  const getGroupActivities = useCallback(async (groupId: number = null) => {
    try {
      const groupActivitiesApiResponse: IActivityDtoApiResponse[] =
        await fetchGroupActivities(groupId);

      const data: IActivityDto[] = groupActivitiesApiResponse?.map(
        (activityApiResponse) =>
          mapApiObjectTypes(activityApiResponse, mapIActivityDtoDefinition)
      );

      const activities = data.map(mapToActivity);
      setGroupActivities(activities);
    } catch (error) {
      setGroupNotification({
        msg: 'Failed to get group classifications.',
        type: 'error'
      });
      console.error(
        `ActivTrak Error: Failed to get group classifications for group ${groupId}`,
        error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(
    async (groupId: number): Promise<void> => {
      await getGroup(groupId);
      getGroupMembers(groupId);
      getGroupActivities(groupId);
    },
    [getGroup, getGroupMembers, getGroupActivities]
  );

  const searchGroupMembers = useCallback(
    (filter: string): void => {
      if (filter) {
        const filtered = filterMembers(originalGroupMembers, filter);
        setGroupMembers(filtered);
      } else {
        setGroupMembers(originalGroupMembers);
      }
    },
    [originalGroupMembers]
  );

  const searchAllGroupMembers = useCallback(
    (filter: string): void => {
      if (filter) {
        const filtered = filterMembers(originalAllGroupMembers, filter);
        setAllGroupMembers(filtered);
      } else {
        setAllGroupMembers(originalAllGroupMembers);
      }
    },
    [originalAllGroupMembers]
  );

  const setSelectedGroupMembers = useCallback(
    (selectedItems: IGroupMember[]): void => {
      setGroupMembers((prevState) => {
        const resetData = prevState.map((item) => {
          return { ...item, selected: false };
        });
        selectedItems.forEach((m) => {
          const i = resetData.findIndex(
            (member) => m.uniqueId === member.uniqueId
          );
          if (i === -1) {
            return;
          }
          resetData[i] = m;
        });
        return resetData;
      });
    },
    []
  );

  const setSelectedAllGroupMembers = useCallback(
    (selectedItems: IGroupMember[]): void => {
      setAllGroupMembers((prevState) => {
        const currentData = [...prevState];
        selectedItems.forEach((m) => {
          const i = currentData.findIndex(
            (member) => m.uniqueId === member.uniqueId
          );
          if (i === -1) {
            return;
          }
          currentData[i].selected = !!m.selected;
        });
        return currentData;
      });
    },
    []
  );

  const setSortedGroupMembers = useCallback(
    (sortDirection: SortDirection, sortOrderBy: string): void => {
      const newOrder = sorter(groupMembers, sortDirection, sortOrderBy);
      setGroupMembers(newOrder);
    },
    [groupMembers]
  );

  const setSortedAllGroupMembers = useCallback(
    (sortDirection: SortDirection, sortOrderBy: string): void => {
      const newOrder = sorter(allGroupMembers, sortDirection, sortOrderBy);
      setAllGroupMembers(newOrder);
    },
    [allGroupMembers]
  );

  const getAllMemberCount = () =>
    originalAllGroupMembers?.filter((gm) => gm.alreadySelected || gm.selected).length;

  const setGroupMemberCount = (
    userIncrement?: number,
    computerIncrement?: number
  ): void => {
    setGroup((prevState) => {
      const currentGroup = prevState;
      const newUserCount = (currentGroup.userCount += userIncrement);
      currentGroup.userCount = newUserCount < 0 ? 0 : newUserCount;

      const newComputerCount = (currentGroup.computerCount +=
        computerIncrement);
      currentGroup.computerCount = newComputerCount < 0 ? 0 : newComputerCount;

      return currentGroup;
    });
  };

  const addGroupMembers = useCallback(
    async (groupId: number, newMembers: IGroupMember[]): Promise<void> => {
      if (!newMembers || newMembers.length <= 0) {
        return;
      }
      try {
        const finalMembers: IMemberAdded[] = mapMembersToAddMembers(newMembers);

        const clientIdsToAdd = finalMembers
          .filter((x) => x.member.type === MemberType.User)
          .map((x) => x.member.id);
        const deviceIdsToAdd = finalMembers
          .filter((x) => x.member.type === MemberType.Computer)
          .map((x) => x.member.id);

        if (!clientIdsToAdd.length && !deviceIdsToAdd.length) {
          throw new Error('No members to add');
        }

        const addClients = async () => {
          return await addClientMembers(groupId, clientIdsToAdd);
        };

        const addDevices = async () => {
          return await addDeviceMembers(groupId, deviceIdsToAdd);
        };

        const promises = [];
        if (clientIdsToAdd.length) {
          promises.push(addClients());
        }
        if (deviceIdsToAdd.length) {
          promises.push(addDevices());
        }

        await Promise.all(promises).then(function () {
          //RELOAD THE VIEW
          init(groupId);
          invalidateEntitiesCache();
          setGroupNotification({
            msg: 'Members added to group',
            type: 'success'
          });
        });
      } catch (error) {
        setGroupNotification({
          msg: 'We could not add any members to this group. Please try again later.',
          type: 'error'
        });
        console.error(
          `ActivTrak Error: Unable to add new group members for group ${groupId}`
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deleteGroupMembers = useCallback(
    async (selectedMembers: IGroupMember[]): Promise<void> => {
      try {
        if (
          (selectedMembers?.length ?? 0) &&
          (selectedMembers[0]?.groupId ?? 0)
        ) {
          const groupId = selectedMembers[0].groupId;

          await deleteMembers(groupId, selectedMembers);

          //DO NOT RELOAD THE VIEW AFTER DELETIONS BECAUSE IT'S MORE PLEASING
          //remove selected from groupMembers
          const filterMembersToRemove = (prevState: IGroupMember[]) => {
            const currentData: IGroupMember[] = [...prevState];
            return currentData.filter((m) => {
              const i = selectedMembers.findIndex(
                (member) => m.uniqueId === member.uniqueId
              );
              if (i === -1) {
                return m;
              }
            });
          };

          setGroupMembers((prevState) => {
            return filterMembersToRemove(prevState);
          });

          setOriginalGroupMembers((prevState) => {
            return filterMembersToRemove(prevState);
          });

          //update statuses for allGroupMembers
          setAllGroupMembers((prevState) => {
            const currentData: IGroupMember[] = [...prevState];

            selectedMembers.forEach((m) => {
              const i = currentData.findIndex(
                (member) => m.uniqueId === member.uniqueId
              );
              if (i === -1) {
                return;
              }
              currentData[i].alreadySelected = false;
              currentData[i].selected = false;
              currentData[i].groupId = null;
            });
            return currentData;
          });

          //update local group userCount and computerCount counts after delete (total selected group member count)
          const newUserCount = selectedMembers.filter(
            (member) => member.type === MemberType.User
          ).length;
          const newComputerCount = selectedMembers.filter(
            (member) => member.type === MemberType.Computer
          ).length;
          setGroupMemberCount(
            newUserCount ? -newUserCount : 0,
            newComputerCount ? -newComputerCount : 0
          );

          invalidateEntitiesCache();
          const message =
            selectedMembers.length > 1
              ? `${selectedMembers.length} members were removed from ${group.name}.`
              : `${selectedMembers[0].name} was removed from ${group.name}.`;
          setGroupNotification({ msg: message, type: 'success' });
        }
      } catch (error) {
        setGroupNotification({
          msg: 'We could not remove any members from this group. Please try again later.',
          type: 'error'
        });
        console.error(
          `ActivTrak Error: Unable to delete members: ${selectedMembers}`,
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [group]
  );

  return {
    group,
    groupMembers,
    allGroupMembers,
    groupActivities,
    isGroupLoading,
    isMembersLoading,
    init, //initial load if groups array doesn't exist
    getGroup, //subsequent forced calls
    getGroupMembers,
    getAllMemberCount,
    searchGroupMembers,
    searchAllGroupMembers,
    setSelectedGroupMembers,
    setSelectedAllGroupMembers,
    setSortedGroupMembers,
    setSortedAllGroupMembers,
    addGroupMembers,
    deleteGroupMembers,
    groupNotification,
    setGroupNotification,
    originalAllGroupMembers
  };
};
