import { RoleAccessKeys } from '../../../_reactivtrak/src/common/enums/RoleAccessKeys';
import { getAccountSettings } from '../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { generateParameters } from '../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import './dashboard.component.js';

angular.module('app').controller('DashboardCtrl', DashboardCtrl);

DashboardCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$stateParams',
    '$state',
    '$timeout',
    'atDashboardWidgetService',
    'localStorageService',
    'messagesService',
    'notificationService',
    'authorizationService',
    'envService'
];

function DashboardCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $state,
    $timeout,
    atDashboardWidgetService,
    localStorageService,
    msg,
    notificationService,
    authorizationService
    // eslint-disable-next-line no-unused-vars
    // envService
    //These parameters are not used however they are needed here to ensure that
    // they are resolved as a dependency prior to binding the report. DO NOT REMOVE
) {
    const accountSettings = getAccountSettings();

    function authorize(role) {
        return (
            authorizationService.hasRole([authorizationService.roles.superAdmin]) ||
            authorizationService.hasRoleAccess(role)
        );
    }

    $scope.hasProductivityRoleAccessKey = authorize(RoleAccessKeys.Productivity);
    $scope.hasTopUsersRoleAccessKey = authorize(RoleAccessKeys.TopUsers);
    $scope.hasTopApplicationsRoleAccessKey = authorize(RoleAccessKeys.TopApplications);
    $scope.hasTopWebsitesRoleAccessKey = authorize(RoleAccessKeys.TopWebsites);
    $scope.hasTopCategoriesRoleAccessKey = authorize(RoleAccessKeys.TopCategories);

    $scope.canViewClassifications = authorizationService.hasAnyRole([
        authorizationService.roles.configurator,
        authorizationService.roles.admin,
        authorizationService.roles.supportAdvanced,
        authorizationService.roles.superAdmin
    ]);

    $scope.canEditCategories = function () {
        return authorizationService.hasAuthorizationLevel('edit', 'app.settings.categories');
    };

    $scope.hasFeature = function (featureName) {
        return authorizationService.hasFeature(featureName);
    };

    $scope.shortLabelsFilter = function (maxSize, property, formatLabel) {
        return kendo.template(function (data) {
            let value = data[property];
            if (formatLabel) {
                value = formatLabel(value);
            }
            if (value.length > maxSize) {
                value = value.substring(0, maxSize - 1) + 'â€¦';
            }
            return value;
        });
    };

    $scope.hasTeamPulse = authorizationService.hasRouteByName('app.teampulse');

    $scope.onDateRangeChanged = function (useLocalStorage, dates) {
        const { fromDate, toDate } = dates.getDates();
        const dateDifference = parseInt((moment(toDate).toDate() - moment(fromDate).toDate()) / (1000 * 60 * 60 * 24));
        const period = localStorageService.get('aggregateProductivityWidgetPeriod-' + accountSettings.username);

        if (useLocalStorage && period) {
            $scope.aggregatePeriod = period;
        } else if (dateDifference < 29) {
            $scope.aggregatePeriod = 'Day';
        } else if (dateDifference >= 29 && dateDifference < 180) {
            $scope.aggregatePeriod = 'Week';
        } else if (dateDifference >= 180 && dateDifference < 366 * 5 - 3) {
            $scope.aggregatePeriod = 'Month';
        } else {
            $scope.aggregatePeriod = 'Year';
        }
    };

    $scope.bindReport = function (reportFilters) {
        $scope.reportFilters = reportFilters;
        $scope.parameters = generateParameters(reportFilters, {});
        $scope.onDateRangeChanged(false, reportFilters.dates);
        atDashboardWidgetService.callBindFunctions();
    };

    $scope.topCategoriesChartRedraw = function () {
        const chart = angular.element('#topCategoriesChart').data('kendoChart');
        if (chart !== undefined) {
            chart.redraw();
        }
    };

    angular.element(document).bind('keydown', function (e) {
        if ($state.current.name === 'app.dashboard' && e.ctrlKey && e.keyCode === 80) {
            e.preventDefault();
            $state.go('app.print-dashboard');
        }
    });

    function toggleIdleFilter(value) {
        $rootScope.$broadcast('updateIdleFilter', value);
    }

    $scope.switchOptions = {
        label: 'Passive',
        onChange: toggleIdleFilter
    };

    $scope.$watch(
        function () {
            return accountSettings.overLimitOrOverStorage;
        },
        function (newValue) {
            $scope.overLimitOrOverStorage = newValue;
        }
    );

    $scope.$watch('aggregatePeriod', function (newValue) {
        if (newValue) {
            localStorageService.set('aggregateProductivityWidgetPeriod-' + accountSettings.username, newValue);
        }
    });

    $timeout(function () {
        if ($stateParams.updated) {
            notificationService.showNotification(msg.get('upgradeProcessedSuccessfully'), 'success', 0);
        }
    });
}
