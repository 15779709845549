import { StateService } from "@uirouter/core";
import { fetchData } from "../../../helpers";
import { getInjector } from "../../../third-party/angular2react/useInjector";
import { resolveLandingState } from "../../../../../../_entry/resolveLandingState";

/**
 * Handles navigation to ActivTrak Academy
 * @param direct // TODO: Reconsider exposing this parameter
 */
export const redirectToAcademy = async (direct:boolean = false, original_uri: string = '') => {
  
  const url = await fetchData<string>({
    path: '/admin/v1/accountsettings/academy'
  });

  const academy_url = original_uri !== '' ?
    `${url}&redirect_uri=${encodeURIComponent(original_uri)}` :
    url;

  // if (direct) {
  //   window.history.replaceState({}, '', '/');
  // }
  

  const $injector = getInjector();
  const $state = $injector.get<StateService>('$state');
  
  // Route the user to their landing state first
  // Trigger window navigation once in the langing state
  // This sets a valid back state in the window.history trace
  const [landing, params] = resolveLandingState();
  $state.go(landing, params ?? {}, { location: 'replace'}).then(() => {
    const target = direct ? '_self' : '_blank';
    window.open(academy_url, target, 'noreferrer=true');
  });
  
};

