import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { ColumnState } from 'ag-grid-community';
import { ITopUsersColumnsStore } from '../models/TopUsersReport.models';
import {
  TOP_USERS_DETAILED_COLUMNS_DEFAULT,
  TOP_USERS_SUMMARY_COLUMNS_DEFAULT
} from '../constants/topUsersReport.constants';
import { REPORT_COLUMNS_STORAGE_PREFIX } from '../../common/constants/reports.constants';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';

export const topUsersColumnsStore = createStore<ITopUsersColumnsStore>()(
  persist(
    () => {
      return {
        [ReportViewTypes.SUMMARY]: TOP_USERS_SUMMARY_COLUMNS_DEFAULT,
        [ReportViewTypes.DETAILED]: TOP_USERS_DETAILED_COLUMNS_DEFAULT
      };
    },
    {
      name: REPORT_COLUMNS_STORAGE_PREFIX + 'topUsers'
    }
  )
);

export const setTopUsersColumnsStore = (
  newColumns: Partial<Record<ReportViewTypes, ColumnState[]>>
) => {
  topUsersColumnsStore.setState(newColumns);
};

export const getTopUsersColumnsStore = () => {
  const cols = topUsersColumnsStore.getState();
  //remove any null values caused by column access removal first
  //this should not be needed, but let's throw it in for safety
  Object.keys(cols).forEach((key) => {
    cols[key] = cols[key].filter((col) => col);
  });

  return cols;
};

export const resetTopUsersColumnsStore = () => {
  topUsersColumnsStore.setState(
    {
      [ReportViewTypes.SUMMARY]: TOP_USERS_SUMMARY_COLUMNS_DEFAULT,
      [ReportViewTypes.DETAILED]: TOP_USERS_DETAILED_COLUMNS_DEFAULT
    },
    true
  );
};
