import React, { useRef, useEffect, useState, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useResizeDetector } from 'react-resize-detector';
import { CustomGridProps } from './CustomGrid.models';
import { SortDirection } from '../../enums/SortDirection';
import {
  useDefaultSkeletonLoader,
  getCellContent,
  resetDataSelections
} from './CustomGrid.utils';
import { useScrollbarSize } from './CustomGrid.hooks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ArrowUpIcon, ArrowDownIcon } from '../../assets/Icons';
import { BulkCheckboxHeader, BulkCheckboxBody } from './BulkCheckboxes';
import { BulkFilterMenu } from './BulkFilterMenu';
import { BulkFilterMenuExpanded } from './BulkFilterMenuExpanded';
import Box from '@mui/material/Box';
import {
  LoaderContainer,
  GridContainerSC,
  GridRowHeaderSC,
  GridRowBodySC,
  GridCellSC,
  GridCellHeaderSC,
  ClickableRow,
  BaseGridContainer
} from './CustomGrid.styles';
import { CheckBoxBulkSelector } from './BulkSelection.styles';

const CustomGrid = React.memo((props: CustomGridProps) => {
  const {
    data,
    columns,
    uniqueRowProp = 'id',
    onClickRow,
    rowDisableCondition = (): boolean => {
      return false;
    },
    isVirtualized,
    isLoading,
    skeletonLoaderTemplate,
    initialSortField,
    initialSortDirection = SortDirection.Ascending,
    hasBulkSelection,
    bulkSelectFilterOptions = [],
    bulkSelectFilterExpandedOptions = [],
    onSortOrder = () => {},
    onSelectBulkFilter = () => {},
    onSubmitBulkFilter = () => {},
    onSelectAllClick = () => {},
    height = '65vh',
    bulkFilterMenuPopperPlacement,
    featureDisabled,
    shouldNotScroll
  } = props;

  const [currentColumns, setCurrentColumns] = useState(null);
  const [orderBy, setOrderBy] = useState<string>(null);
  const [orderDirection, setOrderDirection] = useState<SortDirection>(
    initialSortDirection || SortDirection.Ascending
  );
  const [bulkOption, setBulkOption] = useState(0);
  const [scrollMargin, setScrollMargin] = useState(0);

  //get current browser's scrollbar width to use in grid header
  const scrollbarSize = useScrollbarSize();

  const defaultSkeletonLoader = useDefaultSkeletonLoader(
    columns?.length,
    hasBulkSelection
  );

  //Align headers with body text in columns
  //Scrollbars add margin to body rows. But header row cannot be included in scrolling (virtualized) grid so it does not get extra margin.
  //This adds an observer to the grid ref to monitor resizing and determine if vertical scrollbar is present. If so, add margin to header row to match body rows
  const targetRef = useRef<HTMLDivElement>();
  const onResize = useCallback(() => {
    const element = targetRef.current;
    const isScrollable = element?.offsetWidth > element?.clientWidth;
    setScrollMargin(isScrollable ? scrollbarSize : 0);
  }, [targetRef, scrollbarSize]);
  useResizeDetector({ onResize, targetRef });

  //set the initial sort order
  useEffect(() => {
    //handle when columns are hidden
    const visibleColumns = columns.filter((obj) => {
      if (obj.hidden) return false;
      else {
        return true;
      }
    });
    setCurrentColumns(visibleColumns);

    const initialOrderField =
      orderBy ||
      initialSortField ||
      (visibleColumns && visibleColumns[0] && visibleColumns[0].field);
    setOrderBy(initialOrderField);
  }, [orderBy, columns, initialSortField]);

  const handleSortOrder = async (newOrderBy) => {
    setOrderBy(newOrderBy);
    const newOrderDirection =
      orderDirection === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending;
    setOrderDirection(newOrderDirection);

    onSortOrder(newOrderDirection, newOrderBy);
  };

  const filterSelectedItems = (newData) => {
    const tmpData = newData || data;
    return tmpData.filter((item) => item.selected);
  };

  const filterData = (filterOption) => {
    const filterType = filterOption.filter;
    const filterValue = filterOption.filterValue;
    return data.filter((item) => {
      return item[filterType] === filterValue;
    });
  };

  const isHeaderCheckboxIndeterminate = () => {
    if (data) {
      if (data.every((item) => item.selected)) {
        return false;
      } else if (data.some((item) => item.selected)) {
        return true;
      }
    }
    return false;
  };

  const setRowCheckboxStatus = (dataItems, resetList) => {
    const newData = [...resetList];

    dataItems.forEach((dataItem) => {
      for (let i = 0; newData.length > i; i++) {
        const item = newData[i];
        if (item[uniqueRowProp] === dataItem[uniqueRowProp]) {
          item.selected = !item.selected;
          i = newData.length;
        }
      }
    });
    return newData;
  };

  const handleSelectAllClick = async (event) => {
    event && event.stopPropagation();

    let selectedValue = undefined;
    if (isHeaderCheckboxIndeterminate()) {
      selectedValue = false;
    }

    const newData = data.map((item) => {
      const { selected } = item;
      if (!(selectedValue === false)) {
        selectedValue = !selected;
      }
      item.selected = selectedValue;
      return item;
    });

    //pass selected items to parent so it can use them however needed
    onSelectAllClick(filterSelectedItems(newData));
  };

  const handleSelectSingleClick = (dataItems) => {
    const newData: any[] = setRowCheckboxStatus(dataItems, data);

    onSelectAllClick(filterSelectedItems(newData));
  };

  const handleSelectBulkFilter = async (event, option, index) => {
    //handle what happens when a header checkbox filter dropdown item is selected

    //if selection is all, then just trigger handleSelectAllClick
    if (option?.filter === 'all') {
      return handleSelectAllClick(event);
    }

    const bulkFilteredData = filterData(option);

    //make sure all items are unchecked before changing to new filter
    const resetList = resetDataSelections(data);

    if (bulkFilteredData?.length) {
      const newData = setRowCheckboxStatus(bulkFilteredData, resetList);

      //pass selected items to parent so it can use them however needed
      onSelectAllClick(filterSelectedItems(newData));
      onSelectBulkFilter(option, index);
    }
  };

  const getGridHeader = () => {
    return (
      <GridRowHeaderSC style={{ marginRight: scrollMargin }} role="row">
        {hasBulkSelection ? (
          <GridCellHeaderSC role="columnheader">
            <CheckBoxBulkSelector>
              <BulkCheckboxHeader
                data={data}
                onSelectAllClick={(e) => handleSelectAllClick(e)}
                disabled={featureDisabled}
              />
              {bulkSelectFilterOptions.length ? (
                <BulkFilterMenu
                  icon={<ArrowDropDownIcon />}
                  indexOption={bulkOption}
                  setIndexOption={setBulkOption}
                  menuOptions={bulkSelectFilterOptions}
                  onChangeOption={handleSelectBulkFilter}
                  hideselectedoption={true}
                  disableSelectedItem={true}
                  popperPlacement={bulkFilterMenuPopperPlacement}
                  disabled={featureDisabled}
                />
              ) : bulkSelectFilterExpandedOptions.length ? (
                <BulkFilterMenuExpanded
                  icon={<ArrowDropDownIcon />}
                  menuOptions={bulkSelectFilterExpandedOptions}
                  onSubmitFilters={onSubmitBulkFilter}
                  hideselectedoption={true}
                  disableSelectedItem={true}
                  disabled={featureDisabled}
                />
              ) : null}
            </CheckBoxBulkSelector>
          </GridCellHeaderSC>
        ) : null}
        {currentColumns &&
          currentColumns.map((headerCell, index) => {
            const { field, headerName, isSortable, width, align } = headerCell;

            return (
              <GridCellHeaderSC
                className={isSortable ? 'sortable' : ''}
                onClick={() => isSortable && handleSortOrder(field)}
                cellwidth={width}
                key={`grid-column-${field || index}`}
                style={{ justifyContent: align ?? 'left' }}
                role="columnheader"
              >
                <span>
                  {isSortable && orderBy === field ? (
                    orderDirection == SortDirection.Ascending ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    )
                  ) : (
                    <></>
                  )}
                  {headerName}
                </span>
              </GridCellHeaderSC>
            );
          })}
      </GridRowHeaderSC>
    );
  };

  const getCells = (rowItem) => {
    return (
      <>
        {hasBulkSelection ? (
          <GridCellSC role="cell">
            <Box>
              <BulkCheckboxBody
                rowItem={rowItem}
                onSelectSingleClick={handleSelectSingleClick}
                disabled={featureDisabled}
                rowDisableCondition={rowDisableCondition}
              />
            </Box>
          </GridCellSC>
        ) : null}
        {currentColumns &&
          currentColumns.map((columnDataRow, index) => {
            const { width, align } = columnDataRow;
            return (
              <GridCellSC
                cellwidth={width}
                key={`grid-cell-${rowItem[uniqueRowProp] || index}-${index}`}
                style={{ justifyContent: align ?? 'left' }}
                role="cell"
              >
                {getCellContent(rowItem, columnDataRow)}
              </GridCellSC>
            );
          })}
      </>
    );
  };

  const getVirtualRow = ({ index, style }) => {
    //based on virtualized grid props
    const rowItem = data[index];
    const { selected } = rowItem;
    return onClickRow ? (
      <ClickableRow
        style={style}
        className={`clickable-row ${
          rowDisableCondition(rowItem) ? ' row-disabled' : ''
        } ${selected ? 'selected' : ''}`}
        key={`grid-row-${rowItem[uniqueRowProp] || index}`}
        onClick={(event) => onClickRow(rowItem, event)}
        role="row"
      >
        {getCells(rowItem)}
      </ClickableRow>
    ) : (
      <GridRowBodySC
        style={style}
        className={`${rowDisableCondition(rowItem) ? 'row-disabled' : ''} ${
          selected ? 'selected' : ''
        }`}
        key={`grid-row-${rowItem[uniqueRowProp] || index}`}
        role="row"
      >
        {getCells(rowItem)}
      </GridRowBodySC>
    );
  };

  const getBaseRows = () => {
    //based on regular grid stylings
    return (
      data &&
      data.map((rowItem, index) => {
        const { selected } = rowItem;
        return onClickRow ? (
          <ClickableRow
            selected={selected}
            className={`clickable-row ${
              rowDisableCondition(rowItem) ? ' row-disabled' : ''
            } ${selected ? 'selected' : ''}`}
            key={`grid-row-${rowItem[uniqueRowProp] || index}`}
            onClick={(event) => onClickRow(rowItem, event)}
            role="row"
          >
            {getCells(rowItem)}
          </ClickableRow>
        ) : (
          <GridRowBodySC
            selected={selected}
            className={`${rowDisableCondition(rowItem) ? 'row-disabled' : ''} ${
              selected ? 'selected' : ''
            }`}
            key={`grid-row-${rowItem[uniqueRowProp] || index}`}
            role="row"
          >
            {getCells(rowItem)}
          </GridRowBodySC>
        );
      })
    );
  };

  if (isLoading) {
    return (
      <LoaderContainer loaderHeight={height}>
        {skeletonLoaderTemplate
          ? skeletonLoaderTemplate
          : defaultSkeletonLoader}
      </LoaderContainer>
    );
  }
  return (
    <GridContainerSC role="table">
      {getGridHeader()}
      {isVirtualized ? (
        <Box height={height}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                itemCount={data?.length}
                height={height}
                width={width}
                itemSize={52}
                outerRef={targetRef}
              >
                {getVirtualRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      ) : (
        <BaseGridContainer ref={targetRef} shouldNotScroll={shouldNotScroll}>
          {getBaseRows()}
        </BaseGridContainer>
      )}
    </GridContainerSC>
  );
});

export default CustomGrid;
