import React, { useRef } from 'react';
import { useScheduledTimelineStore } from '../hooks/useScheduledTimelineStore';
import ReportTimelineFilter from '../../common/components/ReportTimelineFilter';
import {
  getListOfReportTimes,
  getShiftedReportTimesList
} from '../../common/utils/reportTimelineFilter.utils';
import {
  handleEndDate,
  handleStartDate
} from '../utils/scheduleAdherence.utils';

const ScheduledTimelineFilter = () => {
  const shiftTimes = useScheduledTimelineStore((s) => s);

  const startShiftTimesList = useRef(getListOfReportTimes(30)).current;
  //re-use, but do not mutate, the startShiftTimesList array
  const endShiftTimesList = useRef(
    getShiftedReportTimesList([...startShiftTimesList], 0)
  ).current;

  return (
    <ReportTimelineFilter
      selectedTimeRange={shiftTimes}
      startTimesList={startShiftTimesList}
      endTimesList={endShiftTimesList}
      onStartDateChange={handleStartDate}
      onEndDateChange={handleEndDate}
    />
  );
};

export default ScheduledTimelineFilter;
