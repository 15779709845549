import { useMemo } from "react";
import { getInjector } from "../third-party/angular2react/useInjector";

export const useUIRouterQueryString = () => {
  const queryString = useMemo(() => {
   
    const $injector = getInjector();

    const $location: any = $injector.get('$location');

    return {
      get: (param: string) => $location.search()[param]
    };
  },[]);

  return queryString;
};
