import moment from 'moment';
import { DateFormat, TimeFormat } from './enums/dateTimeFormats';
import { getDateSettingsStore } from '../../stores/dateSettingsStore';

/**
 * Returns the account's time format setting
 */
export const getTimeFormat = (): TimeFormat => {
  const { isTimeFormat24Hour } = getDateSettingsStore();
  return isTimeFormat24Hour ? TimeFormat.TwentyFourHour : TimeFormat.TwelveHour;
};

/**
 * Returns the account's date format setting
 */
export const getDateFormat = (): DateFormat => {
  const { isDateFormatDDMM } = getDateSettingsStore();
  return isDateFormatDDMM
    ? DateFormat.DayFirstFormat
    : DateFormat.MonthFirstFormat;
};

/**
 * Returns the account's date/time format setting
 */
export const getDateTimeFormat = (): string =>
  `${getDateFormat()} ${getTimeFormat()}`;

/**
 * Returns the current date in the account's timezone, formatted according to the provided date format,
 * defaulting to the API date format 'YYYY-MM-DD'.
 */
export const getTodayInAccountTimezone = (
  dateFormat: DateFormat = DateFormat.ApiDateFormat
): string => {
  const { timezoneKey } = getDateSettingsStore();
  return moment.tz(timezoneKey).format(dateFormat);
};
