import React, { useEffect, useMemo, useState } from 'react';
import { IComputer } from '../models';
import { postData } from '../../common/helpers';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNotifications } from '../../common/services/Notifications';
import { CommandGrid } from './CommandGrid';
import { AgentUserChecks } from './AgentUserChecks';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import { TabPanel } from '../../common/components/Tabs/TabPanel';

import {
  AgentHealthModalHeader,
  HeaderLeft,
  HeaderRight,
  OSVersionContainer,
  ParamContainer,
  ParamTitle
} from '../styles';
import { sendAgentCommand } from '../utils';
import { DiagnosticsModal } from './DiagnosticsModal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogActions from '@mui/material/DialogActions';
import { useAuthorization } from '../../common/services/Authorization';
import { Role } from '../../common/enums';
import { CommandType } from '../enums/CommandType';
import { defaultLogSettings } from '../constants/LogSettings';
import { useAgentHealthState } from '../hooks/useAgentHealthState';

type ComponentProps = {
  open: boolean;
  selectedComputer: IComputer;
  onClose: () => void;
};

export const AgentHealthModal = (props: ComponentProps) => {
  const { open, selectedComputer, onClose } = props;
  const authorization = useAuthorization();
  const userIsSuperAdminOrSupportBasic = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.SupportBasic
  ]);
  const notificationService = useNotifications();
  const [tab, setTab] = useState<number>(0);

  const agentVersionSupportsDiagnosticCommand = useMemo(() => {
    if (selectedComputer?.agentVersion) {
      const agentVersionArray = selectedComputer.agentVersion.split('.');
      if (
        agentVersionArray &&
        agentVersionArray.length > 1 &&
        agentVersionArray[0] >= '8' &&
        agentVersionArray[1] >= '6'
      ) {
        return true;
      }
    }
    return false;
  }, [selectedComputer]);

  const agentHealthState = useAgentHealthState({
    selectedComputer,
    userIsSuperAdminOrSupportBasic,
    selectedComputerId: selectedComputer?.id
  });

  const {
    loggingEnabled,
    isLoading,
    openDiagnostics,
    diagnosticsAgentCommand,
    setLoggedOn,
    emptyAgentCommands,
    refreshAgentCommands,
    setLoggingEnabled,
    systemAgentHealth,
    onStatusRefresh,
    userAgentsHealth,
    onOpenDiagnostics,
    onCloseDiagnostics,
    visibleAgentCommands,
    isCommandPending
  } = agentHealthState;

  useEffect(() => {
    setLoggedOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComputer]);

  const close = () => {
    onClose();
    setTab(0);
    emptyAgentCommands();
  };

  const onRestartAgent = () => {
    issueCommand(CommandType.Restart);
  };

  const onRunDiagnostics = () => {
    issueCommand(CommandType.Diagnostics);
  };

  const issueCommand = (command: CommandType) => {
    sendAgentCommand(selectedComputer.id, CommandType[command]).then(() => {
      refreshAgentCommands();
    });
  };

  const toggleAgentLogging = async () => {
    const newLogging = loggingEnabled
      ? defaultLogSettings.off
      : defaultLogSettings.on;
    try {
      await postData<unknown>({
        path: `/api/agent/logging/` + selectedComputer.id,
        args: newLogging
      });
      notificationService.success('Successfully updated agent logging');
    } catch (response) {
      notificationService.error('Error updating agent logging.');
    }
    setLoggingEnabled(!loggingEnabled);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  function tabProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`
    };
  }

  return (
    <Dialog open={open} onClose={close} maxWidth={'xl'} fullWidth={true}>
      <DialogTitle id="agent-health-dialog-title">
        Agent Health Status
      </DialogTitle>
      <DialogContent id="agent-health-dialog-content">
        <AgentHealthModalHeader>
          <HeaderLeft>
            <ParamContainer>
              <ParamTitle>Computer:</ParamTitle> {selectedComputer?.computer}{' '}
              {selectedComputer?.windowsDomain
                ? '(' + selectedComputer.windowsDomain + ')'
                : ''}
            </ParamContainer>
            <OSVersionContainer>
              <ParamTitle>Version:</ParamTitle>{' '}
              {getOSIcon(selectedComputer?.os)}{' '}
              <span>{selectedComputer?.agentVersion}</span>
            </OSVersionContainer>
            <ParamContainer>
              <ParamTitle>Last Contact:</ParamTitle>{' '}
              {systemAgentHealth && (
                <span>{formatDateTime(systemAgentHealth?.time)} UTC</span>
              )}
              {!systemAgentHealth && <span>None</span>}
            </ParamContainer>
          </HeaderLeft>
          <HeaderRight>
            {userIsSuperAdminOrSupportBasic && (
              <>
                <Checkbox
                  checked={loggingEnabled}
                  onClick={toggleAgentLogging}
                  color="primary"
                  title="Agent Logging"
                  hidden={!userIsSuperAdminOrSupportBasic}
                />
                <span> Agent Logging</span>
                <br />
              </>
            )}

            <Button onClick={onStatusRefresh} startIcon={<RefreshIcon />}>
              Refresh
            </Button>
            <br />
            <Button onClick={onRestartAgent} disabled={isCommandPending}>
              Restart Agent
            </Button>
            {userIsSuperAdminOrSupportBasic && (
              <Button
                onClick={onRunDiagnostics}
                disabled={
                  isCommandPending || !agentVersionSupportsDiagnosticCommand
                }
              >
                Run Diagnostics
              </Button>
            )}
          </HeaderRight>
        </AgentHealthModalHeader>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="agent health tab"
            >
              <Tab label="Checks" {...tabProps(0)} />
              <Tab label="Command History" {...tabProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <AgentUserChecks
              isLoading={isLoading}
              systemAgentHealth={systemAgentHealth}
              userAgentsHealth={userAgentsHealth}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <CommandGrid
              agentCommandHistory={visibleAgentCommands}
              openDiagnosticsModal={onOpenDiagnostics}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
      <DiagnosticsModal
        open={openDiagnostics}
        onClose={onCloseDiagnostics}
        agentCommand={diagnosticsAgentCommand}
        computer={selectedComputer?.computer}
        domain={selectedComputer?.windowsDomain}
      />
    </Dialog>
  );
};
