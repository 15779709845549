import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { ColumnState } from 'ag-grid-community';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';

export type WorkingHoursReportProps = {
  refreshTimestamp: number;
  reportFilters: IReportFilters;
  calendarIntegrated: boolean;
};

export type WorkingHoursHeaderProps = {
  reportFilters: IReportFilters;
  isRefreshing: boolean;
  refreshTimestamp: number;
  onRefresh: () => void;
};

export interface IWorkingHoursUserDto {
  uniqueId: string;
  date: string;
  user: string;
  location: string;
  productiveActiveTime: number;
  productivePassiveTime: number;
  unproductiveActiveTime: number;
  unproductivePassiveTime: number;
  undefinedActiveTime: number;
  undefinedPassiveTime: number;
  productiveTime: number;
  unproductiveTime: number;
  undefinedTime: number;
  totalTime: number;
  totalTimeOffline: number;
  screenTime: number;
}

export interface IWorkingHoursUsersDto {
  data: IWorkingHoursUserDto[];
  total: number;
}

export interface IWorkingHoursColumnsStore {
  [ReportViewTypes.SUMMARY]: ColumnState[];
  [ReportViewTypes.DETAILED]: ColumnState[];
}

export interface IWorkingHoursExportExcludeColumnsStore {
  excludeColumns: WorkingHoursExportExcludeColumn[];
}

export enum WorkingHoursDataId {
  Date = 'date',
  User = 'user',
  Location = 'location',
  Productive = 'productiveTime',
  ProductiveActive = 'productiveActiveTime',
  ProductivePassive = 'productivePassiveTime',
  Unproductive = 'unproductiveTime',
  UnproductiveActive = 'unproductiveActiveTime',
  UnproductivePassive = 'unproductivePassiveTime',
  Undefined = 'undefinedTime',
  UndefinedActive = 'undefinedActiveTime',
  UndefinedPassive = 'undefinedPassiveTime',
  ScreenTime = 'screenTime',
  TotalTimeOffline = 'totalTimeOffline',
  TotalTime = 'totalTime'
}

export enum WorkingHoursExportExcludeColumn {
  Date = 'Date',
  User = 'Users',
  Location = 'Location',
  Productive = 'ProductiveTime',
  ProductiveActive = 'ProductiveActiveTime',
  ProductivePassive = 'ProductivePassiveTime',
  Unproductive = 'UnproductiveTime',
  UnproductiveActive = 'UnproductiveActiveTime',
  UnproductivePassive = 'UnproductivePassiveTime',
  Undefined = 'UndefinedTime',
  UndefinedActive = 'UndefinedActiveTime',
  UndefinedPassive = 'UndefinedPassiveTime',
  ScreenTime = 'ScreenTime',
  TotalTimeOffline = 'TotalTimeOffline',
  TotalTime = 'TotalTime'
}
