import React, { useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent
} from 'ag-grid-community';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { useNotifications } from '../../../common/services/Notifications';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { setScheduleAdherenceColumns } from '../stores/scheduleAdherenceColumns.store';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { useScheduledTimelineStore } from '../hooks/useScheduledTimelineStore';
import { IScheduleAdherenceUsersDto } from '../models/ScheduleAdherence.models';
import {
  getDataSource,
  getValidatedScheduledColumns,
  mapSAColumnsToColumnState
} from '../utils/scheduleAdherence.utils';
import AtGrid from '../../../common/components/AtGrid/AtGrid';
import { NoReportData } from '../../common/components/NoReportData';

type ScheduleAdherenceGridProps = {
  gridRef: React.MutableRefObject<AgGridReact>;
  onColumnUpdate: () => void;
};

export const ScheduleAdherenceGrid = (props: ScheduleAdherenceGridProps) => {
  const { gridRef, onColumnUpdate } = props;

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);
  const shiftTimes = useScheduledTimelineStore((s) => s);
  const refreshTimestamp = useRefreshStore((s) => s.refreshTimestamp);

  const notificationService = useNotifications();

  //Default columns. Does not get directly mutated.
  const columns = useRef<ColDef<IScheduleAdherenceUsersDto>[]>(
    getValidatedScheduledColumns()
  );

  const datasource = useMemo(() => {
    return getDataSource(gridRef, notificationService);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef, reportFilters, shiftTimes, refreshTimestamp]);

  const handleGridReady = useCallback(() => {
    onColumnUpdate();
  }, [onColumnUpdate]);

  const handleColumnResize = useCallback(
    (event: ColumnResizedEvent) => {
      const columnState = event.api.getColumnState();
      const { view: currentView } = getReportView();
      mapSAColumnsToColumnState(columnState, currentView);
      onColumnUpdate();
    },
    [onColumnUpdate]
  );

  const handleColumnReorder = useCallback(
    (event: ColumnMovedEvent<IScheduleAdherenceUsersDto>) => {
      const columnState = event.api.getColumnState();
      const { view: currentView } = getReportView();
      setScheduleAdherenceColumns({ [currentView]: columnState });
      onColumnUpdate();
    },
    [onColumnUpdate]
  );

  return (
    <AtGrid
      ref={gridRef}
      columnDefs={columns.current}
      datasource={datasource}
      noRowsOverlayComponent={NoReportData}
      rowModelType="infinite"
      cacheBlockSize={150}
      cacheOverflowSize={1}
      infiniteInitialRowCount={1}
      onGridReady={handleGridReady}
      onColumnResized={handleColumnResize}
      onColumnMoved={handleColumnReorder}
    />
  );
};
