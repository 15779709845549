import Typography from '@mui/material/Typography';
import { PrimaryUploadBox } from '../../styles/TimeOnTask.view.styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Bulk } from '../../utils/TimeOnTask.view.utils';

type OwnProps = {
  currentUploadView: Bulk;
  isLoading: boolean;
};

const BulkUploadSuccess: React.FC<OwnProps> = ({
  currentUploadView,
  isLoading
}) => {
  return (
    Bulk.SUCCESS === currentUploadView && (
      <PrimaryUploadBox>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TaskAltIcon fontSize="large" color="primary" />
            <Typography>File upload successful.</Typography>
          </>
        )}
      </PrimaryUploadBox>
    )
  );
};

export default BulkUploadSuccess;
