import angular from 'angular';
import { Transition } from '@uirouter/core';

import './entry.common';

import { getUserClaims, userTokenStore } from '../_reactivtrak/src/common/helpers/authentication/useUserTokenStore';
import { getRouteDefinitions } from '../_reactivtrak/src/common/config/routing/routes';
import { LoadApplication } from '../_reactivtrak/src/common/services/Application/AppLoad';
import { ensureOpen, setPageTitle } from '../_reactivtrak/src/common/components/Navigation/navigationStore';
import { setInjector } from '../_reactivtrak/src/common/third-party/angular2react/useInjector';
import {
    getAccountId
} from '../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { resolveLandingState } from './resolveLandingState';
import { rewire } from './rewire';

window['debugReactHybrid'] = false;

angular.module('app').config([
    '$uiRouterProvider',
    ($uiRouterProvider) => {
        const registry = $uiRouterProvider.stateRegistry;
        const existing = registry.get();

        if (existing) {
            // TODO: additional cleanup here
            // e.g. clear stores...
        }

        const userToken = userTokenStore.getState().token;
        if (userToken === 'invalid') {
            return;
        }

        //wire up
        const routes = getRouteDefinitions();
        if (routes === 'invalid') {
            throw new Error('Unable to get route model - app will not start.');
        }

        routes.forEach((route) => {
            $uiRouterProvider.stateRegistry.register({
                name: route.name,
                ...route.stateDefinition
            });
        });
    }
]);

// TODO: Relocate - should not be tied to transitions but sidebar events
angular.module('core').run([
    '$transitions',
    ($transitions) => {
        $transitions.onSuccess(
            {
                to: 'app.**'
            },
            (transition) => {
                const $state = transition.router.stateService;
                const current = $state.current.name;
                ensureOpen(current);

                // Page Title:
                const appName = 'ActivTrak';

                if ($state.current?.data?.pageTitle) {
                    const pageTitle = $state.current.data.pageTitle;
                    document.title = (pageTitle && pageTitle.length > 0 ? pageTitle + ' | ' : '') + appName;
                    setPageTitle(pageTitle);
                } else {
                    document.title = appName;
                    setPageTitle(appName);
                }
            }
        );
    }
]);

const loading = async () => {
    return new Promise((resolve) => {
        angular.element(document).ready(() => {
            resolve(true);
        });
    });
};

// TODO: Refactor once solid...
(async () => {
    const userToken = userTokenStore.getState().token;
    if (userToken !== 'invalid') {
        try {
            await LoadApplication();
        } catch (e) {
            console.error(e);
            userTokenStore.setState({ token: 'invalid' });
        }
    }

    await loading();

    const uiViewEl = document.createElement('div');
    uiViewEl.id = 'core-app';
    uiViewEl.setAttribute('ui-view', '');

    document.body.append(uiViewEl);

    // TODO: Consider two elements
    // login does not need to be rewired every time - only app
    if (userToken === 'invalid') {
        const injector = angular.bootstrap(uiViewEl, ['login', 'verify', 'chromebook']);
        injector.get('$rootScope').$apply();
        setInjector(injector);
    } else {
        const injector = angular.bootstrap(uiViewEl, ['login', 'verify', 'chromebook', 'app', 'cc']);
        injector.get('$rootScope').$apply();
        setInjector(injector);
    }
})();


angular.module('login').run([
    '$transitions',
    '$urlService',
    ($transitions, $urlService) => {
        //go to initial state
        const token = userTokenStore.getState().token;
        if (token === 'invalid') {
            return;
        }

        $transitions.onStart(
            {
                to: 'login'
            },
            (trans: Transition) => {
                const _token = userTokenStore.getState().token;
                if (_token === 'invalid') {
                    console.error('unable to load user token');
                    return;
                }

                const claims = getUserClaims();
                if (claims === 'invalid') {
                    console.error('unable to load user claims');
                    return;
                }

                // Deep linking
                // Check and validate next route param
                const { next, search } = $urlService.search();
                if (next && next !== '') {

                    const match_result = search
                        ? $urlService.match({ path: next, search: Object.fromEntries(new URLSearchParams(search)) })
                        : $urlService.match({ path: next });

                    if (match_result?.rule?.type === 'STATE') {
                        const matched_state = match_result.rule.state.name;
                        const matched_state_params = match_result.match ?? {};
                        const matched_state_data = match_result.rule.state.data;

                        return trans.router.stateService.target(matched_state, matched_state_params, {
                            location: matched_state_data?.location ?? true
                        });
                    }
                }

                const [landing, params] = resolveLandingState();

                return trans.router.stateService.target(landing, params ?? {});
            }
        );

        $transitions.onStart({
            to: 'signup-login'
        }, (trans: Transition) => {            
            const _token = userTokenStore.getState().token;
            if (_token === 'invalid') {
                console.error('unable to load user token');
                return trans.router.stateService.target('login');
            }

            const accountId = getAccountId();
            if (
                window.localStorage.getItem(`activTrak.newAccount_${accountId}`) === 'true' ||
                window.localStorage.getItem('activTrak.show_onboarding_wizard') === 'true'
            ) {
                //
                window.localStorage.removeItem(`activTrak.newAccount_${accountId}`);
                window.localStorage.removeItem('activTrak.show_onboarding_wizard');
        
                return trans.router.stateService.target('app.account.wizard', { firstVisit: true });
            }
        });
    }
]);

angular.module('app').run([
    'notificationService',
    '$rootScope',
    '$state',
    '$urlService',
    (notificationService, $rootScope, $state, $urlService) => {
        const messageHandler = (message: MessageEvent) => {
            if (message.data && message.data.name === 'atk:notify') {
                notificationService.showNotification(message.data.title, message.data.status);
            }
        };

        window.addEventListener('message', messageHandler);

        $urlService.rules.otherwise(() => {
            
            const _token = userTokenStore.getState().token;

            // TODO: this could also handle 404
            // For now it routes user to landing page
            if (_token !== 'invalid') {
                const [landing, params] = resolveLandingState();
                $state.go(landing, params ?? {});
            } else {
                // if token invalid back to login
                $state.go('login');
                rewire();
            }
        });

        $state.defaultErrorHandler((ex) => {
            
            console.error('invalid route', ex);

            // TODO: Define a better default page
            // Routing to a profile's default page here will add complexity
            // since would need to check for token valid or invalid, etc...
            // Keeping this simple for now.
            $state.go('app.account.profile');
        });

        $rootScope.$on('$destroy', () => {
            window.removeEventListener('message', messageHandler);
        });
    }
]);
