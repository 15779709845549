import React from 'react';
import { Typography } from '@mui/material';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { mediumFontWeight, primaryFontSize } from '../../common/constants';

const ShiftSchedulingView = () => {
  return (
    <>
      <Typography variant="h6" lineHeight="30px" mb={1} mt={3}>
        Shift Scheduling
      </Typography>
      <Typography
        fontSize={primaryFontSize}
        fontWeight={mediumFontWeight}
        lineHeight="22px"
      >
        This is some copy that tells the user what Shift Scheduling is and how
        to use it
      </Typography>
    </>
  );
};

export const ShiftSchedulingViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <ShiftSchedulingView />
  </ReactivTrakComponentWrapper>
);
