import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { ColumnState } from 'ag-grid-community';
import { IScheduleAdherenceColumnsStore } from '../models/ScheduleAdherence.models';
import {
  SCHEDULE_DETAILED_COLUMNS_DEFAULT,
  SCHEDULE_SUMMARY_COLUMNS_DEFAULT
} from '../constants/scheduleAdherence.constants';
import { REPORT_COLUMNS_STORAGE_PREFIX } from '../../common/constants/reports.constants';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';

export const scheduleAdherenceColumnsStore =
  createStore<IScheduleAdherenceColumnsStore>()(
    persist(
      () => {
        return {
          [ReportViewTypes.SUMMARY]: SCHEDULE_SUMMARY_COLUMNS_DEFAULT,
          [ReportViewTypes.DETAILED]: SCHEDULE_DETAILED_COLUMNS_DEFAULT
        };
      },
      {
        name: REPORT_COLUMNS_STORAGE_PREFIX + 'scheduleAdherence'
      }
    )
  );

export const setScheduleAdherenceColumns = (
  newColumns: Partial<Record<ReportViewTypes, ColumnState[]>>
) => {
  scheduleAdherenceColumnsStore.setState(newColumns);
};

export const getScheduleAdherenceColumns = () => {
  const cols = scheduleAdherenceColumnsStore.getState();
  //remove any null values caused by column access removal first
  //this should not be needed, but let's throw it in for safety
  Object.keys(cols).forEach((key) => {
    cols[key] = cols[key].filter((col) => col);
  });

  return cols;
};

export const resetScheduleAdherenceColumns = () => {
  scheduleAdherenceColumnsStore.setState(
    {
      [ReportViewTypes.SUMMARY]: SCHEDULE_SUMMARY_COLUMNS_DEFAULT,
      [ReportViewTypes.DETAILED]: SCHEDULE_DETAILED_COLUMNS_DEFAULT
    },
    true
  );
};
