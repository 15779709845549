import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { AtTimeline } from '../../../common/components/AtTimeline/AtTimeline';
import { useTimelineStore } from '../../../common/components/AtTimeline/atTimeline.hook';
import {
  setTimelineStatus,
  setTimelineStore
} from '../../../common/components/AtTimeline/atTimeline.store';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { useTimelineData } from '../hooks/useSingleDayReportData';
import moment from 'moment';
import Typography from '@mui/material/Typography/Typography';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { ApiStatus } from '../../../common/enums/ApiStatus';

export const SingleDayReport = () => {
  const { getTimelineData, data } = useTimelineData();
  const { dates, users }: IReportFilters = useReportFilterStore((s) => s);
  const isRefreshing = useRefreshStore((s) => s.isRefreshing);

  // Future use case, create store to use for timeline chart
  const status = useTimelineStore((s) => s.status);
  const options = useTimelineStore((s) => s.options);
  const date = useTimelineStore((s) => s.date);

  // Future use case of dynamic intervals based on date range and user selection
  // Example: Do not allow 5 minute intervals for a date range greater than 2 days
  const getInterval = (): 5 | 15 | 30 | 60 | 120 | 240 => {
    return 5;
  };

  useEffect(() => {
    const { fromDate } = dates.getDates();
    setTimelineStore({ date: moment(fromDate).startOf('day') });
  }, [dates]);

  useEffect(() => {
    if (isRefreshing) {
      setTimelineStatus(ApiStatus.Loading);
    }
  }, [isRefreshing]);

  useEffect(() => {
    if (!date) return;
    getTimelineData(date, getInterval());
  }, [getTimelineData, date, users]);

  return (
    <Box sx={{ mt: 10 }}>
      <Box>Single-Day Report</Box>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {date?.format('YYYY-MM-DD')}
      </Typography>
      <Box mt={4}>
        <AtTimeline
          rows={data.rows}
          segments={data.segments}
          options={options}
          status={status}
          setStatus={setTimelineStatus}
        />
      </Box>
    </Box>
  );
};
