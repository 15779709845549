import theme from '../../theme/theme';

export const ccDashboardClasses = {
  empty: {
    color: theme.palette.text.disabled,
    textAlign: 'center',
    width: '65%',
    margin: '20vh auto 0',
    '& .MuiTypography-h5': {
      ...theme.typography.h5,
      color: theme.palette.text.disabled
    },
    '& .MuiTypography-subtitle2': {
      ...theme.typography.subtitle2,
      color: theme.palette.text.disabled
    }
  },
  dashboardWrapper: {
    paddingBottom: theme.spacing(3)
  },
  dashboardHeader: {
    marginBottom: theme.spacing(2)
  },
  skeletonTabs: {
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
