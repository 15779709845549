import { useCallback, useState } from 'react';
import { blue1, notifError } from '../../common/constants';
import {
  ActiveSinceDays,
  AvailableDays,
  IUserHealthDto,
  IUserHealthState,
  RawResponses,
  UserHealthDonut,
  UserHealthWidgetInitConfig
} from '../models';
import { useDashboard } from '../services/DashboardProvider';
import { fetchUserHealth } from '../utils';

export const useUserHealthState = (): IUserHealthState => {
  const [chartData, setChartData] = useState<UserHealthDonut>();
  const [activeSinceDays, setActiveSinceDays] = useState<ActiveSinceDays>();
  const [dataPercentages, setDataPercentages] = useState<any[]>();
  const [modalState, setModalState] = useState<boolean>(false);
  const [widgetError, setWidgetError] = useState<boolean>(false);
  const [isWidgetStateLoading, setIsWidgetStateLoading] =
    useState<boolean>(false);
  const [rawResponses, setRawResponses] = useState<RawResponses>();

  const dashboard = useDashboard();
  const { setDashboardNotification } = dashboard;

  const processInitialConfig = useCallback(
    (initConfig: UserHealthWidgetInitConfig) => {
      setActiveSinceDays(initConfig.activeSinceDays);
    },
    []
  );

  const processDonutData = useCallback(
    (data: IUserHealthDto): UserHealthDonut => {
      setIsWidgetStateLoading(true);
      if (data) {
        const getPercent = (value: number) => {
          return value > 0
            ? (
                (value * 100) /
                (data.activeUserCount + data.inactiveUserCount)
              ).toFixed()
            : 0;
        };

        setDataPercentages([
          getPercent(data.activeUserCount),
          getPercent(data.inactiveUserCount)
        ]);

        return {
          labels: ['Active', 'Inactive'],
          datasets: [
            {
              data: [data.activeUserCount, data.inactiveUserCount],
              backgroundColor: [blue1, notifError],
              borderWidth: 0
            }
          ]
        } as UserHealthDonut;
      }
    },
    []
  );

  const getCurrentRawResponse = useCallback(
    (activeSinceDays: AvailableDays) => {
      if (rawResponses) {
        switch (activeSinceDays) {
          case 3:
            return rawResponses.threeDays;
          case 7:
            return rawResponses.sevenDays;
          case 14:
            return rawResponses.fourteenDays;
        }
      }
    },
    [rawResponses]
  );

  const getData = useCallback(
    async (initConfig?: UserHealthWidgetInitConfig) => {
      setIsWidgetStateLoading(true);
      try {
        if (initConfig && !activeSinceDays && !rawResponses) {
          processInitialConfig(initConfig);
          const rawResponseSet = {} as RawResponses;

          const sevenDaysPromise = fetchUserHealth(AvailableDays.SevenDays);
          const threeDaysPromise = fetchUserHealth(AvailableDays.ThreeDays);
          const fourteenDaysPromise = fetchUserHealth(
            AvailableDays.FourteenDays
          );
          const [seven, three, fourteen] = await Promise.all([
            sevenDaysPromise,
            threeDaysPromise,
            fourteenDaysPromise
          ]);

          rawResponseSet['sevenDays'] = seven;
          rawResponseSet['threeDays'] = three;
          rawResponseSet['fourteenDays'] = fourteen;

          setRawResponses(rawResponseSet);
          setChartData(processDonutData(rawResponseSet.sevenDays));
        } else {
          const currentResponse = getCurrentRawResponse(activeSinceDays);
          setChartData(processDonutData(currentResponse));
        }
      } catch (error) {
        setDashboardNotification({
          msg: 'Unable to load User Health Data',
          type: 'error'
        });
        setWidgetError(true);
        console.error(`ActivTrak Error: User Health Widget: ${error}`, error);
      }
      setIsWidgetStateLoading(false);
    },
    [
      activeSinceDays,
      rawResponses,
      getCurrentRawResponse,
      processDonutData,
      processInitialConfig,
      setDashboardNotification
    ]
  );

  const init = useCallback(
    (
      initConfig: UserHealthWidgetInitConfig = {
        activeSinceDays: AvailableDays.SevenDays
      }
    ) => {
      if (!chartData) getData(initConfig);
    },
    [chartData, getData]
  );

  return {
    init,
    chartData,
    activeSinceDays,
    rawResponses,
    setActiveSinceDays,
    dataPercentages,
    isWidgetStateLoading,
    modalState,
    setModalState,
    getData,
    widgetError
  };
};
