import React, { useEffect, useState } from 'react';
import { SortDirection } from '../../common/enums';
import { useGroup } from '../services';
import { MemberType } from '../enums';
import {
  filterAllGroupMembers,
  rowDisableConditionAllMembers,
  useMembersColumns
} from '../utils';
import MemberTypeToggle from './MemberTypeToggle';
import MemberSearchBar from './MemberSearchBar';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { Box, DialogContent, Grid } from '@mui/material';
import { IGroupMember } from '../models';

const classes = {
  searchBar: {
    width: '100%',
    boxShadow:
      '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
    '& fieldset': {
      border: 0
    }
  },
  tableElements: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.5em'
  }
};

export function AddGroupMembersDialogContent(props) {
  const { allGroupMembers } = props;

  const [filter, setFilter] = useState<string>();
  const [typeLabel, setTypeLabel] = useState<MemberType>(MemberType.User);
  const [filteredMembers, setFilteredMembers] =
    useState<IGroupMember[]>(undefined);

  const columns = useMembersColumns({ typeLabel });

  const { groupState } = useGroup();
  const {
    isMembersLoading,
    getAllMemberCount,
    setSelectedAllGroupMembers,
    setSortedAllGroupMembers,
    searchAllGroupMembers
  } = groupState;

  useEffect(() => {
    const filtered = filterAllGroupMembers(typeLabel, allGroupMembers);
    setFilteredMembers(filtered);
  }, [typeLabel, allGroupMembers]);

  useEffect(() => {
    searchAllGroupMembers(filter);
  }, [filter, searchAllGroupMembers]);

  return (
    <DialogContent>
      <Grid container spacing={1} sx={classes.tableElements}>
        <Grid item sm={6}>
          <MemberSearchBar filter={filter} setFilter={setFilter} />
        </Grid>
        <Grid item sm={3}>
          <Box fontWeight="500" width="100%" textAlign="right">
            Total Members: {getAllMemberCount()}
          </Box>
        </Grid>
        <Grid item sm={3} style={{ textAlign: 'right' }}>
          <MemberTypeToggle
            exclusive
            defaultType={typeLabel}
            onChange={(type: MemberType) => {
              setTypeLabel(type);
            }}
          ></MemberTypeToggle>
        </Grid>
      </Grid>
      <CustomGrid
        height={'58vh'}
        isVirtualized={true}
        isLoading={isMembersLoading}
        data={filteredMembers}
        columns={columns}
        uniqueRowProp="uniqueId"
        initialSortField="alias"
        initialSortDirection={SortDirection.Descending}
        hasBulkSelection
        onSelectAllClick={setSelectedAllGroupMembers}
        onSortOrder={setSortedAllGroupMembers}
        rowDisableCondition={rowDisableConditionAllMembers}
      />
    </DialogContent>
  );
}
