import React, { useEffect, useState } from 'react';
import { Button, Typography, CardContent } from '@mui/material';
import { AddAccountModal } from './';
import { selectIdProp, createPayload, getSupportRole } from '../utils';
import { AddAccountContainer } from '../styles/supportPortal.styles';
import {
  getLocalStorageItem,
  setLocalStorageItem
} from '../../common/utils/localStorage';
import { supportPortalLocalStorage } from '../constants';
import { AccountCardLimit } from '../enums';
import { IAddAccountButtonProps } from '../models/IComponentProps';
import { IFormValues } from '../models/IRandomTypes';
import { IAddAccountPayloadDto } from '../models/IPayloadDto';

export const AddAccountButton = (props: IAddAccountButtonProps) => {
  const { savedAccounts, setPayload, setActionType } = props;

  const accountsLength = savedAccounts.length;

  const [openModal, setOpenModal] = useState<boolean>(accountsLength === 0);
  const [supportCardLimit, setSupportCardLimit] = useState<number>(0);

  useEffect(() => {
    let role = '';

    role = getLocalStorageItem(supportPortalLocalStorage.supportRole);

    if (!role) {
      role = getSupportRole();
      setLocalStorageItem(supportPortalLocalStorage.supportRole, role);
    }

    setSupportCardLimit(AccountCardLimit[role]);
  }, []);

  const totalCardsDisplayed: number = accountsLength || 0;
  const cardsLeft: number = supportCardLimit - totalCardsDisplayed;
  const cardsLimitReached: boolean = cardsLeft === 0;

  const getPayload = (values: IFormValues, actionType: string) => {
    const { accountSelector, reason, tickets, isImpersonating } = values;

    const identifier = selectIdProp(accountSelector);

    const payload: Partial<IAddAccountPayloadDto> = createPayload(
      identifier,
      reason,
      tickets,
      isImpersonating
    );

    setPayload(payload);
    setActionType(actionType);
  };

  return (
    <>
      <AddAccountContainer>
        <CardContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(true)}
            disabled={cardsLimitReached}
          >
            Add Account
          </Button>
          <Typography pt={1}>Accounts Remaining: {cardsLeft}</Typography>
        </CardContent>
      </AddAccountContainer>
      <AddAccountModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        getPayload={getPayload}
      />
    </>
  );
};
