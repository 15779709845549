import React, { useEffect, useMemo } from 'react';
import { useInjector } from '../../../common/third-party/angular2react/useInjector';
import { WorkingHoursReportProps } from '../models/WorkingHours.models';

export const WorkingHoursReportWrapper = (props: WorkingHoursReportProps) => {
  const { reportFilters, refreshTimestamp, calendarIntegrated } = props;

  const $injector = useInjector();

  const scope: any = useMemo(
    () =>
      Object.assign($injector.get('$rootScope').$new(true), {
        calendarIntegrated
      }),
    [$injector, calendarIntegrated]
  );

  useEffect(() => {
    return () => {
      scope?.$destroy();
    };
  }, [scope]);

  useEffect(() => {
    if (scope?.$$childHead?.bindReport) {
      scope.$$childHead?.bindReport(reportFilters);
    }
  }, [reportFilters, refreshTimestamp, scope.$$childHead]);

  const element = useMemo(() => {
    const compile = (element: HTMLElement) => {
      if (!scope) {
        return;
      }

      const $compile = $injector.get('$compile');

      const template = $compile(element);
      template(scope);

      try {
        scope?.$digest();
      } catch (e) {
        /* */
      }
    };

    const element = React.createElement('wrapped-working-hours-report', {
      ref: compile
    });

    return element;
  }, [$injector, scope]);

  return element;
};
