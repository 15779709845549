export enum ApiStatus {
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error'
}

export type ApiStatusType =
  | ApiStatus.Loading
  | ApiStatus.Loaded
  | ApiStatus.Error;
