import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import AccountWizardForm from '../AccountWizardForm';
import {
  AccountWizardTextContainer,
  SimpleHeader,
  SimpleSubtitle
} from '../../styles';
import { WavingHandIcon } from '../../../common/assets/Icons/WavingHandIcon';
import { useAccountWizardContext } from '../../services';
import { AccountWizardHandOff } from './AccountWizardHandOff';
import { wizardClasses } from '../../../common/components/Wizard/Wizard.styles';
import { setSideBarStatus } from '../../../common/components/Navigation/sideBarStatusStore';

export const AccountWizardWelcome = () => {
  const [welcomeHidden, setWelcomeHidden] = useState(false);
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const { wizardName, accountWizardService } = useAccountWizardContext();

  // make sure sidebar is open after exiting
  useEffect(() => {
    return () => setSideBarStatus('open');
  }, []);

  const handleCancel = () => {
    accountWizardService.cancelWizard(false, true);
    setWelcomeHidden(true);
    setRedirectSuccess(true);
  };

  const handleFormOpen = () => {
    setWelcomeHidden(true);
  };

  return !welcomeHidden ? (
    <AccountWizardTextContainer>
      <Box style={{ flex: 1 }}>
        <SimpleHeader style={{ fontSize: '48.83px' }}>
          {' '}
          <WavingHandIcon />
          Welcome to ActivTrak!
        </SimpleHeader>
        <SimpleSubtitle style={{ paddingBottom: '51px' }}>
          Let&apos;s customize your experience so you can get the most out of
          our platform
        </SimpleSubtitle>{' '}
        <Button
          onClick={handleFormOpen}
          color="primary"
          variant="contained"
          sx={{
            ...wizardClasses.wizardBtn,
            ...wizardClasses.wizardPrimaryColor
          }}
          id={`click-get-started-welcome-${wizardName}`}
        >
          Get started →
        </Button>
        <br></br>
        <br></br>
        <Button
          onClick={handleCancel}
          sx={{
            ...wizardClasses.wizardBtn,
            ...wizardClasses.wizardSecondaryColor
          }}
          id={`click-cancel-welcome-${wizardName}`}
        >
          No thanks, I&apos;ll customize my app later
        </Button>
      </Box>
    </AccountWizardTextContainer>
  ) : redirectSuccess ? (
    <AccountWizardHandOff />
  ) : (
    <AccountWizardForm />
  );
};
