import { ColumnState } from 'ag-grid-community';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';

interface IScheduleAdherenceTimes {
  productiveActiveTime: number;
  productivePassiveTime: number;
  unproductiveActiveTime: number;
  unproductivePassiveTime: number;
  undefinedActiveTime: number;
  undefinedPassiveTime: number;
  productiveTime: number;
  unproductiveTime: number;
  undefinedTime: number;
}

export interface IScheduleAdherenceUserDto {
  uniqueId: string;
  date: string; //date
  user: string;
  location: string;
  shiftTimes: IScheduleAdherenceTimes;
  nonShiftTimes: IScheduleAdherenceTimes;
  productiveGoalAchievement: number;
  productiveHoursGoal: number;
  firstActivity: string; //time
  lastActivity: string; //time
  shiftPunctuality: number;
  earlyDeparture: number;
  screenTime: number;
  pto: number;
  totalProductiveAndTimeOff: number;
  totalTimeOffline: number;
}
export interface IScheduleAdherenceUsersDto {
  data: IScheduleAdherenceUserDto[];
  total: number;
}

export interface IScheduleAdherenceColumnsStore {
  [ReportViewTypes.SUMMARY]: ColumnState[];
  [ReportViewTypes.DETAILED]: ColumnState[];
}

export interface IScheduleAdherenceExportExcludeColumnsStore {
  excludeColumns: ScheduleAdherenceExportExcludeColumn[];
}

export enum ScheduleAdherenceDataId {
  Date = 'date',
  User = 'user',
  Location = 'location',
  FirstActivity = 'firstActivity',
  LastActivity = 'lastActivity',
  TotalTimeOffline = 'totalTimeOffline',
  ProductiveShiftTime = 'shiftTimes.productiveTime',
  ProductiveGoalAchievement = 'productiveGoalAchievement',
  ProductiveHoursGoal = 'productiveHoursGoal',
  TotalProductiveAndTimeOff = 'totalProductiveAndTimeOff',
  ShiftPunctuality = 'shiftPunctuality',
  EarlyDeparture = 'earlyDeparture',
  ProductiveNonShiftTime = 'nonShiftTimes.productiveTime',
  ScreenTime = 'screenTime',
  ProductiveActiveShiftTime = 'shiftTimes.productiveActiveTime',
  ProductivePassiveShiftTime = 'shiftTimes.productivePassiveTime',
  UnproductiveActiveShiftTime = 'shiftTimes.unproductiveActiveTime',
  UnproductivePassiveShiftTime = 'shiftTimes.unproductivePassiveTime',
  UnproductiveShiftTime = 'shiftTimes.unproductiveTime',
  UndefinedActiveShiftTime = 'shiftTimes.undefinedActiveTime',
  UndefinedPassiveShiftTime = 'shiftTimes.undefinedPassiveTime',
  UndefinedShiftTime = 'shiftTimes.undefinedTime'
}

export enum ScheduleAdherenceExportExcludeColumn {
  Date = 'Date',
  User = 'User',
  Location = 'Location',
  FirstActivity = 'FirstActivity',
  LastActivity = 'LastActivity',
  TotalTimeOffline = 'TotalTimeOffline',
  ProductiveShiftTime = 'ProductiveShiftTime',
  ProductiveGoalAchievement = 'ProductiveGoalAchievement',
  ProductiveHoursGoal = 'ProductiveHoursGoal',
  TotalProductiveAndTimeOff = 'TotalProductiveAndTimeOff',
  ShiftPunctuality = 'ShiftPunctuality',
  EarlyDeparture = 'EarlyDeparture',
  ProductiveNonShiftTime = 'ProductiveNonShiftTime',
  ScreenTime = 'ScreenTime',
  ProductiveActiveShiftTime = 'ProductiveActiveShiftTime',
  ProductivePassiveShiftTime = 'ProductivePassiveShiftTime',
  UnproductiveActiveShiftTime = 'UnproductiveActiveShiftTime',
  UnproductivePassiveShiftTime = 'UnproductivePassiveShiftTime',
  UnproductiveShiftTime = 'UnproductiveShiftTime',
  UndefinedActiveShiftTime = 'UndefinedActiveShiftTime',
  UndefinedPassiveShiftTime = 'UndefinedPassiveShiftTime',
  UndefinedShiftTime = 'UndefinedShiftTime'
}
