import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

export const AtTimelineGroupTemplate = (props) => {
  const { group } = props;
  const [location, setLocation] = useState();
  useEffect(() => {
    setLocation(group.metadata.location.join(', ').trim());
  }, [group.metadata.location]);

  return (
    <Box>
      <Typography>
        {group.content}
        {location ? ` (${location})` : ''}
      </Typography>
    </Box>
  );
};
