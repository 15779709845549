import React, {
  ChangeEvent,
  createRef,
  MutableRefObject,
  useEffect,
  useState
} from 'react';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { ICoreCategory } from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import {
  DialogContentStyled,
  StyledSearchBarContainer,
  StyledBoxContainer,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import RadioGroupTeamBox from './RadioGroupTeamBox';
import DialogActionsBox from './DialogActionsBox';
import TextFieldContainer from './TextFieldContainer';
import { useGroupsStore } from '../../../common/hooks';
import { IGroupFilterDto } from '../../../common/models';
import { getDefaultCoreCategory } from '../../utils/TimeOnTask.view.utils';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onGoToStep2: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
  selectedGroupIds: string[];
};

export const Step1Dialog = (props: ComponentProps) => {
  const { open, onClose, onGoToStep2, coreCategoryRef, selectedGroupIds } =
    props;

  const groups = useGroupsStore((s) => s.insightsFilters);

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<IGroupFilterDto[]>();
  const textInputRef = createRef<HTMLInputElement>();
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      if (coreCategoryRef.current.groupId) {
        setSelected(coreCategoryRef.current.groupId);
        setShowSearch(true);
      }
      if (!filtered) {
        setFiltered(groups);
      }
    }
  }, [open, filtered, groups]);

  const handleDropDownSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.toLowerCase().trim();
    if (!input) {
      setFiltered(null);
      return;
    }

    const matches = groups.filter(
      (x) => x.name.toLowerCase().indexOf(input) > -1
    );
    setFiltered(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  const close = () => {
    setSelected(null);
    setShowSearch(false);
    setFiltered(null);
    onClose();
  };

  const goToStep2 = () => {
    setSelected(null);
    setShowSearch(false);
    setFiltered(null);
    onGoToStep2();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSelected(value);
    if (value !== coreCategoryRef.current.groupId) {
      coreCategoryRef.current.categories = [];
      coreCategoryRef.current.targetPercentage =
        getDefaultCoreCategory().targetPercentage;
      coreCategoryRef.current.selectionMode = null;
    }
    coreCategoryRef.current.groupId = value;
    coreCategoryRef.current.groupName = name;
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <Box width={570} maxHeight={530}>
          <DialogTitle>Step 1 of 4: Select a Team</DialogTitle>
          <DialogContentStyled>
            <Typography sx={TotCss.instructions}>
              Pick the team whose performance you want to evaluate.
            </Typography>
            <TextFieldContainer
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
            <StyledBoxContainer hidden={!showSearch}>
              <StyledSearchBarContainer>
                <SearchBar
                  placeholder={'Search Teams'}
                  fieldRef={textInputRef}
                  onClick={(e) => e.stopPropagation()}
                  handleDropDownSearchChange={(e) =>
                    handleDropDownSearchChange(e)
                  }
                />
              </StyledSearchBarContainer>
              <RadioGroupTeamBox
                selected={selected}
                handleChange={handleChange}
                filtered={filtered}
                selectedGroupIds={selectedGroupIds}
              />
            </StyledBoxContainer>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={goToStep2}
              disabled={!selected}
              confirmText={'Go to step 2 of 4'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
