import {
  gray3,
  notifError,
  notifSuccess,
  notifWarn
} from '../../common/constants/styleGuide';
import { StatusTypes } from '../enums/Status';
import { IHealthCheck } from '../models/IComputerAgentHealth';

export const getHealthCheckStatus = (healthChecks: IHealthCheck[]) => {
  let overallStatus = StatusTypes.healthy;
  healthChecks?.forEach((s) => {
    if (s.status === StatusTypes.failed) {
      overallStatus = StatusTypes.failed;
    } else if (
      s.status === StatusTypes.degraded &&
      overallStatus !== StatusTypes.failed
    ) {
      overallStatus = StatusTypes.degraded;
    }
  });
  return overallStatus;
};

export const getHealthStatusColor = (status) => {
  switch (status) {
    case 'Healthy':
      return notifSuccess;
    case 'Failed':
      return notifError;
    case 'Degraded':
      return notifWarn;
    default:
      return gray3;
  }
};

export const getGlobalStatus = (status) => {
  let globalStatus = StatusTypes.healthy;
  const systemStatus = status.users.find((user) => user.name === 'SYSTEM');
  globalStatus = getHealthCheckStatus(systemStatus?.healthChecks);
  return globalStatus;
};
