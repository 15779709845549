import { useEffect, useState } from 'react';
import {
  ITodaysActivityDto,
  ITodaysActivity,
  IGroupActivityStatus,
  IGroupsActivityByStatus
} from '../models';
import {
  formatPreviousValueString,
  isPreviousValueNegative
} from './widget.utils';
import { mapGroupsWithLinks } from './teams.utils';
import { aqua1, gray7, red2 } from '../../common/constants';
import { Plugin } from 'chart.js';
import { getFullWeekday } from '../../common/utils/datetime/dayOfWeek';

export const mapToTodaysActivity = (
  data: ITodaysActivityDto
): ITodaysActivity => {
  return {
    active: data.active,
    activeToday: data.activeToday,
    todaysLabel: getFullWeekday(new Date(data.date)) || 'today',
    date: data.date,
    inactive: data.inactive,
    passive: data.passive,
    previous: data.previous,
    thresholdHours: data.thresholdHours,
    groupsActivityByStatus: mapGroupsActivityByStatus(data.groupActivityStatus),
    groupsActivityWithPercentage: mapGroupsWithLinks(data.groupActivityStatus),
    counts: data.counts
  };
};

export const useTodaysActivityCenterText = (
  noResults: boolean,
  percentage: number | string,
  previous: number,
  todaysLabel: string
) => {
  const [todaysActivityCenterText, setTodaysActivityCenterText] =
    useState<Plugin<'doughnut', object>>();

  //custom hook with useEffect to only update object if dependencies change
  useEffect(() => {
    const result = {
      id: 'donutCenterText',
      afterDatasetsDraw(chart) {
        const { ctx } = chart;

        ctx.save();
        ctx.textAlign = 'center';

        //upper values
        ctx.fillStyle = gray7;
        ctx.font = '16px sans-serif';
        ctx.fillText(
          'Users',
          chart.getDatasetMeta(0).data[0].x,
          noResults
            ? chart.getDatasetMeta(0).data[0].y - 35
            : chart.getDatasetMeta(0).data[0].y - 55
        );

        ctx.fillStyle = gray7;
        ctx.font = '16px sans-serif';
        ctx.fillText(
          'active today',
          chart.getDatasetMeta(0).data[0].x,
          noResults
            ? chart.getDatasetMeta(0).data[0].y - 15
            : chart.getDatasetMeta(0).data[0].y - 35
        );

        //center value
        ctx.fillStyle = gray7;
        ctx.font = 'bold 52px sans-serif';
        ctx.fillText(
          noResults ? '0%' : `${percentage}%`,
          chart.getDatasetMeta(0).data[0].x,
          noResults
            ? chart.getDatasetMeta(0).data[0].y + 40
            : chart.getDatasetMeta(0).data[0].y + 20
        );

        // lower values
        if (!noResults) {
          ctx.fillStyle =
            previous === 0
              ? gray7
              : isPreviousValueNegative(previous)
              ? red2
              : aqua1;
          ctx.font = 'bold 14px sans-serif';
          ctx.textAlign = 'right';
          ctx.fillText(
            `${formatPreviousValueString(previous)}%`,
            chart.getDatasetMeta(0).data[0].x - 25,
            chart.getDatasetMeta(0).data[0].y + 45
          );

          ctx.fillStyle = gray7;
          ctx.font = '14px sans-serif';
          ctx.textAlign = 'left';
          ctx.fillText(
            'vs average',
            chart.getDatasetMeta(0).data[0].x - 20,
            chart.getDatasetMeta(0).data[0].y + 45
          );

          ctx.fillStyle = gray7;
          ctx.font = '14px sans-serif';
          ctx.textAlign = 'center';
          ctx.fillText(
            `${todaysLabel} at this time`,
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y + 65
          );
        }
      }
    };
    setTodaysActivityCenterText(result);
  }, [previous, percentage, todaysLabel, noResults]);

  return todaysActivityCenterText;
};

export const mapGroupsActivityByStatus = (
  groups: IGroupActivityStatus[]
): IGroupsActivityByStatus => {
  const groupsStatusesMap: IGroupsActivityByStatus = {
    activeUsersCount: [],
    inactiveUsersCount: [],
    passiveUsersCount: []
  };

  groups.forEach((group) => {
    Object.entries(group).forEach(([key]) => {
      if (key in groupsStatusesMap) {
        const formattedGroup = {
          name: group.groupName,
          value: `${group[key]}/${group.totalUsersInGroupCount}`,
          url: 'app.dashboardCurrent.management',
          urlParams: { groupId: group.groupId }
        };
        groupsStatusesMap[key].push(formattedGroup);
      }
    });
  });

  return groupsStatusesMap;
};
