import { SortDirection } from '../enums';
import { UserIdentityGetProps } from '../models/UserIdentityGetProps';

export const SEARCH_TYPE_ALL = 'all';
export const MAX_RESULTS = 9;

export const defaultIdentitySearch: UserIdentityGetProps = {
  sortBy: 'lastLog',
  sortDirection: SortDirection.Descending,
  pageSize: '500',
  searchTerm: '',
  searchType: SEARCH_TYPE_ALL,
  cursor: null,
  filter: null
};
