import { StoreCache, StoreCacheName } from './cacheStore.models';
import { fetchAccountPlanStore } from '../accountPlanStore';
import { ACCOUNT_PLAN_CACHE_TIMEOUT } from '../../constants/accountPlanStoreDefault';

export const STORE_CACHES: StoreCache[] = [
  {
    storeName: StoreCacheName.AccountPlanStore,
    fetchData: fetchAccountPlanStore,
    lifetime: ACCOUNT_PLAN_CACHE_TIMEOUT
  }
];
