import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { ConfigurationCardWrapper } from '.';
import { usePrivacyLevelSettingsState } from '../hooks/usePrivacyLevelSettingsState';
import { PrivacyLevels } from '../enums/privacyLevels';
import { DefaultWidgetProps } from '../models';
import { IPrivacyLevel } from '../models/IPrivacyLevelSettings';
import { filterPrivacyLevelOptions } from '../utils/privacyLevelSettings.utils';
import {
  getAccountPlanStore,
  isAccountPlanInTrial
} from '../../common/stores/accountPlanStore';

export const PrivacyLevelSettingsCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const { planType } = getAccountPlanStore();
  const isTrialAccount = isAccountPlanInTrial();

  const [selectedPrivacyLevel, setSelectedPrivacyLevel] = useState<string>(
    PrivacyLevels.Basic
  );

  const {
    savedPrivacyLevel,
    isLoading,
    getPrivacyLevelSettings,
    updatePrivacyLevelSettings
  } = usePrivacyLevelSettingsState();

  useEffect(() => {
    getPrivacyLevelSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedPrivacyLevel(savedPrivacyLevel);
  }, [savedPrivacyLevel]);

  const handleUpdate = () => {
    updatePrivacyLevelSettings(selectedPrivacyLevel);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPrivacyLevel(e.target.value);
  };

  const BoldLabelAndDescription = (item: IPrivacyLevel) => {
    return (
      <Typography>
        <strong>{item.title}</strong>: {item.description}
      </Typography>
    );
  };

  const visibleOptions: IPrivacyLevel[] = filterPrivacyLevelOptions(
    planType,
    isTrialAccount
  );

  return (
    <ConfigurationCardWrapper
      title="Sensitive Data Visibility"
      actions={
        <Button
          id="64de3e0f-df51-4f78-998e-cf4563f73028"
          variant="contained"
          disabled={
            isSupport || isLoading || selectedPrivacyLevel === savedPrivacyLevel
          }
          onClick={handleUpdate}
        >
          Save
        </Button>
      }
    >
      <Typography sx={{ marginBottom: '16px' }}>
        Choose the level of detail your account users will have access to for
        features involving sensitive information:
      </Typography>
      <FormControl>
        <RadioGroup value={selectedPrivacyLevel} onChange={handleChange}>
          {visibleOptions?.map((item, i) => (
            <FormControlLabel
              id={`${item.id}-${item.value}`}
              key={`sensitive-data-visibility-${i}`}
              value={item.value}
              control={<Radio disabled={isSupport || isLoading} size="small" />}
              label={BoldLabelAndDescription(item)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </ConfigurationCardWrapper>
  );
};
