import angular from 'angular';

import { setSideBarStatus } from '../../_reactivtrak/src/common/components/Navigation/sideBarStatusStore';
import { supportModalTemplate } from '../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').directive('headerWrapper', [
  function () {
    return {
      restrict: 'E',
      template: '<headernew logout="logout" open-support-modal="openSupportModal" hide-navigation="hideNavigation"></headernew>',
      controller: headerWrapperCtrl
    };
  }
]);

headerWrapperCtrl.$inject = [
  '$scope',
  'loginService',
  'customUibModal'
];

function headerWrapperCtrl(
  $scope,
  loginService,
  customUibModal
) {
  $scope.logout = function () {
    loginService.logout();
  };

  $scope.openSupportModal = function () {
    customUibModal.open({
      animation: false,
      template: supportModalTemplate,
      controller: 'SupportModalCtrl',
      windowClass: 'centered-modal off-wht-bg-modal',
      size: 'lg'
    });
  };

  $scope.hideNavigation = function () {
    var hideNavigation = false;
    var urlsToHideNavigation = ['wizard?firstVisit=true'];

    urlsToHideNavigation.map(function (url) {
      if (window.location.hash.includes(url)) {
        hideNavigation = true;
        setSideBarStatus('hidden');

      }
    });
    return hideNavigation;
  };
  
}