import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {
  PercentInputContainer,
  StyledTextField
} from '../../styles/TimeOnTask.view.styles';

type PercentInputProps = {
  defaultValue: number;
  onBlur: (value: number | null) => void;
};

const PercentInput: React.FC<PercentInputProps> = ({
  defaultValue,
  onBlur
}) => {
  const [percentValue, setPercentValue] = useState<number>(defaultValue);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const parsedVal = parseInt(e.target.value);
    if (isNaN(parsedVal) || parsedVal < 1 || parsedVal > 100) {
      setPercentValue(null);
      onBlur(null);
      return;
    }
    e.target.value = String(parsedVal);
    setPercentValue(parsedVal);
    onBlur(parsedVal);
  };

  return (
    <PercentInputContainer>
      Percent of Productive Hrs/Day Goal &nbsp;
      <StyledTextField
        variant={'outlined'}
        defaultValue={percentValue}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
      />
    </PercentInputContainer>
  );
};

export default PercentInput;
