export const ENTITY_STORE_CACHE_LIFETIME = 300000; // 5 minutes

export const ENTITY_STORE_DEFAULT = {
  users: [],
  computers: [],
  groups: [],
  isLoading: false,
  previousSearch: null,
  cacheTimestamp: undefined
};
