import Typography from '@mui/material/Typography';
import { PrimaryUploadBox } from '../../styles/TimeOnTask.view.styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Bulk } from '../../utils/TimeOnTask.view.utils';

type OwnProps = {
  currentUploadView: Bulk;
  message1: string;
  message2: string;
};

const BulkUploadError: React.FC<OwnProps> = ({
  currentUploadView,
  message1,
  message2
}) => {
  return (
    Bulk.ERROR === currentUploadView && (
      <PrimaryUploadBox>
        <WarningAmberIcon fontSize="large" color="error" />
        <Typography>{message1}</Typography>
        <Typography>{message2}</Typography>
      </PrimaryUploadBox>
    )
  );
};

export default BulkUploadError;
