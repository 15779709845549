import { AgGridReact } from 'ag-grid-react';
import { ColDef, IDatasource } from 'ag-grid-community';
import {
  getScheduleAdherenceColumns,
  resetScheduleAdherenceColumns,
  setScheduleAdherenceColumns
} from '../stores/scheduleAdherenceColumns.store';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import {
  handleAllCheckbox,
  mapColDefToColumnState,
  mapColumnStateToGridColumnFilter
} from '../../../common/components/ReportFilters/utils/gridColumnsFilter.utils';
import {
  IGridColumnFilter,
  IReportColumnLabel
} from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { createBaseReportFilterParams } from '../../../common/components/ReportFilters/utils/reportFilter.utils';
import {
  getScheduledTimeline,
  setScheduledTimeline
} from '../stores/scheduledTimeline.store';
import { convertObjectToQs } from '../../../common/utils/parse/parse';
import { fetchReportingData } from '../../../common/helpers';
import { IScheduleAdherenceUsersDto } from '../models/ScheduleAdherence.models';
import { setExportParamsStore } from '../../../common/stores/exportsStore/exportParamsStore';
import { IExportReportParams } from '../../../common/stores/exportsStore/exportStore.models';
import { ReportType } from '../../../common/enums/ReportType';
import { getScheduledColumns } from './scheduleAdherenceColumns.utils';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { AtConsoleLogger } from '../../../common/utils/consoleLogger/AtConsoleLogger';
import { INotificationService } from '../../../common/services/Notifications';
import {
  getScheduleAdherenceExportExcludeColumns,
  setScheduleAdherenceExportExcludeColumns
} from '../stores/scheduleAdherenceExportExcludeColumns.store';
import { SCHEDULE_ADHERENCE_EXPORT_EXCLUDE_COLUMNS_MAP } from '../constants/scheduleAdherence.constants';

//use the sort order from the ColumnState
export const mapSAColumnsToColumnState = (
  newColumns: ColDef[],
  view: ReportViewTypes
): void => {
  //get current saved state directly from store
  const currentColumnState = getScheduleAdherenceColumns()[view];

  const newColumnsStates = currentColumnState.map((currentCol) => {
    if (!currentCol) return;

    const currentDefaultColId = currentCol.colId;

    const newColumnState = newColumns.find(
      (newCol) => newCol.colId === currentDefaultColId
    );
    if (!newColumnState) return;

    return mapColDefToColumnState(newColumnState);
  });

  //update state directly to store
  setScheduleAdherenceColumns({ [view]: newColumnsStates });
};

//use the sort order from the Column Selector labels
export const mapSAColumnFiltersToColumnLabels = (
  view: ReportViewTypes,
  labels: IReportColumnLabel[]
): IGridColumnFilter[] => {
  const sortedDefaultColumns = labels?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const currentColumnState = getScheduleAdherenceColumns()[view];

  const cols = sortedDefaultColumns?.map((col) => {
    return mapColumnStateToGridColumnFilter(col, currentColumnState);
  });

  //Map current state to Export's excludeColumns array
  const excludeColumns = cols
    .filter((col) => col.colId !== 'all' && col.hide)
    .map((col) => SCHEDULE_ADHERENCE_EXPORT_EXCLUDE_COLUMNS_MAP[col.colId]);
  setScheduleAdherenceExportExcludeColumns(excludeColumns);

  return handleAllCheckbox(cols);
};

export const getValidatedScheduledColumns = (): ColDef[] => {
  const defaultColumns: ColDef[] = getScheduledColumns(); //allowed columns
  const { view: currentView } = getReportView(); //current view
  const currentColumnState = getScheduleAdherenceColumns()[currentView]; //current persisted column state

  //SIMPLE VALIDATION: If length of default columns does not equal the length of the persisted column state, reset the persisted column state
  if (defaultColumns.length !== currentColumnState.length) {
    resetScheduleAdherenceColumns();
  }
  return defaultColumns;
};

export const getDataSource = (
  gridRef: React.MutableRefObject<AgGridReact>,
  notificationService: INotificationService
): IDatasource => {
  const pageSize = 150;

  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: async (params) => {
      const gridApi = gridRef.current?.api;

      gridApi.hideOverlay();
      const page = Math.ceil(params.endRow / pageSize);

      setTimeout(async () => {
        const { to, from, userId, computerId, groupId, userMode } =
          createBaseReportFilterParams();

        // TODO: Remove this check if computer based filtering is supported
        if (computerId || userMode === 'computers') {
          notificationService.warning(
            'Computer based filtering is not currently supported for Schedule Adherence Report Beta. Please update your filter to a user or user group.'
          );
          gridApi.showNoRowsOverlay();
          return params.failCallback();
        }

        const shiftTimes = getScheduledTimeline();
        const reportFiltersStr = convertObjectToQs({
          to,
          from,
          userId,
          computerId,
          groupId,
          pageSize,
          page,
          shiftStart: shiftTimes.shiftStart,
          shiftEnd: shiftTimes.shiftEnd
        });

        gridApi.setGridOption('loading', true);

        try {
          const response = await fetchReportingData<IScheduleAdherenceUsersDto>(
            {
              path: '/reports/v1/scheduleadherence?' + reportFiltersStr
            }
          );
          const data = response?.data;
          const total = response?.total;

          const exportParams = {
            hasDataForTable: total > 0
          };

          setExportParamsStore(exportParams);

          //'loading' has to be false BEFORE showing the no-data overlay
          gridApi.setGridOption('loading', false);

          if (data.length === 0) {
            gridApi.showNoRowsOverlay();
          }
          params.successCallback(data, total);
        } catch (error) {
          //'loading' has to be false BEFORE showing the no-data overlay
          gridApi.setGridOption('loading', false);
          gridApi.showNoRowsOverlay();

          notificationService.error('Unable to load Schedule Adherence data');
          AtConsoleLogger(
            'Unable to load Schedule Adherence data',
            error
          ).error();
          params.failCallback();
        }
      }, 0);
    }
  };
  return dataSource;
};

export const createExportPayload = (): IExportReportParams => {
  const { from, groupId, to, userType, userId, computerId } =
    createBaseReportFilterParams();

  const shiftTimes = getScheduledTimeline();

  return {
    type: ReportType.ScheduleAdherence,
    startDate: from,
    endDate: to,
    userType,
    userId: groupId ?? userId ?? computerId,
    shiftStart: shiftTimes?.shiftStart,
    shiftEnd: shiftTimes?.shiftEnd,
    excludeColumns:
      getScheduleAdherenceExportExcludeColumns().excludeColumns ?? []
  };
};

export const handleStartDate = (value: string): void => {
  setScheduledTimeline({
    shiftStart: value
  });
};

export const handleEndDate = (value: string): void => {
  setScheduledTimeline({
    shiftEnd: value
  });
};
