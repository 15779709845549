import React from 'react';
import { Link, IconButton, Box } from '@mui/material';
import ArrowIcon from '../../../common/components/ArrowIcon/ArrowIcon.jsx';
import { useImpersonate } from '../../../../../_reactivtrak/src/common/hooks/useImpersonate';

const CardFooter = ({ accountId, onError }) => {
  const [, impersonate] = useImpersonate(accountId);
  return (
    <Box className="card-footer">
      <Link
        href={'#'}
        onClick={(e) => {
          e.preventDefault();

          setTimeout(async () => {
            try {
              await impersonate();
            } catch (ex) {
              onError(ex);
            }
          });
        }}
        target="_blank"
      >
        <IconButton aria-label="delete" size="medium">
          <ArrowIcon color="primary" />
        </IconButton>
      </Link>
    </Box>
  );
};

export default CardFooter;
