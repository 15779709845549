import { setReportFilters } from '../../../../../components/ReportFilters/hooks/reportFiltersStore';
import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { groupsStore } from '../../../../../hooks';
import { IRoute } from '../../../../../models/IRoute';

export const appDashboardCurrentManagement: IRoute = {
  name: 'app.dashboardCurrent.management',
  stateDefinition: {
    url: '/homepage/management',
    params: {
      verifySuccess: false,
      verifyError: false,
      chromebookInstallError: null,
      updated: false,
      fromNavigation: false,
      groupId: null
    },
    template: '<homepage-management></homepage-management>',
    data: { pageTitle: 'Home - Team Management' },
    resolve: {
      filters: [
        '$stateParams',
        function ($stateParams) {
          const groupId = $stateParams.groupId;
          if (groupId) {
            const group = groupsStore.getState().getGroupFilterById(groupId);
            const groupName: string = group?.name;

            if (groupName) {
              setReportFilters({
                groupId: [groupId],
                users: [
                  {
                    userId: groupId,
                    userType: 'Group',
                    name: groupName,
                    filterMode: group?.mix === 'Computer' ? 'computers' : 'users',
                  }
                ]
              });
            } else {
              console.warn(
                'ActivTrak Warn: Unable to find group name for group id: ' +
                  groupId
              );
            }
          }
        }
      ]
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.DashboardsTeamMgmt,
    edit: [
      Role.Admin,
      Role.Configurator,
      Role.SuperAdmin,
      Role.User,
      Role.Viewer
    ],
    read: [],
    bundleFlags: [BundleFlag.TeamManagementHomepage],
    excludedFeatureFlags: [FeatureFlag.ShowActivation]
  }
};

export default appDashboardCurrentManagement;
