import { http } from '_app/http';
import { BehaviorSubject } from 'rxjs';
import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';
import { analyticEventServiceFunctions } from '../serviceFunctions/analyticEventServiceFunctions';
import dashboardEvent from './dashboardEvent';
import dashboardEventLog from './dashboardEventLog';
import { setReportFilters } from '../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import { groupsStore } from '../../_reactivtrak/src/common/hooks/groupsStore';
import { RoleAccessKeys } from '../../_reactivtrak/src/common/enums/RoleAccessKeys';

const INSIGHTS_TEMP_FILTERS = 'insights_temp_filters-';
const INSIGHTS_FILTERS = 'insights_filters-';
const INSIGHTS_LAST_PAGE_ID = '_insights-last-page-id';
const INSIGHTS_IMPACT_FILTERS = 'insights_impact_filters-';
const INSIGHTS_COACHT_FILTERS = 'insights_coach_filters-';
const SESSION_STORAGE = 'sessionStorage';

const _endsWith = (str, suffix) => {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
};

const _destroySubject = (subject) => {
    subject.next();
    subject.complete();
};
const _formatName = (pageId) => {
    if (typeof pageId !== 'string') {
        console.error(
            `ActivTrak Error: Unable to format dashboard name due to bad page id input.\n   •Page Id: ${pageId}`
        );
        return;
    }

    let name = pageId.replace(/_/g, ' ');
    name = atHelperFunctions.capitalizeFirstLetterEachWord(name);
    return name;
};

const _getLocalStorageKey = (useTempFilters, isCoach, isImpact) => {
    if (isCoach) {
        return INSIGHTS_COACHT_FILTERS;
    }
    if (isImpact) {
        return INSIGHTS_IMPACT_FILTERS;
    }
    return useTempFilters ? INSIGHTS_TEMP_FILTERS : INSIGHTS_FILTERS;
};

const _getFilters = ({ localStorageService, username, useTempFilters, isCoach, isImpact }) => {
    return localStorageService.get(_getLocalStorageKey(useTempFilters, isCoach, isImpact) + username) || '';
};

const _encodeFilters = (filters) => {
    const queryString = Object.keys(filters)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key]))
        .join('&');

    return queryString && encodeURIComponent(`&${queryString}`);
};

const _decodeFilters = (filters) => {
    //empty string check to avoid false errors when we intentionally clear filters for exec summary, coach, & impact
    if (filters.trim() !== '') {
        const decodedString = decodeURI(decodeURIComponent(filters))
            .replace(/^&/g, '')
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
            .replace(/%5E/g, '^')
            .replace(/\+/g, ' ')
            .replace(/%2C/g, ',')
            .replace(/%23/g, '#')
            .replace(/%24/g, '$')
            .replace(/%2F/g, '/')
            .replace(/%40/g, '@')
            .replace(/%26/g, '&')
            .replace(/%2B/g, '+')
            .replace(/%3A/g, ':')
            .replace(/>%3D/g, '>=') //greater than or equal to
            .replace(/<%3D/g, '<='); //less than or equal to

        try {
            const parseObj = JSON.parse(`{"${decodedString}"}`);
            delete parseObj.embed_domain;
            return decodedString && parseObj;
        } catch (error) {
            console.error('ActivTrak Error: Error decoding saved filters ' + error);
            return '';
        }
    }
};

const _canDrill = (authorizationService) => {
    return (
        authorizationService.hasRole(['admin', 'superadmin', 'supportintermediate']) ||
        authorizationService.hasRoleAccess([
            RoleAccessKeys.Insights,
            RoleAccessKeys.ExecutiveSummary,
            RoleAccessKeys.TeamComparison,
            RoleAccessKeys.WorkEfficiency,
            RoleAccessKeys.ActivityBreakdown,
            RoleAccessKeys.TimeOnTask,
            RoleAccessKeys.WorkloadBalance,
            RoleAccessKeys.TechnologyUsage,
            RoleAccessKeys.BenchmarkAndGoals,
            RoleAccessKeys.PersonalInsights
        ])
    );
};

class insightDashboard {
    constructor({
        modelId,
        pageId,
        subPageId,
        embedDomain,
        services = {},
        username,
        useTempFilters,
        isProduction,
        lookerUrl,
        messagesService
    } = {}) {
        this.hash = atHelperFunctions.generateGuid();
        this.modelId = modelId;
        this.pageId = pageId;
        this.subPageId = subPageId;
        this.id = this.getLookerId();
        this.elementId = `looker_${this.id}_${this.hash}`;
        this.name = _formatName(pageId);
        this.isCoach = pageId === 'coaching_opportunities';
        this.isImpact = pageId === 'impact_analysis';
        this.nextId = new BehaviorSubject(this.id);
        this.embedDomain = embedDomain;
        this.embedUrl = new BehaviorSubject();
        this.loaded = new BehaviorSubject(false);
        this.runComplete = new BehaviorSubject(false);
        this.pageChange = new BehaviorSubject(false);
        this.drillingEnabled = new BehaviorSubject(_canDrill(services.authorizationService));
        this.eventLog = new dashboardEventLog();
        this.error = new BehaviorSubject(false);
        this.localStorageService = services.localStorageService;
        this.notificationService = services.notificationService;
        this.authorizationService = services.authorizationService;
        this.username = username;
        this.useTempFilters = useTempFilters;
        this.filters = _getFilters(this);
        this.status = new BehaviorSubject();
        this.isProduction = isProduction;
        this.lookerUrl = lookerUrl;
        this.embeddedFilters = {};
        this.embeddedFilterListener = null;
        this.calculateHeight();
        this.errorLoadingReportMsg = messagesService.get('reportLoadingError');
    }

    shouldNavigate() {
        return this.nextId.value !== this.id;
    }

    getLoaded() {
        return this.loaded.value;
    }

    setLoaded(value) {
        this.loaded.next(value);
        this.calculateHeight();
    }

    setRunComplete(value) {
        this.runComplete.next(value);
    }

    setPageChange(value) {
        this.pageChange.next(value);
    }

    createStateParams(id) {
        const parts = this.splitLookerId(id);
        return {
            modelId: parts.modelId,
            pageId: parts.pageId,
            subPageId: parts.subPageId
        };
    }

    saveFilters(filters = this.filters, useTempFilters = this.useTempFilters) {
        if (useTempFilters) {
            this.localStorageService.set(_getLocalStorageKey(useTempFilters) + this.username, filters);
        } else {
            const teamNamesStr = filters.Team;

            //update React report filters
            if (teamNamesStr) {
                //convert comma-delimited names to array and remove quotes
                const teamsList = teamNamesStr.split(',').map((team) =>
                    team
                        .trim()
                        .replace(/^"(.*)"$/, '$1')
                        .toLowerCase()
                );

                if (groupsStore) {
                    const groupsDefinitions = groupsStore.getState();
                    const allGroups = groupsDefinitions.groupFilters;

                    //pick the first team that matches in React groups list
                    const group = allGroups.find((currentGroup) => {
                        const currentGroupName = currentGroup?.name?.replace(/^"(.*)"$/, '$1').toLowerCase();
                        return teamsList.includes(currentGroupName);
                    });

                    if (group?.id) {
                        setReportFilters({
                            groupId: [group.id],
                            users: [
                                {
                                    userId: group.id,
                                    userType: 'Group',
                                    name: group.name,
                                    filterMode: group?.mix === 'Computer' ? 'computers' : 'users',
                                    groupType: group.mix
                                }
                            ]
                        });
                    }
                }
            }

            const savedFilters = _decodeFilters(this.filters);
            const mergedFilters = { ...savedFilters, ...filters };
            this.filters = _encodeFilters(mergedFilters);
            this.localStorageService.set(
                _getLocalStorageKey(useTempFilters, this.isCoach, this.isImpact) + this.username,
                this.filters
            );
        }
    }

    handleDrillMenuEvent(nextUrl) {
        const parts = nextUrl.split('?');

        // Set filters from next url
        if (nextUrl.indexOf('personal_insights') > -1) {
            this.isCoach = false;
            this.isImpact = false;
        }
        this.saveFilters(encodeURIComponent(`&${parts[1]}`), true);
        // Set next Id for navigation
        this.nextId.next(parts[0].replace('/embed/dashboards/', ''));
    }

    retrieveEmbeddedFilters(event) {
        if (event.dashboard && event.dashboard.dashboard_filters) {
            this.embeddedFilters = event.dashboard.dashboard_filters;

            if (this.embeddedFilterListener) {
                this.embeddedFilterListener(this.embeddedFilters);
            }
        }
    }

    /**
     * Handles incoming looker event
     * @param {object} rawEvent
     */
    handleEvent(rawEvent) {
        const event = new dashboardEvent(rawEvent);
        this.eventLog.addEvent(event);
        this.updateFilters();
        this.retrieveEmbeddedFilters(event);

        if (!this.loaded.value && event.isDashboardEvent(['loaded'])) {
            this.setLoaded(true);
        }

        if (!this.runComplete.value && event.isDashboardEvent(['run']) && event.hasStatus(['complete'])) {
            this.setRunComplete(true);
        }

        if (!this.pageChange.value && event.isPageEvent(['changed'])) {
            this.setPageChange(true);
        }

        if (event.isDrillMenuEvent()) {
            this.handleDrillMenuEvent(event.url);
        }

        if (event.status === 'error' && event.isDashboardEvent(['loaded'])) {
            this.error.next(
                `ActivTrak Error: Unable to load insights dashboard "${event.dashboard.id}".\nURL: ${event.dashboard.url}`
            );
        } else {
            const eventObject = {
                pageId: this.pageId,
                subPageId: this.subPageId,
                filters: event.filters,
                type: event.type,
                subtype: event.subtype,
                status: event.status,
                tile: event.tile && event.tile.title
            };

            // Trigger Event
            analyticEventServiceFunctions.newEvent(
                `Insights Dashboard${event.title ? ': ' + event.title : ''}`,
                eventObject
            );

            this.error.next();
        }

        // console.log('eventLog', this.eventLog);
    }

    grantSchedulerRole(apiUrl) {
        const dashboard = this;
        return http
            .post(`${apiUrl}/api/insights/schedules/grantPermission`)
            .then((results) => {
                let url = results && results.data;
            })
            .catch(() => {
                dashboard.notificationService.showNotification(dashboard.errorLoadingReportMsg, 'danger');
            });
    }

    /**
     * Keeps app dashboard filters in sync with looker iframe filters
     */
    updateFilters() {
        const lastDashboardEvent = this.eventLog.getLastDashboardEvent();

        if (
            lastDashboardEvent &&
            lastDashboardEvent.hasSubtypes(['run']) &&
            lastDashboardEvent.hasStatus(['start']) &&
            !this.useTempFilters
        ) {
            let changedFilters = lastDashboardEvent.getFilters();

            if (changedFilters && !angular.equals(changedFilters, {})) {
                this.saveFilters(changedFilters);
            }
        }
    }

    setCoreCategoryTeamFilters(coreCategoryTeams, range) {
        //Do nothing if no coreCategory configured teams
        if (coreCategoryTeams.length === 0) {
            return;
        }

        const lastPageId = this.localStorageService.get(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, SESSION_STORAGE);
        if (null === lastPageId || lastPageId !== this.pageId) {
            const totTeams = coreCategoryTeams.map((cct) => cct.groupName);
            let filters = {};
            if (range) {
                filters = { 'Activity Date': range };
            }

            if (this.filters && this.filters !== '') {
                const decodedFilters = _decodeFilters(this.filters);
                if (decodedFilters['Team'] === '' || decodedFilters['Team'] === 'null') {
                    //If no incoming team filter, set team to first coreCategory team
                    this.saveFilters({ ...filters, Team: totTeams[0] }, false);
                } else {
                    const teams = decodedFilters['Team'].split(',');
                    //Find intersection of incoming team filter and coreCategory created teams
                    const newTeams = teams.filter((team) => totTeams.includes(team));
                    if (newTeams.length === 0) {
                        //If no intersection, set team to first coreCategory team.
                        this.saveFilters({ ...filters, Team: totTeams[0] }, false);
                    } else {
                        //Set team to intersection of incoming team filter and coreCategory teams.
                        this.saveFilters({ ...filters, Team: newTeams.join(',') }, false);
                    }
                }
            } else {
                //If no incoming filters, set team to first coreCategory team
                this.saveFilters({ ...filters, Team: totTeams[0] }, false);
            }
            this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
        }
    }

    /**
     * For performance reasons, upon initial page load for all 'insights' reports
     * EXCEPT executive_summary, coaching_opportunities, and impact_analysis.
     * if the 'Team' filter doesn't exist or has been unset, set it to the first element from all visible groups.
     *
     * BUT if the user clears the filters on a pageId and navigates the subPageId tabs, maintain user's empty filters
     *
     * Coach 'summary' initial load should always default to empty team selection. Once team selection is made
     * during coach subnavigation, remember the team selection. Once user navigates away from coach and comes back
     * to coach, team selection should be empty.
     *
     * Impact 'summary' initial load should always default to empty selection, UNLESS a team selection has been
     * selected from a previous session, in which case, remember previous session team selection.
     */
    setDefaultFilters(groupName, range) {
        if (this.pageId === 'executive_summary') {
            this.saveFilters({ Team: '' }, false);
            this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
            return;
        }

        const lastPageId = this.localStorageService.get(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, SESSION_STORAGE);

        if (this.isCoach) {
            if (this.subPageId === 'summary' && (null === lastPageId || lastPageId !== this.pageId)) {
                this.saveFilters({ Team: '' }, false);
            }
            this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
            return;
        }

        if (this.isImpact) {
            this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
            return;
        }

        let defaultFilters = { Team: groupName };
        if (range) {
            defaultFilters = { ...defaultFilters, 'Activity Date': range };
        }
        if (!this.filters || this.filters === '') {
            this.saveFilters(defaultFilters, false);
            this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
            return;
        }

        const excludePageIds = ['personal_insights'];
        if (!excludePageIds.includes(this.pageId) && !this.useTempFilters) {
            // AC-13696: When on the same pageId and user clears out team filter and navigates to subPageId, keep team filter empty
            if (null === lastPageId || lastPageId !== this.pageId) {
                //Need this below once user moves to anohter pageId
                this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
                if (this.filters && this.filters !== '') {
                    let decodedFilters = _decodeFilters(this.filters);
                    if (decodedFilters['Team'] === '' || decodedFilters['Team'] === 'null') {
                        //local storage key exists but 'Team' value is empty -> add it
                        this.saveFilters(defaultFilters, false);
                    } else if (range) {
                        this.saveFilters({ ...decodedFilters, 'Activity Date': range });
                    }
                } else {
                    //local storage key doesn't exist -> create it
                    this.saveFilters(defaultFilters, false);
                }
            }
        } else {
            this.localStorageService.set(`${this.username}${INSIGHTS_LAST_PAGE_ID}`, this.pageId, SESSION_STORAGE);
        }
    }

    //For personal_insights page, if there is no existing 'User' filter value, set it to the first visible user
    setDefaultUserFilter(userName) {
        if (this.pageId === 'personal_insights') {
            if (this.filters && this.filters !== '') {
                let decodedFilters = _decodeFilters(this.filters);
                if (
                    decodedFilters['User'] === undefined ||
                    decodedFilters['User'] === '' ||
                    decodedFilters['User'] === 'null'
                ) {
                    this.saveFilters({ User: userName }, false);
                }
            } else {
                this.saveFilters({ User: userName }, false);
            }
        }
    }

    getEmbedUrl(apiUrl) {
        const dashboard = this;
        var dashboardRoute = 'api/dashboard/insights';

        dashboard.eventListener = (event) => {
            const lookerFrame = document.getElementById(dashboard.elementId);

            if (
                lookerFrame &&
                event.source === lookerFrame.contentWindow &&
                (_endsWith(event.origin, 'activtrak.com') || _endsWith(event.origin, 'cloud.looker.com'))
            ) {
                dashboard.handleEvent(event);
            }
        };

        if (dashboard.pageId === 'coaching_opportunities') {
            dashboardRoute = 'api/dashboard/coaching';
        }

        return http
            .get(
                `${apiUrl}/${dashboardRoute}?id=${dashboard.id}&embedDomain=${dashboard.embedDomain}&filters="${dashboard.filters}"&drillingEnabled=${dashboard.drillingEnabled.value}`
            )
            .then((results) => {
                let url = results && results.data && results.data.url;
                dashboard.embedUrl.next(url);

                window.addEventListener('message', dashboard.eventListener);
            })
            .catch(() => {
                dashboard.notificationService.showNotification(dashboard.errorLoadingReportMsg, 'danger');
            });
    }

    postEvent(event) {
        const looker = document.getElementById(this.elementId);
        if (!looker) {
            console.error(
                `ActivTrak Error: Unable to send event due to looker instance "${this.elementId}" not found.`
            );
            return;
        }

        // Save filters to local storage if updated via dropdowns
        if (event.type.indexOf('filters:changed') >= 0) {
            this.saveFilters(event.filters);
        }

        looker.contentWindow.postMessage(JSON.stringify(event), this.lookerUrl);
    }

    calculateHeight() {
        setTimeout(() => {
            const insightControls = document.getElementById('insight_controls');

            // iframe height is view height minus header and footer and dynamic insight
            // control height. This keeps the page from having two scroll bars.
            this.height = `calc(100vh - 154px - ${insightControls.offsetHeight}px)`;
        });
    }

    getLookerId(
        { modelId, pageId, subPageId } = {
            modelId: this.modelId,
            pageId: this.pageId,
            subPageId: this.subPageId
        }
    ) {
        if (!modelId || !pageId) {
            console.error(
                `ActivTrak Error: Unable to create Looker Id due to missing model or page Id.\nModel Id: ${modelId}, Page Id: ${pageId}`
            );
            return;
        }

        return `${modelId}::${pageId}${subPageId ? `__${subPageId}` : ''}`;
    }

    splitLookerId(lookerId) {
        if (typeof lookerId !== 'string') {
            console.error(
                `ActivTrak Error: Unable to split Looker Id due to input not a string.\nLooker Id: ${lookerId}`
            );
            return;
        }

        if (lookerId.indexOf('::') < 0) {
            console.error(`ActivTrak Error: Unable to split Looker Id due to improper format.\nLooker Id: ${lookerId}`);
            return;
        }

        const modelSplit = lookerId.split('::');
        const pageSplit = modelSplit[1].split('__');

        return {
            modelId: modelSplit[0],
            pageId: pageSplit[0],
            subPageId: pageSplit[1]
        };
    }

    decodeFilters(filters = this.filters) {
        return _decodeFilters(filters);
    }

    encodeFilters(filters = this.filters) {
        return _encodeFilters(filters);
    }

    destroy() {
        // console.log('destroy called');
        _destroySubject(this.embedUrl);
        _destroySubject(this.nextId);
        _destroySubject(this.error);
        window.removeEventListener('message', this.eventListener);
        delete this;
    }
}

export default insightDashboard;
