import { IIntegrationDetails } from '../models/IIntegrationTypes';

import { AzureAdIcon } from '../../common/assets/Icons/AzureAdIcon';
import { GoogleCalendarIcon } from '../../common/assets/Icons/GoogleCalendarIcon';
import { OutlookCalendarIcon } from '../../common/assets/Icons/OutlookCalendarIcon';

import azureSample from '../../../../app/images/integrations/azure_sample.png';
import googleCalendarSample from '../../../../app/images/integrations/GCal-card-content.png';
import outlookCalendarSample from '../../../../app/images/integrations/outlook-calendar-sample.png';
import { IntegrationCodes } from './IntegrationCodes';
import { WorkdayIcon } from '../../common/assets/Icons/WorkdayIcon';

export const Common = {
  highestPlan: 'Professional'
};

export enum HRISIntegrationType {
  Workday = 'workday'
}

export const IntegrationDetails: IIntegrationDetails = {
  [IntegrationCodes.AzureAD]: {
    integrationName: 'Azure AD',
    runsOnSchedule: true,
    logo: AzureAdIcon,
    altText: 'Azure AD logo',
    title: 'User Configuration with Azure AD',
    description:
      'Daily sync changes to Groups and Do Not Track list from Azure AD',

    modalTitle: 'User Configuration with Azure AD',
    modalDescription:
      'Daily sync changes to Groups and Do Not Track list from Azure AD',
    modalExampleImage: azureSample,
    modalExampleAltText: 'Azure example'
  },

  [IntegrationCodes.OutlookCalendar]: {
    integrationName: 'Outlook Calendar',
    runsOnSchedule: true,
    logo: OutlookCalendarIcon,
    altText: 'Outlook Calendar logo',
    title: 'Outlook Calendar Integration',
    description:
      "Get a complete picture of employees' workdays by supplementing digital activity data with calendar data",

    modalTitle: 'Outlook Calendar Integration',
    modalDescription:
      "View meeting data from employees' calendars alongside digital activity in your ActivTrak account",
    modalExampleImage: outlookCalendarSample,
    modalExampleAltText: 'Outlook Calendar example'
  },

  [IntegrationCodes.GoogleCalendar]: {
    integrationName: 'Google Calendar',
    runsOnSchedule: true,
    logo: GoogleCalendarIcon,
    altText: 'Google Calendar logo',
    title: 'Google Calendar Integration',
    description:
      "Get a complete picture of employees' workdays by supplementing digital activity data with calendar data",

    modalTitle: 'Google Calendar Integration',
    modalDescription:
      "View meeting data from employees' calendars alongside digital activity in your ActivTrak account",
    modalExampleImage: googleCalendarSample,
    modalExampleAltText: 'Google Calendar example'
  },
  [HRISIntegrationType.Workday]: {
    integrationName: 'Workday',
    runsOnSchedule: true,
    logo: WorkdayIcon,
    altText: 'Workday Integration logo',
    title: 'Workday Integration',
    description:
      "Workday a complete picture of employees' workdays by supplementing digital activity data with workday data",

    modalTitle: 'Workday Integration',
    modalDescription:
      "View meeting data from employees' calendars alongside digital activity in your ActivTrak account",
    modalExampleImage: outlookCalendarSample,
    modalExampleAltText: 'Workday example'
  }
};
