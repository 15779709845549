import { getAccountSettings } from '../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { profileStore } from '../../../_reactivtrak/src/common/services/Profile/useProfileState';
import firstTimeLoginModalTemplate from 'views/modals/firstTimeLoginModal.html?raw';
angular.module('app').service('profileService', ProfileService);

ProfileService.$inject = [
    '$http',
    'envConfig',
    '$q',
    '$injector',
    'customUibModal',
    'analyticsService',
    'authorizationService',
    'atHelperFunctions',
    'browserServiceFunctions'
];

function ProfileService(
    $http,
    envConfig,
    $q,
    $injector,
    customUibModal,
    analyticsService,
    authorizationService,
    atHelperFunctions,
    browserServiceFunctions
) {
    var service = this;

    this.profileRoles = [
        'Analyst',
        'Architect',
        'C-Level',
        'CEO/President',
        'Chancellor/Dean/Provost',
        'Consultant/Systems Integrator',
        'Coordinator/Specialist',
        'Developer/Engineer',
        'Director',
        'Manager',
        'Professor/Teacher',
        'Student',
        'VP'
    ];

    this.profileIndustries = [
        'Administration (Management)',
        'Analytics',
        'Channel Partner',
        'Engineering/R&D',
        'Finance',
        'Human Resources',
        'IT',
        'Legal',
        'Manufacturing',
        'Marketing',
        'Product Management',
        'Purchasing',
        'Sales',
        'Supply Chain & Distribution',
        'Support/Service'
    ];

    // Mock data
    this.profileInfo = {
        firstName: null,
        lastName: null,
        phone: null,
        title: null,
        industry: null,
        creationDate: null
    };

    this.cleanInputs = function (profile) {
        for (var key in profile) {
            if (typeof profile[key] === 'string') {
                profile[key] = profile[key].replace(/\s+/g, ' ').trim();
            }
        }
        return profile;
    };

    function encodeProfileValues(profile) {
        profile.phone = browserServiceFunctions.htmlEscape(profile.phone);
        profile.firstName = browserServiceFunctions.htmlEscape(profile.firstName);
        profile.lastName = browserServiceFunctions.htmlEscape(profile.lastName);
        return profile;
    }

    function decodeProfileValues(profile) {
        profile.phone = browserServiceFunctions.htmlDecode(profile.phone);
        profile.firstName = browserServiceFunctions.htmlDecode(profile.firstName);
        profile.lastName = browserServiceFunctions.htmlDecode(profile.lastName);
        return profile;
    }

    this.loadProfile = function () {
        var state = profileStore.getState();

        var result = state.profile;

        service.profileInfo = {
            firstName: result.firstName ? result.firstName : service.profileInfo.firstName,
            lastName: result.lastName ? result.lastName : service.profileInfo.lastName,
            phone: result.phone,
            title: result.title,
            industry: result.industry,
            creationDate: result.creationDate,
            ssoEnabled: result.ssoEnabled
        };
        service.profileInfo = decodeProfileValues(service.profileInfo);
        analyticsService.identify();

        var { creator, firstName, lastName, phone } = getAccountSettings();
        var { username } = profileStore.getState().profile;
        if (
            !authorizationService.hasRole([
                authorizationService.roles.supportBasic,
                authorizationService.roles.superAdmin
            ]) &&
            (atHelperFunctions.isEmpty(service.profileInfo.firstName) ||
                atHelperFunctions.isEmpty(service.profileInfo.lastName))
        ) {
            if (!atHelperFunctions.isEmpty(creator) && creator === username) {
                var payload = {
                    firstName,
                    lastName,
                    phone
                };
                service.updateProfile(payload);
            } else {
                customUibModal.open({
                    animation: false,
                    keyboard: false,
                    template: firstTimeLoginModalTemplate,
                    controller: 'firstTimeLoginModal',
                    windowClass: 'centered-modal',
                    backdrop: 'static',
                    resolve: {
                        profileService: service,
                        pageStart: 1
                    }
                });
            }
        }
    };

    this.updateProfile = function (payload) {
        var deferred = $q.defer();
        payload.phoneNumber = payload.phone;
        payload = encodeProfileValues(payload);
        $http
            .post(envConfig.apiUrl() + '/profileInfo/upsert', payload)
            .then(function () {
                service.profileInfo = decodeProfileValues(payload);
                deferred.resolve();
                var analyticsService = $injector.get('analyticsService');
                analyticsService.updateIntercom();
            })
            .catch(function () {
                deferred.reject();
            });

        return deferred.promise;
    };
}
