export const localStorageItems = {
  childAccount: 'activTrak.child-',
  globalSettingsStorage: 'activTrak.globals',
  classificationSettingStorage: 'activTrak.newClassifications',
  allActivitiesModal: 'activTrak.dontShowAgainShowAll-',
  dashboardSettingsStorage: 'activTrak.dashboardSettings-',
  reportFiltersStorage: 'activTrak.reportFilters-',
  reportFiltersStorageV2: 'activTrak.reportFiltersV2-',
  reportFiltersSecondaryStorage: 'activTrak.reportFilterSecondary-',
  insightsFiltersStorage: 'activTrak.insights_filters-',
  commandCenterBackupToken: 'activTrak.msp.backup',
  deleteUserEmailModal: 'activTrak.dontShowDeleteIdentityEmail',
  identitySearchParam: 'activTrak.identitySearchParam'
};
