import { Role, RoleAccessKeys } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsClassificationTab: IRoute = {
  name: 'app.settings.classification.tab',
  stateDefinition: {
    url: '/{activeTab}?filter',
    data: { pageTitle: 'Classifications' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.SupportIntermediate]
  }
};

export default appSettingsClassificationTab;
