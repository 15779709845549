import { getAccountSettings } from '../../../common/stores/accountSettingsStore/accountSettingsStore';
import { activTrakConfig } from '../../../common/stores/apiDomainStore/activtrak.config';
import { profileStore } from '../../../common/services/Profile/useProfileState';

const marketoConfig = activTrakConfig.marketo;

const hiddenFormFields = {
  UTM_Medium__c: 'in_product',
  UTM_Source__c: 'in_product',
  UTM_Content__c: 'not_provided',
  UTM_Term__c: 'not_provided',
  UTM_Campaign__c: 'not_provided',
  UTM_Referrer__c: 'not_provided',
  UTM_Landing__c: 'not_provided'
};

export const getProfile = () => {
  const { profile } = profileStore.getState();
  return profile;
};

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`) ?? [];

  if (parts?.length === 2) {
    return parts.pop()?.split(';').shift();
  }

  return null;
}

const getUtm = () => {
  const utm: any = {};

  utm.source = getCookie('utm_source');
  utm.medium = getCookie('utm_medium');
  utm.term = getCookie('utm_term');
  utm.campaign = getCookie('utm_campaign');
  utm.content = getCookie('utm_content');
  utm.landing = getCookie('utm_landing');
  utm.referrer = getCookie('utm_referrer');
  utm.landing = getCookie('utm_landing');
  utm.gclid = getCookie('gclid');

  return utm;
};

const configureForm = (form, vals, hiddenFields, successCallback) => {
  const utm = getUtm();
  const utmHiddenFields = {
    UTM_Source__c: utm.source,
    UTM_Medium__c: utm.medium,
    UTM_Term__c: utm.term,
    UTM_Content__c: utm.content,
    UTM_Campaign__c: utm.campaign,
    GCLID__c: utm.gclid,
    UTM_Referrer__c: utm.referrer,
    UTM_Landing__c: utm.landing
  };

  const hiddenFieldsWithUtm = hiddenFields
    ? Object.assign(utmHiddenFields, hiddenFields)
    : utmHiddenFields;

  if (vals) {
    form.vals(vals);
  }

  form.addHiddenFields(hiddenFieldsWithUtm);

  if (successCallback) {
    form.onSuccess(successCallback);
  }
};

const generateFormFields = () => {
  const userProfile = getProfile();
  const accountSettings = getAccountSettings();

  return {
    Phone: userProfile.phone,
    FirstName: userProfile.firstName,
    LastName: userProfile.lastName,
    Email: userProfile.username,
    Company: accountSettings.companyName,
    Paid_Limit__c: accountSettings.usedLicenses
  };
};

export const showForm = (formId: number, callback?: () => void) => {
  window.MktoForms2.loadForm(
    marketoConfig.formUrl,
    marketoConfig.munchkinId,
    formId,
    (form) => {
      configureForm(form, generateFormFields(), hiddenFormFields, callback);
      window.MktoForms2.lightbox(form).show();
    }
  );
};

export const showTryOfflineMeetingsMarketoForm = () => {
  const formId = marketoConfig?.workingHoursReport?.offlineMeetings;
  showForm(formId);
};

export const showTryLocationMarketoForm = () => {
  const formId = marketoConfig?.workingHoursReport?.location;
  showForm(formId);
};
