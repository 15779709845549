import { ColDef, ColumnState } from 'ag-grid-community';
import {
  IGridColumnFilter,
  IReportColumnLabel
} from '../models/IGridColumnFilter.models';

export const mapColDefToColumnState = (newColumnState: ColDef): ColumnState => {
  return {
    colId: newColumnState.colId,
    hide: newColumnState.hide,
    width: newColumnState.width,
    flex: newColumnState.flex,
    sort: newColumnState.sort,
    sortIndex: newColumnState.sortIndex
  };
};

export const mapColumnStateToGridColumnFilter = (
  gridColumn: IReportColumnLabel,
  currentColumnState: ColumnState[]
) => {
  return {
    colId: gridColumn.colId,
    label: gridColumn.customLabel ?? gridColumn.label,
    hide:
      gridColumn.colId === 'all'
        ? true
        : currentColumnState.find((c) => c?.colId === gridColumn?.colId)
            ?.hide ?? true
  };
};

export const handleAllCheckbox = (
  cols: IGridColumnFilter[]
): IGridColumnFilter[] => {
  const clonedState = [...cols];

  //check if any labels, besides 'All', are not selected
  const hasHiddenCols = clonedState.filter(
    (col) => col.colId !== 'all' && col.hide === true
  ).length;

  //directly set the state of 'All' label based on 'hasHiddenCols'
    const allIndex = clonedState.findIndex((col) => col.colId === 'all');
    if (allIndex !== -1)
      clonedState[allIndex].hide = hasHiddenCols ? true : false;

  return clonedState;
};
