import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { useSubscription } from 'observable-hooks';
import { productivityStatuses } from '../../common/constants';
import { useNotifications } from '../../common/services/Notifications';
import {
  useClassificationViewUtils,
  filterOptions,
  bulkClassificationFilters
} from '../utils';
import { Grid, Tabs, Tab } from '@mui/material';
import { TabPanel } from '../../common/components/Tabs';
import { ClassificationType, Role } from '../../common/enums';
import { ICategory, IClassificationActivity } from '../../common/models';
import { IClassificationFilter } from '../models';
import ClassificationSearch from '../components/ClassificationSearch';
import PendingClassifications from '../components/PendingClassifications';
import ClassifiedClassifications from '../components/ClassifiedClassifications';
import ClassificationSettings from '../components/ClassificationSettings';
import AssignCategoriesDialog from '../components/AssignCategoriesDialog';
import {
  ClassificationsContainer,
  classificationClasses
} from '../styles/Classifications.styles';
import {
  useClassificationStores,
  useClassification,
  ClassificationProvider,
  ClassificationStoreProvider
} from '../services';
import { useAuthorization } from '../../common/services/Authorization';
import { RecentActivitiesProps } from '../../common/models/RecentActivitiesProps';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { GroupsProvider } from '../../common/services/Groups';
import { GroupClassificationsProvider } from '../../common/services/Classifications';
import Activity from './Activity.view';

import {
  useUIRouterHistory,
  useUIRouterState
} from '../../common/hooks/useUIRouterHistory';
import { UIRouter, UIRoute } from './UIRouter';
import { navA11yTabProps } from '../../common/utils/a11y.utils';

export default function ClassificationsView() {
  const [tab, setTab] = useState<number>(0);
  const [currentGetActivitiesProps, setCurrentGetActivitiesProps] =
    useState<RecentActivitiesProps>(null);
  const [selectedItems, setSelectedItems] =
    useState<IClassificationActivity[]>(null);
  const [categories, setCategories] = useState<ICategory[]>();
  const [assignType, setAssignType] = useState('None');
  const [assignValue, setAssignValue] = useState('None');
  const [assignItem, setAssignItem] = useState<
    Record<string, { productivity: number; categoryId: string }>
  >({});
  const [openDialog, setOpenDialog] = useState(false);

  const { categoryService } = useClassification();
  const { classificationStore } = useClassificationStores();

  const {
    activitiesState,
    updateActivities,
    setCurrentSearchValue,
    setSelectedActivities,
    setCurrentFilter,
    setDisabledActivities,
    resetActivitiesError,
    setIsClassified
  } = classificationStore;

  const {
    activities,
    showAllActivities,
    currentSearchValue,
    currentFilter,
    isClassified,
    error: activityError,
    categories: filterCategories
  } = activitiesState;

  const notificationService = useNotifications();

  const history = useUIRouterHistory();
  const state = useUIRouterState<{ activeTab: string }>();
  const activeTab = state.params?.activeTab;

  const urlQuery: URLSearchParams = useRef(
    new URLSearchParams(window.location.href.split('?')[1])
  ).current;

  const authorizationService = useAuthorization();
  const isSupportAdvanced = authorizationService.hasAnyRole([
    Role.SupportAdvanced
  ]);

  const { handleUpdateActivityChange, handleSortOrder } =
    useClassificationViewUtils();

  useEffect(() => {
    let filter: IClassificationFilter;

    const urlFilter = urlQuery.get('filter');
    if (urlFilter) {
      filter = bulkClassificationFilters[urlFilter];
      setCurrentFilter(filter);
    }

    const status =
      filter?.label || currentFilter?.label || ClassificationType.All;

    const defaultProps = {
      classified: false,
      term: currentSearchValue,
      categories: filterCategories,
      status: status,
      minDuration: showAllActivities ? 0 : 300
    };

    switch (activeTab) {
      case 'classified':
        setTab(1);
        setIsClassified(true);
        setCurrentGetActivitiesProps({
          ...defaultProps,
          classified: true
        });
        break;

      case 'settings':
        setTab(2);
        setIsClassified(false);
        break;

      default:
        setTab(0);
        setIsClassified(false);
        setCurrentGetActivitiesProps(defaultProps);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    setIsClassified,
    urlQuery,
    setCurrentFilter,
    filterCategories,
    currentSearchValue,
    showAllActivities
  ]);

  useSubscription(categoryService.getAll(), setCategories, (error) =>
    notificationService.error(error)
  );

  useEffect(() => {
    if (activityError) {
      notificationService.error(activityError);
      resetActivitiesError();
    }
  }, [activityError, resetActivitiesError, notificationService]);

  const handleTabChange = (
    event: React.ChangeEvent<object>,
    newTab: number
  ) => {
    //reset get api props when page changes
    setCurrentGetActivitiesProps(null);

    setTab(newTab);
    setIsClassified(newTab === 1);
    setSelectedItems(null);
    setCurrentSearchValue('');
    setCurrentFilter(null);
  };

  const handleSelectedItems = useCallback(
    (selectedItems) => {
      //local state
      setSelectedItems(selectedItems);

      //global state
      setSelectedActivities(selectedItems);
    },
    [setSelectedActivities]
  );

  const handleActivitiesFilter = useCallback(
    (filter: IClassificationFilter) => {
      if (!filter) {
        return;
      }

      setCurrentFilter(filter);
      setCurrentGetActivitiesProps((previousValue) => {
        return {
          ...previousValue,
          status: filter.label
        };
      });
    },
    [setCurrentFilter]
  );

  const handleBulkUpdateActivityChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;

      setAssignItem({});
      if (value) {
        if (name === 'localCategoryId') {
          setAssignValue(categories?.find((c) => c.id == value)?.name ?? '');
          setAssignType('category');
          setAssignItem({
            localCategoryId: { productivity: undefined, categoryId: value }
          });
          setOpenDialog(true);
        } else if (name === 'localProductivity') {
          const intValue = Number.parseInt(value);
          if (intValue) {
            setAssignValue(
              productivityStatuses.find((p) => p.id === intValue)?.name ??
                'None'
            );
            setAssignType('status');
            setAssignItem({
              localProductivity: {
                productivity: intValue,
                categoryId: undefined
              }
            });
            setOpenDialog(true);
          }
        }
      }
    },
    [categories, setOpenDialog, setAssignType, setAssignValue, setAssignItem]
  );

  const handleDialogClose = () => {
    closeDialog();
  };

  const closeDialog = () => {
    setAssignItem({});
    setAssignValue('None');
    setAssignType('None');
    setOpenDialog(false);
  };

  const handleDialogAssign = useCallback(async () => {
    if (assignItem) {
      const params = assignItem.localCategoryId
        ? {
            localCategoryId: assignItem.localCategoryId.categoryId,
            categoryId: assignItem.localCategoryId.categoryId,
            categoryName: ''
          }
        : assignItem.localProductivity
        ? {
            localProductivity: assignItem.localProductivity.productivity,
            productivity: assignItem.localProductivity.productivity
          }
        : {};

      setDisabledActivities(selectedItems);

      if (Object.keys(params).length && selectedItems?.length) {
        const tmpSelectedItems = [...selectedItems];
        const finalSelectedItems = tmpSelectedItems.map((item) => {
          const categoryId = params.categoryId ?? item.categoryId;
          params.categoryName =
            categories?.find((c) => c.id == categoryId)?.name ?? '';
          return { ...item, ...params };
        });
        updateActivities(finalSelectedItems, true);
      }
    }
    closeDialog();
  }, [
    assignItem,
    categories,
    selectedItems,
    setDisabledActivities,
    updateActivities
  ]);

  const handleClickRowGrid = useCallback(
    (activitySelected: IClassificationActivity) => {
      history.push('app.settings.classification.id', {
        id: activitySelected.id,
        type: activitySelected.type
      });
      setCurrentGetActivitiesProps(null);
    },
    [history]
  );

  const classificationsTabs = useMemo(() => {
    return {
      pending: {
        title: 'Pending',
        tab: 'pending',
        content: (
          <PendingClassifications
            getApiProps={currentGetActivitiesProps}
            defaultFilter={currentFilter}
            categories={categories}
            handleClickRowGrid={handleClickRowGrid}
            activitiesState={activitiesState}
            selectedItems={selectedItems}
            onActivitiesFilter={handleActivitiesFilter}
            onUpdateActivityChange={handleUpdateActivityChange}
            onBulkUpdateActivityChange={handleBulkUpdateActivityChange}
            onSelectedItems={handleSelectedItems}
            onSortOrder={handleSortOrder}
            hasBulkSelection={true}
            isDisabled={isSupportAdvanced}
          />
        )
      },
      classified: {
        title: 'Classified',
        tab: 'classified',
        content: (
          <ClassifiedClassifications
            getApiProps={currentGetActivitiesProps}
            defaultFilter={currentFilter}
            categories={categories}
            handleClickRowGrid={handleClickRowGrid}
            activitiesState={activitiesState}
            selectedItems={selectedItems}
            onActivitiesFilter={handleActivitiesFilter}
            onUpdateActivityChange={handleUpdateActivityChange}
            onBulkUpdateActivityChange={handleBulkUpdateActivityChange}
            onSelectedItems={handleSelectedItems}
            onSortOrder={handleSortOrder}
            hasBulkSelection={true}
            isDisabled={isSupportAdvanced}
          />
        )
      },
      settings: {
        title: 'Settings',
        tab: 'settings',
        content: <ClassificationSettings isDisabled={isSupportAdvanced} />
      }
    };
  }, [
    activitiesState,
    currentFilter,
    currentGetActivitiesProps,
    categories,
    selectedItems,
    handleActivitiesFilter,
    handleUpdateActivityChange,
    handleBulkUpdateActivityChange,
    handleClickRowGrid,
    handleSelectedItems,
    handleSortOrder,
    isSupportAdvanced
  ]);

  return (
    <>
      <ClassificationsContainer>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Tabs component="div" value={tab} onChange={handleTabChange}>
              {Object.entries(classificationsTabs).map((t, index) => (
                <Tab
                  key={`nav-tab-${t[0]}`}
                  {...navA11yTabProps(index)}
                  label={t[1].title}
                  onClick={() => {
                    history.push('app.settings.classification.tab', {
                      activeTab: t[1].tab
                    });
                  }}
                  disabled={tab === index}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={6}>
            {tab !== 2 ? (
              <ClassificationSearch
                autoCompleteOptions={activities}
                filterOptions={(options, state) =>
                  filterOptions(
                    options,
                    state,
                    classificationClasses.pressEnterCTA
                  )
                }
                categories={categories}
                isClassified={isClassified}
                activeTab={activeTab}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        {Object.entries(classificationsTabs).map((t, index) => {
          return (
            <TabPanel
              value={tab}
              index={index}
              id={`nav-tabpanel-${t[0]}`}
              key={`nav-tabpanel-${t[0]}`}
            >
              {t[1].content}
            </TabPanel>
          );
        })}
      </ClassificationsContainer>
      <AssignCategoriesDialog
        open={openDialog}
        assignType={assignType}
        selectedCount={selectedItems?.length}
        assignValue={assignValue}
        onClose={handleDialogClose}
        onAssign={handleDialogAssign}
      />
    </>
  );
}

export const ClassificationsWrapper = () => {
  return (
    <ReactivTrakComponentWrapper>
      <ClassificationProvider>
        <ClassificationStoreProvider>
          <GroupsProvider>
            <GroupClassificationsProvider>
              <UIRouter>
                <UIRoute route={'app.settings.classification.tab'}>
                  <ClassificationsView />
                </UIRoute>
                <UIRoute route={'app.settings.classification.id'}>
                  <Activity />
                </UIRoute>
              </UIRouter>
            </GroupClassificationsProvider>
          </GroupsProvider>
        </ClassificationStoreProvider>
      </ClassificationProvider>
    </ReactivTrakComponentWrapper>
  );
};
