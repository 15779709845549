import { IAccountPlan, IAccountPlanDto } from '../models';
import { atHelperFunctions } from '../../../../_app/serviceFunctions/atHelperFunctions';
import moment from 'moment';
import { PlanType } from '../enums/PlanType';
import { PlanPeriod } from '../enums/PlanPeriod';

const createPlanInfoLabels = (planType: PlanType, planPeriod: PlanPeriod) => {
  const isTrial = planPeriod === PlanPeriod.Trial;
  const planTypeSuffix = isTrial ? ' Trial' : ' Plan';
  const planPeriodLabel = atHelperFunctions
    .capitalizeFirstLetterEachWord(planPeriod ?? '')
    .replace('none', '');

  const planTypeLabel = (planType ?? '') + planTypeSuffix;

  const planTypePeriodLabel =
    planTypeLabel + (planPeriodLabel ? ' ' + planPeriodLabel : '');

  return { planTypeLabel, planTypePeriodLabel };
};

const createRemainingDays = (renewDate: string) => {
  let remainingDays = 0;
  const now = moment.utc();
  const renewDateMoment = moment.utc(renewDate);
  const remainingMinutes = moment.duration(
    renewDateMoment.diff(now, 'minutes'),
    'minutes'
  );

  if (remainingMinutes.asMinutes() !== 0) {
    remainingDays = remainingMinutes.asDays();
  }

  return remainingDays;
};

export const mapAccountPlanDtoToAccountPlan = (
  response: IAccountPlanDto
): IAccountPlan => {
  const { planInfo, previousPlanInfo, renewDate, isDowngrade } = response;
  const planType = Object.values(PlanType).find(
    (value) => value.toLowerCase() === planInfo.type.replace('choice', '')
  ) as PlanType;
  const { planTypeLabel, planTypePeriodLabel } = createPlanInfoLabels(
    planType,
    planInfo.period
  );
  let accountPlanAge = -1;

  if (previousPlanInfo?.endDate) {
    const given = moment(previousPlanInfo.endDate);
    const current = moment().startOf('day');
    accountPlanAge = moment.duration(current.diff(given)).asDays();
  }

  return {
    planCode: planInfo.code,
    planType: planType,
    planTypeLabel,
    planTypePeriodLabel,
    planPeriod: planInfo.period,
    planAge: accountPlanAge,
    planRenewDate: renewDate,
    planRemainingDays: createRemainingDays(renewDate),
    isPlanDowngraded: isDowngrade
  };
};
