import React from 'react';
import { Link, Typography, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Numeral from 'numeral';

import Sidebar from '../Sidebar/Sidebar.jsx';
import { layoutClasses } from './Layout.styles.js';
import AppHeader from '../../components/Header/Header.jsx';
import UserActions from '../../components/Header/UserActions/UserActions.jsx';
import { evaluateResourceAccess } from '../../utils/api/utils.js';

import { CC_TYPE } from '../../utils/constants.js';
import { UserAllIcon } from '../../../../_reactivtrak/src/common/assets/Icons/UserAllIcon.tsx';
import { getSupportBackupToken, setSupportBackupToken } from '../../../../_reactivtrak/src/common/hooks/useImpersonate.ts';
import { setUserToken } from '../../../../_reactivtrak/src/common/helpers/authentication/useUserTokenStore.ts';

import { rewire } from '../../../../_entry/rewire.ts';
import { LoadApplication } from '../../../../_reactivtrak/src/common/services/Application/AppLoad.ts';

const Layout = ({
  activeUsers,
  licenseQuantity,
  children,
  profile,
  openDialog,
  commandCenterType,
  accountTypeCount,
  hasSupportPortalRole,
  isImpersonating
}) => {
  const isSupport = () => hasSupportPortalRole || isImpersonating;

  const renderActiveUsers = () => {
    switch (commandCenterType) {
      case CC_TYPE.Standard:
        return (
          <>
            {evaluateResourceAccess(profile, 'CCPARENTACCOUNTLINK') && (
              <Box sx={layoutClasses.actionItem}>
                <Link
                  href="/#/app/home"
                  target="_self"
                  underline="none"
                  variant="caption"
                >
                  Parent Account
                </Link>
              </Box>
            )}
            <Box sx={layoutClasses.actionItem} id="activeUsersHeader">
              <Typography variant="caption">
                Active Users ({Numeral(activeUsers).format('0,0')})
              </Typography>
            </Box>
          </>
        );
      case CC_TYPE.Enterprise:
        if (activeUsers || licenseQuantity) {
          return (
            <Box sx={layoutClasses.actionItem} id="activeUsersHeader">
              <Typography variant="caption">
                <Box display="flex" alignItems="center">
                  <span>
                    {activeUsers ? activeUsers : '0'}/
                    {licenseQuantity ? licenseQuantity : '0'}
                  </span>{' '}
                  <UserAllIcon style={{ marginLeft: '5px' }} />
                </Box>
              </Typography>
            </Box>
          );
        }
        return (
          <Box sx={layoutClasses.actionItem} id="activeUsersHeader">
            <Typography variant="caption">
              <Box display="flex" alignItems="center">
                -/- <UserAllIcon style={{ marginLeft: '5px' }} />
              </Box>
            </Typography>
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box sx={layoutClasses.root}>
      <CssBaseline />
      <AppHeader>
        <Box sx={layoutClasses.headerActions}>
          {renderActiveUsers()}
          {isSupport() && (
            <Box sx={layoutClasses.actionItem}>
              <Link
                href="/#/"
                target="_self"
                underline="none"
                variant="caption"
                onClick={async (e) => {
                  e.preventDefault();
                  const backup = getSupportBackupToken();
                  setUserToken(backup);
                  setSupportBackupToken('invalid');

                  await LoadApplication();
                  history.pushState(null, '', '/#/support');
                  rewire();

                  return;
                }}
              >
                Go to Support Portal
              </Link>
            </Box>
          )}
          {profile?.firstName && (
            <Box sx={layoutClasses.actionItem}>
              <Typography variant="caption">
                Hello, {profile.firstName} {profile.lastName} (
                {profile.username})
              </Typography>
              <UserActions firstName={profile.firstName} />
            </Box>
          )}
        </Box>
      </AppHeader>
      <Sidebar
        profile={profile}
        openDialog={openDialog}
        commandCenterType={commandCenterType}
        accountTypeCount={accountTypeCount}
      />
      <Box sx={layoutClasses.content}>{children}</Box>
    </Box>
  );
};

export default Layout;
