import React from 'react';
import { Typography } from '@mui/material';
import { cardTopCategoryClasses } from './CardTopCategory.style';
import TopCategory from '../../../models/TopCategory';
import { InfoPanel } from '../';
import { hasRole } from '../../../../../_reactivtrak/src/common/helpers/authorization';
import { Role } from '../../../../../_reactivtrak/src/common/enums';

const CardTopCategory = (props) => {
    const { topCategories, rootClass, contentClass, loading, type, variant } = props;
    const topCategory = new TopCategory({ topCategories, type });
    const headingClass = topCategory.name ? {} : cardTopCategoryClasses.none;
    const isSupportAdvanced = hasRole(Role.SupportAdvanced);

    const setStyling = () => {
        return isSupportAdvanced
            ? { ...cardTopCategoryClasses.disabled, ...rootClass, ...cardTopCategoryClasses.root }
            : { ...rootClass, ...cardTopCategoryClasses.root };
    };

    return (
        <div style={setStyling()}>
            <div style={{ ...contentClass, ...cardTopCategoryClasses.content }}>
                <div style={cardTopCategoryClasses.flexChild}>
                    <InfoPanel
                        heading={topCategory.link}
                        headingClass={headingClass}
                        subheading={'Top Category'}
                        largeHeading={true}
                        loading={loading}
                        variant={variant}
                    />
                </div>
                <div style={cardTopCategoryClasses.grow} />
                {!loading && topCategory.duration > 0 && (
                    <Typography sx={{ ...cardTopCategoryClasses.flexFixed, ...cardTopCategoryClasses.hourTotal }}>
                        {topCategory.durationLabel}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default CardTopCategory;
