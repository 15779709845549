import React, {
  ChangeEvent,
  createRef,
  MutableRefObject,
  useEffect,
  useState
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import {
  IInsightsCategory,
  ICoreCategory,
  IRolesCategories
} from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import { useCategoriesState } from '../../hooks/useCategoriesState';
import CategoryCheckboxList from './CategoryCheckboxList';
import {
  DialogContentStyled,
  StyledSearchBarContainer,
  StyledBoxContainer
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import BackButtonBox from './BackButtonBox';
import CategoryTextContainer from './CategoryTextContainer';
import { handleDropDownSearchChange } from '../../utils/TimeOnTask.view.utils';
import TextFieldContainer from './TextFieldContainer';
import RadioGroupRoleBox from './RadioGroupRoleBox';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onBackToStep2: () => void;
  onGoToStep4: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
};

export const Step3DialogPreset = (props: ComponentProps) => {
  const { open, onClose, onBackToStep2, onGoToStep4, coreCategoryRef } = props;

  const {
    init,
    categories,
    isLoading,
    setCategories,
    isRoleLoading,
    fetchRolesCategories,
    rolesCategories,
    setRolesCategories
  } = useCategoriesState();

  const [checkedItems, setCheckedItems] = useState<IInsightsCategory[]>([]);
  const [showCategorySearch, setShowCategorySearch] = useState<boolean>(false);
  const [showRoleSearch, setShowRoleSearch] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<IInsightsCategory[]>([]);
  const [filteredRoles, setFilteredRoles] = useState<IRolesCategories[]>([]);
  const textInputRef = createRef<HTMLInputElement>();
  const roleTextInputRef = createRef<HTMLInputElement>();
  const [searchString, setSearchString] = useState<string>('');
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      fetchRolesCategories();
      init(coreCategoryRef.current.groupId);
      if (coreCategoryRef.current.categories.length > 0) {
        setCheckedItems(coreCategoryRef.current.categories);
        setShowCategorySearch(true);
      }
      if (coreCategoryRef.current.role) {
        setSelected(coreCategoryRef.current.role);
        setShowRoleSearch(false);
      }
    }
  }, [coreCategoryRef, fetchRolesCategories, init, open]);

  useEffect(() => {
    if (open && categories.length > 0) {
      setFiltered(categories);
    }
    if (open && rolesCategories?.length > 0) {
      setFilteredRoles(rolesCategories);
    }
  }, [categories, open, rolesCategories]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleDropDownSearchChange(event, categories, setFiltered, setSearchString);
  };

  const handleRoleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.toLowerCase().trim();
    if (!input) {
      return;
    }

    const matches = rolesCategories.filter(
      (x) => x.role.toLowerCase().indexOf(input) > -1
    );
    setFilteredRoles(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSelected(value);
    coreCategoryRef.current.role = value;
    const recommendedCats = name.split('::');
    setCheckedItems(
      categories.filter((cat) => recommendedCats.includes(cat.category))
    );
    setShowRoleSearch(false);
    setShowCategorySearch(true);
  };

  const goToStep4 = () => {
    coreCategoryRef.current.categories = checkedItems;
    setCheckedItems([]);
    setShowCategorySearch(false);
    setSelected(null);
    onGoToStep4();
  };

  const close = () => {
    coreCategoryRef.current.categories = [];
    setCheckedItems([]);
    setShowCategorySearch(false);
    setFiltered([]);
    setCategories([]);
    setSelected(null);
    setShowRoleSearch(false);
    setFilteredRoles([]);
    setRolesCategories([]);
    onClose();
  };

  const backToStep2 = () => {
    coreCategoryRef.current.categories = checkedItems;
    setCheckedItems([]);
    setShowCategorySearch(false);
    setFiltered([]);
    setCategories([]);
    setSelected(null);
    setShowRoleSearch(false);
    setFilteredRoles([]);
    setRolesCategories([]);
    onBackToStep2();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <BackButtonBox onClick={backToStep2} />
        <Box width={570} maxHeight={630}>
          <DialogTitle>
            Step 3 of 4: Select a role to see recommended categories
          </DialogTitle>
          <DialogContentStyled>
            <Typography sx={{ marginBottom: '10px' }}>
              Choose the role most closely aligned with the selected team.
            </Typography>
            {isRoleLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  marginBottom: '10px'
                }}
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Box sx={{ marginBottom: '10px' }}>
                <TextFieldContainer
                  showSearch={showRoleSearch}
                  setShowSearch={setShowRoleSearch}
                  placeholder={coreCategoryRef.current.role || 'Role'}
                  setShowCategorySearch={setShowCategorySearch}
                />
                <StyledBoxContainer hidden={!showRoleSearch}>
                  <StyledSearchBarContainer>
                    <SearchBar
                      placeholder={'Search Roles'}
                      fieldRef={roleTextInputRef}
                      onClick={(e) => e.stopPropagation()}
                      handleDropDownSearchChange={(e) =>
                        handleRoleSearchChange(e)
                      }
                    />
                  </StyledSearchBarContainer>
                  <RadioGroupRoleBox
                    selected={selected}
                    handleChange={handleRoleChange}
                    filtered={filteredRoles}
                  />
                </StyledBoxContainer>
              </Box>
            )}
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <CircularProgress />
              </Box>
            ) : coreCategoryRef.current.role ? (
              <>
                <CategoryTextContainer
                  showSearch={showCategorySearch}
                  setShowSearch={setShowCategorySearch}
                  isHideable={true}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  setShowRoleSearch={setShowRoleSearch}
                />
                <StyledBoxContainer hidden={!showCategorySearch}>
                  <StyledSearchBarContainer>
                    <SearchBar
                      placeholder={
                        'Search Categories, Websites, or Applications'
                      }
                      fieldRef={textInputRef}
                      onClick={(e) => e.stopPropagation()}
                      handleDropDownSearchChange={(e) => handleSearchChange(e)}
                    />
                  </StyledSearchBarContainer>
                  <CategoryCheckboxList
                    filtered={filtered}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    searchString={searchString}
                  />
                </StyledBoxContainer>
              </>
            ) : (
              <TextField
                variant="outlined"
                fullWidth
                multiline={true}
                size="small"
                value={'Categories'}
                disabled={true}
                InputProps={{
                  style: { paddingLeft: '0' },
                  readOnly: true,
                  endAdornment: (
                    <IconButton disabled={true}>
                      <KeyboardArrowDown />
                    </IconButton>
                  )
                }}
              />
            )}
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={goToStep4}
              disabled={checkedItems.length === 0}
              confirmText={'Go to step 4 of 4'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
