import template from '../../../views/widgets/aggregateProductivityChart.html?raw';
import ProductivityStatus from '../../../../_app/constants/productivityStatus';
import authorizationService from '../../../../_reactivtrak/src/common/helpers/authorization';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import lodash from 'lodash';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').directive('aggregateProductivityChart', aggregateProductivityChart);

function aggregateProductivityChart() {
    return {
        restrict: 'E',
        scope: {
            chartType: '=',
            config: '=',
            status: '=?'
        },
        template: template,
        controller: AggregateProductivityChartCtrl
    };
}

AggregateProductivityChartCtrl.$inject = [
    '$scope',
    '$window',
    'chartService',
    'dashboardApiService',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'atComparatorService'
];

function AggregateProductivityChartCtrl(
    $scope,
    $window,
    chartService,
    dashboardApiService,
    templateServiceFunctions,
    browserServiceFunctions,
    atComparatorService
) {
    $scope.$watch('config', function () {
        $scope.isProductivityChartReady = false;
        $scope.aggregateProductivityChartStyle = null;
        $scope.isMobile = browserServiceFunctions.isMobileAgent();

        if (!$scope.config) {
            return;
        }

        $scope.barToggleClass = {
            1: '',
            2: '',
            '-1': '',
            '-2': '',
            '-3': '',
            0: '',
            9: ''
        };

        $scope.toggleSeriesVisibility = function (productivityStatus) {
            var chart = angular.element('#aggregateProductivityChart').data('kendoChart');

            var productivityName = ProductivityStatus.getName(productivityStatus, false, false);

            var series = lodash.find(chart.options.series, ['name', productivityName]);

            if (series === undefined) {
                productivityName = ProductivityStatus.getName(productivityStatus, true, true);

                series = lodash.find(chart.options.series, ['name', productivityName]);
            }

            var state = series.visible;

            $scope.barToggleClass[productivityStatus] = state ? 'at-disabled-bar-toggle' : '';

            series.visible = !state;

            chart.options.transitions = false;
            chart.redraw();
            chart.options.transitions = true;
        };

        var chartData;

        function prodCompare(a, b) {
            var order = null;
            var fieldName = null;
            return atComparatorService.productivityComparator(a, b, false, fieldName, order);
        }

        function createSeries(result, bars) {
            $scope.productivityList = [];

            result.data.sort(function (a, b) {
                if (a.productivity === 9) return 1;
                if (b.productivity === 9) return -1;
                if (a.productivity === 0) return -1;
                if (b.productivity === 0) return 1;
                return 0;
            });

            return result.data.map(function (item) {
                $scope.productivityList.push(item.productivity);
                $scope.productivityList.sort(prodCompare);
                return {
                    name: ProductivityStatus.getName(
                        item.productivity,
                        $scope.config.showDetails,
                        $scope.config.showDetails
                    ),
                    data: item.totals,
                    color: ProductivityStatus.getColor(item.productivity),
                    stack: 'time',
                    border: {
                        width: 0
                    },
                    bars: bars,
                    categories: result.dates,
                    passiveTimes: item.combinedTimes,
                    tooltip: {
                        visible: true,
                        padding: 0.01,
                        border: {
                            width: 0
                        },
                        template: kendo.template(function (section) {
                            var categoryIndex = section.series.categories.indexOf(section.category);

                            var totalPercent = (
                                (section.series.bars[categoryIndex] > 0
                                    ? section.series.data[categoryIndex] / section.series.bars[categoryIndex]
                                    : 0) * 100
                            ).toFixed(1);

                            return templateServiceFunctions.chartTooltipTemplate({
                                seriesName: section.series.name,
                                activeTime: section.value,
                                passiveTime: section.series.passiveTimes
                                    ? section.series.passiveTimes[categoryIndex]
                                    : -1,
                                totalPercent,
                                color: section.series.color
                            });
                        })
                    }
                };
            });
        }

        function createChartOptions(result, step, barMax, bars) {
            return {
                series: createSeries(result, bars),
                categories: result.dates,
                labelTooltipTemplate: kendo.template(templateServiceFunctions.labelTooltipTemplate),
                data: result.data,
                seriesClick: $scope.config.seriesClick,
                legendVisible: false,
                rotation: -45,
                majorUnit: templateServiceFunctions.calculateTimeMajorUnit(barMax),
                step: $scope.chartType === 'Area' ? (step > 2 || $scope.isMobile ? step : 2) : undefined,
                transitions: $scope.config?.transitions === undefined ? true : $scope.config?.transitions
            };
        }

        $scope.aggregateProductivityChartConfig = {
            getData: function () {
                if (chartData === undefined) {
                    chartData = dashboardApiService.getAggregate($scope.config.queryParameters);
                }

                return chartData;
            },
            checkData: function (data) {
                return data && data.dates && data.dates.length > 0;
            },
            buildChart: function (result) {
                var containsDateWithDashes = result.dates.some(function (date) {
                    return date.includes('-');
                });

                //Reporting service returns dates with dashes which can be formatted. Already formatted dates should not be formatted
                if (containsDateWithDashes) {
                    result.dates = lodash.map(result.dates, function (dateString) {
                        var { dateFormat } = getAccountSettings();
                        return dateString
                            .split('\n')
                            .map(function (date) {
                                return moment(date).format(dateFormat.toUpperCase());
                            })
                            .join('\n');
                    });
                }

                var bars = [];

                var filterProductivities = [
                    'ProductiveActive',
                    'ProductivePassive',
                    'UnproductiveActive',
                    'UnproductivePassive',
                    'UndefinedActive',
                    'UndefinedPassive'
                ];

                var showOfflineMeetingData = authorizationService.hasFeature(FeatureFlag.ShowOfflineMeetingData);
                var prodReportAggChartOfflineMeetings = authorizationService.hasFeature(
                    BundleFlag.ProdReportAggChartOfflineMeetings
                );

                if (showOfflineMeetingData && prodReportAggChartOfflineMeetings) {
                    filterProductivities.push('Offline');
                }

                // Filter out data that is not needed
                result.data = lodash
                    .filter(result.data, function (filterItem) {
                        return filterProductivities.includes(filterItem.productivity);
                    })
                    .map(function (item) {
                        item.productivity = ProductivityStatus.getStatus(item.productivity);

                        return item;
                    });

                function findDataItem(data, productivity) {
                    return {
                        item: lodash.find(data, function (item) {
                            return item.productivity === productivity;
                        }),
                        index: lodash.findIndex(data, function (item) {
                            return item.productivity === productivity;
                        })
                    };
                }

                function combineData(data, productivityA, productivityB) {
                    var a = findDataItem(data, productivityA);
                    var b = findDataItem(data, productivityB);

                    if (!b.item) {
                        return data;
                    }

                    var combinedTimes = [];
                    a.item.totals = lodash.map(a.item.totals, function (total, i) {
                        combinedTimes.push(b.item.totals[i]);
                        return total + b.item.totals[i];
                    });
                    a.item.combinedTimes = combinedTimes;

                    data[a.index] = a.item;
                    data.splice(b.index, 1);

                    return data;
                }

                // Combine active and passive if in summary view
                if (!$scope.config.showDetails) {
                    result.data = combineData(
                        result.data,
                        ProductivityStatus.Productive,
                        ProductivityStatus.ProductivePassive
                    );
                    result.data = combineData(
                        result.data,
                        ProductivityStatus.Unproductive,
                        ProductivityStatus.UnproductivePassive
                    );
                    result.data = combineData(
                        result.data,
                        ProductivityStatus.Undefined,
                        ProductivityStatus.UndefinedPassive
                    );
                }

                // Sort results
                result.data.sort(function (a, b) {
                    atComparatorService.productivityComparator(a, b, null, 'productivity');
                });

                result.data.reverse();

                if (result.data.length > 0) {
                    lodash.forEach(result.data[0].totals, function (elem, idx) {
                        var total = elem;
                        for (var i = 1; i < result.data.length; i++) {
                            total += result.data[i].totals[idx];
                        }
                        bars.push(total);
                    });
                }

                var barMax = Math.max.apply(null, bars);

                $scope.isProductivityChartReady = true;

                $scope.chartType = 'Bar';
                return chartService.buildColumnChart(createChartOptions(result, null, barMax, bars));
            },
            preventResizeProcessing: false
        };
    });

    var width = $window.innerWidth;
    $scope.$on('sidebarToggled', function () {
        if (width === $window.innerWidth) {
            return;
        }
        width = $window.innerWidth;
        $scope.aggregateProductivityChartConfig = angular.copy($scope.aggregateProductivityChartConfig);
    });
}
