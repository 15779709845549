import moment from 'moment';
import { DateFormat, TimeFormat } from './enums/dateTimeFormats';

/**
 * Returns the current date appended with the provided hour formatted according to the API date/time format.
 */
export const getDateTimeFormatFromHour = (hours: number): string => {
  const minute = hours === 24 ? 59 : 0;
  const hour = hours === 24 ? 23 : hours;
  const dateTimeFormat = `${DateFormat.ApiDateFormat} ${TimeFormat.TwentyFourHour}`;
  return moment().hour(hour).minute(minute).second(0).format(dateTimeFormat);
};

/**
 * Returns the current date as a Moment object appended with the provided hour.
 */
export const getMomentDateTimeFromHour = (hours: number): moment.Moment => {
  const minute = hours === 24 ? 59 : 0;
  const hour = hours === 24 ? 23 : hours;
  return moment().hour(hour).minute(minute).second(0).millisecond(0);
};
