import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import {
  blue9,
  gray0,
  mediumFontWeight,
  primaryAqua,
  primaryColor,
  smallFontSize
} from '../../../../constants';

export const FeatureBadgeWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPrimary'
})<{ isPrimary: boolean }>(({ isPrimary }) => ({
  backgroundColor: isPrimary ? primaryAqua : blue9,
  borderRadius: '5px',
  padding: '1px 8px',
  display: 'inline-block',
  marginLeft: '8px'
}));

export const FeatureBadgeTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isPrimary'
})<{
  isPrimary: boolean;
}>(({ isPrimary }) => ({
  color: isPrimary ? primaryColor : gray0,
  fontSize: smallFontSize,
  fontWeight: mediumFontWeight
}));
