import { Role, RoleAccessKeys } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { IRoute } from '../../../../models/IRoute';
import { blockingTemplate as angularJsTemplate } from '../templates';

export const appBlocking: IRoute = {
  name: 'app.blocking',
  stateDefinition: {
    url: '/blocking',
    template: angularJsTemplate,
    controller: 'BlockingCtrl',
    data: { pageTitle: 'Blocking' }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.Settings,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.SupportIntermediate],
    bundleFlags: [BundleFlag.WebsiteBlocking]
  }
};

export default appBlocking;
