import { BehaviorSubject } from 'rxjs';
import { profileStore } from '../../_reactivtrak/src/common/services/Profile/useProfileState';

const constructor = () => {
    // Declare constants
    // Used to centralize strings
    const _filterModes = {
        users: 'users',
        computers: 'computers'
    };
    const _reportModes = {
        users: 'Users',
        groups: 'Groups'
    };
    const _timeModes = {
        total: 'total',
        active: 'active'
    };

    let _localStorageService;

    const initialize = (localStorageService) => {
        if (!_localStorageService) {
            _localStorageService = localStorageService;
        }
    };

    // Create observable objects
    let _subjects = {
        reportMode: { defaultValue: null }, // Default to null so we can determine default based on group count
        isReport: { defaultValue: false },
        timeFormat: { defaultValue: 'hh:mm:ss A' },
        timeMode: { defaultValue: _timeModes.total },
        containerHeight: { defaultValue: 300 },
        filterMode: { defaultValue: _filterModes.users },
        sort: { defaultValue: 'highestProductivity' },
        filter: { defaultValue: 'activeToday' },
        refreshData: { defaultValue: false },
        selectedCardId: { defaultValue: null },
        apiConfig: { defaultValue: null },
        pollingPaused: { defaultValue: false },
        hideEmptyGroups: { defaultValue: true },
        userFilter: { defaultValue: null },
        isPrivateMode: { defaultValue: false }
    };

    let _isViewer = false;

    // Create observables with defaults
    const _createBehaviorSubject = (obj) => {
        if (obj) {
            obj.subject = new BehaviorSubject(obj.defaultValue);
        }
    };

    _.forEach(_subjects, (subject) => _createBehaviorSubject(subject));

    // Clean up observables on logout
    const _cleanUpSubject = (obj) => {
        if (!obj) {
            return;
        }

        if (obj.subject && typeof obj.subject.next === 'function') {
            obj.subject.next();
            obj.subject.complete();
        }

        obj.subject = new BehaviorSubject(obj.defaultValue);
    };

    const resetSubjectDefaults = () => {
        _.forEach(_subjects, (subject) => _cleanUpSubject(subject));
    };

    return {
        initialize,
        resetSubjectDefaults,
        get isReport() {
            return _subjects.isReport.subject;
        },
        set isReport(value) {
            _subjects.isReport.subject.next(value);
        },
        get reportMode() {
            return _subjects.reportMode.subject;
        },
        set reportMode(value) {
            _subjects.reportMode.subject.next(value);

            const { username } = profileStore.getState().profile;
            if (_localStorageService && value) {
                _localStorageService.set('teamPulseReportMode' + username, value);
            }
        },
        get timeFormat() {
            return _subjects.timeFormat.subject;
        },
        set timeFormat(value) {
            _subjects.timeFormat.subject.next(value.replace('tt', 'A'));
        },
        get timeMode() {
            return _subjects.timeMode.subject;
        },
        set timeMode(value) {
            _subjects.timeMode.subject.next(value);

            const { username } = profileStore.getState().profile;
            if (_localStorageService && value) {
                _localStorageService.set('teamPulseTimeMode' + username, value);
            }
        },
        get containerHeight() {
            return _subjects.containerHeight.subject;
        },
        set containerHeight(value) {
            _subjects.containerHeight.subject.next(Math.max(value, 300));
        },
        get filterMode() {
            return _subjects.filterMode.subject;
        },
        set filterMode(value) {
            _subjects.filterMode.subject.next(value);
        },
        get sort() {
            return _subjects.sort.subject;
        },
        set sort(value) {
            _subjects.sort.subject.next(value);

            if (_localStorageService && value) {
                const { username } = profileStore.getState().profile;
                _localStorageService.set('teamPulseSort-' + _subjects.reportMode.subject.value + '-' + username, value);
            }
        },
        get filter() {
            return _subjects.filter.subject;
        },
        set filter(value) {
            _subjects.filter.subject.next(value);

            if (_localStorageService && value) {
                const { username } = profileStore.getState().profile;
                _localStorageService.set(
                    'teamPulseFilter-' + _subjects.reportMode.subject.value + '-' + username,
                    value
                );
            }
        },
        get refreshData() {
            return _subjects.refreshData.subject;
        },
        set refreshData(value) {
            _subjects.refreshData.subject.next(value);
        },
        get filterModes() {
            return _filterModes;
        },
        get timeModes() {
            return _timeModes;
        },
        get reportModes() {
            return _reportModes;
        },
        get selectedCardIds() {
            return _subjects.selectedCardId.subject;
        },
        set selectedCardIds(value) {
            _subjects.selectedCardId.subject.next(value);
        },
        get apiConfig() {
            return _subjects.apiConfig.subject;
        },
        set apiConfig(value) {
            _subjects.apiConfig.subject.next(value);
        },
        get pollingPaused() {
            return _subjects.pollingPaused.subject;
        },
        set pollingPaused(value) {
            _subjects.pollingPaused.subject.next(value);
        },
        get hideEmptyGroups() {
            return _subjects.hideEmptyGroups.subject;
        },
        set hideEmptyGroups(value) {
            _subjects.hideEmptyGroups.subject.next(value);
        },
        get userFilter() {
            return _subjects.userFilter.subject;
        },
        set userFilter(value) {
            _subjects.userFilter.subject.next(value);
        },
        get isViewer() {
            return _isViewer;
        },
        set isViewer(value) {
            _isViewer = value;
        }
    };
};

export { constructor };
