import React, { useCallback, useEffect, useState } from 'react';
import { IComputerDetail, IComputerDetailDto } from '../models';
import { UsersProvider } from '../services';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import { formatComputerComplexName, mapToComputerDetail } from '../utils';
import { fetchData } from '../../common/helpers';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DownloadProvider } from '../../common/services';
import { useUIRouterParams } from '../../common/hooks/useUIRouterParams';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import authHelper from '../../common/helpers/authorization';
import { RoleAccessKeys } from '../../common/enums';
import Link from '../../common/components/Link';
import { getOSIcon } from '../../common/components/OsIcon/OsIcon';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  GroupContainer,
  GroupHeaderTitleContainer,
  OSVersionContainer,
  ParamContainer,
  ViewHeaderNameContainer
} from '../styles';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';

const formatStorage = (data) => {
  if (data > 0 && data <= 1) {
    return '0.001';
  }
  const gb = data / 1000;
  const precision = gb > 1 ? 2 : 3;
  return gb.toFixed(precision);
};

export function ComputerView() {
  const history = useUIRouterHistory();

  const { screenshotsAllowed = false } = getPrivacySettings();
  const hasAlarmsScreenshotsReportsRoleAccess = authHelper.hasRoleAccess(
    RoleAccessKeys.AlarmsScreenshotsReports
  );

  const [computerDetail, setComputerDetail] = useState<IComputerDetail>({
    videoStorage: 0,
    screenShotStorage: 0,
    logCount: 0,
    screenShotCount: 0,
    videoCount: 0,
    windowsDomain: '',
    computerName: '',
    alias: '',
    agentVersion: ''
  });

  const { id } = useUIRouterParams<any>();

  const {
    videoStorage,
    screenShotStorage,
    logCount,
    screenShotCount,
    videoCount,
    windowsDomain,
    computerName,
    alias,
    agentVersion,
    firstLog,
    lastLog,
    os
  } = computerDetail;

  useEffect(() => {
    const fetchComputerDetail = async () => {
      try {
        // TODO: transfer to admin endpoints later, leave on FEAPI for now
        const response = await fetchData<IComputerDetailDto>({
          path: `/api/settings/storage/computerdetail/${id}`
        });
        const computerDetailResponse = mapToComputerDetail(response);
        setComputerDetail(computerDetailResponse);
      } catch (error) {
        //what should happen here?
      }
    };

    fetchComputerDetail();
  }, [id]);

  const handleClickReturn = useCallback(() => {
    history.push('app.settings.computeragents');
  }, [history]);

  return (
    <Box>
      <ViewHeaderNameContainer>
        <Button onClick={handleClickReturn}>
          <KeyboardBackspaceIcon />
        </Button>
        {formatComputerComplexName(windowsDomain, computerName, alias)}
      </ViewHeaderNameContainer>
      <GroupContainer elevation={3}>
        <GroupHeaderTitleContainer>Agent Info</GroupHeaderTitleContainer>
        <ParamContainer>Primary Domain: {windowsDomain}</ParamContainer>
        <ParamContainer>Computer: {computerName}</ParamContainer>
        {alias ? <ParamContainer>Alias: {alias}</ParamContainer> : null}
        <OSVersionContainer>
          {getOSIcon(os)} <span>{agentVersion}</span>
        </OSVersionContainer>
      </GroupContainer>
      <GroupContainer elevation={3}>
        <GroupHeaderTitleContainer>Activity Details</GroupHeaderTitleContainer>
        <ParamContainer>
          First Log Record: {formatDateTime(firstLog)}
        </ParamContainer>
        <ParamContainer>
          Last Log Record: {formatDateTime(lastLog)}
        </ParamContainer>
        <ParamContainer>
          Total Storage:{' '}
          {formatStorage(
            (logCount * 120) / 1000 / 1000 + videoStorage + screenShotStorage
          )}{' '}
          GB
        </ParamContainer>
        {/* lcount, sstorage , vstorage */}
        <ParamContainer># of Log Records: {logCount}</ParamContainer>
        {screenshotsAllowed && hasAlarmsScreenshotsReportsRoleAccess && (
          <ParamContainer># of Screen Shots: {screenShotCount}</ParamContainer>
        )}
        {videoCount > 0 && (
          <ParamContainer># of Videos: {videoCount}</ParamContainer>
        )}
        <Link
          to={'app.reports.activitylog'}
          params={{
            computer: alias || computerName,
            dateFilter: 'today',
            userFilter: 'allusers'
          }}
        >
          View Activity Log
        </Link>
      </GroupContainer>
    </Box>
  );
}

export const ComputerViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UsersProvider>
        <ComputerView />
      </UsersProvider>
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
