import monitor from '_app/serviceFunctions/agentActivationFunctions';
import { getAccountSettings } from '../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').controller('AgentActivation', AgentActivation);

AgentActivation.started = false;

AgentActivation.$inject = ['$scope', 'envConfig'];

function AgentActivation($scope, envConfig) {
    //show sidebar/topbar if hidden
    $scope.showTopBar();


    //Start monitoring...
    //TODO: review this polling for post_login just to determine if an agent has reported
    const { usedLicenses } = getAccountSettings();
    if (usedLicenses === 0 && !AgentActivation.started) {
        monitor(`${envConfig.apiUrl()}/api/v2/post_login`);
        AgentActivation.started = true;
    }
}
