import React, { useCallback, useEffect, useRef, useState } from 'react';
import { resetScheduleAdherenceColumns } from '../stores/scheduleAdherenceColumns.store';
import { IGridColumnFilter } from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { GridColumnsFilter } from '../../../common/components/ReportFilters/components/GridColumnsFilter';
import { handleAllCheckbox } from '../../../common/components/ReportFilters/utils/gridColumnsFilter.utils';
import {
  mapSAColumnsToColumnState,
  mapSAColumnFiltersToColumnLabels
} from '../utils/scheduleAdherence.utils';
import { getScheduledColumnLabels } from '../utils/scheduleAdherenceColumns.utils';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';

type ScheduleAdherenceColumnSelectorProps = {
  onSubmit: (view: ReportViewTypes) => void;
};

export const ScheduleAdherenceColumnSelector = (
  props: ScheduleAdherenceColumnSelectorProps
) => {
  const { onSubmit } = props;

  const [currentColumnSettings, setCurrentColumnSettings] = useState<
    IGridColumnFilter[]
  >([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const columnLabels = useRef(getScheduledColumnLabels()).current;

  const resetColumns = useCallback(() => {
    const { view: currentView } = getReportView();
    const mappedColumnFilters = mapSAColumnFiltersToColumnLabels(
      currentView,
      columnLabels
    );
    setCurrentColumnSettings(mappedColumnFilters);
  }, [columnLabels]);

  useEffect(() => {
    resetColumns();
  }, [resetColumns]);

  const handleColumnsSubmit = useCallback((): void => {
    const { view: currentView } = getReportView();
    mapSAColumnsToColumnState(currentColumnSettings, currentView);
    onSubmit(currentView);

    setIsOpen(false);
  }, [currentColumnSettings, onSubmit]);

  const handleRestoreColumns = useCallback(() => {
    resetScheduleAdherenceColumns();
    const { view: currentView } = getReportView();
    const mappedColumnFilters = mapSAColumnFiltersToColumnLabels(
      currentView,
      columnLabels
    );
    setCurrentColumnSettings(mappedColumnFilters);
    onSubmit(currentView);

    setIsOpen(false);
  }, [columnLabels, onSubmit]);

  const handleClose = useCallback(() => {
    resetColumns();
    setIsOpen(false);
  }, [resetColumns]);

  const selectAll = () => {
    setCurrentColumnSettings((prevState) => {
      const clonedState = [...prevState];
      return clonedState.map((column) => ({ ...column, hide: false }));
    });
  };

  const unSelectAll = () => {
    setCurrentColumnSettings((prevState) => {
      const clonedState = [...prevState];
      return clonedState.map((column) => ({ ...column, hide: true }));
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      unSelectAll();
    } else {
      selectAll();
    }
    setIsAllSelected((prevState) => !prevState);
  };

  const handleSelect = (colId: string) => {
    if (colId === 'all') {
      handleSelectAll();
    } else {
      setCurrentColumnSettings((prevState) => {
        const clonedState = [...prevState];
        const mapped = clonedState.map((column) =>
          column.colId === colId ? { ...column, hide: !column.hide } : column
        );

        return handleAllCheckbox(mapped);
      });
    }
  };

  return (
    <GridColumnsFilter
      columns={currentColumnSettings}
      isOpen={isOpen}
      onSubmit={handleColumnsSubmit}
      onSelect={handleSelect}
      onRestoreDefaults={handleRestoreColumns}
      onClose={handleClose}
      setIsOpen={setIsOpen}
    />
  );
};
