import React from 'react';
import {
  StarHollow,
  StarFilled
} from './SideBar.styles';

  export const FavoritesStar = (props) => {
    const { favorited } = props;

    return favorited ? (
      <StarFilled
        className="pull-right icon-star"
        fontSize="small"
        onClick={(e) => props.onClick(e)}
      />
    ) : (
      <StarHollow
        className="pull-right icon-star"
        fontSize="small"
        onClick={(e) => props.onClick(e)}
      />
    );
  };