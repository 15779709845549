import { AcademyRedirect } from '../../../../components/Navigation/SideBar/AcademyRedirect';
import { Role } from '../../../../enums';
import { BundleFlag } from '../../../../enums/BundleFlag';
import { IRoute } from '../../../../models/IRoute';

export const appAcademy: IRoute = {
  name: 'app.help.academy',
  stateDefinition: {
    url: '/help/academy?direct&original_uri',
    component: AcademyRedirect,
    data: {
      // instructs UIRouter not to update the window url
      // this is a "pivot" state - don't need it in history
      location: false
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [],
    read: [Role.Admin, Role.Configurator, Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.PaidFunctionality]
  }
};

export default appAcademy;

