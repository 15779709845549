import { PlanPeriod } from '../enums/PlanPeriod';
import { PlanType } from '../enums/PlanType';
import { IAccountPlan } from '../models';

export const ACCOUNT_PLAN_CACHE_TIMEOUT = 600000; // 10 minutes
export const ACCOUNT_PLAN_STORE_DEFAULT: IAccountPlan = {
  planCode: '',
  planType: PlanType.Free,
  planPeriod: PlanPeriod.None,
  planAge: -1,
  planRenewDate: '',
  planRemainingDays: 0,
  isPlanDowngraded: false
};
