import { ProductivityColors } from '../../../../_reactivtrak/src/common/constants/productivityColors';
import ProductivityStatus from '../../../../_app/constants/productivityStatus';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import authorizationService from '../../../../_reactivtrak/src/common/helpers/authorization';
import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { setReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import lodash from 'lodash';

import './productivityLegacy.component.js';

angular.module('app').controller('ProductivityCtrl', ProductivityCtrl);

ProductivityCtrl.$inject = [
    '$scope',
    '$timeout',
    '$state',
    'googleChartApiPromise',
    'localStorageService',
    'messagesService',
    'productivityApiService',
    'pageSizeService',
    'browserServiceFunctions',
    'atHelperFunctions'
];

function ProductivityCtrl(
    $scope,
    $timeout,
    $state,
    googleChartApiPromise,
    localStorageService,
    msg,
    productivityApiService,
    pageSizeService,
    browserServiceFunctions,
    atHelperFunctions
) {
    const accountSettings = getAccountSettings();

    $scope.hideTimelinePager = true;
    $scope.isLoading = true;
    $scope.chartTimeFormat = accountSettings.timeFormat.replace(':ss', '').replace('tt', 'a');
    $scope.tooltipTimeFormat = $scope.chartTimeFormat.replace('hh', 'h').replace(' ', '');
    $scope.isMobile = browserServiceFunctions.isMobileAgent();
    $scope.aggregatePeriod = $state.params.aggregatePeriod || 'Day';

    const savedView = localStorageService.get('productivity-view-' + accountSettings.username);

    $scope.view = !savedView ? 'summaryView' : savedView;
    $scope.isSingleDayReportLoading = true;

    $scope.handleViewChange = function (view) {
        $scope.view = view;
        localStorageService.set('productivity-view-' + accountSettings.username, $scope.view);
        $scope.bindAggregateProductivityChart();
        pageSizeService.dataSourceReload($scope.timelineDataSource);
    };

    const dateFormat = accountSettings.dateFormat.toUpperCase();

    $scope.colors = {
        1: ProductivityColors[1],
        '-1': ProductivityColors['-1'],
        0: ProductivityColors[0],
        '-3': ProductivityColors['-3'],
        2: ProductivityColors[2],
        '-2': ProductivityColors['-2'],
        9: ProductivityColors[9]
    };
    $scope.reloadTrigger = 0;

    function hoursRange(startHour, endHour) {
        const dates = [];
        for (let i = startHour; i <= endHour; i++) {
            dates.push(new Date(2000, 10, 10, i));
        }
        return dates;
    }

    function getTimepickerOptions(startHour, endHour) {
        return {
            format: accountSettings.timeFormat.replace(':ss', ''),
            parseFormats: ['HH:mm', 'hh:mm tt'],
            interval: 60,
            dates: hoursRange(startHour, endHour)
        };
    }

    function getDateFromRange(range) {
        return range.split('\n')[0];
    }

    $scope.startTimePickerOptions = getTimepickerOptions(0, 23);
    $scope.endTimePickerOptions = getTimepickerOptions(1, 24);

    const showLocationData = authorizationService.hasFeature(FeatureFlag.ShowLocationData);
    const locationData = authorizationService.hasFeature(BundleFlag.LocationData);

    $scope.timelineDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                $scope.isSingleDayReportLoading = true;
                // Calculate start and end hours
                const startHour = parseInt(moment($scope.start, 'h:mm A').format('H'));
                let endHour = parseInt(moment($scope.end, 'h:mm A').format('H'));
                endHour = endHour === 0 ? 24 : endHour;

                //Verify start is less than end hour
                if (startHour >= endHour) {
                    $scope.$emit('showNotification', {
                        message:
                            'Single-day report start time must be before end time. Updated end time to one hour after start time.',
                        color: 'danger',
                        timeout: 5000
                    });
                    $scope.end = moment(startHour + 1, 'H').format('h:mm A');
                    options.error();
                    return;
                }

                // Create date range parameters
                const to = moment($scope.reportDate.format('YYYY-MM-DD')).add(endHour, 'h').format('YYYY-MM-DD HH:mm');

                const from = moment($scope.reportDate.format('YYYY-MM-DD'))
                    .add(startHour, 'h')
                    .format('YYYY-MM-DD HH:mm');

                const showOfflineMeetingData = authorizationService.hasFeature(FeatureFlag.ShowOfflineMeetingData);
                const prodReportSingleDayChartOfflineMeetings = authorizationService.hasFeature(
                    BundleFlag.ProdReportSingleDayChartOfflineMeetings
                );

                const { users } = $scope.reportFilters;
                const user = users[0];

                // Create parameter string
                const parameters =
                    'interval=' +
                    $scope.interval +
                    '&userId=' +
                    user.userId +
                    '&userType=' +
                    user.userType +
                    '&mode=' +
                    user.filterMode +
                    '&ganttSort=' +
                    $scope.sort +
                    '&strict=' +
                    $scope.isStrict +
                    '&from=' +
                    from +
                    '&to=' +
                    to +
                    '&showOfflineMeetings=' +
                    (showOfflineMeetingData && prodReportSingleDayChartOfflineMeetings);

                $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_INITIAL;
                $scope.timelineType = user.filterMode;

                productivityApiService
                    .getProductivity(parameters, {
                        params: options.data
                    })
                    .success(function (result) {
                        result.data = lodash.map(result.data, function (item) {
                            switch (item.productivity) {
                                case 1:
                                    item.productivity = ProductivityStatus.Productive;
                                    break;
                                case 2:
                                    item.productivity = ProductivityStatus.Unproductive;
                                    break;
                                case 3:
                                    item.productivity = ProductivityStatus.Undefined;
                                    break;
                                case 4:
                                    item.productivity =
                                        $scope.view === 'detailedView'
                                            ? ProductivityStatus.UndefinedPassive
                                            : ProductivityStatus.Undefined;
                                    break;
                                case 5:
                                    item.productivity =
                                        $scope.view === 'detailedView'
                                            ? ProductivityStatus.ProductivePassive
                                            : ProductivityStatus.Productive;
                                    break;
                                case 6:
                                    item.productivity = item.productivity =
                                        $scope.view === 'detailedView'
                                            ? ProductivityStatus.UnproductivePassive
                                            : ProductivityStatus.Unproductive;
                                    break;
                                case 9:
                                    item.productivity = ProductivityStatus.OfflineMeetings;
                                    break;
                                default:
                                    item.productivity = undefined;
                            }

                            return item;
                        });
                        const { timelineFromHour: fromHour, timelineToHour: toHour } = $scope.reportFilters;

                        result.data.forEach(function (item) {
                            const locationObj = result.location?.find(function (location) {
                                return location.userName === item.user;
                            });

                            let location = locationObj ? locationObj.location : undefined;
                            const isTodaysDate =
                                atHelperFunctions.isToday(fromHour) && atHelperFunctions.isToday(toHour);

                            if (isTodaysDate || !showLocationData || !locationData) {
                                location = '';
                            }

                            item.user = location ? item.user + ' (' + location + ')' : item.user;
                        });
                        options.success(result);
                        $scope.isSingleDayReportLoading = false;
                        $scope.timelineData = result.data;
                        $scope.reloadTrigger++;
                    })
                    .error(function (result) {
                        options.error(result);
                        $scope.isSingleDayReportLoading = false;
                        $scope.timelineData = [];
                        $scope.reloadTrigger++;
                        $scope.$emit('showNotification', {
                            message: 'Error loading single-day report data.',
                            color: 'danger'
                        });
                    });
            }
        },
        pageSize: pageSizeService.loadPageSize('productivity-master', 10),
        serverPaging: true,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    $scope.pagerOptions = {
        dataSource: $scope.timelineDataSource,
        refresh: true,
        pageSizes: ['5', '10', '20'],
        buttonCount: 3,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'items'),
            display: msg.get('itemsDisplay', 'items'),
            empty: msg.get('noItemsToDisplay', 'items')
        }
    };

    $scope.comboboxOption = function (data) {
        return {
            dataTextField: data.dataTextField || 'text',
            dataValueField: data.dataValueField || 'value',
            dataSource: data.dataSource || [],
            select: function () {
                if (this && this.input && typeof this.input.blur === 'function') {
                    this.input.blur();
                }
            },
            dataBound: function (e) {
                e.sender.input
                    .attr('readonly', true)
                    .on('keydown', function (e) {
                        if (e.keyCode === 8) {
                            e.preventDefault();
                        }
                    })
                    .on('click', function () {
                        e.sender.toggle();
                    });

                if (typeof data.dataBound === 'function') {
                    data.dataBound(e);
                }
            }
        };
    };

    $scope.intervalOptions = $scope.comboboxOption({
        dataSource: [
            {
                text: msg.get('fiveMinutes'),
                value: '5'
            },
            {
                text: msg.get('fifteenMinutes'),
                value: '15'
            },
            {
                text: msg.get('thirtyMinutes'),
                value: '30'
            },
            {
                text: msg.get('oneHour'),
                value: '60'
            },
            {
                text: msg.get('twoHours'),
                value: '120'
            }
        ]
    });

    $scope.sortingOptions = $scope.comboboxOption({
        dataSource: [
            {
                text: 'Total ',
                value: 'TotalActivity'
            },
            {
                text: msg.get('productive'),
                value: 'Productive'
            },
            {
                text: msg.get('unproductive'),
                value: 'Unproductive'
            }
        ]
    });

    const bindFullReport = function (filters) {
        const { fromDate } = filters;
        $scope.chartType = null;
        if ($state.params.date) {
            const date = decodeURIComponent($state.params.date);
            $scope.reportDate = moment(getDateFromRange(date), dateFormat);
        } else {
            const from = moment(fromDate);
            $scope.reportDate = from;
        }
        $scope.reportDateFormatted = $scope.reportDate.format(dateFormat);

        googleChartApiPromise.then(function () {
            $scope.bindAggregateProductivityChart();
            pageSizeService.dataSourceReload($scope.timelineDataSource);
        });
    };

    $scope.bindReport = function (filters, refreshTimestamp) {
        const {
            dates: { getDates, range },
            users,
            timelineFromHour,
            timelineToHour,
            timelineInterval,
            timelineSort,
            timelineStrict
        } = filters;
        const { toDate, fromDate } = getDates();

        const mainReportFilters = {
            toDate,
            fromDate,
            range,
            users,
            refreshTimestamp
        };

        $scope.reportFilters = filters;
        $scope.start = moment().startOf('day').hour(timelineFromHour).format($scope.chartTimeFormat);
        $scope.end = moment().startOf('day').hour(timelineToHour).format($scope.chartTimeFormat);
        $scope.interval = timelineInterval;
        $scope.sort = timelineSort;
        $scope.isStrict = timelineStrict;

        if (JSON.stringify(mainReportFilters) !== JSON.stringify($scope.mainReportFilters)) {
            bindFullReport(mainReportFilters);
            $scope.mainReportFilters = mainReportFilters;
        } else {
            $scope.reloadTimelineDataSource();
        }
    };

    $scope.handleStartHourChange = () => {
        const timelineFromHour = parseInt(moment($scope.start, 'hh:mm A').format('HH'));
        setReportFilters({ timelineFromHour });
    };

    $scope.handleEndHourChange = () => {
        const timelineToHour = parseInt(moment($scope.end, 'hh:mm A').format('HH'));
        setReportFilters({ timelineToHour });
    };

    $scope.handleIntervalChange = () => {
        const timelineInterval = parseInt($scope.interval);
        setReportFilters({ timelineInterval });
    };

    $scope.handleSortChange = () => {
        const timelineSort = $scope.sort;
        setReportFilters({ timelineSort });
    };

    $scope.handleStrictChange = () => {
        const timelineStrict = $scope.isStrict;
        setReportFilters({ timelineStrict });
    };

    $scope.reloadTimelineDataSource = () => {
        $timeout(function () {
            pageSizeService.dataSourceReload($scope.timelineDataSource);
        });
    };

    $scope.$watch('aggregatePeriod', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            $scope.bindAggregateProductivityChart();
        }
    });

    $scope.moveDate = function (days) {
        const scrollTop = angular.element(window).scrollTop();
        if (scrollTop) {
            $scope.scrollTop = scrollTop;
        }
        const result = $scope.reportDate.add(days, 'days');
        $scope.reportDate = moment.min(result, moment());
        $scope.reportDateFormatted = $scope.reportDate.format(dateFormat);
        pageSizeService.dataSourceReload($scope.timelineDataSource);
    };

    $scope.moveToDate = function (date) {
        $scope.reportDate = moment(getDateFromRange(date), dateFormat);

        $scope.reportDateFormatted = $scope.reportDate.format(dateFormat);
        pageSizeService.dataSourceReload($scope.timelineDataSource);
    };

    $scope.productivityChartSeriesClick = function (e) {
        $scope.moveToDate(e.category);
    };

    $scope.bindAggregateProductivityChart = function (showNotifications) {
        if ($scope.reportFilters) {
            const params = generateParameters($scope.reportFilters, { period: $scope.aggregatePeriod });

            $scope.productivityChartParams = {
                queryParameters: params,
                seriesClick: $scope.productivityChartSeriesClick,
                isMobile: $scope.isMobile,
                showNotifications: showNotifications,
                showDetails: $scope.view === 'detailedView'
            };
        }
    };

    $scope.$on('timelineChartReady', function () {
        if ($scope.scrollTop) {
            angular.element(window).scrollTop($scope.scrollTop);
            $scope.scrollTop = 0;
        }
    });

    $scope.$watch('productivityChartStatus', function (newVal) {
        $scope.isLoading = newVal === msg.get('loading');
    });
}
