import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { formatDate } from '../../common/utils/datetime/datetimeFormatters';
import { CopyToClipboardButton } from '../components/CopyToClipboardButton';
import { DeleteApiKeyButton } from '../components/DeleteApiKeyButton';
import { NewStatusIcon } from '../components';
import { IApiKeyDto } from '../models';
import { truncateStringWithAsterisks } from '../utils';
import { ApiKeyGridColumnParams } from '../models/ApiKeyGridColumnParams';
import EditDescriptionTextField from '../components/EditDescriptionTextField';

export const useApiKeyColumns = (props: ApiKeyGridColumnParams) => {
  const [columns, setColumns] = useState<any>();
  const { deleteApiKeyMember, isDisabled } = props;

  useEffect(() => {
    const cols = [
      {
        field: 'description',
        fieldName: 'description',
        headerName: 'API Key Name',
        type: 'string',
        isSortable: true,
        width: '35%',
        align: 'left',
        template: (apikey: IApiKeyDto) => {
          return (
            <Box>
              <EditDescriptionTextField {...apikey} />
            </Box>
          );
        }
      },
      {
        field: 'key',
        fieldName: 'key',
        headerName: 'API Key',
        type: 'string',
        isSortable: true,
        width: '25%',
        align: 'left',
        template: (apikey: IApiKeyDto) => (
          <>
            <Box>
              {truncateStringWithAsterisks(apikey.key)}
              <CopyToClipboardButton
                text={apikey.key}
                successMessage="API Key copied"
                errorMessage="This browser does not support copy to clipboard"
              ></CopyToClipboardButton>
            </Box>
          </>
        )
      },

      {
        field: 'dateCreated',
        fieldName: 'dateCreated',
        headerName: 'Creation Date',
        type: 'Date',
        isSortable: true,
        width: '20%',
        align: 'right',
        template: (apikey) => {
          const formattedDate = formatDate(apikey.dateCreated);
          return (
            <Box>
              <NewStatusIcon dateCreated={apikey?.dateCreated}></NewStatusIcon>
              {formattedDate}
            </Box>
          );
        }
      },
      {
        isSortable: false,
        width: '20%',
        align: 'center',
        template: (apikey: IApiKeyDto) => (
          <Box sx={{ minWidth: '50px', paddingLeft: '90%' }}>
            <DeleteApiKeyButton
              apikey={apikey}
              onDelete={deleteApiKeyMember}
              isDisabled={isDisabled}
            ></DeleteApiKeyButton>
          </Box>
        )
      }
    ];
    setColumns(cols);
  }, [deleteApiKeyMember, isDisabled]);

  return columns;
};
