import { IRoute } from '../../../../models/IRoute';

export const verify: IRoute = {
  name: 'verify',
  stateDefinition: {
    url: '/verify?vtoken&email',
    controller: 'VerifyCtrl',
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default verify;
