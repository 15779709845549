import { HRISIntegrationType, IntegrationDetails } from '../constants/IntegrationDetails';
import {
  IntegrationErrors,
  IntegrationMessages
} from '../constants/IntegrationMessages';
import {
  CallToActionType,
  DispositionType,
  IIntegration,
  IIntegrationConfigurationDto,
  IIntegrationDetailDto,
  IIntegrationError,
  IIntegrationPlanDto,
  IIntegrationStateDto,
  IIntegrationSummaryDto,
  ISolutionDto,
  IntegrationStateErrorCodes,
  IntegrationStateType
} from '../models/IIntegrationTypes';
import { MILLISECONDS_IN_1_DAY } from '../../common/constants';
import { BetaIntegrationCodes, IntegrationCodes } from '../constants/IntegrationCodes';
import { FeatureState } from '../../common/enums/FeatureState';
import { IAuthorizationService } from '../../common/models';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { formatDateTimeByTimezone } from '../../common/utils/datetime/datetimeFormatters';

export const determineCallToAction = (
  dispositionState: string,
  isPlanAccessible: boolean,
  isIntegrationEnabled: boolean,
  trayInstance?: string,
  integrationcode?: string,
  isBetaEnabled?: boolean
) => {
  if (trayInstance) return CallToActionType.none;


  const disposition = DispositionType[dispositionState];
  switch (disposition) {
    case DispositionType.ga:
      return isPlanAccessible && !isIntegrationEnabled
        ? CallToActionType.integrate
        : CallToActionType.none;
    case DispositionType.beta:
      if (integrationcode && isHRISIntegration(integrationcode)) {
        if (isBetaEnabled){
            return CallToActionType.integrate;
        } else {
            return CallToActionType.learnMore;
        }
      } else {
        return !isPlanAccessible
        ? CallToActionType.upgrade
        : CallToActionType.learnMore;
      }
    default:
      return CallToActionType.learnMore;
  }
};

export const determineBadge = (dispositionState: string) =>
  dispositionState === 'beta' ? FeatureState.Beta : null;

const isNonScheduledIntegration = (
  summary: Pick<IIntegrationSummaryDto, 'integrationcode'>
) => IntegrationDetails[summary.integrationcode]?.runsOnSchedule === false;

const has24HourIntegrationValidated = (
  summary: Pick<IIntegrationSummaryDto, 'integrationcode' | 'updatedtime'>
) =>
  isNonScheduledIntegration(summary) ? false : has24HourSyncCompleted(summary);

const waitingPeriodFlag = (
  summary: Pick<IIntegrationSummaryDto, 'createddate'>
) => {
  return (
    summary &&
    Date.now() - Date.parse(summary.createddate) < MILLISECONDS_IN_1_DAY
  );
};

export const determineStateMessage = (
  summary: Pick<
    IIntegrationSummaryDto,
    'errorcode' | 'updatedtime' | 'createddate' | 'integrationcode'
  > & {
    integrationState: IntegrationStateType;
  }
) => {
  const error =
    IntegrationErrors[summary.errorcode]?.errorMessage ||
    IntegrationErrors[IntegrationStateErrorCodes.Uncategorized];

  switch (summary.integrationState) {
    case IntegrationStateType.None:
    case IntegrationStateType.Deleted:
    case IntegrationStateType.Paused:
      return '';
    case IntegrationStateType.Idle:
      if (summary.errorcode === IntegrationStateErrorCodes.Uncategorized) {
        return IntegrationMessages.UncategorizedMessage;
      }
      return IntegrationMessages.IdleMessage;
    case IntegrationStateType.Waiting:
      if (waitingPeriodFlag(summary)) {
        return IntegrationMessages.ProcessingIntegration;
      }
      if (summary.updatedtime && !has24HourIntegrationValidated(summary)) {
        return `Last data received: ${formatDateTimeByTimezone(summary.updatedtime)}`;
      }
      return '';
    case IntegrationStateType.Error:
    case IntegrationStateType.NoFetchWarning:
      return error;
    default:
      return IntegrationMessages.ProcessingIntegration1Hr;
  }
};

const has24HourSyncCompleted = (
  summary: Pick<IIntegrationSummaryDto, 'updatedtime'>
) => {
  return (
    summary.updatedtime &&
    Date.now() - Date.parse(summary.updatedtime) > MILLISECONDS_IN_1_DAY
  );
};

export const getHRISIntegrationType = (
  integrationValue: string
): HRISIntegrationType => {
  const integrationValueTest = integrationValue.toLowerCase();

  if (
    Object.values(HRISIntegrationType).includes(
      integrationValueTest as HRISIntegrationType
    )
  ) {
    return integrationValueTest as HRISIntegrationType;
  }
};

export const isHRISIntegration = (integrationCode: string) => {
    return BetaIntegrationCodes.includes(integrationCode as IntegrationCodes)
}

export const mapIntegrations = (
  detailDtos: IIntegrationDetailDto[],
  planDtos: IIntegrationPlanDto[],
  stateDtos: IIntegrationStateDto[],
  configurationDtos: IIntegrationConfigurationDto[],
  solutionsDtos: ISolutionDto[],
  authorizationServiceHasFeature: IAuthorizationService["hasFeature"]

): IIntegration[] => {

  const filteredDetails = detailDtos?.filter(
    (x) =>
      x.integrationcode === IntegrationCodes.AzureAD ||
      x.integrationcode === IntegrationCodes.GoogleCalendar ||
      x.integrationcode === IntegrationCodes.OutlookCalendar ||
      isHRISIntegration(x.integrationcode)
  );

  const getIntegrationCardDetails = (detail: IIntegrationDetailDto, isHRISIntegrationType = false) => {
    const { integrationcode, state } = detail;
    const { planNames, isPlanAccessible } = mapPlans(integrationcode, planDtos);
    const {
      integrationConfigurations,
      isIntegrationEnabled
    } = mapConfigurations(integrationcode, configurationDtos);
    const { integrationState, stateType, error } = mapStates(
      integrationcode,
      stateDtos
    );
    const { traySolutionInstance, trayEnabled } = mapSolutions(
      integrationcode,
      solutionsDtos
    );
    const isBetaEnabled =
      isHRISIntegration(integrationcode) &&
      authorizationServiceHasFeature(BundleFlag.IntegrationHRISEnabled);
    const callToAction = determineCallToAction(
      state,
      isPlanAccessible,
      isIntegrationEnabled,
      traySolutionInstance,
      integrationcode,
      isBetaEnabled
    );
    const badge = determineBadge(state);
    // TODO: Revisit after HRIS MVP
    const integrationDetails = isHRISIntegrationType
    ? IntegrationDetails[HRISIntegrationType.Workday]
    : IntegrationDetails[integrationcode];

    return {
      ...integrationDetails,
      integrationcode: integrationcode as IntegrationCodes,
      planNames,
      isPlanAccessible,
      stateType,
      error,
      callToAction,
      badge,
      integrationConfigurations,
      hasConfigurations: integrationConfigurations.length > 0,
      isIntegrationEnabled,
      disposition: DispositionType[state],
      integrationState,
      traySolutionInstance,
      trayEnabled
    };
  }

  const integrationCards = filteredDetails.map((detail) => {
    if (isHRISIntegration(detail.integrationcode)) {
        return getIntegrationCardDetails(detail, true);
    }
    return getIntegrationCardDetails(detail);
  });

  return integrationCards;
};

const mapSolutions = (
  integrationcode: string,
  solutions: ISolutionDto[]
): { traySolutionInstance: string; trayEnabled: boolean } => {
  const integrationTraySolutions = solutions.filter(
    (solution) => solution.instance && solution.tags.includes(integrationcode)
  );

  const id =
    integrationTraySolutions.length === 0
      ? null
      : integrationTraySolutions[0].instance;

  const trayEnabled =
    integrationTraySolutions.length === 0
      ? null
      : integrationTraySolutions[0].enabled;
  return { traySolutionInstance: id, trayEnabled };
};

const mapConfigurations = (
  integrationcode: string,
  configurations: IIntegrationConfigurationDto[]
): {
  integrationConfigurations: IIntegrationConfigurationDto[];
  isIntegrationEnabled: boolean;
} => {
  const integrationConfigurations = configurations.filter(
    (config) => config.integrationcode === integrationcode
  );

  const enabled = integrationConfigurations.some(
    (config) => config.name === 'enabled' && config.value === 'true'
  );

  return { integrationConfigurations, isIntegrationEnabled: enabled };
};

const mapPlans = (
  integrationcode: string,
  plans: IIntegrationPlanDto[]
): { planNames: string[]; isPlanAccessible: boolean } => {
  const plan = plans.find((plan) => plan.integrationcode === integrationcode);

  if (!plan) {
    return { planNames: [], isPlanAccessible: false };
  }

  return {
    planNames: plan.plannames,
    isPlanAccessible: plan.isPlanAccessible
  };
};
const mapStates = (
  integrationcode: string,
  states: IIntegrationStateDto[]
): {
  integrationState: IIntegrationStateDto;
  stateType: IntegrationStateType;
  error: IIntegrationError;
} => {
  const state = states?.find(
    (state) => state.integrationcode === integrationcode
  );

  if (!state) {
    return { integrationState: null, stateType: null, error: null };
  }

  return {
    integrationState: state,
    stateType: mapStateType(state.state),
    error: IntegrationErrors[state.errorcode]
  };
};

const mapStateType = (state: string): IntegrationStateType => {
  switch (state) {
    case 'Deleted':
      return IntegrationStateType.Deleted;
    case 'Error':
      return IntegrationStateType.Error;
    case 'Idle':
      return IntegrationStateType.Idle;
    case 'Paused':
      return IntegrationStateType.Paused;
    case 'Running':
      return IntegrationStateType.Running;
    case 'Waiting':
      return IntegrationStateType.Waiting;
    case 'NoFetchWarning':
      return IntegrationStateType.NoFetchWarning;
    default:
      return IntegrationStateType.None;
  }
};
