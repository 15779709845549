import { useCallback, useEffect, useRef, useState } from 'react';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { IntegrationNotifications } from '../constants/IntegrationNotifications';
import {
  ConfigMergeModalProps,
  IMergeLinkDto,
  IMergeLinkedAccountsDto
} from '../models';
import { HRISIntegrationType } from '../constants/IntegrationDetails';

export const ConfigureMergeModal = ({
  setMergeInitiateConfig,
  setMergeIsReady,
  showIntegrateButton,
  baseModalProps
}: ConfigMergeModalProps) => {
  const [configMergeLink, setConfigMergeLink] = useState<IMergeLinkDto>();
  const {
    setIntegrationConfigurationNotification,
    mergeLink,
    initiateMergeDev,
    fetchMergeAccount,
    initiatedNotYetCreated,
    setIntegrationCardEnabled,
    setIsLoading
  } = baseModalProps.configState;
  const { setOpenConfigDialog } = baseModalProps;
  const prevOpenRef = useRef(null);
  const mergeValidationErrorRef = useRef(null);
  const initiatedIntegrationRef = useRef(initiatedNotYetCreated);

  const onMergeValidationError = useCallback((error) => {
    mergeValidationErrorRef.current = true;
    console.error(
      'Merge Error: an error occurred during validation: ' +
        error.map((err) => err.detail)
    );
  }, []);

  const onMergeSuccess = useCallback(() => {
    initiatedIntegrationRef.current = true;
  }, []);

  const onMergeExit = useCallback(async () => {
    setConfigMergeLink(null);
    setOpenConfigDialog(false);
    if (showIntegrateButton) initiateMergeDev();
    prevOpenRef.current = false;

    if (mergeValidationErrorRef.current) {
      mergeValidationErrorRef.current = false;
      setIntegrationConfigurationNotification({
        msg: IntegrationNotifications.GenericError,
        type: 'error'
      });
    } else {
      if (initiatedIntegrationRef.current) {
        const workdayAccount: IMergeLinkedAccountsDto = await fetchMergeAccount(
          HRISIntegrationType.Workday
        );
        // For MVP instanceid is set by integrationcode and this is done from parent.
        setIsLoading(true);
        if (workdayAccount) {
          initiatedIntegrationRef.current = false;
          setIntegrationCardEnabled(true);
        }

        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Initiated,
          type: 'success'
        });
      } else {
        setIntegrationConfigurationNotification({
          msg: IntegrationNotifications.Cancelled,
          type: 'success'
        });
      }
    }
  }, [
    setOpenConfigDialog,
    showIntegrateButton,
    initiateMergeDev,
    setIntegrationConfigurationNotification,
    fetchMergeAccount,
    setIsLoading,
    setIntegrationCardEnabled
  ]);

  const { open, isReady } = useMergeLink({
    linkToken: configMergeLink && configMergeLink.link_token,
    onSuccess: onMergeSuccess,
    onExit: onMergeExit,
    onValidationError: onMergeValidationError
  });

  useEffect(() => {
    if (open && !prevOpenRef.current) {
      setMergeInitiateConfig(() => open);
      prevOpenRef.current = true;
    }
  }, [open, setMergeInitiateConfig]);

  useEffect(() => {
    setMergeIsReady(isReady);
  }, [isReady, setMergeIsReady]);

  useEffect(() => {
    setConfigMergeLink(mergeLink);
  }, [mergeLink]);

  useEffect(() => {
    initiatedIntegrationRef.current = initiatedNotYetCreated;
  }, [initiatedNotYetCreated]);

  // Merge Link will be opened by the useMergeLink from parent from the function set by setMergeInitiateConfig
  return null;
};
