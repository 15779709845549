'use strict';

angular
    .module('app')
    .directive('timeline', function () {
        return {
            restrict: 'E',
            template: require('views/widgets/timeline.html'),
            controller: 'TimelineCtrl'
        };
    })
    .controller('TimelineCtrl', TimelineCtrl);

TimelineCtrl.$inject = [
    '$window',
    '$scope',
    '$rootScope',
    '$state',
    'messagesService',
    'customUibModal',
    'pageSizeService',
    'browserServiceFunctions',
    'authorizationService',
    'atHelperFunctions',
    'iconResolverServiceFunctions',
    'templateServiceFunctions'
];

function TimelineCtrl(
    $window,
    $scope,
    $rootScope,
    $state,
    msg,
    customUibModal,
    pageSizeService,
    browserServiceFunctions,
    authorizationService,
    atHelperFunctions,
    iconResolverServiceFunctions,
    templateServiceFunctions
) {
    var self = this;
    self.singleDayProductivityResult = [];
    $scope.mobileTooltip = {};
    $scope.PRODUCTIVITY_CHART_STATE_INITIAL = 0;
    $scope.PRODUCTIVITY_CHART_STATE_NO_DATA = 1;
    $scope.PRODUCTIVITY_CHART_STATE_PREPARING = 2;
    $scope.PRODUCTIVITY_CHART_STATE_DRAWING = 3;
    $scope.PRODUCTIVITY_CHART_STATE_READY = 4;
    $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_INITIAL;
    $scope.isIeOrEdgeBrowser = browserServiceFunctions.isIeOrEdgeBrowser();

    // Build productivity tooltip
    var customizeProductivityTooltip = function (userInfo, startDate, endDate) {
        var timeFormat = getTooltipTimeFormat();
        var time =
            moment(startDate).format(timeFormat) +
            ($scope.intervalUnit === 'Day' ? '' : ' - ' + moment(endDate).format(timeFormat));
        var user;
        var duration;

        if ($scope.intervalUnit) {
            user = userInfo.user.substring(0, userInfo.user.lastIndexOf(' ('));
            duration = '1 ' + $scope.intervalUnit.toLowerCase();
        } else {
            user = userInfo.user;
            var realInterval = (moment(endDate) - moment(startDate)) / 1000 / 60;
            duration = $scope.intervalConst[realInterval] || $scope.intervalConst[$scope.interval];
        }

        if (!$scope.intervalUnit) {
            return templateServiceFunctions.timelineTooltipTemplate(
                userInfo.user,
                time,
                duration,
                userInfo.productivity,
                userInfo.activity,
                $scope.view === 'detailedView' || $scope.layoutMode === 'Detailed'
            );
        }

        var $toolTip = $('div.tooltip-chart').clone();
        $toolTip.removeClass('hide');
        $toolTip.find('.tooltip-user').text(user);
        if (!$scope.intervalUnit && typeof userInfo.productivity !== 'undefined') {
            var productivityStatus = userInfo.productivity;
            $toolTip.find('.tooltip-productivity')[0].outerHTML =
                '<div class="pull-right">' +
                iconResolverServiceFunctions.getProductivityTag(productivityStatus, false) +
                '</div>';
        }
        $toolTip.find('.tooltip-time').text(time);
        $toolTip.find('.tooltip-duration').text(duration);
        if (userInfo.activity || $scope.intervalUnit) {
            $toolTip.find('.tooltip-activity-container').removeClass('hide');
            if ($scope.intervalUnit) {
                $toolTip.find('.tooltip-activity-label').text('Activity Duration:');
                $toolTip.find('.tooltip-activity').addClass('pull-right');
            } else {
                $toolTip.find('.tooltip-activity-label').text('Longest Activity:');
                $toolTip.find('.tooltip-activity').removeClass('pull-right');
            }
            if (userInfo.activity) {
                $toolTip.find('.tooltip-activity').text(userInfo.activity);
            } else {
                $toolTip.find('.tooltip-activity').text(moment.duration(userInfo.duration, 'seconds').humanize());
            }
        }

        return userInfo.duration === 0 || (!$scope.intervalUnit && typeof userInfo.productivity === 'undefined')
            ? ''
            : $toolTip[0].outerHTML;
    };

    function getTooltipTimeFormat() {
        switch ($scope.intervalUnit) {
            case 'Day':
            case 'Week':
                return 'MMM D';
            default:
                return $scope.tooltipTimeFormat;
        }
    }

    function chartHeight() {
        var rows = Math.min(
            $scope.timelineDataSource.total() -
                ($scope.timelineDataSource.page() - 1) * $scope.timelineDataSource.pageSize(),
            $scope.timelineDataSource.pageSize()
        );
        return 50 + 42 * rows;
    }

    function decodeColor(value) {
        return {
            prod: $scope.intervalUnit ? undefined : value,
            level: $scope.intervalUnit ? value : undefined,
            color: $scope.colors[value] || 'white'
        };
    }

    var openUserActivityModal = function (selectedRow) {
        angular.element('.google-visualization-tooltip').remove();
        var activityData = selectedRow;
        if (activityData.productivity !== undefined && activityData.productivity !== 9) {
            activityData.timeFormat = $scope.tooltipTimeFormat;
            activityData.intervalConst = $scope.intervalConst;
            activityData.productivityConst = $scope.productivityConst;
            activityData.timelineType = $scope.timelineType;

            if ($scope.intervalUnit) {
                $scope.userActivityInstance = customUibModal.open({
                    animation: false,
                    template: require('views/modals/userActivityModal.html'),
                    controller: 'userActivityModalController',
                    windowClass: 'centered-modal',
                    size: 'md',
                    resolve: {
                        activityData: activityData
                    }
                });
            } else {
                var stateParams = {};
                stateParams[activityData.timelineType === 'computers' ? 'computer' : 'user'] = activityData.user;

                if (activityData.activity && activityData.isWebsite) {
                    stateParams.site = activityData.activity;
                } else {
                    if (activityData.hasDescription) {
                        stateParams.description = activityData.activity;
                    } else {
                        stateParams.exec = activityData.activity;
                    }
                }
                var routeName = 'app.reports.activitylog';
                if (authorizationService.hasRouteByName(routeName)) {
                    $state.go(routeName, stateParams);
                }
            }
        }
    };

    $scope.binSelect = function (chart, selectedItem) {
        if ($scope.isMobile) {
            var selectedRow = self.singleDayProductivityResult[selectedItem.row];
            var productivity = selectedRow.productivity.toString();

            if (productivity === '0') {
                $scope.mobileTooltip.hasProductivity = false;
                return;
            }

            $scope.mobileTooltip = {
                hasProductivity: true,
                productivity: $scope.productivityConst[productivity],
                user: selectedRow.user,
                time:
                    moment('1900-01-01T' + selectedRow.start).format($scope.tooltipTimeFormat) +
                    ' - ' +
                    moment('1900-01-01T' + selectedRow.end).format($scope.tooltipTimeFormat),
                duration: $scope.intervalConst[$scope.interval],
                activity: selectedRow.activity
            };
        } else {
            openUserActivityModal(self.singleDayProductivityResult[selectedItem.row]);
        }
    };

    $scope.beforeChartDraw = function () {
        $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_DRAWING;
    };

    $scope.clearWhiteSegments = function () {
        // remove white cells so they don't overlap grid lines
        $('.google-timeline g:nth-child(5) rect[fill="#ffffff"]').remove();
    };

    $scope.chartReady = function () {
        if ($scope.productivityChartState === $scope.PRODUCTIVITY_CHART_STATE_DRAWING) {
            $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_READY;
            $scope.$emit('timelineChartReady');

            if ($scope.detailsMode === 'users') {
                var showTooltipHandler = function (e) {
                    var tooltipStyle = e.sender.popup.wrapper.prop('style');
                    var textWidth = e.sender.element.prop('clientWidth');
                    tooltipStyle.left = parseFloat(tooltipStyle.left.replace('px', '')) + textWidth / 2.5 + 'px';
                    tooltipStyle.top = parseFloat(tooltipStyle.top.replace('px', '')) + 20 + 'px';

                    if (!$scope.isInDom(this.element[0])) {
                        this.hide();
                    }
                };

                // add tooltips to labels in first column which don't fit
                angular.element('.google-timeline g:first-of-type text').each(function () {
                    var element = angular.element(this);
                    var text = element.text();
                    if (text.charCodeAt(text.length - 1) === 8230) {
                        // ellipsis
                        for (var i = 0; i < $scope.timelineData.length; i++) {
                            var user = $scope.timelineData[i].user;
                            if (user.indexOf(text.substr(0, text.length - 1)) === 0) {
                                element.kendoTooltip({
                                    content: browserServiceFunctions.htmlEscape(user),
                                    position: 'bottom',
                                    show: showTooltipHandler,
                                    animation: false
                                });
                                break;
                            }
                        }
                    }
                });
            }
            $scope.clearWhiteSegments();

            if (pageSizeService.setDropdownHandler) {
                if ($state.current.name === 'app.reports.topUsersLegacy') {
                    pageSizeService.setDropdownHandler('topUsers-timeline');
                } else if ($state.current.name === 'app.reports.productivityLegacy') {
                    pageSizeService.setDropdownHandler('productivity-master');
                } else {
                    pageSizeService.setDropdownHandler('timeline');
                }
            }
        }

        //FIXME: This was causing an infinite resize loop
        // if (!$scope.resizing) {
        //     angular.element(window).resize();
        // } else {
        //     $scope.resizing = false;
        // }

        angular.element('div.google-visualization-tooltip').remove();
    };

    angular.element(window).on('resize.doResize', function () {
        $scope.resizing = true;
    });

    $scope.successResponseHandler = function () {
        $scope.intervalConst = {
            5: msg.get('fiveMinutes'),
            15: msg.get('fifteenMinutes'),
            30: msg.get('thirtyMinutes'),
            60: msg.get('oneHour'),
            120: msg.get('twoHours')
        };
        var result = $scope.timelineData || [];
        self.singleDayProductivityResult = result;
        // eslint-disable-next-line no-undef
        var dataTable = new google.visualization.DataTable();
        dataTable.addColumn('string', 'user');
        dataTable.addColumn('string', $scope.intervalUnit ? 'level' : 'prod');
        dataTable.addColumn({
            type: 'string',
            role: 'tooltip',
            p: {
                html: true
            }
        });
        dataTable.addColumn('date', 'start');
        dataTable.addColumn('date', 'end');

        var rowResultLength = [];
        angular.forEach(result, function (value) {
            var start = moment(($scope.intervalUnit ? '' : '1900-01-01T') + value.start).toDate();
            var end = moment(($scope.intervalUnit ? '' : '1900-01-01T') + value.end).toDate();
            dataTable.addRow([
                value.date || value.user,
                String($scope.intervalUnit ? value.level : value.productivity),
                customizeProductivityTooltip(value, start, end),
                start,
                end
            ]);
            if (!rowResultLength[value.date || value.user]) {
                rowResultLength[value.date || value.user] = 0;
            }
            rowResultLength[value.date || value.user]++;
        });

        var longestSegment = 1;
        for (var len in rowResultLength) {
            longestSegment = Math.max(longestSegment, rowResultLength[len]);
        }

        var containerWidth = angular.element('#singleDayProductivityContainer').width();
        var columnsWidth = 85 + 17 * longestSegment;

        $scope.singleDayProductivityChartStyle = $scope.isMobile
            ? {
                  width: columnsWidth < containerWidth ? '100%' : columnsWidth + 'px'
              }
            : {
                  width: '100%'
              };

        // Make sure Google timeline assigns the right colors
        var field = $scope.intervalUnit ? 'level' : 'prod';
        var colorMap = [];
        for (var i = 0; i < dataTable.getNumberOfRows(); i++) {
            var color = decodeColor(dataTable.getValue(i, 1));
            var contains = false;
            for (var j = 0; j < colorMap.length; j++) {
                if (color[field] === colorMap[j][field]) {
                    contains = true;
                }
            }
            if (!contains) {
                colorMap.push(color);
            }
        }

        var colors = [];
        for (var k = 0; k < colorMap.length; k++) {
            colors.push(colorMap[k].color);
        }
        // END Make sure Google timeline assigns the right colors

        $scope.chartObject = {
            type: 'Timeline',
            data: dataTable,
            options: {
                timeline: {
                    showBarLabels: false
                },
                colors: colors,
                height: chartHeight(),
                hAxis: $scope.intervalUnit
                    ? undefined
                    : {
                          format: ' ' + $scope.chartTimeFormat + ' '
                      }
            }
        };

        var secondCondition = $scope.userValidator ? $scope.userValidator() : true;
        if (result && result.length > 0 && secondCondition) {
            $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_PREPARING;
        } else {
            $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_NO_DATA;
        }

        atHelperFunctions.safeApply($rootScope);
    };

    $scope.$watch('reloadTrigger', function (newValue) {
        if (newValue > 0) {
            $scope.successResponseHandler();
        }
    });

    $scope.$watch('productivityChartState', function (newValue) {
        var noData = angular.element('#no-data');
        var pager = angular.element('#pager');
        var loader = angular.element('.progress-circle-indeterminate');

        $scope.mobileTooltip.hasProductivity = false;

        if (
            newValue === $scope.PRODUCTIVITY_CHART_STATE_NO_DATA ||
            newValue === $scope.PRODUCTIVITY_CHART_STATE_READY
        ) {
            loader.hide();
        } else {
            loader.show();
        }

        if (newValue === $scope.PRODUCTIVITY_CHART_STATE_NO_DATA) {
            noData.show();
            pager.hide();
        } else {
            noData.hide();
            pager.show();
        }

        if (newValue === $scope.PRODUCTIVITY_CHART_STATE_READY) {
            pager.show();
        } else {
            if ($scope.hideTimelinePager) {
                pager.hide();
            }
        }
    });
}
