import { Role } from '../../../../../enums';
import { IRoute } from '../../../../../models/IRoute';
import { securityTemplate as angularJsTemplate } from '../../templates';

export const appSettingsSecurity: IRoute = {
  name: 'app.settings.security',
  stateDefinition: {
    url: '/settings/security',
    template: angularJsTemplate,
    controller: 'SecurityController',
    data: { pageTitle: 'Security Configuration' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportAdvanced, Role.SupportIntermediate]
  }
};

export default appSettingsSecurity;
