import viewTemplate from 'views/widgets/unverifiedNotification.html?raw';
import {
    accountSettingsStore,
    getAccountSettings
} from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('unverifiedNotification', [
    function () {
        return {
            restrict: 'E',
            scope: false,
            template: viewTemplate,

            controller: [
                '$scope',
                '$rootScope',
                'loginService',
                'customUibModal',
                function ($scope, $rootScope, loginService, customUibModal) {
                    const { creator, isVerified } = getAccountSettings();
                    const { username } = profileStore.getState().profile;
                    $scope.email = username;
                    const isCreator = $scope.email === creator;

                    $scope.showUnverifiedBanner = isCreator && !isVerified;

                    const accountSettingsStoreUnsubscribe = accountSettingsStore.subscribe((state) => {
                        const { isVerified, creator } = state.accountSettings;
                        const isCreator = $scope.email === creator;
                        $scope.showUnverifiedBanner = isCreator && !isVerified;
                    });

                    $scope.$on('$destroy', () => {
                        accountSettingsStoreUnsubscribe();
                    });

                    $scope.showOptions = function (e) {
                        e.preventDefault();
                        const modal = customUibModal.open({
                            animation: false,
                            template:
                                '<div class="verification-modal"><verification-options is-modal="true" owner="' +
                                $scope.email +
                                '"></verification-options></div>',
                            windowClass: 'centered-modal'
                        });

                        $rootScope.$on('dismiss-verification-modal', function () {
                            modal.close();
                        });
                    };
                }
            ]
        };
    }
]);
