'use strict';

angular.module('app')
    .service('tabNotificationService', TabNotificationService);

TabNotificationService.$inject = ['$document', '$timeout'];

function TabNotificationService($document, $timeout) {
    var notificationId = 0;
    var blinkInterval = 1500;
    var defaultFavicon = 'favicon.ico';
    var alertFavicon = 'favicon-alert.ico';

    var isHidden = function () {
        return $document[0].hidden;
    };

    var changeFavicon = function (src) {
        var head = document.head || document.getElementsByTagName('head')[0];

        var link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        link.href = src;

        var oldLink = document.getElementById('favicon');
        if (oldLink) {
            head.removeChild(oldLink);
        }
        head.appendChild(link);
    };

    var changeTitle = function (title, id) {
        document.title = title;
        changeFavicon(alertFavicon);
        if (notificationId === id) {
            $timeout(function () {
                if (notificationId === id) {
                    resetTitle(title, id);
                }
            }, blinkInterval);
        }
    };

    var resetTitle = function (title, id) {
        if (!isHidden()) {
            changeFavicon(defaultFavicon);
        } else if (notificationId === id) {
            $timeout(function () {
                if (!isHidden()) {
                    changeFavicon(defaultFavicon);
                } else if (notificationId === id) {
                    changeTitle(title, id);
                }
            }, blinkInterval);
        }
    };

    return {
        notify: function (message) {
            if (isHidden()) {
                changeTitle(message, ++notificationId);
            }
        }
    };
}
