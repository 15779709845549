import { Button } from "@mui/material";

export const DataDogDemo = () => {

  const doNotClick = () => {

    // This will generate a console error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.DD_DEMO.addUserAction('some_action', { action: 'action' });
  }

  return <div>
    <Button onClick={doNotClick}>
      DO NOT CLICK!!
    </Button>

  </div>;
};
