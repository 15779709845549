import { useCallback, useState } from 'react';
import { fetchGTEUsersDNT, addGTEUserDNT, deleteGTEUserDNT } from '../utils';
import { IGTEUsersState, GTEUserDTO, GTEUserPayload } from '../models';
import { NotificationType, SortDirection } from '../../common/enums';
import { sorter } from '../../common/utils/sorter/sorter';

export const useGTEUsersState = (): IGTEUsersState => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gteUserNotification, setGTEUserNotification] =
    useState<NotificationType>();
  const [gteUsers, setGTEUsers] = useState<GTEUserDTO[]>([]);

  const [apiRequestError, setApiRequestError] = useState<boolean>(false);
  const [selectedUsername, setSelectedUsername] = useState<string>('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.None
  );

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const users = await fetchGTEUsersDNT();
      setGTEUsers(users?.records);
      return users?.records;
    } catch (error) {
      const msg = 'Unable to load Global Tracking Exclusions Users.';
      console.error(`ActivTrak Error: ${msg}`, error);
      setGTEUserNotification({
        msg: msg,
        type: 'error'
      });
      setApiRequestError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, []);

  const addGTEUser = useCallback(async (payload: GTEUserPayload) => {
    setIsLoading(true);
    try {
      await addGTEUserDNT(payload);
      setGTEUserNotification({
        msg: `Success! Added ${payload.username} to global tracking exclusions`,
        type: 'success'
      });
    } catch (error) {
      const msg = 'Unable to add GTE User.';
      console.error(`ActivTrak Error: ${msg}`, error);
      setGTEUserNotification({
        msg: msg,
        type: 'error'
      });
      setApiRequestError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteGTEUser = useCallback(async (payload: GTEUserPayload) => {
    setIsLoading(true);
    try {
      await deleteGTEUserDNT(payload);
      setGTEUserNotification({
        msg: `Success! Deleted ${payload.username} from global tracking exclusions`,
        type: 'success'
      });
    } catch (error) {
      const msg = 'Unable to delete GTE User.';
      console.error(`ActivTrak Error: ${msg}`, error);
      setGTEUserNotification({
        msg: msg,
        type: 'error'
      });
      setApiRequestError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const resetFields = useCallback(() => {
    setSelectedUsername('');
    setIsAcknowledged(false);
  }, [setIsAcknowledged, setSelectedUsername]);

  const sortGTEUsers = useCallback(
    (users: GTEUserDTO[], sortDirection: SortDirection): void => {
      const newOrder = sorter(users, sortDirection, 'username');
      setGTEUsers(newOrder);
    },
    []
  );

  return {
    init,
    gteUsers,
    isLoading,
    gteUserNotification,
    setGTEUserNotification,
    addGTEUser,
    deleteGTEUser,
    selectedUsername,
    setSelectedUsername,
    apiRequestError,
    isAcknowledged,
    setIsAcknowledged,
    resetFields,
    sortDirection,
    setSortDirection,
    sortGTEUsers
  };
};
