import * as definedRoutes from './routeDefinitions';
import { PermissionLevel, Role } from '../../enums';
import { BundleFlag } from '../../enums/BundleFlag';

import { FeatureFlag } from '../../enums/FeatureFlag';
import { getPrivacySettings } from '../../hooks/privacySettingsStore';
import { PrivacySetting } from '../../enums/PrivacySetting';

import { createAuthorizations } from '../../services/Authorization/AuthorizationFactory';
import { getUserClaims } from '../../helpers/authentication/useUserTokenStore';
import { getUserBundleFlags, getUserFeatureFlags } from '../../stores/userPermissionsStore/userPermissionStore';
import { getAccountSettings } from '../../stores/accountSettingsStore/accountSettingsStore';
import { checkUserFlags, checkUserRoleAccess, checkUserRoles } from '../../stores/userPermissionsStore/userPermissionStore.utils';
import { ApplicationRoute, IRoute } from '../../models/IRoute';

const resolveRoutes = (claims: { type: string; value: string }[]) => {

  const bundleFlags = getUserBundleFlags();
  const featureFlags = getUserFeatureFlags();
  const privacySettings = getPrivacySettings();
  const accountSettings = getAccountSettings();
  const authorizations = createAuthorizations(accountSettings, claims);

  const routes = {
    ...definedRoutes
  };

  // This array contains roles and role access
  const user_role_access = authorizations.roles as Role[];

  //filter...
  const filtered = Object.keys(routes)
    .filter((key) => {
      const route: IRoute = routes[key];

      if (route.stateDefinition.abstract) {
        return true;
      }

      if (!route.access) {
        return true;
      }

      const userRoleAccess = checkUserRoleAccess(
        route.access,
        user_role_access
      );
      if (!userRoleAccess) {
        return false;
      }

      const userBundleFlags = checkUserFlags<BundleFlag>(
        bundleFlags,
        route.access.bundleFlags,
        route.access.excludedBundleFlags
      );
      if (!userBundleFlags) {
        return false;
      }

      const userFeatureFlags = checkUserFlags<FeatureFlag>(
        featureFlags,
        route.access.featureFlags,
        route.access.excludedFeatureFlags
      );
      if (!userFeatureFlags) {
        return false;
      }

      const userPrivacySettings = checkUserFlags<PrivacySetting>(
        privacySettings,
        route.access.privacySettings,
        route.access.excludedPrivacySettings
      );
      if (!userPrivacySettings) {
        return false;
      }

      const { edit, read } = route.access;
      if (edit || read) {
        return true;
      }

      return false;
    })
    .map((key) => {
      const route: ApplicationRoute = routes[key];

      if (!route.access) {
        // Default level is Read
        route.authLevel = PermissionLevel.Read;
        return route;
      }

      const { edit, read } = route.access;

      // Check if role has edit access
      if (checkUserRoles(edit, user_role_access as Role[])) {
        route.authLevel = PermissionLevel.Edit;
      }
      // Check if role has read access
      else if (checkUserRoles(read, user_role_access as Role[])) {
        route.authLevel = PermissionLevel.Read;
      } else {
        route.authLevel = PermissionLevel.None;
      }

      return route;
    })
    .filter((route) => {
      // Do not bind PermissionLevel.None
      return route.authLevel !== PermissionLevel.None;
    });

  return filtered;
};

// TODO: Rename to getStates...
export const getRouteDefinitions = () => {
  const claims = getUserClaims();
  if (claims === 'invalid') {
    return 'invalid';
  }

  const routes = resolveRoutes(claims);
  return routes;
};

export const getState = (name: string) => {
  // TODO: Cache...
  const states = getRouteDefinitions();

  if (states === 'invalid') {
    return 'invalid';
  }

  const found = states.find((state) => state.name === name) ?? 'invalid';
  return found;
};

