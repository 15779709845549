import React from 'react';
import {
  DefaultSkeletonLoaderProps,
  SingleSkeletonRowProps
} from './atGrid.models';
import {
  SkeletonLoaderCheckboxContainer,
  SkeletonLoaderContainer,
  SkeletonLoaderRowsContainer,
  SkeletonSC
} from './atGrid.styles';

const SingleSkeletonRow: React.FC<SingleSkeletonRowProps> = ({
  rowIndex,
  columnCount,
  includeCheckboxes
}) => {
  const columnElements = [];
  for (let i = 1; i <= columnCount; i++) {
    columnElements.push(
      <SkeletonLoaderContainer key={`skeleton-column-${i}`}>
        <SkeletonSC />
      </SkeletonLoaderContainer>
    );
  }

  return (
    <SkeletonLoaderRowsContainer
      key={`skeleton-row-${rowIndex}`}
      data-testid="at-grid-skeleton-loader"
    >
      {includeCheckboxes && (
        <SkeletonLoaderCheckboxContainer>
          <SkeletonSC />
        </SkeletonLoaderCheckboxContainer>
      )}
      {columnElements}
    </SkeletonLoaderRowsContainer>
  );
};

export const DefaultSkeletonLoader: React.FC<DefaultSkeletonLoaderProps> = ({
  columnCount,
  includeCheckboxes
}) => {
  if (!columnCount) return <></>;

  const rowsNumber = Math.round(window.innerHeight / 54);
  const rows = [];

  for (let i = 1; i <= rowsNumber; i++) {
    rows.push(
      <SingleSkeletonRow
        key={i}
        rowIndex={i}
        columnCount={columnCount}
        includeCheckboxes={includeCheckboxes}
      />
    );
  }

  return <>{rows}</>;
};
