import React from 'react';
import { NavigationItem } from './NavigationItem';

export const HomeComponent = (props) => {
  const { home } = props;
  if (home) {
    return <NavigationItem item={home} index={0} />;
  }
  return <></>;
};
