import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { TimeAgo } from '../../common/components/TimeAgo';
import {
  InfoOutlinedIcon,
  TextOverflow
} from '../../common/styles/app.component.styles';
import {
  DELETE_USER_DATA,
  START_TRACKING,
  STOP_TRACKING
} from '../constants/buttonLabels.constants';
import { WhiteBackgroundTooltip } from '../../common/components/Tooltip/TextWithTooltip.styles';
import IconButton from '@mui/material/IconButton';
import { IdentityActionsButton } from '../components/IdentityActionsButton';
import Link from '../../common/components/Link';
import { AnchorTags } from '../../common/constants/routesUrls';
import { IUserIdentity } from '../models/IUserIdentity';
import { getTimeLapsedSinceNowString } from '../../common/utils/datetime/datetimeCalculations';
import { GroupTypeLabel } from '../../common/components/GroupType';

const userColumn = (dataItem: IUserIdentity) => {
  const { displayName } = dataItem;
  const displayNameOrId =
    displayName?.value && displayName.value.length > 0
      ? displayName.value
      : dataItem?.displayId?.value;
  return (
    displayNameOrId && (
      <Tooltip title={displayNameOrId ?? ''} placement="bottom-start">
        <TextOverflow>{displayNameOrId}</TextOverflow>
      </Tooltip>
    )
  );
};

export const displayIdColumn = (dataItem: IUserIdentity) => {
  const displayId = dataItem?.displayId;
  return (
    <>
      {displayId && (
        <GroupTypeLabel
          iconSource={displayId?.source}
          tooltipContent={`${displayId?.value} - ${displayId?.source}`}
          tooltipChild={displayId?.value}
        />
      )}
    </>
  );
};

const lastActivityColumn = (dataItem: any) => {
  const timeAgo = getTimeLapsedSinceNowString(dataItem.maxLastLog);
  return (
    <TimeAgo
      lastLog={dataItem.maxLastLog}
      learnMoreLink="https://support.activtrak.com/"
      timeAgo={timeAgo}
    />
  );
};

const headerGlobalExclusion = () => {
  return (
    <>
      Tracking
      <WhiteBackgroundTooltip
        title={
          <>
            User level tracking is managed from this page. If you want to
            exclude on a global level, please navigate to the{' '}
            <Link
              rel="noreferrer"
              href={`/#/app/settings/configuration${AnchorTags.GTEUsers}`}
            >
              Account Configuration page.
            </Link>
          </>
        }
        enterTouchDelay={0}
      >
        <IconButton sx={{ padding: '0px 0px 0px 3px !important' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </WhiteBackgroundTooltip>
    </>
  );
};

const headerUser = () => {
  return (
    <>
      User
      <WhiteBackgroundTooltip
        title={<>Users are represented by their Display Name or ID.</>}
        enterTouchDelay={0}
      >
        <IconButton sx={{ padding: '0px 0px 0px 3px !important' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </WhiteBackgroundTooltip>
    </>
  );
};

const headerId = () => {
  return (
    <>
      Source & ID
      <WhiteBackgroundTooltip
        title={
          <>
            IDs are unique (cannot be shared among users). One default ID will
            be displayed in reports and exports, using this precedence: UPN{' '}
            {'>'} Email {'>'} Employee ID {'>'} Device login.
          </>
        }
        enterTouchDelay={0}
      >
        <IconButton sx={{ padding: '0px 0px 0px 3px !important' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </WhiteBackgroundTooltip>
    </>
  );
};

const menuColumn = (
  dataItem: IUserIdentity,
  onChangeOption: (option?: any, dataItem?: IUserIdentity) => void,
  isReadOnly: boolean
) => {
  const menuOptions: { label: string; disabled?: boolean }[] = [];
  menuOptions.push({
    label: dataItem.tracked ? STOP_TRACKING : START_TRACKING,
    disabled: isReadOnly
  });
  menuOptions.push({
    label: DELETE_USER_DATA,
    disabled: dataItem.agents?.length === 0 || isReadOnly
  });
  return (
    <IdentityActionsButton
      menuOptions={menuOptions}
      onChangeOption={(e, option) => onChangeOption(option, dataItem)}
      disabled={isReadOnly}
    />
  );
};

export const useUserColumns = (
  dateTimeFormat: string,
  onChangeOption: (option?: any, dataItem?: IUserIdentity) => void,
  isReadOnly: boolean
): IGridColumn[] => {
  return [
    {
      field: 'user',
      fieldName: 'user',
      headerName: headerUser(),
      type: 'string',
      isSortable: true,
      width: '35%',
      template: userColumn
    },
    {
      field: 'displayId',
      fieldName: 'displayId',
      headerName: headerId(),
      type: 'string',
      isSortable: true,
      width: '36%',
      template: displayIdColumn
    },
    {
      field: 'tracked',
      headerName: headerGlobalExclusion(),
      type: 'boolean',
      isSortable: true,
      width: '9%',
      align: 'center',
      template: (dataItem) => {
        return dataItem?.tracked ? 'Tracked' : 'Untracked';
      }
    },
    {
      field: 'userCount',
      headerName: 'Agents',
      type: 'string',
      isSortable: true,
      width: '6%',
      align: 'right'
    },
    {
      field: 'lastLog',
      headerName: 'Last Activity',
      type: 'date',
      isSortable: true,
      width: '11%',
      template: (dataItem) => lastActivityColumn(dataItem)
    },
    {
      type: 'string',
      isSortable: false,
      width: '5%',
      template: (dataItem) => menuColumn(dataItem, onChangeOption, isReadOnly)
    }
  ];
};
