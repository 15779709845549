import { ProductivityStatus } from '../enums';

export const isPassive = (status: ProductivityStatus) => {
  return (
    status == ProductivityStatus.UnproductivePassive ||
    status == ProductivityStatus.ProductivePassive ||
    status == ProductivityStatus.UndefinedPassive
  );
};

const getField = (status) => {
  switch (status) {
    case null:
      return 'all';
    case ProductivityStatus.Productive:
      return 'productive';
    case ProductivityStatus.ProductivePassive:
      return 'productivePassive';
    case ProductivityStatus.Undefined:
      return 'undefined';
    case ProductivityStatus.UndefinedPassive:
      return 'passive';
    case ProductivityStatus.Unproductive:
      return 'unproductive';
    case ProductivityStatus.UnproductivePassive:
      return 'unproductivePassive';
    case ProductivityStatus.OfflineMeetings:
      return 'offlineMeetings';
    default:
      return 'none';
  }
};

export const getClass = (status) => {
  const fieldName = getField(status);
  if (fieldName === 'none') return;
  return fieldName
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
    .toLowerCase();
};

export const getName = (status, showActiveLabel, useParentheses) => {
  let activeLabel = showActiveLabel ? ' Active' : '';
  let passiveLabel = ' Passive';

  const addParentheses = (string) => {
    return ` (${string.trim()})`;
  };

  if (useParentheses && showActiveLabel) {
    activeLabel = addParentheses(activeLabel);
    passiveLabel = addParentheses(passiveLabel);
  }

  switch (status) {
    case null:
      return 'All';
    case ProductivityStatus.Productive:
      return `Productive${activeLabel}`;
    case ProductivityStatus.ProductivePassive:
      return `Productive${passiveLabel}`;
    case ProductivityStatus.Undefined:
      return `Undefined${activeLabel}`;
    case ProductivityStatus.UndefinedPassive:
      return showActiveLabel ? `Undefined${passiveLabel}` : 'Passive';
    case ProductivityStatus.Unproductive:
      return `Unproductive${activeLabel}`;
    case ProductivityStatus.UnproductivePassive:
      return `Unproductive${passiveLabel}`;
    default:
      return '';
  }
};
