import { createStore } from 'zustand/vanilla';
import { IProductivityReportStore } from '../models/Productivity.models';
import { ApiStatus } from '../../../common/enums/ApiStatus';
import { getProductivityReportData } from '../utils/productivityReport.utils';

export const productivityReportStore = createStore<IProductivityReportStore>(
  () => {
    return {
      response: null,
      apiStatus: ApiStatus.Loaded
    };
  }
);

export const fetchProductivityReport = async (): Promise<void> => {
  productivityReportStore.setState({ apiStatus: ApiStatus.Loading });
  const response = await getProductivityReportData();
  productivityReportStore.setState((prev) => ({
    ...prev,
    response,
    apiStatus: ApiStatus.Loaded
  }));
};
