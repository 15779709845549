import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { SortDirection } from '../enums/SortDirection';
import { SEARCH_TYPE_ALL } from '../constants/identitySearchTypes';

export interface IIdentitySearchParametersStore {
  sortBy?: string;
  sortDirection?: SortDirection;
  pageSize: string;
  searchTerm: string;
  searchType: string;
  cursor: string;
  filter: string;
}

export const IDENTITY_SEARCH_PARAMETERS_STORE_KEY: string =
  'activTrak.identity-search-parameters-store';

export const IDENTITY_SEARCH_PARAMETERS_STORE_DEFAULT: IIdentitySearchParametersStore =
  {
    sortBy: 'lastLog',
    sortDirection: SortDirection.Descending,
    pageSize: '500',
    searchTerm: '',
    searchType: SEARCH_TYPE_ALL,
    cursor: null,
    filter: null
  };

export const identitySearchParametersStore = createStore<
  Partial<IIdentitySearchParametersStore>
>()(
  persist(
    (set) => ({
      pageSize: '500',
      actions: {
        setPageSize: (newPageSize) => set({ pageSize: newPageSize })
      }
    }),
    {
      name: IDENTITY_SEARCH_PARAMETERS_STORE_KEY,
      partialize: (state) => ({ pageSize: state.pageSize })
    }
  )
);

// get the current state of the identity store
export const getIdentitySearchParametersStore =
  (): IIdentitySearchParametersStore => {
    const partialFields = identitySearchParametersStore.getState();
    return { ...IDENTITY_SEARCH_PARAMETERS_STORE_DEFAULT, ...partialFields };
  };

export const setIdentitySearchParametersState = (
  newIdentityState: IIdentitySearchParametersStore
): void => {
  const current = identitySearchParametersStore.getState();
  identitySearchParametersStore.setState({ ...current, ...newIdentityState });
};

export const getIdentitySearchParametersState =
  (): Partial<IIdentitySearchParametersStore> => {
    return identitySearchParametersStore.getState();
  };

/**
 * Resets the identity store to its default state.
 */
export const resetIdentitySearchParametersStore = (): void =>
  identitySearchParametersStore.setState(
    IDENTITY_SEARCH_PARAMETERS_STORE_DEFAULT
  );
