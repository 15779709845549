import { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColumnMovedEvent,
  ColumnResizedEvent,
  RowSelectionOptions
} from 'ag-grid-community';
import { getUIRouterState } from '../../../common/hooks/useUIRouterHistory';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { useReportViewStore } from '../../../common/components/ReportFilters/hooks/useReportViewToggleStore';
import { htmlDecode } from '../../../common/utils/parse/parse';
import { ReportPageModeTypes } from '../../../common/components/ReportFilters/constants/reportPageModeToggle.constants';
import { getTopUsersColumns } from '../utils/topUsersColumns.utils';
import {
  ITopUserReport,
  ITopUserReportStore,
  ITopUserSearchStore
} from '../models/TopUsersReport.models';
import {
  getTopUsersData,
  mapTopUsersColumnsToColumnState
} from '../utils/topUsersReport.utils';
import { setTopUsersColumnsStore } from '../stores/topUsersColumns.store';
import { useTopUsersReportsStore } from '../hooks/useTopUsersReportStore';
import { useReportPageModeStore } from '../../../common/components/ReportFilters/hooks/useReportPageModeToggleStore';
import {
  setTopUsersReportStore,
  topUsersReportStore
} from '../stores/topUsersReport.store';
import {
  setTopUsersSearchStore,
  clearTopUsersSearch
} from '../stores/topUsersSearch.store';
import { useTopUsersSearchStore } from '../hooks/useTopUsersSearchStore';
import { useNotifications } from '../../../common/services/Notifications';
import { ApiStatus } from '../../../common/enums/ApiStatus';
import AtGrid from '../../../common/components/AtGrid/AtGrid';
import { NoReportData } from '../../common/components/NoReportData';
import { AtConsoleLogger } from '../../../common/utils/consoleLogger/AtConsoleLogger';

type TopUsersGridProps = {
  gridRef: React.MutableRefObject<AgGridReact>;
  onColumnUpdate: () => void;
};

export const TopUsersGrid = (props: TopUsersGridProps) => {
  const { gridRef, onColumnUpdate } = props;

  const [lastSelectedRow, setLastSelectedRow] = useState(null);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);
  const { toDate, fromDate } = reportFilters.dates.getDates();
  const refreshTimestamp = useRefreshStore((s) => s.refreshTimestamp);
  const { response, apiStatus } = useTopUsersReportsStore(
    (s: ITopUserReportStore) => s
  );
  const { searchResults } = useTopUsersSearchStore(
    (s: ITopUserSearchStore) => s
  );
  const view = useReportViewStore((s) => s.view);
  const pageMode = useReportPageModeStore((s) => s.pageMode);
  const notificationService = useNotifications();

  useEffect(() => {
    //this updates the columns based on current date range
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const cols = getTopUsersColumns(response?.largestDuration);
      gridApi.setGridOption('columnDefs', cols);
    }
  }, [toDate, fromDate, response?.largestDuration, gridRef]);

  const fetchTopUsers = useCallback(async (): Promise<number> => {
    topUsersReportStore.setState({ apiStatus: ApiStatus.Loading });
    const response = await getTopUsersData();
    const searchOptions = response?.data.map((result) => ({
      label: result.user,
      value: result.userId
    }));
    setTopUsersReportStore({
      response,
      apiStatus: response?.error ? ApiStatus.Error : ApiStatus.Loaded
    });
    setTopUsersSearchStore({ searchOptions });

    //return largestDuration for column settings on grid ready
    return response?.largestDuration;
  }, []);

  useEffect(() => {
    //clear any search results first
    clearTopUsersSearch();

    //This gets new data AFTER the grid is loaded AND the filters have changed
    const gridApi = gridRef.current?.api;
    const fetchData = async () => {
      await fetchTopUsers();
      gridApi.forEachNodeAfterFilterAndSort(function (node, index) {
        //this is the default row selection
        node.setSelected(index === 0);
      });
    };

    if (gridApi) fetchData();
  }, [reportFilters, refreshTimestamp, pageMode, gridRef, fetchTopUsers]);

  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      if (searchResults?.length) {
        //from the search results, find the matching row in the grid and select it
        const selectedRow = searchResults[0];
        gridApi.forEachNodeAfterFilterAndSort(function (node) {
          if (node.data.userId === selectedRow.userId) {
            node.setSelected(true);
          }
        });
      } else {
        //otherwise, reset the grid to select the first item
        gridApi.forEachNodeAfterFilterAndSort(function (node, index) {
          node.setSelected(index === 0);
        });
      }
    }
  }, [gridRef, searchResults]);

  useEffect(() => {
    //when the view changes, make sure the first row is selected
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.forEachNodeAfterFilterAndSort(function (node, index) {
        //this is the default row selection
        node.setSelected(index === 0);
      });
    }
  }, [view, gridRef]);

  useEffect(() => {
    if (apiStatus === ApiStatus.Error) {
      notificationService.error('Unable to load Top Users data');
      AtConsoleLogger('Unable to load Top Users data', response?.error).error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus, response?.error]);

  const handleColumnResize = useCallback(
    (event: ColumnResizedEvent) => {
      const columnState = event.api.getColumnState();
      mapTopUsersColumnsToColumnState(columnState, view);
      onColumnUpdate();
    },
    [onColumnUpdate, view]
  );

  const handleColumnReorder = useCallback(
    (event: ColumnMovedEvent<ITopUserReport>) => {
      const columnState = event.api.getColumnState();
      setTopUsersColumnsStore({ [view]: columnState });
      onColumnUpdate();
    },
    [onColumnUpdate, view]
  );

  const handleGridReady = useCallback(
    async (params) => {
      //This gets initial data AFTER the grid is loaded
      const gridApi = params.api;
      const largestDuration = await fetchTopUsers();

      //this updates the columns based on current date range
      const cols = getTopUsersColumns(largestDuration);
      await gridApi.setGridOption('columnDefs', cols);

      onColumnUpdate();

      //if there a ('groupBy' && 'groupId') || 'name'  setting from the browser url, auto-select that name in the chart, which auto-triggers 'handleRowSelected'
      const { name, groupId, groupBy } = getUIRouterState().params;
      let keyCheck: Record<string, string> = null;

      if (name || groupId) {
        const decodedName = name && htmlDecode(decodeURIComponent(name));
        if (decodedName) {
          keyCheck = { user: decodedName };
        } else if (groupBy === ReportPageModeTypes.Groups && groupId) {
          keyCheck = { userId: groupId };
        }
      }
      gridApi.forEachNodeAfterFilterAndSort(function (node, index) {
        if (keyCheck) {
          //this is for row selection based on url params
          const key = Object.keys(keyCheck)[0];
          const value = keyCheck[key];
          node.setSelected(node.data[key] === value);
        } else {
          //this is the default row selection
          node.setSelected(index === 0);
        }
      });
    },
    [fetchTopUsers, onColumnUpdate]
  );

  const handleRowSelected = useCallback(
    (event) => {
      //Hacky way to prevent the same row from being selected twice, but working with AgGrid and external filters has timing issues
      const selectedRow = event.data;

      // Prevent unnecessary API calls if the same row is selected again
      if (lastSelectedRow === selectedRow) return;

      // Update the last selected row
      setLastSelectedRow(selectedRow);

      //TODO: set current item in a gantt chart store to be used in the gantt chart api call & header
      console.log('Row selected:', selectedRow);
    },
    [lastSelectedRow]
  );

  const rowSelection = useMemo<
    RowSelectionOptions | 'single' | 'multiple'
  >(() => {
    return {
      mode: 'singleRow',
      checkboxes: false,
      enableClickSelection: true
    };
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      height: '300px'
    };
  }, []);

  const currentData = searchResults?.length ? searchResults : response?.data;

  return (
    <AtGrid
      ref={gridRef}
      rowData={currentData}
      rowSelection={rowSelection}
      loading={apiStatus === ApiStatus.Loading}
      noRowsOverlayComponent={NoReportData}
      noRowsOverlayComponentParams={noRowsOverlayComponentParams}
      onColumnResized={handleColumnResize}
      onColumnMoved={handleColumnReorder}
      onGridReady={handleGridReady}
      onRowSelected={handleRowSelected}
      gridWrapperStyles={{ height: '300px' }}
    />
  );
};
