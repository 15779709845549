import { Role } from '../../_reactivtrak/src/common/enums';
import { fetchData } from '../../_reactivtrak/src/common/helpers';
import authorization from '../../_reactivtrak/src/common/helpers/authorization';
import { filter } from 'lodash';
import { userServiceFunctions } from './userServiceFunctions';
import { getInjector } from '../../_reactivtrak/src/common/third-party/angular2react/useInjector';

let _latestUpdates;
let _userLastSeen;
let _loadingLatestUpdates;
let _callback;

let isArticleNew = (article) => {
    let lastSeenDifference = _userLastSeen.diff(moment.utc(article.createdAt), 'hours');

    // Article is new if the user has not viewed latest updates page
    // since it was released
    return lastSeenDifference < 0;
};

let setHasNewUpdates = () => {
    let newUpdateCount = null;
    if (!_latestUpdates) {
        if (!_loadingLatestUpdates) {
            loadLatestUpdates();
        }
        newUpdateCount = null;
    } else if (!_userLastSeen) {
        newUpdateCount = null;
    } else {
        let newArticles = filter(_latestUpdates, (a) => isArticleNew(a));
        newUpdateCount = newArticles.length;
    }

    if (_callback && typeof _callback === 'function') {
        _callback(newUpdateCount);
    }
};

const clearLocals = () => {
    _latestUpdates = undefined;
    _userLastSeen = undefined;
    _loadingLatestUpdates = undefined;
    _callback = undefined;
};

const loadLatestUpdates = async (showError) => {
    if (_userLastSeen) {
        _loadingLatestUpdates = true;
        if (authorization.hasRole(Role.SupportBasic)) {
            _latestUpdates = [];
            _loadingLatestUpdates = false;
            return _latestUpdates;
        } else {
            try {
                const articles = await fetchData({ path: '/api/support/latestupdates' });
                articles?.forEach(function (article) {
                    article.isNew = isArticleNew(article);
                });

                _latestUpdates = articles;
                _loadingLatestUpdates = false;
                setHasNewUpdates();
                return _latestUpdates;
            } catch (e) {
                _loadingLatestUpdates = false;
                if (showError) {
                    const $injector = getInjector();
                    const notificationService = $injector.get('notificationService');
                    notificationService.showNotification(
                        'An error occurred retrieving latest update articles.',
                        'danger'
                    );
                }
            }
        }
    }
};

const getLatestUpdates = () => {
    return _latestUpdates;
};

const getUserLastSeen = (viewedPage) => {
    return new Promise((resolve, reject) => {
        let tagName = 'latestupdates';
        userServiceFunctions
            .getUserLastSeen(tagName)
            .then((userLastSeen) => {
                _userLastSeen = userLastSeen ? moment.utc(userLastSeen) : moment.utc().add(-7, 'd');
                setHasNewUpdates();
                resolve(_userLastSeen);
                if (viewedPage) {
                    userServiceFunctions.setUserLastSeen(tagName);
                }
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const updateUserLastSeen = () => {
    let tagName = 'latestupdates';
    userServiceFunctions
        .updateUserLastSeen(tagName)
        .then((userLastSeen) => {
            _userLastSeen = userLastSeen ? moment.utc(userLastSeen) : moment.utc().add(-7, 'd');
            setHasNewUpdates();
        })
        .catch((e) => {
            throw e;
        });
};

const setLatestUpdatesCallback = (callback) => {
    _callback = callback;
};

export const latestUpdatesServiceFunctions = {
    clearLocals: clearLocals,
    loadLatestUpdates: loadLatestUpdates,
    getLatestUpdates: getLatestUpdates,
    getUserLastSeen: getUserLastSeen,
    updateUserLastSeen: updateUserLastSeen,
    setLatestUpdatesCallback: setLatestUpdatesCallback
};
