import { createStore } from 'zustand';
import { ILiveReportSettingsStore } from './liveReportSettingsStore.models';
import { fetchSettings } from './liveReportSettingsStore.utils';
import { LIVE_REPORT_SETTINGS_STORE_DEFAULT } from './liveReportSettingsStore.constants';

/**
 * Creates a live report settings store.
 */
export const liveReportSettingsStore = createStore<ILiveReportSettingsStore>(
  () => {
    return LIVE_REPORT_SETTINGS_STORE_DEFAULT;
  }
);

export const fetchLiveReportSettings =
  async (): Promise<ILiveReportSettingsStore> => {
    const response = await fetchSettings();
    liveReportSettingsStore.setState((prevState) => ({
      ...prevState,
      ...response
    }));
    return liveReportSettingsStore.getState();
  };

export const getLiveReportSettingsStore = (): ILiveReportSettingsStore =>
  liveReportSettingsStore.getState();

export const setLiveReportSettingsStore = (
  newSettings: Partial<ILiveReportSettingsStore>
): void =>
  liveReportSettingsStore.setState((prevState) => ({
    ...prevState,
    ...newSettings
  }));

/**
 * Resets the live report settings store to default state.
 */
export const resetLiveReportSettingsStore = (): void => {
  liveReportSettingsStore.setState(LIVE_REPORT_SETTINGS_STORE_DEFAULT);
};
