import { ReportType } from '../../enums/ReportType';
import { SortDirection } from '../../enums/SortDirection';

export interface IExport {
  id: string;
  destination: ExportDestination;
  reportType: ReportType;
  status: ExportStatus;
  downloadUrl: string;
}

export interface IExportStore {
  exports: IExport[];
  exportStatusIntervals: Record<string, NodeJS.Timeout>;
  exportError?: boolean;
}

export interface IExportRequest {
  destination: ExportDestination;
  googleDriveToken?: string;
  report: IExportReportParams;
}

export interface IPaginationState {
  index: number;
  size: number;
}

interface IColumnFieldFilter {
  field: string;
  operator: string;
  value: string;
}

export interface IColumnFilter {
  logic: string;
  filters: [] | IColumnFieldFilter[];
}

export interface IExportReportParams {
  type: ReportType;
  startDate: string;
  endDate: string;
  userType: string;
  userMode?: string;
  userId: string | number;
  limitToPage?: IPaginationState;
  sortDirection?: SortDirection.Ascending | SortDirection.Descending;
  sortField?: string;
  filter?: IColumnFilter;
  productivity?: string;
  groupBy?: string;
  detail?: ExportDetail;
  hasDataForTable?: boolean;
  shiftStart?: string;
  shiftEnd?: string;
  excludeColumns?: string[];
}

export interface IExportMenuOption {
  key: string;
  label: string;
  id: (reportType: ReportType) => string;
  icon?: JSX.Element;
  subItems?: IExportMenuOption[];
  visible?: string;
  exportType?: string;
}

//enums
export enum ExportStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  Canceled = 'Cancelled'
}

export enum ExportSize {
  SmallBatch = '10,000',
  LargeBatch = '50,000'
}

export enum ExportDetail {
  Only = 'topLevelOnly',
  Title = 'byTitle',
  SubPage = 'bySubpage',
  User = 'byUser'
}

export enum ExportPage {
  Current = 'currentPage',
  All = 'allPages'
}

export enum ExportDestination {
  GCS = 'Gcs',
  GoogleDrive = 'GoogleDrive'
}

export enum ExportValidationResult {
  Valid = 'valid',
  InvalidExport = 'invalid_export',
  InvalidProp = 'invalid_prop',
  InvalidRequiredProp = 'invalid_required_prop',
  InvalidOptionalProp = 'invalid_optional_prop',
  InvalidArrayOfExports = 'invalid_array_of_exports',
  InvalidExportInArray = 'invalid_export_in_array',
  InvalidReportPayload = 'invalid_report_payload',
  InvalidReportNoData = 'invalid_report_no_data',
  InvalidDestination = 'invalid_destination'
}
