import viewTemplate from 'views/widgets/dashboard/topSites.html?raw';
import { profileStore } from '../../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('topSites', topSites);

function topSites() {
    return {
        restrict: 'E',
        scope: {
            parameters: '=',
            forceTitle: '@',
            productivity: '@',
            shortLabelsFilter: '=',
            printPage: '='
        },
        template: viewTemplate,
        controller: TopSitesCtrl
    };
}

TopSitesCtrl.$inject = [
    '$scope',
    '$state',
    'chartService',
    'dashboardApiService',
    'atDashboardWidgetService',
    'trackEventService',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'localStorageService'
];

function TopSitesCtrl(
    $scope,
    $state,
    chartService,
    dashboardApiService,
    atDashboardWidgetService,
    trackEventService,
    templateServiceFunctions,
    browserServiceFunctions,
    localStorageService
) {
    $scope.topSitesOptions = $scope.productivity || 'All';
    $scope.widgetTitle = $scope.forceTitle || 'Top Websites';
    var { username } = profileStore.getState().profile;

    if (!$scope.printPage) {
        localStorageService.set('topSitesDashboardWidgetFilter-' + username, $scope.topSitesOptions);
    }

    function formatUrl(url) {
        return url.replace(/^https?:\/\/(www\.)?/, '');
    }

    var bindWidget = function () {
        $scope.topSitesChartConfig = {
            getData: function () {
                return dashboardApiService.getSites($scope.parameters, $scope.topSitesOptions);
            },
            buildChart: function (data) {
                return chartService.buildPieChart({
                    hueOption: $scope.topSitesOptions,
                    huesTable: {
                        Productive: 'productive',
                        Unproductive: 'unproductive',
                        Undefined: 'undefined',
                        All: 'multiExtended'
                    },
                    field: 'seconds',
                    categoryField: 'url',
                    showTooltip: !browserServiceFunctions.isMobileAgent(),
                    model: {
                        tooltip: function () {
                            return (
                                templateServiceFunctions.friendlyViewTimeFormat(this.seconds) +
                                ' — ' +
                                formatUrl(this.url)
                            );
                        }
                    },
                    legendTemplate: $scope.shortLabelsFilter(36, 'text', formatUrl),
                    data: data,
                    legendItemClick: function (e) {
                        trackEventService.track('ts-legend-' + (e.pointIndex + 1));
                    },
                    seriesClick: function (e) {
                        if (e.category === 'Other' || browserServiceFunctions.isMobileAgent()) {
                            return;
                        }

                        trackEventService.track('ts-graph');

                        if ($scope.topSitesOptions === 'Undefined') {
                            $state.go('app.settings.productivity', {
                                site: encodeURIComponent(e.category)
                            });
                        } else {
                            $state.go('app.reports.topwebsites', {
                                site: e.dataItem.id
                            });
                        }
                    }
                });
            }
        };
    };

    function handleFilterChange(selection) {
        if (!$scope.printPage) {
            localStorageService.set('topSitesDashboardWidgetFilter-' + username, selection.text);
        }

        $scope.topSitesOptions = selection.text;
        bindWidget();
    }

    $scope.filterButtonOptions = {
        type: 'filter',
        dynamicLabel: true,
        heapId: 'id_c231e72f-ab6e-487d-b2d1-20968ea17534',
        selectionList: [
            {
                heapId: 'id_958f453d-53bb-453d-910b-c43d2667678b',
                text: 'All',
                value: 'All'
            },
            {
                heapId: 'id_7514e312-5dde-415a-9533-d68e697f7625',
                text: 'Productive',
                value: 'Productive'
            },
            {
                heapId: 'id_c7a50db5-c674-44bc-8b4a-97d264b76848',
                text: 'Unproductive',
                value: 'Unproductive'
            },
            {
                heapId: 'id_24eacd96-833d-4c25-99b1-d53ae4d37ba5',
                text: 'Undefined',
                value: 'Undefined'
            }
        ],
        onChange: handleFilterChange
    };

    atDashboardWidgetService.registerWidget('topSites', 6, bindWidget);
}
