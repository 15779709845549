import React, {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useState
} from 'react';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ICoreCategory } from '../../models';
import { DialogContentStyled } from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import Radio from '@mui/material/Radio';
import BackButtonBox from './BackButtonBox';
import { MANUAL, PRESET } from '../../utils/TimeOnTask.view.utils';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onBackToStep1: () => void;
  onGoToStep3: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
};

export const Step2Dialog = (props: ComponentProps) => {
  const { open, onClose, onGoToStep3, coreCategoryRef, onBackToStep1 } = props;

  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (open && coreCategoryRef.current.selectionMode) {
      setSelected(coreCategoryRef.current.selectionMode);
    }
  }, [coreCategoryRef, open]);

  const close = () => {
    setSelected(null);
    onClose();
  };

  const backToStep1 = () => {
    setSelected(null);
    onBackToStep1();
  };

  const goToStep3 = () => {
    setSelected(null);
    onGoToStep3();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (coreCategoryRef.current.selectionMode !== val) {
      coreCategoryRef.current.categories = [];
      coreCategoryRef.current.role = null;
    }
    setSelected(val);
    coreCategoryRef.current.selectionMode = val;
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <BackButtonBox onClick={backToStep1} />
        <Box width={570} maxHeight={530}>
          <DialogTitle>
            Step 2 of 4: Define Core Categories for{' '}
            {coreCategoryRef.current.groupName}
          </DialogTitle>
          <DialogContentStyled>
            <RadioGroup value={selected} onChange={handleChange}>
              <FormControlLabel
                value={PRESET}
                control={<Radio />}
                label="Start from a role-based template"
              />
              <FormControlLabel
                value={MANUAL}
                control={<Radio />}
                label="Manually select categories"
              />
            </RadioGroup>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={() => goToStep3()}
              disabled={!selected}
              confirmText={'Go to step 3 of 4'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
