import InsightsPromoService from '../../../../_app/insights/insightsPromoService';
import { INSIGHTS_UPGRADE_PLAN_LABEL } from '../../../../_reactivtrak/src/common/constants/plans';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').controller('coachingPromoCtrl', CoachingPromoCtrl);

CoachingPromoCtrl.$inject = [
    '$scope',
    'authorizationService',
    'envService',
    'envConfig',
    'notificationService',
    'utmService'
];

function CoachingPromoCtrl($scope, authorizationService, envService, envConfig, notificationService, utmService) {
    const accountSettings = getAccountSettings();
    const insightsPromoService = new InsightsPromoService(
        envService.getMarketoConfig(),
        envConfig,
        accountSettings,
        notificationService,
        utmService,
        authorizationService
    );

    $scope.hasRoute = function (route) {
        return authorizationService.hasRouteByName(route);
    };

    $scope.requestDemo = function () {
        insightsPromoService.requestFormCoaching('demoRequestFormId', 'Demo');
    };

    $scope.requestTrial = function () {
        insightsPromoService.requestFormCoaching('trialRequestFormId', 'Trial');
    };

    $scope.planLabel = INSIGHTS_UPGRADE_PLAN_LABEL;
}
