import { ApiRoutes } from '../constants/routesApi';
import { fetchAdminData, fetchData } from '../helpers';
import {
  IDateSettingsStore,
  IShowLocalTimezoneDto,
  ITimezoneAPIDto
} from '../models/IDateSettingsStore';
import { postLoginStore } from '../stores/postLoginStore';

const mapSettings = (
  timezoneSettings: ITimezoneAPIDto,
  showLocalTimezone: IShowLocalTimezoneDto,
  timezoneKey: string
): IDateSettingsStore => {
  return {
    timezoneLabel: timezoneSettings.timeZone,
    timezoneKey: timezoneKey,
    isDateFormatDDMM: timezoneSettings.isDateFormatDDMM,
    isTimeFormat24Hour: timezoneSettings.isTimeFormat24Hour,
    showLocalTimezone: showLocalTimezone.showLocalTimezone
  };
};

export const fetchSettings = async (): Promise<IDateSettingsStore> => {
  const timezoneSettings = await fetchData<ITimezoneAPIDto>({
    path: ApiRoutes.settings.timezone
  });
  const showLocalTimezone = await fetchAdminData<IShowLocalTimezoneDto>({
    path: ApiRoutes.settings.showLocalTimezone
  });
  const { currentIanaTimeZone } = await postLoginStore.getState().fetch();
  return mapSettings(timezoneSettings, showLocalTimezone, currentIanaTimeZone);
};
