import { Role } from '../../../../../enums';
import { FeatureFlag } from '../../../../../enums/FeatureFlag';
import { IRoute } from '../../../../../models/IRoute';
import { cartTemplate as angularJsTemplate } from '../../templates';

export const appAccountUpgrade: IRoute = {
  name: 'app.account.upgrade',
  stateDefinition: {
    url: '/account/upgrade?addon',
    template: angularJsTemplate,
    controller: 'CartCtrl',
    data: { pageTitle: 'Upgrades', category: 'Subscription' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [],
    excludedFeatureFlags: [FeatureFlag.RestrictUpgrade]
  }
};

export default appAccountUpgrade;
