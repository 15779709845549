import { useEffect, useState } from 'react';
import { IAgentSearchState } from '../models/IAgentSearchState';
import { UserIdentityGetProps } from '../../common/models/UserIdentityGetProps';
import { SortDirection } from '../../common/enums';
import { fetchIdentityData } from '../../common/helpers';
import { IAgentSearch, IAgentSearchResultsDto } from '../models/IAgentSearch';
import { mapToAgentResults } from '../utils/AgentMappers';
import { PageDirection } from '../models/PageDirection';

export const useAgentSearchState = (): IAgentSearchState => {
  const [isLoadingAgents, setIsLoadingAgents] = useState<boolean>(false);
  const [agents, setAgents] = useState<IAgentSearch[]>([]);
  const [openAssignAgentModal, setOpenAssignAgentModal] =
    useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [agentsSelected, setAgentsSelected] = useState<IAgentSearch[]>([]);
  const [agentFilter, setAgentFilter] = useState<string>(null);
  const [sortBy, setSortBy] = useState<string>('lastLog');
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Descending
  );
  const [cursorStack, setCursorStack] = useState<string[]>([]);
  const [currentCursor, setCurrentCursor] = useState<string>(null);
  const [pageSize, setPageSize] = useState<string>('500');
  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(false);
  const [agentSearchQueryString, setAgentSearchQueryString] =
    useState<string>('');

  const initializeAgents = async () => {
    setSearchTerm(null);
    setAgentFilter(null);
    await updateAgents(null, null, sortBy, sortDirection, null, pageSize);
  };

  const resetAgentsSelected = () => {
    setAgentsSelected([]);
  };

  const updateAgents = async (
    tempSearchTerm: string,
    tempAgentFilter: string,
    tempSortBy: string,
    tempSortDirection: SortDirection,
    tempCursor: string,
    tempPageSize: string
  ) => {
    const getProps = {
      pageSize: tempPageSize,
      searchType: 'agent',
      sortBy: tempSortBy,
      sortDirection: tempSortDirection,
      searchTerm: tempSearchTerm,
      cursor: tempCursor,
      filter: tempAgentFilter
    };
    setAgentSearchQueryString(getIdentityListQueryString(getProps));
  };

  const onSetSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCursorStack([]);
    setCurrentCursor(null);
    updateAgents(
      searchTerm,
      agentFilter,
      sortBy,
      sortDirection,
      currentCursor,
      pageSize
    );
  };

  const getIdentityListQueryString = (props: UserIdentityGetProps): string => {
    let orderDir = null;
    if (props.sortBy) {
      orderDir =
        props.sortDirection == SortDirection.Ascending ? 'asc' : 'desc';
    }
    const querystring =
      `pagesize=${props.pageSize}` +
      `${props.sortDirection && props.sortBy ? '&sort=' + orderDir : ''}` +
      `${props.cursor ? '&cursor=' + encodeURIComponent(props.cursor) : ''}` +
      `${props.sortBy ? '&sortOn=' + props.sortBy : ''}` +
      `${
        props.searchTerm ? '&term=' + encodeURIComponent(props.searchTerm) : ''
      }` +
      `${props.filter ? '&filter=' + props.filter : ''}`;
    return querystring;
  };

  useEffect(() => {
    async function getAgentList() {
      try {
        setIsLoadingAgents(true);
        const fullPath = `/identity/v1/agents?${agentSearchQueryString}`;
        const dtoAgents = await fetchIdentityData<IAgentSearchResultsDto>({
          path: fullPath
        });
        const agentResults = mapToAgentResults(dtoAgents);
        setAgents(agentResults.results);
        if (
          agentResults.results &&
          agentResults?.results.length > 0 &&
          agentResults?.cursor
        ) {
          insertCursorIntoStack(agentResults.cursor);
        }
      } finally {
        setIsLoadingAgents(false);
      }
    }
    if (openAssignAgentModal) {
      getAgentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentSearchQueryString, openAssignAgentModal]);

  const setAgentsSelectedFromAgents = (
    agentArray: IAgentSearch[]
  ): IAgentSearch[] => {
    const selectedAgents = agentArray?.filter((agent) => agent.selected);
    //remove the selected from the agents
    selectedAgents.forEach((agent) => {
      agent.selected = false;
    });
    setAgentsSelected(selectedAgents);
    return selectedAgents;
  };

  const onSetAgentFilter = (filter: string) => {
    setAgentFilter(filter);
    setCursorStack([]);
    setCurrentCursor(null);
    updateAgents(searchTerm, filter, sortBy, sortDirection, null, pageSize);
  };

  const onSetAgentSort = (sortBy: string, direction: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(direction);
    setCursorStack([]);
    setCurrentCursor(null);
    updateAgents(searchTerm, agentFilter, sortBy, direction, null, pageSize);
  };

  const onAgentsChanged = (newAgents: IAgentSearch[]) => {
    setAgents(newAgents);
  };

  const onChangePageSize = (newPageSize: string) => {
    setCursorStack([]);
    setCurrentCursor(null);
    setPageSize(newPageSize);
    updateAgents(
      searchTerm,
      agentFilter,
      sortBy,
      sortDirection,
      null,
      newPageSize
    );
  };

  const onChangePage = (nextPage) => {
    if (nextPage == PageDirection.Previous && cursorStack.length > 0) {
      //clear the next page cursor
      cursorStack.pop();
      if (cursorStack.length > 0) {
        //clear current cursor, as it will get added on with api call
        cursorStack.pop();
      }
      setCursorStack(cursorStack);
    }
    let tempCursor = null;
    if (cursorStack.length > 0) {
      tempCursor = cursorStack[cursorStack.length - 1];
    }
    setCurrentCursor(tempCursor);
    updateAgents(
      searchTerm,
      agentFilter,
      sortBy,
      sortDirection,
      tempCursor,
      pageSize
    );
  };

  const insertCursorIntoStack = (nextCursor: string) => {
    if (cursorStack.length > 0) {
      //don't add the same cursor twice
      if (cursorStack[cursorStack.length - 1] !== nextCursor) {
        const tmpCursorStack = Object.assign([], cursorStack);
        tmpCursorStack.push(nextCursor);
        setCursorStack(tmpCursorStack);
      }
    } else {
      setCursorStack([nextCursor]);
    }
  };

  useEffect(() => {
    const pageSizeInt = parseInt(pageSize);
    const disableNextPage =
      cursorStack?.length == 0 || agents.length < pageSizeInt;
    setNextPageDisabled(disableNextPage);
  }, [currentCursor, cursorStack, pageSize, agents]);

  return {
    initializeAgents,
    openAssignAgentModal,
    isLoadingAgents,
    agents,
    setAgentsSelectedFromAgents,
    setOpenAssignAgentModal,
    agentsSelected,
    searchTerm,
    onAgentsChanged,
    agentFilter,
    onSetAgentFilter,
    sortBy,
    onSetAgentSort,
    sortDirection,
    cursorStack,
    onChangePageSize,
    onChangePage,
    currentCursor,
    pageSize,
    nextPageDisabled,
    onSetSearchTerm,
    resetAgentsSelected
  };
};
