import React, { useEffect } from 'react';
import { SortDirection } from '../../common/enums';
import { chromebookUsersService, useChromebookUsersState } from '../services';
import { useNotifications } from '../../common/services/Notifications';

import { useChromebookUsersColumns } from '../utils/useChromebookUsersColumns';
import { Box, Grid } from '@mui/material';
 import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
 import {
   NoResultsContainerSC,
   NoResultsLabelSC
 } from '../../common/components/NoResults/BaseNoResults.styles';
 import searching from '../../../../app/images/no_results.svg';
 import AddChromebookUserButton from '../components/AddChromebookUserButton';
 import DeleteChromebookUsersButton from '../components/DeleteChromebookUsersButton';
 import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';

 const ChromebookUsers = () => {
   const service = chromebookUsersService();
   const chromebookUsersState = useChromebookUsersState({ service });
   const notificationService = useNotifications();

   const {
     cbUsers,
     isLoading,
     isDisabled,
     cbNotification,
     setCbNotification,
     init,
     addCbUser,
     inviteCbUser,
     deleteCbUsers,
     setSelectedChromebookUsers,
     setSortedChromebookUsers
   } = chromebookUsersState;

   const columns = useChromebookUsersColumns({
     handleResendInvite: inviteCbUser,
     handleCancelInvite: deleteCbUsers,
     isDisabled: isDisabled.current
   });

   useEffect(() => {
     init();
   }, [init]);

   useEffect(() => {
     if (cbNotification?.msg && cbNotification?.type) {
       notificationService[cbNotification.type](cbNotification.msg);
       setCbNotification(undefined);
     }
   }, [
     notificationService,
     cbNotification?.msg,
     cbNotification?.type,
     setCbNotification
   ]);

   const selectedUsers = cbUsers?.filter((cbu) => cbu.selected);
   const deletedUsers = selectedUsers?.map((du) => du.user);

   return (
     <Grid container spacing={3}>
       <Grid item xs={12}>
         <Box sx={{ marginBottom: '10px' }}>
           <AddChromebookUserButton
             onSubmit={addCbUser}
             isDisabled={isDisabled.current}
           />
         </Box>
         <Box sx={{ marginBottom: '10px' }}>
           <DeleteChromebookUsersButton
             users={deletedUsers}
             isDisabled={isDisabled?.current || !selectedUsers?.length}
             onDelete={deleteCbUsers}
           />
         </Box>
         {!isLoading && !cbUsers?.length ? (
           <NoResultsContainerSC>
             <img src={searching} alt="search completed" />
             <NoResultsLabelSC variant="body1">
               Uh oh! No results were found.
             </NoResultsLabelSC>
           </NoResultsContainerSC>
         ) : (
           <CustomGrid
             height={'65vh'}
             isVirtualized={true}
             isLoading={isLoading}
             data={cbUsers}
             columns={columns}
             uniqueRowProp="user"
             initialSortField="user"
             initialSortDirection={SortDirection.Ascending}
             hasBulkSelection
             onSelectAllClick={setSelectedChromebookUsers}
             onSortOrder={setSortedChromebookUsers}
             featureDisabled={isDisabled.current}
           />
         )}
       </Grid>
     </Grid>
   );
 };

export default ChromebookUsers;

export const ChromebookUsersComponent = () => (
  <ReactivTrakComponentWrapper>
    <ChromebookUsers />
  </ReactivTrakComponentWrapper>
);
