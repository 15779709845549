import { ABBREVIATED_WEEKDAYS, FULL_WEEKDAYS } from '../../constants/weekdays';
import { DayAbbreviation, DayName } from './enums/dayName';

export const getAbbreviatedWeekday = (utcDate: Date): DayAbbreviation =>
  ABBREVIATED_WEEKDAYS[utcDate?.getUTCDay()];

/**
 * Returns a string of the weekday for the provided UTC date.
 */
export const getFullWeekday = (utcDate: Date): DayName =>
  FULL_WEEKDAYS[utcDate.getUTCDay()];
