import { useEffect, useState } from 'react';

import {
  find,
  isExcludedPage,
  menuItemsStore,
} from '../navigationStore';

import { setCoachingNotificationLastSeenWeek } from '../../../services/Navigation/Coaching';
import { useFavoritesStore } from '../favoritesStore';
import { IconResolver } from './IconResolver';
import { NavigationFeatureBadge } from './NavigationFeatureBadge';
import { MenuItemLabel } from './SideBar.styles';
import { FavoritesStar } from './FavoritesStar';
import { ExpandIcon } from './ExpandIcon';
import { useSideBarNavigation } from '../../../hooks/useSideBarNavigation';
import { handleStarToggle } from './handleStarToggle';
import { ActivtrakAcademyNavigationItem } from './ActivTrakAcademyNavigationIItem';
import { useUIRouterState } from '../../../hooks/useUIRouterHistory';
import { IMenuItem } from '../model';

// TODO: Experimental - still evaluating...
const _traverse = (items: IMenuItem[], callback: (item:IMenuItem) => void): void => {
  items.forEach(item => {
    callback(item);
    if (item.submenus) {
      _traverse(item.submenus, callback);
    }
  });
};

export const NavigationItem = (props) => {

  const { item, index } = props;

  const [isActive, setIsActive] = useState(false);
  const [showStar, setShowStar] = useState(false);


  const route = useUIRouterState();
  const { navigate } = useSideBarNavigation();

  const isInFavorites = useFavoritesStore((s) => s.isInFavorites);
  const favorites = useFavoritesStore((s) => s.favorites);

  const isFavoriteNavigationEnabled = useFavoritesStore(
    (s) => s.isFavoriteNavigationEnabled
  );



  useEffect(() => {
    const shouldShowStar = isInFavorites({ id: item.id });
    setShowStar(() => shouldShowStar);
  }, [isInFavorites, item.id, favorites]);

  useEffect(() => {
    setIsActive(() => {
      
      if (!route?.name) {
        return false;
      }

      const active = route.name === item.action?.options?.ref;
      //If coach item click, set coach notification to read if exists
      if (
        active &&
        route.name === 'app.coaching' &&
        item.hasCoachingNotifications
      ) {
        item.hasCoachingNotifications = false;
        setCoachingNotificationLastSeenWeek();
      }
      return active;
    });
  }, [route.name, item]);

  const handleNavigate = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any
  ): Promise<void> => {
    e.preventDefault();
    await navigate(item);
  };

  const handleToggle = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any
  ): void => {
    e.preventDefault();

    const { menuItems } = menuItemsStore.getState();    
    const current = find(menuItems, (i) => i.id === item.id);
    const submenuOpen = current.submenuOpen;

    //collapse other navitems
    _traverse(menuItems, (i) => {
      if (i.id !== item.parentId) {
        i.submenuOpen = false;
      }
    });

    if (current?.submenus) {
      current.submenuOpen = !submenuOpen;
    }

    menuItemsStore.setState({ menuItems: [...menuItems] });

    setIsActive(() => {
      const active = route.name === item.action?.options?.ref;
      return active;
    });
  };


  return (
    <li id={`id_${item.id}`} key={index}>
      <div className="at-menu-item-container">
        <div
          className={`at-menu-item-row ${
            (item.submenus && item.submenuOpen) ? 'expanded' : 'collapsed'

            // TODO: check activeSubmenu - does not seem to be working
          } ${item.activeSubmenu ? 'active-submenu' : ''}`}
        >
          <a
            className={`at-flex-container at-menu-item at-pointer ${
              isActive ? 'focus' : ''
            }`}
            onClick={(e) => {
              const navigate = ['internal', 'external', 'event'];
              navigate.includes(item.action.type)
                ? handleNavigate(e, item)
                : handleToggle(e, item);
            }}
          >
            <div className="at-flex-child at-menu-item-icon">
              {/* FIXME: some child routes have icons */}
              {item.parentId === undefined && <IconResolver icon={item.icon} />}

              {item.hasCoachingNotifications && (
                <div className="at-menu-item-coaching-notification"></div>
              )}

              <NavigationFeatureBadge {...item} />
            </div>
            <MenuItemLabel level={item.level} className="at-flex-child">
              {item.label}
              {!item.submenus &&
                !isExcludedPage(item.label) &&
                isFavoriteNavigationEnabled && (
                  <FavoritesStar
                    id={`nav_star_${item.id}`}
                    favorited={showStar}
                    onClick={(e) => handleStarToggle(e, item)}
                  />
                )}
            </MenuItemLabel>

            {item.submenus && (
              <div className="at-flex-child at-menu-item-dropdown-control at-menu-item-icon">
                <ExpandIcon subMenuOpen={item.submenuOpen} />
              </div>
            )}
          </a>
        </div>

        {/* submenu */}
        {item.submenus && item.submenuOpen && (
          <ul className="at-submenu-items">
            {item.submenus.map((subitem, subindex) => {

              if (subitem.id === '1079bad9-6917-4e0f-9846-e0f3a4a0a8e0') {
                return (
                  <ActivtrakAcademyNavigationItem
                    key={subindex}
                    item={subitem}
                    index={subindex}
                    isInFavorites={isInFavorites}
                  />
                );
              }

              return (
                <NavigationItem
                  key={subindex}
                  item={subitem}
                  index={subindex}
                />
              );
            })}
          </ul>
        )}
      </div>
    </li>
  );
};


