import { postData } from '../helpers';

import { LoadApplication } from '../services/Application/AppLoad';
import { getUserToken, setUserToken } from '../helpers/authentication/useUserTokenStore';
import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { rewire } from '../../../../_entry/rewire';

type UserBackupToken =
  | 'invalid'
  | (string & NonNullable<unknown>);

type UserBackupTokenStore = {
  token: UserBackupToken
};

const userBackupTokenStore = createStore<UserBackupTokenStore>()(
  persist(
    () => ({
      token: 'invalid'
    })
    , { name: 'activtrak-user-backup-token'}
  )
);


const supportBackupTokenStore = createStore<UserBackupTokenStore>()(
  persist(
    () => ({
      token: 'invalid'
    })
    , { name: 'activtrak-support-backup-token'}
  )
);

interface IChildAccountToken {
  token: string;
  account: {
    email: string;
    firstName?: string;
    lastName?: string;
    organization?: string;
    licenseCount?: number;
    isConfigured: boolean;
    referrer?: string;
    accountWizard?: string;
  };
}


export const restore = async (url?:string) => {

  const msp = userBackupTokenStore.getState().token;

  if (msp === 'invalid') {
    return;
  }

  // Sequence must keep this order:
  // Invalidate - restore - load-application
  setUserToken('invalid');
  setUserToken(msp);

  await LoadApplication();

  const msp_initial = userBackupTokenStore.getInitialState();
  userBackupTokenStore.setState(msp_initial);

  if (url) {
    history.pushState(null, '', url);
  } else {
    history.pushState(null, '', '/');
  }

  rewire();
};

export const impersonate = (accountId: string) => async (url?:string) => {

  const token = getUserToken(); 

  const response = await postData<IChildAccountToken>({
    path: '/account/child/token',
    args: {
      accountId
    }
  });
  
  //impersonate
  userBackupTokenStore.setState({token})
  
  // Sequence must keep this order:
  // Invalidate - restore - load-application
  setUserToken('invalid');
  setUserToken(response.token);

  await LoadApplication();
  
  if (url) {
    history.pushState(null, '', url);
  } else {
    history.pushState(null, '', '/');
  }

  rewire();
  return response;
};

export const useImpersonate = (
  accountId?: string
):
  | [(url?:string) => Promise<void>]
  | [(url?:string) => Promise<void>, (url?:string) => Promise<IChildAccountToken>] => {
  if (!accountId) {
    return [restore];
  }

  return [restore, impersonate(accountId)];
};

export const isImpersonating = () => {
  const msp = userBackupTokenStore.getState().token;

  return msp !== 'invalid';
}

export const invalidateBackupToken = () => {
  userBackupTokenStore.setState({ token: 'invalid' });
};

export const setBackupToken = (token: string) => {
  userBackupTokenStore.setState({ token });
};

export const getBackupToken = () => {
  const msp = userBackupTokenStore.getState().token;
  return msp;
};

// TODO: Relocate
export const setSupportBackupToken = (token: string) => {
  supportBackupTokenStore.setState({ token });
};

export const getSupportBackupToken = () => {
  const support = supportBackupTokenStore.getState().token;
  return support;
};
