import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  agentCommand: any;
  computer: string;
  domain: string;
};

export const DiagnosticsModal = (props: ComponentProps) => {
  const { open, onClose, agentCommand, computer, domain } = props;

  const close = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth={'md'}>
      <DialogTitle id="agent-diagnostics-dialog-title">Diagnostics</DialogTitle>
      <DialogContent id="agent-diagnostics-dialog-content">
        <div>
          <p>
            Executed on {computer} {domain ? '(' + domain + ')' : ''} at{' '}
            {agentCommand?.status?.executionTime &&
              formatDateTime(agentCommand?.status?.executionTime)}
          </p>
          <pre>
            {agentCommand?.status?.parsedStatusMessage
              ? JSON.stringify(
                  agentCommand?.status?.parsedStatusMessage,
                  null,
                  2
                )
              : ''}
          </pre>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
