import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useNotifications } from '../../common/services/Notifications';
import { SupportPortalService, useSupportPortalState } from '../hooks';
import { displayMessage, consoleMessage } from '../constants';
import { ISetDataCollectionPayloadDto } from '../models/IPayloadDto';

interface IDataCollectionToggleProps {
  value: boolean;
  accountId: string;
  setDetailsModalClose?: () => void;
  token: string;
}

export const DataCollectionToggle = (props: IDataCollectionToggleProps) => {
  const { value, accountId, token, setDetailsModalClose } = props;

  const service = SupportPortalService();
  const { setDataCollectionSetting } = useSupportPortalState({ service });

  const notificationService = useNotifications();
  const swapValue = value ? 'off' : 'on';

  const payload: ISetDataCollectionPayloadDto = {
    accountId: accountId,
    dataCollection: !value
  };

  const toggle = async () => {
    try {
      await setDataCollectionSetting(payload, token);
      notificationService.success(
        displayMessage.dataCollection.success(swapValue)
      );
    } catch (error) {
      notificationService.error(displayMessage.dataCollection.error);
      console.error(
        `ActivTrak Error: ${
          error?.message || consoleMessage.dataCollectionError.unknown
        }`
      );
    } finally {
      setDetailsModalClose();
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onClick={() => toggle()}
          color="primary"
          name="dataCollection"
        />
      }
      label="Data Collection"
    />
  );
};
