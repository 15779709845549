import { createStore } from 'zustand';
import { IRealtimeSettingsStore } from './realtimeSettingsStore.models';
import { fetchSettings } from './realtimeSettingsStore.utils';
import { REALTIME_SETTINGS_STORE_DEFAULT } from './realtimeSettingsStore.constants';

export const realtimeSettingsStore = createStore<IRealtimeSettingsStore>(
  () => REALTIME_SETTINGS_STORE_DEFAULT
);

/**
 * Fetches the export from the API and sets it in the export store.
 */
export const fetchRealtimeSettings =
  async (): Promise<IRealtimeSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = realtimeSettingsStore.getState();
    realtimeSettingsStore.setState({ ...currentSettings, ...response });
    return realtimeSettingsStore.getState();
  };

/**
 * Returns the current export store state.
 */
export const getRealtimeSettingsStore = (): IRealtimeSettingsStore =>
  realtimeSettingsStore.getState();

/**
 * Resets the export store to its default state.
 */
export const resetRealtimeSettingsStore = (): void =>
  realtimeSettingsStore.setState(REALTIME_SETTINGS_STORE_DEFAULT);
