import { getTimeFormat } from '../../../../_reactivtrak/src/common/utils/datetime/datetimeFormatStrings';
import messageModalTemplate from 'views/modals/messageModal.html?raw';
import { getDateSettingsStore } from '../../../../_reactivtrak/src/common/stores/dateSettingsStore';

angular.module('app').controller('SchedulingCtrl', SchedulingCtrl);

SchedulingCtrl.$inject = [
    '$scope',
    '$state',
    '$window',
    '$document',
    '$timeout',
    'schedulingService',
    'messagesService',
    'notificationService',
    'customUibModal',
    'authorizationService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'iconResolverServiceFunctions'
];

function SchedulingCtrl(
    $scope,
    $state,
    $window,
    $document,
    $timeout,
    schedulingService,
    msg,
    notificationService,
    customUibModal,
    authorizationService,
    atHelperFunctions,
    templateServiceFunctions,
    iconResolverServiceFunctions
) {
    var headerHeight = 220;
    var fixedHeight = 346;
    $scope.minUserHeight = 280;
    $scope.minScheduleHeight = $scope.minUserHeight + fixedHeight;
    $scope.scheduleLoading = true;
    var pageLoading = true;
    $scope.selectedSchedule = {
        schedule: {
            id: $state.params.selectedScheduleId
        }
    };
    var timeFormat = getTimeFormat().replace(':ss', '');

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.grid-tooltip'
    });

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.settings.scheduling');
    };

    var userDisplayTemplate = $window.kendo.template(function (data) {
        var type = data.userInfo.userType;
        var icon = iconResolverServiceFunctions.getUserTypeIcon(type);
        var userName = kendo.htmlEncode(data.userInfo.userName);
        return (
            '<span class="k-state-default text-nowrap">' + icon + '<span class="m-l-5">' + userName + '</span></span>'
        );
    });

    function resizeGrids() {
        $timeout(function () {
            if ($scope.scheduleGrid && typeof $scope.scheduleGrid.resizeGrid === 'function') {
                $scope.scheduleGrid.resizeGrid();
            }

            if ($scope.usersGrid && typeof $scope.usersGrid.resizeGrid === 'function') {
                $scope.usersGrid.resizeGrid();
            }
        });
    }

    $scope.usersGridHeight = function () {
        return headerHeight + ($scope.showSchedule() ? ($scope.minimizeSchedule ? 55 : fixedHeight) : 0);
    };

    $scope.minScheduleHeight = function () {
        return $scope.minUserHeight + ($scope.showSchedule() ? ($scope.minimizeSchedule ? 55 : fixedHeight) : 0);
    };

    $scope.scheduleGridHeight = function () {
        return headerHeight;
    };

    $scope.showSchedule = function () {
        return $scope.selectedSchedule && $scope.selectedSchedule.schedule.type !== 'AllSchedules' && !pageLoading;
    };

    $scope.toggleMinimizeSchedule = function () {
        $scope.minimizeSchedule = !$scope.minimizeSchedule;
        resizeGrids();
    };

    $scope.toggleMinimizeUsers = function () {
        $scope.minimizeUsers = !$scope.minimizeUsers;
        resizeGrids();
    };

    $scope.selectAllUsers = function (e) {
        userListDataSource.view().forEach(function (user) {
            user.selected = e.target.checked;
        });
    };

    var scheduleListDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                schedulingService.getAll().then(function (result) {
                    var allSchedules = {
                        schedule: {
                            name: 'All Schedules',
                            isDefault: false,
                            type: 'AllSchedules'
                        }
                    };

                    result.data.push(allSchedules);

                    options.success(result.data);
                });
            }
        },
        sort: [
            {
                field: 'schedule.name',
                dir: 'desc',
                compare: function (a, b) {
                    // All Schedules should be on top
                    if (a.schedule.type === 'AllSchedules') {
                        return 1;
                    } else if (b.schedule.type === 'AllSchedules') {
                        return -1;
                    }

                    // Then Default Schedule
                    if (a.schedule.isDefault) {
                        return 1;
                    } else if (b.schedule.isDefault) {
                        return -1;
                    }

                    // Last sort by name
                    var textA = a.schedule.name.toUpperCase();
                    var textB = b.schedule.name.toUpperCase();
                    return textA < textB ? 1 : textA > textB ? -1 : 0;
                }
            }
        ]
    });

    $scope.scheduleGridOptions = {
        dataSource: scheduleListDataSource,
        columns: [
            {
                title: msg.get('scheduleName'),
                field: 'schedule.name',
                template: kendo.template(function (item) {
                    return (
                        '<span' +
                        (item.schedule.type === 'AllSchedules' ? ' class="text-italic bold"' : '') +
                        '>{{dataItem.schedule.name}}</span>'
                    );
                }),
                attributes: {
                    class: 'text-nowrap'
                },
                filterable: templateServiceFunctions.createFilter('schedule.name', scheduleListDataSource)
            }
        ],
        filterable: {
            mode: 'row'
        },
        scrollable: {
            virtual: true
        },
        selectable: 'row',
        height: atHelperFunctions.getGridHeight($scope.scheduleGridHeight()),
        dataBound: function (e) {
            var grid = e.sender;
            var data = grid._data;
            var firstRow;
            var selectedRow;
            data.forEach(function (row, key) {
                if (key === 0) {
                    firstRow = row;
                } else if (!!$scope.selectedSchedule && $scope.selectedSchedule.schedule.id === row.schedule.id) {
                    selectedRow = row;
                }
            });

            if (!(!!selectedRow && !!selectedRow.uid)) {
                selectedRow = firstRow;
            }

            if (!!selectedRow && !!selectedRow.uid) {
                grid.select('tr[data-uid="' + selectedRow.uid + '"]');
                $scope.scheduleSelected(selectedRow);
            }

            pageLoading = false;
        }
    };

    var scheduleDropdownDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                schedulingService.getAll().then(function (result) {
                    options.success(result.data);
                });
            }
        },
        sort: [
            {
                field: 'schedule.name',
                dir: 'desc',
                compare: function (a, b) {
                    // Default Schedule Should be on top
                    if (a.schedule.isDefault) {
                        return 1;
                    } else if (b.schedule.isDefault) {
                        return -1;
                    }

                    // Last sort by name
                    var textA = a.schedule.name.toUpperCase();
                    var textB = b.schedule.name.toUpperCase();
                    return textA < textB ? 1 : textA > textB ? -1 : 0;
                }
            }
        ]
    });

    $scope.scheduleDropdownOptions = {
        dataSource: scheduleDropdownDataSource,
        dataTextField: 'schedule.name',
        dataValueField: 'schedule.id',
        optionLabel: 'Select Schedule',
        change: function () {
            $scope.scheduleDropdownValue = this.value();
        },
        select: function () {
            $document[0].activeElement.blur();
        }
    };

    var userListDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (options.data && options.data.cache) {
                    options.success(options.data.cache);
                } else {
                    schedulingService.getUserSchedules().then(function (result) {
                        options.success(result.data);
                    });
                }
            }
        },
        sort: [
            {
                field: 'userInfo.userName',
                dir: 'asc'
            }
        ]
    });
    $scope.userListDataSource = userListDataSource;

    $scope.usersGridOptions = {
        autoBind: false,
        dataSource: userListDataSource,
        columns: [
            {
                field: 'selected',
                title: ' ',
                width: '35px',
                filterable: false,
                headerAttributes: {
                    class: 'text-center'
                },
                headerTemplate:
                    '<div class="m-l-5 checkbox checkbox-sm"><input type="checkbox" id="selectbox-users-header" ng-click="selectAllUsers($event)" ng-model="$parent.allUsersSelected"/><label class="m-l-7-i m-t-6-i" for="selectbox-users-header"></label></div>',
                template:
                    '<div class="m-l-5 checkbox checkbox-sm"><input type="checkbox" at-multi-select-checkbox at-data-source="userListDataSource" at-item="dataItem" at-model-field="\'selected\'" id="selectbox-user-{{dataItem.uid}}" ng-model="dataItem.selected" /><label class="m-l-8-i m-b-m5-i" for="selectbox-user-{{dataItem.uid}}"></label></div>',
                attributes: {
                    class: 'text-center',
                    style: 'padding: 5px; overflow: visible;'
                },
                sortable: false
            },
            {
                field: 'userInfo.userName',
                template: userDisplayTemplate,
                title: msg.get('user'),
                attributes: {
                    class: 'text-nowrap grid-tooltip'
                },
                filterable: templateServiceFunctions.createFilter('userInfo.userName', userListDataSource)
            },
            {
                title: msg.get('domain'),
                field: 'userInfo.domain',
                filterable: templateServiceFunctions.createFilter('userInfo.domain', userListDataSource)
            },
            {
                title: msg.get('alias'),
                field: 'userInfo.tag',
                filterable: templateServiceFunctions.createFilter('userInfo.tag', userListDataSource)
            },
            {
                title: msg.get('schedule'),
                field: 'scheduleInfo.name',
                attributes: {
                    class: 'text-nowrap grid-tooltip'
                },
                filterable: false
            }
        ],
        filterable: {
            mode: 'row'
        },
        scrollable: {
            virtual: true
        },
        rowClass: 'scheduling-grid-row',
        height: atHelperFunctions.getGridHeight($scope.usersGridHeight())
    };

    $scope.isAnyUserSelected = function () {
        return userListDataSource.data().some(function (el) {
            return el.selected;
        });
    };

    $scope.createSchedule = function () {
        $state.go('app.settings.schedule', {
            id: 'new'
        });
    };

    $scope.editSchedule = function () {
        $state.go('app.settings.schedule', {
            id: $scope.selectedSchedule.schedule.id
        });
    };

    $scope.duplicateSchedule = function () {
        $state.go('app.settings.schedule', {
            id: 'duplicate',
            schedule: $scope.selectedSchedule.schedule
        });
    };

    $scope.deleteSchedule = function () {
        var modal = customUibModal.open({
            animation: false,
            template: messageModalTemplate,
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageTitle: msg.get('scheduleWillBeDeletedTitle'),
                    messageBody: msg.get('scheduleWillBeDeleted'),
                    confirmLabel: msg.get('delete')
                }
            }
        });

        modal.result.then(function () {
            schedulingService
                .deleteSchedule($scope.selectedSchedule.schedule.id)
                .then(function () {
                    scheduleListDataSource.read();
                    userListDataSource.read();
                    notificationService.showNotification(msg.get('scheduleDeleted'), 'success');
                    $scope.selectedSchedule = null;
                })
                .catch(function () {
                    scheduleListDataSource.read();
                    userListDataSource.read();
                    notificationService.showNotification(msg.get('errorDeletingSchedule'), 'danger');
                });
        });
    };

    $scope.moveSelectedUsers = function () {
        if (!$scope.isAnyUserSelected()) {
            return;
        }
        var userIds = [];
        userListDataSource.data().forEach(function (user) {
            if (user.selected) {
                userIds.push(user.userInfo.userId);
            }
        });
        schedulingService
            .addUsersToSchedule(userIds, $scope.scheduleDropdownValue)
            .then(function () {
                notificationService.showNotification('Users successfully moved.', 'success');
                scheduleListDataSource.read();
                userListDataSource.read();
            })
            .catch(function (error) {
                if (error && error.status === 500) {
                    notificationService.showNotification(error.data.message, 'danger', 0);
                }
            });
    };

    function calculateSegment(blockTime) {
        return blockTime.hour * 4 + Math.floor(blockTime.minute / 15);
    }

    function createTooltip(startTime, endTime, currentTime, isAlways) {
        var tt = '';

        if (currentTime) {
            tt += '<span>Current Time: ' + currentTime.format('dddd ' + timeFormat) + '</span>';
        }

        if (isAlways) {
            tt += currentTime ? '<br/>' : '';
            tt += '<span>Active: Always</span>';
        } else if (startTime && endTime) {
            tt += currentTime ? '<br/>' : '';
            tt +=
                '<span>Active: ' +
                moment(startTime.string, 'HH:mm:ss').format(timeFormat) +
                ' - ' +
                moment(endTime.string, 'HH:mm:ss').format(timeFormat) +
                '</span>';
        }

        return tt;
    }

    var currentTimeTimer;

    function startCurrentTimeUpdate() {
        $timeout.cancel(currentTimeTimer);
        currentTimeTimer = $timeout(refreshTimeSegment, 10000);
    }

    function refreshTimeSegment() {
        var { timezoneKey } = getDateSettingsStore();
        var currentTime = moment().tz(timezoneKey);
        var currentSegment = calculateSegment({
            hour: currentTime.hour(),
            minute: currentTime.minute()
        });
        $scope.dayRanges.forEach(function (day) {
            for (var i = 0; i < 96; i++) {
                var segment = day.segments[i];
                segment.isCurrentSegment = i === currentSegment && currentTime.format('dddd') === day.day;
                segment.tooltip = createTooltip(
                    segment.startTime,
                    segment.endTime,
                    segment.isCurrentSegment && currentTime,
                    segment.isAlways
                );
            }
        });

        startCurrentTimeUpdate();
    }

    function createDaySegments() {
        var schedule = $scope.selectedSchedule.schedule;
        if (schedule.type !== 'AllSchedules') {
            $scope.dayRanges = [
                {
                    day: 'Monday',
                    segments: []
                },
                {
                    day: 'Tuesday',
                    segments: []
                },
                {
                    day: 'Wednesday',
                    segments: []
                },
                {
                    day: 'Thursday',
                    segments: []
                },
                {
                    day: 'Friday',
                    segments: []
                },
                {
                    day: 'Saturday',
                    segments: []
                },
                {
                    day: 'Sunday',
                    segments: []
                }
            ];

            $scope.dayRanges.forEach(function (day) {
                var isAlways = schedule.type === 'AlwaysSchedule';
                for (var i = 0; i < 96; i++) {
                    var segment = {
                        active: isAlways,
                        isTimeStart: i % 4 === 0,
                        isStart: isAlways && i === 0,
                        isEnd: isAlways && i === 95,
                        isAlways: isAlways
                    };
                    day.segments.push(segment);
                }

                if (schedule.days) {
                    schedule.days[day.day.toLowerCase()].forEach(function (block) {
                        var startSegment = calculateSegment(block.start);
                        var endSegment = calculateSegment(block.end);
                        for (var i = startSegment; i < endSegment; i++) {
                            var segment = day.segments[i];
                            segment.active = true;
                            segment.isStart =
                                i === startSegment && (day.segments[i - 1] ? !day.segments[i - 1].isEnd : true);
                            segment.isEnd = i === endSegment - 1;
                            segment.startTime = block.start;
                            segment.endTime = block.end;

                            if (day.segments[i - 1]) {
                                day.segments[i - 1].isEnd = day.segments[i - 1].isEnd && i !== startSegment;
                            }
                        }
                    });
                }
            });
            refreshTimeSegment();
        } else {
            $timeout.cancel(currentTimeTimer);
        }
    }

    $scope.scheduleSelected = function (row) {
        var schedule = row.schedule;
        $scope.scheduleLoading = true;

        if (!schedule) {
            return;
        }

        $scope.selectedSchedule = row;
        resizeGrids();

        // Need to delay 1 cycle to update loading spinners.
        $timeout(function () {
            if (schedule.type === 'AllSchedules') {
                $scope.scheduleName = 'Select a schedule';
                userListDataSource.filter({});
            } else {
                $scope.scheduleName = 'Schedule: ' + schedule.name;
                userListDataSource.filter({
                    field: 'scheduleInfo.name',
                    operator: 'eq',
                    value: schedule.name
                });
            }

            createDaySegments();
            $scope.scheduleLoading = false;
        });
    };

    $scope.scheduleTimeHeaders = [];
    for (var i = 0; i < 12; i++) {
        var time = moment(i * 2 + 1, 'HH:mm');
        $scope.scheduleTimeHeaders.push(time.format(timeFormat));
    }

    $scope.$on('$destroy', function () {
        $timeout.cancel(currentTimeTimer);
    });
}
