import { IAccountSettingsDto } from '../../common/models';
import { ITokenDto } from '../models/IApiResponseDto';

export const isValidTokenDto = (tokenData: ITokenDto): boolean => {
  const isValid =
    typeof tokenData === 'object' &&
    tokenData !== null &&
    typeof tokenData.ccAccess === 'boolean' &&
    typeof tokenData.token === 'string' &&
    typeof tokenData.userName === 'string';

  return isValid;
};

export const isValidAccountCredentialsDto = (
  accountData: IAccountSettingsDto
): boolean => {
  const isValid =
    typeof accountData === 'object' &&
    accountData !== null &&
    typeof accountData.account === 'string' &&
    typeof accountData.companyName === 'string' &&
    typeof accountData.usedLicenses === 'number';

  return isValid;
};
