import React, { MouseEvent } from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/material';
import { useUIRouterHistory } from '../../hooks/useUIRouterHistory';

/**
 * Primary UI component for button linking
 */

interface LinkButtonProps extends ButtonProps {
  to?: string;
  params?: object;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { ...rest } = props;
  const history = useUIRouterHistory();

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }

    if (props.to) {
      history.push(props.to, props.params);
    }

  }

  return <MUIButton {...rest} onClick={(e) => handleOnClick(e)} />;
};

export default LinkButton;
