import React, { useEffect, useMemo, useState } from 'react';
import { smallFontSize } from '../../common/constants';
import { IRoleAccessViewModel } from '../../common/models';
import { useRoleAccess } from '../services';
import { GetFilterDataInitialData } from '../utils';
import { RoleAccessFilterTable } from './RoleAccessFilterTable';
import { Box, Checkbox } from '@mui/material';
import { getAccountSettings } from '../../common/stores/accountSettingsStore/accountSettingsStore';

type rowsProps = {
  resetDefault: boolean;
  setResetDefault: any;
};

const roleAccessDateFilterStyles = {
  footer: {
    fontSize: `${smallFontSize} !important`,
    lineHeight: '14px !important',
    pointerEvents: 'none'
  }
};

export const RoleAccessDateFilter = (props: rowsProps) => {
  const dataRetentionTerm = useMemo(() => {
    const {
      settings: { dataRetentionSettings }
    } = getAccountSettings();
    const term = dataRetentionSettings?.['term-months'] ?? null;
    return term ? parseInt(term) : null;
  }, []);

  const { resetDefault, setResetDefault } = props;

  const [dateFilters, setDateFilters] = useState<IRoleAccessViewModel[]>(
    GetFilterDataInitialData(dataRetentionTerm)
  );

  const { roleAccessService } = useRoleAccess();

  useEffect(() => {
    const getAllFilters = async () => {
      try {
        const filter = await roleAccessService.getAllFilters();
        setDateFilters(
          roleAccessService.mapFilterAccess(filter, dataRetentionTerm)
        );
      } catch (error) {
        //what should happen here?
      }
    };

    getAllFilters();
  }, [roleAccessService, dataRetentionTerm]);

  return (
    <>
      <Box minHeight="22px" fontWeight="bold">
        DATE FILTER OPTIONS
      </Box>
      <Box minHeight="35px" alignItems="top">
        Configure which date filter options are available for users to limit
        filters to shorter date ranges.
      </Box>
      <RoleAccessFilterTable
        rows={dateFilters}
        showLimitedDesc={false}
        resetDefault={resetDefault}
        setResetDefault={setResetDefault}
        dataRetentionTerm={dataRetentionTerm}
      />
      <Box display="flex" p={1} sx={roleAccessDateFilterStyles.footer}>
        <Box p={1} flexGrow={1}></Box>
        <Box p={1}>
          <Checkbox checked={true} color="primary" disabled />
          Always Access
        </Box>
        <Box p={1}>
          <Checkbox checked={true} color="primary" />
          Access
        </Box>
        <Box p={1}>
          <Checkbox checked={false} color="primary" />
          No Access
        </Box>
      </Box>
    </>
  );
};
