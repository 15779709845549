import { apiService } from '../../helpers';
import { getDefaultConfig } from '../../config';
import { IAccountSettingsDto } from '../../stores/accountSettingsStore/accountSettingsStore.models';

export const getAccountForSupport = async (
  token: string
): Promise<IAccountSettingsDto> => {
  const response = await apiService.get<IAccountSettingsDto>(
    '/api/v2/post_login',
    {
      ...getDefaultConfig(),
      headers: { Authorization: token },
      authorizationRequired: false
    }
  );
  return response?.data;
};
