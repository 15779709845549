import { createStore } from 'zustand';
import { decodeToken } from '../../../support-portal/utils';
import { IAuthorizations } from '../../models';

import { createAuthorizations } from '../../services/Authorization/AuthorizationFactory';
import { persist } from 'zustand/middleware';
import { resetStores } from './logout';

import { getAccountSettings, accountSettingsStore } from '../../stores/accountSettingsStore/accountSettingsStore';

type UserToken = 'invalid' | (string & NonNullable<unknown>);

type UserTokenStore = {
  token: UserToken;
};

type UserClaims = { type: string; value: string }[];
type UserClaimsResult = 'invalid' | UserClaims;

type UserRoles = 'invalid' | string[];

export const userTokenStore = createStore<UserTokenStore>()(
  persist(
    () => ({
      token: 'invalid'
    }),
    { name: 'activtrak-user-token' }
  )
);

export const getUserToken = (): UserToken => {
  const token = userTokenStore.getState().token;
  return token;
};

export const setUserToken = (token: UserToken) => {
  userTokenStore.setState({ token });
};

export const getUserClaims = (): UserClaimsResult => {
  const token = getUserToken();

  if (token === 'invalid') {
    return 'invalid';
  }

  const decoded = decodeToken(token);

  const claims:UserClaims = [];
  decoded.Claims?.forEach((claim: { Type: string; Value: string }) => {
    claims.push({ type: claim.Type, value: claim.Value });
  });

  return claims;
};

const _authorizationsStore = createStore<IAuthorizations>(() => ({
  roles: [],
  bundleFlags: [],
  featureFlags: [],
  privateMode: false
}));


export const getUserRoles = (): UserRoles => {
  const token = getUserToken();
  if (token === 'invalid') {
    return 'invalid';
  }

  const roles = _authorizationsStore.getState().roles;

  // TODO: Add invalid state here.
  return roles ?? 'invalid';
};

userTokenStore.subscribe((current, previous) => {
  if (current.token !== previous.token) {    
    if (current.token === 'invalid') {
      console.log('resetStores via token =>');

      resetStores();
    }    
  }
});

/**
 * Combine token claims and settings to build flags
 * TODO: cleanup
 * @param accountSettings AccountSettings DTO (post_login)
 * @returns 
 */
export const setAuthorizations = (accountSettings) => {
  const userClaims = getUserClaims();

  // Invalidate if claims are invalid
  if (userClaims === 'invalid') {
    const initial = _authorizationsStore.getInitialState();
    _authorizationsStore.setState(initial);
    return;
  }

  const authorizations = createAuthorizations(accountSettings, userClaims);
  _authorizationsStore.setState(authorizations);
};

accountSettingsStore.subscribe((current, previous) => {
  if (current.accountSettings.account !== previous.accountSettings.account) {
    const accountSettings = getAccountSettings();

    setAuthorizations(accountSettings);
  }
});


