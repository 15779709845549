import { gray1, gray5, gray7 } from '../../../common/constants';
import { durationFormat } from '../../../common/utils/datetime/time';
import { getStringDurationFormat } from '../../../dashboard/utils';
import { getTotalPercent } from '../../common/utils/reports.utils';

// NOTE: Do not directly modify base_configs but rather extend them in chart itself.
export const PRODUCTIVITY_REPORT_BAR_CHART_BASE_CONFIG = {
  datasets: {
    bar: {
      barPercentage: 0.7,
      categoryPercentage: 0.7
    }
  },
  legend: {
    display: false
  },
  tooltip: {
    enabled: true,
    caretSize: 0,
    backgroundColor: gray1,
    titleColor: gray7,
    bodyColor: gray7,
    footerColor: gray7,
    borderColor: gray5,
    borderWidth: 1,
    cornerRadius: 0,
    footerFont: {
      style: 'italic',
      weight: 'normal'
    },
    callbacks: {
      title: () => '',
      label: (context) => {
        const dataIndex = context.dataIndex;
        const allData = context.dataset.data;

        const value = allData[dataIndex] as number;
        const total = context.chart.data.datasets.reduce((sum, dataset) => {
          const dataValue = dataset.data[dataIndex];
          if (typeof dataValue === 'number') {
            return sum + dataValue;
          }
          return sum;
        }, 0);

        const percentageAndTime = getTotalPercent(value, total);
        const formatted = getStringDurationFormat(value, false);
        return `${context.dataset.label}: ${percentageAndTime}% ${formatted}`;
      }
    }
  },
  dataLabels: {
    align: 'center',
    anchor: 'center',
    font: {
      size: 10,
      weight: 'bold'
    }
  },
  scales: {
    x: {
      stacked: true,
      type: 'category',
      grid: {
        display: false
      },
      ticks: {
        minRotation: 45,
        padding: -2
      }
    },
    y: {
      stacked: true,
      grid: {
        drawTicks: false
      },
      suggestedMax: 100,
      ticks: {
        callback: function (value: any) {
          if (value === 0) {
            return '0s';
          }
          return durationFormat(value, false);
        },
        stepSize: 110000,
        padding: 5
      }
    }
  }
} as const;
