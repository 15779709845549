import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import deleteModalControllerTemplate from 'views/modals/deleteModal.html?raw';
import { getDateTimeFormat } from '../../../../_reactivtrak/src/common/utils/datetime/datetimeFormatStrings';

// Register report component
import './videos.component.js';

angular.module('app').controller('VideosCtrl', VideosCtrl);

VideosCtrl.$inject = [
    '$scope',
    'customUibModal',
    '$timeout',
    'localStorageService',
    'messagesService',
    'videosService',
    'pageSizeService',
    '$sce',
    'exportInfoService',
    'authorizationService',
    'atHelperFunctions',
    'browserServiceFunctions'
];

function VideosCtrl(
    $scope,
    customUibModal,
    $timeout,
    localStorageService,
    msg,
    videosService,
    pageSizeService,
    $sce,
    exportInfoService,
    authorizationService,
    atHelperFunctions,
    browserServiceFunctions
) {
    $scope.videosService = videosService;
    $scope.pageMinImages = browserServiceFunctions.isMobileAgent() ? 16 : 32;
    $scope.isAdmin = authorizationService.hasRole(authorizationService.roles.admin);

    const rowSize = videosService.getVideosRowSize();
    const storedOrDefaultPageSize = pageSizeService.loadPageSize('videos-master', $scope.pageMinImages);
    const pageSize = videosService.getClosestMultipleOfRowSize(storedOrDefaultPageSize, rowSize);
    const pageSizes = videosService.getPageSizes(rowSize);
    let cacheKey;
    $scope.reportFilters = {};

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.videos.history', 'videoPlayback');
    };

    const source = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (Object.keys($scope.reportFilters).length === 0 || !$scope.hasViewLevel('read')) {
                    options.success({
                        data: [],
                        total: 0,
                        pagerTotal: 0
                    });
                    return;
                }

                $scope.loading = true;

                $scope.$broadcast('forgetCurrentVideo');

                videosService
                    .getVideos(generateParameters($scope.reportFilters, {}), {
                        params: options.data
                    })
                    .then(function (result) {
                        $scope.loading = false;
                        cacheKey = result.data.cacheKey;
                        options.success(result.data);
                    })
                    .catch(function (result) {
                        $scope.loading = false;
                        options.error(result);
                        $scope.$emit('showNotification', {
                            message: msg.get('videosRetrievingError'),
                            color: 'danger'
                        });
                        console.error('ActivTrak Error: Error retrieving videos', result);
                    });
            }
        },
        schema: {
            data: 'data',
            total: 'total',
            pagerTotal: 'sliderCount'
        },
        sort: {
            field: 'time',
            dir: localStorageService.get('alarm-video-newest-first') ? 'desc' : 'asc'
        },
        pageSize: pageSize,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        change: function () {
            const data = this.data();
            const videos = [];
            data.forEach(function (v, idx) {
                v.originalSources = angular.copy(v.sources);
                v.source = [];
                v.originalSources.forEach(function (s) {
                    const src = {
                        src: $sce.trustAsResourceUrl(s.src),
                        type: s.type
                    };
                    v.source.push(src);
                });
                const { dateTimeFormat } = getDateTimeFormat();
                v.startTime = moment(v.startTime, dateTimeFormat);
                v.endTime = moment(v.endTime, dateTimeFormat);
                v.duration = atHelperFunctions.getDuration(v.startTime, v.endTime);
                v.index = idx;
                videos.push(v);
            });

            $scope.videos = videos;
        },
        entryLimit: 10000
    });

    $scope.source = source;

    $scope.pagerOptions = {
        dataSource: source,
        refresh: true,
        pageSizes: pageSizes,
        buttonCount: 5,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'videos'),
            display: msg.get('itemsDisplay', 'videos'),
            empty: msg.get('noItemsToDisplay', 'videos')
        }
    };

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        pageSizeService.dataSourceReload(source);
    };

    $scope.deleteAll = function () {
        $scope.deleteModalInstance = customUibModal.open({
            template: deleteModalControllerTemplate,
            animation: false,
            windowClass: 'centered-modal',
            controller: 'deleteModalController',
            resolve: {
                warningMessage: {
                    message: msg.get('deleteAllVideos'),
                    deleteLabel: msg.get('deleteAllVideosConfirm')
                }
            }
        });

        $scope.deleteModalInstance.result.then(function () {
            videosService
                .deleteAll()
                .success(function () {
                    source.read({
                        refresh: true
                    });
                    $scope.$emit('showNotification', {
                        message: msg.get('allVideosDeleted'),
                        color: 'success'
                    });
                })
                .error(function () {
                    $scope.$emit('showNotification', {
                        message: msg.get('dataDeletingError'),
                        color: 'danger'
                    });
                });
        });
    };

    exportInfoService.setExportInfo({
        mainDataSource: source,
        getCacheKey: function () {
            return cacheKey;
        },
        getSecondaryParameters: function () {
            return {
                filter: source.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: source.sort()
            };
        }
    });

    $timeout(function () {
        $scope.bindReport();

        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('videos-master');
        }
    });
}
