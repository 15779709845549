import { useEffect, useState } from 'react';
import {
  IUserIdentity,
  IValueValidation,
  IValueValidationPrimary
} from '../models/IUserIdentity';
import { IUserIdentityEditDto } from '../models/IUserIdentityDto';
import {
  trimValueId,
  trimValueOrEmptyString,
  trimValueValidation
} from '../utils/TrimUtils';
import { IUserLogon } from '../models/IUserLogon';
import { IUpdateValue } from '../models/IUpdateValue';
import _ from 'lodash';
import {
  ENTER_VALID_EMAIL,
  ENTER_VALID_UPN
} from '../constants/errorMessages.constants';
import {
  getCombinedDeviceLogonAgents,
  getDefaultDeviceLogon,
  runEmailUpnValidations,
  toggleGlobalDeviceLogon
} from '../utils/DetailEmailEmployeeIdUtils';
import { nullIfEmpty } from '../utils/SearchUtils';
import {
  VALUE_VALIDATION_DEFAULT,
  VALUE_VALIDATION_PRIMARY
} from '../constants/defaultObjects.constants';

export interface IEditUserState {
  editUser: IUserIdentity;
  handleDisplayNameChange?: (e) => void;
  handleEmployeeIdChange?: (e, employeeId: IValueValidation) => void;
  handleEmailChange?: (e, email: IValueValidationPrimary) => void;
  updateEmailUpnValidations?: () => void;
  handleUserPrincipalNameChange?: (e, upn: IValueValidation) => void;
  addDeviceLogon: (userName: string, logonDomain: string) => void;
  getSaveDto: () => IUserIdentityEditDto;
  setEditUser?: (editUser: IUserIdentity) => void;
  handleDeviceLoginDomainChange: (
    targetValue: string,
    deviceLogin: IUserLogon
  ) => void;
  handleDeviceLoginUserChange: (
    targetValue: string,
    deviceLogin: IUserLogon
  ) => void;
  onAddUserPrincipalName?: () => void;
  onAddEmail?: () => void;
  onAddEmployeeId?: () => void;
  onAddDeviceLogon?: () => void;
  onTrackingFlip: () => void;
  onDeleteEmail?: (email: IValueValidationPrimary) => void;
  onDeleteUserPrincipalName?: (upn: IValueValidation) => void;
  onDeleteDeviceLogon?: (deviceLogon: IUserLogon) => void;
  onDeleteEmployeeId?: (employeeId: IValueValidation) => void;
  toggleAgentDeviceLogon: (
    agentSelected: IUserLogon,
    isChecked: boolean
  ) => void;
  setInitialDeviceLogonAgents: (tempUser: IUserIdentity) => void;
  updateDeviceLogonAgents: (tempUser: IUserIdentity) => void;
  agentsAndDeviceLogons: IUserLogon[];
  resetEditUser: () => void;
}

export const useEditUserState = (
  selectedIdentity: IUserIdentity
): IEditUserState => {
  const [editUser, setEditUser] = useState<IUserIdentity>(
    _.cloneDeep(selectedIdentity) as IUserIdentity
  );
  const [agentsAndDeviceLogons, setAgentsAndDeviceLogons] = useState<
    IUserLogon[]
  >([]);

  useEffect(() => {
    resetEditUser();
  }, [selectedIdentity]);

  const resetEditUser = () => {
    if (selectedIdentity) {
      const clonedUser = _.cloneDeep(selectedIdentity) as IUserIdentity;
      //create some default values to edit
      if (clonedUser.emails.length == 0) {
        clonedUser.emails.push({ ...VALUE_VALIDATION_PRIMARY });
      }
      if (clonedUser.upns.length == 0) {
        clonedUser.upns.push({
          ...VALUE_VALIDATION_PRIMARY
        });
      }
      if (clonedUser.employeeIds.length == 0) {
        clonedUser.employeeIds.push({ ...VALUE_VALIDATION_DEFAULT });
      }
      if (
        clonedUser.deviceLogons.length == 0 &&
        clonedUser.agents.length == 0
      ) {
        clonedUser.deviceLogons.push(getDefaultDeviceLogon());
      }
      setEditUser(clonedUser);
    }
  };

  const toggleAgentDeviceLogon = (
    agentSelected: IUserLogon,
    isChecked: boolean
  ) => {
    const updatedUser = toggleGlobalDeviceLogon(
      editUser,
      agentSelected,
      isChecked
    );
    setEditUser({ ...updatedUser });
  };

  const handleDisplayNameChange = (e) => {
    setEditUser((prevState) => {
      return {
        ...prevState,
        displayName: {
          ...(prevState?.displayName ?? VALUE_VALIDATION_DEFAULT),
          value: e.target.value
        }
      };
    });
  };

  const handleEmployeeIdChange = (e, employeeId: IValueValidation) => {
    if (employeeId) {
      employeeId.value = e.target.value;
    } else {
      if (editUser.employeeIds == null) {
        editUser.employeeIds = [];
      }
      if (editUser.employeeIds.length == 0) {
        editUser.employeeIds.push({
          ...VALUE_VALIDATION_PRIMARY,
          value: e.target.value
        });
      }
    }

    setEditUser({ ...editUser });
  };

  const handleDeviceLoginDomainChange = (
    targetValue: string,
    deviceLogin: IUserLogon
  ) => {
    if (deviceLogin) {
      editUser.deviceLogons?.forEach((dl) => {
        if (dl.uniqueId === deviceLogin.uniqueId) {
          dl.logonDomain = targetValue;
          dl.canBeMerged = false;
        }
      });
    } else {
      if (editUser.deviceLogons == null) {
        editUser.deviceLogons = [];
      }
      if (editUser.deviceLogons.length == 0) {
        const newDeviceLogon = getDefaultDeviceLogon();
        newDeviceLogon.logonDomain = targetValue;
        newDeviceLogon.canBeMerged = false;
        editUser.deviceLogons.push(newDeviceLogon);
      }
    }
    setEditUser({ ...editUser });
  };

  const handleDeviceLoginUserChange = (
    targetValue: string,
    deviceLogin: IUserLogon
  ) => {
    if (deviceLogin) {
      editUser.deviceLogons?.forEach((dl) => {
        if (dl.uniqueId === deviceLogin.uniqueId) {
          dl.user = targetValue;
          dl.canBeMerged = false;
        }
      });
    } else {
      if (editUser.deviceLogons == null) {
        editUser.deviceLogons = [];
      }
      if (editUser.deviceLogons.length == 0) {
        const newDeviceLogon = getDefaultDeviceLogon();
        newDeviceLogon.user = targetValue;
        newDeviceLogon.canBeMerged = false;
        editUser.deviceLogons.push(newDeviceLogon);
      }
    }
    setEditUser({ ...editUser });
  };

  const handleUserPrincipalNameChange = (e, upn: IValueValidation) => {
    if (upn) {
      upn.value = e.target.value;
    } else {
      if (!editUser.upns) {
        editUser.upns = [];
      }
      if (editUser.upns.length == 0) {
        editUser.upns.push({
          value: e.target.value,
          originalValue: '',
          validationFailed: false,
          failedExplanation: ''
        });
      }
    }
    setEditUser({ ...editUser });
  };

  const handleEmailChange = (e, email: IValueValidationPrimary) => {
    email.value = e.target.value;
    setEditUser({ ...editUser });
  };

  const updateEmailUpnValidations = () => {
    const emailValidationChanged = runEmailUpnValidations(
      editUser?.emails,
      ENTER_VALID_EMAIL
    );
    const upnValidationChanged = runEmailUpnValidations(
      editUser?.upns,
      ENTER_VALID_UPN
    );

    if (emailValidationChanged || upnValidationChanged) {
      setEditUser({ ...editUser });
    }
  };

  const addDeviceLogon = (user: string, logonDomain: string) => {
    const clone = { ...editUser };

    if (clone.deviceLogons == null) {
      clone.deviceLogons = [];
    }
    const newDeviceLogon = getDefaultDeviceLogon();
    newDeviceLogon.user = user;
    newDeviceLogon.logonDomain = logonDomain;
    clone.deviceLogons.push(newDeviceLogon);

    setEditUser(clone);
  };

  const getUserLogonCsv = (user: string, logonDomain: string): string => {
    const trimmedUser = trimValueOrEmptyString(user);
    const trimmedDomain = trimValueOrEmptyString(logonDomain);
    return trimmedUser && trimmedUser.length > 0
      ? user + ',' + trimmedDomain
      : null;
  };

  const getSaveDeviceLogons = (): IUpdateValue[] => {
    const saveDeviceLogons: IUpdateValue[] = [];
    //changed device logons
    editUser.deviceLogons?.forEach((dl) => {
      if (dl.isGlobal) {
        dl.logonDomain = '';
      }
      if (
        nullIfEmpty(dl.user) !== nullIfEmpty(dl.originalUser) ||
        nullIfEmpty(dl.logonDomain) !== nullIfEmpty(dl.originalLogonDomain)
      ) {
        const userCsv = getUserLogonCsv(dl.user, dl.logonDomain);
        const idCsv = getUserLogonCsv(dl.originalUser, dl.originalLogonDomain);
        const foundDuplicate = saveDeviceLogons.find((e) => e.id === userCsv);
        if (!foundDuplicate) {
          saveDeviceLogons.push({
            value: userCsv,
            id: idCsv
          });
        }
      }
    });

    //deleted device logons
    selectedIdentity.deviceLogons.forEach((deviceLogon) => {
      if (
        !editUser.deviceLogons.some(
          (e) =>
            e.originalUser === deviceLogon.user &&
            e.originalLogonDomain === deviceLogon.logonDomain
        )
      ) {
        const idCsv = getUserLogonCsv(
          deviceLogon.originalUser,
          deviceLogon.originalLogonDomain
        );
        saveDeviceLogons.push({
          value: '',
          id: idCsv
        });
      }
    });
    return saveDeviceLogons;
  };

  const getSaveEmails = (): IUpdateValue[] => {
    const tempEmails: IUpdateValue[] = [];
    editUser.emails.forEach((email) => {
      if (email.value != email.originalValue) {
        tempEmails.push(trimValueValidation(email));
      }
    });
    //look for any deleted emails
    selectedIdentity.emails.forEach((email) => {
      if (!editUser.emails.some((e) => e.originalValue === email.value)) {
        tempEmails.push({
          value: '',
          id: email.originalValue
        });
      }
    });
    return tempEmails;
  };

  const getSaveUpns = (): IUpdateValue[] => {
    const tempUpns: IUpdateValue[] = [];
    editUser.upns.forEach((upn) => {
      if (upn.value != upn.originalValue) {
        tempUpns.push(trimValueValidation(upn));
      }
    });
    //look for any deleted upns
    selectedIdentity.upns.forEach((upn) => {
      if (!editUser.upns.some((e) => e.originalValue === upn.value)) {
        tempUpns.push({
          value: '',
          id: upn.originalValue
        });
      }
    });
    return tempUpns;
  };

  const getSaveEmployeeIds = (): IUpdateValue[] => {
    const tempEmployeeIds: IUpdateValue[] = [];
    editUser.employeeIds.forEach((employeeId) => {
      if (employeeId.value != employeeId.originalValue) {
        tempEmployeeIds.push(trimValueValidation(employeeId));
      }
    });
    //look for any deleted employeeIds
    selectedIdentity.employeeIds.forEach((employeeId) => {
      if (
        !editUser.employeeIds.some((e) => e.originalValue === employeeId.value)
      ) {
        tempEmployeeIds.push({
          value: '',
          id: employeeId.originalValue
        });
      }
    });
    return tempEmployeeIds;
  };

  const getSaveDto = () => {
    const saveDto: IUserIdentityEditDto = {
      displayName:
        selectedIdentity?.displayName?.value == editUser?.displayName?.value
          ? null
          : trimValueId(editUser?.displayName),
      upns: getSaveUpns(),
      deviceLogons: getSaveDeviceLogons(),
      emails: getSaveEmails(),
      employeeIds: getSaveEmployeeIds(),
      //can't reassign from edit user screen
      userid: null,
      entity: null,
      revision: null,
      tracking: selectedIdentity.tracked ? null : false
    };
    return saveDto;
  };

  const onAddUserPrincipalName = () => {
    if (editUser.upns == null) {
      editUser.upns = [];
    }
    editUser.upns.push({ ...VALUE_VALIDATION_PRIMARY });
    setEditUser({ ...editUser });
  };

  const onDeleteUserPrincipalName = (upn: IValueValidation) => {
    const tmpUpns = editUser.upns.filter((e) => e !== upn);
    editUser.upns = tmpUpns;
    if (editUser.upns.length == 0) {
      //if we deleted the last one, then add a default
      onAddUserPrincipalName();
    } else {
      setEditUser({ ...editUser });
    }
  };

  const onAddEmail = () => {
    if (editUser.emails == null) {
      editUser.emails = [];
    }
    editUser.emails.push({ ...VALUE_VALIDATION_PRIMARY });
    setEditUser({ ...editUser });
  };

  const onDeleteEmail = (email: IValueValidationPrimary) => {
    const tmpEmails = editUser.emails.filter((e) => e !== email);
    editUser.emails = tmpEmails;
    if (editUser.emails.length == 0) {
      //if we deleted the last one, then add a default
      onAddEmail();
    } else {
      setEditUser({ ...editUser });
    }
  };

  const onAddEmployeeId = () => {
    if (editUser.employeeIds == null) {
      editUser.employeeIds = [];
    }
    editUser.employeeIds.push({ ...VALUE_VALIDATION_PRIMARY });
    setEditUser({ ...editUser });
  };

  const onDeleteEmployeeId = (employeeId: IValueValidation) => {
    const tmpEmployeeIds = editUser.employeeIds.filter((e) => e !== employeeId);
    editUser.employeeIds = tmpEmployeeIds;
    if (editUser.employeeIds.length == 0) {
      //if we deleted the last one, then add a default
      onAddEmployeeId();
    } else {
      setEditUser({ ...editUser });
    }
  };

  const onAddDeviceLogon = () => {
    if (editUser.deviceLogons == null) {
      editUser.deviceLogons = [];
    }
    const newDeviceLogon = getDefaultDeviceLogon();
    newDeviceLogon.canBeMerged = false;
    editUser.deviceLogons.push(newDeviceLogon);
    setEditUser({ ...editUser });
  };

  const onDeleteDeviceLogon = (deviceLogon: IUserLogon) => {
    const tmpDeviceLogons = editUser.deviceLogons.filter(
      (e) => e.uniqueId !== deviceLogon.uniqueId
    );
    editUser.deviceLogons = tmpDeviceLogons;
    if (editUser.employeeIds.length == 0) {
      //if we deleted the last one, then add a default
      onAddDeviceLogon();
    } else {
      setEditUser({ ...editUser });
    }
  };

  const onTrackingFlip = () => {
    editUser.tracked = !editUser.tracked;
    setEditUser({ ...editUser });
  };

  const setInitialDeviceLogonAgents = (tempUser: IUserIdentity) => {
    const combined = getCombinedDeviceLogonAgents(tempUser);
    const userSorter = (dl) => dl.user.toLowerCase();
    const sortedList = _.sortBy(combined, [userSorter]);
    setAgentsAndDeviceLogons(sortedList);
  };

  const updateDeviceLogonAgents = (tempUser: IUserIdentity) => {
    const combined = getCombinedDeviceLogonAgents(tempUser);
    const existingList = [];
    //clone the existing List, but maintain the existing order
    agentsAndDeviceLogons.forEach((val) => {
      const existingMatch = combined.find((dl) => dl.uniqueId === val.uniqueId);
      if (existingMatch) {
        existingList.push(existingMatch);
      }
    });
    //add new device logons at the end
    combined.forEach((deviceLogon) => {
      const found = existingList?.find(
        (dl) => dl.uniqueId === deviceLogon.uniqueId
      );
      if (!found) {
        existingList.push(deviceLogon);
      }
    });
    //remove any deleted device logons
    const filteredList = existingList?.filter((deviceLogon) =>
      combined.some((dl) => dl.uniqueId === deviceLogon.uniqueId)
    );
    setAgentsAndDeviceLogons(filteredList);
  };

  return {
    editUser,
    handleDisplayNameChange,
    handleEmployeeIdChange,
    handleEmailChange,
    updateEmailUpnValidations,
    handleUserPrincipalNameChange,
    addDeviceLogon,
    getSaveDto,
    setEditUser,
    handleDeviceLoginDomainChange,
    handleDeviceLoginUserChange,
    onAddUserPrincipalName,
    onAddEmail,
    onAddEmployeeId,
    onAddDeviceLogon,
    onTrackingFlip,
    onDeleteEmail,
    onDeleteUserPrincipalName,
    onDeleteDeviceLogon,
    onDeleteEmployeeId,
    toggleAgentDeviceLogon,
    setInitialDeviceLogonAgents,
    updateDeviceLogonAgents,
    agentsAndDeviceLogons,
    resetEditUser
  };
};
