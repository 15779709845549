import {
  deleteData,
  fetchAdminData,
  fetchData,
  postAdminData,
  postData,
  putAdminData,
  putData
} from '../../common/helpers';
import {
  IAgentCommand,
  IComputer,
  IComputerDto,
  IDeviceV1Dto,
  IFetchAllComputers,
  IScheduledAction
} from '../models';
import {
  mapDeviceV1ToComputers,
  mapDeviceV1ToScheduledActionDto,
  mapToComputers
} from './ComputersService.utils';

export const formatComputerComplexName = (
  windowsDomain?: string,
  computer?: string,
  alias?: string
): string => {
  let labelText = ``;
  if (windowsDomain) {
    labelText += `${windowsDomain}`;
  }
  if (computer) {
    if (labelText.length) {
      labelText += `${String.fromCharCode(92)}`;
    }
    labelText += `${computer}`;
  }
  if (alias) {
    if (labelText.length) {
      labelText += ` `;
    }
    labelText += `(${alias})`;
  }
  return labelText;
};

const fetchAllComputersAdmin = async (): Promise<IFetchAllComputers> => {
  const response = await fetchAdminData<IDeviceV1Dto[]>({
    path: `/admin/v1/devices?includeRestorable=True`
  });
  const newResponse: IFetchAllComputers = {
    computers: mapDeviceV1ToComputers(response)
  };
  return newResponse;
};

const fetchAllComputersFeapi = async (): Promise<IFetchAllComputers> => {
  const response = await fetchData<IComputerDto[]>({
    path: `/api/settings/storage/computer`
  });
  const newResponse: IFetchAllComputers = {
    computers: mapToComputers(response)
  };
  return newResponse;
};

export const fetchAllComputers = async (
  softDeletesEnabled: boolean
): Promise<IFetchAllComputers> => {
  if (softDeletesEnabled) {
    const newResponse = await fetchAllComputersAdmin();
    return Promise.resolve(newResponse);
  } else {
    const newResponse = await fetchAllComputersFeapi();
    return Promise.resolve(newResponse);
  }
};

// TODO: Leave on FEAPI for now - transfer to agent services later
export const sendAgentCommand = async (
  computerId: number,
  command: string
): Promise<unknown> => {
  const response = await postData<unknown>({
    path: `api/agent/cmd/` + computerId,
    args: command
  });
  return response;
};

// TODO: transfer to agent admin endpoints later, leave on FEAPI for now
export const fetchComputerAgentCommands = async (computerId: number) => {
  const response = await fetchData<IAgentCommand[]>({
    path: `/api/agent/cmd/` + computerId
  });

  return response;
};

export const fetchComputerScheduledDeletes = async (): Promise<
  IScheduledAction[]
> => {
  const response = await fetchAdminData<IDeviceV1Dto[]>({
    path: `/admin/v1/devices?includeRestorable=True`
  });
  return mapDeviceV1ToScheduledActionDto(response);
};

export const deleteComputerScheduledDelete = async (
  computerId: number
): Promise<void> => {
  await putAdminData<null>({
    path: `/admin/v1/devices/restore`,
    args: { DeviceIds: [computerId] }
  });
};

export const deleteAllComputerScheduledDeletes = async (): Promise<void> => {
  await putAdminData({ path: `/admin/v1/devices/restore` });
};

export const uninstallComputersAdmin = async (
  computers: IComputer[]
): Promise<void> => {
  await postAdminData<null>({
    path: `/admin/v1/devices/uninstall`,
    args: { DeviceIds: computers.map((c) => c.id) }
  });
};

export const uninstallComputersFeapi = async (
  computers: IComputer[]
): Promise<void> => {
  const payload = computers.map((computerItem) => {
    const {
      id: compid,
      windowsDomain: windowsdomain,
      alias,
      computer,
      agentVersion: agentversion
    } = computerItem;
    return { compid, windowsdomain, alias, computer, agentversion };
  });

  await postData({
    path: `/api/settings/uninstall-computer`,
    args: payload
  });
};

export const downloadCSV = async (): Promise<string> => {
  //long term we want to switch to web api, but not ready yet
  return await fetchData<string>({
    path: '/api/settings/storage/computer-report-csv',
    params: { responseType: 'blob' }
  });
};

export const deleteComputersAdmin = async (
  computerIds: number[]
): Promise<void> => {
  await deleteData({
    path: `/admin/v1/devices`,
    args: { DeviceIds: computerIds }
  });
};

export const deleteComputersFeapi = async (
  computerIds: number[]
): Promise<void> => {
  putData({
    path: `/api/settings/delete-multiple-computers`,
    args: { ComputerIds: computerIds }
  });
};
