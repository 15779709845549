import { ColumnState } from 'ag-grid-community';
import { TopUsersDataId } from '../models/TopUsersReport.models';
import { ApiStatus } from '../../../common/enums/ApiStatus';

export const TOP_USERS_SUMMARY_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: TopUsersDataId.User, hide: false },
  { colId: TopUsersDataId.UserId, hide: false },
  { colId: TopUsersDataId.Productive, hide: false },
  { colId: TopUsersDataId.ProductiveActive, hide: true },
  { colId: TopUsersDataId.ProductivePassive, hide: true },
  { colId: TopUsersDataId.Unproductive, hide: false },
  { colId: TopUsersDataId.UnproductiveActive, hide: true },
  { colId: TopUsersDataId.UnproductivePassive, hide: true },
  { colId: TopUsersDataId.Undefined, hide: false },
  { colId: TopUsersDataId.UndefinedActive, hide: true },
  { colId: TopUsersDataId.UndefinedPassive, hide: true },
  { colId: TopUsersDataId.Total, hide: false, sort: 'desc' },
  { colId: TopUsersDataId.Active, hide: true },
  { colId: TopUsersDataId.Offline, hide: false }
];

export const TOP_USERS_DETAILED_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: TopUsersDataId.User, hide: false },
  { colId: TopUsersDataId.UserId, hide: false },
  { colId: TopUsersDataId.Productive, hide: true },
  { colId: TopUsersDataId.ProductiveActive, hide: false },
  { colId: TopUsersDataId.ProductivePassive, hide: false },
  { colId: TopUsersDataId.Unproductive, hide: true },
  { colId: TopUsersDataId.UnproductiveActive, hide: false },
  { colId: TopUsersDataId.UnproductivePassive, hide: false },
  { colId: TopUsersDataId.Undefined, hide: true },
  { colId: TopUsersDataId.UndefinedActive, hide: false },
  { colId: TopUsersDataId.UndefinedPassive, hide: false },
  { colId: TopUsersDataId.Total, hide: false, sort: 'desc' },
  { colId: TopUsersDataId.Active, hide: false },
  { colId: TopUsersDataId.Offline, hide: false }
];

export const TOP_USERS_REPORT_STORE_DEFAULT = {
  response: {
    data: [],
    largestDuration: 0,
    totalActivity: 0,
    error: ''
  },
  apiStatus: ApiStatus.Loaded
};

export const TOP_USERS_SEARCH_STORE_DEFAULT = {
  searchOptions: [],
  searchResults: [],
  searchValue: '',
  searchInputValue: ''
};
