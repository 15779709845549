import {
  IPrivacySettings,
  IPrivacySettingsDto
} from '../models/IPrivacySettings';

export const mapPrivacySettingsDtoToPrivacySettings = (
  privacySettingsDto: IPrivacySettingsDto
): IPrivacySettings => ({
  screenshotsAllowed: privacySettingsDto.screenshots?.enabled ?? false,
  activityAllowed: privacySettingsDto.activity?.enabled ?? false,
  alarmActionsAllowed: privacySettingsDto.alarms?.enabled ?? false
});
