import { react2angular } from 'react2angular';
import { WorkingHoursLegacyReportView } from '../../../../../_reactivtrak/src/reports/working-hours/views/WorkingHoursLegacyReport.view';
import { workingHoursLegacyTemplate } from '../../../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';

angular.module('app').component('wrappedWorkingHoursReport', {
    template: workingHoursLegacyTemplate,
    controller: 'WorkingHoursCtrl'
});
angular
    .module('app')
    .component('workingHoursLegacyReport', react2angular(WorkingHoursLegacyReportView, ['calendarIntegrated']));
