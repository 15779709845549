import React from 'react';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { DownloadProvider } from '../../../common/services/Download/DownloadProvider';
import { ProductivityBarChart } from '../components/ProductivityBarChart';
import { Box } from '@mui/material';
import { ProductivityHeader } from '../components/productivityHeader';
import { ReportViewToggle } from '../../../common/components/ReportFilters/components/ReportViewToggle';
import { ProductivityAggregationsButtons } from '../components/ProductivityAggregationsButtons';
import { SingleDayReport } from '../components/SingleDayReport';
const ProductivityReportView = () => {
  return (
    <>
      <ProductivityHeader />
      <Box
        sx={{
          display: 'block'
        }}
      >
        <Box sx={{ mt: 3 }}>
          <ReportViewToggle />
        </Box>
        <ProductivityAggregationsButtons />
      </Box>
      <ProductivityBarChart />
      <SingleDayReport />
    </>
  );
};

export default ProductivityReportView;

export const ProductivityReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <ProductivityReportView />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
