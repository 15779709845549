import { useEffect, useState } from 'react';
import { AvailableDays, ChartLabelStyle, IUserHealthDto } from '../models';
import {
  fetchAdminData,
  getDashboardSettings,
  setDashboardSettings
} from '../../common/helpers';
import { ApiRoutes } from '../constants';

export const useUserHealthCenterText = (
  percentage: number | string,
  duration: number,
  donutCenterTextStyle: ChartLabelStyle
) => {
  const [userHealthCenterText, setUserHealthCenterText] = useState<any>({});

  //custom hook with useEffect to only update object if dependencies change
  useEffect(() => {
    const result = {
      id: 'donutCenterText',
      afterDatasetsDraw(chart) {
        const { ctx } = chart;

        ctx.save();
        ctx.textAlign = 'center';

        //upperValue
        ctx.fillStyle = 'rgba(68, 68, 68, 1)';
        ctx.font = `bold 40px sans-serif`;
        ctx.fillText(
          `${percentage}%`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y - 25
        );

        // lowerValue
        const lowerValue1 = `of your users have been`;
        const lowerValue2 = `inactive for ${duration}+ days`;

        ctx.fillStyle = 'rgba(68, 68, 68, 1)';
        ctx.font = `${donutCenterTextStyle.textSize}px sans-serif`;
        ctx.fillText(
          lowerValue1,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y + 10
        );
        ctx.fillStyle = 'rgba(68, 68, 68, 1)';
        ctx.font = `${donutCenterTextStyle.textSize}px sans-serif`;
        ctx.fillText(
          lowerValue2,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y + donutCenterTextStyle.lineHeight
        );
      }
    };
    setUserHealthCenterText(result);
  }, [
    donutCenterTextStyle.lineHeight,
    donutCenterTextStyle.textSize,
    duration,
    percentage
  ]);
  return userHealthCenterText;
};

export const getUserHealthConfig = (): AvailableDays => {
  const dashboardSettings = getDashboardSettings();
  const userHealthConfig =
    (dashboardSettings?.userHealthConfig as unknown as AvailableDays) ||
    AvailableDays.SevenDays;

  return userHealthConfig;
};

export const setUserHealthConfigStorage = (value: AvailableDays): void => {
  setDashboardSettings({
    userHealthConfig: value
  });
};

export const fetchUserHealth = (activeSinceDays) =>
  fetchAdminData<IUserHealthDto>({
    path: ApiRoutes.admin.fetchUserHealth,
    params: { activeSinceDays }
  });
