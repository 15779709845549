import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { hideModal } from '../../../common/stores/globalModalStore';
import { newEvent } from '../../../common/analytics/eventHeap';
import Link from '../../../common/components/Link';
import { OfflineMeetingTable, OfflineMeetingsBar } from '../assets/images';
import { showTryOfflineMeetingsMarketoForm } from '../utils/marketo';
import { GlobalModalContent } from '../templates/GlobalModalContent';
import { offlineMeetingsPromoText } from '../constants/WorkingHoursPromo';
import { PrimaryButton, OutlinedButton } from '../styles/ModalBtn.styles';
import {
  fontColor800,
  fontColor900,
  mediumFontWeight
} from '../../../common/constants';
import { getInjector } from '../../../common/third-party/angular2react/useInjector';

export const OfflineMeetingsPromoModal = () => {
  return (
    <GlobalModalContent style={{ height: 670 }}>
      <Typography
        mb={3}
        sx={{
          fontSize: '20px',
          color: fontColor900,
          fontWeight: mediumFontWeight
        }}
      >
        {offlineMeetingsPromoText.title}
      </Typography>
      <Typography sx={{ color: fontColor800 }} mb={3}>
        {offlineMeetingsPromoText.blurb}
      </Typography>
      <Box
        mx="auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={1}
      >
        <img
          width="350"
          src={OfflineMeetingsBar}
          alt="horizontal bar graph with offline meeting data"
        />
      </Box>

      <Box mx="auto" display="flex" alignItems="center" justifyContent="center">
        <img
          width="550"
          src={OfflineMeetingTable}
          alt="three-column chart, first being the user names, second being the users' offline meeting duration, and third being productivity duration"
        />
      </Box>
      <Grid
        container
        direction="column"
        display="flex"
        alignItems="flex-end"
        mt={3}
      >
        <Grid item>
          <OutlinedButton
            variant="outlined"
            onClick={() => {
              hideModal();
              newEvent('WHROfflineMeetingsCTA', { cta: 'marketo' });
              showTryOfflineMeetingsMarketoForm();
            }}
            id="6e6e5fd1-21e6-4ffd-9d10-4bd00d76c881"
          >
            Try for Free
          </OutlinedButton>
          <PrimaryButton
            onClick={() => {
              hideModal();
              newEvent('WHROfflineMeetingsCTA', { cta: 'upgrade' });
              const $injector = getInjector();
              const $state: any = $injector.get('$state');
              $state.go('app.account.upgrade');
            }}
            id="e2815cf1-8629-4fb8-be25-7f4f0c8978c4"
          >
            Add Calendar Integration
          </PrimaryButton>
        </Grid>
        <Grid item mt={3}>
          <Typography sx={{ color: fontColor800 }}>
            To learn more about {offlineMeetingsPromoText.type},{' '}
            <Link
              href={offlineMeetingsPromoText.link}
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              click here
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </GlobalModalContent>
  );
};
