import { useCallback, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import BackButtonBox from './BackButtonBox';
import DialogActionsBox from './DialogActionsBox';
import DialogContent from '@mui/material/DialogContent';
import { isValidConfiguration } from '../../utils/TimeOnTask.view.utils';
import { Bulk } from '../../utils/TimeOnTask.view.utils';
import BulkDownload from './BulkDownload';
import BulkUpload from './BulkUpload';
import BulkUploading from './BulkUploading';
import BulkUploadStatus from './BulkUploadStatus';
import BulkUploadError from './BulkUploadError';
import BulkUploadSuccess from './BulkUploadSuccess';
import BulkUploadWarning from './BulkUploadWarning';
import { useCoreCategoriesState } from '../../hooks/useCoreCategoriesState';
import { IBulkPreprocess } from '../../models';
import { useNotifications } from '../../../common/services/Notifications';

type BulkDialogProps = {
  open: boolean;
  onClose: () => void;
  onBackToGetStarted: () => void;
  onBulkSuccess: (bulkCount: number) => void;
};

export const BulkDialog: React.FC<BulkDialogProps> = ({
  open,
  onClose,
  onBackToGetStarted,
  onBulkSuccess
}) => {
  const [templatefile, setTemplatefile] = useState<File | null>(null);
  const [filename, setFilename] = useState('');
  const [filesize, setFilesize] = useState(0);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [hasDownloadError, setHasDownloadError] = useState(false);

  const [currentUploadView, setCurrentUploadView] = useState(Bulk.DEFAULT);
  const [message1, setMessage1] = useState('');
  const [message2, setMessage2] = useState('');
  const [bulkPreprocess, setBulkPreprocess] = useState<IBulkPreprocess>(null);
  const notificationService = useNotifications();

  const {
    downLoadExcelTemplate,
    isLoading,
    isDownloading,
    isUploading,
    uploadExcelFile,
    createBulkCoreCategories
  } = useCoreCategoriesState();

  const doUpload = useCallback(
    async (file: File) => {
      setCurrentUploadView(null);
      setUploadProgress(0);

      const abortController = new AbortController();
      const signal = abortController.signal;
      intervalRef.current = abortController;

      try {
        const data = await uploadExcelFile(
          file,
          (progress) => {
            setUploadProgress(progress);
          },
          signal
        );
        setBulkPreprocess(data);
        if (data.totalGroupsWithErrorCount > 0) {
          setCurrentUploadView(Bulk.HAS_WARNINGS);
        } else {
          setCurrentUploadView(Bulk.SUCCESS);
        }
      } catch (error) {
        if (abortController.signal.aborted) {
          return;
        }
        setMessage1(Bulk.NETWORK_ERROR);
        setMessage2(Bulk.TRY_AGAIN);
        setCurrentUploadView(Bulk.ERROR);
      }
    },
    [uploadExcelFile]
  );

  const intervalRef = useRef<AbortController | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setFilename(file.name);
      setFilesize(file.size);
      if (file.size > Bulk.MAX_FILE_SIZE) {
        setMessage1(Bulk.FILE_TOO_LARGE);
        setMessage2(Bulk.DECREASE_FILE_SIZE);
        setCurrentUploadView(Bulk.ERROR);
        return;
      }
      const parts = file.name.split('.');
      const ext = parts[parts.length - 1];
      if (!['xlsx', 'xlsm'].includes(ext)) {
        setMessage1(Bulk.INVALID_FILE_TYPE);
        setMessage2(Bulk.SELECT_XLSX_FILE);
        setCurrentUploadView(Bulk.ERROR);
        return;
      }

      setTemplatefile(file);
      await doUpload(file);
    },
    [doUpload]
  );

  const handleRetry = async () => {
    await doUpload(templatefile);
  };

  const handleDownload = async () => {
    setHasDownloadError(false);
    try {
      await downLoadExcelTemplate();
    } catch (error) {
      setHasDownloadError(true);
    }
  };

  const handleCancelUpload = () => {
    if (intervalRef.current) {
      intervalRef.current.abort();
      reset();
      setCurrentUploadView(Bulk.DEFAULT);
    }
  };

  const reset = () => {
    setTemplatefile(null);
    setBulkPreprocess(null);
    setFilename('');
    setFilesize(0);
    setUploadProgress(0);
    setCurrentUploadView(Bulk.DEFAULT);
    setMessage1('');
    setMessage2('');
    setHasDownloadError(false);
    intervalRef.current = null;
  };

  const back = () => {
    if (isUploading) {
      handleCancelUpload();
    }
    reset();
    onBackToGetStarted();
  };

  const close = () => {
    if (isUploading) {
      handleCancelUpload();
    }
    reset();
    onClose();
  };

  const saveBulkConfigurations = async () => {
    const successCount =
      bulkPreprocess.totalGroupCount - bulkPreprocess.totalGroupsWithErrorCount;
    try {
      await createBulkCoreCategories(bulkPreprocess.message);
    } catch (e) {
      console.error('Error saving bulk configurations', e);
      notificationService.error('Error saving bulk configurations');
      reset();
      return;
    }
    reset();
    onBulkSuccess(successCount);
  };

  return (
    <Dialog open={open} onClose={close}>
      <BackButtonBox onClick={back} />
      <Box width={595}>
        <DialogTitle>Configure multiple teams via Excel upload</DialogTitle>
        <DialogContent>
          <Typography>
            <strong>Upload your file below.</strong>
          </Typography>
          <Typography>
            Any teams included in this file that have been previously configured
            in Activity Alignment will be overwritten.
          </Typography>

          <BulkUploading
            isUploading={isUploading}
            uploadProgress={uploadProgress}
            handleCancelUpload={handleCancelUpload}
          />

          <BulkUpload currentUploadView={currentUploadView} onDrop={onDrop} />

          <BulkUploadError
            currentUploadView={currentUploadView}
            message1={message1}
            message2={message2}
          />

          <BulkUploadSuccess
            currentUploadView={currentUploadView}
            isLoading={isLoading}
          />

          <BulkUploadWarning
            currentUploadView={currentUploadView}
            bulkPreprocess={bulkPreprocess}
            isLoading={isLoading}
          />

          <Box display="flex" justifyContent="space-between" marginTop="10px">
            <Typography>Supported format: .xlsx/.xlsm</Typography>
            <Typography>Maximum size: 1MB</Typography>
          </Box>

          <BulkUploadStatus
            isUploading={isUploading}
            filename={filename}
            filesize={filesize}
            currentUploadView={currentUploadView}
            isNetWorkError={message1?.includes('Network error')}
            handleDelete={reset}
            handleRetry={handleRetry}
          />

          <BulkDownload
            isDownloading={isDownloading}
            isUploading={isUploading}
            handleDownload={handleDownload}
            hasDownloadError={hasDownloadError}
          />
        </DialogContent>
        <DialogActions>
          <Box marginTop="-24px">
            <DialogActionsBox
              close={close}
              onClick={saveBulkConfigurations}
              disabled={
                !bulkPreprocess ||
                !isValidConfiguration(bulkPreprocess) ||
                isLoading
              }
              confirmText={'Save configuration'}
            />
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
