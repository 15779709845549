import { inMemoryStore } from 'activtrak-ui-utilities';
import { http as httpService } from '_app/http';
import { constructor as realtimeConstructor } from '_app/settings/realtimeSettingsFunctions';
import { getPrivacySettings } from '_reactivtrak/src/common/hooks/privacySettingsStore';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import lodash from 'lodash';
import messageModalControllerTemplate from '../../../views/modals/messageModal.html?raw';
import messageBodyTemplate from '../../../views/modals/teamPulseScreenViewSettingsModal.html?raw';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import { setReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import {
    screenshotSettingsStore,
    getScreenshotSettingsStore,
    refreshScreenshotSettingsStore
} from '../../../../_reactivtrak/src/common/stores/screenshotSettingsStore';

// Register report component
import './teamPulseController.component.js';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').controller('TeamPulseCtrl', TeamPulseCtrl);

TeamPulseCtrl.$inject = [
    '$scope',
    '$state',
    'authorizationService',
    'realtimeService',
    'localStorageService',
    'atHelperFunctions',
    'realtimeScreenshotService',
    'customUibModal',
    'envConfig',
    'notificationService',
    '$timeout'
];

function TeamPulseCtrl(
    $scope,
    $state,
    authorizationService,
    realtimeService,
    localStorageService,
    atHelperFunctions,
    realtimeScreenshotService,
    customUibModal,
    envConfig,
    notificationService,
    $timeout
) {
    const realtimeSettingsFunctions = realtimeConstructor(httpService, envConfig);
    const { screenshotsAllowed = false } = getPrivacySettings();
    const teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');
    const reportId = 'teamPulse';
    let destroying = false;
    $scope.reportFilters = {};
    teamPulseDataControl.isReport = true;

    teamPulseDataControl.initialize(localStorageService);

    // TODO: remove timeFormat from hear and add to dateSettingsStore
    const { viewableGroupsCount, timeFormat } = getAccountSettings();
    const { username: loggedInUsername } = profileStore.getState().profile;

    if (!authorizationService.hasFeature('isTeamPulseEnabled')) {
        $state.go('app.teampulsepromo');
    }

    $scope.hasTeamPulseGroups = authorizationService.hasFeature('isTeamPulseEnabled');

    $scope.tab = 'pulseView';
    $scope.pollingPaused = false;
    const { thumbnailsEnabled } = getScreenshotSettingsStore();
    $scope.allowThumbnails = thumbnailsEnabled;

    const userFilterSubscription = teamPulseDataControl.userFilter.subscribe(function (userFilter) {
        if (!userFilter) {
            return;
        }
        const { userId, userName, userType, groupType } = userFilter;

        setReportFilters({
            groupId: [userId],
            users: [
                {
                    userId: userId.toString(),
                    userType: userType,
                    name: userName,
                    filterMode: $scope.filterMode,
                    groupType: groupType
                }
            ]
        });
    });

    // Set report mode
    if (!$scope.hasTeamPulseGroups) {
        teamPulseDataControl.reportMode = teamPulseDataControl.reportModes.users;
    } else if ($state.params.reportMode) {
        teamPulseDataControl.reportMode = $state.params.reportMode;
    } else if (!teamPulseDataControl.reportMode.value) {
        if (viewableGroupsCount) {
            const groupCount =
                $scope.filterMode === 'users'
                    ? viewableGroupsCount.viewableUserGroupsCount
                    : viewableGroupsCount.viewableComputerGroupsCount;
            teamPulseDataControl.reportMode =
                groupCount > 2 ? teamPulseDataControl.reportModes.groups : teamPulseDataControl.reportModes.users;
        } else {
            teamPulseDataControl.reportMode = teamPulseDataControl.reportModes.users;
        }

        teamPulseDataControl.reportMode = teamPulseDataControl.reportMode.value;
    }

    // Set initial value based on local storage or default
    $scope.reportMode =
        localStorageService.get('teamPulseReportMode' + loggedInUsername) || teamPulseDataControl.reportMode.value;
    teamPulseDataControl.reportMode.next($scope.reportMode);

    // Set initial value based on local storage or default
    const timeMode =
        localStorageService.get('teamPulseTimeMode' + loggedInUsername) || teamPulseDataControl.timeMode.value;
    teamPulseDataControl.timeMode.next(timeMode);

    // Set initial value based on local storage or default
    $scope.selectedFilter =
        localStorageService.get('teamPulseFilter-' + $scope.reportMode + '-' + loggedInUsername) ||
        teamPulseDataControl.filter.value;
    teamPulseDataControl.filter.next($scope.selectedFilter);

    // Set initial value based on local storage or default
    $scope.selectedSort =
        localStorageService.get('teamPulseSort-' + $scope.reportMode + '-' + loggedInUsername) ||
        teamPulseDataControl.sort.value;
    teamPulseDataControl.sort.next($scope.selectedSort);

    $scope.hideEmptyGroups = teamPulseDataControl.hideEmptyGroups.value;

    $scope.showThumbnails =
        localStorageService.get('showRealtimeThumbnails-' + loggedInUsername) && !atHelperFunctions.isSmallWindow();

    $scope.showTiles =
        $scope.allowThumbnails &&
        localStorageService.get('showRealtimeTiles-' + loggedInUsername) &&
        !atHelperFunctions.isSmallWindow();

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.teampulse');
    };

    $scope.itemClicked = function (item, type) {
        if (type === 'user') {
            $state.go('app.reports.activitylog', {
                user: item.user
            });
        } else if (type === 'title') {
            $state.go('app.reports.activitylog', {
                title: item.title
            });
        } else if (type === 'url') {
            $state.go('app.reports.activitylog', {
                site: item.url
            });
        }
    };

    if ($state.params.card) {
        teamPulseDataControl.selectedCardIds = $state.params.card;
    }

    function handleSortSelection(selection) {
        teamPulseDataControl.sort = selection.text;
    }

    function setSortButtonOptions() {
        const availableSelections = [
            {
                heapId: 'id_536efe10-7a7b-4d18-9fe0-122899e37403',
                text: 'highestProductivity',
                value: 'Productivity %',
                type: 'all'
            },
            {
                heapId: 'id_487814ac-6701-4226-a4be-4d074caff51c',
                text: 'productiveHours',
                value: 'Productive Time',
                type: 'Users',
                alternative: 'averageProductiveHours'
            },
            {
                heapId: 'id_b3c109e4-a0c5-46c8-9143-8f0ec14f2002',
                text: 'totalHours',
                value: 'Average Time',
                type: 'Users',
                alternative: 'averageProductiveHours'
            },
            {
                heapId: 'id_4ca90396-74c0-4564-9f35-fe477d1b198a',
                text: 'username',
                value: 'Username',
                type: 'Users',
                alternative: 'groupName'
            },
            {
                heapId: 'id_74817ca6-acf7-47b5-b757-67a54101fb4d',
                text: 'firstSeen',
                value: 'First Seen',
                type: 'Users',
                alternative: 'highestProductivity'
            },
            {
                heapId: 'id_4808ea74-2ba2-4e7d-b9a1-8c79352d24fa',
                text: 'lastSeen',
                value: 'Last Seen',
                type: 'Users',
                alternative: 'highestProductivity'
            },
            {
                heapId: 'id_74cbea11-e719-47cd-8488-4374070f0753',
                text: 'averageProductiveHours',
                value: 'Average Time',
                type: 'Groups',
                alternative: 'totalHours'
            },
            {
                heapId: 'id_d81efe2b-4855-4cf9-884a-ec97606b76e9',
                text: 'groupName',
                value: 'Group Name',
                type: 'Groups',
                alternative: 'username'
            }
        ];

        const selectionList = availableSelections.filter(function (selection) {
            return selection.type === 'all' || selection.type === teamPulseDataControl.reportMode.value;
        });

        // Change sort if no longer valid selection
        const selectedSort = lodash.find(availableSelections, function (item) {
            return item.text === teamPulseDataControl.sort.value;
        });
        if (
            selectedSort &&
            selectedSort.type !== 'all' &&
            selectedSort.type !== teamPulseDataControl.reportMode.value
        ) {
            teamPulseDataControl.sort = selectedSort.alternative;
        }

        teamPulseDataControl.sort = teamPulseDataControl.sort.value;

        $scope.selectedSort = teamPulseDataControl.sort.value;
        $scope.sortButtonOptions = {
            type: 'sort',
            dynamicLabel: true,
            heapId: 'id_00cb27bc-7342-4fdd-a242-51e73eb526da',
            selectionList: selectionList,
            onChange: handleSortSelection
        };
    }
    setSortButtonOptions();

    function handleFilterSelection(selection) {
        teamPulseDataControl.filter = selection.text;
    }

    function setFilterButtonOptions() {
        const availableSelections = [
            {
                heapId: 'id_52b6034a-cf4d-42f9-86b3-f7d5bddc9d0d',
                text: 'all',
                value: 'All',
                type: 'all'
            },
            {
                heapId: 'id_032bd412-722d-4a35-a270-c59cd711d199',
                text: 'activeNow',
                value: 'Active Now',
                type: 'Users',
                alternative: 'activeToday'
            },
            {
                heapId: 'id_349489a1-d37f-42dd-8273-04d87ef41aaa',
                text: 'activeToday',
                value: 'Active Today',
                type: 'all'
            },
            {
                heapId: 'id_386b30bd-b0d5-4625-ae2e-fa5f70eeca93',
                text: 'passiveNow',
                value: 'Passive Now',
                type: 'Users',
                alternative: 'activeToday'
            },
            {
                heapId: 'id_654fa39f-e96b-488f-ae26-a0ccf59e530b',
                text: 'inactiveNow',
                value: 'Inactive Now',
                type: 'Users',
                alternative: 'inactiveToday'
            },
            {
                heapId: 'id_2d88894c-e9db-4e63-9709-5a01a5deabdd',
                text: 'inactiveToday',
                value: 'Inactive Today',
                type: 'all'
            }
        ];

        const selectionList = availableSelections.filter(function (selection) {
            return selection.type === 'all' || selection.type === teamPulseDataControl.reportMode.value;
        });

        // Change filter if no longer valid selection
        const selectedFilter = lodash.find(availableSelections, function (item) {
            return item.text === teamPulseDataControl.filter.value;
        });
        if (
            selectedFilter &&
            selectedFilter.type !== 'all' &&
            selectedFilter.type !== teamPulseDataControl.reportMode.value
        ) {
            teamPulseDataControl.filter = selectedFilter.alternative;
        }

        teamPulseDataControl.filter = teamPulseDataControl.filter.value;

        $scope.selectedFilter = teamPulseDataControl.filter.value;

        $scope.filterButtonOptions = {
            type: 'filter',
            dynamicLabel: true,
            heapId: 'id_fab24286-34f3-40d5-b180-80560949feb0',
            selectionList: selectionList,
            onChange: handleFilterSelection
        };
    }
    setFilterButtonOptions();

    function setToggleOptions() {
        $scope.toggleOptions = {
            labels: [
                {
                    heapId: 'id_abcc4728-b5c4-4251-a5ff-91e2e59547e7',
                    text: 'Users',
                    value: $scope.filterMode === 'users' ? 'Users' : 'Computers'
                },
                {
                    heapId: 'id_39d1161f-02f5-4006-b2a8-ebd10e9b91d4',
                    text: 'Groups',
                    value: 'Groups'
                }
            ],
            onChange: function (val) {
                teamPulseDataControl.reportMode = val;
                reportModeUpdated();
            }
        };
    }

    function reportModeUpdated() {
        setSortButtonOptions();
        setFilterButtonOptions();
        setSettingsButtonOptions();
    }

    // Sync scope with observable
    function handleReportModeChange(reportMode) {
        if (reportMode && reportMode !== $scope.reportMode) {
            $scope.reportMode = reportMode;
            reportModeUpdated();
        }
    }
    const reportModeSubscription = teamPulseDataControl.reportMode.subscribe(handleReportModeChange);

    function handleViewChange(view) {
        switch (view) {
            case 'pulseView':
                teamPulseDataControl.pollingPaused = $scope.pollingPaused;
                $state.current.data.filter.columns = false;
                realtimeService.dataSource.filter({});
                realtimeService.pause(true);
                break;
            case 'extendedList':
                teamPulseDataControl.pollingPaused = true;
                $state.current.data.filter.columns = true;
                $scope.setShowPassive(false);
                realtimeService.pause($scope.pollingPaused);
                break;
            case 'thumbnails':
                teamPulseDataControl.pollingPaused = true;
                $state.current.data.filter.columns = false;
                realtimeService.dataSource.filter({});
                realtimeService.pause($scope.pollingPaused);
                break;
        }
    }
    handleViewChange($scope.tab);

    function setTabOptions() {
        $scope.tabOptions = {
            tabs: [
                {
                    heapId: 'id_e39e9fd7-d20f-445e-95ea-e8ec41e41d62',
                    text: 'pulseView',
                    value: 'Pulse View'
                },
                {
                    heapId: 'id_b2085727-821d-4ace-9b94-ce689a77b173',
                    text: 'extendedList',
                    value: 'Extended List',
                    show: screenshotsAllowed
                },
                {
                    heapId: 'id_259223da-3624-41ab-b6b2-38903ce47707',
                    text: 'thumbnails',
                    value: 'Screen View',
                    show: $scope.allowThumbnails && showScreenViewAccess() && screenshotsAllowed
                }
            ],
            onChange: handleViewChange
        };
    }
    setTabOptions();

    function pausePolling() {
        $scope.pollingPaused = !$scope.pollingPaused;
        if ($scope.tab !== 'pulseView') {
            realtimeService.pause($scope.pollingPaused);
        } else {
            teamPulseDataControl.pollingPaused = $scope.pollingPaused;
        }
    }

    $scope.pauseButtonOptions = {
        icon: 'pause',
        onClick: pausePolling,
        tooltip: 'Pause Team Pulse',
        activeTooltip: 'Resume Team Pulse',
        heapId: 'id_ce1f3290-6489-4cb5-bd32-631c15d34cd0'
    };

    function showScreenViewAcceptanceModal() {
        const modal = customUibModal.open({
            animation: false,
            template: messageModalControllerTemplate,
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageBody: messageBodyTemplate,
                    confirmLabel: 'Accept',
                    confirmHeapId: 'id_a78f26a6-28d3-4de7-99e9-b8e7cc0ef728',
                    cancelHeapId: 'id_74c0996d-0737-4e4b-9079-91984af12dea',
                    confirmClass: 'btn-success'
                }
            }
        });

        modal.result
            .then(function () {
                updateScreenViewSetting(true);
            })
            .catch(angular.noop);
    }

    function updateScreenViewSetting(value) {
        realtimeSettingsFunctions
            .saveSettings(value)
            .then(function () {
                refreshScreenshotSettingsStore();
            })
            .catch(function (e) {
                console.error('ActivTrak Error: An error occurred while saving the Real-time screenshot setting.', e);
                notificationService.showNotification(
                    'An error occurred while saving the Real-time screenshot setting.',
                    'danger'
                );
            });
    }

    function handleSettingSelection(selection) {
        switch (selection.text) {
            case 'enableScreenViews':
                showScreenViewAcceptanceModal();
                break;
            case 'disableScreenViews':
                updateScreenViewSetting(false);
                break;
            case 'showActiveTime':
                teamPulseDataControl.timeMode = teamPulseDataControl.timeModes.active;
                setSettingsButtonOptions();
                setSortButtonOptions();
                break;
            case 'showTotalTime':
                teamPulseDataControl.timeMode = teamPulseDataControl.timeModes.total;
                setSettingsButtonOptions();
                setSortButtonOptions();
                break;
            case 'hideEmptyGroups':
                teamPulseDataControl.hideEmptyGroups = !teamPulseDataControl.hideEmptyGroups.value;
                setSettingsButtonOptions();
                break;
        }
    }

    function setSettingsButtonOptions() {
        const screenViewSetting = $scope.allowThumbnails
            ? {
                  heapId: 'id_4eaea23e-4743-4aa5-aa25-6b5f072fa3ea',
                  text: 'disableScreenViews',
                  value: 'Disable Screen Views',
                  disabled: !$scope.hasViewLevel('edit')
              }
            : {
                  heapId: 'id_4eaea23e-4743-4aa5-aa25-6b5f072fa3ea',
                  text: 'enableScreenViews',
                  value: 'Enable Screen Views',
                  disabled: !$scope.hasViewLevel('edit')
              };
        const timeModeSetting =
            teamPulseDataControl.timeMode.value === teamPulseDataControl.timeModes.total
                ? {
                      heapId: 'id_a7ad8fac-10b9-4640-a6a1-dbb589324e8d',
                      text: 'showActiveTime',
                      value: 'Exclude Passive Time'
                  }
                : {
                      heapId: 'id_a7ad8fac-10b9-4640-a6a1-dbb589324e8d',
                      text: 'showTotalTime',
                      value: 'Include Passive Time'
                  };
        const emptyGroupSetting = teamPulseDataControl.hideEmptyGroups.value
            ? {
                  heapId: 'id_ffaa478a-f77c-434b-b1f5-bd9bc049f3c4',
                  text: 'hideEmptyGroups',
                  value: 'Show Empty Groups'
              }
            : {
                  heapId: 'id_ffaa478a-f77c-434b-b1f5-bd9bc049f3c4',
                  text: 'hideEmptyGroups',
                  value: 'Hide Empty Groups'
              };

        let selectionList = [timeModeSetting];
        if (screenshotsAllowed) {
            selectionList = [screenViewSetting].concat(selectionList);
        }
        if (teamPulseDataControl.reportMode.value === 'Groups') {
            selectionList = selectionList.concat(emptyGroupSetting);
        }
        $scope.settingsButtonOptions = {
            type: 'settings',
            heapId: 'id_859d5bad-17a8-4195-8105-4b9ada233c8b',
            selectionList: selectionList,
            onChange: handleSettingSelection
        };
        $scope.showTiles =
            $scope.allowThumbnails &&
            localStorageService.get('showRealtimeTiles-' + loggedInUsername) &&
            !atHelperFunctions.isSmallWindow();
        if (typeof $scope.updateGridOptions == 'function') {
            $timeout($scope.updateGridOptions);
        }
    }
    setSettingsButtonOptions();

    // Inform service that data should be reloaded
    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        const users = $scope.reportFilters?.users || [];
        const filterMode = users[0]?.filterMode || 'users';
        $scope.filterMode = filterMode;
        const reportingUrl = envConfig.reportingServiceUrl();

        if (!destroying) {
            teamPulseDataControl.filterMode = $scope.filterMode;
            teamPulseDataControl.apiConfig = {
                url: envConfig.apiUrl(),
                reportingUrl: reportingUrl,
                websocketHttpsUrl: envConfig.websocketHttpsUrl(),
                isWidget: false,
                parameters: generateParameters($scope.reportFilters, {
                    rangeOverride: 'Today'
                })
            };
            teamPulseDataControl.refreshData = true;

            // start realtime polling
            realtimeService.start(reportId);

            // pause realtime poll if using team pulse
            if ($scope.tab === 'pulseView') {
                realtimeService.pause(true);
            }

            setToggleOptions();
        }
    };

    $scope.showScreenshot = function (dataItem) {
        // Pause polling while screenshot viewer is open
        realtimeService.pause(true);
        $scope.$broadcast('showRealtimeScreenshotEvent', {
            item: dataItem,
            callback: screenshotClosed
        });
    };

    $scope.titleClicked = function (item) {
        $state.go('app.reports.activitylog', {
            title: item.title
        });
    };

    function screenshotClosed() {
        // Resume polling if not paused by UI control
        realtimeService.pause($scope.pollingPaused);
    }

    function setShowThumbnails(value) {
        $scope.setShowThumbnails(value);
    }

    function setShowPassive(value) {
        $scope.setShowPassive(value);
    }

    $scope.screenshotViewerConfig = {
        isRealtime: true,
        closeViewer: screenshotClosed
    };

    $scope.teamPulseOptions = {
        isReport: true
    };

    $scope.thumbnailSwitchOptions = {
        label: 'Thumbnails',
        onChange: setShowThumbnails
    };

    $scope.passiveSwitchOptions = {
        label: 'Passive',
        onChange: setShowPassive
    };

    const screenshotSettingsStoreUnsubscribe = screenshotSettingsStore.subscribe((state) => {
        const { thumbnailsEnabled } = state;
        $scope.allowThumbnails = thumbnailsEnabled;
        setSettingsButtonOptions();
        setTabOptions();
    });

    function setContainerHeight() {
        const containerHeight = atHelperFunctions.getGridHeight(284, 330);
        $scope.teamPulseOptions.containerHeight = containerHeight;
    }
    setContainerHeight();

    teamPulseDataControl.isReport = $scope.teamPulseOptions.isReport;
    teamPulseDataControl.timeFormat = timeFormat;
    teamPulseDataControl.containerHeight = $scope.teamPulseOptions.containerHeight;
    teamPulseDataControl.isViewer = authorizationService.hasRole([authorizationService.roles.viewer]);

    realtimeService.settings();

    realtimeScreenshotService.initialize();
    realtimeScreenshotService.reset();

    $scope.$on('atWindowResizing', function (event, windowSize) {
        if (windowSize.newHeight !== windowSize.oldHeight) {
            setContainerHeight();
        }
    });

    $scope.$on('$destroy', function () {
        destroying = true;
        realtimeService.unsubscribe(reportId);
        realtimeScreenshotService.destroy();
        reportModeSubscription.unsubscribe();
        userFilterSubscription.unsubscribe();
        teamPulseDataControl.userFilter = null;
        screenshotSettingsStoreUnsubscribe();
    });

    function showScreenViewAccess() {
        const isViewer = authorizationService.hasRole(authorizationService.roles.viewer);
        return !isViewer || authorizationService.hasFeature(FeatureFlag.ShowTeamPulseScreenViews);
    }
}
