import { apiService as apiServiceDefault } from '../../helpers/apis/apiService';

import { getDefaultConfig } from '../../config/env.config';
import { IDownloadService } from './IDownloadService';
import { BaseDownloadCSVData } from './DownloadService.utils';
import { IApiService } from '../../models/IApiService';

export class DownloadService implements IDownloadService {
   protected apiService: IApiService;

   constructor(apiService?: IApiService) {
       this.apiService = apiService ?? apiServiceDefault;
     }
  
  public downloadAgent = (os: string) =>
    this.apiService
      .get(`/api/download/${os}agent`, getDefaultConfig())
      .then((response) => response.data);

  public downloadCSV = (reportType: string, args: any) =>
    this.apiService
      .get(`/api/export/${reportType}?${args}`, {
        ...getDefaultConfig(),
        params: { ...BaseDownloadCSVData, exportMode: reportType }
      })
      .then((response) => response.data);
}

export default DownloadService;
