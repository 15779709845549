import { useCallback, useState } from 'react';
import { IDigestSetting } from '../../common/models/IDigestSetting';
import { IDigestSettingPost } from '../../common/models/IDigestSettingPost';
import { IDigestState, DigestStateProps } from '../models';
import { SortDirection } from '../../common/enums/SortDirection';
import { NotificationType } from '../../common/enums/NotificationType';
import { filterDigests, mapToDigestSetting } from '../utils';
import { sorter } from '../../common/utils/sorter/sorter';

export const useDigestState = ({ service }: DigestStateProps): IDigestState => {
  const { fetchDigestSettings, postDigestSettings } = service;

  const [digests, setDigests] = useState<IDigestSetting[]>();
  const [originalDigests, setOriginalDigests] = useState<IDigestSetting[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [digestsNotification, setDigestsNotification] =
    useState<NotificationType>();

  const getDigestSettings = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await fetchDigestSettings();
      const mapped: IDigestSetting[] = response.map((item) =>
        mapToDigestSetting(item)
      );
      setDigests(mapped);
      //keep a copy of all digests for local search resetting
      setOriginalDigests(mapped);
    } catch (error) {
      setDigestsNotification({
        msg: 'Unable to load digest settings',
        type: 'error'
      });
      console.error('ActivTrak Error: Unable to load digest settings', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    getDigestSettings();
  }, [getDigestSettings]);

  const updateDigestSettings = useCallback(
    async (payload: IDigestSettingPost[]): Promise<void> => {
        try {
          await postDigestSettings(payload);

          //merge changes with local data store
          setDigests((prevState) => {
            const currentItems = [...prevState];
            for (let i = 0; i < payload.length; i++) {
              const index = currentItems?.findIndex(
                (item) => item.userId === payload[i].userId
              );

              if (index > -1) currentItems[index].digest = payload[i].digest;
            }
            return currentItems;
          });
        } catch (error) {
          setDigestsNotification({
            msg: 'Unable to update digest settings',
            type: 'error'
          });
          console.error(
            'ActivTrak Error: Unable to update digest settings',
            error
          );
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

    const searchDigests = useCallback(
      (filter: string): void => {
        if (filter) {
          const filtered = filterDigests(originalDigests, filter);
          setDigests(filtered);
        } else {
          setDigests(originalDigests);
        }
      },
      [originalDigests]
    );

    const setSortedDigests = useCallback(
      (sortDirection: SortDirection, sortOrderBy: string): void => {
        const newOrder = sorter(digests, sortDirection, sortOrderBy);
        setDigests(newOrder);
      },
      [digests]
    );

    return {
      digests,
      isLoading,
      digestsNotification,
      setDigestsNotification,
      init,
      updateDigestSettings,
      searchDigests,
      setSortedDigests
    };
};
