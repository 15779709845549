import {
  AgentVersionType,
  OperationalSystemsTypeShort
} from '../../common/enums';
import { IAgent, IAgentDto, IPlatformDto, IAgentSelection } from '../models';
import {
  adminDisabledAutoUpdate,
  autoUpdateEnabled,
  newVersionAvailable
} from '../constants/autoUpdate.const';

export const getAgentCount = function (accountPlatforms, agentVersion) {
  let agentCountText = 'N/A';
  const platform = getCorrespondingPlatform(accountPlatforms, agentVersion);
  if (platform && platform.totalMachines > 0) {
    agentCountText =
      platform.machinesAtTarget + ' of ' + platform.totalMachines;
  }
  return agentCountText;
};

export const getAgentLabel = function (agentVersion) {
  if (agentVersion.label) return agentVersion.label;
  switch (agentVersion.type_id) {
    case 'GA':
      return 'Production';
    case 'Beta':
      return 'Early Access';
  }
  return null;
};

export const getVersionType = function (type) {
  switch (type) {
    case 'GA':
      return AgentVersionType.ga;
    case 'Beta':
      return AgentVersionType.beta;
    default:
      return AgentVersionType.unknown;
  }
};

export const getOsIcon = function (os: string) {
  switch (os) {
    case 'Win':
      return 'fa-windows';
    case 'Mac':
      return 'fa-apple';
    default:
      return 'fa-question';
  }
};

export const getOsTitle = (os) => {
  switch (os) {
    case 'Win':
      return 'Windows';
    case 'Mac':
      return 'Apple';
    default:
      return os;
  }
};

export const getCorrespondingPlatform = (accountPlatforms, agentVersion) => {
  let matchedPlatform = null;

  accountPlatforms.forEach(function (platform) {
    if (
      platform.target &&
      platform.target.os === agentVersion.os &&
      platform.target.version &&
      platform.target.version.version == agentVersion.version.version
    ) {
      matchedPlatform = platform;
    }
  });

  return matchedPlatform;
};

export const getAgentStatus = (accountPlatforms, agentVersion) => {
  let statusText = '';
  let statusColor = '';
  let statusTooltip = null;
  const platform = getCorrespondingPlatform(accountPlatforms, agentVersion);
  if (!platform || platform.totalMachines == 0) {
    statusText = 'No agents found';
    statusColor = 'text-master-light';
  } else {
    if (agentVersion.enabled) {
      if (agentVersion.pinned) {
        statusText = adminDisabledAutoUpdate;
        statusColor = 'text-danger';
        statusTooltip =
          'An admin in your organization requested to pin Agents to this version';
      } else {
        statusText = autoUpdateEnabled;
        statusColor = 'text-success';
        statusTooltip =
          'Your account is enabled for this OS and auto-updates are available';
      }
    } else {
      statusText = newVersionAvailable;
      statusColor = 'text-warning';
    }
  }

  return [statusText, statusColor, statusTooltip];
};

export const mapToAgents = (
  agents: IAgentDto[],
  platforms: IPlatformDto[]
): IAgent[] => {
  const result = agents.map((item) => {
    const status = getAgentStatus(platforms, item);
    const statusText = status[0];
    const statusColor = status[1];
    const statusTooltip = status[2];

    return {
      version: item.version?.version,
      os: item.os,
      osDisplay: getOsTitle(item.os),
      type: getVersionType(item.type_id),
      typeDisplay: getAgentLabel(item),
      pinned: item.pinned,
      osIcon: getOsIcon(item.os),
      countText: getAgentCount(platforms, item),
      statusColor: statusColor,
      statusText: statusText,
      statusTooltip: statusTooltip
    };
  });
  return result;
};

export const filterAgents = (
  data: IAgentDto[],
  os: string
): IAgentSelection[] => {
  const filtered: IAgentSelection[] = [
    {
      title: 'Early Access (auto-update)',
      subtitle: 'includes every new feature',
      value: AgentVersionType.beta
    },
    {
      title: 'Production (auto-update)',
      subtitle: 'includes major features',
      value: AgentVersionType.ga
    }
  ];

  data.forEach((item) => {
    if (item.os === os && item.visible) {
      filtered.push({
        title: item.version.version,
        value: item.version.version
      });
    }
  });
  return filtered;
};

export const getSelectedAgentVersion = (
  agentVersions: IAgent[],
  os: OperationalSystemsTypeShort
) => {
  const item = agentVersions?.filter((item) => item.os === os)[0];
  if (item) {
    return item.pinned ? item.version : item.type;
  }

  return '';
};

export const getAutoUpdateWarning = (
  os: OperationalSystemsTypeShort,
  value: AgentVersionType | string
) => {
  const osName = os === OperationalSystemsTypeShort.win ? 'Windows' : 'Mac';
  return `By selecting this ${osName} agent version, you are opting into auto-updates${
    value == AgentVersionType.beta
      ? ' that will include beta and preview features.'
      : '.'
  } Once you proceed, your agents will begin updating immediately.`;
};

export const getManualWarning = (os: OperationalSystemsTypeShort) => {
  const osName = os === OperationalSystemsTypeShort.win ? 'Windows' : 'Mac';
  return (
    `Targeting a specific version will pause automatic updates for ${osName}. You will have to manually update this ` +
    `setting for agents to update to newer versions going forward. Once you proceed, your agents will begin updating immediately.`
  );
};

export const hasSwitchedToAuto = (
  originalValue: AgentVersionType | string,
  newValue: AgentVersionType | string
) => {
  return (
    originalValue !== AgentVersionType.ga &&
    originalValue !== AgentVersionType.beta &&
    (newValue === AgentVersionType.ga || newValue === AgentVersionType.beta)
  );
};

export const hasSwitchedToManual = (
  originalValue: AgentVersionType | string,
  newValue: AgentVersionType | string
) => {
  return (
    (originalValue === AgentVersionType.ga ||
      originalValue === AgentVersionType.beta) &&
    newValue !== AgentVersionType.ga &&
    newValue !== AgentVersionType.beta
  );
};
