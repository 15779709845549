import React, { useEffect } from 'react';
import { DashboardSubheader } from '../templates';
import { useTeamTrendsState } from '../../hooks/useTeamTrendsState';
import { IRedirectParams, WidgetProps } from '../../models';
import authorizationService from '../../../common/helpers/authorization';
import { noUsersReportedLast30days } from '../../constants';
import { convertObjectToQs } from '../../../common/utils/parse/parse';

export default function TeamTrendsSubheader(props: WidgetProps) {
  const { reportFilters } = props;

  const { teamTrendsHours, isTileError, isLoading, init } =
    useTeamTrendsState();

  const primaryFiltersQuery = convertObjectToQs(reportFilters);

  useEffect(
    () => {
      init(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [init, primaryFiltersQuery]
  );

  const hasActivityBreakdownAccess = authorizationService.hasRouteByName(
    'app.reports.insightsActivityBreakdown'
  );

  const params: IRedirectParams = {
    modelId: 'activinsights',
    pageId: 'activity_breakdown',
    subPageId: 'users',
    insightsRange: '30 day'
  };

  return (
    <DashboardSubheader
      {...teamTrendsHours}
      topLineSubheader="Team Trends (Last 30 Days)"
      hasTileError={isTileError}
      isLoading={isLoading}
      infoIconText="Last 30 days of average productive hours compared to the previous 30 days. If the average remains the same, no comparison will display."
      url={
        hasActivityBreakdownAccess
          ? 'app.reports.insightsActivityBreakdown'
          : ''
      }
      params={params}
      zeroDataMsg={noUsersReportedLast30days}
      zeroDataLink="https://support.activtrak.com/hc/en-us/articles/19321210019483"
    />
  );
}
