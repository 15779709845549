import React, { ChangeEvent, useEffect, useState } from 'react';
import { usePerformanceOptimizationState } from '../hooks/usePerformanceOptimizationState';
import { PerformanceOptimizationService } from '../services/PerformanceOptimizationService';
import { useNotifications } from '../../common/services/Notifications';
import { DefaultWidgetProps } from '../models';
import { ConfigurationCardWrapper } from './ConfigurationCardWrapper';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

export const PerformanceOptimizationCard = (props: DefaultWidgetProps) => {
  const { isSupport = false } = props;

  const [newValue, setNewValue] = useState<string>('false');

  const service = PerformanceOptimizationService();
  const notificationService = useNotifications();
  const settingsState = usePerformanceOptimizationState({
    service,
    notificationService
  });
  const { optimize, isLoading, isLocked, updateOptimize } = settingsState;

  useEffect(() => {
    setNewValue(optimize?.toString());
  }, [optimize]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const disabled = isSupport || isLoading;

  const hideCard = isLocked && !isSupport;

  return (
    <>
      {!hideCard && (
        <ConfigurationCardWrapper
          title="Live Report Performance Optimization"
          actions={
            <Button
              variant="contained"
              color="primary"
              disabled={disabled || newValue === optimize.toString()}
              onClick={() => updateOptimize(newValue)}
            >
              Save
            </Button>
          }
        >
          <Typography sx={{ marginBottom: '16px' }}>
            Select data filter preferences for all Live Reports and Dashboard.
            Excluding the current day&apos;s data in the results will improve
            performance. Including the current day&apos;s data will result in
            slower performance. This setting will apply to all users on your
            account.
          </Typography>
          <Typography sx={{ marginBottom: '24px' }}>
            Please note: This does not affect the selection of a “Custom range”
            or “Today” in the filter dropdown.
          </Typography>
          <FormControl>
            <RadioGroup value={newValue} onChange={handleChange}>
              <FormControlLabel
                value="true"
                control={
                  <Radio disabled={disabled} color="primary" size="small" />
                }
                label="Exclude today's data (will result in a faster load time) "
              />
              <FormControlLabel
                value="false"
                control={
                  <Radio disabled={disabled} color="primary" size="small" />
                }
                label="Include today's data (will result in a slower load time)"
              />
            </RadioGroup>
          </FormControl>
        </ConfigurationCardWrapper>
      )}
    </>
  );
};
